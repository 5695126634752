import { useContext, useEffect, useRef, useState } from 'react'
import styles from './form-details-by-id.module.scss';
import { Form, FormState } from '../../../store/models/form.interface';
import { useTranslation } from 'react-i18next';
import TextComponent from '../../../components/v2/common/text-component/text-component';
import { useSelector } from 'react-redux';
import { getForm, setActiveUsers } from '../../../store/slices/forms.slice';
import { RootDispatch, RootState } from '../../../store/store';
import { useParams } from 'react-router-dom';
import { fetchResponseCount } from '../../../api/form-response';
import toast from 'react-hot-toast';
import { getPvtFormsSharedCount } from '../../../api/forms';
import { SocketContext } from '../../../context/socket';
import { Socket } from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { setNewData } from '../../../store/slices/active-page.slice';
import { ACTIVE_PAGE_STATUS } from '../../../store/models/active-page';
import { activePageThunk } from '../../../store/thunks/active-page.thunk';
import { fetchFormByIdThunk } from '../../../store/thunks/forms.thunk';
import ToolTip from '../../../components/v2/common/tool-tip/tool-tip';
import CustomButton from '../../../components/v2/common/custom-button/custom-button';
import svgLoading from '../../../assets/v2/icons/loading.svg';
import svgRefresh from "../../../assets/v2/refresh.svg";

export default function FormDetailsContainer() {
  const { t } = useTranslation();
  const { formId } = useParams();
  const form: Form | undefined = useSelector((state: RootState) => getForm(state, formId as string));
  const [responseCount, setResponseCount] = useState(0);
  const [sharedWithCount, setSharedWithCount] = useState('');
  const socket = useContext(SocketContext);
  const [liveuser, setLiveUser] = useState(0);
  const dispatch: RootDispatch = useDispatch();
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const [refreshPage, setRefreshPage] = useState(false);
  const activeWebPage = useSelector((state: RootState) => state.activePage.activePage);
  const refreshWebPage = useSelector((state: RootState) => state.activePage.refreshPage);
  const socketIns = useSelector((state: RootState) => state.socket.Ins);
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activeWebPage])

  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    if (formId) {
      dispatch(activePageThunk({ socket: socket, payload: { pageName: `form-details-${formId}`, status } }));
    }
  }

  useEffect(() => {
    if (newData) {
      getLatestData(newData);
      dispatch((setNewData({ action: "forms" })));
    }
  }, [newData])

  useEffect(() => {
    init();
  }, [refreshWebPage]);

  const init = () => {
    if (formId) {
      fetchFormResponseCount(formId);
      getSharedWithCount();
    }
  }

  useEffect(() => {
    if (form && form.activeUsers) {
      setLiveUser(form.activeUsers);
    } else if (form && form.id && socketIns) {
      fetchActiveUsers(socketIns, form.id);
    }
  }, [form])



  const getLatestData = async (newData?: string) => {
    try {
      if (formId) {
        setRefreshPage(true);
        if (newData === "forms") {
          await dispatch(fetchFormByIdThunk(formId));
          init();
        }

      }
      setRefreshPage(false);
    } catch (err) { }
  }

  const fetchFormResponseCount = async (formId: string) => {
    let count: number = 0;
    try {
      const response = await fetchResponseCount(formId);
      count = response.data.count;

    } catch (err) {
      toast.error(t('form_details_toast_form_response_err'));
    }
    setResponseCount(count);
  }

  const getSharedWithCount = async () => {
    try {
      if (formId) {
        const res = await getPvtFormsSharedCount(formId);
        setSharedWithCount(res.data.count.toString());
      }
    } catch (err) {
      setSharedWithCount('-')
    }
  }

  const fetchActiveUsers = async (socketIns: Socket, formId: string) => {
    const action = 'activeusercount';
    socketIns.emit(action, { action, payload: { formId: formId } }, (result: { socket: Socket, activeUsers: number }) => {
      socketIns.removeListener("error");
      setLiveUser(result.activeUsers);
      dispatch(setActiveUsers(result));
    });
  }

  return (
    <>
      {
        form &&
        <div className={styles.middleContainer}>
          <div className={styles.containerOne}>
            <div className={styles.midTwo}>
              <div className={`${styles.card} ${styles.nameCard}`} >
                <div className={styles.cardTitle}>
                  <TextComponent textType='Content'>
                    <div>{t("table_form_name")}</div>
                  </TextComponent>
                </div>
                <div className={styles.cardContent} >
                  <TextComponent textType='Content'>
                    <div className={styles.cardText}>{form.name}</div>
                  </TextComponent>
                </div>
              </div>
              <div className={styles.card} style={{ width: '100%' }}>
                <div className={styles.cardTitle}>
                  <TextComponent textType='Content'>
                    <div>{t("form_details_description")}</div>
                  </TextComponent>
                </div>
                <div className={styles.cardContent}>
                  <TextComponent textType='Content'>
                    <div className={styles.cardText}>{form.description ? form.description : "-"}</div>
                  </TextComponent>
                </div>
              </div>
              <div className={styles.card} >
                <div className={styles.cardTitle}>
                  <TextComponent textType='Content'>
                    <div>{t("table_form_created_by")}</div>
                  </TextComponent>
                </div>
                <div className={styles.cardContent} >
                  <TextComponent textType='Content'>
                    <div className={styles.cardText}>{form.createdByUserName}</div>
                  </TextComponent>
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <TextComponent textType='Content'>
                    <div >{t("table_form_created_on")}</div>
                  </TextComponent>
                </div>
                <div className={styles.cardContent}>
                  <TextComponent textType='Content'>
                    <div className={styles.cardText}>{form.createdAt ? new Date(form.createdAt).toLocaleString() : "-"}</div>
                  </TextComponent>
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <TextComponent textType='Content'>
                    <div >{t("expiry_time")}</div>
                  </TextComponent>
                </div>
                <div className={styles.cardContent}>
                  <TextComponent textType='Content'>
                    <div className={styles.cardText}>{form.expiryTime ? new Date(form.expiryTime).toLocaleString() : "-"}</div>
                  </TextComponent>
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <TextComponent textType='Content'>
                    <div >{t("table_form_status")}</div>
                  </TextComponent>
                </div>
                <div className={styles.cardContent}>
                  <TextComponent textType='Content'>
                    <div className={styles.cardText} style={{ color: `${form.state === FormState.DRAFT ? '#642DFB' : ''} ${form.state === FormState.PUBLISHED ? "#15D503" : ""} ${form.state === FormState.WAITING_FOR_PUBLISH ? 'sandybrown' : ''}` }}>{form.state && t(form.state)}</div>
                  </TextComponent>
                </div>
              </div>
              {
                form.isPublic === false &&
                <div className={styles.card}>
                  <div className={styles.cardTitle}>
                    <TextComponent textType='Content'>
                      {t("table_form_shared_with")}
                    </TextComponent>
                  </div>
                  <div className={styles.cardContent}>
                    <TextComponent textType='Content'>
                      {parseInt(sharedWithCount) > 0 ? `${t("count_people", { count: +sharedWithCount })}` : "-"}
                    </TextComponent>
                  </div>
                </div>
              }
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <TextComponent textType='Content'>
                    <div >{t("currently_viewed_by")}</div>
                  </TextComponent>
                </div>
                <div className={styles.cardContent}>
                  <TextComponent textType='Content'>
                    <div >{liveuser > 0 ? `${t("count_people", { count: +liveuser })}` : "-"}</div>
                  </TextComponent>
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <TextComponent textType='Content'>
                    <div >{t("max_responses_cnt")}</div>
                  </TextComponent>
                </div>
                <div className={styles.cardContent}>
                  <TextComponent textType='Content'>
                    <div >{form.expiryResponseCount ? form.expiryResponseCount : "-"}</div>
                  </TextComponent>
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <TextComponent textType='Content'>
                    <div >{t("dashboard_total_responses")}</div>
                  </TextComponent>
                </div>
                <div className={styles.cardContent}>
                  <TextComponent textType='Content'>
                    <div >{responseCount > 0 ? `${t("count_responses", { count: responseCount })}` : "-"}</div>
                  </TextComponent>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.previewContainer}>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '0.5rem', alignItems: 'center' }}>
              <TextComponent textType='Header'>{t("preview")}</TextComponent>
              <div>
                <ToolTip position="bottom" content={`${t("reload_preview")}`}>
                  <CustomButton
                    buttonType='button'
                    classType='tertiary'
                    style={{
                      background: "var(--accent-color)",
                      borderRadius: '48px',
                      border: 'none',
                      padding: '6px',
                      boxShadow: 'none'
                    }}
                    onClick={() => {
                      const ref = iFrameRef.current;
                      if (ref && ref instanceof HTMLIFrameElement) {
                        // this will reload the iFrame.
                        const pageSrc = ref.src;
                        ref.src = pageSrc;
                      }
                    }}
                  >
                    <div className={styles.isImageHolder}>
                      {refreshPage ? <img src={svgLoading} /> : <img src={svgRefresh} />}
                    </div>
                  </CustomButton>
                </ToolTip>
              </div>
            </div>
            <iframe ref={iFrameRef} className={`${styles.frame}`} src={`${process.env.REACT_APP_RENDERER_WEBAPP}/${form?.url}?mode=preview&liveControls=live-controls`} />
          </div>
        </div >
      }
    </>
  )
}
