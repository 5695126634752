import axios from 'axios';
import { GenerateApiUrl } from './generate-api-url';

export async function submitFeedback(data: CreateFeedbackDto) {
  const url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `feedback`, false);
  const response = await axios.post<void>(url, data);
  return response;
}

export interface CreateFeedbackDto {
  rating: number;
  comments: string;
  name?: string;
  email?: string;
}
