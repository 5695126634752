import { Toaster } from "react-hot-toast";
import styles from './toast.module.scss';

export default function CustomToast() {

  return (<Toaster
    position="top-center"
    reverseOrder={false}
    gutter={8}
    containerClassName={styles.toastContainer}
    containerStyle={{}}
    toastOptions={{
      className: '',
      duration: 3000,
      style: {
        boxShadow: "0 0 2px 2px solid #000000",
        fontFamily: "Poppins !important",
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        background: '#333',
        color: '#ffffff',
        borderRadius: '0.5rem',
        padding: '0.5rem'
      },
      iconTheme: {
        primary: '#642DFB',
        secondary: '#ffff'
      },
      success: {
        iconTheme: {
          primary: 'green',
          secondary: '#ffff'
        },
      },
      error: {
        iconTheme: {
          primary: 'red',
          secondary: '#ffff'
        },
      }
    }}
  />);
}
