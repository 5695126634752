import { useDispatch, useSelector } from "react-redux";
import { ConditionalEdge, CreateConditionalEdge } from "../../../../../../store/models/form-builder/conditional-logic";
import { getConditionalLogicSelector } from "../../../../../../store/slices/conditional-logic.slice";
import { RootDispatch, RootState } from "../../../../../../store/store";
import { createConditionalLogicThunk, deleteConditionalLogicThunk, getAllConditionalLogicThunk, updateConditionalLogicThunk } from "../../../../../../store/thunks/conditional-logic.thunk";
import { toast } from "react-hot-toast";
import { UseLogicBuilderRes } from "../models/use-logic-builder.interface";
import { useEffect, useState } from "react";

export function useLogicBuilder(formId: string): UseLogicBuilderRes {
  const dispatch: RootDispatch = useDispatch();
  const conditionalLogic: ConditionalEdge[] = useSelector((state: RootState) => getConditionalLogicSelector(state, formId));
  const [deleteLoader, setDeleteLoader] = useState(false);

  useEffect(() => {
    if (!conditionalLogic) getConditionalLogic();
  }, []);

  async function createConditionalLogic(payload: CreateConditionalEdge) {
    try {
      await dispatch(createConditionalLogicThunk({ formId: formId, payload: payload })).unwrap();
      toast.success('Condition created successfully');
    } catch (e) {
      toast.error('There was an error in creating the condition');
    }
  }

  async function getConditionalLogic() {
    try {
      await dispatch(getAllConditionalLogicThunk({ formId: formId })).unwrap();
    } catch (e) {
      toast.error('There was an error in fetching conditions');
    }
  }

  async function updateConditionalLogic(conditionalId: string, payload: Partial<CreateConditionalEdge>) {
    try {
      await dispatch(updateConditionalLogicThunk({ conditionalLogicId: conditionalId, formId: formId, payload: payload })).unwrap();
    } catch (e) {
      toast.error('There was an error in updating condition');
    }
  }

  async function deleteConditionalLogic(conditionalId: string) {
    setDeleteLoader(prev => true);
    try {
      await dispatch(deleteConditionalLogicThunk({ conditionalLogicId: conditionalId, formId: formId })).unwrap();
    } catch (e) {
      toast.error('There was an error in deleting condition');
    }
    setDeleteLoader(prev => false);
  }

  return {
    createConditionalLogic,
    getConditionalLogic,
    updateConditionalLogic,
    deleteConditionalLogic,
    conditionalLogic,
    deleteLoader
  };
}
