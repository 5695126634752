import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../models/load-state.enum";
import { fetchFormListThunk, fetchResponseListThunk, fetchAllResponsesID, fetchFileResponseListByWorkspaceThunk, deleteFileThunk } from "../thunks/form-responses.thunk";
import { FormListResponseDto, GetAllResponseID, GetFormResponseItemDto } from '../models/form-response.interface'
import { RootState } from "../store";
import { GetFileItemsDto } from "../models/form.interface";

const FormResponseInitialState: {
  data: FormListResponseDto[];
  formscount: number;
  count: number;
  totalCount: number;
  responseList: GetFormResponseItemDto[],
  fetchLoadingState: LoadState;
  responsesID: GetAllResponseID[];
  workspaceFileResponses: GetFileItemsDto[]
} = { data: [], formscount: 0, count: 0, totalCount: 0, responseList: [], fetchLoadingState: LoadState.IDLE, responsesID: [], workspaceFileResponses: [] };

export const formresponsSlice = createSlice({
  name: "formresponsSlice",
  initialState: FormResponseInitialState,
  reducers: {
    clearFileResponse(state) {
      state.workspaceFileResponses = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormListThunk.pending, (state) => {
        state.fetchLoadingState = LoadState.LOADING;
      })
      .addCase(fetchFormListThunk.fulfilled, (state, action) => {
        state.data = action.payload.formList;
        state.totalCount = action.payload.totalResponseCount;
        state.formscount = action.payload.formsCount;
        state.fetchLoadingState = LoadState.IDLE;
      })
      .addCase(fetchFormListThunk.rejected, (state) => {
        state.fetchLoadingState = LoadState.ERROR;
      })
      .addCase(fetchResponseListThunk.pending, (state) => {
        state.fetchLoadingState = LoadState.LOADING;
      })
      .addCase(fetchResponseListThunk.fulfilled, (state, action) => {

        let { data, totalCount } = action.payload;
        if (data && totalCount) {
          state.responseList = data.FormResponses;
          state.count = totalCount;
          state.fetchLoadingState = LoadState.IDLE;
        }
      })
      .addCase(fetchResponseListThunk.rejected, (state, action) => {
        state.responseList = [];
        state.fetchLoadingState = LoadState.ERROR;
      })
      .addCase(fetchAllResponsesID.pending, (state) => {
        state.fetchLoadingState = LoadState.LOADING;
      })
      .addCase(fetchAllResponsesID.fulfilled, (state, action) => {
        state.responsesID = action.payload;
      })
      .addCase(fetchAllResponsesID.rejected, (state) => {
        state.fetchLoadingState = LoadState.ERROR;
      })
      .addCase(fetchFileResponseListByWorkspaceThunk.pending, (state) => {
        state.fetchLoadingState = LoadState.LOADING;
      })
      .addCase(fetchFileResponseListByWorkspaceThunk.fulfilled, (state, action) => {
        const { data } = action.payload;
        if (state.workspaceFileResponses.length > 0) {
          state.workspaceFileResponses = state.workspaceFileResponses.concat(data);
        } else {
          state.workspaceFileResponses = [...data];
        }
      })
      .addCase(fetchFileResponseListByWorkspaceThunk.rejected, (state) => {
        state.fetchLoadingState = LoadState.ERROR;
      })
      .addCase(deleteFileThunk.pending, (state) => {
        state.fetchLoadingState = LoadState.LOADING;
      })
      .addCase(deleteFileThunk.fulfilled, (state, action) => {
        const { attachmentId } = action.payload;
        state.workspaceFileResponses = state.workspaceFileResponses.filter(response => response.id !== attachmentId);
      })
      .addCase(deleteFileThunk.rejected, (state) => {
        state.fetchLoadingState = LoadState.ERROR;
      })
  }
});

export const getAllResponsesID = (state: RootState) => state.formResponses.responsesID;

export const getWorkspaceFileResponses = (state: RootState) => state.formResponses.workspaceFileResponses;

export const { clearFileResponse } = formresponsSlice.actions;

export default formresponsSlice;

