import React from 'react'
import styles from './from-details-header-menu.module.scss'
import TextComponent from '../../../../../components/v2/common/text-component/text-component'
import { useTranslation } from 'react-i18next'
import CustomButton from '../../../../../components/v2/common/custom-button/custom-button';
import hasSufficientScopes from '../../../../../util/access-validator/access-validator';
import toast from 'react-hot-toast';
import { FormDetailsHeaderMenuProps } from './form-details-header-menu.props';
import { useParams } from 'react-router-dom';
import { Form, FormState } from '../../../../../store/models/form.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { getForm } from '../../../../../store/slices/forms.slice';
import svgClone from "../../../../../assets/v2/icons/clone.svg";
import svgDelete from "../../../../../assets/v2/icons/delete.svg";
import svgCloseBtn from "../../../../../assets/v2/close.svg";
import svgEdit from "../../../../../assets/v2/icons/edit.svg";
import svgShare from "../../../../../assets/v2/icons/share.svg";
import { CustomModal } from '../../../../../components/v2/common/custom-modal/custom-modal';
import svgPreview from "../../../../../assets/v2/icons/preview.svg";

export default function FormDetailsHeaderMenu(props: FormDetailsHeaderMenuProps) {

  const { t } = useTranslation();
  const { formId } = useParams();
  const form: Form | undefined = useSelector((state: RootState) => getForm(state, formId as string));

  const triggerModal = () => {
    let element = document.getElementById('preview-form');
    if (element) element.style.display = 'flex';
    let elementIframe = document.getElementById('preview-form-iframe') as HTMLIFrameElement;
    if (elementIframe) {
      elementIframe.src = '';
      elementIframe.src = `${process.env.REACT_APP_RENDERER_WEBAPP}/${form && form.url}?mode=preview&liveControls=live-controls`;
    }
  }

  const closeModal = () => {
    let element = document.getElementById('preview-form');
    if (element) element.style.display = 'none';
  }

  return (
    <>
      <CustomModal
        id={'preview-form'}
        header={
          <div className={styles.modalTitleContainer} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flexGrow: "1" }}>
              <TextComponent textType='Header'>{t("preview_form")}</TextComponent>
            </div>
            <div className={styles.modalTitleCloseBtnWrapper}>
              <img className={styles.closeModalBtn} onClick={closeModal} src={svgCloseBtn} alt="close-button" />
            </div>
          </div>
        }
        content={
          <>
            <iframe
              id={'preview-form-iframe'}
              title={"preview-form"}
              className={`${styles.frame}`} style={{ flexGrow: 1 }}>
            </iframe>
          </>
        }
      />
      <div className={`${styles.actionMenuBg} ${props.isMenuActive ? styles.actionMenuBgActive : ""}`} onClick={() => { props.setIsMenuActive(false) }}>
        <div className={`${styles.mobileFilterContainer} ${props.isMenuActive ? styles.mobileFilterContainerActive : ""}`}>
          <div className={styles.actionContainer}>
            <div style={{ alignSelf: "baseline", width: "100%" }}>
              <div className={styles.tagTitleContainer}>
                <TextComponent textType='Content'>
                  <div className={styles.tagTitle}>{t("i_want_to")}</div>
                </TextComponent>
                <div className={styles.mobileCloseBtn}>
                  <button style={{
                    background: "var(--accent-color)",
                    borderRadius: '48px',
                    border: 'none',
                    padding: '0.2rem',
                    display: 'flex',
                    boxShadow: 'none'
                  }} onClick={() => { props.setIsMenuActive(false) }}>
                    <img style={{ width: '1rem' }} src={svgCloseBtn} alt="" />
                  </button>
                </div>
              </div>
              <hr className={styles.actionline} />
            </div>
            {/* <div className={styles.actionButtonContainer}>
              <CustomButton
                buttonType='button'
                classType='tertiary'
                style={{
                  background: "var(--highlight-color)",
                  padding: " 8px 16px",
                  width: "fit-content"
                }}
                onClick={() => getLatestData()}
              >
                <div className={styles.isImageHolder}>
                  <img src={svgRefresh} />
                </div>
              </CustomButton>
              <TextComponent textType='Content'>
                <div className={styles.tagTitle}>{t("refresh")}</div>
              </TextComponent>
            </div> */}
            <div className={styles.actionButtonContainer}>
              <CustomButton
                buttonType='button'
                classType='tertiary'
                style={{
                  background: "var(--highlight-color)",
                  padding: " 8px 16px",
                  width: "fit-content",
                  boxShadow: 'none'
                }}
                onClick={triggerModal}
              >
                <div className={styles.isImageHolder}>
                  <img src={svgPreview} />
                </div>
              </CustomButton>
              <TextComponent textType='Content'>
                <div className={styles.tagTitle}>{t("preview")}</div>
              </TextComponent>
            </div>
            {
              form && form.state === FormState.PUBLISHED && form.isPublic === true &&
              <div className={styles.actionButtonContainer}>
                <CustomButton
                  style={{
                    background: 'var(--highlight-color)',
                    padding: " 8px 16px",
                    width: "fit-content",
                    boxShadow: 'none'
                  }}
                  buttonType='button'
                  classType='tertiary'
                  onClick={() => { props.setShareVisible(true); props.setIsShareMenuActive(true) }}
                >
                  <div className={styles.isImageHolder}>
                    <img src={svgShare} />
                  </div>
                </CustomButton>
                <TextComponent textType='Content'>
                  <div className={styles.tagTitle}>{t("share")}</div>
                </TextComponent>
              </div>
            }
            {
              form && form.state === FormState.DRAFT &&
              <div className={styles.actionButtonContainer}>
                <CustomButton
                  style={{
                    background: 'var(--highlight-color)',
                    padding: " 8px 16px",
                    width: "fit-content",
                    boxShadow: 'none'
                  }}
                  buttonType='button'
                  classType='tertiary'
                  onClick={() => { props.editForm(formId as string); }}
                >
                  <div className={styles.isImageHolder}>
                    <img src={svgEdit} alt='edit' />
                  </div>
                </CustomButton>
                <TextComponent textType='Content'>
                  <div className={styles.tagTitle}>{t("edit")}</div>
                </TextComponent>
              </div>
            }
            <div className={styles.actionButtonContainer}>
              <CustomButton
                style={{
                  background: 'var(--highlight-color)',
                  padding: " 8px 16px",
                  width: "fit-content",
                  boxShadow: 'none'
                }}
                buttonType='button'
                classType='tertiary'
                onClick={() => { props.cloneForm(formId as string); }}
              >
                <div className={styles.isImageHolder}>
                  <img src={svgClone} alt='clone' />
                </div>
              </CustomButton>
              <TextComponent textType='Content'>
                <div className={styles.tagTitle}>{t("clone")}</div>
              </TextComponent>
            </div>
            <div className={styles.actionButtonContainer}>
              <CustomButton
                style={{
                  background: "var(--highlight-color)",
                  padding: "8px 16px",
                  width: "fit-content",
                  boxShadow: 'none'
                }}
                buttonType='button'
                classType='tertiary'
                onClick={() => {
                  if (hasSufficientScopes(["forms:delete", "forms:delete.all"], form?.createdBy)) {
                    props.setShowModal(true);
                  } else {
                    toast.error(t('form_details_delete_form_no_permission'));
                  }
                }}
              >
                <div className={styles.isImageHolder}>
                  <img src={svgDelete} alt='delete' />
                </div>
              </CustomButton>
              <TextComponent textType='Content'>
                <div className={styles.tagTitle}>{t("delete")}</div>
              </TextComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
