import { ConditionComparatorEnum } from "../../../../../../store/models/form-builder/conditional-logic";
import { DropdownDataItem } from "../../../../common/custom-select/custom-select.props";

export const comparatorData: DropdownDataItem[] = [
  { label: 'equal to', value: ConditionComparatorEnum.EQUAL_TO }
];

export const numberComparatorData: DropdownDataItem[] = [
  { label: 'greater than', value: ConditionComparatorEnum.GREATER_THAN },
  { label: 'less than', value: ConditionComparatorEnum.LESSER_THAN },
  { label: 'greater than or equal to', value: ConditionComparatorEnum.GREATER_THAN_OR_EQUAL_TO },
  { label: 'less than or equal to', value: ConditionComparatorEnum.LESSER_THAN_OR_EQUAL_TO }
];

export const logicActionData: DropdownDataItem[] = [
  { label: 'show', value: 'show' }
];

export const tagInputControls = ['email', 'mobile number', 'number', 'text', 'textarea'];

export const dropdownInputControls = ['dropdown', 'radio', 'checkbox'];
