import React, { ChangeEvent, useEffect, useState } from 'react'
import styles from './add-role.module.scss';
import CustomHeading from '../../../../../components/v2/common/custom-heading/custom-heading';
import CustomText from '../../../../../components/v2/common/custom-bodyText/custom-text';
import CustomInput from '../../../../../components/v2/common/custom-input/custom-input';
import CustomCheckbox from '../../../../../components/v2/common/custom-checkbox/custom-checkbox';
import CustomButton from '../../../../../components/v2/common/custom-button/custom-button';
import { getGroupedPermissions } from '../user-management-constants';
import toast from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../../store/store';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { CreateRoleReqDto, RolesResponseDtoItem, UpdateRoleReqDto } from '../../../../../store/models/roles.interface';
import { createRoleThunk, updateRoleThunk } from '../../../../../store/thunks/roles.thunk';
import svgPaneDelete from "../../../../../assets/v2/close.svg";
import TextComponent from '../../../../../components/v2/common/text-component/text-component';
import CharacterValidator from '../../../../../util/character-validator';

export default function AddRole(props: AddRoleDto) {
  const { t } = useTranslation();
  const grouped = getGroupedPermissions();  // TODO: This can be changed to fetch from getBaseRoles API instead of the static list we have here.
  const dispatch = useDispatch<RootDispatch>();
  const [selectedPermissions, setSelectedPermissions] = useState<Set<string>>(new Set());
  const [roleName, setRoleName] = useState<string>('');
  const [errors, setErrors] = useState({
    roleName: "",
    permission: ""
  })
  const [roleDesc, setRoleDesc] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState(true);
  const [dirtyFlags, setDirtyFlags] = useState({
    role: true,
    name: true
  });
  const roles = useSelector((state: RootState) => state.roles.data);

  async function handleInit() {
    if (props.currentRole) {
      setIsEditMode(true);
      setDirtyFlags({
        ...dirtyFlags,
        name: false
      })
      const rn = props.currentRole.name;
      setRoleName(rn);
      setRoleDesc(props.currentRole.description || '');
      const perms = props.currentRole.permissions;
      setSelectedPermissions(new Set(perms));
    }
  }

  useEffect(() => {
    handleInit();
  }, []);

  const handleCheckChange = (data: { data: string, isChecked: boolean }) => {
    const temp = new Set(selectedPermissions);
    if (data.isChecked) {
      temp.add(data.data);
    } else {
      temp.delete(data.data);
    }
    setDirtyFlags({
      ...dirtyFlags,
      role: false
    })
    setIsError(false);
    setSelectedPermissions(temp);
  };


  const validate = () => {
    let obj = {
      roleName: "",
      permission: ""
    }
    if (roleName && roleName.trim().length > 0) {
      obj = {
        ...obj,
        roleName: ""
      }
    } else {
      obj = {
        ...obj,
        roleName: "Role name is mandatory"
      }
    }

    if (selectedPermissions.size > 0) {
      obj = {
        ...obj,
        permission: ""
      }
    } else {
      obj = {
        ...obj,
        permission: "At least one permission needed"
      }
    }

    if (obj.roleName.length === 0 && obj.permission.length === 0) {
      setErrors(obj);
      setIsError(false);
      return true
    }
    setErrors(obj);
    setIsError(true);
    return false;
  }

  const handleAddSaveClicked = async () => {
    setIsLoading(true);
    const isErrorPresent = validate();
    if (!isErrorPresent) {
      setIsLoading(false);
      return
    }
    if (isEditMode) {
      const updateData: UpdateRoleReqDto = {
        name: roleName,
        description: roleDesc,
        permissions: Array.from(selectedPermissions)
      };
      const dataToPost = {
        roleName,
        data: updateData
      };
      try {
        await dispatch(updateRoleThunk(dataToPost)).unwrap();
        props.setIsSidePaneOpen(false);
        toast.success(t("account_details_roles_saved_successfully"));
      } catch (err) {
        toast.error(t("account_details_roles_couldnt_save_role"));
      }
    } else {
      const dataToPost: CreateRoleReqDto = {
        name: roleName,
        description: roleDesc,
        permissions: Array.from(selectedPermissions)
      };
      try {
        const res = await dispatch(createRoleThunk(dataToPost)).unwrap();
        setRoleDesc("");
        setRoleName("");
        props.setIsSidePaneOpen(false);
        toast.success(t("account_details_roles_created_successfully"));

      } catch (err) {
        console.log("if error", err);
        if ((err as AxiosError).message == 'QUOTA_LIMIT_EXCEEDED') {
          toast.error(`Quota set for the your current subscription has exceeded. Please upgrade your plan to create more roles.`);
        } else {
          toast.error(t("account_details_roles_couldnt_create_role"));
        }
      }
    }
    setIsLoading(false);
  };

  const handleRoleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRoleName(e.target.value);
    const validate = CharacterValidator(e.target.value);
    if (validate.length > 0) {
      setErrors({
        ...errors,
        roleName: validate
      });
      setIsError(true)
      return;
    }
    const value = roles.find(value => value.name === e.target.value)
    if (value) {
      setErrors({
        ...errors,
        roleName: "Role name is already used"
      });
      setIsError(true);
      return;
    }
    setErrors({
      ...errors,
      roleName: ""
    });
    setDirtyFlags({
      ...dirtyFlags,
      name: false
    });
    setIsError(false);
  };

  const handleRoleDescChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRoleDesc(e.target.value);
    if (isEditMode) {
      setDirtyFlags({
        ...dirtyFlags,
        role: false
      });
      setIsError(false);
    }
  };

  return (
    <div className={styles.sidepaneMain}>
      <div className={styles.topContainer}>
        <div className={styles.closeBtnContainer}>
          <div style={{ width: "80%" }}>
            <CustomHeading headerType='h5'>
              <div className={styles.textColor}>{t(isEditMode ? "account_details_roles_save_role" : "account_details_roles_add_role")}</div>
            </CustomHeading>
            <CustomText textType='t5'>
              <div className={styles.textColor}>{t(isEditMode ? "account_details_roles_save_role_desc" : "account_details_roles_add_role_desc")}</div>
            </CustomText>
          </div>
          <div className={styles.mobileCloseBtn}>
            <button className={styles.mobilePaneCloseBtn} onClick={() => { props.setIsSidePaneOpen(false) }}>
              <img style={{ width: '1rem' }} src={svgPaneDelete} alt="" />
            </button>
          </div>
        </div>

        <div className={styles.line}></div>
      </div>
      <div className={styles.inpuContainer}>
        <div>
          <CustomHeading headerType='h5'>
            <div className={styles.textColor}>{t("account_details_roles_role_name")}</div>
          </CustomHeading>
          <CustomInput
            inputStyleType='fade'
            inputType='text'
            placeHolder={t("account_details_roles_role_name")}
            onChange={handleRoleNameChange}
            value={roleName}
            disabled={isEditMode}
          />
          <div>
            {
              errors && errors.roleName &&
              <TextComponent textType='Error'>
                <span>{errors.roleName}</span>
              </TextComponent>
            }
          </div>
        </div>
        <div>
          <CustomHeading headerType='h5'>
            <div className={styles.textColor}>{t("account_details_roles_what_is_the_role_about")}</div>
          </CustomHeading>
          <CustomInput
            inputStyleType='fade'
            inputType='text'
            placeHolder={t("account_details_roles_what_is_the_role_about")}
            onChange={handleRoleDescChange}
            // defaultValue={props.currentRole?.description}
            value={roleDesc}
          />
        </div>
        <CustomHeading headerType='h5'>
          <div className={styles.textColor}>{t("account_details_roles_permissions")}</div>
        </CustomHeading>
        <div className={styles.permissionContainer}>
          <div>
            {
              Array.from(grouped.keys()).map((k, index, arr) => (
                <div key={k}>
                  <CustomHeading headerType='h5'>
                    <div className={styles.groupTitle}>{k}</div>
                  </CustomHeading>
                  <div className={styles.gridList}>
                    {
                      grouped.get(k)?.map(p => (
                        <div key={p}>
                          <div className={styles.groupItem}>
                            <CustomCheckbox data={k + ':' + p} labelText={p} isInitialChecked={selectedPermissions.has(k + ':' + p)} onCheckChange={handleCheckChange} />
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  {(index !== (arr.length - 1)) &&
                    <div className={styles.spacer}></div>
                  }
                </div>
              ))
            }
          </div>
        </div>
        <div>
          {
            errors && errors.permission &&
            <TextComponent textType='Error'>
              <span>{errors.permission}</span>
            </TextComponent>
          }
        </div>
        <div className={styles.saveBtn} style={{ marginBottom: "1rem" }}>
          <CustomButton
            buttonType='button'
            classType='primary'
            onClick={handleAddSaveClicked}>
            <CustomHeading headerType='h4' style={{ color: "#ffff" }}>
              <div>
                {
                  isLoading
                    ? t("loading")
                    : (isEditMode ? t("account_details_roles_save_role") : t("account_details_roles_add_role"))
                }
              </div>
            </CustomHeading>
          </CustomButton>
        </div>
        <div className={styles.closebtn} style={{ marginBottom: "1rem" }}>
          <CustomButton
            buttonType='button'
            classType='secondary'
            onClick={handleAddSaveClicked}
            disabled={isError || dirtyFlags.role || dirtyFlags.name}
          >
            <TextComponent textType='Content'>
              <div>
                {
                  isLoading
                    ? t("loading")
                    : (isEditMode ? t("account_details_roles_save_role") : t("account_details_roles_add_role"))
                }
              </div>
            </TextComponent>
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

interface AddRoleDto {
  setIsSidePaneOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentRole?: RolesResponseDtoItem;
}
