import styles from '../settings-panel.module.scss';
import { useEffect, useRef, useState } from "react";
import { Accordion } from "../../../common/accordion/accordion";
import { useTranslation } from 'react-i18next';
import { IntegrationSettingsProps } from './integration-settings.props';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import CustomSelect from '../../../common/custom-select/custom-select';
import { DropdownDataItem } from '../../../common/custom-select/custom-select.props';
import { MultiValue, SingleValue } from 'react-select';
import { useNavigate } from 'react-router-dom';
import { getAllIntegrations } from '../../../../../api/integrations';
import TextComponent from '../../../common/text-component/text-component';

export function IntegrationSettings(props: IntegrationSettingsProps) {
  const [isIntegrationSettingsOpen, setIsIntegrationSettingsOpen] = useState(false);
  const resetTags = useRef<() => {}>();
  const { t } = useTranslation();
  const integrations = useSelector((state: RootState) => state.integrations.data);
  const [selectedIntegrations, setSelectedIntegrations] = useState<MultiValue<DropdownDataItem>>([]);
  const [integrationError, setIntegrationError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getFormIntegration = async () => {
      const response = await getAllIntegrations(props.formId);
      const res = response.items.filter(item => item.associatedForms && item.associatedForms.length > 0)
      const data = res.map((item) => {
        let data: DropdownDataItem = {
          label: item.displayName,
          value: item.id
        }
        return data;
      });
      setSelectedIntegrations(data);
    };
    getFormIntegration();
  }, []);

  useEffect(() => {
    if (resetTags.current) resetTags.current();
    if (props.isActionPanelOpen === false) {
      setIsIntegrationSettingsOpen(false);
    }
  }, [props.isActionPanelOpen]);

  const checkAssociatedForms = (value: MultiValue<DropdownDataItem> | SingleValue<DropdownDataItem>) => {
    const data = value as MultiValue<DropdownDataItem>;
    data.forEach(ele => {
      const integ = integrations.find(val => val.id === ele.value);
      if (integ && integ.type === 'GOOGLE_SHEETS' && integ.associatedFormsCount && integ.associatedFormsCount > 0) {
        setIntegrationError(`${t("integration_exists")}`);
        return;
      } else {
        setIntegrationError('');
      }
    })
  }

  return (
    <Accordion
      onAccordionClick={() => setIsIntegrationSettingsOpen((prevState) => !prevState)}
      btn_content={t("integrations")}
      openState={isIntegrationSettingsOpen}
    >
      <div className={styles.accordionContent}>
        <div style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}>
          <TextComponent textType='Header'>
            {/* @TODO in future this font color also will be moved to global */}
            <div>{t("select_integrations")}</div>
          </TextComponent>
          <CustomSelect
            data={integrations && integrations.map((elem) => {
              let data: DropdownDataItem = {
                label: elem.displayName,
                value: elem.id
              }
              return data;
            })}
            isMultiSelect={true}
            selectStyleType={"colored"}
            selectedData={(value) => {
              checkAssociatedForms(value);
              if (value != null && Array.isArray(value)) {
                setSelectedIntegrations(value);
                props.setUploadPayload((prev) => { return { ...prev, integrationIds: value.map((item) => item.value) } });
                if (props.errors.size === 0) {
                  props.setIsValuesDirty(true);
                }
              }
            }}
            value={selectedIntegrations}
            placeholder={`${t("select_integrations")}`}
          />
          <div className={styles.infoText}>{`${t("create_integration")}`}<a style={{ color: '#18EB04' }} onClick={() => navigate(`../integrations`)}>{t("here")}</a></div>
          <div className={styles.errorText}>
            {
              integrationError && selectedIntegrations.length > 0 &&
              <span>{integrationError}</span>
            }
          </div>
        </div>
      </div>
    </Accordion>
  )
}
