import styles from './logic-builder-pane.module.scss';
import { LogicBuilderPaneProps } from './models/logic-builder-pane.interface';
import { ConditionalLogicCreate } from '../conditional-logic-create/conditional-logic-create';
import { ConditionalLogicList } from '../conditional-logic-list/conditional-logic-list';
import { useCreateLogic } from '../conditional-logic-create/hooks/use-create-logic';

export function LogicBuilderPane(props: LogicBuilderPaneProps) {

  const createLogic = useCreateLogic(props.controls, props.logicBuilder);

  return (
    <div className={styles.container}>
      <ConditionalLogicCreate createLogic={createLogic} controls={props.controls} logicBuilder={props.logicBuilder} srcControls={props.srcControls} targetControls={props.targetControls} />
      <div className={styles.break}></div>
      <ConditionalLogicList createLogic={createLogic} conditionalLogic={props.conditionalLogic} logicControlLookup={props.logicControlLookup} logicBuilder={props.logicBuilder} />
    </div>
  )
}
