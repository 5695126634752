import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { fetchFormList, fetchFormResponseAnalytics, fetchResponseList, fetchFormResponseWithQuestion, fetchResponsesID, getFileResponses, deleteAttachment } from "../../api/form-response";
import { QueryParams, FormListResponseDto, GetFormResponseItemDto, GetFormResponseWithQuestions, GetAllResponseID } from "../models/form-response.interface";
import { GetFileItemsDto } from "../models/form.interface";


export const fetchFormListThunk = createAsyncThunk(
  "formResponse/fetchFormList",
  async (params?: QueryParams) => {
    let response: AxiosResponse<{ formList: FormListResponseDto[], totalResponseCount: number, formsCount: number }> = await fetchFormList(params);
    return response.data;
  }
)

export const fetchResponseListThunk = createAsyncThunk(
  'formResponse/fetchResponseList',
  async (params: { formId: string, startDate?: Date, endDate?: Date, searchText?: string, skip?: number, take?: number }) => {
    let response: AxiosResponse<{ data: { FormResponses: GetFormResponseItemDto[], hasFile: boolean }, totalCount: number }> = await fetchResponseList(params);
    return response.data;
  }
)


export const fetchResponseAnalytics = createAsyncThunk(
  "formResponse/fetchResponseAnalytics",
  async (params: { formId: string }) => {
    let response: any = await fetchFormResponseAnalytics(params);
    return response.data;
  }
)


export const fetchResponseListWithQuestionThunk = createAsyncThunk(
  'formResponse/fetchResponseListWithQuestion',
  async (params: { formId: string, skip?: number, take?: number }) => {
    let response: AxiosResponse<GetFormResponseWithQuestions> = await fetchFormResponseWithQuestion(params);
    return response.data;
  }
)

export const fetchFileResponseListByWorkspaceThunk = createAsyncThunk(
  'formResponse/fetchFileResponseListByWorkspaceThunk', async (params: { skip?: number, take?: number }) => {
    let response: AxiosResponse<GetFileItemsDto[]> = await getFileResponses(params.skip, params.take);
    return response;
  }
)

export const deleteFileThunk = createAsyncThunk(
  'formResponse/deleteFileThunk', async (params: { mediaId: string, attachmentId: string }) => {
    await deleteAttachment(params.mediaId, params.attachmentId);
    return params;
  }
)

export const fetchAllResponsesID = createAsyncThunk('formResponse/fetchAllResponsesID', async (params: { formId: string }) => {
  let response: GetAllResponseID[] = await fetchResponsesID(params.formId);
  return response;
})
