import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../models/load-state.enum";
import { GetIntegrationsRespItem } from "../models/settings/integrations/get-integrations.interface";
import { createIntegrationsThunk, deleteIntegrationThunk, getAllIntegrationsThunk, updateIntegrationThunk } from "../thunks/integrations.thunk";
import { RootState } from "../store";

const integrationInitialState: {
  data: GetIntegrationsRespItem[];
  fetchLoadingState: LoadState;
} = { data: [], fetchLoadingState: LoadState.IDLE };

export const integrationsSlice = createSlice({
  name: "integration",
  initialState: integrationInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllIntegrationsThunk.pending, (state) => {
        state.fetchLoadingState = LoadState.LOADING;
      })
      .addCase(getAllIntegrationsThunk.fulfilled, (state, action) => {
        const res = action.payload.items;
        state.data = [...res];
        state.fetchLoadingState = LoadState.IDLE;
      })
      .addCase(getAllIntegrationsThunk.rejected, (state) => {
        state.fetchLoadingState = LoadState.ERROR;
      })
      .addCase(createIntegrationsThunk.pending, (state) => {
        state.fetchLoadingState = LoadState.LOADING;
      })
      .addCase(createIntegrationsThunk.fulfilled, (state, action) => {
        state.fetchLoadingState = LoadState.IDLE;
      })
      .addCase(createIntegrationsThunk.rejected, (state) => {
        state.fetchLoadingState = LoadState.ERROR;
      })
      .addCase(deleteIntegrationThunk.pending, (state) => {
        state.fetchLoadingState = LoadState.LOADING;
      })
      .addCase(deleteIntegrationThunk.fulfilled, (state, action) => {
        state.fetchLoadingState = LoadState.IDLE;
      })
      .addCase(deleteIntegrationThunk.rejected, (state) => {
        state.fetchLoadingState = LoadState.ERROR;
      })
      .addCase(updateIntegrationThunk.pending, (state) => {
        state.fetchLoadingState = LoadState.LOADING;
      })
      .addCase(updateIntegrationThunk.fulfilled, (state, action) => {
        state.fetchLoadingState = LoadState.IDLE;
      })
      .addCase(updateIntegrationThunk.rejected, (state) => {
        state.fetchLoadingState = LoadState.ERROR;
      })
  }
});

export const getAllIntegrationsSelector = (state: RootState) => {
  return state.integrations.data;
}

export default integrationsSlice;
