import { Fragment, useEffect, useRef, useState } from 'react'
import styles from './storage-manager.module.scss'
import SecondaryTopBar from '../../components/v2/common/secondary-top-bar/secondary-top-bar';
import TextComponent from '../../components/v2/common/text-component/text-component';
import { useTranslation } from 'react-i18next';
import svgDelete from "../../assets/v2/delete.svg";
import CustomButton from '../../components/v2/common/custom-button/custom-button';
import ToolTip from '../../components/v2/common/tool-tip/tool-tip';
import FileControl from '../../components/form-response/controls/file-control/file-control';
import { useSelector, useDispatch } from 'react-redux';
import { RootDispatch, RootState } from '../../store/store';
import { deleteFileThunk, fetchFileResponseListByWorkspaceThunk } from '../../store/thunks/form-responses.thunk';
import { GetFileItemsDto } from '../../store/models/form.interface';
import Modal from '../../components/v2/common/modal/modal';
import { clearFileResponse } from '../../store/slices/form-respone.slice';
import NoContent from '../../components/v2/common/no-content/no-content';
import hasSufficientScopes from '../../util/access-validator/access-validator';
import toast from 'react-hot-toast';
import CustomCheckbox from '../../components/v2/common/custom-checkbox/custom-checkbox';

function StorageManager() {

  const { t } = useTranslation();
  const tableRef = useRef<HTMLDivElement>(null);
  const workspaceFileResponses = useSelector((state: RootState) => state.formResponses.workspaceFileResponses)
  const dispatch: RootDispatch = useDispatch();
  const [deletePayload, setDeletePayload] = useState<GetFileItemsDto>();
  const [showModal, setShowModal] = useState(false);
  const [batchDeleteItems, setBatchDeleteItems] = useState<Record<string, GetFileItemsDto>>({});

  useEffect(() => {
    init();

    return () => {
      dispatch(clearFileResponse())
    }
  }, [])


  async function init() {
    if (workspaceFileResponses.length === 0)
      await dispatch(fetchFileResponseListByWorkspaceThunk({ skip: workspaceFileResponses.length, take: 15 }));
  }

  async function deleteMedia(response?: GetFileItemsDto) {

    if (Object.keys(batchDeleteItems).length > 0) {
      const deleteAllPromise = [];
      for (const key in batchDeleteItems) {
        const mediaId = batchDeleteItems[key].fileId.split('/');
        deleteAllPromise.push(dispatch(deleteFileThunk({ mediaId: mediaId[2], attachmentId: batchDeleteItems[key].id })));
      }
      toast.promise(Promise.all(deleteAllPromise), {
        loading: t("storage_manager_delete_file"),
        success: <b>{t("storage_manager_delete_files_successfully", { count: Object.keys(batchDeleteItems).length })}</b>,
        error: <b>{t("storage_manager_delete_file_error")}</b>,
      });
      setBatchDeleteItems({});
      setShowModal(false);
      return;
    }

    if (!response) return;
    const temp = response.fileId.split('/');
    try {
      await dispatch(deleteFileThunk({ mediaId: temp[2], attachmentId: response.id }));
      toast.success(t("storage_manager_delete_file_successful"))
    } catch (error) { }
    setShowModal(false);
  }

  const onScroll = async () => {
    if (tableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
      const isBottom = scrollTop + clientHeight >= scrollHeight;

      if (isBottom) {
        await dispatch(fetchFileResponseListByWorkspaceThunk({ skip: workspaceFileResponses.length, take: 10 }));
      }
    }
  }

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal}
        header={
          <div>{t("storage_manager_delete_modal_confirmation")}</div>
        }
        content={
          <div style={{ display: "flex", gap: "1rem" }}>
            <CustomButton
              buttonType='button'
              classType='secondary'
              onClick={() => {
                if (deletePayload || Object.keys(batchDeleteItems).length > 0) deleteMedia(deletePayload)
              }}>
              <div style={{ color: "#000" }}>{t("yes")}</div>
            </CustomButton>
            <CustomButton
              buttonType='button'
              classType='quaternary'
              onClick={() => { setShowModal(false) }}>
              <div>{t("no")}</div>
            </CustomButton>
          </div>
        }
      />
      <SecondaryTopBar>
        <div className={styles.titleContainer} style={{ userSelect: 'none' }}>
          <TextComponent textType='Content'>
            <div style={{ padding: '0.5rem' }}>{t("storage_manager_heading_content")}</div>
          </TextComponent>
          <div>
            {
              Object.keys(batchDeleteItems).length > 0 &&
              <ToolTip content={t("storage_manager_delete_file_tooltip")} position={'bottom'}>
                <CustomButton
                  disabled={!hasSufficientScopes(["storage:delete", "storage:delete.all"])}
                  buttonType='button'
                  classType='quaternary'
                  onClick={async () => {
                    setShowModal(true)
                  }}>
                  <TextComponent textType='Info'>
                    <div>{t("storage_manager_delete_all")}</div>
                  </TextComponent>
                </CustomButton>
              </ToolTip>
            }
          </div>
        </div>
      </SecondaryTopBar>
      <div className={styles.mainContainer} ref={tableRef} onScroll={onScroll}>
        {
          workspaceFileResponses.length > 0 ?
            <>
              <div className={'tableWrapper'}>
                <div className={'tableHeaderRow'}>
                  <div className={'tableData'} style={{ flex: '1 1 10%' }} >
                    <CustomCheckbox
                      isInitialChecked={workspaceFileResponses.length === Object.keys(batchDeleteItems).length}
                      data={''}
                      onCheckChange={() => {
                        if (workspaceFileResponses.length === Object.keys(batchDeleteItems).length) {
                          setBatchDeleteItems({});
                          return;
                        }
                        const temp = batchDeleteItems;
                        for (const file of workspaceFileResponses) {
                          temp[file.id] = file;
                        }
                        setBatchDeleteItems({ ...temp });
                      }}
                    />
                  </div>
                  <div className={'tableData'}>{t("storage_manager_file_name")}</div>
                  <div className={'tableData'}>{t("storage_manager_file_type")}</div>
                  <div className={'tableData'}>{t("storage_manager_file_size")}</div>
                  <div className={'tableData'}>{t("storage_manager_form_name")}</div>
                  <div className={'tableData'}>{t("storage_manager_file_preview")}</div>
                  <div className={'tableData'}>{`${t("actions")}`}</div>
                </div>
                {
                  workspaceFileResponses && workspaceFileResponses.length > 0 &&
                  workspaceFileResponses.map((response) => (
                    <div key={response.id} className={'tableRow'}>
                      <div className={'tableData'} style={{ flex: '1 1 10%' }}>
                        <CustomCheckbox
                          isInitialChecked={batchDeleteItems[response.id] !== undefined}
                          data={''}
                          onCheckChange={() => {
                            if (batchDeleteItems[response.id]) {
                              const temp = batchDeleteItems;
                              delete temp[response.id];
                              setBatchDeleteItems({
                                ...temp
                              });
                              return;
                            }
                            setBatchDeleteItems({
                              ...batchDeleteItems,
                              [response.id]: response
                            })
                          }}
                        />
                      </div>
                      <div className={'tableData'}>{response.originalName}</div>
                      <div className={'tableData'}> {response.mimeType}</div>
                      <div className={'tableData'}> {Math.round(response.fileSize / 1000)} Kb</div>
                      <div className={'tableData'}> {response.formName ? response.formName : "-"}</div>
                      <div className={'tableData'}>
                        {
                          <>
                            <FileControl value={[response]} />
                          </>
                        }
                      </div>
                      <div className={`${'tableData'} ${styles.actionContainer}`}>
                        <ToolTip content={t("storage_manager_delete_file_tooltip")} position={'bottom'}>
                          <CustomButton
                            style={{
                              background: "var(--accent-color)",
                              padding: " 12px",
                              borderRadius: '50%',
                              border: 'none',
                              boxSizing: "border-box"
                            }}
                            disabled={!hasSufficientScopes(["storage:delete", "storage:delete.all"])}
                            buttonType='button'
                            classType='tertiary'
                            onClick={async () => {
                              setDeletePayload(response)
                              setShowModal(true)
                            }}>
                            <div className={styles.isImageHolder}>
                              <img style={{ width: "1.2rem" }} src={svgDelete} alt="filter" />
                            </div>
                          </CustomButton>
                        </ToolTip>
                      </div>
                    </div>
                  ))
                }
              </div>
              <div className={'mobileContainer'}>
                {
                  workspaceFileResponses.map((item) => {
                    return (
                      <Fragment key={item.id}>
                        <div className={'mobileCard'}>
                          <div className={'rowOne'}>
                            <TextComponent textType='Content'>
                              <b>{item.originalName}</b>
                            </TextComponent>
                          </div>
                          <div className={'rowFour'}>
                            <div className={'line'}></div>
                          </div>
                          <div className={'rowOne'}>
                            <TextComponent textType='Content'>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <b>{t("storage_manager_form_name")}</b>
                                {item.formName}
                              </div>
                            </TextComponent>
                          </div>
                          <div className={'rowOne'}>
                            <TextComponent textType='Content'>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <b>{t("storage_manager_file_size")}</b>
                                {Math.round(item.fileSize / 1000)} Kb
                              </div>
                            </TextComponent>
                            <CustomButton
                              style={{
                                background: "var(--accent-color)",
                                padding: " 12px",
                                borderRadius: '50%',
                                border: 'none',
                                boxSizing: "border-box",
                                width: "fit-content"
                              }}
                              disabled={!hasSufficientScopes(["storage:delete", "storage:delete.all"])}
                              buttonType='button'
                              classType='tertiary'
                              onClick={() => {
                                setDeletePayload(item);
                                setShowModal(true);
                              }}
                            >
                              <div className={styles.isImageHolder}>
                                <img style={{ width: "1.2rem" }} src={svgDelete} alt="filter" />
                              </div>
                            </CustomButton>
                          </div>
                        </div>
                      </Fragment>
                    )
                  })
                }
              </div>
            </> :
            <NoContent content={t("storage_manager_no_content")} />
        }
      </div>
    </>
  )
}

export default StorageManager
