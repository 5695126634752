import axios from 'axios';
import { GenerateApiUrl } from './generate-api-url';

export async function getTimeTrackData(responseId: string) {
  let apiUrl: string = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `time-track/${responseId}`, true);
  try {
    const response = await axios.get<GetTimeTrackResDto>(apiUrl);
    return response.data;
  } catch (err) {
    return undefined;
  }
}

export interface GetTimeTrackResDto {
  id: string;
  startTime: Date;
  endTime: Date;
  duration: number;
  controls: {
    [controlId: string]: {
      duration: number;
      type: string;
    }
  };
}
