import { createSlice } from "@reduxjs/toolkit";
import { ConditionalEdge, StoreConditionalEdge } from "../models/form-builder/conditional-logic";
import { LoadState } from "../models/load-state.enum";
import { createConditionalLogicThunk, deleteConditionalLogicThunk, getAllConditionalLogicThunk, updateConditionalLogicThunk } from "../thunks/conditional-logic.thunk";
import { RootState } from "../store";

const conditionalLogicInitialState: {
  data: StoreConditionalEdge;
  loadingState: LoadState,
} = { data: {}, loadingState: LoadState.IDLE };

export const conditionalLogicSlice = createSlice({
  name: "conditionalLogic",
  initialState: conditionalLogicInitialState,
  reducers: {}, extraReducers(builder) {
    builder
      .addCase(getAllConditionalLogicThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(getAllConditionalLogicThunk.fulfilled, (state, action) => {
        const formId = action.payload.formId;
        const conditionalEdges = action.payload.conditionalEdges.map(val => new ConditionalEdge(val.id, val.createdAt, val.updatedAt, val.deletedAt, val.source, val.target, val.value, val.createdBy));
        state.data = { ...state.data, [formId]: conditionalEdges };
      })
      .addCase(getAllConditionalLogicThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
      .addCase(createConditionalLogicThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(createConditionalLogicThunk.fulfilled, (state, action) => {
        const formId = action.payload.formId;
        let conditionalEdges = state.data[formId] ? state.data[formId] : [];
        const conditionalEdge = new ConditionalEdge(action.payload.id, action.payload.createdAt, action.payload.updatedAt, action.payload.deletedAt, action.payload.source, action.payload.target, action.payload.value, action.payload.createdBy);
        conditionalEdges = [...conditionalEdges, conditionalEdge];
        state.data = { ...state.data, [formId]: conditionalEdges }
      })
      .addCase(createConditionalLogicThunk.rejected, (state) => {
        state.loadingState = LoadState.ERROR;
      })
      .addCase(updateConditionalLogicThunk.pending, (state) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(updateConditionalLogicThunk.fulfilled, (state, action) => {
        const formId = action.payload.formId;
        const conditionalId = action.payload.conditionalLogicId;
        const payload = action.payload.data;
        const conditionalEdges = state.data[formId].map(val => {
          if (val.id === conditionalId) {
            if (payload.sourceControlId) val.source = payload.sourceControlId;
            if (payload.targetControlId) val.target = payload.targetControlId;
            if (payload.value) val.value = payload.value;
          }
          return val;
        });
        state.data = { ...state.data, [formId]: conditionalEdges }
      })
      .addCase(updateConditionalLogicThunk.rejected, (state) => {
        state.loadingState = LoadState.ERROR;
      })
      .addCase(deleteConditionalLogicThunk.pending, (state) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(deleteConditionalLogicThunk.fulfilled, (state, action) => {
        const formId = action.payload.formId;
        const conditionalId = action.payload.conditionalLogicId;
        const conditionalEdges = state.data[formId];
        state.data = { ...state.data, [formId]: conditionalEdges.filter(val => val.id !== conditionalId) };
      })
      .addCase(deleteConditionalLogicThunk.rejected, (state) => {
        state.loadingState = LoadState.ERROR;
      })
  },
});

export const getConditionalLogicSelector = (state: RootState, formId: string) => {
  return state.conditionalLogic.data[formId];
};

export default conditionalLogicSlice;
