import { useEffect, useState } from 'react';
import { downloadAttachment } from '../../../../api/form-response';
import { triggerDownload } from '../../../../util/util';
import CustomButton from '../../../v2/common/custom-button/custom-button';
import styles from './file-card.module.scss';
import svgAudio from "../../../../assets/audio.svg";
import svgBtnSpinner from "../../../../assets/btn-spinner.svg";
import svgDocument from "../../../../assets/document.svg";
import svgExcel from "../../../../assets/excel.svg";
import svgImage from "../../../../assets/image.svg";
import svgPdf from "../../../../assets/REPLACE-THESE/pdf.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgPpt from "../../../../assets/ppt.svg";
import svgVideo from "../../../../assets/video.svg";
import svgZip from "../../../../assets/zip.svg";
import svgUnknownFile from "../../../../assets/unknown-file.svg";
import svgDownloadDark from "../../../../assets/v2/icons/download-dark.svg";

export default function FileCard(props: fileControlProps) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);

  let fileTypeIcon = svgUnknownFile;
  if (props && props.mimeType) {
    switch (true) {
      case (props.mimeType.includes('image/')):
        fileTypeIcon = svgImage;
        break;
      case (props.mimeType.includes('video/')):
        fileTypeIcon = svgVideo;
        break;
      case (props.mimeType.includes('audio/')):
        fileTypeIcon = svgAudio;
        break;
      case (props.mimeType.includes('pdf')):
        fileTypeIcon = svgPdf;
        break;
      case (props.mimeType.includes('excel')):
        fileTypeIcon = svgExcel;
        break;
      case (props.mimeType.includes('.presentation')):
        fileTypeIcon = svgPpt;
        break;
      case (props.mimeType.includes('zip') || props.mimeType.includes('compressed') || props.mimeType.includes('.rar')):
        fileTypeIcon = svgZip;
        break;
      case (props.mimeType.includes('msword')):
        fileTypeIcon = svgDocument;
        break;
      default:
        fileTypeIcon = svgUnknownFile;
        break;
    }
  }

  useEffect(() => {
    downloadThumbnail();
  }, []);

  async function downloadThumbnail() {
    const { fileId, originalName, mimeType } = props;

    // If the file isn't an image, ignore fetching thumbnail.
    if (!mimeType.includes('image/')) return;

    // fileId is of format "media/my-org-02/efa5cdfb-9de4-46ca-a92d-e5e20e7f659b"
    // Thumbnail image file would have the name "media/my-org-02/efa5cdfb-9de4-46ca-a92d-e5e20e7f659b-thumnail".
    const thumbnailFileName = `${fileId}-thumbnail`;
    setIsImageLoading(true);
    try {
      const res = await downloadAttachment(thumbnailFileName);
      const blob = res && new Blob([res.data], { type: "image/png" });
  
      const url = URL.createObjectURL(blob);
  
      const img = document.createElement('img');
      img.setAttribute('src', url)
      img.setAttribute('class', styles.thumbNailImg)
  
      const ele = document.getElementById(props.fileId);
      if (ele) {
        ele.innerHTML = '';
        ele.append(img);
      }
    } catch (error) {}
    setIsImageLoading(false);
  }

  async function handleDownload() {
    const { fileId, originalName, mimeType } = props;

    setIsDownloading(true);
    try {
      const res = await downloadAttachment(fileId);
      const blob = new Blob([res.data], { type: mimeType })
      triggerDownload(blob, originalName);
    } catch (error) {}
    setIsDownloading(false);
  }

  const isImage = /image\/*/.test(props.mimeType);
  const isVideo = /video\/*/.test(props.mimeType);

  return (
    <>
      <div className={styles.container}>
        {
          (isImage || isVideo) ?
            <>
              <div className={styles.imageWrapper} id={props.fileId} onClick={() => props.handleClick()}>
                <img
                  src={fileTypeIcon}
                  className={`${styles.thumbNailIcon} ${styles.clickable} ${isImageLoading ? styles.isThumbNailLoading : ''}`}
                  alt=""
                />
              </div>
              <div className={`${styles.fileName} ${styles.clickable}`} onClick={() => props.handleClick()}> {props.originalName} </div>
            </> :
            <>
              <div>
                <img src={fileTypeIcon} className={styles.thumbNailIcon} alt="" />
              </div>
              <div className={styles.fileName}>{props.originalName}</div>
            </>
        }
        <div className={styles.downloadBtnWrapper}>
          {isDownloading ?
            <img src={svgBtnSpinner} className={styles.downloading} alt="" />
            :
            <CustomButton
              style={{
                backgroundColor: "var(--accent-color)",
                width: "2.5rem",
                height: "2.5rem",
                padding: "9px",
              }}
              buttonType='reset'
              classType='primary'
              onClick={() => {
                handleDownload()
              }}
            >
              <img style={{ width: "1.1rem" }} src={svgDownloadDark} alt="download" />
            </CustomButton>
          }
        </div>
      </div>
    </>
  )
}

export interface fileControlProps {
  fileId: string;
  originalName: string;
  mimeType: string;
  thumbnail?: string;
  handleDownload: Function;
  handleClick: Function;
}
