import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAllTemplates } from "../../api/templates";

export const fetchAllTemplatesThunk = createAsyncThunk(
  "forms/fetchAllTemplates",
  async (filters?: { tags?: string, name?: string }) => {
    let response = await fetchAllTemplates(filters);
    return response.data.templates;
  }
)



