import { useEffect, useId, useRef } from 'react'
import bulmaCalendar from 'bulma-calendar';
import { CustomCalendarProps } from './custom-calendar.props';
import styles from './custom-date.module.scss';

export default function CustomCalender(props: CustomCalendarProps) {
  const containerId = useId();
  const id = useId();

  const dateRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown',(event)=>{
      if (dateRef.current && !dateRef.current.contains(event.target as Node)) {
        const comp = document.getElementById(id);
        const bul: bulmaCalendar = (comp as any).bulmaCalendar;
        bul.hide();
      }
    })
    return () => {
      document.removeEventListener('mousedown',()=>{});
    }
  }, [])

  const closeButton = () => {
    const comp = document.getElementById(id);
    const bul: bulmaCalendar = (comp as any).bulmaCalendar;
    if (!bul) return;
    bul.hide();
  }

  useEffect(() => {
    const comp = document.getElementById(id);
    const bul: bulmaCalendar = (comp as any).bulmaCalendar;
    if (!bul) return;
    if (props.value) {
      bul.startDate = new Date(props.value);
    } else {
      bul.clear();
    }
    bul.save();
  }, [props.value]);

  useEffect(() => {
    bulmaCalendar.attach(`[id="${id}"]`, {
      type: 'date', showHeader: false, validateLabel: 'Submit', startDate: props.value ? new Date(props.value) : undefined
    });

    const comp = document.getElementById(id);
    const bul: bulmaCalendar = (comp as any).bulmaCalendar;
    bul.on('hide', (value) => {
      props.selectedDate(value.data.value());
    });
    bul.on('show', (value) => {
      if (props.onShow) props.onShow();
    });

    // custom implementation to hide element during clear
    const container = document.getElementById(containerId);
    const onClickClear = () => { bul.clear(); bul.hide(); bul.save(); }
    const onClickCancel = () => {
      bul.hide();
    }
    if (container) {
      const clearBtnFtr = container.querySelector('.datetimepicker-footer-clear');
      if (clearBtnFtr) clearBtnFtr.addEventListener('click', onClickClear);
      const clearBtn = container.querySelector('.datetimepicker-clear-button');
      if (clearBtn) clearBtn.classList.add(styles.displayNone);
      const cancelBtnFtr = container.querySelector('.datetimepicker-footer-cancel');
      if (cancelBtnFtr) cancelBtnFtr.addEventListener('click', onClickCancel);
    }

    if (props.hideDateDialog) props.hideDateDialog.current = closeButton;

    return () => {
      const container = document.getElementById(containerId);
      if (container) {
        const clearBtnFtr = container.querySelector('.datetimepicker-footer-clear');
        if (clearBtnFtr) clearBtnFtr.removeEventListener('click', onClickClear);
        const cancelBtnFtr = container.querySelector('.datetimepicker-footer-cancel');
        if (cancelBtnFtr) cancelBtnFtr.removeEventListener('click', onClickCancel);
      }
    }
  }, []);

  return (
    <div id={containerId} ref={dateRef}>
      <input type={props.type ? props.type : 'date'} id={id} disabled={props.disabled} />
    </div>
  )
}

