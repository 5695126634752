import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateConditionalEdge } from "../models/form-builder/conditional-logic";
import { createConditionalLogic, deleteConditionalLogic, getAllConditionalLogic, updateConditionalLogic } from "../../api/conditional-logic";

export const createConditionalLogicThunk = createAsyncThunk(
  'conditionalLogic/createConditionalLogic',
  async (params: { formId: string, payload: CreateConditionalEdge }) => {
    let response = await createConditionalLogic(params.formId, params.payload);
    return { formId: params.formId, ...response };
  }
)

export const getAllConditionalLogicThunk = createAsyncThunk(
  'conditionalLogic/getAllConditionalLogic',
  async (params: { formId: string }) => {
    let response = await getAllConditionalLogic(params.formId);
    return { formId: params.formId, ...response };
  }
)

export const updateConditionalLogicThunk = createAsyncThunk(
  'conditionalLogic/updateConditionalLogic',
  async (params: { formId: string, conditionalLogicId: string, payload: Partial<CreateConditionalEdge> }) => {
    await updateConditionalLogic(params.formId, params.conditionalLogicId, params.payload);
    return { formId: params.formId, conditionalLogicId: params.conditionalLogicId, data: params.payload };
  }
)

export const deleteConditionalLogicThunk = createAsyncThunk(
  'conditionalLogic/deleteConditionalLogic',
  async (params: { formId: string, conditionalLogicId: string }) => {
    await deleteConditionalLogic(params.formId, params.conditionalLogicId);
    return { formId: params.formId, conditionalLogicId: params.conditionalLogicId };
  }
)
