import styles from './accordion.module.scss';
import { useEffect, useState } from "react";
import CustomButton from "../custom-button/custom-button";
import { AccordionProps } from './accordion.props';
import svgDownArrow from "../../../../assets/v2/icons/down-arrow.svg";

export function Accordion(props: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (props.openState !== undefined) setIsOpen(props.openState);
  }, [props.openState]);

  return (
    <>
      <CustomButton
        onClick={() => {
          setIsOpen(!isOpen);
          if (props.onAccordionClick) props.onAccordionClick();
          if (isOpen && props.onAccordionOpen) props.onAccordionOpen();
          if (!isOpen && props.onAccordionClose) props.onAccordionClose();
        }}
        classType={props.btn_classType ? props.btn_classType : 'secondary'}
        buttonType={props.btn_buttonType ? props.btn_buttonType : 'button'}
        isLoading={props.btn_isLoading}
        disabled={props.btn_disabled}
        style={props.btn_style ? props.btn_style : { boxShadow: 'none' }}
      >
        {
          props.btn_children
            ? props.btn_children
            : <div className={styles.btnContainer}>
              <div>{props.btn_content}</div>
              <img className={`${styles.arrow} ${isOpen ? styles.arrowUp : ''}`} src={svgDownArrow} alt="" />
            </div>
        }
      </CustomButton>
      <div className={`${styles.content} ${isOpen === true ? styles.contentOpen : ''}`}>
        {props.children}
      </div>
    </>
  )
}
