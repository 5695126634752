export const IndustryTypes = [
  "Agriculture",
  "Chemical",
  "E-commerce",
  "Construction",
  "Education",
  "Financial services",
  "Food and Beverages",
  "Health services",
  "Travel and Tourism",
  "Media and culture",
  "Telecommunication",
  "Public services",
  "Textiles",
  "Transport"
]

export const CompanyPopulation = [
  "10-99",
  "100-200",
  "200+"
]
