import React from "react";

export interface CustomTooltipProps {
  content: string;
  delay?: number;
  children?: React.ReactNode
  direction: Direction;
  styles?: React.CSSProperties;
}


export enum Direction {
  TOP = 'TOP',
  RIGHT = 'RIGHT',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT'
}
