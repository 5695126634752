import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { activateUser, checkIfUserExists, resendAccountVerification } from '../../../../api/sign-up';
import { Captcha } from '../../../../components/v2/common/captcha/captcha';
import CustomText from '../../../../components/v2/common/custom-bodyText/custom-text';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import CustomHeading from '../../../../components/v2/common/custom-heading/custom-heading';
import CustomInput from '../../../../components/v2/common/custom-input/custom-input';
import OtpInput from '../../../../components/v2/common/otp-input/otp-input';
import { CreateAccountRespDto, ResendVerificationReqDto } from '../../../../store/models/sign-up.interface';
import { UserActivationReqDto } from '../../../../store/models/user.interface';
import { RootState } from '../../../../store/store';
import PageLayout from '../page-layout/page-layout';
import styles from './validate-otp.module.scss';
import svgBusinessAndFinance from "../../../../assets/REPLACE-THESE/business-and-finance.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgEmail from "../../../../assets/REPLACE-THESE/email.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import CustomOtpInput from '../../../../components/v2/common/custom-otp-input/custom-otp-input';

export default function ValidateOtp() {
  const navigate = useNavigate();
  const [emailOtp, setEmailOtp] = useState("");
  const [mobileOtp, setMobileOtp] = useState("");
  const [mobileOtpError, setMobileOtpError] = useState<string>('');
  const [emailOtpError, setEmailOtpError] = useState<string>('');
  const { t } = useTranslation();
  const user: CreateAccountRespDto = useSelector((state: RootState) => state.user.createAccountData);
  const [userData, setUserData] = useState<{ userId: string, email: string, mobile: string }>({ userId: '', mobile: '', email: '' });
  const [isEmailNeeded, setIsEmailNeeded] = useState(false);
  const [emailWarningText, setEmailWarningText] = useState("");
  const [freezeResend, setFreezeResend] = useState(true);
  const [freezeTimeout, setFreezeTimeout] = useState(0);

  useEffect(() => {
    if (user.userId && user.email && user.mobileNumber) {
      setUserData({
        userId: user.userId,
        email: user.email,
        mobile: user.mobileNumber
      });
      setIsEmailNeeded(false);
    } else {
      setIsEmailNeeded(true);
    }

    const interval = setInterval(() => {
      setFreezeTimeout(prev => prev + 1);
    }, 1000);
    const timeout = setTimeout(() => {
      setFreezeResend(false);
      clearInterval(interval);
      setFreezeTimeout(0);
    }, 30000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    }
  }, []);

  // On submit function to submit data
  async function ValidateOtp() {
    const data: UserActivationReqDto = {
      email: userData.email,
      mobileNumber: userData.mobile,
      emailVerificationCode: emailOtp,
      mobileVerificationCode: mobileOtp
    }
    try {
      const response = await activateUser(data);
      if (response && response.isActive) {
        toast.success(t("verification_success"));
        navigate('/');
      }
    } catch (err) {
      toast.error(t("enter_valid_otp"));
    }
  }

  async function checkUserEmail(email: string): Promise<boolean> {
    const res = await checkIfUserExists({ email: email });
    if (res && res.isActive) {
      setEmailWarningText(t("account_already_activated"));
      return false;
    }
    return true;
  }

  async function resendOtp(emailValue: string) {
    if (await checkUserEmail(emailValue)) {
      const data: ResendVerificationReqDto = {
        email: emailValue
      };
      try {
        await resendAccountVerification(data);
        toast.success(t("eml_mob_otp_dispatched"));
        setIsEmailNeeded(false);
      } catch (err) {
        console.log("err....", err);
        toast.error(`${t("eml_not_exists")}`);
      }
    } else {
      setEmailWarningText(`${t("account_already_activated")}`);
    }
  }

  async function triggerResend() {
    if (userData.email) {
      await resendOtp(userData.email);
      setFreezeResend(true);
      const interval = setInterval(() => {
        setFreezeTimeout(prev => prev + 1);
      }, 1000);
      setTimeout(() => {
        setFreezeResend(false);
        clearInterval(interval);
        setFreezeTimeout(0);
      }, 30000);
    } else {
      setIsEmailNeeded(true);
    }
  }

  // Navigates to login screen if user tries to press back
  window.addEventListener("popstate", (e) => {
    navigate("/")
  });

  function isSubmitDisabled(): boolean {
    if (emailOtpError || mobileOtpError || emailOtp.length !== 6 || mobileOtp.length !== 6) {
      return true;
    } else {
      return false;
    }
  }

  function validateEmail(value: string) {
    const re = new RegExp(/^[a-z0-9+_.-]+@[a-z0-9.-]+\.[a-z]+$/);
    if (value.length < 1) {
      setEmailWarningText(`${t("sign_up_email_required")}`);
      return false;
    } else if (!re.test(value)) {
      setEmailWarningText(`${t("sign_up_email_invalid")}`);
      return false;
    }
    setEmailWarningText('');
    return true;
  }

  return (
    <>
      <Captcha />
      {
        <div className={styles.mainContainer}>
          <div className={styles.pageLayout}>
            <PageLayout />
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.login}>
              <CustomButton
                buttonType='button'
                classType='tertiary'
                onClick={() => {
                  navigate('/')
                }}
              ><CustomHeading
                headerType='h4'
              >
                  <div>{t("login")}</div>
                </CustomHeading>
              </CustomButton>
            </div>
            {
              isEmailNeeded ?
                <>
                  <div>
                    <CustomHeading
                      headerType='h1'
                    ><div>{t("account_validation")}</div></CustomHeading>
                    <CustomText
                      textType='t6'
                    ><div>{t("enter_eml_mob_verif_code")}</div></CustomText>
                  </div>
                  <CustomInput
                    inputStyleType='fade'
                    inputType='email'
                    value={userData.email}
                    onBlur={() => {
                      if (userData.email !== "" && userData.email != null) {
                        checkUserEmail(userData.email);
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      validateEmail(value);
                      setUserData((prevState) => ({
                        ...prevState,
                        email: value
                      }))
                    }}
                    placeHolder={`${t("feedback_application_enter_email")}`}
                  />
                  {
                    emailWarningText && (emailWarningText !== '') &&
                    <p className={styles.errorText}>{emailWarningText}</p>
                  }
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <CustomButton
                      buttonType='button'
                      onClick={() => {
                        navigate('/');
                      }}
                      style={{
                        width: "40%"
                      }}
                      classType={'secondary'}
                    >
                      <span style={{color:'var(--font-color)'}}>{`${t("go_back")}`}</span>
                    </CustomButton>
                    <CustomButton
                      buttonType='submit'
                      classType='quaternary'
                      disabled={(emailWarningText !== '') || !userData.email}
                      onClick={() => {
                        resendOtp(userData.email);
                      }}
                    >
                      <div className={styles.forgotBtn}>
                        <CustomHeading
                          headerType='h4'
                          style={{ color: "#ffff" }}
                        >
                          <div>{`${t("send_otp")}`}</div>
                        </CustomHeading>
                      </div>
                    </CustomButton>
                  </div>
                </> :
                <>
                  {
                    <>
                      {/* <span className={styles.verifyOtpTxtOne}>{`Hey ${userName},`}</span> */}
                      <span className={styles.verifyOtpTextTwo}>{t("verify_account")}</span>
                      <span className={styles.verifyOtpTextThree}>{t("verif_code_sent_mob")}</span>

                      <div className={styles.otpContainer}>
                        <div className={styles.emailIconContainer}>
                          <img src={svgEmail} alt="email-otp" style={{ width: "5rem" }}></img>
                        </div>
                        <CustomOtpInput
                          setOtp={setEmailOtp}
                        />
                      </div></>
                  }
                  {
                    emailOtpError !== '' ?
                      <span className={styles.errorText}>{emailOtpError}</span> :
                      <span>{window.sessionStorage.getItem("email")}</span>
                  }
                  <div className={styles.otpContainer}>
                    <div className={styles.mobileIconContainer} >
                      <img src={svgBusinessAndFinance} alt="mobile-otp" style={{ width: "4rem", padding: "10px" }}></img>
                    </div>
                    <CustomOtpInput
                      setOtp={setMobileOtp}
                    />
                  </div>
                  {
                    mobileOtpError !== '' ?
                      <span className={styles.errorText}>{mobileOtpError}</span> :
                      <span>{window.sessionStorage.getItem("mobile")}</span>
                  }
                  <div className={`${styles.alignResendOtp}`}>
                    {
                      freezeResend &&
                      <u className={styles.enableResend}>
                        {t("enable_resend_otp")}&nbsp;{30 - freezeTimeout}&nbsp;secs
                      </u>
                    }
                    &nbsp;
                    <u
                      onClick={() => triggerResend()}
                      className={`${styles.resendOtpText} ${freezeResend ? styles.freezeResend : ''}`}>
                      {t("resent_otp")}
                    </u>
                  </div>
                  <CustomButton
                    buttonType='submit'
                    classType='quaternary'
                    disabled={isSubmitDisabled()}
                    onClick={() => {
                      ValidateOtp()
                    }}
                  >
                    <span style={{color:'#fff'}}>{t("verify_create_acc")} &#8594;</span>
                  </CustomButton>
                </>

            }
          </div >
        </div >
      }
    </>
  );
}
