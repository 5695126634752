import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadState } from "../models/load-state.enum";
import { ProfileInfoRespDto } from "../models/profile.interface";
import { CreateAccountRespDto } from "../models/sign-up.interface";
import { GetInvitationsRespDto } from "../models/user.interface";
import { acceptInviteToOrganizationThunk, createAccountThunk, declineInviteToOrganizationThunk, getInvitationsThunk, getUserThunk } from "../thunks/user.thunk";

const userInitialState: {
  data: object;
  fetchLoadingState: LoadState;
  createAccountData: CreateAccountRespDto;
  loadingState: LoadState;
  userInvitations: GetInvitationsRespDto;
  profileInfo: ProfileInfoRespDto;
} = {
  data: {}, fetchLoadingState: LoadState.IDLE,
  createAccountData: {
    userId: '',
    displayName: '',
    orgName: '',
    email: '',
    mobileNumber: ''
  },
  loadingState: LoadState.IDLE,
  userInvitations: { userId: '', items: [] },
  profileInfo: { userId: '', createdAt: new Date(), metadata: {}, organizations: [] }
};

export const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    addUser(state, action) {
      state.data = action.payload;
    },
    setProfileInfo(state, action: PayloadAction<ProfileInfoRespDto>) {
      const profile = action.payload;
      state.profileInfo.userId = profile.userId;
      state.profileInfo.createdAt = profile.createdAt;
      state.profileInfo.metadata = profile.metadata;
      state.profileInfo.organizations = profile.organizations;
    },
  }, extraReducers(builder) {
    builder
      .addCase(createAccountThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(createAccountThunk.fulfilled, (state, action) => {
        let data = action.payload;
        state.createAccountData.userId = data.userId;
        state.createAccountData.displayName = data.displayName;
        state.createAccountData.orgName = data.orgName;
        state.createAccountData.email = data.email;
        state.createAccountData.mobileNumber = data.mobileNumber;
        state.loadingState = LoadState.IDLE;
      })
      .addCase(createAccountThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
      .addCase(getInvitationsThunk.fulfilled, (state, action) => {
        let data = action.payload;
        state.userInvitations = data;
      })
      .addCase(acceptInviteToOrganizationThunk.fulfilled, (state, action) => {
        const inviteId = action.payload.inviteId;
        for (let i of state.userInvitations.items) {
          if (i.id === inviteId) {
            i.status = 'ACCEPTED'
          }
        }
      })
      .addCase(declineInviteToOrganizationThunk.fulfilled, (state, action) => {
        const inviteId = action.payload.inviteId;
        for (let i of state.userInvitations.items) {
          if (i.id === inviteId) {
            i.status = 'DECLINED'
          }
        }
      })
      .addCase(getUserThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(getUserThunk.fulfilled, (state, action) => {
        const userId = action.payload.id;
        for (let i of state.userInvitations.items) {
          if (userId === i.createdByUserId) {
            i.createdByUserEmail = action.payload.email;
          }
        }
        state.loadingState = LoadState.IDLE;
      })
      .addCase(getUserThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
  },
});

export const { addUser, setProfileInfo } = userSlice.actions;

export default userSlice;
