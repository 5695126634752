import React from 'react'
import styles from './custom-radio.module.scss';
import { radioDataProps } from './custom-radio.props';

export default function CustomRadio(props: radioDataProps) {
  return (
    <>
      <label className={styles.container}>
        <input type="radio" value={props.data} disabled={props.disabled} />
        <span className={styles.checkmark}></span>
      </label>
    </>
  )
}
