import { useEffect, useState } from 'react'
import styles from './org-invitations.module.scss';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import svgRevokee from "../../../../assets/v2/revoke.svg";
import { useTranslation } from 'react-i18next';
import ToolTip from '../../../../components/v2/common/tool-tip/tool-tip';
import { OrgInvitationRowProps } from './org-invitation.props';

export default function OrgInvitationsRow(props: OrgInvitationRowProps) {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [invitedEmail, setInvitedEmail] = useState("");
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setInitialValues()
  }, [])

  const setInitialValues = async()=>{
    setName(await props.resolveInviteItem(props.inviteItem));
    setInvitedEmail(await props.getInvitedEmail(props.inviteItem));
    setLoader(false);
  }

  return (
    <>
      <div key={props.index} className={'tableRow'}>
        <div id="email" data-userid-email={props.inviteItem.userIdOrEmail} className={'tableData'}>{name || props.inviteItem.userIdOrEmail}</div>
        <div id="invited-by" data-userid-email={props.inviteItem.userIdOrEmail} className={'tableData'}>{loader ? "-" : invitedEmail}</div>
        <div id="invited-at" className={'tableData'}>{new Date(props.inviteItem.createdAt).toLocaleString()}</div>
        <div id="status" className={'tableData'}>{props.inviteItem.status}</div>
        <div id="action" className={`${'tableData'} ${styles.actionContainer}`}>
          <ToolTip content={`${t("revoke_invite")}`} position='bottom'>
            <CustomButton
              disabled={props.inviteItem.status === 'PENDING' ? false : true}
              style={{
                background: "var(--accent-color)",
                padding: " 12px",
                borderRadius: '50%',
                border: 'none',
                boxSizing: "border-box"
              }}
              buttonType='button'
              classType='tertiary'
              onClick={() => {
                props.setShowModal(true);
                props.setUserId(props.inviteItem.id)
              }}
            >
              <div className={styles.isImageHolder}>
                <img style={{ width: "1.2rem" }} src={svgRevokee} alt="filter" />
              </div>
            </CustomButton>
          </ToolTip>
        </div>
      </div>

    </>
  )
}
