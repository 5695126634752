import React, { useRef, useState } from 'react';
import styles from './add-user.module.scss';
import CustomText from '../../../../../components/v2/common/custom-bodyText/custom-text';
import CustomHeading from '../../../../../components/v2/common/custom-heading/custom-heading';
import CustomButton from '../../../../../components/v2/common/custom-button/custom-button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../../store/store';
import CustomSelect from '../../../../../components/v2/common/custom-select/custom-select';
import { DropdownDataItem } from '../../../../../components/v2/common/custom-select/custom-select.props';
import toast from 'react-hot-toast';
import { getOrgUsersThunk } from '../../../../../store/thunks/organization.thunk';
import { MultiValue } from 'react-select'
import { AxiosError } from 'axios';
import { inviteUserToOrg } from '../../../../../api/organization';
import { InviteUserReqDto } from '../../../../../store/models/organization.interface';
import svgPaneDelete from "../../../../../assets/v2/close.svg";
import { MultilineTags } from '../../../../../components/v2/common/multiline-tags/multiline-tags';

export default function AddUser(props: AddUserDto) {
  const dispatch: RootDispatch = useDispatch();
  const roles = useSelector((state: RootState) => state.roles.data);
  const [emailError, setEmailError] = useState('');
  const [privateEmails, setPrivateEmails] = useState<string[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<MultiValue<DropdownDataItem>>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [isCleared, setIsCleared] = useState(false);
  const [errors, setErrors] = useState("");
  const [emails, setEmails] = useState<string[]>([]);
  const [isDirtyFlags, setIsDirtyFlags] = useState({
    email: false,
    roles: false
  });

  const resetValues = () => {
    setIsCleared(true);
    setIsLoading(false);
    props.setIsSidePaneOpen(false);
    setEmails([]);
    setPrivateEmails([]);
    setSelectedRoles([]);
    setIsCleared(false);
  }

  const createUser = async () => {
    const emailValidation = validateEmail(emails);
    const rolesValidation = validateRoles(selectedRoles);
    if (emailValidation === false || rolesValidation === false) {
      return;
    }
    setIsLoading(true);
    try {
      const data: InviteUserReqDto = {
        email: emails,
        roleNames: selectedRoles.map((e) => e.value)
      };
      await inviteUserToOrg(data);
      await dispatch(getOrgUsersThunk()).unwrap();
      toast.success(t("users_added"));
    } catch (err) {
      if ((err as AxiosError).message == 'QUOTA_LIMIT_EXCEEDED') {
        toast.error(t("quota_exceeded_users"));
      } else {
        toast.error(t("users_error"));
      }
    } finally {
      resetValues();
    }
  }

  const validateEmail = (data: string[]) => {
    if (data.length === 0) {
      setEmailError(t("email_error"));
      return false;
    } else setEmailError("");
    return true;
  }

  const validateRoles = (data: MultiValue<DropdownDataItem>) => {
    if (data.length === 0) {
      setError(t("role_error"));
      return false;
    }
    else setError("");
    return true;
  }


  return (
    <div className={styles.sidepaneMain}>
      <div className={styles.topContainer}>
        <div className={styles.closeBtnContainer}>
          <div >
            <CustomHeading headerType='h5'>
              <div className={styles.textColor}>{t("add_user_heading")}</div>
            </CustomHeading>
            <CustomText textType='t5'>
              <div className={styles.textColor}>{t("add_user_subheading")}</div>
            </CustomText>
          </div>
          <div className={styles.mobileCloseBtn}>
            <button className={styles.closebtn} onClick={() => { props.setIsSidePaneOpen(false) }}>
              <img style={{width:'1rem'}} src={svgPaneDelete} alt="" />
            </button>
          </div>
        </div>
        <div className={styles.line}></div>
      </div>
      <div className={styles.inpuContainer}>
        <div>
          <CustomHeading headerType='h5'>
            <div className={styles.textColor}>{t("mail_ids")}</div>
          </CustomHeading>
          <div>
            <div className={styles.shareEmailContainer}>
              <div className={styles.mailContainer}>
                <MultilineTags type='email' value={emails} setErrors={setErrors} onChange={
                  (tags) => {
                    setIsDirtyFlags({ ...isDirtyFlags, email: true });
                    setEmails(tags);
                  }} />
                {errors ? <CustomText textType='t6' style={{ color: "red" }} >
                  <div>{t("enter_valid_email")}</div>
                </CustomText> : <></>}
              </div>
            </div>
          </div>
          <div className={styles.errorEmail}>{emailError}</div>
        </div>
        <div>
          <CustomHeading headerType='h5'>
            <div className={styles.textColor}>{t("select_role")}</div>
          </CustomHeading>
          <div className={styles.roleSelector}>
            <CustomSelect
              data={roles && roles.map((elem) => {
                let data: DropdownDataItem = {
                  label: elem.name,
                  value: elem.name,
                }
                return data;
              })}
              isMultiSelect={true}
              selectStyleType={"colored"}
              selectedData={(value) => {
                if (value && Array.isArray(value)) {
                  setIsDirtyFlags({ ...isDirtyFlags, roles: true });
                  setSelectedRoles(value);
                  if (value.length) setError("");
                }
              }}
              value={selectedRoles}
              placeholder={`${t("select_role")}`}
              isClearable={isCleared}
            />
          </div>
        </div>
        <div className={styles.errorEmail}>{error}</div>

        <div className={styles.saveBtn}>
          <CustomButton
            buttonType='button'
            classType='quaternary'
            isLoading={isLoading}
            disabled={emails.length === 0 || selectedRoles.length === 0}
            onClick={createUser}>
            {
              isLoading ?
                <>{t("loading")}</>
                :
                <CustomHeading headerType='h4' style={{ color: "#ffff" }}>
                  <div>{`${t("add_user")}`}</div>
                </CustomHeading>
            }
          </CustomButton>
        </div>

        <div className={styles.mobileCloseBtn}>
          <CustomButton
            buttonType='button'
            classType='secondary'
            isLoading={isLoading}
            disabled={emails.length === 0 || selectedRoles.length === 0}
            onClick={createUser}>
            {
              isLoading ?
                <>{t("loading")}</>
                :
                <CustomHeading headerType='h4' style={{ color: "#000" }}>
                  <div>{`${t("add_user")}`}</div>
                </CustomHeading>
            }
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

interface AddUserDto {
  setIsSidePaneOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
