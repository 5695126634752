import styles from './publish-panel.module.scss';
import { useState } from 'react';
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import CustomText from '../../common/custom-bodyText/custom-text';
import CustomButton from '../../common/custom-button/custom-button';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomLinksDto, Form, UpdateFormDto } from '../../../../store/models/form.interface';
import { dispatchCustomLinks } from '../../../../api/forms';
import { RootDispatch, RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { publishFormThunk, updateFormThunk } from '../../../../store/thunks/forms.thunk';
import hasSufficientScopes from '../../../../util/access-validator/access-validator';
import { getForm } from '../../../../store/slices/forms.slice';
import { MultilineTags } from '../../common/multiline-tags/multiline-tags';
import LinkContainer from '../../../../pages/v2/form-details/link-container/link-container';

export function PublishPanel() {
  const navigate = useNavigate();
  const dispatch: RootDispatch = useDispatch();
  const [isActive, setIsActive] = useState(true);
  const [errors, setErrors] = useState("");
  const [emails, setEmails] = useState<string[]>([]);
  const { t } = useTranslation();
  const { formId } = useParams();
  const form: Form | undefined = useSelector((state: RootState) => getForm(state, formId as string));

  const publishPrivateMode = async () => {
    if (errors) return;
    if (formId) {
      let updateForm: {
        formId: string;
        data: UpdateFormDto;
      } = { formId: '', data: {} };
      let publishState: {
        id: string;
      } = { id: '' };

      const checkUpdateScope = hasSufficientScopes(["forms:update", "forms:update.all"], form && form.createdBy);
      const payload: CustomLinksDto = {
        emails: emails,
        formId: formId
      };
      if (checkUpdateScope) {
        updateForm = await dispatch(updateFormThunk({
          formId: formId,
          data: {
            isPublic: false,
          }
        })).unwrap();
      } else {
        toast.error(t('form_details_edit_form_no_permission'));
      }

      const checkPublishScope = hasSufficientScopes(["forms:publish", "forms:publish.all"], form && form.createdBy);
      if (checkPublishScope) {
        publishState = await dispatch(publishFormThunk({
          id: formId,
        })).unwrap();
      } else {
        toast.error(t('form_details_publish_form_no_permission'));
      }

      const res = await dispatchCustomLinks(payload);

      if (checkUpdateScope && checkPublishScope) {
        if (updateForm && publishState && res === true) {
          toast.success(t("form_created_success_private"));
          navigate(`../forms/${formId}`);
        } else {
          toast.error(t("form_details_toast_email_sent_err"));
        }
      }
    }
  }

  const publishPublicMode = async () => {
    if (formId) {
      let updateForm: { formId: string; data: UpdateFormDto; } = { formId: '', data: {} };
      let publishState: { id: string; } = { id: '' };

      const checkUpdateScope = hasSufficientScopes(["forms:update", "forms:update.all"], form && form.createdBy);
      if (checkUpdateScope) {
        updateForm = await dispatch(updateFormThunk({
          formId: formId,
          data: {
            isPublic: true,
          }
        })).unwrap();
      } else {
        toast.error(t('form_details_edit_form_no_permission'));
      }

      const checkPublishScope = hasSufficientScopes(["forms:publish", "forms:publish.all"], form && form.createdBy);
      if (checkPublishScope) {
        publishState = await dispatch(publishFormThunk({
          id: formId,
        })).unwrap();
      } else {
        toast.error(t('form_details_publish_form_no_permission'));
      }

      if (checkUpdateScope && checkPublishScope) {
        if (updateForm && publishState) {
          navigate(`../forms/${formId}`);
          toast.success(t("form_created_success_public"));

        } else {
          toast.error(t("form_details_toast_email_sent_err"));
        }
      }
    }
  }

  return (
    <div className={styles.publishContainer}>
      <CustomText textType='t5' style={{
        color: "black",
        fontWeight: "500"
      }}>
        <div>{t("publish_form_access_link")}</div>
      </CustomText>
      <CustomButton buttonType='button' classType='quaternary' onClick={() => { setIsActive(true) }} style={isActive ? { width: "100%" } : { width: "100%", backgroundColor: "#EEEEEE", color: "#0A0A0A", boxShadow: "var(--box-shdow)", border: "var(--border)" }}>
        <div>{t("publish_form_access_link_anyone")}</div>
      </CustomButton>
      <CustomButton buttonType='button' classType='quaternary' onClick={() => { setIsActive(false) }} style={!isActive ? { width: "100%" } : { width: "100%", backgroundColor: "#EEEEEE", color: "#0A0A0A", boxShadow: "var(--box-shdow)", border: "var(--border)" }}>
        <div>{t("publish_form_access_link_specific")}</div>
      </CustomButton>
      {
        isActive ? <div className={styles.anyoneWithLinkContainer}>
          <LinkContainer url={formId} />
        </div>
          : <div className={styles.shareEmailContainer}>
            <CustomText textType='t3' style={{ color: "black", fontWeight: "600", paddingBottom: "10px" }}>
              <div>{t("send_to_email")}</div>
            </CustomText>
            <MultilineTags onChange={(tags) => setEmails(tags)} setErrors={setErrors} type={"email"} value={emails} />
            <div className={styles.infoText}>{t("enter_add_value")}</div>
            {
              errors
                ? <CustomText textType='t7' style={{ paddingTop: "10px", color: "red", fontWeight: "600" }}><div>{errors}</div></CustomText>
                : <></>
            }
          </div>
      }
      <div className={styles.saveBtnContainer}>
        {isActive ? <CustomButton
          disabled={errors ? true : false}
          buttonType='button' classType='quaternary' onClick={() => {
            
            publishPublicMode();
          }} style={{ width: "fit-content" }}>
          <div>{t("publish")}</div>
        </CustomButton> : <CustomButton
          disabled={errors ? true : false}
          buttonType='button' classType='quaternary' onClick={() => {
            if (emails.length < 1) {
              setErrors(t("enter_atleast_one_email"));
              return;
            }
            publishPrivateMode()
          }
          } style={{ width: "fit-content" }}>
          <div>{t("send_and_publish")}</div>
        </CustomButton>}

      </div>
    </div >
  )
}
