import React from 'react'
import style from './cards.module.scss'
import TextComponent from '../text-component/text-component';
import { CardProps } from './cards.props';

export default function Cards(props: CardProps) {
    return (
        <div className={style.cardMain} style={{
            cursor:`${props.isClickabel ? 'pointer' : 'default'}`
        }} onClick={props.onClick}>
            <div>
                <TextComponent textType='Content'>
                    <div>{props.title}</div>
                </TextComponent>
            </div>
            <div>
                {props.children}
            </div>
        </div>
    )
}