import { Dispatch, SetStateAction } from 'react';
import styles from '../add-integration.module.scss'
import CustomHeading from '../../../../../../components/v2/common/custom-heading/custom-heading';
import CustomInput from '../../../../../../components/v2/common/custom-input/custom-input';
import { useTranslation } from 'react-i18next';
import { MongoDBProperties, GoogleSheetProperties } from '../add-integration';

export default function MongoDbIntegration(props: MongoDBIntegrationProps) {

  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <CustomHeading headerType='h5'>
        <div className={styles.textColor}>{t("integration_connection_parameters")}</div>
      </CustomHeading>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', gap: '4%' }}>
          <div id="integration-host" style={{ width: '48%', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <CustomHeading headerType='h5'>
              <div className={styles.textColor}>{t("integration_host")}&nbsp;<span style={{ color: 'red' }}>*</span></div>
            </CustomHeading>
            <CustomInput
              inputStyleType={'primary'}
              inputType={'text'}
              placeHolder={'Provide your host name'}
              value={(props.connectionProps as MongoDBProperties).host}
              onChange={(e) => {
                props.setConnectionProps({ ...(props.connectionProps as MongoDBProperties), host: e.target.value });
                if (props.isDirtyFlags.host === true) {
                  if (e.target.value.trim().length < 1) {
                    props.setIsError(true);
                    props.setErrors((prev) => { return { ...prev, host: 'This is required' } });
                  } else {
                    props.setIsError(false);
                    props.setErrors((prev) => { return { ...prev, host: undefined } });
                  }
                }
              }}
              onBlur={(e) => {
                props.setIsDirtyFlags((prev) => { return { ...prev, host: true } });
                if (e.target.value.trim().length < 1) {
                  props.setErrors((prev) => { return { ...prev, host: 'This is required' } });
                } else {
                  props.setErrors((prev) => { return { ...prev, host: undefined } });
                }
              }}
            />
            {
              props.errors && props.errors.host &&
              <div className={styles.errorText}>This is required</div>
            }
          </div>
          <div id="integration-port" style={{ width: '48%', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <CustomHeading headerType='h5'>
              <div className={styles.textColor}>{t("integration_port")}</div>
            </CustomHeading>
            <CustomInput
              inputStyleType={'primary'}
              inputType={'text'}
              placeHolder={'Provide your port number'}
              value={(props.connectionProps as MongoDBProperties).port}
              onChange={(e) => props.setConnectionProps({ ...(props.connectionProps as MongoDBProperties), port: e.target.value })}
            />
          </div>
        </div>
        <div style={{ display: 'flex', gap: '4%' }}>
          <div id="integration-database" style={{ width: '48%', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <CustomHeading headerType='h5'>
              <div className={styles.textColor}>{t("integration_db_name")}&nbsp;<span style={{ color: 'red' }}>*</span></div>
            </CustomHeading>
            <CustomInput
              inputStyleType={'primary'}
              inputType={'text'}
              placeHolder={'Provide your database name'}
              value={(props.connectionProps as MongoDBProperties).dbName}
              onChange={(e) => {
                props.setConnectionProps({ ...(props.connectionProps as MongoDBProperties), dbName: e.target.value });
                if (props.isDirtyFlags.dbName === true) {
                  if (e.target.value.trim().length < 1) {
                    props.setIsError(true)
                    props.setErrors((prev) => { return { ...prev, dbName: 'This is required' } });
                  } else {
                    props.setIsError(false)
                    props.setErrors((prev) => { return { ...prev, dbName: undefined } });
                  }
                }
              }}
              onBlur={(e) => {
                props.setIsDirtyFlags((prev) => { return { ...prev, dbName: true } });
                if (e.target.value.trim().length < 1) {
                  props.setIsError(true)
                  props.setErrors((prev) => { return { ...prev, dbName: 'This is required' } });
                } else {
                  props.setIsError(false)
                  props.setErrors((prev) => { return { ...prev, dbName: undefined } });
                }
              }}
            />
            {
              props.errors && props.errors.dbName &&
              <div className={styles.errorText}>This is required</div>
            }
          </div>
          <div id="integration-collection" style={{ width: '48%', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <CustomHeading headerType='h5'>
              <div className={styles.textColor}>{t("integration_collection")}&nbsp;<span style={{ color: 'red' }}>*</span></div>
            </CustomHeading>
            <CustomInput
              inputStyleType={'primary'}
              inputType={'text'}
              placeHolder={'Provide your collection name'}
              value={(props.connectionProps as MongoDBProperties).collectionName}
              onChange={(e) => {
                props.setConnectionProps({ ...(props.connectionProps as MongoDBProperties), collectionName: e.target.value });
                if (props.isDirtyFlags.collectionName === true) {
                  if (e.target.value.trim().length < 1) {
                    props.setIsError(true)
                    props.setErrors((prev) => { return { ...prev, collectionName: 'This is required' } });
                  } else {
                    props.setIsError(false)
                    props.setErrors((prev) => { return { ...prev, collectionName: undefined } });
                  }
                }
              }}
              onBlur={(e) => {
                props.setIsDirtyFlags((prev) => { return { ...prev, collectionName: true } });
                if (e.target.value.trim().length < 1) {
                  props.setErrors((prev) => { return { ...prev, collectionName: 'This is required' } });
                } else {
                  props.setErrors((prev) => { return { ...prev, collectionName: undefined } });
                }
              }}
            />
            {
              props.errors && props.errors.collectionName &&
              <div className={styles.errorText}>This is required</div>
            }
          </div>
        </div>
        <div style={{ display: 'flex', gap: '4%' }}>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <CustomHeading headerType='h5'>
              <div className={styles.textColor}>{t("integration_username")}&nbsp;<span style={{ color: 'red' }}>*</span></div>
            </CustomHeading>
            <CustomInput
              inputStyleType={'primary'}
              inputType={'text'}
              placeHolder={'Provide your username'}
              value={(props.connectionProps as MongoDBProperties).userName}
              onChange={(e) => {
                props.setConnectionProps({ ...(props.connectionProps as MongoDBProperties), userName: e.target.value });
                if (props.isDirtyFlags.userName === true) {
                  if (e.target.value.trim().length < 1) {
                    props.setIsError(true)
                    props.setErrors((prev) => { return { ...prev, userName: 'This is required' } });
                  } else {
                    props.setIsError(false)
                    props.setErrors((prev) => { return { ...prev, userName: undefined } });
                  }
                }
              }}
              onBlur={(e) => {
                props.setIsDirtyFlags((prev) => { return { ...prev, userName: true } });
                if (e.target.value.trim().length < 1) {
                  props.setErrors((prev) => { return { ...prev, userName: 'This is required' } });
                } else {
                  props.setErrors((prev) => { return { ...prev, userName: undefined } });
                }
              }}
            />
            {
              props.errors && props.errors.userName &&
              <div className={styles.errorText}>This is required</div>
            }
          </div>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <CustomHeading headerType='h5'>
              <div className={styles.textColor}>{t("integration_password")}</div>
            </CustomHeading>
            <CustomInput
              inputStyleType={'primary'}
              inputType='password'
              placeHolder={'Provide your password'}
              value={(props.connectionProps as MongoDBProperties).password}
              onChange={(e) => props.setConnectionProps({ ...(props.connectionProps as MongoDBProperties), password: e.target.value })}
            />
          </div>
        </div>
        <div style={{ display: 'flex', gap: '4%' }}>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <CustomHeading headerType='h5'>
              <div className={styles.textColor}>{t("integration_auth_db")}</div>
            </CustomHeading>
            <CustomInput
              inputStyleType={'primary'}
              inputType={'text'}
              placeHolder={'Provide your auth db name'}
              value={(props.connectionProps as MongoDBProperties).authDb}
              onChange={(e) => props.setConnectionProps({ ...(props.connectionProps as MongoDBProperties), authDb: e.target.value })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}


interface MongoDBIntegrationProps {
  connectionProps: MongoDBProperties | GoogleSheetProperties;
  setConnectionProps: Dispatch<SetStateAction<MongoDBProperties | GoogleSheetProperties>>;
  setErrors: (value: SetStateAction<{
    host?: string | undefined;
    dbName?: string | undefined;
    collectionName?: string | undefined;
    userName?: string | undefined;
    integrationType?: string | undefined;
    integrationName?: string | undefined;
  }>) => void;
  errors: {
    host?: string | undefined;
    dbName?: string | undefined;
    collectionName?: string | undefined;
    userName?: string | undefined;
    integrationType?: string | undefined;
    integrationName?: string | undefined;
  };
  isDirtyFlags: {
    host: boolean;
    dbName: boolean;
    collectionName: boolean;
    userName: boolean;
    integrationType: boolean;
    integrationName: boolean;
    sheetName: boolean;
  }
  setIsDirtyFlags: Dispatch<SetStateAction<{
    host: boolean;
    dbName: boolean;
    collectionName: boolean;
    userName: boolean;
    integrationType: boolean;
    integrationName: boolean;
    sheetName: boolean;
    token: boolean;
  }>>
  setIsError: Dispatch<SetStateAction<boolean>>
}
