import { createSlice } from "@reduxjs/toolkit";


export const tokenSlice = createSlice({
    name: "token",
    initialState: {
        data: ""
    },
    reducers: {
        addToken(state, action) {
            state.data = action.payload;
        }
    }
});

export const { addToken } = tokenSlice.actions;

export default tokenSlice;