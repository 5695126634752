import styles from './org-settings.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootDispatch, RootState } from '../../../../store/store';
import hasSufficientScopes from '../../../../util/access-validator/access-validator';
import Billing from '../billing/billing';
import SubscriptionDetails from './subscription-details';
import { UserMetadataKey } from '../../../../store/models/user-metadata-key.enum';
import { Metrics } from '../metrics/metrics';
import { useEffect, useState } from 'react';
import { fetchSubscriptionThunk } from '../../../../store/thunks/subscription.thunk';
import { ProfileInfoRespOrgInfoItem } from '../../../../store/models/profile.interface';
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import SecondaryTopBar from '../../../../components/v2/common/secondary-top-bar/secondary-top-bar';
import PaymentHistory from '../payment-history/payment-history';
import { DeleteOrganization } from '../delete-organization/delete-organization';

export default function OrgSettings() {
  const { t } = useTranslation();
  const dispatch: RootDispatch = useDispatch();
  const currentOrgId = useSelector((state: RootState) => state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);
  const latestSubscription = useSelector((state: RootState) => state.subscription.data);
  const organizations = useSelector((state: RootState) => state.user.profileInfo.organizations);
  const [currentOrg, setCurrentOrgData] = useState<ProfileInfoRespOrgInfoItem>();
  const userWorkspace = useSelector((state: RootState) => state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);

  useEffect(() => {
    dispatch(fetchSubscriptionThunk());
  }, [userWorkspace]);

  useEffect(() => {
    if (organizations.length > 0) {
      const org = organizations.find(o => o.name === currentOrgId);
      setCurrentOrgData(org);
    }
  }, [userWorkspace, latestSubscription]);

  return (
    <>
      <SecondaryTopBar>
        <div style={{ padding: '0.5rem' }}>
          <TextComponent textType='Content'>
            {t("org_settings_subheading")}
          </TextComponent>
        </div>
      </SecondaryTopBar>
      <div className={styles.main}>
        <div className={`${styles.mainSection} ${styles.animIn}`}>
          {
            // latestSubscription.isActive && hasSufficientScopes(["subscription:read"]) &&
            // <DeleteOrganization />
          }
          {
            latestSubscription.isActive && hasSufficientScopes(["subscription:read"]) &&
            <Metrics />
          }
          {
            hasSufficientScopes(["subscription:read", "subscription:create"]) &&
            <SubscriptionDetails />
          }
          {
            currentOrg?.paymentMethodId && hasSufficientScopes(["payments:read"]) &&
            <Billing />
          }
          {
            latestSubscription.isActive && hasSufficientScopes(["payments:read"]) &&
            <PaymentHistory />
          }
        </div>
      </div>
    </>
  )
}

