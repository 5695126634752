import { createSlice } from "@reduxjs/toolkit";
import { ACTIVE_PAGE_STATUS } from "../models/active-page";
import { activePageThunk } from "../thunks/active-page.thunk";
import { RootState } from "../store";

const activePageInitialState: {
  activePage: string,
  status: ACTIVE_PAGE_STATUS,
  newData: string  // event which is updated ,
  refreshPage: Date
} = {
  activePage: "", // TODO: decide default page
  status: ACTIVE_PAGE_STATUS.INACTIVE,
  newData: "",
  refreshPage: new Date()
};

export const activePageSlice = createSlice({
  name: "activePage",
  initialState: activePageInitialState,
  reducers: {
    setNewData(state, action) {
      state.newData = action.payload.action;
    },
    updateRefreshPage(state, action) {
      //@TODO This logic will be replaced by event emitter logic later
      state.refreshPage = action.payload.refresh;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(activePageThunk.pending, (state, action) => {
        state.activePage = action.meta.arg.payload.pageName as string;
        state.status = action.meta.arg.payload.status as ACTIVE_PAGE_STATUS;
      })
  }
});

export const getPage = (state: RootState) => {
  return state;
}

export const { setNewData, updateRefreshPage } = activePageSlice.actions;

export default activePageSlice;
