import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { forgotAndResetPassword } from '../../../../api/sign-up';
import { Captcha } from '../../../../components/v2/common/captcha/captcha';
import CustomText from '../../../../components/v2/common/custom-bodyText/custom-text';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import CustomHeading from '../../../../components/v2/common/custom-heading/custom-heading';
import CustomInput from '../../../../components/v2/common/custom-input/custom-input';
import { PasswordResetReqDto } from '../../../../store/models/sign-up.interface';
import PageLayout from '../page-layout/page-layout';
import styles from './forgot-password.module.scss';
import svgRightArrow from "../../../../assets/v2/icons/right-arrow.svg";
import TextComponent from '../../../../components/v2/common/text-component/text-component';

export default function ForgotPassword() {
  const [sentMailStatus, setSentMailStatus] = useState(false);
  const [email, setEmailValue] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [emailWarningText, setEmailWarningText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function forgotPassword() {
    setIsLoading(true);
    const data: PasswordResetReqDto = {
      email: email as string
    };
    toast.success(t("forgot_password_check_mail_toast"));
    try {
      const res = await forgotAndResetPassword(data);
      setSentMailStatus(true);
      if (res && res.status === '201') {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }

  function validateEmail(value: string) {
    const re = new RegExp(/^[a-z0-9+_.-]+@[a-z0-9.-]+\.[a-z]+$/);
    if (value.length < 1) {
      setEmailWarningText(`${t("sign_up_email_required")}`);
      return;
    } else if (!re.test(value)) {
      setEmailWarningText(`${t("sign_up_email_invalid")}`);
      return;
    }
    setEmailWarningText('');
  }

  return (
    <>
      <Captcha />
      <div className={styles.mainContainer}>
        <div className={styles.pageLayout}>
          <PageLayout />
        </div>
        <div className={styles.login}>
          <CustomButton
            buttonType='button'
            classType='tertiary'
            onClick={() => {
              navigate('/')
            }}
          >
            <TextComponent textType='Header'>
            <div>{t("login")}</div>
            </TextComponent>
          </CustomButton>
        </div>
        <div className={styles.rightContainer}>
          {
            sentMailStatus ? (
              <>
                <div>
                  <TextComponent textType='Header'>
                  <div>{`${t("forgot_password_check_mail")}`}</div>
                  </TextComponent>
                  <TextComponent textType='Content'>
                  <div>{`${t("forgot_password_check_mail_sub")}`}</div>
                  </TextComponent>
                </div>
                <span>{email}</span>
                <TextComponent textType='Content'>
                <span>{t("forgot_password_check_mail_link")}</span>
                </TextComponent>
                <CustomButton
                  buttonType='button'
                  classType='quaternary'
                  disabled={!email}
                  onClick={() => {
                    forgotPassword()
                  }}
                >
                  <div>{`${t("Resend")}`}</div>
                </CustomButton>
                <div>
                  <TextComponent textType='Content'>
                <div>{t("link_dispatch_to_reset_password")}<div>{t("or")}</div><div>{t("click_reset_password")}</div></div>
                  </TextComponent>
                </div>
                <CustomButton
                  buttonType='button'
                  classType='quaternary'
                  disabled={!email}
                  onClick={() => {
                    navigate('/reset-password');
                  }}
                >
                  <div>{t("reset_passwd")}</div>
                </CustomButton>
              </>
            ) : (
              <>
                <div>
                  <CustomHeading
                    headerType='h1'
                  ><div>{`${t("forgot_password")}`}</div></CustomHeading>
                  <CustomText
                    textType='t6'
                  ><div>{`${t("forgot_password_sub")}`}</div></CustomText>
                </div>
                <CustomInput
                  inputStyleType='fade'
                  inputType='email'
                  value={email}
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    validateEmail(value);
                    setEmailValue(value);
                  }}
                  placeHolder={`${t("feedback_application_enter_email")}`}
                />
                {
                  emailWarningText && emailWarningText !== '' &&
                  <p className={styles.errorText}>{emailWarningText}</p>
                }
                <div style={{ display: "flex", gap: "1rem" }}>
                  <CustomButton
                    buttonType='button'
                    onClick={() => {
                      navigate('/');
                    }}
                    style={{
                      width: "40%"
                    }}
                    classType={'secondary'}
                  >
                    <TextComponent textType='Content'>
                    <span>{`${t("go_back")}`}</span>
                    </TextComponent>
                  </CustomButton>
                  <CustomButton
                    buttonType='submit'
                    classType='quaternary'
                    disabled={emailWarningText !== '' || !email}
                    isLoading={isLoading}
                    onClick={() => { forgotPassword() }}
                  >
                    <div className={styles.forgotBtn}>
                      <TextComponent textType='Content'>
                        <div style={{color:'#fff'}}>{isLoading ? t("loading") : `${t("forgot_password_sent_code", { interpolation: { escapeValue: false } })}`}</div>
                      </TextComponent>
                      <img src={svgRightArrow} alt="" />
                    </div>
                  </CustomButton>
                </div>
              </>
            )
          }
        </div>
      </div>
    </>
  )
}
