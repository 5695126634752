import styles from '../settings-panel.module.scss';
import { useEffect, useState } from "react";
import { Accordion } from '../../../common/accordion/accordion';
import { TranslationSettingsProps } from './translation-settings.props';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { SubscriptionPlanCode } from '../../../../../store/models/subscription/plan-name.enum';

export function TranslationSettings(props: TranslationSettingsProps) {
  const { t } = useTranslation();
  const [isTranslationSettingsOpen, setIsTranslationSettingsOpen] = useState(false);
  const plans = useSelector((state: RootState) => state.subscription.data.plans);
  const [isTranslationSubscribed, setIsTranslationSubscribed] = useState(false);

  useEffect(() => {
    if (plans.find(i => i.code.toUpperCase() === SubscriptionPlanCode.TRANSLATION)) {
      setIsTranslationSubscribed(true);
    }
  }, [plans]);

  useEffect(() => {
    if (props.isActionPanelOpen === false) setIsTranslationSettingsOpen(false);
  }, [props.isActionPanelOpen]);

  useEffect(() => {
    if (props.updatePayload.config?.isTranslate) {
      props.setTranslationSelected(props.updatePayload.config?.isTranslate);
    } else {
      props.setTranslationSelected(false);
    }
  }, [props.updatePayload.config?.isTranslate])

  return (
    <Accordion
      onAccordionClick={() => setIsTranslationSettingsOpen((prev) => !prev)}
      btn_content={t("translation")}
      openState={isTranslationSettingsOpen}
    >
      <div className={styles.accordionContent}>
        <div className={`${styles.logoContainer} ${isTranslationSubscribed ? '' : styles.sliderDisabled}`}>
          <div>{t("enable_translation")}</div>
          <div>
            <label className={styles.switch} id={'isTranslate'}>
              <input
                type={"checkbox"}
                checked={isTranslationSubscribed && props.updatePayload.config?.isTranslate}
                onChange={(e) => {
                  props.setUploadPayload((prev) => {
                    return { ...prev, config: { ...prev.config, isTranslate: e.target.checked } }
                  });
                  props.setIsValuesDirty(true);
                }}
                id="isTranslate"
              />
              <span className={styles.slider}></span>
            </label>
          </div>
        </div>
        <div className={styles.info}>{isTranslationSubscribed ? t("translation_disclaimer") : t("translation_not_subscribed")}</div>
      </div>
    </Accordion >
  )
}
