import styles from '../settings-panel.module.scss';
import { useEffect, useState } from "react";
import { Accordion } from '../../../common/accordion/accordion';
import CustomTextArea from '../../../common/custom-text-area/custom-text-area';
import { TimerSettingsProps } from './timer-settings.props';
import CustomButton from '../../../common/custom-button/custom-button';
import { useTranslation } from 'react-i18next';
import TextComponent from '../../../common/text-component/text-component';

export function TimerSettings(props: TimerSettingsProps) {
  const { t } = useTranslation();
  const [isTimerSettingsOpen, setIsTimerSettingsOpen] = useState(false);

  useEffect(() => {
    if (props.isActionPanelOpen === false) setIsTimerSettingsOpen(false);
  }, [props.isActionPanelOpen]);

  useEffect(() => {
    if (props.updatePayload.timerConfig?.timerActiveStatus && !props.updatePayload.timerConfig?.timer) {
      props.setTimerSelected(false);
    } else {
      props.setTimerSelected(true)
    }

  }, [props.updatePayload.timerConfig?.timerActiveStatus, props.updatePayload.timerConfig?.timer])

  return (
    <Accordion
      onAccordionClick={() => setIsTimerSettingsOpen((prev) => !prev)}
      btn_content={t("timer")}
      openState={isTimerSettingsOpen}
    >
      <div className={styles.accordionContent}>
        <div className={styles.logoContainer}>
          <div>{t("enable_time_limit")}</div>
          <div>
            <label className={styles.switch} id={'timerActiveStatus'}>
              <input
                type={"checkbox"}
                checked={props.updatePayload.timerConfig?.timerActiveStatus && props.updatePayload.timerConfig?.timerActiveStatus === true ? true : false}
                onChange={(e) => {
                  props.setUploadPayload((prev) => {
                    return { ...prev, timerConfig: { ...prev.timerConfig, timerActiveStatus: e.target.checked } }
                  });
                  if (props.errors.size === 0) {
                    props.setIsValuesDirty(true);
                  }
                }}
                id="timerActiveStatus"
              />
              <span className={styles.slider}></span>
            </label>
          </div>
        </div>
        {
          props.updatePayload.timerConfig?.timerActiveStatus === true &&
          <>
            <div className={styles.timerBtnContainer}>
              {props.minutes.map((item, index) => {
                return (
                  <CustomButton
                    key={index}
                    buttonType={'button'}
                    classType={props.updatePayload.timerConfig?.timer == item ? 'quaternary' : 'secondary'}
                    onClick={() => {
                      props.setUploadPayload((prev) => {
                        return { ...prev, timerConfig: { ...prev.timerConfig, timer: item } }
                      });
                      if (props.errors.size === 0) {
                        props.setIsValuesDirty(true);
                      }
                    }}
                    style={{ width: 'auto', boxShadow: 'none' }}
                  >{item}</CustomButton>
                )
              })}

            </div>
            <TextComponent textType='Header'>
              <div>{t("instruction_text")}</div>
            </TextComponent>
            <CustomTextArea
              placeHolder={t("instruction_text")}
              value={props.updatePayload.timerConfig?.timerInstruction}
              getText={(data) => {
                // props.form.timerConfig?.timerInstruction = e
                props.setUploadPayload((prev) => {
                  return { ...prev, timerConfig: { ...prev.timerConfig, timerInstruction: data } }
                });
                if (props.errors.size === 0) {
                  props.setIsValuesDirty(true);
                }
              }}
            />
          </>
        }
      </div>
    </Accordion >
  )
}
