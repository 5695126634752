import axios from 'axios';
import { GenerateApiUrl } from './generate-api-url';
import { AddOnSubscriptionPlan, BaseSubscriptionPlan } from '../store/models/subscription/plans';
import { CreateSubscriptionItemReqDto, GetInvoicesRespDto, GetSubscriptionItemRespDto, UpdateSubscriptionItemReqDto } from '../store/models/subscription.interface';

export async function getPlans() {
  const url = GenerateApiUrl(process.env.REACT_APP_SUBSCRIPTION_SVC as string, `plans`, false);
  const response = await axios.get<{ plan: (BaseSubscriptionPlan | AddOnSubscriptionPlan)[] }>(url);
  return response.data.plan;
}

export async function getLatestSubscription() {
  const url = GenerateApiUrl(process.env.REACT_APP_SUBSCRIPTION_SVC as string, `subscription`, true);
  const response = await axios.get<GetSubscriptionItemRespDto & { message: string, status: number }>(url);
  return response.data;
}

export async function cancelSubscription() {
  const url = GenerateApiUrl(process.env.REACT_APP_SUBSCRIPTION_SVC as string, `subscription`, true);
  const response = await axios.delete(url);
  return response.data;
}

export async function createSubscription(data: CreateSubscriptionItemReqDto) {
  const url = GenerateApiUrl(process.env.REACT_APP_SUBSCRIPTION_SVC as string, `subscription`, true);
  const response = await axios.post(url, data);
  return response.data;
}

export async function changePaymentMethod(id: string): Promise<void> {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_SUBSCRIPTION_SVC as string, `subscription/update-payment-method`, true);
  await axios.patch(apiUrl, { paymentMethodId: id });
}

export async function modifySubscription(data: UpdateSubscriptionItemReqDto) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_SUBSCRIPTION_SVC as string, `subscription/upgrade`, true);
  const response = await axios.patch<GetSubscriptionItemRespDto & { message: string, status: number }>(apiUrl, data);
  return response.data;
}

export async function activateCancelledSubscription() {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_SUBSCRIPTION_SVC as string, `subscription/activate`, true);
  const response = await axios.patch<GetSubscriptionItemRespDto & { message: string, status: number }>(apiUrl);
  return response.data;
}

export async function getPaymentHistory() {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_SUBSCRIPTION_SVC as string, `subscription/invoices`, true);
  const response = await axios.get<GetInvoicesRespDto>(apiUrl);
  return response.data.invoices;
}
