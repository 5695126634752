import axios from "axios";
import { GetIntegrationsRespDto } from "../store/models/settings/integrations/get-integrations.interface";
import { GenerateApiUrl } from "./generate-api-url";
import { CreateIntegrationReqDto } from "../store/models/settings/integrations/create-integrations.interface";
import { UpdateIntegrationReqDto } from "../store/models/settings/integrations/update-integrations.interface";

export async function getAllIntegrations(formId? : string): Promise<GetIntegrationsRespDto> {
  const url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `integrations`, true);
  const response = await axios.get<GetIntegrationsRespDto>(url,{params:{
    formId:formId
  }});
  return response.data;
}

export async function createIntegration(payload: CreateIntegrationReqDto): Promise<void> {
  const url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `integrations`, true);
  await axios.post<void>(url, payload);
}

export async function deleteIntegration(id: string) {
  const url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `integrations/${id}`, true);
  await axios.delete<void>(url);
}

export async function updateIntegration(id: string, payload: UpdateIntegrationReqDto) {
  const url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `integrations/${id}`, true);
  await axios.put<void>(url, payload);
}
