import styles from "./file-preview.module.scss";

import { useEffect, useState } from "react";
import { downloadAttachment } from "../../../api/form-response";

export default function FilePreviewModal(props: IProps) {

  useEffect(() => {
    const downloadImg = async() => {
      if (props.fileId && props.originalName && props.mimeType) {
        const res = await downloadAttachment(props.fileId);
        const blob = new Blob([res.data], { type: props.mimeType })
        const isImage = new RegExp('image/*').test(props.mimeType);
        const isVideo = new RegExp('video/*').test(props.mimeType);

        if (isImage) {
          renderImage(blob);
        } else if (isVideo) {
          renderVideo(blob, props.mimeType)
        }
      }
    }
    downloadImg();
  }, [])

  const renderVideo = (blob: any, type: string) => {
    const url = URL.createObjectURL(blob);
    const video = document.createElement('video') as HTMLVideoElement;
    video.setAttribute('class', styles.imgEle)
    video.src = url;
    video.setAttribute('controls', 'true')
    const ele = document.getElementById('previewImg') as HTMLElement;
    if(ele.firstElementChild !== null) ele.removeChild(ele.firstElementChild);
    ele.appendChild(video);
  }

  const renderImage = (blob: any) => {
    const url = URL.createObjectURL(blob);
    const img = document.createElement('img');
    img.setAttribute('class', styles.imgEle);
    img.src = url;
    const ele = document.getElementById('previewImg') as HTMLElement;
    if(ele.firstElementChild !== null) ele.removeChild(ele.firstElementChild);
    ele.appendChild(img);
  }

  return (
    <div id={props.id} className={`modal ${styles.modal}`} >
      <div className={`modal-container ${styles.modalContainer}`}>
        <span className={styles.closeBtn} onClick={() => props.onModalClose()}>Close</span>
        <div id="previewImg" className={styles.previewImgWrapper}>
          <div className={styles.loadingText}>Please wait...</div>
        </div>
      </div>
    </div>
  )
}

interface IProps {
  id?: string;
  onModalClose: any;
  fileId?: string;
  mimeType?: string;
  originalName?: string;
}
