
export interface GetIntegrationsRespDto {
  items: GetIntegrationsRespItem[];
}

export interface GetIntegrationsRespItem {
  id: string;
  createdAt: Date;
  updatedAt?: Date;
  displayName: string;
  type: IntegrationType;
  createdBy: string;
  updatedBy?: string;
  config: MongodbConnectionProperties | WebhookConnectionProperties;
  associatedFormsCount?: number;
  associatedForms?:string[];
}

export enum IntegrationType {
  MONGODB = 'MONGODB',
  MONGODB_ATLAS = 'MONGODB_ATLAS',
  WEBHOOK = 'WEBHOOK',
  GOOGLE_SHEETS="GOOGLE_SHEETS"
}

export interface MongodbConnectionProperties extends IntegrationConnectionProperties {
  host: string;
  port?: string;
  username: string;
  authDatabase?: string;
  databaseName: string;
  collectionName: string;
}

export interface IntegrationConnectionProperties {
  credentials?: IntegrationConnectionCredentials;
}

export interface IntegrationConnectionCredentials {
  password?: string;
  authCode?:string;
}

export interface WebhookConnectionProperties extends IntegrationConnectionProperties {
  //TODO: Add authentication options - Basic, OAuth, etc.
}

export interface GoogleSheetConnectionProperties extends IntegrationConnectionProperties {
  sheetName:string;
}