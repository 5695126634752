export const FORM_THEMES = [
  {
    id: 1,
    main: "#2F3454",
    background: "#5A71FF0A"
  },
  {
    id: 2,
    main: "#73A9AD",
    background: "rgba(255,253,232,1)"
  },
  {
    id: 3,
    main: "#1572A1",
    background: "rgba(236,247 ,253,1)"
  },
  {
    id: 4,
    main: "#64b6ac",
    background: "rgba(234,255,246,1)"
  },
  {
    id: 5,
    main: "#525E75",
    background: "rgba(253,243,228,1)"
  },
  {
    id: 6,
    main: "#F38BA0",
    background: "rgba(255,234,234,1)"
  },
  {
    id: 7,
    main: "#1099a2",
    background: "rgba(235, 250, 253, 1)"
  }
];

export const FONT_SIZE = ["larger", "x-large", "xx-large"];

export const FONT_WEIGHT = ['bold', 'normal'];

export const TIMER_MINS = [15, 30, 45, 60, 90, 120, 180];
