import { LoadState } from "../models/load-state.enum";
import { GetAPIKeysResDto } from "../models/api-keys.interface";
import { createAPIKeyThunk, deleteAPIKeyThunk, getAPIKeysThunk } from "../thunks/api-keys.thunk";
import { createSlice } from "@reduxjs/toolkit";

const prefixLength: number = 6;
const apiKeyLength: number = 64;
/**
 * Before changing these values, please read instructions in dataexport-restapi > apikeys.service.ts.
 */
const apiKeysIntialState: {
    data: GetAPIKeysResDto[];
    loadingState: LoadState,
} = { data : [], loadingState: LoadState.IDLE }

export const apiKeysSlice = createSlice({
  name: "apiKeys",
  initialState: apiKeysIntialState,
  reducers: {}, extraReducers(builder) {
    builder
      .addCase(getAPIKeysThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(getAPIKeysThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingState = LoadState.IDLE;
      })
      .addCase(getAPIKeysThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
      .addCase(createAPIKeyThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(createAPIKeyThunk.fulfilled, (state, action) => {
        const data = [...state.data];
        /** This step is done inorder to split out the prefix and APIKey and just use prefix in the listing. */
        const transformedPayload = {id: action.payload.id, createdAt: action.payload.createdAt, prefix: splitPrefixAndX(action.payload.prefixWithAPIKey).prefix, name: action.payload.name, orgName: action.payload.orgName };
        data.push(transformedPayload);
        state.data = data;
        state.loadingState = LoadState.IDLE;
      })
      .addCase(createAPIKeyThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
      .addCase(deleteAPIKeyThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(deleteAPIKeyThunk.fulfilled, (state, action) => {
        state.data = state.data.filter((value) => value.id !== action.payload);
        state.loadingState = LoadState.IDLE;
      })
      .addCase(deleteAPIKeyThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
  },
});

export const splitPrefixAndX = (prefixWithX: string) => {
  return { prefix : prefixWithX.slice(0, prefixLength), X : prefixWithX.slice(prefixLength + 1) };
}

export default apiKeysSlice;