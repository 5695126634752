import styles from './modal.module.scss'
import { ModalProps } from './modal.props';
import ReactDOM from 'react-dom';

export default function Modal(props: ModalProps) {

  if (!props.showModal) return null;

  const modalEle = (
    <div className={styles.main} onClick={() => {
      props.setShowModal(false);
      if (props.onClose) props.onClose();
    }}>
      <div className={styles.modalContainer} onClick={(e) => e.stopPropagation()} style={props.innerStyle}>
        <div>{props.header}</div>
        <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}>{props.content}</div>
      </div>
    </div>
  )

  return ReactDOM.createPortal(modalEle, document.body);
}
