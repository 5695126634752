import { useTranslation } from 'react-i18next';
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import styles from './payment-history.module.scss';
import { Fragment, useEffect, useState } from 'react';
import CustomLoader from '../../../../components/v2/common/custom-loader/custom-loader';
import NoContent from '../../../../components/v2/common/no-content/no-content';
import { getPaymentHistory } from '../../../../api/subscription';
import { GetInvoicesItemRespDto } from '../../../../store/models/subscription.interface';

export default function PaymentHistory() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [invoices, setInvoices] = useState<GetInvoicesItemRespDto[]>([]);

  useEffect(() => {
    getLatestData();
  }, []);

  const getLatestData = async () => {
    const res = await getPaymentHistory();
    setInvoices(res);
    setIsLoading(false);
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <TextComponent textType='Header'>{t("payment_history")}</TextComponent>
        <TextComponent textType='Content'>{t("payment_history_sub")}</TextComponent>
      </div>
      <div style={{ position: "relative" }} >
        {
          <div className={`${styles.loaderCube} ${isLoading ? '' : styles.loaderClose}`}><CustomLoader /></div>
        }
        <div className={`${styles.contentPane} ${isLoading ? '' : styles.contentReady}`}>
          {
            invoices.length > 0 ? (
              <>
                <div className={'tableWrapper tableNoShadow'}>
                  <>
                    <div className={'tableHeaderRow'}>
                      <div className={'tableData'}>{t("amnt_due")}</div>
                      <div className={'tableData'}>{t("amnt_paid")}</div>
                      <div className={'tableData'}>{t("table_form_status")}</div>
                      <div className={'tableData'}>{t("invoice_pdf")}</div>
                      <div className={'tableData'}>{t("paid_on")}</div>
                      <div className={'tableData'}>{t("billing_reason")}</div>
                    </div>
                    {
                      invoices.map((item, index) => {
                        return (
                          <div key={`k${index}`} className={'tableRow'}>
                            <div className={'tableData'}>${(item.amountDue / 100)}</div>
                            <div className={'tableData'}>${(item.amountPaid / 100)}</div>
                            <div className={'tableData'}>{item.status}</div>
                            <div className={'tableData'}>
                              {
                                item.status === 'paid' ?
                                  <a href={item.invoicePdfUrl} >{t("click_here")}</a> : '-'
                              }
                            </div>
                            <div className={'tableData'}>{new Date(item.periodStart).toLocaleDateString()}</div>
                            <div className={'tableData'}>{item.billingReason}</div>
                          </div>
                        )
                      })
                    }
                  </>
                </div>
                <div className={'mobileContainer'}>
                  {
                    invoices.map((item, index) => {
                      return (
                        <Fragment key={`f${index}`}>
                          <div className={'mobileCard'} key={`k${index}`} style={{ "alignItems": "center" }} >
                            <div className={'rowOne'}>{t("amnt_due")}: ${item.amountDue / 100}</div>
                            <div className={'rowOne'}>{t("amnt_paid")}: ${item.amountPaid / 100}</div>
                            <div className={'rowOne'}>{item.status}</div>
                            <div className={'rowOne'}>
                              <a href={item.invoicePdfUrl}>{t("invoice_url")}</a>
                            </div>
                          </div>
                        </Fragment>
                      )
                    })
                  }
                </div>
              </>
            ) : (
              <NoContent
                content={`${t("no_users_to_show")}`}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}
