import { DropdownDataItem } from "../../../../common/custom-select/custom-select.props";
import { useEffect, useRef, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import { LogicControl } from "../../logic-builder/models/logic-control.props";
import { dropdownInputControls, numberComparatorData, tagInputControls } from "../../logic-builder-pane/models/panel-data.constants";
import { UseLogicBuilderRes } from "../../logic-builder/models/use-logic-builder.interface";
import { useTranslation } from "react-i18next";

export function useCreateLogic(controls: LogicControl[], logicBuilder: UseLogicBuilderRes) {
  const { t } = useTranslation();
  const [srcControl, setSrcControl] = useState<LogicControl>();
  const [tgtControl, setTgtControl] = useState<LogicControl>();
  const [comparator, setComparator] = useState<SingleValue<DropdownDataItem>>();
  const [tagValues, setTagValues] = useState<string[]>([]);
  const clearTags = useRef<() => {}>();
  const [dropDownValues, setDropDownValues] = useState<MultiValue<DropdownDataItem>>([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isValuesDefined, setIsValuesDefined] = useState<Set<boolean>>(new Set([false]));
  const isTouched = useRef(false);

  useEffect(() => {
    if (!srcControl || !tgtControl || !comparator) {
      setIsValuesDefined(prev => new Set(prev).add(false));
      return;
    }
    if (dropdownInputControls.includes(srcControl.controlType) && dropDownValues.length === 0) {
      setIsValuesDefined(prev => new Set(prev).add(false));
      return;
    }
    if (tagInputControls.includes(srcControl.controlType) && tagValues.length === 0) {
      setIsValuesDefined(prev => new Set(prev).add(false));
      return;
    }
    setIsValuesDefined(new Set([true]));
  }, [srcControl, tgtControl, comparator, tagValues, dropDownValues]);

  const assignErrors = (key: string, value: string) => {
    if (value.length === 0) {
      setErrors((prev) => {
        const { [key]: remove, ...rest } = prev;
        return { ...rest };
      });
      return;
    }
    setErrors((prev) => {
      return {
        ...prev,
        [key]: value,
      }
    });
  }

  const srcControlValidation = (control?: LogicControl) => {
    if (isTouched.current === true && !control) {
      assignErrors('srcControl', t('cond_edge_req_err'));
      return;
    } else {
      assignErrors('srcControl', '');
    }
    if(control?.id && tgtControl?.id) {
      for(const logic of logicBuilder.conditionalLogic) {
        if(logic.source === control.id && logic.target === tgtControl.id) {
          assignErrors('duplicate', t('cond_edge_dup_err'));
          return;
        }
      }
      assignErrors('duplicate', '');
    }
    controlsValidation(control, tgtControl);
    setComparator(undefined);
    assignErrors('comparator', '');
    setTagValues([]);
    assignErrors('tags', '')
    setDropDownValues([]);
    assignErrors('dropdown', '');
  }

  const targetControlValidation = (control?: LogicControl) => {
    if (isTouched.current === true && !control) {
      assignErrors('tgtControl', t('cond_edge_req_err'));
      return;
    } else {
      assignErrors('tgtControl', '');
    }
    if(control?.id && srcControl?.id) {
      for(const logic of logicBuilder.conditionalLogic) {
        if(logic.target === control.id && logic.source === srcControl.id) {
          assignErrors('duplicate', t('cond_edge_dup_err'));
          return;
        }
      }
      assignErrors('duplicate', '');
    }
    controlsValidation(srcControl, control);
  }

  const comparatorValidation = (data?: SingleValue<DropdownDataItem>) => {
    if (isTouched.current === true && !data) {
      assignErrors('comparator', t('cond_edge_req_err'));
      return;
    } else {
      assignErrors('comparator', '');
    }

    const type = srcControl?.controlType;
    if (type === 'number' && data && numberComparatorData.includes(data)) {
      assignErrors('tags', t('cond_edge_num_err'));
      return;
    }
  }

  const tagsValidation = (data: string[]) => {
    if (isTouched.current === true && data.length === 0) {
      assignErrors('tags', t('cond_edge_req_err'));
      return;
    } else {
      assignErrors('tags', '');
    }

    const type = srcControl?.controlType;
    if (type === 'number' && comparator && numberComparatorData.includes(comparator)) {
      if (data.length > 1) {
        assignErrors('tags', t('cond_edge_num_err'));
        return;
      }
      if (data.length === 1 && isNaN(parseFloat(data[0]))) {
        assignErrors('tags', t('cond_edge_vld_num_err'));
        return;
      }
    }
    assignErrors('tags', '');
    return;
  }

  const dropdownValidation = (data: string[]) => {
    if (isTouched.current === true && data.length === 0) {
      assignErrors('dropdown', t('cond_edge_req_err'));
      return;
    } else {
      assignErrors('dropdown', '');
    }
  }

  const requiredValidation = () => {
    isTouched.current = true;
    const checkErrors = new Set([false]);
    if (!srcControl) {
      assignErrors('srcControl', t('cond_edge_req_err'));
      checkErrors.add(true);
    }
    if (!tgtControl) {
      assignErrors('tgtControl', t('cond_edge_req_err'));
      checkErrors.add(true);
    }
    if (srcControl && !comparator) {
      assignErrors('comparator', t('cond_edge_req_err'));
      checkErrors.add(true);
    }
    if (srcControl && dropdownInputControls.includes(srcControl.controlType) && dropDownValues.length === 0) {
      assignErrors('dropdown', t('cond_edge_req_err'));
      checkErrors.add(true);
    }
    if (srcControl && tagInputControls.includes(srcControl.controlType) && tagValues.length === 0) {
      assignErrors('tags', t('cond_edge_req_err'));
      checkErrors.add(true);
    }
    if (checkErrors.has(true)) return false;
    return true;
  }

  const controlsValidation = (src?: LogicControl, tgt?: LogicControl) => {
    if (src && tgt) {
      if (src.id === tgt.id) {
        assignErrors('controlsErr', t('cond_edge_same_src_tgt_err'));
        return false;
      }

      let isTargetFound = false;
      let isSrcFound = false;
      for (const ctrl of controls) {
        if (ctrl.id === tgt.id) isTargetFound = true;
        if (isTargetFound && !isSrcFound) {
          assignErrors('controlsErr', t('cond_edge_tgt_bef_src_err'));
          return false;
        }
        if (ctrl.id === src.id) isSrcFound = true;
      }
    }
    assignErrors('controlsErr', '');
    return true;
  }

  const clearValues = () => {
    setSrcControl(undefined);
    setTgtControl(undefined);
    setComparator(undefined);
    setTagValues([]);
    if (clearTags.current) clearTags.current();
    setDropDownValues([]);
    setErrors({});
  }

  return {
    srcControl,
    setSrcControl,
    srcControlValidation,
    tgtControl,
    setTgtControl,
    targetControlValidation,
    comparator,
    setComparator,
    comparatorValidation,
    tagValues,
    setTagValues,
    tagsValidation,
    dropDownValues,
    setDropDownValues,
    dropdownValidation,
    errors,
    clearTags,
    clearValues,
    requiredValidation,
    isValuesDefined,
    setErrors,
    assignErrors
  }
}
