import { useTranslation } from 'react-i18next';
import { GetPaymentAccountsRespItem } from '../../../../store/models/user.interface';
import styles from './billing-accounts.module.scss';
import CustomText from '../../common/custom-bodyText/custom-text';
import CustomHeading from '../../common/custom-heading/custom-heading';
import { Dispatch, SetStateAction } from 'react';
import toast from 'react-hot-toast';
import { addCard } from '../../../../api/billing-accounts';

export interface BillingAccountProps {
  accounts: GetPaymentAccountsRespItem[];
  isBillingAccErr: boolean;
  selectedBillingAcc: GetPaymentAccountsRespItem | undefined;
  setSelectedBillingAcc: Dispatch<SetStateAction<GetPaymentAccountsRespItem | undefined>>;
}

export function BillingAccounts(props: BillingAccountProps) {
  const { t } = useTranslation();
  async function addPaymentAccount() {
    try {
      const res = await addCard();
      if (res) {
        window.location.href = res;
      } else {
        toast.error(t("attach_card_error"));
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  return (
    <div className={`${styles.container} ${props.isBillingAccErr ? styles.containerErr : ''}`}>
      <div className={styles.titleContainer}>
        <CustomHeading headerType='h3'>{t("card_plan_main")}</CustomHeading>
        <CustomText textType='t5'>{t("card_plan_sub")}</CustomText>
      </div>
      <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
        {
          props.accounts?.length > 0
            ?
            <>
              {
                props.accounts.map((card, index) => {
                  return (
                    <div key={index} className={`${styles.cardDetails} ${props.selectedBillingAcc?.id === card.id ? styles.activeCard : ''}`}
                      onClick={() => {
                        if (card.id === props.selectedBillingAcc?.id) {
                          props.setSelectedBillingAcc(undefined);
                        } else {
                          props.setSelectedBillingAcc(card);
                        }
                      }}
                    >
                      <div className={styles.cardDetailsContainer}>
                        <CustomText textType='t4'>{`${card.name}`}</CustomText>
                        <div>
                          <CustomText textType='t5'>{`**** **** **** ${card.last4}`}</CustomText>
                        </div>
                        <div>
                          <CustomText textType='t6'>exp</CustomText>
                          <CustomText textType='t5'>{`${card.expMonth}`} / {`${card.expYear}`}</CustomText>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </>
            : null
        }
        <div className={styles.cardDetails} onClick={addPaymentAccount}>
          <div className={styles.cardDetailsContainer}>
            + Add a card
          </div>
        </div>
      </div>
      {
        props.isBillingAccErr &&
        <div className={styles.error}>Select a card</div>
      }
    </div>
  )
}
