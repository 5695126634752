import React, { useState } from "react";
import { CustomTooltipProps, Direction } from "./custom-tooltip.props"
import styles from "./custom-tooltip.module.scss";

export default function CustomTooltip (props : CustomTooltipProps) {
  let timeout: string | number | NodeJS.Timeout | undefined;
  const [active, setActive] = useState(false);

  function classSelector(classType: Direction) {
    switch (classType) {
      case Direction.TOP:
        return styles.top;
      case Direction.RIGHT:
        return styles.right;
      case Direction.LEFT:
        return styles.left;
      case Direction.BOTTOM:
        return styles.bottom;
      default:
        return styles.top;
    }
}

  const showTip = () => {
    // timeout = setTimeout(() => {
      setActive(true);
    // }, props.delay || 400);
  };

  const hideTip = () => {
    // clearInterval(timeout);
    setActive(false);
  };

  return (
    <div className={`${styles.TooltipWrapper}`}
      style={props.styles}
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div className={ `${styles.TooltipTip} ${classSelector(props.direction)}` }>
          {/* Content */}
          {props.content}
        </div>
      )}
    </div>
  );
};

