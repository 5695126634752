import toast from "react-hot-toast";
import CustomButton from "../../../../components/v2/common/custom-button/custom-button";
import styles from './publish-helper.module.scss';
import { useTranslation } from 'react-i18next';
import CustomInput from "../../../../components/v2/common/custom-input/custom-input";
import { Dispatch, SetStateAction } from "react";
import svgPaneDelete from "../../../../assets/v2/icons/pane-delete.svg";
import LinkContainer from "../link-container/link-container";

export interface HelperProps {
  url?: string;
  setIsShareMenuActive: Dispatch<SetStateAction<boolean>>
}

export default function PublishHelper(props: HelperProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.inpuContainer}>
      <div className={styles.mobileCloseBtn}>
        <div>{t('share_form')}</div>
        <button className={styles.closebtn} onClick={() => { props.setIsShareMenuActive(false) }}>
          <img src={svgPaneDelete} alt="" />
        </button>
      </div>
      <div className={styles.line}></div>
      <LinkContainer url={props.url} />
    </div>
  );
}
