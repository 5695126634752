import { useNavigate, useParams } from "react-router-dom";
import { CustomPagination } from "../../../../components/v2/common/custom-pagination/custom-pagination";
import styles from './quiz-score.module.scss';
import CustomHeading from "../../../../components/v2/common/custom-heading/custom-heading";
import { Form, FormState } from "../../../../store/models/form.interface";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootDispatch, RootState } from "../../../../store/store";
import { useContext, useEffect, useState } from "react";
import { QuizFormResponses } from "../../../../store/models/form-response.interface";
import { fetchResponseCount, getQuizModeScores } from "../../../../api/form-response";
import { getQuizFormControl } from "../../../../api/form-controls";
import { FormControl } from "../../../../store/models/form-controls/form-control.interface";
import { getForm } from "../../../../store/slices/forms.slice";
import QuizMode from "../quiz-mode/quiz-mode";
import toast from "react-hot-toast";
import { SocketContext } from "../../../../context/socket";
import { ACTIVE_PAGE_STATUS } from "../../../../store/models/active-page";
import { useDispatch } from "react-redux";
import { activePageThunk } from "../../../../store/thunks/active-page.thunk";
import { fetchFormByIdThunk } from "../../../../store/thunks/forms.thunk";
import { setNewData } from "../../../../store/slices/active-page.slice";
import ScoreCard from "../../form-response/score-card/score-card";
import TextComponent from "../../../../components/v2/common/text-component/text-component";

export default function QuizScore() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [quizFormResponses, setQuizFormResponses] = useState<QuizFormResponses[]>([]);
  const { formId } = useParams();
  const [quizSkip, setQuizSkip] = useState<number>(0);
  const [quizControl, setQuizControl] = useState<FormControl>();
  const [isDisabledCustom, setDisabledCustom] = useState<boolean>(false);
  const [responseCount, setResponseCount] = useState(0);
  const form: Form | undefined = useSelector((state: RootState) => getForm(state, formId as string));
  const socket = useContext(SocketContext);
  const activeWebPage = useSelector((state: RootState) => state.activePage.activePage);
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const dispatch: RootDispatch = useDispatch();
  const refreshWebPage = useSelector((state: RootState) => state.activePage.refreshPage);
  useEffect(() => {
    getQuizControl();
    if (formId)
      fetchFormResponseCount(formId);
  }, [])

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activeWebPage])

  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    if (formId) {
      dispatch(activePageThunk({ socket: socket, payload: { pageName: `form-details-${formId}`, status } }));
    }
  }

  useEffect(() => {
    if (newData) {
      getLatestData(newData);
      dispatch((setNewData({ action: "" })));
    }
  }, [newData])

  useEffect(() => {
    getLatestData()
  }, [refreshWebPage])


  async function setQuizPagination(num: number) {
    if (formId) {
      setQuizSkip(num);
      const quizScores = await getQuizModeScores(formId, num, 10);
      setQuizFormResponses(quizScores);
    }
  }

  const getQuizControl = async () => {
    if (formId) {
      try {
        const quizControl = await getQuizFormControl(formId);
        setQuizControl(quizControl);
        if (quizControl) {
          const quizScores = await getQuizModeScores(formId, quizSkip || 0, 10);
          setQuizFormResponses(quizScores);
          if (quizControl.config.isActive === false) {
            setDisabledCustom(true);
          }
        }
      } catch (err) { }
    }
  }


  const fetchFormResponseCount = async (formId: string) => {
    let count: number = 0;
    try {
      const response = await fetchResponseCount(formId);
      count = response.data.count;

    } catch (err) {
      toast.error(t('form_details_toast_form_response_err'));
    }
    setResponseCount(count);
  }

  const getLatestData = async (newData?: string) => {
    try {
      if (formId) {
        if (newData === "forms") {
          await dispatch(fetchFormByIdThunk(formId));
        }
        if (quizControl) {
          await getQuizControl();
          const quizScores = await getQuizModeScores(formId, (quizSkip || 0), 10);
          setQuizFormResponses(quizScores);
        }

      }
    } catch (err) { }
  }

  return (
    <div className={styles.quizMain}>
      {
        form && form.state === FormState.PUBLISHED && responseCount > 0 &&
        <>
          <div className={styles.main}>
            <TextComponent textType="Header">
              <div style={{
                padding: '0.75rem 0'
              }}>{t('quiz_resp')}</div>
            </TextComponent>
            <div className={styles.userTableMainDiv}>
              <div  className={'tableWrapper tableNoShadow'}>
                <div className={'tableHeaderRow'}>
                  <div className={'tableData'}>{t('resp_id')}</div>
                  <div className={'tableData'}>{t('email')}</div>
                  <div className={'tableData'}>{t('score')}</div>
                  <div className={'tableData'}>{t('dur')}</div>
                </div>
                {
                  quizFormResponses.map((item) => (
                    <>
                      <div
                        key={item.responseId}
                        className={'tableRow'}
                        onClick={() => {
                          if (formId) {
                            navigate(`../responses/${item.responseId}`);
                          }
                        }}>
                        <div className={'tableData'}>{item.responseId}</div>
                        <div className={'tableData'}>{item.email}</div>
                        <div className={'tableData'}>{item.score}</div>
                        <div className={'tableData'}>{item?.duration ? `${Math.floor((item.duration / 1000 / 60) % 60).toString().padStart(2, "0")}:${Math.floor((item.duration / 1000) % 60).toString().padStart(2, "0")}` : "00:00"}</div>
                      </div>
                    </>
                  ))
                }
              </div>
              <div className={'mobileContainer'}>
                {
                  quizFormResponses.map((item, index) => (
                    <div className={'mobileCard'} key={item.responseId} >
                      <div className={'rowOne'}>
                        <CustomHeading headerType='h4'>
                          {item.email}
                        </CustomHeading>
                      </div>
                      <div className={'rowOne'}>
                        <div style={{ color: "#999999" }}>{item.responseId}</div>
                      </div>
                      <div className={'rowFour'}>
                        <div className={'diamond'}></div>
                        <div className={'line'}></div>
                        <div className={'diamond'}></div>
                      </div>
                      <div className={'rowOne'}>
                        <div>{t('score')}</div>
                        <div>{item.score}</div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div style={{ margin: "0px 0px 0px auto" }}>
              {responseCount > 10 ? <CustomPagination countPerPage={10} totalCount={responseCount} handlePagination={(res) => setQuizPagination(res.skip)} /> : ''}
            </div>
          </div>
          <div className={styles.quizContainers}>
            <div className={styles.quizSubHeadContainer}>
              <TextComponent textType="Header">
                <div>Score Board</div>
              </TextComponent>
              <div className={styles.endQuizContainer}>
                <QuizMode form={form} quiz={quizControl} setQuiz={setQuizControl} responseCount={responseCount} />
              </div>
            </div>
            <ScoreCard />
          </div>
        </>
      }
    </div>
  );
}
