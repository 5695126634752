import React, { Dispatch, SetStateAction } from 'react';
import styles from './profile-menu.module.scss';


export default function ProfileMenu(props : ProfileMenuProps) {
  return (
    <div className={`${styles.menu} ${props.isMenuOpen ? styles.menuActive : ""}`}>
     <div style={{display:"flex",width:"100%"}}>
      <div style={{width:"20%", background: "#00000096", visibility:`${props.isMenuOpen ? "visible" : "hidden"}`}} onClick={()=>{ props.setIsMenuOpen(!props.isMenuOpen) }}>
        {/* Message */}
      </div>
      <div style={{width:"80%",background: "var(--secondary-color)",position:"relative"}}>
      {props.children && props.children}
      </div>
     </div>
    </div>
  )
}

interface ProfileMenuProps{
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
    isMenuOpen:boolean;
    children?:React.ReactNode;
}
