import styles from './forms.module.scss';
import { Form, FormState } from '../../../store/models/form.interface';
import CustomText from '../../../components/v2/common/custom-bodyText/custom-text';
import CustomButton from '../../../components/v2/common/custom-button/custom-button';
import { TFunction } from 'i18next';
import svgNoForms from "../../../assets/v2/icons/no-forms.svg";
import svgRightArrowBlack from "../../../assets/v2/icons/right-arrow-black.svg";
import TextComponent from '../../../components/v2/common/text-component/text-component';

export default function FormsTable(props: FormsListProps) {

  return (
    <div className={styles.tableContainer}>
      {
        props.forms.length > 0 ? (
          <>
            <div className={'tableWrapper'}>
              <div className={'tableHeaderRow'}>
                <div className={'tableData'}>{`${props.t("table_form_name")}`}</div>
                <div className={'tableData'}>{`${props.t("table_form_created_by")}`}</div>
                <div className={'tableData'}>{`${props.t("table_form_created_on")}`}</div>
                <div className={'tableData'}>{`${props.t("form_details_access_mode")}`}</div>
                <div className={'tableData'}>{`${props.t("table_form_status")}`}</div>
              </div>
              {
                props.forms.map((item, index) => (
                  <div key={item.id} className={'tableRow isClickable'} onClick={() => { props.goToFormDetails(item.id, item.state) }}>
                    <div className={'tableData'}>{item.name}</div>
                    <div className={'tableData'}>{item.createdByUserName}</div>
                    {
                      item.createdAt &&
                      <div className={'tableData'}>{new Date(item.createdAt).toLocaleString()}</div>
                    }
                    <div className={'tableData'}>{item.isPublic ? `${props.t('forms_list_public')}` : `${props.t('forms_list_private')}`}</div>
                    <div className={'tableData'}>{item.state && props.t(item.state)}</div>
                  </div>
                ))
              }
            </div>
            <div className={'mobileContainer'}>
              {
                props.forms.map((item, index) => (
                  <div className={'mobileCard'} key={item.id} >
                    <div className={'rowOne'}>
                      <TextComponent textType='Header'>
                        <div className={styles.mobileTitle}>{item.name}</div>
                      </TextComponent>
                    </div>
                    <div>
                      <div style={{ color: "#999999", fontSize: "12px" }}>{item.createdByUserName}</div>
                    </div>
                    <div className={'rowOne'}>
                      {
                        item.createdAt &&
                        <div style={{ color: "#999999" }}>{new Date(item.createdAt).toLocaleString()}</div>
                      }
                      <div style={{ color: `${item.state === FormState.DRAFT ? '#642DFB' : ''} ${item.state === FormState.PUBLISHED ? "#15D503" : ""} ${item.state === FormState.WAITING_FOR_PUBLISH ? 'sandybrown' : ''}` }}>{item.state && item.state && props.t(item.state)}</div>
                    </div>
                    <div className={'rowFour'}>
                      <div className={'diamond'}></div>
                      <div className={'line'}></div>
                      <div className={'diamond'}></div>
                    </div>
                    <div className={'rowOne'}>
                      <CustomText textType='t5' style={{ fontWeight: "500" }}>
                        {item.isPublic ? `${props.t("forms_list_public")}` : `${props.t("forms_list_private")}`}
                      </CustomText>
                      <CustomButton
                        buttonType='button'
                        classType='secondary'
                        onClick={() => { props.goToFormDetails(item.id, item.state) }}
                        style={{
                          background: "none",
                          border: "none",
                          boxShadow: "none",
                          padding: "0",
                          fontSize: "12px",
                          fontWeight: "500"
                        }}>
                        <div className={'mobileViewAllContainer'}>
                          <div>{props.t("view_details")}</div>
                          <div style={{ width: "1rem", height: "1rem" }}>
                            <img src={svgRightArrowBlack} alt="no" />
                          </div>
                        </div>
                      </CustomButton>
                    </div>
                  </div>
                ))}
            </div>
          </>
        ) : (
          <div className={styles.noFormsContainer}>
            <div>
              <img src={svgNoForms} alt="no-forms" />
            </div>
            <CustomText textType='t4'>
              <div>{props.t("table_no_content", { tab: props.t(props.activeTab.toLowerCase()) })}</div>
            </CustomText>
          </div>
        )
      }
    </div>
  )
}

interface FormsListProps {
  forms: Form[];
  goToFormDetails: (id: string, state: FormState | undefined) => void;
  activeTab: string;
  t: TFunction<"translation", undefined>;
}
