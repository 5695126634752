export enum BlackListAction {
  ADD = "ADD",
  REMOVE = "REMOVE"
}

export interface BlackListRafflesDto {
  raffleId: string;
  action: BlackListAction;
  formId: string;
  responseId: string;
}
