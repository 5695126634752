import axios from "axios";
import { FormControl } from "../store/models/form-controls/form-control.interface";
import { GenerateApiUrl } from './generate-api-url';
import { CloneFormControlsReqDto } from "../store/models/form-controls/clone-form-control";
import { BlackListRafflesDto } from "../store/models/form-controls/blacklist-raffle";

export async function getControlsByFormId(formId: string): Promise<FormControl[]> {
  let url: string = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${formId}/form-controls`, true);
  const response = await axios.get<{ formControls: FormControl[] }>(url);
  return response.data.formControls;
}

export async function cloneFormControls(params: CloneFormControlsReqDto) {
  const url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${params.formId}/form-controls/clone`, true);
  const response = await axios.post(url, params);
  return response;
}

export async function getRaffleFormControl(formId: string) {
  const url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${formId}/form-controls/raffle`, true);
  const response = await axios.get<FormControl>(url);
  return response.data;
}

export async function getQuizFormControl(formId: string) {
  const url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${formId}/form-controls/quiz`, true);
  try {
    const response = await axios.get<FormControl>(url);
    return response.data;
  } catch (error) {
    return undefined;
  }
}

export async function blackListRaffleIds(controlId: string, body: BlackListRafflesDto) {
  const url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form-response/${controlId}/raffle/blacklist`, true);
  await axios.patch<void>(url, body);
}
