import styles from './page-control.module.scss';
import { DragEvent, useRef, useState } from 'react';
import { ControlItem } from '../control-item/control-item';
import { SectionControl } from '../section-control/section-control';
import { PageControlProps } from './page-control.props';
import CustomButton from '../../common/custom-button/custom-button';
import { BuilderPlaceholder } from '../builder-placeholder/builder-placeholder';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import svgDelete from "../../../../assets/v2/delete.svg";
import { useTranslation } from 'react-i18next';
import TextComponent from '../../common/text-component/text-component';
import ToolTip from '../../common/tool-tip/tool-tip';

export function PageControl(props: PageControlProps) {
  const { t } = useTranslation();
  const referenceDiv = useRef<HTMLDivElement>();
  const [blurPlaceholder, setBlurPlaceholder] = useState(false);

  const deleteControl = () => {
    props.deleteControl({ formControl: props.formControl, controlIndex: props.controlIndex, formId: props.formControl.formId, order: props.controlIndex, parentId: null, parentType: null });
  }

  const controlErrorMessage = useSelector((state: RootState) => { return state.validation.controlValidation });

  const onDropPage = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setBlurPlaceholder(false);
    const res = e.dataTransfer.types;
    let controlName: string | null = null;
    if (res && res.length > 0 && res[0] === 'create' && props.dragPlaceHolder.current) {
      const htmlCollection = props.dragPlaceHolder.current.parentNode?.children as HTMLCollection;
      if (htmlCollection) {
        const val = Array.from(htmlCollection).indexOf(props.dragPlaceHolder.current);
        props.hoverCtrlIndexRef.current = val;
      } else {
        props.hoverCtrlIndexRef.current = 0;
      }
      let pickedElement = props.pickedCtrlRef.current;
      if (pickedElement) controlName = pickedElement.getAttribute('ctrl-type');
      if (controlName == null) return;
      props.createControl({ controlName: controlName, controlIndex: props.hoverCtrlIndexRef.current, parentIndex: props.controlIndex, parentId: props.formControl.id, parentType: "page" });
      props.hoverCtrlIndexRef.current = undefined;
    }
  }

  const ondropReorder = (e: DragEvent<HTMLDivElement>) => {
    setBlurPlaceholder(false);
    const res = e.dataTransfer.types;
    if (res && res.length > 0 && res[0] === 're-order') {
      props.hoverCtrlIndexRef.current = 0;
      props.hoverCtrlParentRef.current = props.formControl;
      props.hoverCtrlParentIndexRef.current = props.controlIndex;
      props.hoverCtrlId.current = props.formControl.id;
      props.reorderControls({ controlId: props.pickedCtrlId.current, formId: props.formControl.formId, newParentId: props.formControl.id, newPosition: 0, oldParentId: props.pickedCtrlParentRef.current?.id ? props.pickedCtrlParentRef.current?.id : '', oldPosition: props.pickedCtrlIndexRef.current ? props.pickedCtrlIndexRef.current : 0 });
      props.hoverCtrlIndexRef.current = undefined;
      props.hoverCtrlParentRef.current = undefined;
      props.hoverCtrlParentIndexRef.current = undefined;
      props.hoverCtrlId.current = '';
      e.preventDefault();
      e.stopPropagation();
    }
  }

  const onDragEnter = (e: DragEvent<HTMLDivElement>) => {
    setBlurPlaceholder(true);
    if (props.formControl.children.length === 0) {
      const res = e.dataTransfer.types;
      const element = e.target as HTMLDivElement;
      if (res && res.length > 0 && res[0] === 're-order' && props.pickedCtrlRef.current) {
        props.hoverCtrlRef.current = e.target as HTMLDivElement;
        props.hoverCtrlIndexRef.current = 0;
        props.hoverCtrlParentRef.current = props.formControl;
        props.hoverCtrlParentIndexRef.current = props.controlIndex;
        props.hoverCtrlId.current = props.formControl.id;
        element.appendChild(props.pickedCtrlRef.current);
      } if (res && res.length > 0 && res[0] === 'create' && props.dragPlaceHolder.current) {
        props.dragPlaceHolder.current.remove();
        const div = document.createElement('div');
        referenceDiv.current = div;
        div.classList.add(styles.addPlaceholderDiv);
        element.appendChild(div);
      }
    }
  }

  return (
    <>
      <div
        page-id={props.formControl.id}
        className={`${styles.page} ${props.controlIndex === 0 ? styles.topPage : ""} ${props.formControls.length === 1 && props.formControl.children && props.formControl.children.length === 0 ? styles.soloPage : ""} ${controlErrorMessage != null && controlErrorMessage[props.formControl.formId] != null && controlErrorMessage[props.formControl.formId][props.formControl.id] != null ? styles.errorBoundary : ''}`}
        onDrop={(e) => onDropPage(e)}
        onDragOver={(e) => e.preventDefault()}
        draggable={false}
        style={{ background: props.form?.config?.theme?.background ? props.form?.config?.theme?.background : 'var(--secondary-top-bar-color)' }}
      >
        {
          props.formControl.children.length > 0 && props.formControl.children.map((value, index) => {
            if (value.controlType !== 'section') {
              return (
                <ControlItem
                  key={value.id}
                  formControl={value}
                  controlIndex={index}
                  parent={props.formControl}
                  parentIndex={props.controlIndex}
                  grandParent={undefined}
                  grandParentIndex={undefined}
                  isDraggable={props.isDraggable}
                  mouseHeight={props.mouseHeight}
                  dragPlaceHolder={props.dragPlaceHolder}
                  setIsDraggable={props.setIsDraggable}
                  reorderControls={props.reorderControls}
                  pickedCtrlGrandParentIndexRef={props.pickedCtrlGrandParentIndexRef}
                  pickedCtrlGrandParentRef={props.pickedCtrlGrandParentRef}
                  pickedCtrlParentIndexRef={props.pickedCtrlParentIndexRef}
                  pickedCtrlParentRef={props.pickedCtrlParentRef}
                  hoverCtrlRef={props.hoverCtrlRef}
                  pickedCtrlIndexRef={props.pickedCtrlIndexRef}
                  pickedCtrlRef={props.pickedCtrlRef}
                  hoverCtrlIndexRef={props.hoverCtrlIndexRef}
                  hoverCtrlParentIndexRef={props.hoverCtrlParentIndexRef}
                  hoverCtrlGrandParentIndexRef={props.hoverCtrlGrandParentIndexRef}
                  hoverCtrlParentRef={props.hoverCtrlParentRef}
                  deleteControl={props.deleteControl}
                  setSidePaneTabvalue={props.setSidePaneTabvalue}
                  setActiveFormControl={props.setActiveFormControl}
                  setIsActionPanelOpen={props.setIsActionPanelOpen}
                  isActionPanelOpen={props.isActionPanelOpen}
                  hoverCtrlId={props.hoverCtrlId}
                  pickedCtrlId={props.pickedCtrlId}
                  hoverCtrlGrandParentRef={props.hoverCtrlGrandParentRef}
                  pickedCtrlParentDomRef={props.pickedCtrlParentDomRef}
                  setActionBarTitle={props.setActionBarTitle}
                  createControl={props.createControl}
                  isQuizEnabled={props.isQuizEnabled}
                  isLoading={props.isLoading}
                />
              )
            }
            else return (
              <SectionControl
                key={value.id}
                formControl={value}
                controlIndex={index}
                parent={props.formControl}
                parentIndex={props.controlIndex}
                grandParent={undefined}
                grandParentIndex={undefined}
                isDraggable={props.isDraggable}
                mouseHeight={props.mouseHeight}
                dragPlaceHolder={props.dragPlaceHolder}
                setIsDraggable={props.setIsDraggable}
                reorderControls={props.reorderControls}
                pickedCtrlRef={props.pickedCtrlRef}
                pickedCtrlIndexRef={props.pickedCtrlIndexRef}
                pickedCtrlParentRef={props.pickedCtrlParentRef}
                hoverCtrlParentIndexRef={props.hoverCtrlParentIndexRef}
                pickedCtrlGrandParentRef={props.pickedCtrlGrandParentRef}
                pickedCtrlGrandParentIndexRef={props.pickedCtrlGrandParentIndexRef}
                hoverCtrlRef={props.hoverCtrlRef}
                hoverCtrlIndexRef={props.hoverCtrlIndexRef}
                hoverCtrlParentRef={props.hoverCtrlParentRef}
                pickedCtrlParentIndexRef={props.pickedCtrlParentIndexRef}
                hoverCtrlGrandParentIndexRef={props.hoverCtrlGrandParentIndexRef}
                deleteControl={props.deleteControl}
                createControl={props.createControl}
                setSidePaneTabvalue={props.setSidePaneTabvalue}
                setActiveFormControl={props.setActiveFormControl}
                setIsActionPanelOpen={props.setIsActionPanelOpen}
                isActionPanelOpen={props.isActionPanelOpen}
                hoverCtrlId={props.hoverCtrlId}
                pickedCtrlId={props.pickedCtrlId}
                hoverCtrlGrandParentRef={props.hoverCtrlGrandParentRef}
                pickedCtrlParentDomRef={props.pickedCtrlParentDomRef}
                setActionBarTitle={props.setActionBarTitle}
                isQuizEnabled={props.isQuizEnabled}
                isLoading={props.isLoading}
              />
            )
          })
        }
        {
          props.formControl.children.length === 0 &&
          <div
            className={styles.emptyPage}
            onDragEnter={(e) => { onDragEnter(e) }}
            onDragLeave={(e) => {
              setBlurPlaceholder(false);
              const res = e.dataTransfer.types;
              if (res && res.length > 0 && res[0] === 'create' && props.dragPlaceHolder.current) {
                referenceDiv.current?.remove();
              }
            }}
            onDrop={(e) => { ondropReorder(e); }}
            style={{ position: 'relative' }}
          >
            {
              props.formControls.length === 1 && props.formControl.children.length === 0 &&
              <div className={styles.builderPlaceholder}>
                {
                  controlErrorMessage != null && controlErrorMessage[props.formControl.formId] != null && controlErrorMessage[props.formControl.formId][props.formControl.id] != null
                    ?
                    <TextComponent textType='Error'>
                      <div style={blurPlaceholder ? { opacity: '0.25' } : {}}>{t("validation_control_minimum_count_err")}</div>
                    </TextComponent>
                    :
                    <div style={blurPlaceholder ? { opacity: '0.25' } : {}}>
                      <BuilderPlaceholder></BuilderPlaceholder>
                    </div>
                }
              </div>
            }
            {
              props.formControls.length > 1 && controlErrorMessage != null && controlErrorMessage[props.formControl.formId] != null && controlErrorMessage[props.formControl.formId][props.formControl.id] != null &&
              <TextComponent textType='Error'>
                <div style={blurPlaceholder ? { opacity: '0.25' } : {}} className={`${styles.pageErr}`}>Minimum 1 control is required</div>
              </TextComponent>
            }
          </div>
        }
        <div className={styles.deleteContainer} draggable={false}>
          {
            props.formControls.length === 1
              ?
              <ToolTip content={`${t("validation_control_minimum_count_err")}`} position='left'>
                <CustomButton buttonType={'button'} classType={'secondary'} onClick={() => { }} style={{  borderRadius:'48px',background:'var(--accent-color)',border:'none',width: 'fit-content', padding: '6px', height: '2rem', }} disabled={true}>
                  <img style={{ width: '1.2rem' }} src={svgDelete} alt="delete" />
                </CustomButton>
              </ToolTip>
              :
              <ToolTip content={'Delete page'} position='left'>
                <CustomButton buttonType={'button'} classType={'secondary'} onClick={deleteControl} style={{ borderRadius:'48px',background:'var(--accent-color)',border:'none', width: 'fit-content', alignSelf: 'flex-end', padding: '6px', height: '2rem', }}>
                  <img style={{ width: '1.2rem' }} src={svgDelete} alt="delete" />
                </CustomButton>
              </ToolTip>
          }
        </div>
      </div>
    </>
  )
}
