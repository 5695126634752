import React, { useEffect, useState } from 'react'
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import styles from './org-invitations.module.scss'
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import svgRevokee from "../../../../assets/v2/revoke.svg";
import { OrgInvitationMobileRowProps } from './org-invitation.props';

export default function OrgInvitationMobileRow(props : OrgInvitationMobileRowProps) {

  const [email, setEmail] = useState("");
  const [invitedEmail, setInvitedEmail] = useState("");

  useEffect(() => {
    setInitialValues();
  }, [])

  async function setInitialValues() {
    setEmail(await props.resolveInviteItem(props.item));
    setInvitedEmail(await props.getInvitedEmail(props.item));
  }

  return (
    <>
      <div className={styles.rowOne}>
        <TextComponent textType='Header'>
          {email || props.item.userIdOrEmail}
        </TextComponent>
      </div>
      <div className={'rowOne'}>
        <div className={styles.mailText}>
          {invitedEmail || props.item.createdByUserId}
        </div>
        <div>
          {new Date(props.item.createdAt).toLocaleString()}
        </div>
      </div>
      <div className={'rowFour'}>
        <div className={'diamond'}></div>
        <div className={'line'}></div>
        <div className={'diamond'}></div>
      </div>
      <div className={'rowOne'}>
        {props.item.status}
        <CustomButton
          disabled={props.item.status === 'PENDING' ? false : true}
          style={{
            background: "var(--accent-color)",
            padding: " 12px",
            borderRadius: '50%',
            border: 'none',
            boxSizing: "border-box",
            width: "fit-content"
          }}
          buttonType='button'
          classType='secondary'
          onClick={() => {
            props.setUserId(props.item.id)
            props.setShowModal(true);
          }}
        >
          <div className={styles.isImageHolder}>
            <img style={{ width: "1.2rem" }} src={svgRevokee} alt="filter" />
          </div>
        </CustomButton>
      </div>
    </>
  )
}


