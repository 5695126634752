import { useEffect, useRef, useState } from 'react';
import styles from './top-menu.module.scss';
import ToolTip from '../tool-tip/tool-tip';
import { useNavigate, useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web/lib/useKeycloak';
import CustomSelect from '../custom-select/custom-select';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import store, { RootState } from '../../../../store/store';
import svgPaneDelete from "../../../../assets/v2/close.svg";
import Modal from '../modal/modal';
import { MultiValue, SingleValue } from 'react-select';
import AddOrganizationModal from '../../../../pages/v2/setting/add-organization/add-organization-modal';
import CustomButton from '../custom-button/custom-button';
import { DropdownDataItem } from '../custom-select/custom-select.props';
import { SetUserMetadataDto } from '../../../../store/models/profile.interface';
import { UserMetadataKey } from '../../../../store/models/user-metadata-key.enum';
import { setUserMetadataByKey } from '../../../../api/profile';
import toast from 'react-hot-toast';
import BreadCrumbs from '../bread-crumbs/bread-crumbs';
import TextComponent from '../text-component/text-component';
import { useTranslation } from 'react-i18next';

export default function TopMenu() {
  const { t } = useTranslation();
  const [toggleAccntSettings, setToggleAccntSettings] = useState(false);
  const [toggleSettings, setToggleSettings] = useState(false);
  const [toggleSwitchOrg, setToggleSwitchOrg] = useState(false);
  const [toggleAddOrg, setToggleAddOrg] = useState(false);
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const profile = useSelector((state: RootState) => state.user.profileInfo);
  const [activeOrg, setActiveOrg] = useState<MultiValue<DropdownDataItem> | SingleValue<DropdownDataItem>>();
  const [isModified, setIsModified] = useState<Boolean>(false);
  const handleClick = useRef<HTMLDivElement>(null);
  const [activePageButton, setActivePageButton] = useState(false);
  const [activeOrgName, setActiveOrgName] = useState('');
  const user: any = useSelector((state: RootState) => state.user.data);
  const { workspaceId } = useParams();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (handleClick.current && !handleClick.current.contains(event.target)) {
        setToggleSettings(false);
        setToggleAccntSettings(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    let data: DropdownDataItem = {
      label: "", value: ""
    };
    if (workspaceId === profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]) {
      profile.organizations.forEach(item => {
        if (item.name === profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]) {
          data = {
            label: `${item.displayName} (${item.name})`,
            value: item.name
          }
        }
      });
      setActiveOrgName(profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION])
      setActiveOrg(data);
    }
  }, [profile, workspaceId]);

  useEffect(() => {
    if (window.location.pathname.includes("feedback")) {
      setActivePageButton(true);
    } else {
      setActivePageButton(false);
    }
  }, [window.location.pathname])

  useEffect(() => {
    if (activeOrg && (activeOrg as DropdownDataItem).value === profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  }, [activeOrg]);

  const logoutAction = () => {
    keycloak.logout({
      redirectUri: window.location.origin
    });
  }

  async function switchOrg() {
    try {
      const reqData: SetUserMetadataDto = {
        value: (activeOrg as DropdownDataItem).value
      };
      const res = await setUserMetadataByKey(UserMetadataKey.ACTIVE_ORGANIZATION, reqData);
      if (res.status === 201) {
        const newOrgName = (activeOrg as SingleValue<DropdownDataItem>)?.value as string;
        window.history.pushState(null, '', `${window.location.origin}/o/${newOrgName}/dashboard`);
        window.location.reload();
      }
    } catch (err) {
      toast.error(`${err}`);
    }
  }

  return (
    <>
      <div className={styles.topContainer}>

        <div className={styles.mainContainer}>
          <div className={styles.titleContent}>
            {
              !window.location.pathname.includes("profile") && !window.location.pathname.includes("feedback") &&
              <div style={{ display: 'flex', gap: '0.5rem' }}>
                <div style={{ cursor: 'pointer' }} onClick={() => { setToggleSwitchOrg(true); setToggleAccntSettings(false); }}>
                  <span className={styles.orgTitle} title={activeOrgName}>{activeOrgName ? activeOrgName.toUpperCase() : '-'}</span>
                </div>
                <div style={{ fontSize: '16px', fontWeight: '300', color: ' var(--font-color)' }}> ~ </div>
              </div>
            }
            <BreadCrumbs />
          </div>
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <ToolTip position='left' content={`${t("account")}`}>
              <div className={`${toggleAccntSettings ? styles.activeProfile : ''}`}>
                <div className={`${styles.profileCircle} ${toggleAccntSettings ? styles.profileCircleActive : ''}`} onClick={() => { setToggleAccntSettings(!toggleAccntSettings) }}>
                  {user.email[0].toLocaleUpperCase()}{user.email[1].toLocaleUpperCase()}
                </div>
                <div className={styles.profileMenu} ref={handleClick}>
                  <div onClick={() => { setToggleAddOrg(true); setToggleAccntSettings(false); }}>{`${t("add_organization")}`}</div>
                  <div className={styles.line}></div>
                  <div onClick={() => { setToggleSwitchOrg(true); setToggleAccntSettings(false); }}>{`${t("switch_workspace")}`}</div>
                  <div className={styles.line}></div>
                  <div onClick={() => { setToggleAccntSettings(false); navigate('/profile/invites'); }}>{`${t("my_invitation")}`}</div>
                  <div className={styles.line}></div>
                  <div onClick={() => { setToggleAccntSettings(false); navigate('/profile'); }}>{`${t("my_profile")}`}</div>
                  <div className={styles.line}></div>
                  <div onClick={() => { logoutAction() }}>{`${t("logout")}`}</div>
                </div>
              </div>
            </ToolTip>
          </div>
        </div>
      </div>
      <Modal showModal={toggleSwitchOrg} setShowModal={setToggleSwitchOrg}
        header={
          <div className={styles.endRaffleHeader}>
            <div className={styles.headerContainer}>
              <TextComponent textType='Header'>
                {`${t("switch_workspace_text")}`}
              </TextComponent>
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <CustomButton
                  style={{
                    background: "var(--accent-color)",
                    padding: "0.2rem",
                    borderRadius: '50%',
                    border: 'none',
                    boxSizing: "border-box",
                    display: 'flex'
                  }}
                  buttonType='button'
                  classType='tertiary'
                  onClick={() => { setToggleSwitchOrg(false); }}>
                  <img style={{ width: '1.2rem' }} src={svgPaneDelete} alt="" />
                </CustomButton>
              </div>
            </div>
          </div>
        }
        content={
          <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem', marginBottom: '2rem' }}>
              <TextComponent textType='Content'>
                <div>{`${t("switch_workspace_warning")}`}</div>
              </TextComponent>
              <CustomSelect
                data={
                  profile.organizations.map(item => {
                    const data: DropdownDataItem = {
                      label: `${item.displayName} (${item.name})`,
                      value: item.name
                    }
                    return data;
                  })}
                selectStyleType={"colored"}
                selectedData={(item) => setActiveOrg(item)}
                value={activeOrg}
              />
            </div>
            <div style={{ width: '5rem', marginLeft: 'auto' }}>
              <CustomButton buttonType={'button'} classType={'quaternary'} disabled={!isModified} onClick={() => { switchOrg() }}>{`${t("apply")}`}</CustomButton>
            </div>
          </>
        }
      />
      <AddOrganizationModal toggleAddOrg={toggleAddOrg} setToggleAddOrg={setToggleAddOrg} />
    </>
  )
}
