import styles from './custom-search.module.scss';
import { CustomSearchProps } from './custom-search.props';
import svgSearch from "../../../../assets/v2/icons/search.svg";

export function CustomSearch(props: CustomSearchProps) {
  return (
    <div className={styles.search} >
      <input type="text" placeholder={props.placeHolder}
        style={props.styles}
        className={styles.searchBox}
        onChange={(e) => { props.searchWord(e.target.value); }} />
      <img src={svgSearch} alt="No" className={styles.searchIcon} />
    </div>
  )
}
