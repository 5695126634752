import { useSelector } from 'react-redux';
import { getUsageFromAuditLogs } from '../../../../api/audit-log';
import { RootState } from '../../../../store/store';
import styles from './metrics.module.scss';
import { useEffect, useState } from 'react';
import { AuditLogUsageCountDto } from '../../../../store/models/audit-log.interface';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import ToolTip from '../../../../components/v2/common/tool-tip/tool-tip';
import svgLoading from "../../../../assets/v2/icons/loading.svg";
import svgRefresh from "../../../../assets/v2/refresh.svg";
import { useTranslation } from 'react-i18next';
import TextComponent from '../../../../components/v2/common/text-component/text-component';

export function Metrics() {
  const subscription = useSelector((state: RootState) => state.subscription.data);
  const [usage, setUsage] = useState<AuditLogUsageCountDto>({ usage: [] });
  const { t } = useTranslation();
  const [refreshPage, setRefreshPage] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      setRefreshPage(true);
      const data = await getUsageFromAuditLogs({ fromDate: subscription.lastPaymentDate, toDate: subscription.nextPaymentDate });
      setUsage(data);
      setRefreshPage(false);
    } catch (err) {
      setRefreshPage(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [subscription]);

  return (
    <div className={styles.container}>
      <div style={{ display: 'flex' }}>
        <div className={styles.titleContainer}>
          <TextComponent textType='Header'>{t("usage_metrics")}</TextComponent>
          <TextComponent textType='Content'>{t("usage_metrics_sub")}</TextComponent>
        </div>
        <div>
          <ToolTip position='bottom' content={`${t("refresh")}`}>
            <CustomButton
              buttonType='button'
              classType='tertiary'
              style={{
                background: "var(--accent-color)",
                padding: "8px",
                borderRadius: '50%',
                border: 'none',
                boxShadow: 'none',
                display: 'flex'
              }}
              disabled={refreshPage}
              onClick={() => fetchData()}
            >
              <div className={styles.isImageHolder}>
                {refreshPage ? <img src={svgLoading} /> : <img src={svgRefresh} />}
              </div>
            </CustomButton>
          </ToolTip>
        </div>
      </div>
      <div className={styles.flexContainer}>
        <Metric title={t("nav_bar_responses")} value={usage.usage.find(i => i.action === 'response:submitted')?.count} defaultValue={'-'} />
        <Metric title={t("active_users")} value={usage.usage.find(i => i.action === 'user:login')?.count} defaultValue={'-'} />
        <Metric title={t("sms")} value={usage.usage.find(i => i.action === 'sms:dispatched')?.count} defaultValue={'-'} />
        <Metric title={t("storage")} value={usage.usage.find(i => i.action === 'storage:added')?.count} unit="MB" defaultValue={'0 '} />
      </div>
    </div>
  )
}

interface MetricProps {
  title: string;
  value?: number;
  unit?: string;
  defaultValue: string;
}

const Metric = ({ title, value, unit, defaultValue }: MetricProps) => {
  return (
    <div className={styles.metricContainer}>
      <TextComponent textType="Header">{title}</TextComponent>
      <div>{value ?? defaultValue}{unit && <span>{unit}</span>}</div>
    </div>
  );
};