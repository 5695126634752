import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './billing.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../store/store';
import { toast } from 'react-hot-toast';
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import { UserMetadataKey } from '../../../../store/models/user-metadata-key.enum';
import { GetPaymentAccountsRespItem } from '../../../../store/models/user.interface';
import { addCard, getPaymentAccountById, getPaymentAccounts } from '../../../../api/billing-accounts';
import CustomText from '../../../../components/v2/common/custom-bodyText/custom-text';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import { changePaymentMethod } from '../../../../api/subscription';
import { getAllProfileInfoForAppInit } from '../../../../api/profile';
import { setProfileInfo } from '../../../../store/slices/user.slice';
import Modal from '../../../../components/v2/common/modal/modal';
import hasSufficientScopes from '../../../../util/access-validator/access-validator';

export default function Billing() {
  const { t } = useTranslation();
  const dispatch: RootDispatch = useDispatch();
  const latestSubscription = useSelector((rootState: RootState) => rootState.subscription.data);
  const [cardDetails, setCardDetails] = useState<GetPaymentAccountsRespItem>();
  const currentOrg = useSelector((state: RootState) => state.user.profileInfo.organizations.find(org => org.name === state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [billingAcc, setBillingAcc] = useState<GetPaymentAccountsRespItem[]>();
  const [activeCard, setActiveCard] = useState<GetPaymentAccountsRespItem>();
  const [isError, setIsError] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  useEffect(() => {
    if (currentOrg?.paymentMethodId) {
      fetchCard(currentOrg.paymentMethodId);
    }
  }, [currentOrg]);

  useEffect(() => {
    getBillingAccounts();
  }, []);

  const getBillingAccounts = async () => {
    try {
      const res = await getPaymentAccounts();
      setBillingAcc(res.cards);
    } catch (err) { }
  }

  const fetchCard = async (paymentMethodId: string) => {
    try {
      const card = await getPaymentAccountById(paymentMethodId);
      setCardDetails(card);
    } catch (err) { }
  }

  const addPaymentAccount = async () => {
    try {
      const res = await addCard();
      if (res) {
        window.location.href = res;
      } else {
        toast.error(t("attach_card_error"));
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  const modifyPaymentCard = async () => {
    if (!activeCard) {
      setIsError(true);
      return;
    }
    setIsBtnLoading(prev => true);
    try {
      await changePaymentMethod(activeCard.id);
      toast.success(t("biling_acc_update_success"));
      try {
        const res = await getAllProfileInfoForAppInit();
        dispatch(setProfileInfo(res));
      } catch (e) {
        console.log("Error while profile init ", e);
      }
      setIsModalOpen(false);
      setActiveCard(undefined);
      setIsError(false);
    } catch (err) {
      toast.error(t("billing_acc_update_failure"));
    }
    setIsBtnLoading(prev => false);
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <TextComponent textType='Header'>{t("payment_details")}</TextComponent>
        <TextComponent textType='Content'>{t("payment_details_sub")}</TextComponent>
      </div>
      {
        latestSubscription &&
        <div className={styles.paymentMenthodContainer}>
          <div className={styles.billingInfoContainer}>
            {
              cardDetails &&
              <div className={`${styles.cardDetailsBilling}`}>
                <div className={styles.cardDetailsContainer}>
                  <CustomText textType='t4'>{`${cardDetails.name}`}</CustomText>
                  <div>
                    <CustomText textType='t5'>{`**** **** **** ${cardDetails.last4}`}</CustomText>
                  </div>
                  <div>
                    <CustomText textType='t6'>exp</CustomText>
                    <CustomText textType='t5'>{`${cardDetails.expMonth}`} / {`${cardDetails.expYear}`}</CustomText>
                  </div>
                </div>
              </div>
            }
            {
              hasSufficientScopes(["subscription:update"]) && <div className={styles.changeBillingText} onClick={() => setIsModalOpen(true)}>{t("change_billing_account")}</div>
            }
          </div>
        </div>
      }
      <Modal showModal={isModalOpen} setShowModal={setIsModalOpen}
        header={
          <div className={styles.headingContainer}>
            <TextComponent textType='Header'>{t("change_payment_method")}</TextComponent>
            <TextComponent textType='Content'>{t("change_payment_method_sub")}</TextComponent>
          </div>
        }
        content={
          <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
            <div className={styles.cardsContainer}>
              {
                billingAcc
                  ?
                  billingAcc.map((account, index) => {
                    return (
                      <div key={index} className={`${styles.cardDetails} ${account.id === activeCard?.id ? styles.activeCard : ''}`} onClick={() => {
                        setIsError(false);
                        if (account.id === activeCard?.id) {
                          setActiveCard(undefined);
                        } else {
                          setActiveCard(account);
                        }
                      }}>
                        <div className={styles.cardDetailsContainer}>
                          <CustomText textType='t4'>{`${account.name}`}</CustomText>
                          <div>
                            <CustomText textType='t5'>{`**** **** **** ${account.last4}`}</CustomText>
                          </div>
                          <div>
                            <CustomText textType='t6'>exp</CustomText>
                            <CustomText textType='t5'>{`${account.expMonth}`} / {`${account.expYear}`}</CustomText>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  :
                  <div>{t("cards_not_fetched")}</div>
              }
              <div className={styles.cardDetails} onClick={() => addPaymentAccount()}>
                <CustomText textType='t6'><div>{`${t("add_payment_method")}`}</div></CustomText>
              </div>
            </div>
            {
              isError &&
              <TextComponent textType='Error'>{t("select_card")}</TextComponent>
            }
            <div style={{ display: 'flex', gap: '1rem', width: '50%' }}>
              <CustomButton buttonType='button' classType='quaternary' onClick={modifyPaymentCard} isLoading={isBtnLoading}>{t("apply")}</CustomButton>
              <CustomButton buttonType='button' classType='secondary' onClick={() => { setIsModalOpen(false); setIsError(false); setActiveCard(undefined); }}>{t("close")}</CustomButton>
            </div>
          </div>
        }
      />
    </div>
  )
}
