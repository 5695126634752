import RouteComponent from "./routes/routes";
import { SocketInit, SocketContext } from "./context/socket";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import CustomToast from "./components/v2/common/toast/toast";
import { UserMetadataKey } from "./store/models/user-metadata-key.enum";
import { useEffect } from "react";
import { getI18n } from "react-i18next";

function App() {
  const token = useSelector((state: RootState) => state.token.data);
  const activeOrg = useSelector((state: RootState) => state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);
  const profileInfo = useSelector((state: RootState) => state.user.profileInfo);

  useEffect(() => {
    if (profileInfo?.metadata?.language) {
      const i18N = getI18n();
      i18N.changeLanguage(profileInfo?.metadata?.language);
    }
  }, [profileInfo]);

  return (
    <>
      {
        <SocketContext.Provider value={SocketInit(token, activeOrg)}>
          <CustomToast />
          <RouteComponent />
        </SocketContext.Provider>
      }
    </>
  );
}

export default App;
