export const PROPERTIES_TYPE: Map<string, PropertyDefinition> = new Map([
  ["isMandatory", { name: 'properties_constants_required', propertyType: "checkbox", tip: 'properties_constants_required_tip' }],
  ["placeHolder", { name: 'properties_constants_placeholder', propertyType: "text", tip: 'properties_constants_placeholder_tip' }],
  ["maxRows", { name: 'properties_constants_maximum_rows', propertyType: "number", tip: 'properties_constants_maximum_rows_tip', helperText: 'properties_constants_maximum_rows_helper' }],
  ["maxCols", { name: "Max Cols", propertyType: "text", tip: "placeholder" }],
  ["helperText", { name: "Helper text", propertyType: "text", tip: "placeholder" }],
  ["minDate", { name: 'properties_constants_start_date_limit', propertyType: "date", tip: 'properties_constants_start_date_limit_tip' }],
  ["maxDate", { name: 'properties_constants_end_date_limit', propertyType: "date", tip: 'properties_constants_end_date_limit_tip', helperText: 'properties_constants_end_date_limit_helper' }],
  ["minTime", { name: 'properties_constants_start_time_limit', propertyType: "time", tip: 'properties_constants_start_time_limit_tip' }],
  ["maxTime", { name: 'properties_constants_end_time_limit', propertyType: "time", tip: 'properties_constants_end_time_limit_tip', helperText: 'properties_constants_end_time_limit_helper' }],
  ["minValue", { name: "properties_constants_minimum_number", propertyType: "number", tip: "properties_constants_minimum_number_tip" }],
  ["maxValue", { name: "properties_constants_maximum_number", propertyType: "number", tip: "properties_constants_maximum_number_tip", helperText: "properties_constants_maximum_number_helper" }],
  ["maxlength", { name: "properties_constants_maximum_length", propertyType: "number", tip: "properties_constants_maximum_length_tip" }],
  ["layout", { name: "properties_constants_layout", propertyType: "select", tip: "properties_constants_layout_tip" }],
  ["maxCharacter", { name: "properties_constants_maximum_character", propertyType: "number", tip: "properties_constants_maximum_character_tip", helperText: "properties_constants_maximum_character_helper" }],
  ["formatValidation", { name: "Format Validation", propertyType: "text", tip: "placeholder" }],
  ["maxFileCount", { name: "properties_constants_maximum_file_count", propertyType: "number", tip: "properties_constants_maximum_file_count_tip", helperText: "properties_constants_maximum_file_count_helper" }],
  ["maxFileSize", { name: "properties_constants_maximum_file_size", propertyType: "number", tip: "properties_constants_maximum_file_size_tip", helperText: "properties_constants_maximum_file_size_helper" }],
  ["acceptType", { name: 'properties_constants_accept_type', propertyType: "text", tip: 'properties_constants_accept_type_tip' }],
  ["textStyle", { name: 'properties_constants_text_style', propertyType: "button", tip: "properties_constants_text_style_tip" }],
]);

export const SORTED_PROPERTIES_LABEL = {
  label: ["textStyle"],
  text: ["isMandatory", "placeHolder", "maxCharacter", "textStyle"],
  textarea: ["isMandatory", "placeHolder", "maxCharacter", 'maxRows', "textStyle"],
  radio: ["isMandatory", "layout", "textStyle"],
  checkbox: ["isMandatory", "layout", "textStyle"],
  date: ["isMandatory", "minDate", "maxDate", "textStyle"],
  time: ["isMandatory", "minTime", "maxTime", "textStyle"],
  dropdown: ["isMandatory", "textStyle"],
  file: ["isMandatory", "acceptType", "maxFileCount", "maxFileSize", "textStyle"],
  number: ["isMandatory", "placeHolder", "minValue", "maxValue", "textStyle"],
  section: ["textStyle"]
}

export const BUTTON_TIP = {
  bold: "properties_constants_bold",
  italic: "properties_constants_italic",
  underline: "properties_constants_underline"
}

interface PropertyDefinition {
  name: string;
  propertyType: string;
  tip: string;
  helperText?: string;
}
