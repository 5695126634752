import { useContext, useEffect, useState } from 'react'
import styles from './user-invitations.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import store, { RootDispatch, RootState } from '../../../../store/store';
import { acceptInviteToOrganizationThunk, declineInviteToOrganizationThunk, getInvitationsThunk, getUserThunk } from '../../../../store/thunks/user.thunk';
import NoContent from '../../../../components/v2/common/no-content/no-content';
import CustomHeading from '../../../../components/v2/common/custom-heading/custom-heading';
import UserInvitationRow from './user-invitation-row';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import { ACTIVE_PAGE_STATUS } from '../../../../store/models/active-page';
import { activePageThunk } from '../../../../store/thunks/active-page.thunk';
import { setNewData } from '../../../../store/slices/active-page.slice';
import { SocketContext } from '../../../../context/socket';
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import { GetInvitationsRespItem, OrgInviteReqDto } from '../../../../store/models/user.interface';
import toast from 'react-hot-toast';
import { getAllProfileInfoForAppInit } from '../../../../api/profile';
import { setProfileInfo } from '../../../../store/slices/user.slice';
import Modal from '../../../../components/v2/common/modal/modal';
import svgCross from "../../../../assets/v2/close.svg";
import svgTick from "../../../../assets/v2/icons/tick.svg";
import SecondaryTopBar from '../../../../components/v2/common/secondary-top-bar/secondary-top-bar';
import SettingRefreshComponent from '../../setting/setting-refresh-component';
import { useTranslation } from 'react-i18next';

export default function UserInvitations() {
  const { t } = useTranslation();
  const dispatch: RootDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const userInvitations = useSelector((state: RootState) => state.user.userInvitations);
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const activePage = useSelector((state: RootState) => state.activePage.activePage);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [email, setEmail] = useState('');
  const [invitationDetails, setInvitationDetails] = useState<GetInvitationsRespItem>();

  useEffect(() => {
    // on page load
    getLatestData();
  }, []);

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activePage])
  //

  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    dispatch(activePageThunk({ socket: socket, payload: { pageName: "my-invitations", status } }));
  }

  useEffect(() => {
    if (newData) {
      getLatestData();
      dispatch((setNewData({ action: "" })));
    }
  }, [newData])


  const getLatestData = async () => {
    setRefreshPage(true);
    await dispatch(getInvitationsThunk());
    setRefreshPage(false);
  }

  const acceptInvite = async (item: GetInvitationsRespItem) => {
    try {
      const token = { code: item.token?.token as string };
      const tokenB64 = btoa(JSON.stringify(token));
      const data: OrgInviteReqDto = {
        orgId: item.organizationId,
        token: tokenB64,
      };
      await dispatch(acceptInviteToOrganizationThunk({ inviteId: item.id, data: data })).unwrap();
      toast.success(t("invite_accepted_toast"));
      const res = await getAllProfileInfoForAppInit();
      if (res) {
        store.dispatch(setProfileInfo(res));
      }
    } catch (err) {
      console.log("err", err);
      toast.error(t("invite_accept_error_toast"));
    }
  }

  const declineInvite = async () => {
    try {
      if (invitationDetails) {
        const token = { code: invitationDetails.token?.token as string };
        const tokenB64 = btoa(JSON.stringify(token));
        const data: OrgInviteReqDto = {
          orgId: invitationDetails.organizationId,
          token: tokenB64,
        };
        await dispatch(declineInviteToOrganizationThunk({ inviteId: invitationDetails.id, data: data })).unwrap();
        setShowDeleteModal(false);
        toast.success(t("invite_decline_toast"));
      }
    } catch (err) {
      console.log("err", err);
      toast.error(t("invite_decline_error_toast"));
    }
  }

  const setInitialValue = async (item: GetInvitationsRespItem) => {
    const res = await dispatch(getUserThunk({ userId: item.createdByUserId })).unwrap();
    setEmail(res.email);
  }


  return (
    <>
      <Modal showModal={showDeleteModal} setShowModal={setShowDeleteModal}
        header={
          <div>{`${t("decline_invite_confirmation")}`}</div>
        }
        content={
          <div style={{ display: "flex", gap: "1rem" }}>
            <CustomButton
              buttonType='button'
              classType='tertiary'
              onClick={() => declineInvite()}>
              <div>{t("yes")}</div>
            </CustomButton>
            <CustomButton
              buttonType='button'
              classType='quaternary'
              onClick={() => { setShowDeleteModal(false) }}>
              <div>{t("no")}</div>
            </CustomButton>
          </div>
        }
      />
      <SecondaryTopBar>
        <div className={styles.subHeadingContainer}>
          <TextComponent textType='Content'>
            {`${t("my_invitation_text")}`}
          </TextComponent>
        </div>

        <div className={styles.topBtnContainer}>
          <SettingRefreshComponent
            getLatestData={getLatestData}
            refreshPage={refreshPage}
          />
        </div>
      </SecondaryTopBar>
      <div className={styles.main}>
        <div className={styles.pageContainer}>
          <div style={{ position: "relative" }}>
            <div className={`${styles.contentPane} ${styles.contentReady}`}>
              {
                userInvitations.items.length > 0 ? (
                  <>
                    <div className={'tableWrapper'}>
                      <>
                        <div className={'tableHeaderRow'}>
                          <div className={'tableData'}>{`${t("workspace_name")}`}</div>
                          <div className={'tableData'}>{`${t("invited_by")}`}</div>
                          <div className={'tableData'}>{`${t("invited_at")}`}</div>
                          <div className={'tableData'}>{`${t("status")}`}</div>
                          <div className={'tableData'}>{`${t("action")}`}</div>
                        </div>
                        {
                          userInvitations.items.map((item, index) => {
                            setInitialValue(item);
                            return (
                              <UserInvitationRow
                                key={item.id}
                                index={index}
                                inviteItem={item}
                                acceptInvite={acceptInvite}
                                email={email}
                                setShowDeleteModal={setShowDeleteModal}
                                setInvitationDetails={setInvitationDetails}
                              />
                            )
                          })
                        }
                      </>
                    </div>
                    <div className={'mobileContainer'}>
                      <>
                        {
                          userInvitations.items.map((item, index) => (
                            <div className={'mobileCard'} key={index} >
                              <div className={'rowOne'}>
                                <CustomHeading headerType='h4'>
                                  {item.orgName}
                                </CustomHeading>
                              </div>
                              <div className={'rowOne'}>
                                <div style={{ wordBreak: "break-word" }}>
                                  {item.createdByUserEmail || email || item.createdByUserId}
                                </div>
                                <div>
                                  {new Date(item.createdAt).toLocaleString()}
                                </div>
                              </div>
                              <div className={'rowFour'}>
                                <div className={'diamond'}></div>
                                <div className={'line'}></div>
                                <div className={'diamond'}></div>
                              </div>
                              <div className={'rowOne'}>
                                <CustomButton
                                  disabled={item.status === 'PENDING' ? false : true}
                                  style={{
                                    background: "var(--accent-color)",
                                    padding: " 12px",
                                    borderRadius: '50%',
                                    border: 'none',
                                    boxSizing: "border-box",
                                    width: "fit-content"
                                  }}
                                  buttonType='button'
                                  classType='tertiary'
                                  onClick={() => {
                                    acceptInvite(item);
                                  }}
                                >
                                  <div className={styles.isImageHolder}>
                                    <img style={{ width: "1.2rem" }} src={svgTick} alt="filter" />
                                  </div>
                                </CustomButton>
                                <CustomButton
                                  disabled={item.status === 'PENDING' ? false : true}
                                  style={{
                                    background: "var(--accent-color)",
                                    padding: " 12px",
                                    borderRadius: '50%',
                                    border: 'none',
                                    boxSizing: "border-box",
                                    width: "fit-content"
                                  }}
                                  buttonType='button'
                                  classType='tertiary'
                                  onClick={() => {
                                    setInvitationDetails(item)
                                    setShowDeleteModal(true);
                                  }}
                                >
                                  <div className={styles.isImageHolder}>
                                    <img style={{ width: "1.2rem" }} src={svgCross} alt="filter" />
                                  </div>
                                </CustomButton>
                              </div>
                            </div>
                          ))
                        }
                      </>
                    </div>
                  </>
                ) : (
                  <NoContent
                    content={`${t("no_invitations")}`}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

