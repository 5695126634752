import styles from './form-builder.module.scss';
import toast from 'react-hot-toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { TopBar, ControlsPanel, BuilderCanvas, ActionsPanel, SettingsPanel } from '../../../components/v2/form-builder';
import { getAllLookupCtrls } from '../../../api/lookup-controls';
import { FormControl } from '../../../store/models/form-controls/form-builder-control';
import { CreateFormControlDto } from '../../../store/models/form-controls/create-form-control';
import { SocketContext } from '../../../context/socket';
import ControlPropertiesPanel from '../../../components/v2/form-builder/control-properties-panel/control-properties-panel';
import { PublishPanel } from '../../../components/v2/form-builder/publish-panel/publish-panel';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../store/store';
import { getFormControls } from '../../../store/slices/form-control.slice';
import { controlReorderThunk, createControlThunk, deleteControlThunk, getControlByFormThunk, updateQuizControlThunk } from '../../../store/thunks/controls.thunk';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteFormControlBuilderDto } from '../../../store/models/form-controls/delete-form-control';
import { FormState } from '../../../store/models/form.interface';
import { ReorderFormControlsDto, UpdateQuizControlDto } from '../../../store/models/form-controls/update-form-control';
import { fetchAllFormsThunk, fetchFormByIdThunk } from '../../../store/thunks/forms.thunk';
import { ControlPropertiesPanelProp } from '../../../components/v2/form-builder/control-properties-panel/controls-properties-panel.props';
import { getForm } from '../../../store/slices/forms.slice';
import { deleteValidatedControl, removeControl } from '../../../store/slices/validation.slice';
import { LookupControl } from '../../../store/models/lookup-control/lookup-controls.interface';
import { GetAllLookupCtrlQueryParamsDto } from '../../../store/models/lookup-control/get-lookup-controls.interface';
import UseValidation from '../../../util/validator/use-validation';
import { getAllIntegrationsThunk } from '../../../store/thunks/integrations.thunk';
import hasSufficientScopes from '../../../util/access-validator/access-validator';
import { useTranslation } from 'react-i18next';
import { ActiveBuilderPane } from '../../../store/models/form-builder/active-builder-pane.enum';
import { LogicBuilder } from '../../../components/v2/form-builder/conditional-logic/logic-builder/logic-builder';
import { useLogicBuilder } from '../../../components/v2/form-builder/conditional-logic/logic-builder/hooks/use-logic-builder';
import { fetchSubscriptionThunk } from '../../../store/thunks/subscription.thunk';
import { UserMetadataKey } from '../../../store/models/user-metadata-key.enum';
import CustomLoader from '../../../components/v2/common/custom-loader/custom-loader';
import PageNotFound from '../../page-not-found/page-not-found';
import { SubscriptionPlanCode } from '../../../store/models/subscription/plan-name.enum';

export function FormBuilder() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: RootDispatch = useDispatch();
  const [isActionPanelOpen, setIsActionPanelOpen] = useState(false);
  const [lookupCtrls, setLookupCtrls] = useState<LookupControl[]>([]);
  const { formId } = useParams<string>() as { formId: string };
  const socket = useContext(SocketContext);
  const globalControls = useSelector((state: RootState) => getFormControls(state, formId));
  const [formControls, setFormControls] = useState<FormControl[]>([]);
  const form = useSelector((state: RootState) => getForm(state, formId));
  const [isDraggable, setIsDraggable] = useState<boolean>(false);
  const mouseHeight = useRef<number>();
  const dragPlaceHolder = useRef<HTMLDivElement>();
  const [activeBarTitle, setActionBarTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const controlErrorMessage = useSelector((state: RootState) => { return state.validation.controlValidation });
  const clearSettingsState = useRef<() => {}>();
  const [isRaffleActive, setIsRaffleActive] = useState(true);
  const [isQuizEnabled, setIsQuizEnabled] = useState(false);
  const [score, setScore] = useState<number>();
  const [quizControl, setQuizControl] = useState<FormControl>();
  const [raffleControl, setRaffleControl] = useState<FormControl>();
  const { quizModeValidation, quizModeControlPositionValidation } = UseValidation();
  const plans = useSelector((state: RootState) => state.subscription.data.plans);
  const [isRaffleSubscribed, setIsRaffleSubscribed] = useState(false);
  const [isQuizSubscribed, setIsQuizSubscribed] = useState(false);
  const [refreshPage, setRefreshPage] = useState<boolean>(true);
  const userWorkspace = useSelector((state: RootState) => state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);

  const [activeFormControl, setActiveFormControl] = useState<ControlPropertiesPanelProp>();
  const [sidePaneTabvalue, setSidePaneTabvalue] = useState("");

  const pickedCtrlRef = useRef<HTMLDivElement>();
  const pickedCtrlId = useRef<string>('');
  const pickedCtrlIndexRef = useRef<number>();
  const pickedCtrlParentRef = useRef<FormControl>();
  const pickedCtrlParentDomRef = useRef<HTMLDivElement>();
  const pickedCtrlParentIndexRef = useRef<number>();
  const pickedCtrlGrandParentRef = useRef<FormControl>();
  const pickedCtrlGrandParentIndexRef = useRef<number>();

  const hoverCtrlRef = useRef<HTMLDivElement>();
  const hoverCtrlId = useRef<string>('');
  const hoverCtrlIndexRef = useRef<number>();
  const hoverCtrlParentRef = useRef<FormControl>();
  const hoverCtrlParentIndexRef = useRef<number>();
  const hoverCtrlGrandParentRef = useRef<FormControl>();
  const hoverCtrlGrandParentIndexRef = useRef<number>();

  const [activeToggle, setActiveToggle] = useState<ActiveBuilderPane>(ActiveBuilderPane.CREATE_FORM);
  const logicBuilder = useLogicBuilder(formId);

  useEffect(() => {
    setFormControls([]);
  }, [globalControls]);

  useEffect(() => {
    if (formControls && formControls.length === 0 && globalControls && globalControls.length > 0) {
      setFormControls([...globalControls]);
    }
  }, [formControls]);

  useEffect(() => {
    const temp = async () => {
      try {
        await dispatch(fetchAllFormsThunk({})).unwrap();
        setRefreshPage(false);
      } catch (error) {
        setRefreshPage(false);
      }
    }
    temp();
  }, [userWorkspace])


  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (clearSettingsState.current) clearSettingsState.current();
    if (isActionPanelOpen === false) {
      dispatch(deleteValidatedControl({ formId: formId, controlId: activeFormControl?.control.id }));
      setActiveFormControl({ ...activeFormControl, control: { children: [], config: {}, controlId: '', controlType: '', formId: '', id: '', isReadOnly: false, label: '', openEditor: false, options: [], order: 0, parentId: '' }, controlIndex: 0, parentType: '', lookupCtrls: [], createControl: createControl, deleteControl: deleteControl });
    }
  }, [isActionPanelOpen]);

  useEffect(() => {
    if (score !== undefined && score >= 0 && quizControl && sidePaneTabvalue === 'publish-share') {
      updateScore(score, quizControl);
    }
  }, [sidePaneTabvalue]);

  useEffect(() => {
    setRaffleAndQuizMode(formControls);
  }, [formControls]);

  useEffect(() => {
    if (plans.find(i => i.code.toUpperCase() === SubscriptionPlanCode.RAFFLE)) {
      setIsRaffleSubscribed(true);
    }
    if (plans.find(i => i.code.toUpperCase() === 'QUIZ')) {
      setIsQuizSubscribed(true);
    }
  }, [plans]);

  const init = async () => {
    fetchLookupCtrls();
    if (!form) fetchForm();
    fetchFormCtrls();
    registerGoBack();
    dispatch(fetchSubscriptionThunk());
    const checkScope = hasSufficientScopes(['integrations:read']);
    if (checkScope) {
      await dispatch(getAllIntegrationsThunk());
    }
  }

  const setRaffleAndQuizMode = (formControls: FormControl[]) => {
    let quizFormControl: any;
    let hasQuiz = false;
    let hasRaffle = false;
    if (formControls && formControls.length > 0) {
      for (const element of formControls) {
        if (element.children.length > 0) {
          for (const elem of element.children) {
            if (elem.controlType === 'quiz') {
              hasQuiz = true;
              quizFormControl = elem;
              setQuizControl(elem);
              quizModeValidation(elem);
            }
            if (elem.controlType === 'raffle') {
              hasRaffle = true;
              setRaffleControl(elem);
            }
            if (hasRaffle && hasQuiz) {
              break;
            }
          }
        }
      }
      if (hasQuiz && quizFormControl) {
        setIsQuizEnabled(true);
        if (formControls[0].children.length > 0 && formControls[0].children[0].controlType !== 'quiz') {
          return quizModeControlPositionValidation(quizFormControl, false);
        } else if (formControls[0].children.length > 0 && formControls[0].children[0].controlType === 'quiz') {
          return quizModeControlPositionValidation(quizFormControl, true);
        }
      }
    }
  }

  const registerGoBack = () => {
    window.onpopstate = () => navigate(`../forms`);
  }

  const updateScore = async (score: number, quizControl: FormControl) => {
    try {
      if (quizControl && score !== undefined && score >= 0) {
        const payload: UpdateQuizControlDto = {
          id: quizControl.id,
          formId: quizControl.formId,
          config: {
            ...quizControl.config,
            totalScore: score.toString().includes('.') ? score.toFixed(1) : score
          }
        }
        await dispatch(updateQuizControlThunk({ socket: socket, payload: payload })).unwrap();
      }
    } catch (err) {
      toast.error(t("quiz_score_update_fail"));
    }
  }

  const fetchForm = async () => {
    try {
      const res = await dispatch(fetchFormByIdThunk(formId ?? '')).unwrap();
      if (res.state === FormState.PUBLISHED) {
        navigate(`/forms/${res.id}/details`);
      }
    } catch (err) {
      toast.error(t("form_details_fetch_fail"));
    }
  }

  const fetchFormCtrls = async () => {
    if (formId) {
      try {
        let tempScore = 0;
        const res = await dispatch(getControlByFormThunk(formId)).unwrap();
        for (const pages of res) {
          for (const pageChildren of pages.children) {
            if (pageChildren.controlType === 'section') {
              for (const sectionChildren of pageChildren.children) {
                if (sectionChildren.controlType === 'raffle') {
                  setIsRaffleActive(false);
                }
                if (sectionChildren.config?.quiz?.score) {
                  tempScore += Number(sectionChildren.config?.quiz?.score);
                }
              }
            }
            if (pageChildren.controlType === 'raffle') {
              setIsRaffleActive(false);
            }
            if (pageChildren.config?.quiz?.score) {
              tempScore += Number(pageChildren.config?.quiz?.score);
            }
          }
          setScore(tempScore);
        }
      } catch (err) {
        toast.error(t("control_fetch_fail"));
      }
    }
  }

  const fetchLookupCtrls = async (params?: GetAllLookupCtrlQueryParamsDto) => {
    try {
      const controls = await getAllLookupCtrls();
      setLookupCtrls(controls);
    } catch (err) {
      toast.error(t("control_fetch_fail"));
    }
  }

  const createControl = async (params: {
    controlName: string,
    controlIndex: number,
    parentIndex?: number,
    grandParentIndex?: number,
    parentId: string | null,
    parentType: "page" | "section" | null,
    isCopy?: boolean,
    refCtrl?: FormControl
  }) => {
    setIsLoading(() => { return true });
    if (params.isCopy === true && params.refCtrl) {
      if (params.refCtrl.config && params.refCtrl.config.quiz && params.refCtrl.config.quiz.score && score) {
        setScore(score + Number(params.refCtrl.config.quiz.score));
      }
      const payload: CreateFormControlDto = {
        data: {
          children: [],
          controlId: params.refCtrl.controlId,
          formId: formId ? formId : '',
          isReadOnly: params.refCtrl.isReadOnly,
          openEditor: params.refCtrl.openEditor,
          options: params.refCtrl.options,
          parentId: params.refCtrl.parentId,
          config: params.refCtrl.config,
          controlType: params.refCtrl.controlType,
          order: params.controlIndex,
          label: params.refCtrl.label,
          labelHTML: params.refCtrl.labelHTML
        },
        controlIndex: params.controlIndex,
        parentType: params.parentType,
        parentIndex: params.parentIndex,
        grandParentIndex: params.grandParentIndex
      }
      try {
        const res = (await dispatch(createControlThunk({ socket: socket, payload: payload })).unwrap()).data;
        if (controlErrorMessage != null && res.parentId != null && controlErrorMessage[formId] != null && controlErrorMessage[formId][res.parentId] != null) {
          const obj = {
            formId: res.formId,
            controlId: res.parentId,
            key: params.parentType,
          };
          dispatch(removeControl(obj));
        }
        setIsLoading(() => { return false });
      } catch (err) {
        toast.error(t("create_control_fail"));
        setIsLoading(() => { return false });
      }
    } else {
      const ctrl = lookupCtrls.find(val => val.name === params.controlName);
      if (ctrl) {
        const emailOrMobileLabel = (ctrl.name === 'email') ? `Email ID` : `Mobile number with country code`;
        const emailOrMobileLabelHtml = (ctrl.name === 'email') ? `<p>Email ID</p>` : `<p>Mobile number with country code</p>`;
        const payload: CreateFormControlDto = {
          data: {
            children: [],
            controlId: ctrl.id,
            formId: formId ? formId : '',
            isReadOnly: false,
            openEditor: false,
            options: [],
            parentId: params.parentId,
            config: ((ctrl.name === 'email') || (ctrl.name === 'mobile number')) ? {
              ...ctrl.config,
              isMandatory: true,
              placeHolder: (ctrl.name === 'email') ? `Enter your email ID` : `Enter mobile number without the preceding 0`,
            } : ctrl.config,
            controlType: ctrl.name,
            order: params.controlIndex,
            label: ((ctrl.name === 'email') || (ctrl.name === 'mobile number')) ? emailOrMobileLabel : undefined,
            labelHTML: ((ctrl.name === 'email') || (ctrl.name === 'mobile number')) ? emailOrMobileLabelHtml : undefined,
          },
          controlIndex: params.controlIndex,
          parentType: params.parentType,
          parentIndex: params.parentIndex,
          grandParentIndex: params.grandParentIndex
        }
        try {
          const res = (await dispatch(createControlThunk({ socket: socket, payload: payload })).unwrap()).data;
          if (controlErrorMessage != null && res.parentId != null && controlErrorMessage[formId] != null && controlErrorMessage[formId][res.parentId] != null) {
            const obj = {
              formId: res.formId,
              controlId: res.parentId,
              key: params.parentType,
            };
            dispatch(removeControl(obj));
          }
          if (params.controlName === 'raffle') {
            await createRaffleSiblings(res, params.parentIndex ? params.parentIndex : 0);
            setIsRaffleActive(false);
          }
          if (params.controlName === 'quiz') {
            setIsQuizEnabled(true);
          }
          setIsLoading(() => { return false });
        } catch (err) {
          toast.error(t("create_control_fail"));
          setIsLoading(() => { return false });
        }
      }
    }
    const canvas = document.getElementById('builder-canvas');
    if (canvas) {
      canvas.scrollTop = canvas.scrollHeight + 500;
    }
  }

  const createRaffleSiblings = async (raffleControl: FormControl, gpIndex: number) => {
    try {
      const emailCtrl = lookupCtrls.find(val => val.name === 'email');
      const mobileCtrl = lookupCtrls.find(val => val.name === 'mobile number');
      if (emailCtrl && form) {
        const payload: CreateFormControlDto = {
          controlIndex: 0,
          data: {
            children: [],
            controlId: emailCtrl.id,
            formId: form.id,
            isReadOnly: false,
            openEditor: false,
            options: [],
            order: 0,
            parentId: raffleControl.id,
            config: {
              ...emailCtrl.config,
              isMandatory: true,
              placeHolder: `Enter your email ID`
            },
            controlType: emailCtrl.name,
            label: `Email ID`,
            labelHTML: `<p>Email ID</p>`
          },
          parentType: 'section',
          parentIndex: raffleControl.order,
          grandParentIndex: gpIndex
        }
        await dispatch(createControlThunk({ socket: socket, payload: payload })).unwrap();
      }
      if (mobileCtrl && form) {
        const payload: CreateFormControlDto = {
          controlIndex: 1,
          data: {
            children: [],
            controlId: mobileCtrl.id,
            formId: form.id,
            isReadOnly: false,
            openEditor: false,
            options: [],
            order: 1,
            parentId: raffleControl.id,
            config: {
              ...mobileCtrl.config,
              isMandatory: true,
              placeHolder: `Enter mobile number without the preceding 0`
            },
            controlType: mobileCtrl.name,
            label: `Mobile number with country code`,
            labelHTML: `<p>Mobile number with country code</p>`,
          },
          parentType: 'section',
          parentIndex: raffleControl.order,
          grandParentIndex: gpIndex
        }
        await dispatch(createControlThunk({ socket: socket, payload: payload })).unwrap();
      }
    } catch (e) { }
  }

  const deleteControl = async (payload: DeleteFormControlBuilderDto) => {
    try {
      const deleteConditionalLogic = (controlId: string) => {
        if (logicBuilder.conditionalLogic) {
          for (const logic of logicBuilder.conditionalLogic) {
            if (logic.source === controlId || logic.target === controlId) {
              logicBuilder.deleteConditionalLogic(logic.id);
            }
          }
        }
      }

      setIsLoading(true);
      await dispatch(deleteControlThunk({ socket: socket, payload: { ...payload, id: payload.formControl.id, parentId: payload.formControl.parentId } })).unwrap();
      deleteConditionalLogic(payload.formControl.id);
      let deletedScore = 0;
      const data = {
        formId: payload.formId,
        controlId: payload.formControl.id
      }
      if (payload.formControl.controlType === 'raffle') setIsRaffleActive(true);
      if (payload.formControl.controlType === 'quiz') {
        setQuizControl(undefined);
        setIsQuizEnabled(false);
      }
      if (payload.formControl.config && payload.formControl.config.quiz && payload.formControl.config.quiz.score) deletedScore += Number(payload.formControl.config.quiz.score);
      dispatch(deleteValidatedControl(data));

      for (const control of payload.formControl.children) {
        const data = {
          formId: control.formId,
          controlId: control.id
        }
        if (control.controlType === 'raffle') setIsRaffleActive(true);
        if (control.controlType === 'quiz') {
          setQuizControl(undefined);
          setIsQuizEnabled(false)
        };
        if (control.config && control.config.quiz && control.config.quiz.score) deletedScore += Number(control.config.quiz.score);
        dispatch(deleteValidatedControl(data));
        deleteConditionalLogic(control.id);
        for (const childControl of control.children) {
          const data = {
            formId: childControl.formId,
            controlId: childControl.id
          }
          if (childControl.controlType === 'raffle') setIsRaffleActive(true);
          if (childControl.controlType === 'quiz') setIsQuizEnabled(false);
          if (childControl.config && childControl.config.quiz && childControl.config.quiz.score) deletedScore += Number(childControl.config.quiz.score);
          dispatch(deleteValidatedControl(data));
          deleteConditionalLogic(childControl.id);
        }
      }
      if (score !== undefined) setScore(Number((score - deletedScore).toFixed(1)));
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(`${t("delete_cntl_fail")}`);
      setIsLoading(false);
    }
  }

  const reorderControls = async (params: ReorderFormControlsDto) => {
    try {
      setIsLoading(true);
      for (const edge of logicBuilder.conditionalLogic) {
        if (params.controlId === edge.source || params.controlId === edge.target) {
          toast.error(`Cannot reorder a control associated with a conditional logic`);
          setFormControls([]);
          setIsLoading(false);
          return;
        }
      }
      await dispatch(controlReorderThunk({ socket: socket, payload: params })).unwrap();
      if (controlErrorMessage != null && params.newParentId != null && controlErrorMessage[formId] != null && controlErrorMessage[formId][params.newParentId] != null) {
        const obj = {
          formId: formId,
          controlId: params.newParentId
        };
        dispatch(deleteValidatedControl(obj));
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(`${t("reordering_fail")}`);
      setFormControls([]);
      setIsLoading(false);
    }
  }

  return (
    <>
      {
        refreshPage ?
          <div className={styles.loaderContainer}>
            <CustomLoader />
          </div> :
          <>
            {
              form ?
                <div className={`${styles.container}`}>
                  <TopBar formName={form?.name} isActionsPanelOpen={isActionPanelOpen} setIsActionsPanelOpen={setIsActionPanelOpen} setSidePaneTabvalue={setSidePaneTabvalue} setActionBarTitle={setActionBarTitle} form={form} isLoading={isLoading} setIsLoading={setIsLoading} formControls={formControls} score={score} quizControl={quizControl} activeToggle={activeToggle} setActiveToggle={setActiveToggle} raffleControl={raffleControl} plans={plans} />
                  <div className={styles.builderContainer}>
                    {
                      activeToggle === ActiveBuilderPane.CREATE_FORM &&
                      <>
                        <ControlsPanel
                          lookupControls={lookupCtrls}
                          pickedCtrlRef={pickedCtrlRef}
                          dragPlaceHolder={dragPlaceHolder}
                          isRaffleActive={isRaffleActive}
                          setIsRaffleActive={setIsRaffleActive}
                          isQuizEnabled={isQuizEnabled}
                          isRaffleSubscribed={isRaffleSubscribed}
                          isQuizSubscribed={isQuizSubscribed}
                          createControl={createControl}
                          setIsQuizEnabled={setIsQuizEnabled}
                          formControls={formControls}
                        />
                        <BuilderCanvas
                          formControls={formControls}
                          isDraggable={isDraggable}
                          mouseHeight={mouseHeight}
                          setIsDraggable={setIsDraggable}
                          reorderControls={reorderControls}
                          pickedCtrlRef={pickedCtrlRef}
                          pickedCtrlIndexRef={pickedCtrlIndexRef}
                          pickedCtrlParentRef={pickedCtrlParentRef}
                          pickedCtrlParentIndexRef={pickedCtrlParentIndexRef}
                          pickedCtrlGrandParentRef={pickedCtrlGrandParentRef}
                          pickedCtrlGrandParentIndexRef={pickedCtrlGrandParentIndexRef}
                          hoverCtrlRef={hoverCtrlRef}
                          hoverCtrlIndexRef={hoverCtrlIndexRef}
                          hoverCtrlParentRef={hoverCtrlParentRef}
                          hoverCtrlParentIndexRef={hoverCtrlParentIndexRef}
                          hoverCtrlGrandParentRef={hoverCtrlGrandParentRef}
                          hoverCtrlGrandParentIndexRef={hoverCtrlGrandParentIndexRef}
                          dragPlaceHolder={dragPlaceHolder}
                          createControl={createControl}
                          deleteControl={deleteControl}
                          setSidePaneTabvalue={setSidePaneTabvalue}
                          setActiveFormControl={setActiveFormControl}
                          setIsActionPanelOpen={setIsActionPanelOpen}
                          isActionPanelOpen={isActionPanelOpen}
                          pickedCtrlId={pickedCtrlId}
                          hoverCtrlId={hoverCtrlId}
                          pickedCtrlParentDomRef={pickedCtrlParentDomRef}
                          form={form}
                          setActionBarTitle={setActionBarTitle}
                          isQuizEnabled={isQuizEnabled}
                          score={score}
                          isLoading={isLoading}
                        />
                      </>
                    }
                    {
                      activeToggle === ActiveBuilderPane.BUILD_LOGIC &&
                      <LogicBuilder logicBuilder={logicBuilder} formControls={formControls} />
                    }
                  </div>
                  {
                    <ActionsPanel title={activeBarTitle} isActionsPanelOpen={isActionPanelOpen} setIsActionsPanelOpen={setIsActionPanelOpen}>
                      <>
                        {
                          sidePaneTabvalue === 'settings' && form &&
                          <SettingsPanel isActionPanelOpen={isActionPanelOpen} form={form} setIsActionsPanelOpen={setIsActionPanelOpen} clearSettingsState={clearSettingsState} />
                        }
                        {
                          sidePaneTabvalue === 'publish-share' && form && <PublishPanel />
                        }
                        {
                          sidePaneTabvalue === 'properties' && form && activeFormControl &&
                          <ControlPropertiesPanel
                            control={activeFormControl.control}
                            controlIndex={activeFormControl.controlIndex}
                            parentType={activeFormControl.parentType}
                            parentIndex={activeFormControl.parentIndex}
                            grandParentIndex={activeFormControl.grandParentIndex}
                            setIsActionsPanelOpen={setIsActionPanelOpen}
                            lookupCtrls={lookupCtrls}
                            isQuizEnabled={isQuizEnabled}
                            score={score}
                            setScore={setScore}
                            createControl={createControl}
                            deleteControl={deleteControl}
                          />
                        }
                      </>
                    </ActionsPanel>
                  }
                </div> : <PageNotFound />
            }
          </>
      }
    </>
  )
}
