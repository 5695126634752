import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { forgotAndResetPassword } from '../../../../api/sign-up';
import { Captcha } from '../../../../components/v2/common/captcha/captcha';
import CustomText from '../../../../components/v2/common/custom-bodyText/custom-text';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import CustomHeading from '../../../../components/v2/common/custom-heading/custom-heading';
import CustomInput from '../../../../components/v2/common/custom-input/custom-input';
import { PasswordResetReqDto } from '../../../../store/models/sign-up.interface';
import PageLayout from '../page-layout/page-layout';
import styles from './reset-password.module.scss';
import svgGreenTick from "../../../../assets/v2/icons/green-tick.svg";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [passwordData, setPasswordData] = useState<ResetPasswordData>({
    newPassword: "",
    confirmPassword: ""
  });
  const [newPasswordWarningText, setNewPasswordWarningText] = useState<string | undefined>(undefined);
  const [confirmPasswordWarningText, setConfirmPasswordWarningText] = useState<string | undefined>(undefined);
  const [isMailSent, setIsMailSent] = useState(false);
  const [userClaim, setUserClaim] = useState<{ email: string, emailVerificationCode: string }>({ email: '', emailVerificationCode: '' });
  const { t } = useTranslation();
  let [params] = useSearchParams();
  const [tokenData, setTokenData] = useState<{ email: string, emailVerificationCode: string }>({ email: '', emailVerificationCode: '' });
  const [validation, setValidation] = useState<boolean>(false);
  const [emailWarningText, setEmailWarningText] = useState<string | undefined>(undefined);
  const [codeWarningText, setCodeWarningText] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  async function submitForm() {
    setIsLoading(true);
    const data: PasswordResetReqDto = {
      email: userClaim.email,
      emailVerificationCode: userClaim.emailVerificationCode,
      newPassword: passwordData.confirmPassword
    };
    try {
      await forgotAndResetPassword(data);
      setIsLoading(false);
      toast.success(t("password_reset_success"));
      setIsMailSent(true);
    } catch (err) {
      toast.error(t("something_went_wrong"));
      setIsLoading(false);
    }
  }

  const checkToken = () => {
    if (params.get("token")) {
      const token = params.get("token") as string;
      const parsedToken: { code: string, email: string } = JSON.parse(atob(token));
      setTokenData({ email: parsedToken.email, emailVerificationCode: parsedToken.code });
      setUserClaim({ email: parsedToken.email, emailVerificationCode: parsedToken.code });
    }
  }

  function validatePassword(value: string) {
    const re = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,50}$/);
    if (value.length < 1) {
      setNewPasswordWarningText(`${t("sign_up_new_password_required")}`);
    } else if (!re.test(value)) {
      setNewPasswordWarningText(`${t("reset_password_type_warning")}`);
    } else {
      setNewPasswordWarningText('');
    }
  }

  function validateEmail(value: string) {
    const re = new RegExp(/^[a-z0-9+_.-]+@[a-z0-9.-]+\.[a-z]+$/);
    if (value.length < 1) {
      setEmailWarningText(`${t("sign_up_email_required")}`);
      return false;
    } else if (!re.test(value)) {
      setEmailWarningText(`${t("sign_up_email_invalid")}`);
      return false;
    }
    setEmailWarningText('');
    return true;
  }

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    if (userClaim.email && userClaim.emailVerificationCode && passwordData.newPassword && passwordData.confirmPassword && (passwordData.newPassword === passwordData.confirmPassword)) {
      setValidation(true);
    }
    else {
      setValidation(false);
    }
  }, [passwordData, userClaim]);

  return (
    <>
      <Captcha />
      <div className={styles.mainContainer}>
        <div className={styles.pageLayout}>
          <PageLayout />
        </div>
        {
          isMailSent ? (
            <div className={styles.rightContainer}>
              <div className={styles.updatedContainer}>
                <CustomHeading headerType='h1'>
                  <div>{`${t("reset_password_updated")}`}</div>
                </CustomHeading>
                <img src={svgGreenTick} alt="success" />
              </div>
              <CustomText textType='t4' style={{ color: '#5cb85c' }}>
                <div>{`${t("reset_password_updated_sub")}`}</div>
              </CustomText>
              <div className={styles.login}>
                <CustomButton
                  buttonType='button'
                  classType='tertiary'
                  onClick={() => {
                    navigate('/')
                  }}
                ><CustomHeading
                  headerType='h4'
                >
                    <div>{t("login")}</div>
                  </CustomHeading>
                </CustomButton>
              </div>
            </div>
          ) : (
            <div className={styles.rightContainer}>
              <div className={styles.login}>
              <CustomButton
                buttonType='button'
                classType='tertiary'
                onClick={() => {
                  navigate('/')
                }}
              ><CustomHeading
                headerType='h4'
              >
                  <div>{t("login")}</div>
                </CustomHeading>
              </CustomButton>
              </div>
              <div>
                <CustomHeading headerType='h1'>
                  <div>{`${t("reset_password")}`}</div>
                </CustomHeading>

                <CustomText textType='t6'>
                  <div>{`${t("reset_password_sub")}`}</div>
                </CustomText>
              </div>
              <CustomInput
                inputStyleType='fade'
                inputType='text'
                value={userClaim.email}
                placeHolder={`Enter email`}
                disabled={tokenData.email ? true : false}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  validateEmail(value);
                  setUserClaim({
                    ...userClaim,
                    email: value
                  });
                }}
              />
              {
                emailWarningText && emailWarningText !== '' &&
                <CustomText textType='t5' isErrorText={true}>
                  <div>{emailWarningText}</div>
                </CustomText>
              }
              <CustomInput
                inputStyleType='fade'
                inputType='number'
                value={userClaim.emailVerificationCode}
                placeHolder={`${t("enter_verif_code")}`}
                disabled={tokenData.emailVerificationCode ? true : false}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  if (value.length < 1) {
                    setCodeWarningText(t("code_required"));
                  } else {
                    setCodeWarningText('');
                  }
                  setUserClaim({
                    ...userClaim,
                    emailVerificationCode: value
                  });
                }}
              />
              {
                codeWarningText && codeWarningText !== '' &&
                <CustomText textType='t5' isErrorText={true}>
                  <div>{codeWarningText}</div>
                </CustomText>
              }
              <CustomInput
                inputStyleType='fade'
                inputType='password'
                value={passwordData.newPassword}
                placeHolder={`${t("reset_password_type")}`}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  validatePassword(value);
                  setPasswordData({
                    ...passwordData,
                    newPassword: e.target.value
                  });
                }}
              />
              {
                newPasswordWarningText && newPasswordWarningText !== '' &&
                <CustomText textType='t5' isErrorText={true}>
                  <div>{newPasswordWarningText}</div>
                </CustomText>
              }
              <CustomInput
                inputStyleType='fade'
                inputType='password'
                value={passwordData.confirmPassword}
                placeHolder={`${t("reset_password_confirm")}`}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  if (value.length < 1) {
                    setConfirmPasswordWarningText(`${t("sign_up_new_password_required")}`);
                  } else if (passwordData.newPassword !== value) {
                    setConfirmPasswordWarningText(`${t("reset_password_confirm_warning")}`);
                  } else {
                    setConfirmPasswordWarningText('');
                  }
                  setPasswordData({
                    ...passwordData,
                    confirmPassword: e.target.value
                  });
                }}
              />
              {
                confirmPasswordWarningText && confirmPasswordWarningText !== '' &&
                <CustomText textType='t5' isErrorText={true}>
                  <div>{confirmPasswordWarningText}</div>
                </CustomText>
              }
              <CustomButton
                buttonType='button'
                classType='quaternary'
                disabled={(newPasswordWarningText || confirmPasswordWarningText || emailWarningText || codeWarningText || !validation) ? true : false}
                onClick={() => submitForm()}
              ><CustomHeading headerType='h4' style={{ color: "#ffff" }}>
                  <div>{isLoading ? t("loading") : `${t("reset_password_btn")}`}</div>
                </CustomHeading>
              </CustomButton>
            </div>
          )
        }
      </div>
    </>
  )
}


interface ResetPasswordData {
  newPassword: string;
  confirmPassword: string;
}
