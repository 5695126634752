import styles from './top-bar.module.scss';
import CustomButton from '../../common/custom-button/custom-button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootDispatch, RootState } from '../../../../store/store';
import { submitFormForApprovalThunk, updateFormThunk } from '../../../../store/thunks/forms.thunk';
import { LoaderSecondary } from '../../common/loader-secondary/loader-secondary';
import { TopBarProps } from './top-bar.props';
import { useEffect, useState } from 'react';
import { CustomModal } from '../../common/custom-modal/custom-modal';
import CustomHeading from '../../common/custom-heading/custom-heading';
import UseValidation from '../../../../util/validator/use-validation';
import { toast } from 'react-hot-toast';
import hasSufficientScopes from '../../../../util/access-validator/access-validator';
import { useTranslation } from 'react-i18next';
import svgCloseBtn from "../../../../assets/v2/close.svg";
import svgGreenTickBuilder from "../../../../assets/v2/icons/green-tick-builder.svg";
import svgPreviewEye from "../../../../assets/v2/icons/preview-eye.svg";
import svgSettings from "../../../../assets/v2/icons/settings.svg";
import svgShare from "../../../../assets/v2/icons/share.svg";
import ToolTip from '../../common/tool-tip/tool-tip';
import { ActiveBuilderPane } from '../../../../store/models/form-builder/active-builder-pane.enum';
import BreadCrumbs from '../../common/bread-crumbs/bread-crumbs';

export function TopBar(props: TopBarProps) {
  const { t } = useTranslation();
  const dispatch: RootDispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();
  const controlErrorMessage = useSelector((state: RootState) => { return state.validation.controlValidation });
  const { formControlValidation, quizScoreValidation } = UseValidation();
  const [isPublishScopePresent, setIsPublishScopePresent] = useState(false);
  const [publishState, setPublishState] = useState(true);

  useEffect(() => {
    checkScopes();
  }, []);

  const checkScopes = async () => {
    setIsPublishScopePresent(hasSufficientScopes(['forms:publish', 'forms:publish.all']));
    setPublishState(false);
  }

  const setPublishStatus = async () => {
    if (formId) {
      await dispatch(updateFormThunk({
        formId: formId,
        data: {
          isPublic: true,
        }
      }))
    }
  }

  const triggerModal = () => {
    let element = document.getElementById('preview-form');
    if (element) {
      element.style.display = 'flex';
      //@TODO in future z-index will be maintained in global level
      element.style.zIndex = '999';
    }
    let elementIframe = document.getElementById('preview-form-iframe') as HTMLIFrameElement;
    if (elementIframe) {
      elementIframe.src = '';
      elementIframe.src = `${process.env.REACT_APP_RENDERER_WEBAPP}/${props.form?.url}?mode=preview&liveControls=live-controls`;
    }
  }

  const closeModal = () => {
    let element = document.getElementById('preview-form');
    if (element) element.style.display = 'none';
  }

  const publishForm = async () => {
    const checkScope = hasSufficientScopes(['forms:publish', 'forms:publish.all']);
    if (checkScope) {
      const val = formControlValidation(props.formControls);
      let quizControlVal;
      if (props.score !== undefined && props.quizControl) {
        quizControlVal = quizScoreValidation(props.quizControl, props.score);
      }
      if (val === false && !quizControlVal) {
        setPublishStatus();
        props.setIsActionsPanelOpen(!props.isActionsPanelOpen);
        props.setSidePaneTabvalue("publish-share");
        props.setActionBarTitle(t("publish_share_form"));
      } else {
        toast.error(t("fix_errors_to_publish"));
      }
    } else {
      toast.error(t('form_publish_no_permission'));
    }
  }

  const submitForApproval = async () => {
    const val = formControlValidation(props.formControls);
    if (val === false) {
      try {
        if (formId) {
          await dispatch(submitFormForApprovalThunk({ id: formId })).unwrap();
        }
        navigate(`../forms/${formId}`);
        toast.success(t("form_submitted_approval_success"));
      } catch (e) {
        toast.error(t("form_submitted_approval_error"));
      }
    } else {
      toast.error(t("fix_errors_to_publish"));
    }
  }

  const onPreviewClick = () => {
    const val = formControlValidation(props.formControls);
    if (val === false) {
      triggerModal();
    } else {
      toast.error(t("fix_errors_to_preview"));
    }
  }

  return (
    <div className={styles.topBar}>
      <div className={styles.toggleContainer}>
        <div className={styles.toggleBar}>
          <div className={`${styles.toggleBarItem} ${props.activeToggle === ActiveBuilderPane.CREATE_FORM ? styles.isActive : ''}`} onClick={() => props.setActiveToggle(ActiveBuilderPane.CREATE_FORM)}>{t("form_creation")}</div>
          <div className={`${styles.toggleBarItem} ${props.activeToggle === ActiveBuilderPane.BUILD_LOGIC ? styles.isActive : ''}`} onClick={() => props.setActiveToggle(ActiveBuilderPane.BUILD_LOGIC)}>{t("form_rules")}</div>
        </div>
      </div>
      <div className={styles.layoutRight}>
        <div className={styles.loaderTextContainer}>
          <p className={`${styles.loaderText1} ${!props.isLoading ? styles.loaderShow : styles.loaderHide}`}>{t("saved_to_cloud")}</p>
          <p className={`${styles.loaderText2} ${props.isLoading ? styles.loaderShow : styles.loaderHide}`}>{t("saving_changes")}</p>
        </div>
        <div className={`${styles.loaderContainer} ${!props.isLoading ? styles.loaderContainerSuccess : ''}`}>
          <div className={`${styles.loaderLayout}`}>
            <div className={`${styles.secondaryLoader} ${!props.isLoading ? styles.zeroOpacity : styles.fullOpacity}`} style={{ position: 'absolute', transition: 'all 750ms ease-in-out' }}><LoaderSecondary /></div>
            <img className={`${styles.successTick} ${props.isLoading ? styles.zeroOpacity : styles.fullOpacity}`} src={svgGreenTickBuilder} alt="settings-icon" style={{ position: 'absolute', transition: 'all 750ms ease-in-out' }} />
          </div>
        </div>
        <ToolTip position={'bottom'} content={`${t("preview_form")}`}>
          <CustomButton
            classType={'tertiary'}
            buttonType={'button'}
            onClick={() => onPreviewClick()}
            style={{
              width: "fit-content",
              display: "flex",
              background: "var(--accent-color)",
              borderRadius: '50%',
              border: 'none',
              padding: "0.65rem",
              boxShadow: 'none'
            }}
          >
            <img className={styles.topBarBtn} src={svgPreviewEye} alt="settings-icon" />
          </CustomButton>
        </ToolTip>
        <ToolTip position={'bottom'} content={`${t("configure_settings")}`}>
          <CustomButton
            classType={'tertiary'}
            buttonType={'button'}
            onClick={() => { props.setIsActionsPanelOpen(!props.isActionsPanelOpen); props.setSidePaneTabvalue("settings"); props.setActionBarTitle(t("settings")) }}
            style={{
              width: "fit-content",
              display: "flex",
              background: "var(--accent-color)",
              borderRadius: '50%',
              border: 'none',
              padding: "0.65rem",
              boxShadow: 'none'
            }}
          >
            <img className={styles.topBarBtn} src={svgSettings} alt="settings-icon" />
          </CustomButton>
        </ToolTip>
        <CustomButton
          classType={'quaternary'}
          buttonType={'button'}
          onClick={() => {
            if (isPublishScopePresent) publishForm();
            else submitForApproval();
          }}
          style={{
            width: "fit-content",
            display: "flex",
            padding: "0.4rem 1rem",
            background: "var(--secondary-color)",
            border: 'none',
            color: '#000',
            boxShadow: 'none'
          }}
          disabled={controlErrorMessage && (formId && controlErrorMessage[formId] && Object.keys(controlErrorMessage[formId]).length > 0) || publishState}>
          <div className={styles.publishBtn}>
            {
              isPublishScopePresent
                ?
                <>
                  <img className={styles.topBarBtn} src={svgShare} alt="settings-icon" />
                  <div>{t("publish_and_share")}</div>
                </>
                :
                <div>{t("submit_for_approval")}</div>
            }
          </div>
        </CustomButton>
      </div>
      <CustomModal
        id={'preview-form'}
        header={
          <div className={styles.modalTitleContainer}>
            <div style={{ flexGrow: "1" }}>
              <CustomHeading headerType={'h3'}>{t("preview_form")}</CustomHeading>
            </div>
            <div className={styles.modalTitleCloseBtnWrapper}>
              <img className={styles.closeModalBtn} onClick={closeModal} src={svgCloseBtn} alt="close-button" />
            </div>
          </div>
        }
        content={
          <>
            <iframe
              id={'preview-form-iframe'}
              title={"preview-form"}
              className={`${styles.frame}`}>
            </iframe>
          </>
        }
      />
    </div>
  )
}
