import React from 'react';
import styles from './custom-heading.module.scss';
import { CustomHeadingProps } from './custom-heading.props';

export default function CustomHeading(props: CustomHeadingProps) {
    function classSelector(headerType: string) {
        switch (headerType) {
            case 'h1':
                return styles.headerOne;
            case 'h2':
                return styles.headerTwo;
            case 'h3':
                return styles.headerThree;
            case 'h4':
                return styles.headerFour;
            case 'h5':
                return styles.headerFive;
            case 'h6':
                return styles.headerSix;
            default:
                break;
        }
    }
    return (
        <>
            <div className={classSelector(props.headerType)} style={props.style}> {props.children}</div>
        </>
    )
}
