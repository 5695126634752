import { createSlice } from "@reduxjs/toolkit";
import { CreateFormResDto, Form, FormResponse } from "../models/form.interface";
import { LoadState } from "../models/load-state.enum";
import { RootState } from "../store";
import { FormState } from '../models/form.interface'
import { createFormThunk, deleteFormThunk, fetchAllFormsThunk, fetchFormByIdThunk, publishFormThunk, submitFormForApprovalThunk, updateFormThunk } from "../thunks/forms.thunk";

const formsInitialState: {
  data: Form[];
  count: number;
  current: number;
  skip: number;
  fetchLoadingState: LoadState;
  createLoadingState: LoadState;
  createControlLoadingState: LoadState;
} = { data: [], count: 0, current: 0, skip: 0, fetchLoadingState: LoadState.IDLE, createLoadingState: LoadState.IDLE, createControlLoadingState: LoadState.IDLE };

export const formsSlice = createSlice({
  name: "forms",
  initialState: formsInitialState,
  reducers: {
    setActiveUsers(state, action) {
      if (state.data.length > 0) {
        for (const elem of state.data) {
          if (elem.id === action.payload.formId) {
            elem.activeUsers = action.payload.activeUsers;
          }
        }
      }
    }
  }, extraReducers: (builder) => {
    builder
      .addCase(fetchAllFormsThunk.pending, (state) => {
        state.fetchLoadingState = LoadState.LOADING;
      })
      .addCase(fetchAllFormsThunk.fulfilled, (state, action) => {
        let res: FormResponse = action.payload;
        state.data = res.forms
        state.count = res.count;
        state.current = res.current;
        state.skip = res.skip;
        state.fetchLoadingState = LoadState.IDLE;
      })
      .addCase(fetchAllFormsThunk.rejected, (state) => {
        state.fetchLoadingState = LoadState.ERROR;
      })
      .addCase(createFormThunk.pending, (state) => {
        state.createLoadingState = LoadState.LOADING;
      })
      .addCase(createFormThunk.fulfilled, (state, action) => {
        let data: CreateFormResDto = action.payload as CreateFormResDto;
        let form: Form = {
          name: data.name,
          tags: data.tags,
          id: data.id,
          description: data.description,
          isPublic: data.isPublic,
          state: data.state,
          expiryResponseCount: data.expiryResponseCount,
          expiryTime: data.expiryTime,
          timerConfig: data.timerConfig,
          url: data.url,
          config: data.config,
          createdBy: data.createdBy,
          createdAt: data.createdAt,
          createdByUserName: data.createdByUserName
        };
        state.data.push(form);
        state.createLoadingState = LoadState.IDLE;
      })
      .addCase(createFormThunk.rejected, (state) => {
        state.createLoadingState = LoadState.ERROR;
      })
      .addCase(fetchFormByIdThunk.fulfilled, (state, action) => {
        let data: Form = action.payload as Form;
        let form: Form = {
          tags: data.tags,
          name: data.name,
          id: data.id,
          description: data.description,
          isPublic: data.isPublic,
          url: data.url,
          state: data.state,
          expiryResponseCount: data.expiryResponseCount,
          expiryTime: data.expiryTime,
          config: data.config,
          createdBy: data.createdBy,
          createdAt: data.createdAt,
          timerConfig: data.timerConfig,
          createdByUserName: data.createdByUserName,
          activeUsers: data.activeUsers
        };

        let newData = [];
        let pushed = false;
        // updating new data if data exists else append
        state.data.forEach((d) => {
          if (d.id === form.id) {
            pushed = true;
            newData.push(form);
          } else newData.push(d);
        });
        if (!pushed) newData.push(form);

        state.data = newData;
      })
      .addCase(updateFormThunk.fulfilled, (state, action) => {
        state.data = state.data.map(form => {
          if (form.id === action.payload.formId) {
            const data = action.payload.data;
            Object.keys(data).forEach(key => {
              const keyObject = key as keyof object;
              form[keyObject] = data[keyObject];
            })
          }
          return form;
        })
      })
      .addCase(deleteFormThunk.fulfilled, (state, action) => {
        state.data = state.data.filter((item) => item.id !== action.payload.id);
      })
      .addCase(publishFormThunk.fulfilled, (state, action) => {
        state.data = state.data.map((form) => {
          if (form.id === action.payload.id) {
            form.state = FormState.PUBLISHED;
          }
          return form;
        });
      })
      .addCase(submitFormForApprovalThunk.fulfilled, (state, action) => {
        state.data = state.data.map((form) => {
          if (form.id === action.payload.id) {
            form.state = FormState.WAITING_FOR_PUBLISH;
          }
          return form;
        });
      })
  },
});

export const getForm = (state: RootState, formId: string) => {
  return state.forms.data.find(item => item.id === formId);
};

export const getAllForms = (state: RootState) => {
  return state.forms.data;
}

export const getTotalFormsCount = (state: RootState) => state.forms.count;

export const getCurrentFormsCount = (state: RootState) => state.forms.current;

export const { setActiveUsers } = formsSlice.actions;

export default formsSlice;
