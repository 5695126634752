import { useEffect, useState } from "react";
import { getUser } from "../../../../api/user";
import styles from "./response-user-name.module.scss";

interface ResponseUserNameProps {
  userId: string;
  includeStyle?: boolean;
}

export function ResponseUserName(props: ResponseUserNameProps) {
  const [userName, setUserName] = useState(props.userId);

  useEffect(() => {
    getUserName(props.userId);
  },[])

  const getUserName = async (userId: string) => {
    try {
      const res = await getUser(userId);
      setUserName(res.email);
    } catch (e) {
      return userId;
    }
  }

  return (
    <div className={`${props?.includeStyle === true ? styles.tableValue : ''}`}>{userName}</div>
  )
}
