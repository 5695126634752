import { useTranslation } from 'react-i18next';
import ToolTip from '../../../../components/v2/common/tool-tip/tool-tip';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import hasSufficientScopes from '../../../../util/access-validator/access-validator';
import { toast } from 'react-hot-toast';
import { GetIntegrationsRespItem } from '../../../../store/models/settings/integrations/get-integrations.interface';
import svgEdit from '../../../../assets/v2/edit-2.svg';
import svgDelete from '../../../../assets/v2/delete.svg';

export default function IntegrationActionContainer(props: IntegrationActionContainerProps) {

  const { t } = useTranslation();
  const isLatestSubscriptionActive = useSelector((state: RootState) => state.subscription.data.isActive);

  return (
    <>
      <ToolTip content={`${t("edit")}`} position={'bottom'}>
        <CustomButton
          style={{
            background: "var(--accent-color)",
            padding: " 12px",
            borderRadius: '50%',
            border: 'none',
            boxSizing: "border-box"
          }}
          buttonType='button'
          classType='tertiary'
          onClick={() => {
            if (isLatestSubscriptionActive) {
              const checkScope = hasSufficientScopes(['integrations:update']);
              if (checkScope) {
                props.setSelectedIntegration(props.item);
                props.setIntegrationsType('EDIT');
                props.setIsPanelOpen(true);
              } else {
                toast.error(t('settings_integration_edit_no_permission'));
              }
            } else {
              toast.error(t('no_active_subscription_error'));
            }
          }}
        >
          <div style={{ width: '1.2rem', height: '1.2rem' }}>
            <img src={svgEdit} alt="filter" />
          </div>
        </CustomButton>
      </ToolTip>
      <ToolTip content={`${t("delete")}`} position='bottom'>
        <CustomButton
          style={{
            background: "var(--accent-color)",
            padding: " 12px",
            borderRadius: '50%',
            border: 'none',
            boxSizing: "border-box"
          }}
          buttonType='button'
          classType='tertiary'
          onClick={() => {
            if (isLatestSubscriptionActive) {
              const checkScope = hasSufficientScopes(['integrations:create']);
              if (checkScope) {
                props.setItemId(props.item.id);
                props.setShowModal(true);
              } else {
                toast.error(t('settings_integration_delete_no_permission'));
              }
            } else {
              toast.error(t('no_active_subscription_error'));
            }
          }}
        >
          <div style={{ width: '1.2rem', height: '1.2rem' }}>
            <img src={svgDelete} alt="filter" />
          </div>
        </CustomButton>
      </ToolTip>
    </>
  )
}

interface IntegrationActionContainerProps {
  setItemId: (value: React.SetStateAction<string>) => void;
  setShowModal: (value: React.SetStateAction<boolean>) => void;
  setSelectedIntegration: (value: React.SetStateAction<GetIntegrationsRespItem | undefined>) => void;
  setIntegrationsType: (value: React.SetStateAction<"CREATE" | "EDIT">) => void;
  setIsPanelOpen: (value: React.SetStateAction<boolean>) => void;
  item: GetIntegrationsRespItem;
}
