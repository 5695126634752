import { useEffect, useState } from 'react';
import { DropdownDataItem } from '../../../../components/v2/common/custom-select/custom-select.props';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { toast } from 'react-hot-toast';
import { MultiValue, SingleValue } from 'react-select';
import { deleteUserProfile, getAllProfileInfoForAppInit, setUserMetadataByKey } from '../../../../api/profile';
import { UserMetadataKey } from '../../../../store/models/user-metadata-key.enum';
import { SetUserMetadataDto } from '../../../../store/models/profile.interface';
import styles from './profile.module.scss';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import CustomInput from '../../../../components/v2/common/custom-input/custom-input';
import CustomSelect from '../../../../components/v2/common/custom-select/custom-select';
import AddOrganizationModal from '../../setting/add-organization/add-organization-modal';
import { useNavigate } from 'react-router-dom';
import { GetPaymentAccountsRespItem } from '../../../../store/models/user.interface';
import CustomTooltip from '../../../../components/v2/custom-tooltip/custom-tooltip';
import { Direction } from '../../../../components/v2/custom-tooltip/custom-tooltip.props';
import svgDelete from "../../../../assets/v2/icons/delete.svg";
import { addCard, detachPaymentMethod, getPaymentAccounts } from '../../../../api/billing-accounts';
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import SecondaryTopBar from '../../../../components/v2/common/secondary-top-bar/secondary-top-bar';
import { AxiosError, AxiosResponse } from 'axios';
import { SupportedLanguages } from '../../../../constants/languages';
import Modal from '../../../../components/v2/common/modal/modal';
import { useKeycloak } from '@react-keycloak/web';

export default function Profile() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user: any = useSelector((state: RootState) => state.user.data);
  const profile = useSelector((state: RootState) => state.user.profileInfo);
  const AVAILABLE_LANGS = SupportedLanguages;
  const [lang, setLang] = useState<MultiValue<DropdownDataItem> | SingleValue<DropdownDataItem>>();
  const [isModified, setIsModified] = useState<Boolean>(false);
  const [toggleAddOrg, setToggleAddOrg] = useState(false);
  const [billingAcc, setBillingAcc] = useState<GetPaymentAccountsRespItem[]>([]);
  const [showModal, setShowModal] = useState(false);
  const { keycloak } = useKeycloak();


  useEffect(() => {
    const setOrgFunc = async () => {
      if (!profile.metadata[UserMetadataKey.LANGUAGE]) {
        await getAllProfileInfoForAppInit();
      }
    }
    setOrgFunc();
  }, [])

  async function onSave() {
    try {
      const reqData: SetUserMetadataDto = {
        value: (lang as DropdownDataItem).value
      };
      await setUserMetadataByKey(UserMetadataKey.LANGUAGE, reqData);
      i18n.changeLanguage(reqData.value);
      toast.success(t("profile_updated_success"));
    } catch (err) {
      toast.error(`${err}`);
    }
  }

  async function addPaymentAccount() {
    try {
      const res = await addCard();
      if (res) {
        window.location.href = res;
      } else {
        toast.error(t("attach_card_error"));
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  const deleteMyAccount = async () => {
    try {
      await deleteUserProfile();
      toast.success(t("deleted_account_successfully"));
      keycloak.logout({
        redirectUri: window.location.origin
      });
    } catch (err) {
      if (((err as AxiosError).response as AxiosResponse).data?.data.code === 'DELETE_ACCOUNT_FORBIDDEN') {
        setShowModal(false);
        toast.error("You are the last user in one of the workspaces. You cannot delete your account.");
      } else {
        toast.error("An error occurred while deleting your account. Please try again later.")
      }
    }
  }

  const onDeleteCardClick = async (card: GetPaymentAccountsRespItem) => {
    try {
      await detachPaymentMethod(card.id);
      let cards = billingAcc.filter(c => c.id !== card.id);
      setBillingAcc(cards);
      toast.success(t("card_detach_success"));
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(err.response?.data.data);
        console.log("err", err);
      }
    }
  }

  const getBillingAccounts = async () => {
    try {
      const res = await getPaymentAccounts();
      setBillingAcc(res.cards);
    } catch (err) {
      console.log("err", err);
    }
  }

  useEffect(() => {
    getBillingAccounts();
  }, []);

  return (
    <>
      <SecondaryTopBar>
        <div style={{ padding: '0.25rem ' }}>
          <TextComponent textType='Content'>{`${t("update_profile")}`}</TextComponent>
        </div>
        <CustomButton
          buttonType='button'
          classType='quaternary'
          style={{
            width: "fit-content",
            height: 'fit-content',
            float: 'right'
          }}
          disabled={!isModified}
          onClick={onSave}>
          <div className={styles.saveBtn}>
            <TextComponent textType='Header'>
              <span style={{ color: '#fff' }}>{`${t("save")}`}</span>
            </TextComponent>
          </div>
        </CustomButton>
      </SecondaryTopBar>
      <div className={styles.main}>
        <div className={styles.pageContainer}>
          <div className={`${styles.mainSection} ${styles.animIn}`}>
            <div className={styles.middleContainer}>
              <div className={styles.profileDetailsContainer}>
                <div className={styles.profileNameContainer}>
                  <div className={styles.profileName}>
                    {user.email[0].toLocaleUpperCase()}{user.email[1].toLocaleUpperCase()}
                  </div>
                </div>
                <div className={styles.profileDetailsContent}>
                  <div className={styles.accountName}>{user.name}</div>
                  <div className={styles.contactInfo}>{user.email}</div>
                </div>
              </div>
              <div className={styles.profileContent}>
                <div className={styles.middleSubContainer}>
                  <div className={styles.middleInnerContainer}>
                    <TextComponent textType='Content'>
                      <div style={{
                        paddingBottom: "5px",
                        fontWeight: "bold"
                      }}>{`${t("first_name")}`}</div>
                    </TextComponent>
                    <CustomInput
                      inputStyleType='primary'
                      inputType='text'
                      value={user.given_name}
                      readonly={true}
                      disabled={true}
                    />
                  </div>
                  <div className={styles.middleInnerContainer}>
                    <TextComponent textType='Content'>
                      <div style={{
                        paddingBottom: "5px",
                        fontWeight: "bold"
                      }}>{`${t("last_name")}`}</div>
                    </TextComponent>
                    <CustomInput
                      inputStyleType='primary'
                      inputType='text'
                      value={user.family_name}
                      readonly={true}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className={styles.middleSubContainer}>
                  <div className={styles.middleInnerContainer}>
                    <TextComponent textType='Content'>
                      <div style={{
                        paddingBottom: "5px",
                        fontWeight: "bold"
                      }}>{`${t("account_details_email")}`}</div>
                    </TextComponent>
                    <div style={{ position: "relative" }}>
                      <CustomInput
                        inputStyleType='primary'
                        inputType='text'
                        readonly={true}
                        disabled={true}
                        value={user.email}
                      />
                    </div>
                  </div>
                  <div className={styles.middleInnerContainer}>
                    <TextComponent textType='Content'>
                      <div style={{
                        paddingBottom: "5px",
                        fontWeight: "bold"
                      }}>{`${t("language")}`}</div>
                    </TextComponent>
                    <CustomSelect
                      data={AVAILABLE_LANGS.map(item => {
                        const data: DropdownDataItem = {
                          label: item.language,
                          value: item.code
                        }
                        return data;
                      })}
                      selectStyleType={"colored"}
                      defaultvalue={lang}
                      selectedData={(item) => {
                        setLang(item);
                        setIsModified(true)
                      }}
                      placeholder={AVAILABLE_LANGS.find(i => i.code === profile.metadata[UserMetadataKey.LANGUAGE])?.language ?? "English"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.organizationContainer}>
              <div className={styles.orgSectionTitle}>
                <TextComponent textType='Content'>
                  <div style={{
                    paddingBottom: "5px",
                    fontWeight: "bold"
                  }}>{t('workspace')}</div>
                </TextComponent>
              </div>
              {
                profile && profile.organizations.length < 1 ?
                  <div className={styles.organizationError}>
                    <div className={styles.organizationErrorText}>
                      <TextComponent textType='Content'>{t('workspace_content_one')} <span style={{ color: "blue", cursor: "pointer" }} onClick={() => setToggleAddOrg(true)}>{t('workspace_content_two')}</span> {t('workspace_content_three')} "<span style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={() => { navigate('invites') }}>{t('my_invitation')}</span>" {t("workspace_content_four")}</TextComponent>
                    </div>
                  </div> :
                  <>
                    <div className='tableWrapper'>
                      <div className='tableHeaderRow'>
                        <div className='tableData'>{`${t("id")}`}</div>
                        <div className='tableData'>{`${t("org_name")}`}</div>
                      </div>
                      {
                        profile && profile.organizations.length > 0 &&
                        profile.organizations.map(org => {
                          return <>
                            <div className='tableRow' key={org.id}>
                              <div className='tableData'>{org.name}</div>
                              <div className='tableData'>{org.displayName}</div>
                            </div>
                          </>
                        })
                      }
                    </div>
                    <div className='mobileContainer'>
                      {
                        profile && profile.organizations.length > 0 &&
                        profile.organizations.map(org => {
                          return <>
                            <div className='mobileCard'>
                              <div className='rowOne'>{`${t("id")}`} : <span style={{ maxWidth: '50%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{org.name}</span></div>
                              <div className='rowOne'>{`${t("org_name")}`} : <span style={{ maxWidth: '50%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{org.displayName}</span></div>
                            </div>
                          </>
                        })
                      }
                    </div>
                  </>
              }
              {/* <button type='submit' onClick={addPaymentAccount} >add</button> */}
              <div style={{ "marginTop": "2rem" }}>
                <TextComponent textType='Header'>
                  <div style={{ paddingBottom: '0.5rem' }}>{t('payment')}</div>
                </TextComponent>
                <div className={styles.cardContainer}>
                  {
                    (billingAcc && billingAcc.length)
                      ? billingAcc.map((card) => (
                        <div className={styles.cardDetails}>
                          <div className={styles.cardDetailsContainer}>
                            <div>{`**** **** **** ${card.last4}`} </div>
                            <div> {`${card.expMonth}`} / {`${card.expYear}`}</div>
                            <div> {`${card.name}`} </div>
                          </div>
                          {
                            billingAcc.length > 1 &&
                            <CustomTooltip content={'Delete'} direction={Direction.TOP}>
                              <div className={styles.deleteContainer}>
                                <button style={{
                                  background: "none",
                                  border: "none",
                                  outline: "none",
                                  cursor: "pointer"
                                }} onClick={() => onDeleteCardClick(card)} >
                                  <img style={{ width: "1.2rem" }} src={svgDelete} alt="filter" />
                                </button>
                              </div>
                            </CustomTooltip>
                          }
                        </div>
                      ))
                      : null
                  }
                  <div className={styles.cardDetails} onClick={() => addPaymentAccount()}>
                    <TextComponent textType='Header'>
                      <div>{`${t("add_payment_method")}`}</div>
                    </TextComponent>
                  </div>
                </div>
              </div>
              <Modal showModal={showModal} setShowModal={setShowModal}
                header={
                  <div>{t("sure_delete_account")}</div>
                }
                content={
                  <div style={{ display: "flex", flexDirection: 'column', gap: "1rem" }}>
                    <div style={{ display: "flex", gap: "1rem" }}>
                      <CustomButton
                        buttonType='button'
                        classType='secondary'
                        onClick={() => deleteMyAccount()}
                      >
                        <div style={{ color: "#000" }}>{t("yes")}</div>
                      </CustomButton>
                      <CustomButton
                        buttonType='button'
                        classType='quaternary'
                        onClick={() => { setShowModal(false) }}>
                        <div>{t("no")}</div>
                      </CustomButton>
                    </div>
                  </div>
                }
              />
              <div style={{ "marginTop": "2rem", "display": "none" }}>
                <TextComponent textType='Header'>
                  <div style={{ paddingBottom: '0.5rem' }}>{t('delete_account')}</div>
                </TextComponent>
                <div className={styles.cardContainer}>
                  <div className={styles.cardDetails} onClick={() => { setShowModal(true) }}>
                    <TextComponent textType='Header'>
                      <div>{`${t("delete")}`}</div>
                    </TextComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddOrganizationModal toggleAddOrg={toggleAddOrg} setToggleAddOrg={setToggleAddOrg} />
    </>
  )
}
