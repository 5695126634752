import styles from './control-item.module.scss';
import React, { DragEvent, useRef } from 'react';
import { ControlItemProps } from './control-item.props';
import CustomInput from '../../common/custom-input/custom-input';
import CustomTextArea from '../../common/custom-text-area/custom-text-area';
import CustomFileInput from '../../common/custom-file-input/custom-file-input';
import CustomCheckbox from '../../common/custom-checkbox/custom-checkbox';
import CustomRadio from '../../common/custom-radio/custom-radio';
import CustomSelect from '../../common/custom-select/custom-select';
import UseValidation from '../../../../util/validator/use-validation';
import { debounce } from '../../../../util/debounce';
import toast from 'react-hot-toast';
import { RootDispatch, RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import svgDrag from "../../../../assets/drag.svg";
import svgTextControl from "../../../../assets/v2/controls/text.svg";
import svgNumberControl from "../../../../assets/v2/controls/number.svg";
import svgDateControl from "../../../../assets/v2/controls/date.svg";
import svgTimeControl from "../../../../assets/v2/controls/time.svg";
import svgRaffleControl from "../../../../assets/v2/controls/raffle.svg";
import svgQuizControl from "../../../../assets/v2/controls/quiz.svg";
import svgCopyBuilder from "../../../../assets/v2/copy.svg";
import svgDeleteBuilder from "../../../../assets/v2/delete.svg";
import svgMailIcon from "../../../../assets/v2/icons/mail-icon.svg";
import svgPhoneIcon from "../../../../assets/v2/icons/phone-icon.svg";
import svgSettingsBuilder from "../../../../assets/v2/settings.svg";
import TextComponent from '../../common/text-component/text-component';
import ToolTip from '../../common/tool-tip/tool-tip';

export function ControlItem(props: ControlItemProps) {
  const enableDrag = (e: React.MouseEvent<Element, MouseEvent>) => props.setIsDraggable(true);

  const disableDrag = (e: React.MouseEvent<Element, MouseEvent>) => props.setIsDraggable(false);

  const { labelValidation } = UseValidation();
  const dispatch: RootDispatch = useDispatch();
  const { t } = useTranslation();
  const nextSibling = useRef<ChildNode>();

  const controlErrorMessage = useSelector((state: RootState) => { return state.validation.controlValidation });

  const onCtrlDragStart = (e: DragEvent<HTMLDivElement>) => {
    // set mouse tracker
    props.mouseHeight.current = e.clientY;

    // set all required elements
    const pickedElement = e.target as HTMLDivElement;
    const order = pickedElement.getAttribute('ctrl-order');
    props.pickedCtrlRef.current = pickedElement;
    props.pickedCtrlIndexRef.current = order ? +order : undefined;
    props.pickedCtrlParentRef.current = props.parent;
    props.pickedCtrlParentIndexRef.current = props.parentIndex;
    props.pickedCtrlParentDomRef.current = pickedElement.parentElement as HTMLDivElement;
    props.pickedCtrlGrandParentRef.current = props.grandParent;
    props.pickedCtrlGrandParentIndexRef.current = props.grandParentIndex;
    props.pickedCtrlId.current = props.formControl.id;
    props.hoverCtrlId.current = props.formControl.id;
    props.hoverCtrlRef.current = pickedElement;
    props.hoverCtrlIndexRef.current = order ? +order : undefined;
    nextSibling.current = pickedElement.nextSibling ?? undefined;

    // set dragged control styling
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.dropEffect = 'move';
    props.pickedCtrlRef.current.classList.add(styles.draggedCtrl);
    e.dataTransfer.clearData();
    e.dataTransfer.setData('re-order', 're-order');
    e.stopPropagation();
  }

  const onCtrlDragEnd = async (e: DragEvent<HTMLDivElement>) => {
    const resetValues = () => {
      if (props.pickedCtrlRef.current && props.hoverCtrlRef.current) {
        props.pickedCtrlRef.current.classList.remove(styles.draggedCtrl);
        props.pickedCtrlRef.current = undefined;
        props.pickedCtrlIndexRef.current = undefined;
        props.pickedCtrlParentRef.current = undefined;
        props.pickedCtrlParentIndexRef.current = undefined;
        props.pickedCtrlParentDomRef.current = undefined;
        props.pickedCtrlGrandParentRef.current = undefined;
        props.pickedCtrlGrandParentIndexRef.current = undefined;
        props.hoverCtrlRef.current.classList.remove(styles.dragOverCtrl);
        props.hoverCtrlRef.current = undefined;
        props.hoverCtrlIndexRef.current = undefined;
        props.hoverCtrlParentRef.current = undefined;
        props.hoverCtrlParentIndexRef.current = undefined;
        props.hoverCtrlGrandParentRef.current = undefined;
        props.hoverCtrlGrandParentIndexRef.current = undefined;
        props.pickedCtrlId.current = '';
        props.hoverCtrlId.current = '';
      }
      props.setIsDraggable(false);
    }
    props.hoverCtrlRef.current?.classList.remove(styles.dragOverCtrl);
    if (props.pickedCtrlRef.current) {
      props.pickedCtrlRef.current.classList.remove(styles.draggedCtrl);
    }
    if (props.hoverCtrlParentRef.current?.controlType === 'section' && props.formControl.controlType === 'raffle') {
      toast.error(t("builder_raffle_in_sec_not_allowed"));
      props.pickedCtrlParentDomRef.current?.insertBefore(props.pickedCtrlRef.current as Node, nextSibling.current ? nextSibling.current : null);
      try { props.hoverCtrlRef.current?.removeChild(props.pickedCtrlRef.current as Node); } catch (err) { }
      resetValues();
      return;
    }
    if (props.hoverCtrlParentRef.current?.controlType === 'section' && props.formControl.controlType === 'quiz') {
      toast.error(t("builder_quiz_in_sec_not_allowed"));
      props.pickedCtrlParentDomRef.current?.insertBefore(props.pickedCtrlRef.current as Node, nextSibling.current ? nextSibling.current : null);
      try { props.hoverCtrlRef.current?.removeChild(props.pickedCtrlRef.current as Node); } catch (err) { }
      resetValues();
      return;
    }
    if (props.pickedCtrlRef.current !== props.hoverCtrlRef.current) {
      if (props.pickedCtrlRef.current) {
        const val = Array.from(props.pickedCtrlRef.current.parentNode?.children as HTMLCollection).indexOf(props.pickedCtrlRef.current);
        props.hoverCtrlIndexRef.current = val;
      }

      if (props.pickedCtrlParentRef.current?.id !== props.hoverCtrlParentRef.current?.id) {
        const element = props.pickedCtrlRef.current;
        const parent = props.pickedCtrlParentDomRef.current;
        if (element && parent) {
          parent.appendChild(element);
        }
      }

      await props.reorderControls({ controlId: props.formControl.id, formId: props.formControl.formId, newParentId: props.hoverCtrlParentRef.current?.id ? props.hoverCtrlParentRef.current?.id : '', newPosition: props.hoverCtrlIndexRef.current ? props.hoverCtrlIndexRef.current : 0, oldParentId: props.pickedCtrlParentRef.current?.id ? props.pickedCtrlParentRef.current.id : '', oldPosition: props.pickedCtrlIndexRef.current ? props.pickedCtrlIndexRef.current : 0 });

      resetValues();
      e.stopPropagation();
    }
    props.setIsDraggable(false);
  }

  const onCtrlDragEnter = (e: DragEvent<HTMLDivElement>) => {
    const vals = e.dataTransfer.types;
    const hoveredElement = e.target as HTMLDivElement;
    if (props.hoverCtrlRef.current) props.hoverCtrlRef.current.classList.remove(styles.dragOverCtrl);
    if (hoveredElement === props.pickedCtrlRef.current) {
      return;
    }
    hoveredElement.classList.add(styles.dragOverCtrl);

    let mouseDirection: 'up' | 'down';
    if (props.mouseHeight.current && props.mouseHeight.current > hoveredElement.getBoundingClientRect().y) {
      mouseDirection = 'down';
    } else {
      mouseDirection = 'up';
    }

    const controlOrder = hoveredElement.getAttribute('ctrl-order') as string;

    props.hoverCtrlRef.current = hoveredElement;
    props.hoverCtrlIndexRef.current = +controlOrder;
    props.hoverCtrlParentRef.current = props.parent;
    props.hoverCtrlParentIndexRef.current = props.parentIndex;
    props.hoverCtrlGrandParentRef.current = props.grandParent;
    props.hoverCtrlGrandParentIndexRef.current = props.grandParentIndex;
    props.hoverCtrlId.current = props.formControl.id;

    const parent = hoveredElement.parentElement;
    if (mouseDirection === 'down') {
      if (vals && vals[0] === 'create' && parent && props.dragPlaceHolder.current) {
        try { parent.insertBefore(props.dragPlaceHolder.current, hoveredElement); } catch (err) { }
      }
      else if (parent && props.pickedCtrlRef.current) {
        // when the reorder happens check if the source element is same as the parent element. If yes then
        // no problem. Else create a copy of the element and set the original element to display none and
        // move the clone node across for the reorder.
        try { parent.insertBefore(props.pickedCtrlRef.current, hoveredElement); } catch (err) { }
      }
    } else {
      if (vals && vals[0] === 'create' && parent && props.dragPlaceHolder.current) {
        try { parent.insertBefore(props.dragPlaceHolder.current, hoveredElement.nextSibling); } catch (err) { }
      }
      else if (parent && props.pickedCtrlRef.current) {
        try { parent.insertBefore(props.pickedCtrlRef.current, hoveredElement.nextSibling); } catch (err) { }
      }
    }

    props.mouseHeight.current = e.clientY;
    e.stopPropagation();
  }

  const onCtrlDragLeave = (e: DragEvent<HTMLDivElement>) => {
    props.hoverCtrlRef.current?.classList.remove(styles.dragOverCtrl);
    props.mouseHeight.current = e.clientY;
    e.stopPropagation();
  }

  const deleteControl = () => {
    const parentType = props.parent?.controlType === "section" ? "section" : "page";
    props.deleteControl({ formControl: props.formControl, controlIndex: props.controlIndex, formId: props.formControl.formId, order: props.controlIndex, parentId: null, parentType: parentType, parentIndex: props.parentIndex, grandParentIndex: props.grandParentIndex });
  }

  const insertCtrlLabel = debounce((args: any) => {
    try {
      const res = labelValidation(args.d);
      if (res.isError) return;
      const payload = {
        id: args.d.id,
        formId: args.d.formId,
        label: args.d.label
      };
      // dispatch(updateControlThunk({ socket: socket, payload: { data: payload, localState: args.d } }));
    } catch (error) {
      toast.error(`${error}`)
    }
  });

  const copyControl = () => {
    props.createControl({ controlIndex: props.controlIndex + 1, controlName: props.formControl.controlType, parentId: props.parent?.id ? props.parent.id : null, parentType: props.parent?.controlType === 'page' ? 'page' : 'section', grandParentIndex: props.grandParentIndex, parentIndex: props.parentIndex, isCopy: true, refCtrl: props.formControl });
  }

  let label1;
  if (props.formControl.labelHTML) {
    label1 = parse(props.formControl.labelHTML);
  } else if (props.formControl.label) {
    label1 = parse(props.formControl.label);
  } else {
    label1 = <p className={styles.labelPlaceHolder}>Enter label content</p>;
  }

  return (
    <div ctrl-id={props.formControl.id} ctrl-order={props.controlIndex} className={`${styles.draggableItem} ${controlErrorMessage?.[props.formControl.formId] && controlErrorMessage[props.formControl.formId][props.formControl.id] ? styles.errorBoundary : ''} ${props.formControl.controlType === 'raffle' ? styles.raffleBackground : ''} ${props.formControl.controlType === 'quiz' ? styles.quizBackground : ''}`} draggable={props.isDraggable} onDragStart={e => { onCtrlDragStart(e) }} onDragEnd={e => onCtrlDragEnd(e)} onDragEnter={e => onCtrlDragEnter(e)} onDragLeave={e => onCtrlDragLeave(e)} onClick={() => { }}
    >
      <div className={styles.dragImgContainer}>
        <div className={styles.dragBtn} onMouseDown={e => enableDrag(e)} onMouseUp={e => disableDrag(e)}>
          <img className={styles.dragImg} src={svgDrag} alt="drag" />
        </div>
      </div>
      <div className={styles.controlContainer}>
        {
          props.formControl.controlType !== 'raffle' && props.formControl.controlType !== 'quiz' &&
          <div style={{ cursor: "pointer", display: "flex", "justifyContent": "space-between" }} onClick={() => { props.setActionBarTitle(`${t(`${props.formControl.controlType[0].toLocaleUpperCase()}${props.formControl.controlType.substring(1)}`)} ${" "} ${t('properties')}`); props.setIsActionPanelOpen(!props.isActionPanelOpen); props.setSidePaneTabvalue("properties"); props.setActiveFormControl({ control: props.formControl, controlIndex: props.controlIndex, parentType: props.parent ? props.parent.controlType : null, grandParentIndex: props.grandParentIndex, parentIndex: props.parentIndex, lookupCtrls: [], createControl: props.createControl, deleteControl: props.deleteControl }) }}>
            <div className={styles.labelStyle}>
              <label className={(props.formControl.config?.quiz && props.formControl.config.quiz.score) ? styles.labelComponentQuiz : styles.labelComponent} htmlFor={props.formControl.id}>{label1}</label>
              {props.formControl.config?.isMandatory && <span style={{ color: 'red', display: "flex", alignItems: "flex-end" }}> *</span>}
            </div>
            {props.formControl.config?.quiz && props.formControl.config.quiz.score && props.isQuizEnabled ? <p style={{ width: "7%" }}>{t("score")}: {props.formControl.config.quiz.score}</p> : <></>}
          </div>
        }
        {
          (() => {
            switch (props.formControl.controlType) {
              case "text":
                return <div style={{ position: 'relative' }}>
                  <img style={{ position: 'absolute', top: '50%', right: '0.5rem', transform: 'translateY(-50%)' }} src={svgTextControl} alt="" draggable={false} />
                  <CustomInput style={{ textOverflow: 'ellipsis' }} inputStyleType='primary' inputType='text' placeHolder={props.formControl.config.placeHolder} readonly={true} leftLittleMoreExtraPadding={true} />
                </div>
              case "textarea":
                return <CustomTextArea getText={() => { }} placeHolder={props.formControl.config.placeHolder} disabled={true} />
              case "number":
                return <div style={{ position: 'relative' }}>
                  <img style={{ position: 'absolute', top: '50%', right: '0.5rem', transform: 'translateY(-50%)' }} src={svgNumberControl} alt="" draggable={false} />
                  <CustomInput style={{ textOverflow: 'ellipsis' }} inputStyleType='primary' inputType='text' placeHolder={props.formControl.config.placeHolder} readonly={true} leftExtraPadding={true} />
                </div>
              case "date":
                return <div style={{ position: 'relative' }}>
                  <img style={{ position: 'absolute', top: '50%', right: '0.5rem', transform: 'translateY(-50%)' }} src={svgDateControl} alt="" draggable={false} />
                  <CustomInput style={{ textOverflow: 'ellipsis' }} inputStyleType='primary' inputType='text' placeHolder={props.formControl.config.placeHolder} readonly={true} />
                </div>
              case "time":
                return <div style={{ position: 'relative' }}>
                  <img style={{ position: 'absolute', top: '50%', right: '0.5rem', transform: 'translateY(-50%)' }} src={svgTimeControl} alt="" draggable={false} />
                  <CustomInput style={{ textOverflow: 'ellipsis' }} inputStyleType='primary' inputType='text' placeHolder={props.formControl.config.placeHolder} readonly={true} />
                </div>
              case "file":
                return <CustomFileInput disabled={false} multiple={true} isReadOnly={true} placeHolder={props.formControl.config.placeHolder} />
              case "checkbox":
              case "radio":
                return (
                  <div className={`${styles.optionsContainer} ${props.formControl.config != null && props.formControl.config.layout != null && props.formControl.config.layout === 'column' ? styles.optionsContainerCol : ''}`}>
                    {
                      props.formControl.options.length === 0
                        ?
                        ['option 1', 'option 2', 'option 3'].map((item, index) => (
                          <React.Fragment key={index}>
                            {
                              <div style={props.formControl.controlType === 'radio' ? {
                                display: "flex",
                                gap: "0.2rem",
                                boxSizing: "border-box",
                                padding: "0.25rem 0.5rem",
                                alignItems: "center"
                              } : {
                                display: "flex",
                                gap: "1.9rem",
                                boxSizing: "border-box",
                                padding: "0.5rem 1rem"
                              }}>
                                {
                                  props.formControl.controlType === 'radio'
                                    ? <CustomRadio data='' disabled={true} />
                                    : <CustomCheckbox data='' disabled={true}></CustomCheckbox>
                                }
                                <div style={{ color: '#9197a3' }}>{item}</div>
                              </div>
                            }
                          </React.Fragment>
                        ))
                        :
                        props.formControl.options.map((item, index) => (
                          <React.Fragment key={index}>
                            {
                              item.length > 0 &&
                              <div style={props.formControl.controlType === 'radio' ? {
                                display: "flex",
                                gap: "0.5rem",
                                boxSizing: "border-box",
                                padding: "0.25rem 0.5rem",
                                alignItems: "center"
                              } : {
                                display: "flex",
                                gap: "2rem",
                                boxSizing: "border-box",
                                padding: "0.5rem"
                              }}>
                                {
                                  props.formControl.controlType === 'radio'
                                    ? <CustomRadio data='' disabled={true} />
                                    : <CustomCheckbox data='' disabled={true}></CustomCheckbox>
                                }
                                <div>{item}</div>
                              </div>
                            }
                          </React.Fragment>
                        ))
                    }
                  </div>
                )
              case "dropdown":
                return <CustomSelect disabled={true} data={props.formControl.options.map(item => {
                  return { value: item, label: item }
                })} selectedData={() => { }} selectStyleType={'colored'} placeholder={props.formControl.config.placeHolder} />
              case "raffle":
                return (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {
                      props.formControl.config?.userInfo && props.formControl.config.userInfo.includes('email') &&
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                        <label style={{ display: 'flex', gap: '0.25rem' }} className={styles.labelComponent} htmlFor={props.formControl.id}>
                          {t("email_id")}
                          <img style={{ width: '1rem', position: 'relative', bottom: '2px', pointerEvents: 'none' }} src={svgRaffleControl} draggable={false} />
                        </label>
                        <div style={{ position: 'relative' }}>
                          <img style={{ position: 'absolute', top: '50%', right: '0.5rem', transform: 'translateY(-50%)', pointerEvents: 'none' }} src={svgMailIcon} alt="" draggable={false} />
                          <CustomInput style={{ textOverflow: 'ellipsis' }} inputStyleType='primary' inputType='text' placeHolder={props.formControl.config.placeHolder} readonly={true} leftLittleMoreExtraPadding={true} />
                        </div>
                      </div>
                    }
                    {
                      props.formControl.config?.userInfo && props.formControl.config.userInfo.includes('phone') &&
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                        <label style={{ display: 'flex', gap: '0.25rem' }} className={styles.labelComponent} htmlFor={props.formControl.id}>
                          {t("mobile")}
                          <img style={{ width: '1rem', position: 'relative', bottom: '2px', pointerEvents: 'none' }} src={svgRaffleControl} draggable={false} />
                        </label>
                        <div style={{ position: 'relative' }}>
                          <img style={{ position: 'absolute', top: '50%', right: '0.5rem', transform: 'translateY(-50%)', pointerEvents: 'none' }} src={svgPhoneIcon} alt="" draggable={false} />
                          <CustomInput style={{ textOverflow: 'ellipsis' }} inputStyleType='primary' inputType='text' placeHolder={props.formControl.config.placeHolder} readonly={true} leftLittleMoreExtraPadding={true} />
                        </div>
                      </div>
                    }
                  </div>)
              case "quiz":
                return (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {
                      props.formControl.config?.userInfo && props.formControl.config.userInfo.includes('email') &&
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                        <label style={{ display: 'flex', gap: '0.25rem' }} className={styles.labelComponent} htmlFor={props.formControl.id}>
                          {t("email_id")}
                          <img style={{ width: '1rem', position: 'relative', bottom: '1px', pointerEvents: 'none' }} src={svgQuizControl} draggable={false} />
                        </label>
                        <div style={{ position: 'relative' }}>
                          <img style={{ position: 'absolute', top: '50%', right: '0.5rem', transform: 'translateY(-50%)', pointerEvents: 'none' }} src={svgMailIcon} alt="" draggable={false} />
                          <CustomInput style={{ textOverflow: 'ellipsis' }} inputStyleType='primary' inputType='text' placeHolder={props.formControl.config.placeHolder} readonly={true} leftLittleMoreExtraPadding={true} />
                        </div>
                      </div>
                    }
                  </div>)
            }
          })()
        }
        {
          controlErrorMessage && controlErrorMessage[props.formControl.formId] && controlErrorMessage[props.formControl.formId][props.formControl.id] && controlErrorMessage[props.formControl.formId][props.formControl.id]["label"] &&
          <TextComponent textType='Error'>
            <div>{t((controlErrorMessage[props.formControl.formId][props.formControl.id]["label"]))} </div>
          </TextComponent>
        }
        {
          controlErrorMessage && controlErrorMessage[props.formControl.formId] && controlErrorMessage[props.formControl.formId][props.formControl.id] && controlErrorMessage[props.formControl.formId][props.formControl.id]["options"] &&
          <TextComponent textType='Error'>
            <div>{t((controlErrorMessage[props.formControl.formId][props.formControl.id]["options"]))} </div>
          </TextComponent>
        }
        {
          controlErrorMessage && controlErrorMessage[props.formControl.formId] && controlErrorMessage[props.formControl.formId][props.formControl.id] && controlErrorMessage[props.formControl.formId][props.formControl.id]["raffleParticipants"] &&
          <TextComponent textType='Error'>
            <div>{t((controlErrorMessage[props.formControl.formId][props.formControl.id]["raffleParticipants"]))} </div>
          </TextComponent>
        }
        {
          controlErrorMessage && controlErrorMessage[props.formControl.formId] && controlErrorMessage[props.formControl.formId][props.formControl.id] && controlErrorMessage[props.formControl.formId][props.formControl.id]["quizControl"] &&
          <TextComponent textType='Error'>
            <div>{t((controlErrorMessage[props.formControl.formId][props.formControl.id]["quizControl"]))} </div>
          </TextComponent>
        }
        {
          controlErrorMessage && controlErrorMessage[props.formControl.formId] && controlErrorMessage[props.formControl.formId][props.formControl.id] && controlErrorMessage[props.formControl.formId][props.formControl.id]["totalScore"] &&
          <TextComponent textType='Error'>
            <div>{t((controlErrorMessage[props.formControl.formId][props.formControl.id]["totalScore"]))} </div>
          </TextComponent>
        }
        {
          controlErrorMessage && controlErrorMessage[props.formControl.formId] && controlErrorMessage[props.formControl.formId][props.formControl.id] && controlErrorMessage[props.formControl.formId][props.formControl.id]["raffle"] &&
          <TextComponent textType='Error'>
            <div>{t((controlErrorMessage[props.formControl.formId][props.formControl.id]["raffle"]))} </div>
          </TextComponent>
        }
      </div>
      {
        props.isDraggable === false &&
        <div className={styles.deleteBtn}>

          <ToolTip content={`${t("cofigure_control")}`} position='top' >
            <button className={styles.hoverBtn} onClick={() => { props.setActionBarTitle(`${t(`${props.formControl.controlType[0].toLocaleUpperCase()}${props.formControl.controlType.substring(1)}`)} ${" "} ${t('properties')}`); props.setIsActionPanelOpen(!props.isActionPanelOpen); props.setSidePaneTabvalue("properties"); props.setActiveFormControl({ control: props.formControl, controlIndex: props.controlIndex, parentType: props.parent ? props.parent.controlType : null, grandParentIndex: props.grandParentIndex, parentIndex: props.parentIndex, lookupCtrls: [], createControl: props.createControl, deleteControl: props.deleteControl }) }}>
              <div className={styles.contronContextBtnConatiner}>
                <img className={styles.contronContextImg} src={svgSettingsBuilder} alt="settings" draggable={false} />
              </div>
            </button>
          </ToolTip>
          {
            props.formControl.controlType !== 'raffle' && props.formControl.controlType !== 'quiz' &&
            <ToolTip content={`${t("clone_control")}`} position='top'>
              <button className={styles.hoverBtn} onClick={copyControl}>
                <div className={styles.contronContextBtnConatiner}>
                  <img className={styles.contronContextImg} src={svgCopyBuilder} alt="settings" draggable={false} />
                </div>
              </button>
            </ToolTip>
          }
          <ToolTip content={`${t("delete_control")}`} position='top'>
            <button className={styles.hoverBtn} onClick={deleteControl} disabled={props.isLoading}>
              <div className={styles.contronContextBtnConatiner}>
                <img className={styles.contronContextImg} src={svgDeleteBuilder} alt="settings" draggable={false} />
              </div>
            </button>
          </ToolTip>
        </div>
      }
    </div >
  )
}
