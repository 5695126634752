import styles from './integrations.module.scss';
import { IntegrationsRowProps } from './integrations-row.props';
import IntegrationActionContainer from './integration-action-container';


export default function IntegrationsRow(props: IntegrationsRowProps) {

  return (
    <>
      <div key={props.inviteItem.id} className={"tableRow"}>
        <div id="name" className={"tableData"}>{props.inviteItem.displayName}</div>
        <div id="type" className={"tableData"}>{props.inviteItem.type}</div>
        <div id="forms-count" className={"tableData"}>{new Date(props.inviteItem.createdAt).toLocaleString()}</div>
        <div id="action" className={`${"tableData"} ${styles.actionContainer}`}>
          <IntegrationActionContainer
          item={props.inviteItem}
          setIntegrationsType={props.setIntegrationsType}
          setIsPanelOpen={props.setIsPanelOpen}
          setItemId={props.setItemId}
          setSelectedIntegration={props.setSelectedIntegration}
          setShowModal={props.setShowModal}
          />
        </div>
      </div>
    </>
  )
}

