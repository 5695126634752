import React from 'react';
import styles from "./custom-loader.module.scss";


export default function CustomLoader() {
    return (
        <div className={styles.container} >
        <div className={styles.mainLoader}>
            <div className={styles.loaderContainer}>
                <div className={styles.loader}></div>
            </div>
        </div>

            {/* <div className={styles.container}>
                <div className={styles.parent}>
                    <div className={styles.item}></div>
                </div>
            </div> */}
        </div>
    )
}
