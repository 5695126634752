import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAPIKey, deleteAPIKey, getAPIKeys } from "../../api/api-keys";

export const getAPIKeysThunk = createAsyncThunk(
    'apiKeys/getAPIKeys',
    async () => {
        const res = await getAPIKeys();
        return res;
    }
)

export const createAPIKeyThunk = createAsyncThunk(
    'apiKeys/add',
    async (payload : { name: string }) => {
        const res = await createAPIKey({ name : payload.name });
        return res;
    }
)

export const deleteAPIKeyThunk = createAsyncThunk(
    'apiKeys/delete',
    async (id: string) => {
        await deleteAPIKey(id);
        return id;
  }
);

export const splitPrefixAndX = (prefixWithX: string) => {
    return { prefix : prefixWithX.slice(0, 14), X : prefixWithX.slice(15) };
}