import styles from './no-content.module.scss';
import CustomText from '../custom-bodyText/custom-text';
import { NoContentProps } from './no-content.props';
import svgNoForms from "../../../../assets/v2/icons/no-forms.svg";

export default function NoContent(props: NoContentProps) {
  return (
    <div className={styles.noFormsContainer}>
      <div><img src={svgNoForms} alt="no-forms" /></div>
      <CustomText textType='t4'>
        <div>{props.content}</div>
      </CustomText>
    </div>
  )
}
