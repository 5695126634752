import { createContext } from 'react';
import { useSelector } from 'react-redux';
import io, { ManagerOptions, SocketOptions } from 'socket.io-client';
import { setStatus } from '../store/slices/socket.slice';
import store, { RootState } from '../store/store';
import { setActiveUsers } from '../store/slices/forms.slice';
import { activePageThunk } from '../store/thunks/active-page.thunk';
import { setNewData } from '../store/slices/active-page.slice';

export function SocketInit(token: string, activeOrg: string) {
  const socketIns = useSelector((state: RootState) => state.socket.Ins);
  const activePage = useSelector((state: RootState) => state.activePage.activePage);
  const activePageStatus = useSelector((state: RootState) => state.activePage.status);

  // setting in session storage, to set authToken on socket reconnect
  sessionStorage.setItem('sessionToken', token)

  if (!token) {
    return -1;
  }
  if (socketIns !== null) {
    return socketIns;
  }
  /**
   * Pardon the URL gymnastics. If you put a path in the socket URL, socket.io assumes it to be a "namespace".
   * So we've to strip the bad boy out of the way...
   * ...and then add it back in the `path` property of the options below.
   * Sigh!
   */
  const socketUrl = process.env.REACT_APP_MANAGER_SVC as string ? (process.env.REACT_APP_MANAGER_SVC as string).replace('/' + process.env.REACT_APP_MANAGER_NAME, '') : 'http://localhost:3000';

  const socketOptions: Partial<ManagerOptions & SocketOptions> = process.env.REACT_APP_MANAGER_NAME ? {
    /**
     * We've to specify the token in both, the query param as well as the auth body, because of the
     * weird way we've to authenticate websockets.
     * The browser first sends a GET request as a handshake to the backend to establish the websocket
     * connection. This is a HTTP connection and the browser spec does not allow a Authorization
     * header to be included here. (It'll be stripped even if included in extraHeaders - it's
     * available in long polling but we use only the websocket transport.) We validate the handshake
     * at the backend to determine if we have to allow the connection or not. At this point the
     * websocket client hasn't even been initialized and the hence the "auth" content won't be
     * available. This is why we have to use the query param.
     * Once the handshake is validated, the GET connection gets "upgraded" to a pure TCP websocket
     * connection. From this point onwards, communication switches to packets and standard HTTP
     * properties like headers and query params are not available. Yet, we'd still like to validate
     * the roles for individual websocket messages. We need the token to do that. The only way, we
     * can specify the token going forward is via the "auth" property which bakes the token into the
     * client data. This can then be fetched in the AuthGuard to extract roles and authorize the
     * individual messages.
     * Why can't we use just this info to verify messages at the AuthGuard instead of specifying it
     * in the query param and validating the handshake?
     * Well, due to the way websockets work, by the time the AuthGuard becomes active, messages will
     * already be queuing up in the backend. The guard will validate the messages and let them through,
     * but it has no ability to control the connection in itself. As a result, there is a possibility
     * Hence, the dual mode.
     */
    query: { token, activeOrg },
    auth: { token, activeOrg },
    timeout: 5000,
    path: `/${process.env.REACT_APP_MANAGER_NAME}/socket.io`,
    /**
     * ONLY 'websocket' transport for deployment. In multi-instance setups, using polling will prevent you from using session persistence even if Redis is used.
     * This can be prevented by using cookies but I'm in no mood to deal with them now.
     * Refer: https://docs.nestjs.com/websockets/adapter
     */
    transports: ['websocket'],
  } : {
    query: { token, activeOrg },
    auth: { token, activeOrg },
    timeout: 5000,
    transports: ['websocket'],
  };


  const socket = io(socketUrl, socketOptions);

  socket.on('connect', () => {
    console.debug('✔️ socket connected');
    store.dispatch(activePageThunk({ socket: socket, payload: { pageName: activePage, status: activePageStatus } }));
    const engine = socket.io.engine;
    console.debug('[pre upgrade] engine transport name:', engine.transport.name); // in most cases, prints 'polling'
    engine.once('upgrade', () => {
      // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
      console.debug('[post upgrade] engine transport name:', engine.transport.name); // in most cases, prints 'websocket'
    });
    engine.on('close', (reason) => {
      // called when the underlying connection is closed
      console.debug('engine close:', reason);
    });
    engine.on('error', err => {
      console.error('engine error: ', err);
    });
  });

  // set updated token on reconnect
  socket.io.on('reconnect_attempt', () => {
    const newToken = sessionStorage.getItem('sessionToken');
    socket.io.opts.query = { token: newToken };
  })

  socket.on('disconnect', () => {
    console.log('socket disconnected');
  });

  socket.on('activeusers', (payload) => {
    store.dispatch(setActiveUsers(payload.data))
  });
  store.dispatch(setStatus(socket));

  socket.on('newData', (payload) => {
    store.dispatch(setNewData(payload));
  })

  return socket;
}

export const SocketContext = createContext({});

