import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Editor, EditorState, RichUtils, ContentState, Modifier } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { TextStyle } from '../../../store/models/text-style.enum';
import styles from './rich-text-editor-modal.module.scss';
import { RichTextEditorProps } from './rich-text-editor-modal.props';
import CustomHeading from '../../v2/common/custom-heading/custom-heading';
import { BUTTON_TIP } from '../../v2/form-builder/control-properties-panel/constants';
import CustomText from '../../v2/common/custom-bodyText/custom-text';
import svgInfo from "../../../assets/v2/icons/info.svg";

export default function RichTextEditorModal(props: RichTextEditorProps) {
  const { t } = useTranslation();

  const [selectedTextStyleBtn, setSelectedTextStyleBtn] = useState({ BOLD: '', ITALIC: '', UNDERLINE: '' });

  let defaultLabelHtml = '';
  if (props.control?.labelHTML) {
    defaultLabelHtml = props.control.labelHTML;
  } else if (props.control?.label) {
    defaultLabelHtml = `<p>${props.control.label}</p>`;
  }
  const defaultHtmlBlock = htmlToDraft(defaultLabelHtml);
  const { contentBlocks, entityMap } = defaultHtmlBlock;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  useEffect(() => {
    setEditorState(EditorState.createWithContent(contentState));
    setSelectedTextStyleBtn({ BOLD: '', ITALIC: '', UNDERLINE: '' });
  }, [props.control.id]);

  useEffect(() => {
    if (!props.control.label || (props.control.label === '') || (props.liveUpdate === true)) {
      setEditorState(getResetEditorState(editorState));
      setSelectedTextStyleBtn({ BOLD: '', ITALIC: '', UNDERLINE: '' });
    }
  }, [props.control]);

  const removeSelectedBlocksStyle = (editorState: EditorState) => {
    const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
    if (newContentState) {
      return EditorState.push(editorState, newContentState, 'change-block-type');
    }
    return editorState;
  }

  const getResetEditorState = (editorState: EditorState) => {
    const blocks = editorState
      .getCurrentContent()
      .getBlockMap()
      .toList();
    const updatedSelection = editorState.getSelection().merge({
      anchorKey: blocks.first().get('key'),
      anchorOffset: 0,
      focusKey: blocks.last().get('key'),
      focusOffset: blocks.last().getLength(),
    });
    const newContentState = Modifier.removeRange(
      editorState.getCurrentContent(),
      updatedSelection,
      'forward'
    );
    const newState = EditorState.push(editorState, newContentState, 'remove-range');
    return removeSelectedBlocksStyle(newState);
  }

  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const editor = React.useRef(null);

  const onTxtStyBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, style: TextStyle) => {
    e.preventDefault();
    let nextState = RichUtils.toggleInlineStyle(editorState, style);
    handleOnChange(nextState);
  }

  const setCurrentBtnInlineStyle = (e: EditorState) => {
    const currentInlineStyle = e.getCurrentInlineStyle();

    let className: any = {};
    Object.values(TextStyle).forEach(s => {
      if (currentInlineStyle.has(s)) className[s] = 'active';
      else className[s] = '';
    });
    setSelectedTextStyleBtn(className);
  }

  const handleOnChange = (e: EditorState) => {
    setEditorState(e);
    setCurrentBtnInlineStyle(e);
    props.onChange(e);
  }

  return (
    <>
      <div className={`${styles.showModal} ${props.errorText ? styles.errorEditor : ''}`} style={props.style ? props.style : undefined} >
        <div className={styles.editorContainer} style={props.leftPadding === false ? { paddingLeft: '0' } : {}}  >
          <Editor
            ref={editor}
            editorState={editorState}
            onChange={(e) => { handleOnChange(e) }}
            placeholder={props.placeHolder ? props.placeHolder : undefined}
            readOnly={props.readOnly}
          />
        </div>
      </div>
      <div style={{ marginTop: '0.25rem' }}>{props.errorText && <CustomText textType='t8' isErrorText={true}>{t(props.errorText)}</CustomText>}</div>
      {/* textStyle */}
      {
        props.showTextStyle ?
          <div className={styles.propertyContainer}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>Text styles</div>
                <div className={styles.topCheckToolTip} tool-tip={`Select text in the label field above and choose from a style to format the selected text.`} style={{ position: 'relative', cursor: 'pointer' }} >
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <div className={styles.formatContainer}>
              <button
                className={`${styles.btn} ${selectedTextStyleBtn?.BOLD ? styles.active : ""} `}
                title={t(BUTTON_TIP.bold)}
                // onClick={(e) => onTxtStyBtnClick(e,  TextStyle.BOLD) }
                onMouseDown={(e) => onTxtStyBtnClick(e, TextStyle.BOLD)}
              >
                <div className={styles.textFormatContainer}><strong> B </strong></div>
              </button>
              <button
                className={`${styles.btn} ${selectedTextStyleBtn?.ITALIC ? styles.active : ""}`}
                title={t(BUTTON_TIP.italic)}
                onMouseDown={(e) => onTxtStyBtnClick(e, TextStyle.ITALIC)}
              // onClick={(e) => onTxtStyBtnClick(e,  TextStyle.ITALIC) }
              >
                <div className={styles.textFormatContainer}><em> I </em></div>
              </button>
              <button
                className={`${styles.btn} ${selectedTextStyleBtn?.UNDERLINE ? styles.active : ""} `}
                title={t(BUTTON_TIP.underline)}
                onMouseDown={(e) => onTxtStyBtnClick(e, TextStyle.UNDERLINE)}
              // onClick={(e) => onTxtStyBtnClick(e,  TextStyle.UNDERLINE) }
              >
                <div className={styles.textFormatContainer}><u> U </u></div>
              </button>
            </div>
          </div>
          : null
      }
    </>
  )
}


