import styles from "./custom-modal.module.scss";
import { useTranslation } from "react-i18next";
import { CustomModalProps } from "./custom-modal.props";

export function CustomModal(props: CustomModalProps) {
    return (
        <div id={props.id} className={`modal`}>
            <div className={styles.modalContainer}>
                <div className="heading">{props.header}</div>
                <div className={styles.content}>{props.content}</div>
                {
                    props.footer &&
                    <div className={styles.btnContainer} style={{ justifyContent: props.justifyContent }}>
                        {props.footer}
                    </div>
                }
            </div>
        </div>
    )
}

