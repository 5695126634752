import React, { useContext, useEffect, useRef, useState } from 'react'
import CustomLinkListing from './custom-link-listing'
import { GetCustomLinkInteractionsResDto } from '../../../../store/models/custom-links';
import { dispatchCustomLinks, revokeCustomLinkApi } from '../../../../api/forms';
import { CustomLinksDto } from '../../../../store/models/form.interface';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getLinkInteractions } from '../../../../api/custom-links';
import { ACTIVE_PAGE_STATUS } from '../../../../store/models/active-page';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { activePageThunk } from '../../../../store/thunks/active-page.thunk';
import { RootDispatch, RootState } from '../../../../store/store';
import { SocketContext } from '../../../../context/socket';
import { setNewData } from '../../../../store/slices/active-page.slice';
import { fetchFormByIdThunk } from '../../../../store/thunks/forms.thunk';

export default function CustomLink() {
  const [customLink, setCustomLink] = useState<GetCustomLinkInteractionsResDto[]>([]);
  const { t } = useTranslation()
  const [emails, setEmails] = useState<string[]>([]);
  const [errors, setErrors] = useState("");
  const { formId } = useParams();
  const resetTags = useRef<() => {}>();
  const [isDisabledCustom, setDisabledCustom] = useState<boolean>(false);
  const activeWebPage = useSelector((state: RootState) => state.activePage.activePage);
  const dispatch: RootDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const refreshWebPage = useSelector((state: RootState) => state.activePage.refreshPage);

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activeWebPage])

  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    if (formId) {
      dispatch(activePageThunk({ socket: socket, payload: { pageName: `form-details-${formId}`, status } }));
    }
  }

  useEffect(() => {
    if (newData) {
      getLatestData(newData);
      dispatch((setNewData({ action: "" })));
    }
  }, [newData])

  useEffect(() => {
    const temp = async () => await getInteractions();
    temp()
  }, [refreshWebPage])

  const getLatestData = async (newData?: string) => {
    try {
      if (formId) {
        if (newData === "forms") {
          await dispatch(fetchFormByIdThunk(formId));
        }
        if (customLink.length) {
          await getInteractions();
        }
      }
    } catch (err) { }
  }

  const getInteractions = async () => {
    if (formId) {
      try {
        const res = await getLinkInteractions(formId, {});
        setCustomLink(res.data.interactions);
      } catch (err) {
        toast.error(t("fetch_interaction_fail"));
      }
    }
  }

  const revokeCustomLink = async (id: string) => {
    try {
      await revokeCustomLinkApi(id);
      await getInteractions();
      toast.success("Link revoked");
    } catch (error) {
      toast.error(t("revoke_fail"))
    }
  }

  const dispatchMail = async () => {
    if (errors) return;
    if (formId) {
      const payload: CustomLinksDto = {
        emails: emails,
        formId: formId
      };
      const res = await dispatchCustomLinks(payload);
      if (res === true) {
        toast.success(t("email_sent"));
        setEmails([]);
        if (resetTags.current) resetTags.current();
        await getInteractions();
      } else {
        toast.error(t("form_details_toast_email_sent_err"));
      }
    }
  }

  return (
    <>
      {
        customLink && customLink.length > 0 &&
        <CustomLinkListing
          customLink={customLink}
          dispatchMail={dispatchMail}
          emails={emails}
          errors={errors}
          formId={formId}
          resetTags={resetTags}
          revokeCustomLink={revokeCustomLink}
          setEmails={setEmails}
          setErrors={setErrors}
          isDisabledCustom={isDisabledCustom}
          t={t}
        />
      }
    </>
  )
}
