import styles from './text-component.module.scss';
import { TextComponentProps } from './text-component.props.';

export default function TextComponent(props: TextComponentProps) {

    const getContentType = (classType: string) => {
        switch (classType) {
            case 'Content':
                return styles.content;
            case 'Header':
                return styles.header;
            case 'Error':
                return styles.errorContent;
            case 'Info':
                return styles.infoContent;
        }
    }

    return (
        <div className={props.textType ? getContentType(props.textType) : styles.content}>
            {props.children}
        </div>
    )
}
