import { ResponsiveCirclePacking } from '@nivo/circle-packing';
import { fontFamily } from './constants';
import { colorGenerator } from './colorGenerator';
import { interpolateGnBu } from 'd3-scale-chromatic';
import styles from './charts.module.scss'
import { TooltipWrapper } from '@nivo/tooltip';

export default function CircleChart(props: CircleChartProps) {

  return (
    <ResponsiveCirclePacking
      data={props.data}
      colors={colorGenerator(props.data.children.length, interpolateGnBu)}
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      borderColor={"#000"}
      borderWidth={0.4}
      id={props.id}
      labelTextColor={"#ffff"}
      theme={{
        fontFamily: fontFamily,
        fontSize: 14,
        tooltip: {
          container: {
            background: "#000000"
          },
        },
      }}
      tooltip={({ id, value, color }) => {
        return <>{chartToolTip({ id: id, value: value, color: color })}</>
      }}
      value={props.value}
      padding={4}
      label="id"
      colorBy='id'
      leavesOnly
    />)
}

export function chartToolTip(props: { id: string, value: number, color: string }) {
  return (
    <TooltipWrapper anchor='bottom' position={[0, 0]}>
      <div className={styles.tooltipContainer}>
        <span style={{
          minWidth: '0.75rem',
          minHeight: '0.75rem',
          background: props.color
        }}></span>
        <span>
          {props.id} : <strong>{props.value}</strong>
        </span>
      </div>
    </TooltipWrapper>
  )
}
export interface CircleChartProps {
  id: string;
  data: {
    name: string; // to print tge outer parent
    children: object[]; // actual data to print
  }
  value: string; // value should consider as  label in chart
}
