import { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, { Edge, Node, useReactFlow, NodeChange, applyNodeChanges, EdgeChange, applyEdgeChanges, Background, MarkerType } from "reactflow";
import { CustomNode } from "./custom-node";
import * as Dagre from 'dagre';
import { LogicControl } from "../logic-builder/models/logic-control.props";
import { ConditionalEdge } from "../../../../../store/models/form-builder/conditional-logic";


export function LayoutPanel(props: { control: LogicControl[], conditionalLogic: ConditionalEdge[] }) {

  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

  const getLayoutElements = (nodes: Node[], edges: Edge[]) => {

    g.setGraph({
      rankdir: 'TB',
      marginy: 50,
      marginx: 50,
      ranksep: 70,
      edgesep: 200,
      align: 'UR'
    });

    edges.forEach(edge => g.setEdge(edge.source, edge.target));
    nodes.forEach(node => g.setNode(node.id, node));

    Dagre.layout(g);

    return {
      nodes: nodes.map(node => {
        const { x, y } = g.node(node.id);
        return { ...node, position: { x, y } };
      }),
      edges
    }
  }

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const { fitView } = useReactFlow();
  const nodeTypes = useMemo(() => ({ customNode: CustomNode }), []);

  useEffect(() => {
    if (props.control) {
      const nodes: Node[] = [];
      const edges: Edge[] = [];

      for (let i = 0; i < props.control.length; i++) {
        const src = props.control[i];

        nodes.push({
          id: src.id,
          data: { label: src.label, type: src.controlType },
          position: { x: 0, y: 0 },
          type: 'customNode',
          width: 190
        });

        if (i < props.control.length - 1) {
          const tgt = props.control[i + 1];
          edges.push({
            id: `${src.id}-${tgt.id}`,
            source: src.id,
            target: tgt.id,
            // needed if the edges should have arrow. Commented out for now.
            // markerEnd: {
            //   type: MarkerType.Arrow,
            //   height: 32,
            //   width: 24,
            // },
            type: 'smoothstep'
          });
        }
      }

      for (const logic of props.conditionalLogic) {
        edges.push({
          id: `${logic.source}-${logic.target}`,
          source: logic.source,
          target: logic.target,
          markerEnd: {
            type: MarkerType.Arrow,
            height: 32,
            width: 24,
          },
          animated: true,
          label: `${logic.value.type.split('_').join(' ').toLowerCase()} [${logic.value.value.join(', ')}]`,
          type: 'default'
        });
      }

      setNodes(nodes);
      setEdges(edges);
      onLayout(nodes, edges);
    }
  }, [props.control, props.conditionalLogic])

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onLayout = useCallback(
    (nodes: Node[], edges: Edge[]) => {
      const layouted = getLayoutElements(nodes, edges);
      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);

      window.requestAnimationFrame(() => {
        fitView();
      })
    },
    [nodes, edges]
  );

  return (
    <div style={{ height: '100%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        proOptions={{
          hideAttribution: true
        }}
        nodeTypes={nodeTypes}
        fitView
      >
        <Background />
        {/* <Controls /> */}
      </ReactFlow>
    </div>
  )
}
