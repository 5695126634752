import CustomButton from "../../../../components/v2/common/custom-button/custom-button"
import { QuizModeProps } from "./quiz-mode.props"
import styles from './quiz-mode.module.scss';
import { useState } from "react";
import Modal from "../../../../components/v2/common/modal/modal";
import { endQuizMode, sendUserScore } from "../../../../api/forms";
import { FormState } from "../../../../store/models/form.interface";
import toast from 'react-hot-toast';
import svgPaneDelete from "../../../../assets/v2/icons/pane-delete.svg";
import { useTranslation } from "react-i18next";
import ToolTip from "../../../../components/v2/common/tool-tip/tool-tip";

export default function QuizMode(props: QuizModeProps) {
  const { t } = useTranslation();
  const [showEndQuizModal, setShowEndQuizModal] = useState(false);
  const [isSendScoreModal, setIsSendScoreModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const endQuiz = async () => {
    if (props.form) {
      setIsLoading(true);
      try {
        await endQuizMode(props.form.id);
        if (props.quiz) props.setQuiz({ ...props.quiz, config: { ...props.quiz?.config, isActive: false } });
        toast.success(t('quiz_ended'));
      } catch (err) {
        toast.error(t('quiz_end_fail'));
      }
      setIsLoading(false);
      setShowEndQuizModal(false);
    }
  }

  const sendScore = async () => {
    if (props.form) {
      try {
        const res = await sendUserScore(props.form.id);
        if (props.quiz) props.setQuiz({ ...props.quiz, config: { ...props.quiz?.config, scoreSent: true } });
        toast.success(t('score_sent'));
      } catch (err) {
        toast.error(t('score_sent_fail'));
      }
      setIsLoading(false);
      setIsSendScoreModal(false);
    }
  }

  return (
    <>
      {/* End quiz modal */}
      <Modal showModal={showEndQuizModal} setShowModal={setShowEndQuizModal}
        header={
          <div className={styles.endQuizHeader}>
            <div className={styles.headerContainer}>
              <div style={{ fontWeight: '600' }}>
                {t('heads_up')}
              </div>
              <div style={{ position: 'absolute', right: '0' }}>
                <button className={styles.quizClosebtn} onClick={() => { setShowEndQuizModal(false) }}>
                  <img className={styles.closImg} src={svgPaneDelete} alt="" />
                </button>
              </div>
            </div>
            <div style={{ width: '75%', textAlign: 'center', fontWeight: '400' }}>
              {t('quiz_end_sure')}
            </div>
          </div>
        }
        content={
          <div style={{ display: "flex", gap: "5rem" }}>
            <CustomButton
              buttonType='button'
              classType='secondary'
              onClick={() => { setShowEndQuizModal(false) }}
            >
              <div>{t('no_back')}</div>
            </CustomButton>
            <CustomButton
              buttonType='button'
              classType='quaternary'
              onClick={() => { endQuiz() }}>
              {
                isLoading
                  ? <div>{t('loading')}</div>
                  : <div>{t('yes_end_quiz')}</div>
              }
            </CustomButton>
          </div>
        }
      />
      {/* pick winner mode raffle modal */}
      <Modal showModal={isSendScoreModal} setShowModal={setIsSendScoreModal}
        header={
          <div className={styles.endRaffleHeader}>
            <div className={styles.headerContainer}>
              <div style={{ width: "100%", color: "#642DFB", fontWeight: "600", fontSize: "16px" }}>
                {t('pick_winner')}
              </div>
              <div>
                <button className={styles.raffleClosebtn} onClick={() => { setIsSendScoreModal(false) }}>
                  <img className={styles.closImg} src={svgPaneDelete} alt="" />
                </button>
              </div>
            </div>
          </div>
        }
      />
      {
        props.form && props.form.state === FormState.PUBLISHED &&
        <div className={styles.quizBtnContainer}>
          {
            props.quiz?.config.isActive === true && props.responseCount > 0
              ?
              <CustomButton buttonType="button" classType="quaternary" onClick={() => { setShowEndQuizModal(true) }}>
                <div style={{color:'var(--font-color)'}}>{t('end_quiz')}</div>
              </CustomButton>
              :
              <div style={{width:'100%'}}>
                <ToolTip content={props.responseCount > 0 ? `${t('quiz_ended_tip')}` : `${t('resp_less_than_1')}`} position="bottom">
                  <CustomButton buttonType="button" classType="quaternary" onClick={() => { }} disabled={true}>
                    <div>{t('end_quiz')}</div>
                  </CustomButton>
                </ToolTip>
              </div>
          }
          {
            props.quiz?.config.isActive === false && props.quiz.config.scoreSent === false
              ?
              <div style={{width:'100%'}}>
                <ToolTip content={
                  (props.quiz?.config.isActive === false)
                    ? `${t('select_and_send_score')}`
                    : `${t('end_quiz_send_score')}`
                } position="bottom">
                  <CustomButton buttonType="button" classType="quaternary" onClick={() => { sendScore() }}>
                    <div>{t('send_score')}</div>
                  </CustomButton>
                </ToolTip>
              </div>
              :
              <CustomButton buttonType="button" classType="quaternary" disabled={true} onClick={() => { }}>
                <div>{t('send_score')}</div>
              </CustomButton>
          }
        </div>
      }

    </>

  )
}
