import axios from 'axios';
import { GetInvitationsRespDto, GetUserRespDto, OrgInviteReqDto } from '../store/models/user.interface';
import { GenerateApiUrl } from './generate-api-url';

export async function acceptInviteToOrganization(inviteId: string, data: OrgInviteReqDto) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `users/invitations/${inviteId}/accept`, false);
  const response = await axios.post<void>(apiUrl, data);
  return response.data;
}

export async function declineInviteToOrganization(inviteId: string, data: OrgInviteReqDto) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `users/invitations/${inviteId}/decline`, false);
  const response = await axios.post<void>(apiUrl, data);
  return response.data;
}

export async function getInvitations(): Promise<GetInvitationsRespDto & { status: number, message: string }> {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `users/invitations`, false);
  const response = await axios.get<GetInvitationsRespDto & { status: number, message: string }>(apiUrl);
  return response.data;
}

export async function getUser(userId: string, qParams?: { email: string, orgName: string }): Promise<GetUserRespDto & { status: number, message: string }> {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `users/${userId}`, false);
  let params = {};
  if (qParams?.email) {
    Object.assign(params, { email: qParams.email });
  }
  if (qParams?.orgName) {
    Object.assign(params, { orgName: qParams.orgName });
  }
  const response = await axios.get<GetUserRespDto & { status: number, message: string }>(apiUrl, { params: params });
  return response.data;
}
