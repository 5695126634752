import { useEffect, useState } from 'react';
import styles from './carousal.module.scss';
import svgFormBuilding from "../../../../assets/v2/icons/form-building.svg";
import svgSmartInsights from "../../../../assets/v2/icons/smart-insights.svg";
import svgSmartIntegration from "../../../../assets/v2/icons/smart-integration.svg";

export default function Carousal() {
  const data = [
    {
      image: svgFormBuilding,
      title: "Create apps with ease",
      content: "Design personalized and engaging apps to capture valuable feedback from your customers and external stakeholders. Easily consolidate data and information effectively and efficiently, all in one place."
    },
    {
      image: svgSmartIntegration,
      title: "Seamless integration",
      content: "With seamless integration to your existing platforms, creating apps has never been easier. Gain access to your form data at any place and time through your personalized Smart apps profile."
    },
    {
      image: svgSmartInsights,
      title: "Gain insight through rich analytics",
      content: "Use your Smart Form data to make informed business decisions and keep your customers feeling heard and engaged."
    }
  ]
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const [autoPlay, setAutoPlay] = useState(true);
  let timeOut: any = null;

  useEffect(() => {
    timeOut = autoPlay && setTimeout(() => {
      setActiveImageIndex(activeImageIndex === data.length - 1 ? 0 : activeImageIndex + 1)
    }, 2500);
  })

  return (
    <>
      <div className={styles.main}
        onMouseEnter={() => {
          setAutoPlay(false);
          clearTimeout(timeOut)
        }}
        onMouseLeave={() => {
          setAutoPlay(true)
        }}
      >
        <div className={styles.wrapper}>
          {
            data.map((item, index) => (
              <div className={index === activeImageIndex ? styles.imgContainerActive : styles.imgContainer} key={index}>
                <img src={item.image} alt="nthn" className={styles.imgs} />
                <span className={styles.contentTitle}>{item.title} <br /></span>
                <span className={styles.content}>{item.content}</span>
              </div>
            ))
          }
        </div>
        <div style={{
          margin: "auto"
        }}>
          {
            data.map((item, index) => (
              <button onClick={() => {
                setActiveImageIndex(index);
                clearTimeout(timeOut)
              }}
                className={`${styles.buttonIndicator} ${index === activeImageIndex && styles.buttonIndicatorActive}`}
                key={index}
              ></button>
            ))
          }
        </div>
      </div>
    </>
  )
}

