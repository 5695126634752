import { FormControl } from "../../store/models/form-controls/form-builder-control";

const MAXROWS = 10;
const MINIMUMVALUE = 1;
const MAXCHARACTERS = 500;
const MINSCORE = 1;
const MAXSCORE = 10;
const MINPERCENT = 0;
const MAXPERCENT = 100;

export function formControlValidator(value: FormControl) {
  if (value.label === null || (value.label != null && (value.label as string).length <= 0)) {
    return {
      property: "label",
      message: "validation_label_err",
      validation: true,
    };
  }
  if (value.controlType === 'checkbox' || value.controlType === 'radio' || value.controlType === 'dropdown') {
    if (value.options.length <= 1) {
      return {
        property: "options",
        message: 'validation_minimum_control_err',
        validation: true,
      };
    } else {
      return { message: null, property: "options", validation: false };
    }
  }
  return { message: null, property: "", validation: false };
}

export function formControlLabelValidator(value: FormControl) {
  if ((value.label === null || (value.label != null && (value.label as string).length <= 0)) && value.controlType !== 'section') {
    return {
      property: "label",
      message: "validation_label_err",
      validation: true,
    };
  }
  return { message: null, property: "", validation: false };
}

export function formControlOptionsValidator(value: FormControl) {
  if (value.controlType === 'checkbox' || value.controlType === 'radio' || value.controlType === 'dropdown') {
    if (value.options.length <= 1) {
      return {
        property: "options",
        message: `validation_minimum_${value.controlType}_control_err`,
        validation: true,
      };
    } else {
      return { message: null, property: "options", validation: false };
    }
  }
}

export function labelValidator(label: string) {
  if (label == null || (label != null && (label as string).length <= 0)) {
    return {
      property: "label",
      message: "validation_label_err",
      validation: true,
    };
  }
  return { message: null, property: "label", validation: false };
}

export function numberValidator(minValue?: string, maxValue?: string) {
  if (minValue != null && maxValue != null && minValue.length > 0 && maxValue.length > 0 && Number(minValue) > Number(maxValue)) {
    return { message: "validation_number_control_err", validation: true };
  }
  return { message: null, validation: false };
}

export function textValidator(maxCharacter?: string) {
  if (maxCharacter != null && maxCharacter.length > 0) {
    if (Number.isInteger(Number(maxCharacter))) {
      if (Number(maxCharacter) < MINIMUMVALUE) {
        return { message: "validation_text_control_minimum_err", validation: true };
      }

      if (Number(maxCharacter) > MAXCHARACTERS) {
        return { message: "validation_text_control_maximum_err", validation: true };
      }
    }
    else {
      return { message: "validation_control_integer_err", label: "maxRows", validation: true }
    }
  }
  return { message: null, validation: false };
}

export function textAreaValidator(key?: string, value?: string) {
  if (key === 'maxRows' && value != null && value.length > 0) {
    if (Number.isInteger(Number(value))) {
      if (Number(value) > MAXROWS) {
        return { message: "validation_text_area_control_maximum_row_err", label: "maxRows", validation: true };
      }

      if (Number(value) < MINIMUMVALUE) {
        return { message: "validation_text_area_control_minimum_row_err", label: "maxRows", validation: true }
      }
    }
    else {
      return { message: "validation_control_integer_err", label: "maxRows", validation: true }
    }
  }
  if (key === "maxCharacter" && value != null && value.length > 0) {
    if (Number.isInteger(Number(value))) {
      if (Number(value) < MINIMUMVALUE) {
        return { message: "validation_text_area_control_minimum_character_err", label: "maxCharacter", validation: true }
      }

      if (Number(value) > 500) {
        return { message: "validation_text_area_control_maximum_character_err", label: "maxCharacter", validation: true }
      }
    } else {
      return { message: "validation_control_integer_err", label: "maxCharacter", validation: true }
    }
  }
  return { message: "", validation: false }
}

export function timeValidator(minTime: string, maxTime: string) {
  const minDateTime = new Date(minTime);
  const maxDateTime = new Date(maxTime);
  if (minTime != null && minTime.length > 0 && maxTime != null && maxTime.length > 0) {
    if (minDateTime >= maxDateTime) {
      return { message: "validation_time_control_minimum_err", validation: true }
    }
  }
  return { message: "", validation: false }
}

export function dateValidator(minDate: string, maxDate: string) {
  if (minDate != null && minDate.length > 0 && maxDate != null && maxDate.length > 0) {
    const startDate = new Date(minDate);
    const endDate = new Date(maxDate);
    if (startDate >= endDate) {
      return { message: "validation_date_control_minimum_err", validation: true }
    }
  }
  return { message: "", validation: false }
}

export function optionsValidator(options: string[]) {
  let tempArr: string[] = [...options];
  if (tempArr.length < 2) {
    return { message: "properties_constants_options_helper", validation: true }
  }
  return { message: "", validation: false }
}

export function fileValidator(key: string, value: string) {
  if (key === "maxFileSize" && value != null && value.length > 0) {
    if (Number.isInteger(Number(value))) {
      if (Number(value) < 1) {
        return { message: "validation_file_control_minimum_size_err", label: "maxFileSize", validation: true }
      }
      if (Number(value) > 10) {
        return { message: "validation_file_control_maximum_size_err", label: "maxFileSize", validation: true }
      }
    }
    else {
      return { message: "validation_control_integer_err", label: "maxFileSize", validation: true }
    }
  }
  if (key === "maxFileCount" && value != null && value.length > 0) {
    if (Number.isInteger(Number(value))) {
      if (Number(value) < 1) {
        return { message: "validation_file_control_minimum_count_err", label: "maxFileCount", validation: true }
      }
      if (Number(value) > 10) {
        return { message: "validation_file_control_maximum_count_err", label: "maxFileCount", validation: true }
      }
    } else {
      return { message: "validation_control_integer_err", label: "maxFileCount", validation: true }
    }
  }
  return { message: "", validation: false }
}

export function raffleModeValidator(config: Record<string, any>) {
  if (config.userInfo && config.userInfo.length === 0) {
    return { message: "raffle_control_participants_err", label: "raffleParticipants", validation: true }
  }
  return { message: "", validation: false }
}

export function raffleModeBlackListValidator(blackListInput: string) {
  if (blackListInput.trim().length <= 0) return;
  const emails = blackListInput.split(',');
  for (let email of emails) {
    const re = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    email = email.trim();
    if (email.length > 0 && !re.test(email)) {
      return { message: "raffle_control_blacklist_err", label: "raffleBlackListEmails", validation: true }
    }
  };
}
export function quizModeValidator(control: FormControl, key: string, value: string) {

  // To validate whether the passing percent is greater than 0 and lesser than or equal to 100
  if (key === 'passingPercent') {
    if (Number(value) <= MINPERCENT) {
      return { message: "quiz_control_min_passing_percentage_err", label: "passingPercent", validation: true }
    }
    if (Number(value) > MAXPERCENT) {
      return { message: "quiz_control_max_passing_percentage_err", label: "passingPercent", validation: true }
    }
  }

  if (key === 'userInfo' && value.length === 0) {
    return { message: "quiz_control_participants_err", label: "userInfo", validation: true }
  }

  // To validate whether the score is greater than 0 and lesser than or equal to 10. Along with that checking if the score has only two decimal places at the max
  if (key === 'score') {
    if (value && Number(value) < MINSCORE) {
      return { message: "form_control_min_score_err", label: "score", validation: true }
    }

    if (value && Number(value) > MAXSCORE) {
      return { message: "form_control_max_score_err", label: "score", validation: true }
    }

    if (value && value.split('.').length > 1 && value.split('.')[1].length > 1) {
      return { message: "form_control_max_score_decimal_err", label: "score", validation: true }
    }
  }

  // To validate whether the correct answer(s) array has options provided in the options for multi choice control
  if (key === 'correctAnswer' && control.config && control.config.quiz && control.config.quiz.score && (control.config.quiz.correctAnswer && control.config.quiz.correctAnswer.length > 0)) {
    if (control.controlType === 'radio' || control.controlType === 'dropdown') {
      if (!control.options.some(item => item.trim() === value[0].trim())) {
        return { message: "form_control_correct_answer_err", label: "correctAnswer", validation: true }
      }
    }
    if (control.controlType === 'checkbox') {
      if (control.options.length > 0 && control.options.length > 0 && value.length === control.options.length) {
        return { message: "form_control_correct_answer_lenght_err", label: "correctAnswer", validation: true }
      }

      if (value.length > 0 && control.options.length > 0) {
        const correctAnswerArr: Array<string> = control?.config.quiz?.correctAnswer;
        const contains = correctAnswerArr.every(element => {
          return control.options.some(item => item.trim() === element.trim())
        });

        if (!contains) {
          return { message: "form_control_correct_answer_err", label: "correctAnswer", validation: true }
        }
      }
    }
  }

  // To validate and check if the score is provided when the correct answer is provided and vice versa
  if (control.config && control.config.quiz) {
    if (control.config.quiz.score && (!control.config.quiz.correctAnswer || (control.config.quiz.correctAnswer && (control.config.quiz.correctAnswer.length <= 0)))) {
      return { message: "form_control_correct_answer_empty_err", label: "correctAnswer", validation: true }
    }

    if (!control.config.quiz.score && control.config.quiz.correctAnswer && control.config.quiz.correctAnswer.length > 0) {
      return { message: "form_control_score_empty_err", label: "score", validation: true }
    }

  }

  return { message: "", validation: false }

}

export function quizModeControlPositionValidator(isFirst: boolean) {
  if (!isFirst) {
    return { message: "quiz_control_position_err", label: "quizControl", validation: true }
  }
  return { message: "", validation: false }

}
