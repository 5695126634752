import styles from './settings-panel.module.scss';
import CustomButton from '../../common/custom-button/custom-button';
import { useEffect, useState } from 'react';
import { SettingsV2Props } from './settings-panel.props';
import { FONT_SIZE, FONT_WEIGHT, FORM_THEMES, TIMER_MINS } from './form-constants';
import { UpdateFormDto } from '../../../../store/models/form.interface';
import { RootDispatch } from '../../../../store/store';
import { useDispatch } from 'react-redux';
import { updateFormThunk } from '../../../../store/thunks/forms.thunk';
import { FormProperties } from './form-properties/form-properties';
import { GlobalSettings } from './global-settings/global-settings';
import { HeaderSettings } from './header-settings/header-settings';
import { TimerSettings } from './timer-settings/timer-settings';
import { toast } from 'react-hot-toast';
import { ActionType, FormResponseAction } from '../../../../store/models/form-response.interface';
import { addFormResponseAction, getFormResponseAction, deleteFormResponseAction, updateFormResponseAction } from '../../../../api/forms';
import { useParams } from 'react-router-dom';
import { IntegrationSettings } from './integration-settings/integration-settings';
import hasSufficientScopes from '../../../../util/access-validator/access-validator';
import { useTranslation } from 'react-i18next';
import { TranslationSettings } from './translation-settings/translation-settings';
import PostSubmissionMessageSettings from './post-submission-message-settings/post-submission-message-settings';
import { AuthenticationSettings } from './authenticated-form/authenticated-form';

export function SettingsPanel(props: SettingsV2Props) {
  const dispatch: RootDispatch = useDispatch();
  const { t } = useTranslation();
  const { formId } = useParams<string>() as { formId: string };
  const [updatePayload, setUploadPayload] = useState<UpdateFormDto>({
    config: props.form.config === null ? undefined : props.form.config,
    createdAt: props.form.createdAt,
    createdBy: props.form.createdBy,
    description: props.form.description,
    expiryResponseCount: props.form.expiryResponseCount != null ? props.form.expiryResponseCount : null,
    expiryTime: props.form.expiryTime,
    isPublic: props.form.isPublic,
    name: props.form.name,
    state: props.form.state,
    timerConfig: props.form.timerConfig === null ? undefined : props.form.timerConfig,
    url: props.form.url,
    tags: props.form.tags,
    integrationIds: props.form.integrationIds
  });
  const [formResponseAction, setFormResponseAction] = useState<FormResponseAction[]>();
  const [isEmailNotificationOpted, setIsEmailNotificationOpted] = useState(false);
  const [isPostSubmissionMessageOpted, setIsPostSubmissionMessageOpted] = useState(false);
  const [postSubmissionMessageConfig, setPostSubmissionMessageConfig] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Map<string, string>>(new Map());
  const [emails, setEmails] = useState<string[]>([]);
  const [isValuesDirty, setIsValuesDirty] = useState(false);
  const [timerSelected, setTimerSelected] = useState(false);
  const [translationSelected, setTranslationSelected] = useState(false);
  const [hasScope, setHasScope] = useState(false);
  const [isPostSubmissionMessageAccordion, setIsPostSubmissionMessageAccordion] = useState(false);
  const [isResponseActionEnabled, setIsResponseActionEnabled] = useState<Record<string, boolean>>({
    "EMAIL": false,
    "POST_SUBMISSION_MESSAGE": false
  });

  useEffect(() => {
    fetchFormResponseAction();
    props.clearSettingsState.current = clearSettingsState;
    const checkScope = hasSufficientScopes(['forms:update', 'forms:update.all']);
    setHasScope(checkScope);
  }, []);

  useEffect(() => {
    props.clearSettingsState.current = clearSettingsState;
  }, [props.form]);

  const clearSettingsState = () => {
    setUploadPayload({
      config: props.form.config === null ? undefined : props.form.config,
      createdAt: props.form.createdAt,
      createdBy: props.form.createdBy,
      description: props.form.description,
      expiryResponseCount: props.form.expiryResponseCount != null ? props.form.expiryResponseCount : null,
      expiryTime: props.form.expiryTime,
      isPublic: props.form.isPublic,
      name: props.form.name,
      state: props.form.state,
      timerConfig: props.form.timerConfig === null ? undefined : props.form.timerConfig,
      url: props.form.url,
      tags: props.form.tags,
      integrationIds: props.form.integrationIds
    });
    setIsValuesDirty(false);
    fetchFormResponseAction();
    setErrors(new Map());
  }

  useEffect(() => {
    setUploadPayload({
      config: props.form.config === null ? undefined : props.form.config,
      createdAt: props.form.createdAt,
      createdBy: props.form.createdBy,
      description: props.form.description,
      expiryResponseCount: props.form.expiryResponseCount != null ? props.form.expiryResponseCount : null,
      expiryTime: props.form.expiryTime,
      isPublic: props.form.isPublic,
      name: props.form.name,
      state: props.form.state,
      timerConfig: props.form.timerConfig === null ? undefined : props.form.timerConfig,
      url: props.form.url,
      tags: props.form.tags,
      integrationIds: props.form.integrationIds
    });
  }, [props.form]);

  useEffect(() => {
    setIsResponseActionEnabled({
      ...isResponseActionEnabled,
      "EMAIL": isEmailNotificationOpted,
      "POST_SUBMISSION_MESSAGE": isPostSubmissionMessageOpted,
    });
  }, [isEmailNotificationOpted, isPostSubmissionMessageOpted])


  const fetchFormResponseAction = async () => {
    try {
      const res = (await getFormResponseAction({ formId: formId })).data;
      if (res.formResponseAction) {
        setFormResponseAction(res.formResponseAction);
        res.formResponseAction.forEach(item => {
          switch (item.actionType) {
            case "EMAIL":
              setEmails(item.config.to);
              setIsEmailNotificationOpted(true);
              break;
            case "POST_SUBMISSION_MESSAGE":
              setPostSubmissionMessageConfig({
                url:item.config.url,
                username:item.config.username,
                password:item.config.password
              });
              setIsPostSubmissionMessageOpted(true);
              break;
          }
        })
      } else {
        setIsEmailNotificationOpted(false);
        setIsPostSubmissionMessageOpted(false);
        setFormResponseAction(undefined);
        setEmails([]);
      }
    } catch (err) { }
  }

  const createFormResponseAction = async (actionType: string) => {
    if (formId) {
      let config = {};
      switch (actionType) {
        case 'EMAIL':
          config = {
            to: emails
          }
          break;
        case 'POST_SUBMISSION_MESSAGE':
          config = {
            url: postSubmissionMessageConfig.url,
            username:postSubmissionMessageConfig.username,
            password:postSubmissionMessageConfig.password
          }
          break;
      }

      let payload = {
        formId,
        actionType: actionType,
        order: 1,
        config: config
      };
      const res = (await addFormResponseAction(payload)).data;
      const temp = formResponseAction;
      temp?.push(res);
      setFormResponseAction(temp);
    }
  }

  const clearFormResponseAction = async (item: FormResponseAction) => {
    await deleteFormResponseAction(item.id);
    switch (item.actionType) {
      case 'EMAIL':
        setEmails([]);
        break;
      case 'POST_SUBMISSION_MESSAGE':
        setPostSubmissionMessageConfig({})
        break;
    }
    const temp = formResponseAction?.filter(res => res.id !== item.id);
    setFormResponseAction(temp);
  }

  const updateFormResponseActions = async (data: FormResponseAction) => {

    let config = {};
    switch (data.actionType) {
      case 'EMAIL':
        config = {
          to: emails
        }
        break;
      case 'POST_SUBMISSION_MESSAGE':
        config = {
          url: postSubmissionMessageConfig.url,
            username:postSubmissionMessageConfig.username,
            password:postSubmissionMessageConfig.password
        }
        break;
    }
    await updateFormResponseAction(data.id, { config: config });
    const updatedAction = formResponseAction?.map(item => {
      if (item.id === data.id) {
        item.config = config;
      }
      return item;
    })
    setFormResponseAction(updatedAction);
  }

  const updateForm = async () => {
    let payload = {
      ...updatePayload,
      name: updatePayload.name?.trim()
    };

    try {
      //// form actions
      for (const key in isResponseActionEnabled) {
        //check existenc in rsArr
        const isActionExist = formResponseAction?.find(item => item.actionType === key)
        //create
        if (isResponseActionEnabled[key] && !isActionExist) {
          await createFormResponseAction(key);
        }
        //update
        if (isResponseActionEnabled[key] && isActionExist) {
          try {
            await updateFormResponseActions(isActionExist);
          } catch (error) {
            toast.error(t("eml_update_fail"));
          }
        }
        //delete
        if (!isResponseActionEnabled[key] && isActionExist) {
          try {
            await clearFormResponseAction(isActionExist);
          } catch (err) {
            toast.error(t("eml_update_fail"));
          }
        }
      }
      setIsValuesDirty(false);
      await dispatch(updateFormThunk({ formId: props.form.id, data: payload })).unwrap();
      toast.success(t("form_setting_updated"));
    } catch (err) {
      toast.error(t("form_setting_update_fail"));
    }
  }

  return (
    <div className={styles.settingsContainer}>

      <div className={`${styles.settingsContainer} ${isPostSubmissionMessageAccordion && styles.disappear}`}>
      {/* form properties */}
      <FormProperties isActionPanelOpen={props.isActionPanelOpen} errors={errors} setErrors={setErrors} setUploadPayload={setUploadPayload} updatePayload={updatePayload} setIsValuesDirty={setIsValuesDirty} />

      {/* global settings */}
      <GlobalSettings errors={errors} setErrors={setErrors} setUploadPayload={setUploadPayload} updatePayload={updatePayload} themes={FORM_THEMES} setIsValuesDirty={setIsValuesDirty} formResponseAction={formResponseAction} setFormResponseAction={setFormResponseAction} isEmailNotificationOpted={isEmailNotificationOpted} setIsEmailNotificationOpted={setIsEmailNotificationOpted} emails={emails} setEmails={setEmails} isActionPanelOpen={props.isActionPanelOpen} />

      {/* Header settings */}
      <HeaderSettings errors={errors} setErrors={setErrors} setUploadPayload={setUploadPayload} updatePayload={updatePayload} fontSize={FONT_SIZE} fontWeight={FONT_WEIGHT} setIsValuesDirty={setIsValuesDirty} isActionPanelOpen={props.isActionPanelOpen} />

      {/* Timer settings */}
      <TimerSettings errors={errors} setErrors={setErrors} setUploadPayload={setUploadPayload} updatePayload={updatePayload} minutes={TIMER_MINS} setIsValuesDirty={setIsValuesDirty} isActionPanelOpen={props.isActionPanelOpen} setTimerSelected={setTimerSelected} />

      {/* Integrations */}
      <IntegrationSettings errors={errors} isActionPanelOpen={props.isActionPanelOpen} setUploadPayload={setUploadPayload} updatePayload={updatePayload} setIsValuesDirty={setIsValuesDirty} formId={formId} />

      {/* Translation settings */}
      <TranslationSettings errors={errors} setErrors={setErrors} setUploadPayload={setUploadPayload} updatePayload={updatePayload} setIsValuesDirty={setIsValuesDirty} isActionPanelOpen={props.isActionPanelOpen} setTranslationSelected={setTranslationSelected} />

      {/* Authentication settings */}
      <AuthenticationSettings errors={errors} setErrors={setErrors} setUploadPayload={setUploadPayload} updatePayload={updatePayload} setIsValuesDirty={setIsValuesDirty} isActionPanelOpen={props.isActionPanelOpen} />
      </div>


      {/* <PostSubmissionMessageSettings setPostSubmissionMessageAccordion={setIsPostSubmissionMessageAccordion} isActionPanelOpen={props.isActionPanelOpen} isPostSubmissionMessageAccordion={isPostSubmissionMessageAccordion} setIsPostSubmissionMessageAccordion={setIsPostSubmissionMessageAccordion} PostSubmissionMessageConfig={postSubmissionMessageConfig} setPostSubmissionMessageConfig={setPostSubmissionMessageConfig} errors={errors} isPostSubmissionMessageOpted={isPostSubmissionMessageOpted} setErrors={setErrors} setIsPostSubmissionMessageOpted={setIsPostSubmissionMessageOpted} setIsValuesDirty={setIsValuesDirty} /> */}


      <div className={styles.saveBtnContainer}>
        {/* <CustomButton buttonType='reset' classType='secondary' onClick={clearForm} style={{ width: "25%" }}>
          <div>Clear</div>
        </CustomButton> */}
        <CustomButton
          disabled={(!isValuesDirty || errors.size !== 0 || !timerSelected || !hasScope) || (isEmailNotificationOpted && emails.length <= 0)}
          buttonType='submit' classType='quaternary' style={{ width: "25%" }}
          onClick={() => {
            updateForm();
            props.setIsActionsPanelOpen(false);
          }}
        >
          <span style={{ color: '#fff' }}>{t("save")}</span>
        </CustomButton>
      </div>
    </div>
  )
}
