import { Dispatch, SetStateAction } from 'react';
import { AddOnSubscriptionPlan } from '../../../../store/models/subscription/plans';
import CustomText from '../../common/custom-bodyText/custom-text';
import CustomCheckbox from '../../common/custom-checkbox/custom-checkbox';
import CustomHeading from '../../common/custom-heading/custom-heading';
import styles from './subs-add-ons.module.scss';
import svgInfo from "../../../../assets/v2/icons/info.svg";
import { useTranslation } from 'react-i18next';

export interface SubsAddOnsProps {
  plans: (AddOnSubscriptionPlan & { isSelected: boolean })[];
  setAddOnPlans: Dispatch<SetStateAction<(AddOnSubscriptionPlan & {
    isSelected: boolean;
  })[]>>;
  isFreeze: boolean;
}

export function SubsAddOns(props: SubsAddOnsProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className={`${styles.container} ${props.isFreeze ? styles.containerDisabled : ''}`}>
        <div className={styles.titleContainer}>
          <div style={{ "display": "flex", "gap": "0.25rem" }}>
            <CustomHeading headerType='h3'>{t("add_ons")}</CustomHeading>
            <div className={styles.bottomCheckToolTip} tool-tip={`Changes will be reflected immediately. Usage charge will be updated at the end of billing cycle.`} style={{ position: 'relative', cursor: 'pointer' }} >
              <img className={styles.toolTipImg} src={svgInfo} alt="" />
            </div>
          </div>
          <CustomText textType='t5'>{t("spcl_feature")}</CustomText>
        </div>
        <div className={styles.flexContainer}>
          <div className={`tableWrapper tableNoShadow ${styles.showTable}`}>
            <>
              <div className={'tableHeaderRow'} style={{ backgroundColor: 'transparent' }}>
                <div className={'tableData'}>{t("feature_heading")}</div>
                <div className={'tableData'}>{t("price")}</div>
                <div className={'tableData'}>{t("choose")}</div>
              </div>
              {
                props.plans?.map((plan, index) => {
                  return (
                    <div className={'tableRow'} key={index}>
                      <div className={'tableData'}>{plan.displayName}</div>
                      <div className={'tableData'}>${plan.planMetaData.baseAmount / 100}</div>
                      <div className={'tableData'}>
                        <div style={{ width: 'fit-content' }}>
                          <CustomCheckbox
                            data={plan.id} isInitialChecked={plan.isSelected} onCheckChange={(data) => {
                              props.setAddOnPlans((prevAddOnPlans) => prevAddOnPlans.map(prevPlan => {
                                const val = { ...prevPlan };
                                if (prevPlan.id === plan.id) val.isSelected = data.isChecked;
                                return val;
                              }))
                            }} />
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </>
          </div>
        </div>
      </div>
    </>
  )
}
