import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './google-sheets-integrations.module.scss';
import CustomHeading from '../../../../../../components/v2/common/custom-heading/custom-heading';
import CustomInput from '../../../../../../components/v2/common/custom-input/custom-input';
import { useTranslation } from 'react-i18next';
import { MongoDBProperties, GoogleSheetProperties } from '../add-integration';

export default function GoogleSheetsIntegrations(props: GoogleSheetsIntegrationsProps) {
  const { t } = useTranslation();
  const [codeClient, setCodeClient] = useState<google.accounts.oauth2.CodeClient>();
  const [authEnable, setAuthEnable] = useState(false);

  useEffect(() => {
    if (props.integrationsType === 'EDIT') {
      setAuthEnable(true);
    }
    const codeClient = google.accounts.oauth2.initCodeClient({
      client_id: `${process.env.REACT_APP_OAUTH2_CLIENT_ID}`,
      redirect_uri: `${process.env.REACT_APP_MANAGER_SVC}`,
      scope: "https://www.googleapis.com/auth/spreadsheets",
      select_account: true,
      callback: (res: google.accounts.oauth2.CodeResponse) => {
        props.setConnectionProps({
          ...props.connectionProps,
          token: res.code,
        })
        setAuthEnable(true);
      },
    });
    setCodeClient(codeClient);
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <div className={styles.enableConatiner} style={{
        opacity: `${props.integrationsType === 'EDIT' ? '0.5' : '1'}`,
        pointerEvents: `${props.integrationsType === 'EDIT' ? 'none' : 'auto'}`
      }}>
        <CustomHeading headerType='h5'>
          <div className={styles.textColor}>{t("enable_google_integration")}&nbsp;<span style={{ color: 'red' }}>*</span></div>
        </CustomHeading>
        <label className={styles.switch} >
          <input
            type={"checkbox"}
            checked={authEnable}
            onChange={(e) => {
              if (authEnable) {
                setAuthEnable(false);
              } else {
                codeClient?.requestCode();
              }
              //@TODO fix this validation issue in next PR
              // if (props.isDirtyFlags.token === true) {
              //   if (!authEnable) {
              //     props.setErrors((prev) => { return { ...prev, token: 'This is required' } });
              //   } else {
              //     console.log("here");
              //     props.setErrors((prev) => { return { ...prev, token: undefined } });
              //   }
              // }
            }}
            onBlur={() => {
              //@TODO fix this validation issue in next PR
              // props.setIsDirtyFlags((prev) => { return { ...prev, token: true } });
              // if (!authEnable) {
              //   props.setErrors((prev) => { return { ...prev, toke: 'This is required' } });
              // } else {
              //   console.log("here");
              //   props.setErrors((prev) => { return { ...prev, token: undefined } });
              // }
            }}
          />
          <span className={styles.slider}></span>
        </label>
      </div>
      {
        props.errors && props.errors.token &&
        <div className={styles.errorText}>{t("this_required")}</div>
      }
      {
        authEnable &&
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem"
        }}>
          <CustomHeading headerType='h5'>
            <div className={styles.textColor}>{t("sheet_name")}&nbsp;<span style={{ color: 'red' }}>*</span></div>
          </CustomHeading>
          <CustomInput
            inputStyleType={'primary'}
            inputType={'text'}
            placeHolder={t("give_sheet_name")}
            value={(props.connectionProps as GoogleSheetProperties).sheetName}
            onChange={(e) => {
              props.setConnectionProps({
                ...props.connectionProps,
                sheetName: e.target.value
              })
              if (props.isDirtyFlags.sheetName === true) {
                if (e.target.value.trim().length < 1) {
                  props.setIsError(true);
                  props.setErrors((prev) => { return { ...prev, sheetName: 'This is required' } });
                } else {
                  props.setIsError(false);
                  props.setErrors((prev) => { return { ...prev, sheetName: undefined } });
                }
              }
            }}
            onBlur={(e) => {
              props.setIsDirtyFlags((prev) => { return { ...prev, sheetName: true } });
              if (e.target.value.trim().length < 1) {
                props.setErrors((prev) => { return { ...prev, sheetName: `${t("this_required")}` } });
              } else {
                props.setErrors((prev) => { return { ...prev, sheetName: undefined } });
              }
            }}
          />
          {
            props.errors && props.errors.sheetName &&
            <div className={styles.errorText}>{t("this_required")}</div>
          }
        </div>
      }
    </div>
  )
}

interface GoogleSheetsIntegrationsProps {
  connectionProps: MongoDBProperties | GoogleSheetProperties;
  setConnectionProps: Dispatch<SetStateAction<MongoDBProperties | GoogleSheetProperties>>;
  setErrors: (value: SetStateAction<{
    host?: string | undefined;
    dbName?: string | undefined;
    collectionName?: string | undefined;
    userName?: string | undefined;
    integrationType?: string | undefined;
    integrationName?: string | undefined;
    sheetName?: string | undefined;
    token?: string | undefined;
  }>) => void;
  errors: {
    host?: string | undefined;
    dbName?: string | undefined;
    collectionName?: string | undefined;
    userName?: string | undefined;
    integrationType?: string | undefined;
    integrationName?: string | undefined;
    sheetName?: string | undefined;
    token?: string | undefined;
  };
  isDirtyFlags: {
    host: boolean;
    dbName: boolean;
    collectionName: boolean;
    userName: boolean;
    integrationType: boolean;
    integrationName: boolean;
    sheetName: boolean;
    token: boolean;
  }
  setIsDirtyFlags: (value: SetStateAction<{
    host: boolean;
    dbName: boolean;
    collectionName: boolean;
    userName: boolean;
    integrationType: boolean;
    integrationName: boolean;
    sheetName: boolean;
    token: boolean;
  }>) => void;
  integrationsType: "CREATE" | "EDIT";
  setIsError: Dispatch<SetStateAction<boolean>>
}


