import { useDispatch, useSelector } from 'react-redux';
import { addMessage, removeControl } from '../../store/slices/validation.slice';
import { RootDispatch, RootState } from '../../store/store';
import { numberValidator, textValidator, textAreaValidator, timeValidator, dateValidator, optionsValidator, fileValidator, labelValidator, formControlLabelValidator, formControlOptionsValidator, raffleModeValidator, quizModeValidator, quizModeControlPositionValidator, raffleModeBlackListValidator } from './validator';
import { FormControl } from '../../store/models/form-controls/form-builder-control';
import { useTranslation } from 'react-i18next';
import { SubscriptionPlanCode } from '../../store/models/subscription/plan-name.enum';

export default function UseValidation() {
  const { t } = useTranslation();
  const dispatch: RootDispatch = useDispatch();
  const controlErrorMessage = useSelector((state: RootState) => { return state.validation.controlValidation });
  const plans = useSelector((state: RootState) => state.subscription.data.plans);

  function formControlValidation(formControls: FormControl[], quizScore?: number) {
    let isError = false;

    const controlLabelValidation = (item: FormControl) => {
      const { message, validation, property } = formControlLabelValidator(item);
      if (validation) {
        const obj = {
          formId: item.formId,
          controlId: item.id,
          property: property,
          message: message,
        };
        isError = true;
        dispatch(addMessage(obj));
      } else {
        const obj = {
          formId: item.formId,
          controlId: item.id,
          key: property,
        };
        resetErrorMessage(obj);
      }
    }

    const controlOptionsValidation = (item: FormControl) => {
      const res = formControlOptionsValidator(item);
      if (res) {
        const { message, validation, property } = res;
        if (validation) {
          const obj = {
            formId: item.formId,
            controlId: item.id,
            property: property,
            message: message,
          };
          isError = true;
          dispatch(addMessage(obj));
        } else {
          const obj = {
            formId: item.formId,
            controlId: item.id,
            key: property,
          };
          resetErrorMessage(obj);
        }
      }
    }

    const controlErrorValidation = (item: FormControl) => {
      if (item.controlType !== 'raffle' && item.controlType !== "quiz") {
        controlOptionsValidation(item);
        controlLabelValidation(item);
      } else if (item.controlType === 'raffle') {
        if (!plans.find(i => i.code.toUpperCase() === SubscriptionPlanCode.RAFFLE)) {
          const obj = {
            formId: item.formId,
            controlId: item.id,
            property: 'raffle',
            message: t("raffle_not_subscribed"),
          };
          isError = true;
          dispatch(addMessage(obj));
        } else {
          const obj = {
            formId: item.formId,
            controlId: item.id,
            key: 'raffle'
          };
          resetErrorMessage(obj);
        }
      }
    }

    const parentControlValidation = (item: FormControl, type: 'page' | 'section') => {
      if (item.children && item.children.length === 0) {
        const obj = {
          formId: item.formId,
          controlId: item.id,
          property: type,
          message: 'Minimum 1 control required',
        };
        isError = true;
        dispatch(addMessage(obj));
      } else {
        const obj = {
          formId: item.formId,
          controlId: item.id,
          key: type
        };
        resetErrorMessage(obj);
      }
    }


    for (const pageControl of formControls) {
      parentControlValidation(pageControl, 'page');
      for (const pageControlChild of pageControl.children) {
        if (pageControlChild.controlType === 'section') parentControlValidation(pageControlChild, 'section');
        // if (pageControlChild.controlType === 'quiz') quizScoreValidation(pageControlChild, 'quiz');
        else controlErrorValidation(pageControlChild);
        for (const sectionControlChild of pageControlChild.children) {
          // if (pageControlChild.controlType === 'quiz') quizScoreValidation(pageControlChild, 'quiz');
          controlErrorValidation(sectionControlChild);
        }
      }
    }

    return isError;
  }

  function quizScoreValidation(item: FormControl, score: number,) {
    if (score <= 0) {
      const obj = {
        formId: item.formId,
        controlId: item.id,
        property: 'totalScore',
        message: 'Total score cannot be less than 0',
      };
      dispatch(addMessage(obj));
      return true;
    } else {
      const obj = {
        formId: item.formId,
        controlId: item.id,
        key: 'quiz'
      };
      resetErrorMessage(obj);
    }
    return false;
  }

  function numberValidation(value: FormControl, isQuiz?: boolean) {
    let errorObject = {};
    if (isQuiz && value.config.quiz) {
      Object.keys(value.config.quiz).forEach(item => {
        const { message, validation, label } = quizModeValidator(value, item, value.config.quiz[item]);
        if (validation) {
          const obj = {
            formId: value.formId,
            controlId: value.id,
            property: label,
            message: message,
          };
          dispatch(addMessage(obj));
          errorObject = {
            ...errorObject,
            [`${label}`]: message
          }
        } else {
          const obj = {
            formId: value.formId,
            controlId: value.id,
            key: item,
          };
          resetErrorMessage(obj);
        }
      });
    }
    const { message, validation } = numberValidator(value.config?.minValue, value.config?.maxValue);
    if (validation) {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        property: 'maxValue',
        message: message,
      };
      dispatch(addMessage(obj));
      errorObject = {
        ...errorObject,
        'maxValue': message
      };
    } else {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        key: 'maxValue',
      };
      resetErrorMessage(obj);
    }
    if (Object.keys(errorObject).length > 0) {
      return { isError: true, errors: errorObject };
    }
    return { isError: false, errors: errorObject };
  }

  function textValidation(value: FormControl, isQuiz?: boolean) {
    let errorObject = {};
    if (isQuiz && value.config.quiz) {
      Object.keys(value.config.quiz).forEach(item => {
        const { message, validation, label } = quizModeValidator(value, item, value.config.quiz[item]);
        if (validation) {
          const obj = {
            formId: value.formId,
            controlId: value.id,
            property: label,
            message: message,
          };
          dispatch(addMessage(obj));
          errorObject = {
            ...errorObject,
            [`${label}`]: message
          }
        } else {
          const obj = {
            formId: value.formId,
            controlId: value.id,
            key: item,
          };
          resetErrorMessage(obj);
        }
      });
    }
    const { message, validation } = textValidator(value.config?.maxCharacter);
    if (validation) {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        property: 'maxCharacter',
        message: message,
      };
      dispatch(addMessage(obj));
      errorObject = {
        ...errorObject,
        'maxCharacter': message
      }
    } else {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        key: 'maxCharacter',
      };
      resetErrorMessage(obj);
    }
    if (Object.keys(errorObject).length > 0) {
      return { isError: true, errors: errorObject };
    }
    return { isError: false, errors: errorObject };
  }

  function textAreaValidation(value: FormControl) {
    let errorObject = {};
    Object.keys(value.config).forEach(item => {
      const { message, validation, label } = textAreaValidator(item, value.config[item]);
      if (validation) {
        const obj = {
          formId: value.formId,
          controlId: value.id,
          property: label,
          message: message,
        };
        dispatch(addMessage(obj));
        errorObject = {
          ...errorObject,
          [`${label}`]: message
        }
      } else {
        const obj = {
          formId: value.formId,
          controlId: value.id,
          key: item,
        };
        resetErrorMessage(obj);
      }
    });
    if (Object.keys(errorObject).length > 0) {
      return { isError: true, errors: errorObject };
    }
    return { isError: false, errors: errorObject };
  }

  function timeValidation(value: FormControl) {
    const { message, validation } = timeValidator(value.config.minTime, value.config.maxTime);
    let errorObject = {};
    if (validation) {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        property: 'maxTime',
        message: message,
      };
      errorObject = {
        ...errorObject,
        'maxTime': message
      }
      dispatch(addMessage(obj));
    } else {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        key: 'maxTime',
      };
      resetErrorMessage(obj);
    }
    if (Object.keys(errorObject).length > 0) {
      return { isError: true, errors: errorObject };
    }
    return { isError: false, errors: errorObject };
  }

  function dateValidation(value: FormControl, isQuiz?: boolean) {
    let errorObject = {}
    if (isQuiz && value.config.quiz) {
      Object.keys(value.config.quiz).forEach(item => {
        const { message, validation, label } = quizModeValidator(value, item, value.config.quiz[item]);
        if (validation) {
          const obj = {
            formId: value.formId,
            controlId: value.id,
            property: label,
            message: message,
          };
          dispatch(addMessage(obj));
          errorObject = {
            ...errorObject,
            [`${label}`]: message
          }
        } else {
          const obj = {
            formId: value.formId,
            controlId: value.id,
            key: item,
          };
          resetErrorMessage(obj);
        }
      });
    }
    const { message, validation } = dateValidator(value.config.minDate, value.config.maxDate);
    if (validation) {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        property: 'maxDate',
        message: message,
      };
      errorObject = {
        ...errorObject,
        'maxDate': message
      }
      dispatch(addMessage(obj));
    } else {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        key: 'maxDate',
      };
      resetErrorMessage(obj);
    }
    if (Object.keys(errorObject).length > 0) {
      return { isError: true, errors: errorObject };
    }
    return { isError: false, errors: errorObject };
  }

  function optionsValidation(value: FormControl, isQuiz?: boolean) {
    let errorObject = {}
    if (isQuiz && value.config.quiz) {
      Object.keys(value.config.quiz).forEach(item => {
        const { message, validation, label } = quizModeValidator(value, item, value.config.quiz[item]);
        if (validation) {
          const obj = {
            formId: value.formId,
            controlId: value.id,
            property: label,
            message: message,
          };
          dispatch(addMessage(obj));
          errorObject = {
            ...errorObject,
            [`${label}`]: message
          }
        } else {
          const obj = {
            formId: value.formId,
            controlId: value.id,
            key: item,
          };
          resetErrorMessage(obj);
        }
      });
    }
    const { message, validation } = optionsValidator(value.options);
    if (validation) {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        property: 'options',
        message: message,
      };
      errorObject = {
        ...errorObject,
        [`${'options'}`]: message
      }
      dispatch(addMessage(obj));
    } else {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        key: 'options',
      };
      resetErrorMessage(obj);
    }
    if (Object.keys(errorObject).length > 0) {
      return { isError: true, errors: errorObject };
    }
    return { isError: false, errors: errorObject };
  }

  function fileValidation(value: FormControl) {
    let errorObject = {}
    Object.keys(value.config).forEach(item => {
      const { message, validation, label } = fileValidator(item, value.config[item])
      if (validation) {
        const obj = {
          formId: value.formId,
          controlId: value.id,
          property: label,
          message: message,
        };
        errorObject = {
          ...errorObject,
          [`${label}`]: message
        }
        dispatch(addMessage(obj));
      } else {
        const obj = {
          formId: value.formId,
          controlId: value.id,
          key: item,
        };
        resetErrorMessage(obj);
      }
    });
    if (Object.keys(errorObject).length > 0) {
      return { isError: true, errors: errorObject };
    }
    return { isError: false, errors: errorObject };
  }

  function labelValidation(value: FormControl) {
    const { message, validation } = labelValidator(value.label);
    let errorObject = {};
    if (validation) {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        property: 'label',
        message: message,
      };
      errorObject = {
        ...errorObject,
        [`${'label'}`]: message
      }
      dispatch(addMessage(obj));
    } else {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        key: 'label',
      };
      resetErrorMessage(obj);
    }
    if (Object.keys(errorObject).length > 0) {
      return { isError: true, errors: errorObject };
    }
    return { isError: false, errors: errorObject };
  }

  function resetErrorMessage(obj: resetErrorDto) {
    if (controlErrorMessage != null && controlErrorMessage[obj.formId] != null && controlErrorMessage[obj.formId][obj.controlId] != null) {
      dispatch(removeControl(obj));
    }
  }

  const raffleModeValidation = (value: FormControl) => {
    const { message, validation } = raffleModeValidator(value.config);
    let errorObject = {};
    if (validation) {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        property: 'raffleParticipants',
        message: message,
      };
      dispatch(addMessage(obj));
      errorObject = {
        ...errorObject,
        'raffleParticipants': message
      }
    } else {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        key: 'raffleParticipants',
      };
      resetErrorMessage(obj);
    }
    if (Object.keys(errorObject).length > 0) {
      return { isError: true, errors: errorObject };
    }
    return { isError: false, errors: errorObject };
  }

  const raffleModeBlackListValidation = (value: FormControl, blackListInput: string) => {
    const res = raffleModeBlackListValidator(blackListInput);
    let errorObject = {};
    if (res) {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        property: res.label,
        message: res.message,
      };
      dispatch(addMessage(obj));
      errorObject = {
        ...errorObject,
        [res.label]: res.message
      }
    } else {
      const obj = {
        formId: value.formId,
        controlId: value.id,
        key: 'raffleBlackListEmails',
      };
      resetErrorMessage(obj);
    }
    if (Object.keys(errorObject).length > 0) {
      return { isError: true, errors: errorObject };
    }
    return { isError: false, errors: errorObject };
  }
  const quizModeValidation = (formControl: FormControl) => {
    let errorObject = {};
    Object.keys(formControl.config).forEach(item => {
      const { message, validation, label } = quizModeValidator(formControl, item, formControl.config[item]);
      if (validation) {
        const obj = {
          formId: formControl.formId,
          controlId: formControl.id,
          property: label,
          message: message,
        };
        dispatch(addMessage(obj));
        errorObject = {
          ...errorObject,
          [`${label}`]: message
        }
      } else {
        const obj = {
          formId: formControl.formId,
          controlId: formControl.id,
          key: item,
        };
        resetErrorMessage(obj);
      }
    });
    if (Object.keys(errorObject).length > 0) {
      return { isError: true, errors: errorObject };
    }
    return { isError: false, errors: errorObject };
  }

  const quizModeControlPositionValidation = (formControl: FormControl, isFirst: boolean) => {
    const { message, validation } = quizModeControlPositionValidator(isFirst);
    let errorObject = {};
    if (validation) {
      const obj = {
        formId: formControl.formId,
        controlId: formControl.id,
        property: "quizControl",
        message: message,
      };
      errorObject = {
        ...errorObject,
        "quizControl": message
      }
      dispatch(addMessage(obj));
    } else {
      const obj = {
        formId: formControl.formId,
        controlId: formControl.id,
        key: "quizControl",
      };
      resetErrorMessage(obj);
    }
    if (Object.keys(errorObject).length > 0) {
      return { isError: true, errors: errorObject };
    }
    return { isError: false, errors: errorObject };
  }


  return { numberValidation, textValidation, textAreaValidation, timeValidation, dateValidation, optionsValidation, fileValidation, formControlValidation, labelValidation, raffleModeValidation, quizModeValidation, quizModeControlPositionValidation, raffleModeBlackListValidation, quizScoreValidation };
}


interface resetErrorDto {
  formId: string;
  controlId: string;
  key: string;
}

export interface errorObjects {
  [label: string]: string;
}

export interface errorObject {
  isError: boolean;
  value: string;
}
