import i18next from "i18next";
import { initReactI18next } from 'react-i18next';
import en from '../locales/en/en.json';
import ta from '../locales/ta/ta.json';
import es from '../locales/es/es.json';

export function initI18N() {
  i18next.use(initReactI18next).init(
    {
      lng: 'en',
      interpolation: {
        escapeValue: false
      },
      resources: {
        en,
        ta,
        es
      },
    });
}
