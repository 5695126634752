export function colorGenerator(length: number, colors: (t: number) => string) {
  let colorRange = 0.9 - 0.15;
  let intervalSize = colorRange / length;
  let i, colorPoint;
  let colorArray = [];

  for (i = 0; i < length; i++) {
    colorPoint = (0.9 - (i * intervalSize));
    colorArray.push(colors(colorPoint));
  }
  return colorArray;
}
