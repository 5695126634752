import { ProtectedRoutesProps } from './protected.props'
import hasSufficientScopes from '../access-validator/access-validator'
import Forbidden from '../../pages/forbidden-pages/forbidden'

export default function Protected(props: ProtectedRoutesProps) {
  return (
    <>
      {
        hasSufficientScopes(props.scopes) ?
          props.element :
          <Forbidden />
      }
    </>
  )
}
