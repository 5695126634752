import { Fragment, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import styles from './users-tab.module.scss';
import CustomButton from '../../../../../components/v2/common/custom-button/custom-button';
import { RootDispatch, RootState } from '../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { getOrgUsersThunk, removeUserFromOrganizationThunk } from '../../../../../store/thunks/organization.thunk';
import AddUser from '../add-user/add-user';
import EditUser from '../edit-user/edit-user';
import hasSufficientScopes from '../../../../../util/access-validator/access-validator';
import NoContent from '../../../../../components/v2/common/no-content/no-content';
import Modal from '../../../../../components/v2/common/modal/modal';
import { ActionsPanel } from '../../../../../components/v2/form-builder';
import CustomLoader from '../../../../../components/v2/common/custom-loader/custom-loader';
import { GetOrgUserRespDto } from '../../../../../store/models/organization.interface';
import CustomHeading from '../../../../../components/v2/common/custom-heading/custom-heading';
import { getRolesThunk } from '../../../../../store/thunks/roles.thunk';
import { SocketContext } from '../../../../../context/socket';
import { ACTIVE_PAGE_STATUS } from '../../../../../store/models/active-page';
import { activePageThunk } from '../../../../../store/thunks/active-page.thunk';
import { setNewData } from '../../../../../store/slices/active-page.slice';
import svgDelete from "../../../../../assets/v2/delete.svg";
import svgEdit from "../../../../../assets/v2/edit-2.svg";
import svgLoading from "../../../../../assets/v2/icons/loading.svg";
import svgRefresh from "../../../../../assets/v2/icons/refresh.svg";
import TextComponent from '../../../../../components/v2/common/text-component/text-component';
import ToolTip from '../../../../../components/v2/common/tool-tip/tool-tip';
import { fetchSubscriptionThunk } from '../../../../../store/thunks/subscription.thunk';
import SecondaryTopBar from '../../../../../components/v2/common/secondary-top-bar/secondary-top-bar';
import SettingRefreshComponent from '../../setting-refresh-component';
import { UserMetadataKey } from '../../../../../store/models/user-metadata-key.enum';

export default function UsersTab() {
  const dispatch: RootDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const users = useSelector((state: RootState) => state.organization.orgUsers);
  const [selectedUser, setSelectedUser] = useState<GetOrgUserRespDto>();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [deleteUserName, setDeleteUserName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isEditActive, setIsEditActive] = useState(false);
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const activePage = useSelector((state: RootState) => state.activePage.activePage);
  const isLatestSubscriptionActive = useSelector((state: RootState) => state.subscription.data.isActive);
  const subscriptionDetails = useSelector((state: RootState) => state.subscription.data);
  const profile = useSelector((state: RootState) => state.user.profileInfo);
  const [isSubscriptionOwner, setIsSubscriptionOwner] = useState(false);

  useEffect(() => {
    dispatch(fetchSubscriptionThunk());
    getLatestData();
  }, [profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]]);

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activePage])

  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    dispatch(activePageThunk({ socket: socket, payload: { pageName: "users", status } }));
  }

  useEffect(() => {
    const checkScope = hasSufficientScopes(["user:read"]);
    if (checkScope && newData) {
      getLatestData();
      dispatch((setNewData({ action: "" })));
    }
  }, [newData])

  useEffect(() => {
    if (!isEditActive) {
      setSelectedUser(undefined);
    }
  }, [isEditActive]);

  const getLatestData = async () => {
    const checkScope = hasSufficientScopes(["user:read"]);
    if (checkScope) {
      setRefreshPage(true);
      await dispatch(getOrgUsersThunk());
      await dispatch(getRolesThunk());
      setIsLoading(false);
      setRefreshPage(false);
    } else {
      setIsLoading(false);
    }
  }

  const deleteUser = async (userId: string) => {
    if (isLatestSubscriptionActive) {
      const checkScope = hasSufficientScopes(["user:delete"]);
      if (checkScope) {
        try {
          await dispatch(removeUserFromOrganizationThunk(userId)).unwrap();
          setDeleteUserName("");
          setShowModal(false);
          toast.success(t("user_deleted"));
        } catch (err: any) {
          if (err.message) {
            toast.error(err.message);
          } else {
            toast.error(t("user_delete_error"));
          }
        }
      } else {
        toast.error(t("settings_user_delete_no_permission"));
      }
    } else {
      toast.error(t('no_active_subscription_error'));
    }
  }


  const disableCheck = (scopes: string[], id: string) => {

    if (profile.userId === id || !isLatestSubscriptionActive) {
      return true;
    }
    return hasSufficientScopes(scopes) ? false : true
  }

  function addEditUserPane() {
    if (isEditActive && selectedUser) {
      return <EditUser setIsSidePaneOpen={setIsEditActive} userDetails={selectedUser} />
    }

    return isEditActive ? <AddUser setIsSidePaneOpen={setIsEditActive} /> : null;
  }

  return (
    <>
      <div className={styles.actionPaneBlock}>
        <ActionsPanel title={`${t("team_members")}`} style={{ top: "5rem", minHeight: "calc(100vh - 6rem)", minWidth: "40rem", maxWidth: "40rem", transform: `${isEditActive ? "translate3d(0, 0, 0)" : "translate3d(40rem, 0, 0)"}` }} isActionsPanelOpen={isEditActive} setIsActionsPanelOpen={setIsEditActive}>
          {addEditUserPane()}
        </ActionsPanel>
      </div>
      <SecondaryTopBar>
        <div>
          <TextComponent textType='Content'>
            <div>{t("user_management_subheading")}</div>
          </TextComponent>
        </div>
        <div className={styles.addBtnContainer}>
          <SettingRefreshComponent
            getLatestData={getLatestData}
            refreshPage={refreshPage}
          />
          <CustomButton
            buttonType='button'
            classType='quaternary'
            style={{
              padding: "5px 2rem",
              letterSpacing: '0.5px',
            }}
            onClick={async () => {
              if (isLatestSubscriptionActive) {
                const checkScope = hasSufficientScopes(['user:create']);
                if (checkScope) {
                  setIsEditActive(true);
                } else {
                  toast.error(t('settings_user_add_no_permission'));
                }
              } else {
                toast.error(t('no_active_subscription_error'));
              }
            }}>
            <TextComponent textType='Content'>
              <div style={{ color: '#fff' }}>{t("add_user")}</div>
            </TextComponent>
          </CustomButton>
        </div>
      </SecondaryTopBar>
      <div className={`${styles.userMainContainer}`}>
        <Modal showModal={showModal} setShowModal={setShowModal}
          header={
            <div>{t("sure_delete_user")}</div>
          }

          content={
            <div style={{ display: "flex", flexDirection: 'column', gap: "1rem" }}>
              {
                isSubscriptionOwner &&
                <>
                <TextComponent textType='Info'>
                  <div style={{maxWidth:'20rem',color:'#000'}}>{t('deleting_subscription_owner_info')}
                    <a href={`./subscription-plans`}>{t('click_here')}</a> {t('deleting_subscription_owner_info_2')}</div>
                </TextComponent>
                </>
              }
              <div style={{ display: "flex", gap: "1rem" }}>
                <CustomButton
                  buttonType='button'
                  classType='secondary'
                  onClick={() => deleteUser(deleteUserName)}
                  disabled={isSubscriptionOwner}
                >
                  <div style={{ color: "#000" }}>{t("yes")}</div>
                </CustomButton>
                <CustomButton
                  buttonType='button'
                  classType='quaternary'
                  onClick={() => { setShowModal(false) }}>
                  <div>{t("no")}</div>
                </CustomButton>
              </div>
            </div>
          }
        />
        <div style={{ position: "relative" }} >
          {
            <div className={`${isLoading ? '' : styles.loaderClose}`}><CustomLoader /></div>
          }
          <div className={`${styles.contentPane} ${isLoading ? '' : styles.contentReady}`}>
            {
              users && users.length > 0 ? (
                <>
                  <div className={'tableWrapper'}>
                    <>
                      <div className={'tableHeaderRow'}>
                        <div className={'tableData'}>{`${t("user_email")}`}</div>
                        {/* @TBD Because its showing user created time not user invited accepted time */}
                        {/* <div className={'tableData'}>{`${t("date_added")}`}</div> */}
                        <div className={'tableData'}>{`${t("roles")}`}</div>
                        <div className={'tableData'}>{`${t("actions")}`}</div>
                      </div>
                      {
                        users.map((item, index) => {
                          const r = item.organizations[0].roleNames.join(", ");
                          return (
                            <div key={index} className={'tableRow'}>
                              <div className={'tableData'}>{item.email}</div>
                              {/* @TBD Because its showing user created time not user invited accepted time */}
                              {/* <div className={'tableData'}>{new Date(item.createdAt).toLocaleString()}</div> */}
                              <div className={'tableData'}>
                                <span> {r ? r : `${t("no_roles")}`} </span>
                              </div>
                              <div className={`${'tableData'} ${styles.actionContainer}`}>
                                <ToolTip content={`${t("edit_user")}`} position={'bottom'}>
                                  <CustomButton
                                    style={{
                                      background: "var(--accent-color)",
                                      padding: " 12px",
                                      borderRadius: '50%',
                                      border: 'none',
                                      boxSizing: "border-box"
                                    }}
                                    buttonType='button'
                                    classType='tertiary'
                                    disabled={disableCheck(["user:update"], item.id)}
                                    onClick={() => {
                                      setSelectedUser(item);
                                      setIsEditActive(true);
                                    }}
                                  >
                                    <div className={styles.isImageHolder}>
                                      <img style={{ width: "1.2rem" }} src={svgEdit} alt="filter" />
                                    </div>
                                  </CustomButton>
                                </ToolTip>
                                <ToolTip content={`${t("delete_user")}`} position={'bottom'}>
                                  <CustomButton
                                    style={{
                                      background: "var(--accent-color)",
                                      padding: " 12px",
                                      borderRadius: '50%',
                                      border: 'none',
                                      boxSizing: "border-box"
                                    }}
                                    buttonType='button'
                                    classType='tertiary'
                                    disabled={disableCheck(["user:delete"], item.id)}
                                    onClick={() => {
                                      if (item.id === subscriptionDetails.createdBy && subscriptionDetails.deletedAt === null) {
                                        setIsSubscriptionOwner(true);
                                      }
                                      setDeleteUserName(item.id);
                                      setShowModal(true);
                                    }}
                                  >
                                    <div className={styles.isImageHolder}>
                                      <img style={{ width: "1.2rem" }} src={svgDelete} alt="filter" />
                                    </div>
                                  </CustomButton>
                                </ToolTip>
                              </div>
                            </div>
                          )
                        })
                      }
                    </>
                  </div>
                  <div className={'mobileContainer'}>
                    {
                      users.map((item, index) => {
                        const r = item.organizations[0].roleNames.join(",");
                        return (
                          <Fragment key={index}>
                            <div className={'mobileCard'} key={index} >
                              <div className={'rowOne'}>
                                <CustomHeading headerType='h4'>
                                  {item.email}
                                </CustomHeading>
                              </div>
                              <div className={'rowOne'}>
                                {r ? r : `${t("no_roles")}`}
                                {
                                  item.createdAt &&
                                  <div style={{ color: "#999999" }}>{new Date(item.createdAt).toLocaleString()}</div>
                                }
                              </div>
                              <div className={'rowFour'}>
                                <div className={'diamond'}></div>
                                <div className={'line'}></div>
                                <div className={'diamond'}></div>
                              </div>
                              <div className={'rowOne'}>
                                <CustomButton
                                  style={{
                                    background: "var(--accent-color)",
                                    padding: " 12px",
                                    borderRadius: '50%',
                                    border: 'none',
                                    width: "fit-content"
                                  }}
                                  buttonType='button'
                                  classType='tertiary'
                                  disabled={disableCheck(["user:update"], item.id)}
                                  onClick={() => {
                                    setSelectedUser(item);
                                    setIsEditActive(true);
                                  }}
                                >
                                  <div className={styles.isImageHolder}>
                                    <img style={{ width: "1.2rem" }} src={svgEdit} alt="filter" />
                                  </div>
                                </CustomButton>
                                <CustomButton
                                  style={{
                                    background: "var(--accent-color)",
                                    padding: " 12px",
                                    borderRadius: '50%',
                                    border: 'none',
                                    boxSizing: "border-box",
                                    width: "fit-content"
                                  }}
                                  buttonType='button'
                                  classType='tertiary'
                                  disabled={disableCheck(["user:delete"], item.id)}
                                  onClick={() => {
                                    if (item.id === subscriptionDetails.createdBy && subscriptionDetails.deletedAt === null) {
                                      setIsSubscriptionOwner(true);
                                    }
                                    setDeleteUserName(item.id);
                                    setShowModal(true);
                                  }}
                                >
                                  <div className={styles.isImageHolder}>
                                    <img style={{ width: "1.2rem" }} src={svgDelete} alt="filter" />
                                  </div>
                                </CustomButton>
                              </div>
                            </div>
                          </Fragment>
                        )
                      })
                    }
                  </div>
                </>
              ) : (
                <NoContent
                  content={`${t("no_users_to_show")}`}
                />
              )
            }
          </div>
        </div>
      </div>
      <div className={`${styles.actionMenuBg} ${isEditActive ? styles.actionMenuBgActive : ""}`}>
        <div className={`${styles.mobileActionPanel} ${isEditActive ? styles.mobileActionPanelActive : ""}`}>
          {addEditUserPane()}
        </div>
      </div>
    </>
  )
}
