import { useContext, useEffect, useState } from 'react'
import styles from './roles-tab.module.scss'
import CustomButton from '../../../../../components/v2/common/custom-button/custom-button';
import { RootDispatch, RootState } from '../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import AddRole from '../add-edit-role/add-role';
import hasSufficientScopes from '../../../../../util/access-validator/access-validator';
import { useTranslation } from 'react-i18next';
import NoContent from '../../../../../components/v2/common/no-content/no-content';
import Modal from '../../../../../components/v2/common/modal/modal';
import { ActionsPanel } from '../../../../../components/v2/form-builder/actions-panel/actions-panel';
import CustomLoader from '../../../../../components/v2/common/custom-loader/custom-loader';
import { deleteRoleThunk, getRolesThunk } from '../../../../../store/thunks/roles.thunk';
import { RolesResponseDtoItem } from '../../../../../store/models/roles.interface';
import CustomHeading from '../../../../../components/v2/common/custom-heading/custom-heading';
import { getOrgUsersThunk } from '../../../../../store/thunks/organization.thunk';
import { setNewData } from '../../../../../store/slices/active-page.slice';
import { ACTIVE_PAGE_STATUS } from '../../../../../store/models/active-page';
import { activePageThunk } from '../../../../../store/thunks/active-page.thunk';
import { SocketContext } from '../../../../../context/socket';
import svgDelete from "../../../../../assets/v2/delete.svg";
import svgEdit from "../../../../../assets/v2/edit-2.svg";
import TextComponent from '../../../../../components/v2/common/text-component/text-component';
import ToolTip from '../../../../../components/v2/common/tool-tip/tool-tip';
import { fetchSubscriptionThunk } from '../../../../../store/thunks/subscription.thunk';
import SecondaryTopBar from '../../../../../components/v2/common/secondary-top-bar/secondary-top-bar';
import SettingRefreshComponent from '../../setting-refresh-component';
import { UserMetadataKey } from '../../../../../store/models/user-metadata-key.enum';

export default function RolesTab() {
  const dispatch: RootDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const roles = useSelector((state: RootState) => state.roles.data);
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<RolesResponseDtoItem>();
  const [userRole, setUserRole] = useState<boolean>();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [deleteRoleName, setDeleteRoleName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isEditActive, setIsEditActive] = useState(false);
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const activePage = useSelector((state: RootState) => state.activePage.activePage);
  const isLatestSubscriptionActive = useSelector((state: RootState) => state.subscription.data.isActive);
  const userWorkspace = useSelector((state: RootState) => state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);

  useEffect(() => {
    dispatch(fetchSubscriptionThunk());
    getLatestData();
  }, [userWorkspace]);

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activePage])


  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    dispatch(activePageThunk({ socket: socket, payload: { pageName: "roles", status } }));
  }

  useEffect(() => {
    const checkScope = hasSufficientScopes(["role:read"]);
    if (checkScope && newData) {
      getLatestData();
      dispatch((setNewData({ action: "" })));
    }
  }, [newData])


  useEffect(() => {
    if (!isEditActive) {
      setSelectedRole(undefined);
    }
  }, [isEditActive]);

  const deleteRole = async (name: string) => {
    if (isLatestSubscriptionActive) {
      const checkScope = hasSufficientScopes(["role:delete"]);
      if (checkScope) {
        try {
          await dispatch(deleteRoleThunk(name)).unwrap();
          setDeleteRoleName("");
          setShowModal(false);
          toast.success(t("role_deleted"));
        } catch (err) {
          toast.error(t("role_delete_fail"));
        }
      } else {
        toast.error(t('settings_role_delete_no_permission'));
      }
    } else {
      toast.error(t('no_active_subscription_error'));
    }
  }

  const editRole = () => {
    if (isLatestSubscriptionActive) {
      const checkScope = hasSufficientScopes(["role:update"]);
      if (checkScope) {
        setIsEditActive(true);
        setIsSidePaneOpen(true);
        return true;
      } else {
        toast.error(t('settings_user_edit_no_permission'));
        return false;
      }
    } else {
      toast.error(t('no_active_subscription_error'));
    }
  }

  const getLatestData = async () => {
    const checkScope = hasSufficientScopes(["role:read"]);
    if (checkScope) {
      setRefreshPage(true);
      await dispatch(getOrgUsersThunk());
      await dispatch(getRolesThunk());
      setIsLoading(false);
      setUserRole(true);
      setRefreshPage(false);
    } else {
      setIsLoading(false);
      setUserRole(false);
    }
  }


  return (
    <>
      <SecondaryTopBar>
        <div>
          <TextComponent textType='Content'>
            <div>{t("team_roles_subheading")}</div>
          </TextComponent>
        </div>
        <div className={styles.addBtnContainer}>
          <SettingRefreshComponent
            getLatestData={getLatestData}
            refreshPage={refreshPage}
          />
          <CustomButton
            buttonType='button'
            classType='quaternary'
            style={{
              padding: "5px 2rem",
              letterSpacing: '0.5px',
              boxShadow: 'none',
              border: 'none'
            }}
            onClick={async () => {
              if (isLatestSubscriptionActive) {
                const checkScope = hasSufficientScopes(['role:create']);
                if (checkScope) {
                  setIsEditActive(true);
                  setIsSidePaneOpen(true);
                } else {
                  toast.error(t('settings_role_add_no_permission'));
                }
              } else {
                toast.error(t('no_active_subscription_error'));
              }
            }}>
            <TextComponent textType='Content'>
              <div style={{ color: '#fff' }}>{t("add_role")}</div>
            </TextComponent>
          </CustomButton>
        </div>
      </SecondaryTopBar>
      <div className={`${styles.userMainContainer}`}>
        {/* {UserPermissionCheck} */}
        <>
          <Modal showModal={showModal} setShowModal={setShowModal}
            header={
              <div>{t("sure_delete_role")}</div>
            }
            content={
              <div style={{ display: "flex", gap: "1rem" }}>
                <CustomButton
                  buttonType='button'
                  classType='secondary'
                  onClick={() => deleteRole(deleteRoleName)}>
                  <div style={{ color: "#000" }}>{t("yes")}</div>
                </CustomButton>
                <CustomButton
                  buttonType='button'
                  classType='quaternary'
                  onClick={() => { setShowModal(false) }}>
                  <div>{t("no")}</div>
                </CustomButton>
              </div>
            }
          />
          <div style={{ position: "relative" }}>
            {
              <div className={`${isLoading ? '' : styles.loaderClose}`}><CustomLoader /></div>
            }
            {
              <div className={`${styles.contentPane} ${isLoading ? '' : styles.contentReady}`}>
                {
                  roles && roles.length > 0
                    ?
                    <>
                      <div className={'tableWrapper'}>
                        <div className={'tableHeaderRow'}>
                          <div className={'tableData'}>{t("role_name")}</div>
                          <div className={'tableData'}>{t("description")}</div>
                          <div className={'tableData'}>{t("user")}</div>
                          <div className={'tableData'}>{t("actions")}</div>
                        </div>
                        {
                          roles.map((item, index) => {
                            return (
                              <div key={index} className={'tableRow'}>
                                <div className={'tableData'}>{item.name}</div>
                                <div className={'tableData'}>{item.description ? item.description : item.name}</div>
                                <div className={'tableData'}>{`${item.userCount ? item.userCount : '0'} ${t("users")}`}</div>
                                <div className={`${'tableData'} ${styles.actionContainer}`}>
                                  <ToolTip content={`${t("edit_role")}`} position='bottom'>
                                    <CustomButton
                                      style={{
                                        background: "var(--accent-color)",
                                        padding: " 12px",
                                        borderRadius: '50%',
                                        border: 'none',
                                        boxSizing: "border-box"
                                      }}
                                      buttonType='button'
                                      classType='tertiary'
                                      disabled={isLatestSubscriptionActive ? (item.name.startsWith('system:') ? true : (hasSufficientScopes(["role:update"]) ? false : true)) : true}
                                      onClick={() => {
                                        if (editRole()) {
                                          setSelectedRole(item);
                                        }
                                      }}
                                    >
                                      <div className={styles.isImageHolder}>
                                        <img style={{ width: "1.2rem" }} src={svgEdit} alt="edit" />
                                      </div>
                                    </CustomButton>
                                  </ToolTip>
                                  <ToolTip content={`${t("delete_role")}`} position='bottom'>
                                    <CustomButton
                                      style={{
                                        background: "var(--accent-color)",
                                        padding: " 12px",
                                        borderRadius: '50%',
                                        border: 'none',
                                        boxSizing: "border-box"
                                      }}
                                      buttonType='button'
                                      classType='tertiary'
                                      disabled={isLatestSubscriptionActive ? (item.name.startsWith('system:') ? true : (hasSufficientScopes(["role:delete"]) ? false : true)) : true}
                                      onClick={() => {
                                        setDeleteRoleName(item.name);
                                        setShowModal(true);
                                      }}
                                    >
                                      <div className={styles.isImageHolder}>
                                        <img style={{ width: "1.2rem" }} src={svgDelete} alt="delete" />
                                      </div>
                                    </CustomButton>
                                  </ToolTip>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                      <div className={styles.actionPaneMobile}>
                        {
                          isSidePaneOpen &&
                          <ActionsPanel title={t("role_management")} style={{ top: "5rem", minHeight: "calc(100vh - 6rem)", minWidth: "40rem", maxWidth: "40rem", transform: `${isEditActive ? "translate3d(0, 0, 0)" : "translate3d(40rem, 0, 0)"}` }} isActionsPanelOpen={isEditActive} setIsActionsPanelOpen={setIsEditActive}>
                            {
                              isEditActive &&
                              <AddRole setIsSidePaneOpen={setIsSidePaneOpen} currentRole={selectedRole} />
                            }
                          </ActionsPanel>
                        }
                      </div>
                      <div className={`${styles.actionMenuBg} ${isEditActive ? styles.actionMenuBgActive : ""}`}>
                        <div className={`${styles.mobileActionPanel} ${isEditActive ? styles.mobileActionPanelActive : ""}`}>
                          {
                            isEditActive &&
                            <AddRole setIsSidePaneOpen={setIsEditActive} currentRole={selectedRole} />
                          }
                        </div>
                      </div>
                      <div className={'mobileContainer'}>
                        {
                          roles.map((item, index) => {
                            return (
                              <div className={'mobileCard'} key={index} >
                                <div className={styles.rowOne}>
                                  <CustomHeading headerType='h4'>
                                    {item.name}
                                  </CustomHeading>
                                </div>
                                <div className={'rowOne'}>
                                  <div>

                                    {item.description ? item.description : item.name}
                                  </div>
                                  <div>

                                    {`${item.userCount ? item.userCount : '0'} ${t("users")}`}
                                  </div>
                                </div>
                                <div className={'rowFour'}>
                                  <div className={'diamond'}></div>
                                  <div className={'line'}></div>
                                  <div className={'diamond'}></div>
                                </div>
                                <div className={'rowOne'}>
                                  <CustomButton
                                    style={{
                                      background: "var(--accent-color)",
                                      padding: " 12px",
                                      borderRadius: '50%',
                                      border: 'none',
                                      boxSizing: "border-box",
                                      width: "fit-content"
                                    }}
                                    buttonType='button'
                                    classType='tertiary'
                                    disabled={item.name.startsWith('system:') ? true : (hasSufficientScopes(["role:update"]) ? false : true)}
                                    onClick={() => {
                                      if (editRole()) {
                                        setSelectedRole(item);
                                      }
                                    }}
                                  >
                                    <div className={styles.isImageHolder}>
                                      <img style={{ width: "1.2rem" }} src={svgEdit} alt="edit" />
                                    </div>
                                  </CustomButton>
                                  <CustomButton
                                    style={{
                                      background: "var(--accent-color)",
                                      padding: " 12px",
                                      borderRadius: '50%',
                                      border: 'none',
                                      boxSizing: "border-box",
                                      width: "fit-content"
                                    }}
                                    buttonType='button'
                                    classType='tertiary'
                                    disabled={item.name.startsWith('system:') ? true : (hasSufficientScopes(["role:delete"]) ? false : true)}
                                    onClick={() => {
                                      setDeleteRoleName(item.name);
                                      setShowModal(true);
                                    }}
                                  >
                                    <div className={styles.isImageHolder}>
                                      <img style={{ width: "1.2rem" }} src={svgDelete} alt="delete" />
                                    </div>
                                  </CustomButton>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </>
                    :
                    <NoContent
                      content='No roles to show'
                    />
                }
              </div>
            }
          </div>
        </>
      </div>
    </>

  )
}

