import styles from './custom-input.module.scss';
import { CustomInputProps } from './custom-input.props';

export default function CustomInput(props: CustomInputProps) {

  function classSelector(classType: string) {
    switch (classType) {
      case 'primary':
        return styles.customInput;
      case 'secondary':
        return styles.custom2;
      case 'fade':
        return styles.custom3;
      case 'colored':
        return styles.custom4;
      default:
        break;
    }
  }

  return (
    <>
      {!props.hide ? <input type={props.inputType}
        className={`${classSelector(props.inputStyleType)} ${props.disabled ? styles.disableInput : ""} ${props.leftExtraPadding ? styles.extraPadding : ''} ${props.leftLittleMoreExtraPadding ? styles.leftLittleMoreExtraPadding : ''}`}
        style={props.style ? props.style : undefined}
        placeholder={props.placeHolder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        readOnly={props.readonly}
        disabled={props.disabled}
        id={props.id}
        value={props.value != null ? props.value : ''}
        onWheel={(e) => { (e.target as HTMLInputElement).blur() }}
        onKeyDown={(e) => { if (props.inputType === "number" && (e.code === 'ArrowDown' || e.code === 'ArrowUp' || e.code === "KeyE" || e.code === "Minus" || e.code === "Plus")) e.preventDefault();}}
        defaultValue={props.defaultValue}
        onKeyDownCapture={props.onKeyDownCapture}
        key={props.defaultValue}
      /> : undefined}
    </>
  )
}
