import styles from './dashboard.module.scss'; import PieChart, { PieDataProps } from '../../../components/common/charts/pie';
import CustomHeading from '../../../components/v2/common/custom-heading/custom-heading';
import { TFunction } from 'i18next';
import GeoChart, { GeoChartProps } from '../../../components/common/charts/geo';
import BarChart, { BarChartData } from '../../../components/common/charts/bar';
import TextComponent from '../../../components/v2/common/text-component/text-component';

export default function DashboardChartStatistics(props: DashboardChartStatisticsProps) {

  const geoData: GeoChartProps = {
    data: [
      {
        "id": "IND",
        "value": 762115
      },
      {
        "id": "USA",
        "value": 629855
      },
      {
        "id": "CAN",
        "value": 701715
      },
    ]
  }

  return (
    <>
      <div className={styles.chartContainer}>
        {
          props.formStatusData && props.formStatusData.data && props.formStatusData.data.length > 0 &&
          <div className={`${styles.charts} ${styles.pieChart}`}>
            <div className={styles.chartTitle}>
              <TextComponent textType='Header'>
                <div>{`${props.t("dashboard_form_status")}`}</div>
              </TextComponent>
            </div>
            <div className={styles.chartHeight}>
              <PieChart
                data={props.formStatusData.data}
              />
            </div>
          </div>
        }
        {/* <div className={`${styles.charts} ${styles.geoChart}`}>
          <div className={styles.geoText}>
            <TextComponent textType='Header'>
              <div>{`${props.t("location_coming_soon_dashboard")}`}</div>
            </TextComponent>
          </div>
          <div className={styles.geoTitle}>
            <TextComponent textType='Header'>
              <div>{`${props.t("response_location_info_dashboard")}`}</div>
            </TextComponent>
          </div>
          <div className={styles.geoMap}>
            <GeoChart
              data={geoData.data}
            />
          </div>
        </div> */}
        {
          props.deviceInfoData && props.deviceInfoData.data && props.deviceInfoData.data.length > 0 &&
          <div className={`${styles.charts} ${styles.pieChart}`}>
            <div className={styles.chartTitle}>
              <TextComponent textType='Header'>
                <div>{`${props.t("response_device_info_dashboard")}`}</div>
              </TextComponent>
            </div>
            <div className={styles.chartHeight}>
              <PieChart
                data={props.deviceInfoData.data}
              />
            </div>
          </div>
        }
        {
          props.engagingFormsData && props.engagingFormsData.data && props.engagingFormsData.data.length > 0 &&
          <div className={`${styles.charts} ${styles.barChart}`}>
            <div className={styles.chartTitle}>
              <TextComponent textType='Header'>
                <div>{`${props.t("most_engaging_forms_dashboard")}`}</div>
              </TextComponent>
            </div>
            <div className={styles.chartHeight}>
              <BarChart
                data={props.engagingFormsData.data}
                keys={props.engagingFormsData.keys}
                groupMode={props.engagingFormsData.groupMode}
                layout={props.engagingFormsData.layout}
                indexBy={props.engagingFormsData.indexBy}
              />
            </div>
          </div>
        }
        {
          props.formConversionData && props.formConversionData.data && props.formConversionData.data.length > 0 &&
          <div className={`${styles.charts} ${styles.pieChart}`}>
            <div className={styles.chartTitle}>
              <TextComponent textType='Header'>
                <div>{`${props.t("form_access_conversion_dashboard")}`}</div>
              </TextComponent>
            </div>
            <div className={styles.chartHeight}>
              <BarChart
                data={props.formConversionData.data}
                keys={props.formConversionData.keys}
                groupMode={props.formConversionData.groupMode}
                layout={props.formConversionData.layout}
                indexBy={props.formConversionData.indexBy}
              />
            </div>
          </div>
        }
      </div>
      {/* <div className={styles.chartContainer}>
        {
          props.engagingFormsData && props.engagingFormsData.data && props.engagingFormsData.data.length > 0 &&
          <div className={`${styles.charts} ${styles.barChart}`}>
            <div className={styles.chartTitle}>
              <TextComponent textType='Header'>
                <div>{`${props.t("most_engaging_forms_dashboard")}`}</div>
              </TextComponent>
            </div>
            <div className={styles.chartHeight}>
              <BarChart
                data={props.engagingFormsData.data}
                keys={props.engagingFormsData.keys}
                groupMode={props.engagingFormsData.groupMode}
                layout={props.engagingFormsData.layout}
                indexBy={props.engagingFormsData.indexBy}
              />
            </div>
          </div>
        }
        {
          props.formConversionData && props.formConversionData.data && props.formConversionData.data.length > 0 &&
          <div className={`${styles.charts} ${styles.pieChart}`}>
            <div className={styles.chartTitle}>
              <TextComponent textType='Header'>
                <div>{`${props.t("form_access_conversion_dashboard")}`}</div>
              </TextComponent>
            </div>
            <div className={styles.chartHeight}>
              <BarChart
                data={props.formConversionData.data}
                keys={props.formConversionData.keys}
                groupMode={props.formConversionData.groupMode}
                layout={props.formConversionData.layout}
                indexBy={props.formConversionData.indexBy}
              />
            </div>
          </div>
        }
      </div> */}
    </>
  )
}

interface DashboardChartStatisticsProps {
  formStatusData: PieDataProps;
  t: TFunction<"translation", undefined>;
  deviceInfoData: PieDataProps;
  engagingFormsData: BarChartData;
  formConversionData: BarChartData;
}
