import { useNavigate } from 'react-router-dom';
import CustomHeading from '../../common/custom-heading/custom-heading';
import styles from './actions-panel.module.scss';
import { ActionsPanelProps } from './actions-panel.props';
import svgCloseBtn from "../../../../assets/v2/close.svg";

export function ActionsPanel(props: ActionsPanelProps) {
  const navigate = useNavigate();

  const closeActionPanel = () => {
    if (props.navigateDashboardOnClose) {
      if (window.history.state && window.history.state.idx > 0)
        navigate(-1);
      else
        navigate('/', { replace: true });
      return;
    }
    props.setIsActionsPanelOpen(false);
  }

  return (
    <>
      <div className={`${styles.parentCont} ${props.isActionsPanelOpen ? styles.isParentOpen : ''}`} onClick={() => { closeActionPanel() }}>
      </div>
      <div className={`${styles.actionPanel} ${props.isActionsPanelOpen ? styles.actionPanelOpen : ''}`} style={props.style} onClick={(e) => e.stopPropagation()}>
        <div className={styles.m1}>
          <div className={styles.titleContainer}>
            {props.title && <CustomHeading headerType={'h3'}>{props.title}</CustomHeading>}
            {!props.hideCloseButton &&
              <div className={styles.closeBtn}>
                <img style={{width:'1rem'}} onClick={closeActionPanel} src={svgCloseBtn} alt="close-button" />
              </div>
            }
          </div>
          {props.children}
        </div>
      </div>
    </>
  )
}
