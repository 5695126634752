import { useState } from 'react';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import styles from './raffle-mode.module.scss';
import Modal from '../../../../components/v2/common/modal/modal';
import CustomInput from '../../../../components/v2/common/custom-input/custom-input';
import { EndRaffleProps } from './end-raffle.props';
import { chooseWinners, endRaffleMode, getEligibleRaffleCount } from '../../../../api/forms';
import toast from 'react-hot-toast';
import { FormState } from "../../../../store/models/form.interface";
import { RaffleWinner } from '../../../../store/models/form-response.interface';
import CustomHeading from '../../../../components/v2/common/custom-heading/custom-heading';
import svgPaneDelete from "../../../../assets/v2/close.svg";
import gifRaffleSpinWheel from "../../../../assets/v2/icons/raffle-spin-wheel.gif";
import gifRaffleWinner from "../../../../assets/v2/icons/raffle-winner.gif";
import { useTranslation } from 'react-i18next';
import ToolTip from '../../../../components/v2/common/tool-tip/tool-tip';

export default function RaffleMode(props: EndRaffleProps) {
  const { t } = useTranslation();
  const [showEndRaffleModal, setShowEndRaffleModal] = useState(false);
  const [isPickWinnerModal, setIsPickWinnerModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [winners, setWinners] = useState('');
  const [pickWinnerError, setPickWinnerError] = useState('');
  const [pickWinnerLoader, setIsPickWinnerLoader] = useState<{ isApiLoading: boolean, isTimeoutLoading: boolean }>({ isApiLoading: false, isTimeoutLoading: false });
  const [raffleWinners, setRaffleWinners] = useState<RaffleWinner[]>();

  const endRaffle = async () => {
    if (props.form) {
      setIsLoading(true);
      try {
        await endRaffleMode(props.form.id);
        const res = await getEligibleRaffleCount(props.form.id);
        props.setEligibleRaffleCount(res.data.eligibleRaffleCount);
        if (props.raffle) props.setRaffle({ ...props.raffle, config: { ...props.raffle?.config, isActive: false } });
        toast.success(t('raffle_ended'));
      } catch (err) {
        toast.error(t('raffle_end_error'));
      }
      setIsLoading(false);
      setShowEndRaffleModal(false);
    }
  }

  const closeModal = () => {
    setRaffleWinners(undefined);
    setIsPickWinnerLoader({ isApiLoading: false, isTimeoutLoading: false });
    setIsPickWinnerModal(false);
  }

  const chooseWinner = async () => {
    if (props.form) {
      try {
        setTimeout(() => { setIsPickWinnerLoader(prevState => { return { ...prevState, isTimeoutLoading: false } }) }, 2000);
        const res = await chooseWinners(props.form.id, Number(winners));
        setRaffleWinners(res.data.winners);
        if (props.raffle) props.setRaffle({ ...props.raffle, config: { ...props.raffle?.config, winners: [...props.raffle.config.winners, ...res.data.winners] } });
        setIsPickWinnerLoader(prevState => { return { ...prevState, isApiLoading: false } });
        toast.success(t('picked_winners', { winners }));
        setWinners('');
      } catch (err) {
        setIsPickWinnerLoader({ isTimeoutLoading: false, isApiLoading: false });
        toast.error(t('pick_winner_fail'));
      }
    }
  }

  return (
    <>
      {/* End raffle modal */}
      <Modal showModal={showEndRaffleModal} setShowModal={setShowEndRaffleModal}
        header={
          <div className={styles.endRaffleHeader}>
            <div className={styles.headerContainer}>
              <div style={{ fontWeight: '600' }}>
                {t('heads_up')}
              </div>
              <div style={{ position: 'absolute', right: '0' }}>
                <button className={styles.raffleClosebtn} onClick={() => { setShowEndRaffleModal(false) }}>
                  <img className={styles.closImg} src={svgPaneDelete} alt="" />
                </button>
              </div>
            </div>
            <div style={{ width: '75%', textAlign: 'center', fontWeight: '400' }}>
              {t('raffle_sure_end')}
            </div>
          </div>
        }
        content={
          <div style={{ display: "flex", gap: "5rem" }}>
            <CustomButton
              buttonType='button'
              classType='secondary'
              onClick={() => { setShowEndRaffleModal(false) }}
            >
              <div style={{ color: 'var(--font-color)' }}>{t('no_back')}</div>
            </CustomButton>
            <CustomButton
              buttonType='button'
              classType='quaternary'
              onClick={() => { endRaffle() }}>
              {
                isLoading
                  ? <div>{t('loading')}</div>
                  : <div>{t('yes_end_raffle')}</div>
              }
            </CustomButton>
          </div>
        }
      />

      {/* pick winner mode raffle modal */}
      <Modal showModal={isPickWinnerModal} setShowModal={setIsPickWinnerModal} onClose={() => { closeModal(); }}
        innerStyle={{ minWidth: '43rem', minHeight: '29.5rem' }}
        header={
          <div className={styles.endRaffleHeader}>
            <div className={styles.headerContainer}>
              <div style={{ width: "100%", color: "var(--secondary-color)", fontWeight: "600", fontSize: "16px" }}>
                {t('pick_winner')}
              </div>
              <div>
                <button className={styles.raffleClosebtn} onClick={() => { closeModal() }}>
                  <img className={styles.closImg} src={svgPaneDelete} alt="" />
                </button>
              </div>
            </div>
          </div>
        }
        content={
          <div className={styles.modalContainer}>
            {
              pickWinnerLoader.isApiLoading === false && pickWinnerLoader.isTimeoutLoading === false && !raffleWinners &&
              <div style={{ display: "flex", flexDirection: "column", gap: "1rem", fontSize: "14px", flexGrow: '1' }}>
                <div className={styles.infoContainer}>
                  <div style={{ fontWeight: "500" }}> {t('eligible_resps')} - {props.eligibleRaffleCount - props.raffle?.config.winners.length}</div>
                  <div style={{ fontWeight: "500" }}> {t('total_winners_chosen')} - {props.raffle?.config.winners.length}</div>
                </div>
                <div style={{ marginTop: '1rem' }}>{t('how_many_winner')}</div>
                <div>
                  <CustomInput placeHolder={t('enter_winners_count')} inputStyleType='primary' inputType='number' value={winners} onChange={(e) => {
                    if (e.target.value.length > 0 && (parseInt(e.target.value) < 1 || parseInt(e.target.value) > (props.eligibleRaffleCount - props.raffle?.config.winners.length))) {
                      setPickWinnerError(`${t('pick_winner_error', { eligibleRaffleCount: (props.eligibleRaffleCount - props.raffle?.config.winners.length) })}`);
                      setWinners(e.target.value);
                    } else {
                      setWinners(e.target.value);
                      setPickWinnerError('');
                    }
                  }}
                    onKeyDownCapture={(e) => { if (e.key === ".") { e.preventDefault(); } }}
                  />
                  {
                    pickWinnerError !== ''
                      ?
                      <div style={{ fontSize: '0.65rem', marginTop: '0.5rem', color: 'red' }}>{pickWinnerError}</div>
                      :
                      <div style={{ fontSize: '0.65rem', marginTop: '0.5rem' }}> {t('winner_limit', { eligibleLimit: (props.eligibleRaffleCount - props.raffle?.config.winners.length) })}</div>
                  }
                </div>
                <div className={styles.spinBtn} onClick={() => {
                  setIsPickWinnerLoader({ isApiLoading: true, isTimeoutLoading: true });
                  chooseWinner();
                }}>
                  {t('spin')}
                </div>
              </div>
            }
            {
              (pickWinnerLoader.isApiLoading === true || pickWinnerLoader.isTimeoutLoading === true) &&
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: 'auto' }}>
                <div style={{ width: '21rem', height: '17.25rem' }}>
                  <img style={{ width: '21rem' }} src={gifRaffleSpinWheel} alt="spinning wheel..." />
                </div>
                <div>{t('picking_winners')}</div>
                <div className={styles.policyThemeContainer}>
                  <div style={{ textAlign: 'center' }}>{t('powered_by')} <a target="_blank" rel="noreferrer" href="https://iqzsystems.com/products/iqzapps" className={styles.policyTheme}>{t('smart_forms')}</a> - {t('IQZAppsTagLine')}</div>
                </div>
              </div>
            }
            {
              pickWinnerLoader.isApiLoading === false && pickWinnerLoader.isTimeoutLoading === false && raffleWinners &&
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ width: '10rem', height: '14.1rem' }}>
                  <img className={styles.raffleWinner} src={gifRaffleWinner} alt="raffle winner logo" />
                </div>
                {
                  raffleWinners && raffleWinners.length > 0 &&
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', gap: '1rem' }}>
                    <div style={{ color: 'var(--secondary-color)', fontWeight: '600' }}>{
                      raffleWinners.length === 1 ? `${t('we_have_winner')}` : `${t('we_have_winners')}`
                    }</div>
                    <div className={styles.tableScroll}>
                      <div className={styles.main}>
                        <div className={styles.userTableMainDiv}>
                          <div className={styles.userTable}>
                            <div className={styles.headRow}>
                              <div className={styles.tableData}>{t('raffle_id')}</div>
                              {
                                props.raffle && props.raffle.config.userInfo?.includes('email') &&
                                <div className={styles.tableData}>{t('user_email')}</div>
                              }
                              {
                                props.raffle && props.raffle.config.userInfo?.includes('phone') &&
                                <div className={styles.tableData}>{t('phone_num')}</div>
                              }
                            </div>
                            {
                              raffleWinners.map((item, index) => {
                                return (
                                  <div key={index} className={styles.tableRow} >
                                    <div className={styles.tableData}>{item.metadata.raffleId ? item.metadata.raffleId : '-'}</div>
                                    {
                                      props.raffle && props.raffle.config.userInfo?.includes('email') &&
                                      <div className={styles.tableData}>{item.metadata.email ? item.metadata.email : '-'}</div>
                                    }
                                    {
                                      props.raffle && props.raffle.config.userInfo?.includes('phone') &&
                                      <div className={styles.tableData}>{item.metadata.phone ? `${item.metadata.phone.countryCode}-${item.metadata.phone.mobileNumber}` : '-'}</div>
                                    }
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                      {
                        raffleWinners.map((item, index) => {
                          return (
                            <div className={styles.mobileCard} key={index} >
                              <div className={styles.rowOne}>
                                <CustomHeading headerType='h4'>
                                  {t('raffle_id')}: {item.metadata.raffleId ? item.metadata.raffleId : '-'}
                                </CustomHeading>
                              </div>
                              <div className={styles.rowFour}>
                                <div className={styles.diamond}></div>
                                <div className={styles.line}></div>
                                <div className={styles.diamond}></div>
                              </div>
                              {
                                item.metadata.email &&
                                <div className={styles.rowOne}>
                                  <div className={styles.raffleCardData}>
                                    <div>{t('user_email')}:</div>
                                    <div style={{ wordBreak: "break-word", fontWeight: '500' }}>{item.metadata.email}</div>
                                  </div>
                                </div>
                              }
                              {
                                item.metadata.phone &&
                                <div className={styles.rowOne}>
                                  <div className={styles.raffleCardData}>
                                    <div>{t('phone')}:</div>
                                    <div style={{ fontWeight: '500' }}>{item.metadata.phone.countryCode}-{item.metadata.phone.mobileNumber}</div>
                                  </div>
                                </div>
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                }
                <div className={styles.policyThemeContainer}>
                  <div style={{ textAlign: 'center' }}>{t('powered_by')} <a target="_blank" rel="noreferrer" href="https://iqzsystems.com/products/iqzapps" className={styles.policyTheme}>{t('smart_forms')}</a> - {t('IQZAppsTagLine')}</div>
                </div>
              </div>
            }
          </div>
        }
      />
      {
        props.form && props.form.state === FormState.PUBLISHED &&
        <div className={styles.raffleBtnContainer}>
          {
            props.raffle?.config.isActive === true ?
              <div style={{ width: '50%' }}>
                {
                  <CustomButton buttonType="button" style={{ whiteSpace: 'pre' }} classType="quaternary" onClick={() => { setShowEndRaffleModal(true) }}>
                    <div title={t('end_raffle')} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{t('end_raffle')}</div>
                  </CustomButton>
                }
              </div>
              :
              <div style={{ width: '50%' }}>
                <ToolTip content={`Raffle mode ended`} position='bottom'>
                  <CustomButton buttonType="button" style={{ whiteSpace: 'pre' }} classType="secondary" onClick={() => { }} disabled={true}>
                    <div title={t('end_raffle')} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{t('end_raffle')}</div>
                  </CustomButton>
                </ToolTip>
              </div>
          }
          {
            props.raffle?.config.isActive === true ||
              (props.raffle?.config.isActive === false && props.eligibleRaffleCount === props.raffle.config.winners.length)
              ?
              <div style={{ width: '50%' }}>
                <ToolTip content={
                  (props.raffle?.config.isActive === false && props.eligibleRaffleCount === props.raffle.config.winners.length)
                    ? `${t('max_winners')}`
                    : `${t('end_raffle_to_pick')}`
                } position='bottom'>
                  <CustomButton buttonType="button" classType="secondary" style={{ whiteSpace: 'pre' }} onClick={() => { }} disabled={true}>
                    <div title={t('pick_winner')} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{t('pick_winner')}</div>
                  </CustomButton>
                </ToolTip>
              </div>
              :
              <CustomButton buttonType="button" classType="quaternary" style={{ whiteSpace: 'pre', width: '50%' }} onClick={() => { setIsPickWinnerModal(true) }}>
                <div title={t('pick_winner')}>{t('pick_winner')}</div>
              </CustomButton>
          }
        </div>
      }
    </>
  )
}
