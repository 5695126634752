import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../models/load-state.enum";
import { GetOrgInvitationsRespItem, GetOrgUserRespDto } from "../models/organization.interface";
import { attachUserWithOrgRolesThunk, createOrganizationThunk, getOrgInvitationsThunk, getOrgUserByIdThunk, getOrgUsersThunk, removeUserFromOrganizationThunk, revokeUserInviteToOrganizationThunk } from "../thunks/organization.thunk";

const orgInitialState: {
  orgUsers: GetOrgUserRespDto[],
  orgName: string,
  loadingState: LoadState,
  orgInvitations: GetOrgInvitationsRespItem[]
} = { orgUsers: [], orgName: '', orgInvitations: [], loadingState: LoadState.IDLE };

export const orgSlice = createSlice({
  name: "organization",
  initialState: orgInitialState,
  reducers: {
  }, extraReducers(builder) {
    builder
      .addCase(getOrgUsersThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(getOrgUsersThunk.fulfilled, (state, action) => {
        state.orgUsers = action.payload.users;
        state.orgName = action.payload.orgName;
        state.loadingState = LoadState.IDLE;
      })
      .addCase(getOrgUsersThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
      .addCase(removeUserFromOrganizationThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(removeUserFromOrganizationThunk.fulfilled, (state, action) => {
        state.orgUsers = state.orgUsers.filter((item) => item.id !== action.payload.userId);
        state.loadingState = LoadState.IDLE;
      })
      .addCase(removeUserFromOrganizationThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
      .addCase(getOrgInvitationsThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(getOrgInvitationsThunk.fulfilled, (state, action) => {
        state.orgInvitations = action.payload.items;
        state.orgName = action.payload.orgName;
        state.loadingState = LoadState.IDLE;
      })
      .addCase(getOrgInvitationsThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
      .addCase(revokeUserInviteToOrganizationThunk.fulfilled, (state, action) => {
        const inviteId = action.payload;
        for (let invite of state.orgInvitations) {
          if (invite.id === inviteId) {
            invite.status = 'REVOKED'
          }
        }
      })
      .addCase(getOrgUserByIdThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(getOrgUserByIdThunk.fulfilled, (state, action) => {
        state.loadingState = LoadState.IDLE;
      })
      .addCase(getOrgUserByIdThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
      .addCase(attachUserWithOrgRolesThunk.fulfilled, (state, action) => {
        const userId = action.payload.userId;
        for (let u of state.orgUsers) {
          if (u.id === userId) {
            u.organizations[0].roleNames = action.payload.data.roleNames;
          }
        }
        state.loadingState = LoadState.IDLE;
      })
  },
});

export default orgSlice;
