import { createAsyncThunk } from '@reduxjs/toolkit';
import { attachUserWithOrgRoles, createOrganization, getOrgInvitations, getOrgUsers, removeUserFromOrganization, revokeUserInviteToOrganization } from '../../api/organization';
import { getUser } from '../../api/user';
import { SetOrgUserRolesDto } from '../models/organization.interface';
import { CreateOrganizationReqDto } from '../models/sign-up.interface';

export const getOrgUsersThunk = createAsyncThunk(
  'organization/getUsers',
  async () => {
    const response = await getOrgUsers();
    return response;
  }
);

export const getOrgUserByIdThunk = createAsyncThunk(
  'organization/getUserById',
  async (userId: string) => {
    const response = await getUser(userId);
    return response;
  }
)

export const removeUserFromOrganizationThunk = createAsyncThunk(
  'organization/removeUser',
  async (userId: string, { rejectWithValue }) => {
    try {
      await removeUserFromOrganization(userId);
      return { userId: userId };
    } catch (err: any) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const getOrgInvitationsThunk = createAsyncThunk(
  'organization/getOrgInvitations',
  async () => {
    const res = await getOrgInvitations();
    let { status, message, ...data } = res;
    return data;
  }
)

export const revokeUserInviteToOrganizationThunk = createAsyncThunk(
  'organization/revokeUserInviteToOrganization',
  async (inviteId: string) => {
    await revokeUserInviteToOrganization(inviteId);
    return inviteId;
  }
)

export const attachUserWithOrgRolesThunk = createAsyncThunk(
  'organization/attachUserWithOrgRoles',
  async (payload: { userId: string, data: SetOrgUserRolesDto }) => {
    await attachUserWithOrgRoles(payload.userId, payload.data);
    return payload;
  }
)

export const createOrganizationThunk = createAsyncThunk(
  'organization/add',
  async (data: CreateOrganizationReqDto) => {
    let response = await createOrganization(data);
    return response;
  }
)


