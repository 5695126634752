import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styles from './bread-crumbs.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { getForm } from '../../../../store/slices/forms.slice';

export default function BreadCrumbs() {
  const location = useLocation();
  const [crumbs, setCrumbs] = useState<{ title: string, path: string }[]>([]);
  const { workspaceId, formId } = useParams();
  const form = useSelector((state: RootState) => getForm(state, formId as string));
  useEffect(() => {
    const pathArr = location.pathname.split('/');
    const spliceLen = pathArr.length > 2 && pathArr.includes(workspaceId as string) ? 3 : 1;
    const splitPath = pathArr.splice(spliceLen);
    const splitPathCopy = [...splitPath];
    let pathValue = '';
    let obj = { title: "", path: "" }
    const temp = [];
    for (const element of splitPath) {
      let title = '';
      if (formId === splitPathCopy[splitPathCopy.length - 1] && form) {
        title = form?.name
        pathValue = `forms/${formId}`
      } else {
        title = splitPathCopy[splitPathCopy.length - 1];
        pathValue = splitPathCopy.join('/');
      }
      title = title.charAt(0).toUpperCase() + title.slice(1);
      obj = {
        title: title,
        path: pathValue
      }
      temp.push(obj);
      splitPathCopy.pop();
    }
    temp.reverse();
    setCrumbs(temp);
  }, [location.pathname, form]);

  return (
    <div className={styles.main}>{
      crumbs.map((item, index) => (
        <Fragment key={`${item.title}`}>
          {
            location.pathname === `/o/${workspaceId}/${item.path}` || location.pathname === `/${item.path}` ?
              <div className={`${styles.crumbs} ${styles.crumbsActiveTitle}`}>{
                item.title}</div> :
              <Link style={{
                color: 'var(--secondary-color)',
                textDecoration: 'underline',
                textUnderlineOffset: '4px',
                cursor: 'pointer'
              }} className={styles.crumbs} to={item.path.includes('profile') ? `/${item.path}` : `o/${workspaceId}/${item.path}`}>{
                  item.title.length > 10 ?
                    `${item.title.substring(0, 7)}...` :
                    item.title
                }</Link>
          }
          {
            crumbs.length !== (index + 1) &&
            <div style={{color:'var(--font-color)'}}>{'/'}</div>
          }
        </Fragment>
      ))
    }</div>
  )
}
