import { useEffect, useState } from 'react'
import styles from './form-details-header.module.scss'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import ToolTip from '../../../../components/v2/common/tool-tip/tool-tip';
import { Form, FormState } from '../../../../store/models/form.interface';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { RootDispatch, RootState } from '../../../../store/store';
import { getForm } from "../../../../store/slices/forms.slice";
import svgShare from "../../../../assets/v2/share.svg";
import hasSufficientScopes from '../../../../util/access-validator/access-validator';
import { getControlByFormThunk } from '../../../../store/thunks/controls.thunk';
import { updateIndex } from '../../../../store/slices/step-index.slice';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import svgEdit from "../../../../assets/v2/edit-2.svg";
import svgClone from "../../../../assets/v2/copy.svg";
import svgDelete from "../../../../assets/v2/delete.svg";
import { fetchResponseCount } from '../../../../api/form-response';
import Modal from '../../../../components/v2/common/modal/modal';
import { deleteFormThunk, fetchFormByIdThunk } from '../../../../store/thunks/forms.thunk';
import { FormDetailsHeaderProps } from './form-details-header.props';
import { getLinkInteractions } from '../../../../api/custom-links';
import { getQuizFormControl, getRaffleFormControl } from '../../../../api/form-controls';
import FormDetailsHeaderMenu from './form-details-header-menu/form-details-header-menu';
import svgCharmMenuMeatball from "../../../../assets/v2/icons/charm_menu-meatball.svg";
import { fetchSubscriptionThunk } from '../../../../store/thunks/subscription.thunk';
import svgRefresh from "../../../../assets/v2/refresh.svg";
import svgLoading from "../../../../assets/v2/icons/loading.svg";
import { updateRefreshPage } from '../../../../store/slices/active-page.slice';
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import { UserMetadataKey } from '../../../../store/models/user-metadata-key.enum';

export default function FormDetailsHeader(props: FormDetailsHeaderProps) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formId } = useParams();
  const form: Form | undefined = useSelector((state: RootState) => getForm(state, formId as string));
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch: RootDispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState("");
  const [pageTitle, setPageTitle] = useState<{ url: string; title: string; end: boolean; }[]>([]);
  const isLatestSubscriptionActive = useSelector((state: RootState) => state.subscription.data.isActive);
  const [refreshPage, setRefreshPage] = useState(false);
  const refreshWebPage = useSelector((state: RootState) => state.activePage.refreshPage);
  const location = useLocation();
  const profile = useSelector((state: RootState) => state.user.profileInfo);
  const [activeOrgName, setActiveOrgName] = useState(profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);

  useEffect(() => {
    dispatch(fetchSubscriptionThunk());
  }, []);

  useEffect(() => {
    setActiveOrgName(profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);
  }, [profile]);

  useEffect(() => {
    init();
  }, [form, refreshWebPage]);

  useEffect(() => {
    if (/responses|quiz|raffle|analytics|custom-link/.test(location.pathname)) {
      const value = pageTitle.find(item => { return location.pathname.includes(item.title.toLowerCase()) });
      if (value) setActiveIndex(value.title);
    } else {
      setActiveIndex('Details');
    }
  }, [location.pathname, pageTitle])


  const shareForm = () => {
    props.setShareVisible(true);
    setIsMenuActive(false);
    props.setIsShareMenuActive(true)
  }

  const editForm = (id: string) => {
    if (isLatestSubscriptionActive) {
      if (window.matchMedia("(pointer:coarse)").matches && window.matchMedia("(any-pointer:coarse)").matches) {
        toast.error(t("mob_not_supported"));
        return
      }
      const checkScope = hasSufficientScopes(["forms:update", "forms:update.all"], form?.createdBy);
      if (checkScope) {
        dispatch(getControlByFormThunk(id));
        dispatch(updateIndex(2));
        navigate('edit');
      } else {
        toast.error(t('form_details_edit_form_no_permission'));
      }
    } else {
      toast.error(t('no_active_subscription_error'));
    }
  }

  const cloneForm = async (id: string) => {
    if (isLatestSubscriptionActive) {
      const checkScope = hasSufficientScopes(["forms:create"]);
      if (checkScope) {
        dispatch(getControlByFormThunk(id));
        dispatch(updateIndex(1));
        navigate(`../forms/create?type=form&id=${id}`)
      } else {
        toast.error(t('form_details_clone_form_no_permission'));
      }
    } else {
      toast.error(t('no_active_subscription_error'));
    }
  }

  const init = async () => {
    const tempArr = [{
      url: ``,
      title: 'Details',
      end: true
    }];
    if (!form) {
      await dispatch(fetchFormByIdThunk(formId as string));
    }
    try {
      if (form && form.state === FormState.PUBLISHED) {
        const formPublished = await fetchForm();
        if (formPublished > 0) {
          tempArr.push({
            title: 'Responses',
            url: `responses`,
            end: false
          });
          tempArr.push({
            title: 'Analytics',
            url: `analytics`,
            end: false
          });
        }
        const customLink = await getInteractions();
        if (customLink > 0) tempArr.push({
          title: 'Custom-Link',
          url: `custom-link`,
          end: true
        });
        if (await getQuizControl() && formPublished > 0) tempArr.push({
          title: 'Quiz',
          url: `quiz`,
          end: true
        });
        if (await getRaffleControls() && formPublished > 0) tempArr.push({
          title: 'Raffle',
          url: `raffle`,
          end: true
        });
      }
    } catch (err) {
      toast.error(t("fetch_control_fail"));
    }
    setRefreshPage(false)
    setPageTitle(tempArr);
  }

  const getQuizControl = async () => {
    if (formId) {
      try {
        return await getQuizFormControl(formId);
      } catch (err) { }
    }
  }

  const fetchForm = async () => {
    let count = 0;
    if (formId) {
      if (form === undefined) {
        await dispatch(fetchFormByIdThunk(formId));
      }
      const checkScope = hasSufficientScopes(["response:read", "response:read.all"], form?.createdBy);
      if (form && form.state === FormState.PUBLISHED && checkScope) {
        count = await fetchFormResponseCount(formId);
      }
    }
    return count;
  }

  const getInteractions = async () => {
    let customLinkCount = 0;
    if (formId) {
      try {
        const res = await getLinkInteractions(formId, {});
        customLinkCount = res.data.interactions.length;
      } catch (err) {
        toast.error(t("fetch_interaction_fail"));
      }
    }
    return customLinkCount;
  }

  const getRaffleControls = async () => {
    if (formId) {
      try {
        return await getRaffleFormControl(formId);
      } catch (err) { }
    }
  }

  const fetchFormResponseCount = async (formId: string) => {
    let count: number = 0;
    try {
      const response = await fetchResponseCount(formId);
      count = response.data.count;

    } catch (err) {
      toast.error(t('form_details_toast_form_response_err'));
    }
    return count;
  }

  const deleteForm = async () => {
    if (isLatestSubscriptionActive) {
      const checkScope = hasSufficientScopes(["forms:delete", "forms:delete.all"], form?.createdBy);
      if (checkScope) {
        if (formId) {
          try {
            await dispatch(deleteFormThunk({ id: formId }));
            toast.success(t('form_delete_success'));
            navigate(`/o/${activeOrgName}/forms`);
          } catch (err) {
            toast.error(t('form_details_toast_form_delete_err'));
          }
        } else {
          toast.error(t('form_details_delete_form_no_permission'));
        }
      } else {
        toast.error(t('form_details_delete_form_no_permission'));
      }
    } else {
      toast.error(t('no_active_subscription_error'));
    }
  }

  const deleteModal = () => {
    return (
      <Modal showModal={showModal} setShowModal={setShowModal}
        header={
          <div>{t("sure_delete_form")}</div>
        }
        content={
          <div style={{ display: "flex", gap: "1rem" }}>
            <CustomButton
              buttonType='button'
              classType='secondary'
              onClick={deleteForm}>
              <div style={{ color: "#000" }}>{t("yes")}</div>
            </CustomButton>
            <CustomButton
              buttonType='button'
              classType='quaternary'
              onClick={() => { setShowModal(false) }}>
              <div style={{ color: '#fff' }}>{t("no")}</div>
            </CustomButton>
          </div>
        }
      />
    )
  }

  return (
    <>
      {deleteModal()}
      <div className={styles.headerContainer}>
        <div className={pageTitle.length > 1 ? styles.headerTabTwo : styles.headerTab}>
          {
            pageTitle.length > 0 &&
            <div className={styles.pageTabContainer}>
              {
                pageTitle.map((item, index) => (
                  <div key={item.title} className={styles.pageTabContent} style={{
                    borderBottom: `${activeIndex === item.title ? '2px solid var(--secondary-color)' : '2px solid transparent'}`
                  }} >
                    <CustomButton
                      buttonType='button'
                      classType='secondary'
                      style={{
                        background: 'none',
                        border: 'none',
                        boxShadow: 'none',
                        padding: '1rem'
                      }}
                      onClick={() => {
                        const checkScope = hasSufficientScopes(["response:read", "response:read.all"], form?.createdBy);
                        if (/Responses|Quiz|Raffle|Analytics/.test(item.title) && !checkScope) {
                          toast.error(t('form_details_view_response_no_permission'));
                          return;
                        }
                        navigate(item.url);
                      }}
                    >
                      <TextComponent textType='Content'> {item.title}</TextComponent>
                    </CustomButton>
                  </div>
                ))
              }
            </div>
          }
        </div>
        <div className={styles.formActionContainer}>
          <div>
            <ToolTip position="bottom" content={`${t("refresh")}`}>
              <CustomButton
                buttonType='button'
                classType='tertiary'
                style={{
                  background: "var(--accent-color)",
                  borderRadius: '48px',
                  border: 'none',
                  padding: '12px',
                  boxShadow: 'none'
                }}
                onClick={() => {
                  setRefreshPage(true);
                  dispatch(updateRefreshPage({ refresh: new Date() }));
                }}
              >
                <div className={styles.isImageHolder}>
                  {refreshPage ? <img src={svgLoading} /> : <img src={svgRefresh} />}
                </div>
              </CustomButton>
            </ToolTip>
          </div>
          {
            form && form.state === FormState.PUBLISHED && form.isPublic === true &&
            <div>
              <ToolTip position="bottom" content={`${t("share")}`}>
                <CustomButton
                  style={{
                    background: "var(--accent-color)",
                    borderRadius: '48px',
                    border: 'none',
                    padding: '12px',
                    boxShadow: 'none'
                  }}
                  buttonType='button'
                  classType='tertiary'
                  onClick={shareForm}
                >
                  <div className={styles.isImageHolder}>
                    <img src={svgShare} />
                  </div>
                </CustomButton>
              </ToolTip>
            </div>
          }
          {
            form && (form.state === FormState.DRAFT || form.state === FormState.WAITING_FOR_PUBLISH) &&
            <div>
              <ToolTip position="bottom" content={`${t("edit")}`}>
                <CustomButton
                  buttonType='button'
                  classType='secondary'
                  style={{
                    background: "var(--accent-color)",
                    borderRadius: '48px',
                    border: 'none',
                    padding: '12px',
                    boxShadow: 'none',
                    display: 'flex',
                    alignItems: 'baseline'
                  }}
                  onClick={() => {
                    editForm(formId as string)
                  }}
                >
                  <div className={styles.isImageHolder}>
                    <img src={svgEdit} />
                  </div>
                </CustomButton>
              </ToolTip>
            </div>
          }
          <div>
            <ToolTip position="bottom" content={`${t("clone")}`}>
              <CustomButton
                buttonType='button'
                classType='secondary'
                style={{
                  background: "var(--accent-color)",
                  borderRadius: '48px',
                  border: 'none',
                  padding: '12px',
                  boxShadow: 'none',
                  display: 'flex',
                  alignItems: 'baseline'
                }}
                onClick={() => {
                  cloneForm(formId as string);
                }}
              >
                <div className={styles.isImageHolder}>
                  <img src={svgClone} alt='clone' />
                </div>
              </CustomButton>
            </ToolTip>
          </div>
          <div>
            <ToolTip position="bottom" content={`${t("delete")}`}>
              <CustomButton
                style={{
                  background: "var(--accent-color)",
                  borderRadius: '48px',
                  padding: '12px',
                  border: 'none',
                  boxShadow: 'none'
                }}
                buttonType='button'
                classType='tertiary'
                onClick={() => {
                  if (isLatestSubscriptionActive) {
                    if (hasSufficientScopes(["forms:delete", "forms:delete.all"], form?.createdBy)) {
                      setShowModal(true);
                    } else {
                      toast.error(t('form_details_delete_form_no_permission'));
                    }
                  } else {
                    toast.error(t('no_active_subscription_error'));
                  }
                }}
              >
                <div className={styles.isImageHolder}>
                  <img src={svgDelete} alt='delete' />
                </div>
              </CustomButton>
            </ToolTip>
          </div>
        </div>
        <div className={styles.actionMenuBtn}>
          <CustomButton
            buttonType='button'
            classType='quaternary'
            style={{ height: '2.5rem' }}
            onClick={() => { setIsMenuActive(true) }}
          >
            <div className={styles.isImageHolder}>
              <img src={svgCharmMenuMeatball} alt='menu' />
            </div>
          </CustomButton>

        </div>
      </div>
      <FormDetailsHeaderMenu
        cloneForm={cloneForm}
        editForm={editForm}
        isMenuActive={isMenuActive}
        setIsMenuActive={setIsMenuActive}
        setShareVisible={props.setShareVisible}
        setShowModal={setShowModal}
        isShareMenuActive={props.isShareMenuActive}
        setIsShareMenuActive={props.setIsShareMenuActive}
      />
    </>
  )
}
