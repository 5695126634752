import { ConditionalLogicListProps } from "./conditional-logic-list.props";
import styles from './conditional-logic-list.module.scss';
import trashIcon from '../../../../../assets/v2/delete.svg';
import { useTranslation } from "react-i18next";

export function ConditionalLogicList(props: ConditionalLogicListProps) {

  const { t } = useTranslation();

  const validateDuplication = (id : string)=>{
    const temp = props.logicBuilder.conditionalLogic.filter(val => val.id !== id);
    if (props.createLogic.srcControl && props.createLogic.tgtControl) {
      for (const logic of temp) {
        if (logic.source === props.createLogic.srcControl.id && logic.target === props.createLogic.tgtControl.id) {
          props.createLogic.assignErrors('duplicate',t('cond_edge_dup_err'));
          return;
        } 
        props.createLogic.assignErrors('duplicate', '');
      }
    }

    if (temp.length === 0) {
      props.createLogic.assignErrors('duplicate', '');
    }
  }

  return (
    <div className={styles.container}>
      {
        props.conditionalLogic?.map((logic, index) => {
          return (
            <div key={index} className={styles.listContainer}>
              <div className={styles.command}>SHOW</div>
              <div className={styles.label}>{props.logicControlLookup[logic.target]?.label}</div>
              <div className={styles.command}>IF</div>
              <div className={styles.label}>{props.logicControlLookup[logic.source]?.label}</div>
              <div className={styles.command}>{logic.value.type.split('_').join(' ').toLowerCase()}</div>
              <div>{logic.value.value.join(', ')}</div>
              <div style={{ float: 'right' }}>
                <div className={`${styles.trashContainer} ${props.logicBuilder.deleteLoader ? styles.disableDelete : ''}`} onClick={() => { props.logicBuilder.deleteConditionalLogic(logic.id); validateDuplication(logic.id); }}>
                  <img src={trashIcon} alt="trash icon" />
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}
