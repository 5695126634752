import { useEffect, useState } from 'react';
import { BarDatum, BarTooltipProps, ResponsiveBar } from '@nivo/bar';
import { chartColors, fontFamily } from './constants';
import { interpolateGnBu } from 'd3-scale-chromatic';
import styles from './charts.module.scss'
import { colorGenerator } from './colorGenerator';

export default function BarChart(chartData: BarChartData) {

  const [axisValues, setAxisValues] = useState<Record<string, BarDatum>>({});

  useEffect(() => {
    if (chartData.layout === 'horizontal') {
      let obj: Record<string | number, BarDatum> = {}
      for (const key in chartData.data) {
        obj[chartData.data[key].id] = chartData.data[key];
      };
      setAxisValues(obj);
    }
  }, []);

  return (
    <>
      <ResponsiveBar
        keys={chartData.keys}
        margin={{ top: 10, right: 20, bottom: 80, left: 60 }}
        colors={colorGenerator(chartData.data.length, interpolateGnBu)}
        colorBy='indexValue'
        axisTop={null}
        axisRight={null}
        borderColor={"#000"}
        padding={0.2}
        borderWidth={0.4}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: "linear scale"
        }}
        axisLeft={{
          format: (v) => {
            let tickLable = null;
            if (Object.keys(axisValues).length > 0) {
              tickLable = axisValues[v].name as string;
              return (tickLable && tickLable.length > 5 ? (tickLable.substring(0, 3) + "...") : tickLable)
            }
            return (v);
          },
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: -40,
          tickValues: "linear scale"

        }}
        tooltip={(data) => {
          return (
            <>{chartToolTip({ data: data })}</>
          )
        }}
        theme={{
          fontFamily: fontFamily,
          fontSize: 12,
        }}
        labelTextColor={"#ffff"}
        data={chartData.data.filter((item) => {
          if ((item.value as number) > 0) {
            return item;
          }
        })}
        enableGridX={false}
        enableGridY={false}
        indexBy={chartData.indexBy}
        // legends={[{
        //   dataFrom: 'indexes',
        //   anchor: 'bottom',
        //   direction: 'row',
        //   justify: false,
        //   translateX: 0,
        //   translateY: 60,
        //   itemsSpacing: 2,
        //   itemWidth: 100,
        //   itemHeight: 20,
        //   itemDirection: 'left-to-right',
        //   itemOpacity: isMobile ? 0 : 1,
        //   symbolSize: 20,
        //   effects: [
        //     {
        //       on: 'hover',
        //       style: {
        //         itemOpacity: 1
        //       }
        //     }
        //   ],
        // }]}
        layout={chartData.layout}
        groupMode={chartData.groupMode}
      />
    </>
  )
}

export function chartToolTip(props: { data: BarTooltipProps<BarDatum> }) {
  return (
    <div className={styles.tooltipContainer}>
      <span style={{
        minWidth: '0.75rem',
        minHeight: '0.75rem',
        background: props.data.color
      }}></span>
      <span>
        {props.data.data.name} : <strong>{props.data.data.value}</strong>
      </span>
    </div>)
}



export interface BarChartData {
  data: BarDatum[]; //data for chart
  keys: string[]; // data object keys to print as label in chart
  layout: "horizontal" | "vertical";//chart type "horizontal" or "vertical"
  groupMode: "grouped" | "stacked";//chart grouping mode "grouped" or "stacked"
  indexBy: string; // index key value for chart render
}
