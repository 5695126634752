import React from 'react';
import styles from './custom-text.module.scss';
import { CustomTextProps } from './custom-text.props';

export default function CustomText(props: CustomTextProps) {
    function classSelector(textType: string) {
        switch (textType) {
            case 't1':
                return styles.textOne;
            case 't2':
                return styles.textTwo;
            case 't3':
                return styles.textThree;
            case 't4':
                return styles.textFour;
            case 't5':
                return styles.textFive;
            case 't6':
                return styles.textSix;
            case 't7':
                return styles.textSeven;
            case 't8':
                return styles.textEight;
            case 't9':
                return styles.textNine;
            default:
                break;
        }
    }
    return (
        <>
            <div className={`${classSelector(props.textType)} ${props.isErrorText && styles.errorText}`} style={props.style}>{props.children}</div>
        </>
    )
}