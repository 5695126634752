import axios, { AxiosResponse } from "axios";
import { QueryParams, FormListResponseDto, GetFormResponseItemDto, GetFormResponseWithQuestions, GetAllResponseID } from '../store/models/form-response.interface';
import { GenerateApiUrl } from "./generate-api-url";
import { GetFilesDto } from "../store/models/form.interface";

export async function fetchFormList(qParams?: QueryParams): Promise<AxiosResponse<{ formList: FormListResponseDto[], totalResponseCount: number, formsCount: number }>> {
  let apiUrl: string = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form-response/forms-list`, true);
  let response: AxiosResponse<{ formList: FormListResponseDto[], totalResponseCount: number, formsCount: number }>;
  let params = {};
  if (qParams?.take) {
    Object.assign(params, { take: qParams?.take });
  }
  if (qParams?.skip) {
    Object.assign(params, { skip: qParams?.skip });
  }
  if (qParams?.formName) {
    Object.assign(params, { formName: qParams?.formName });
  }
  response = await axios.get<{ formList: FormListResponseDto[], totalResponseCount: number, formsCount: number }>(apiUrl, { params: params });
  return response;
}

export async function fetchResponseList(params: { formId: string, startDate?: Date, endDate?: Date, searchText?: string, skip?: number, take?: number, includeCustomLink?: boolean }) {
  let apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'form-response/' + params.formId, true);
  const url = new URL(apiUrl);
  if (params.startDate) {
    url.searchParams.append('startDate', params.startDate.toString())
  }
  if (params.endDate) {
    url.searchParams.append('endDate', params.endDate.toString())
  }
  if (params.searchText) {
    url.searchParams.append('searchText', params.searchText);
  }
  if (params.skip) {
    url.searchParams.append('skip', params.skip.toString())
  }
  if (params.take) {
    url.searchParams.append('take', params.take.toString())
  }
  if (params.includeCustomLink) {
    url.searchParams.append('includeCustomLink', params.includeCustomLink.toString());
  }
  apiUrl = url.href;
  const response = await axios.get<{ data: { FormResponses: GetFormResponseItemDto[], hasFile: boolean }, totalCount: number }>(apiUrl);
  return response;
}

export async function fetchFormResponseAnalytics(params: { formId: string }): Promise<AxiosResponse<{ data: { FormResponses: GetFormResponseItemDto[] }, totalCount: number }>> {
  let apiUrl: string = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'form-response/analytics/' + params.formId, true);
  const url = new URL(apiUrl);
  apiUrl = url.href;
  let response: AxiosResponse<{ data: { FormResponses: GetFormResponseItemDto[] }, totalCount: number }> = await axios.get(apiUrl);
  return response;
}

export async function downloadAttachment(fileName: string) {
  let downloadApiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'form-response/download-attachment?fileName=' + fileName, true);
  const resp = await axios({
    method: 'get',
    url: downloadApiUrl,
    responseType: 'arraybuffer'
  })
  return resp;
}

export async function downloadCsvOrExcelZip(formId: string, donwloadType: string) {
  let downloadApiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'form-response/' + formId + `/download/` + donwloadType, true);

  const resp = await axios({
    method: 'get',
    url: downloadApiUrl,
    responseType: 'blob'
  });

  return resp;
}

export async function fetchResponseCount(formId: string) {
  let apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'form-response/count?formId=' + formId, true);
  let response = await axios.get<{ count: number }>(apiUrl);
  return response;
}

export async function downloadResZip(formId: string, responseId: string, donwloadType: string) {
  let downloadApiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form-response/${formId}/${responseId}/download/${donwloadType}`, true);
  const res = await axios({
    method: 'get',
    url: downloadApiUrl,
    responseType: 'blob'
  });
  return res;
}

export async function getQuizModeScores(formId: string, skip?: number, take?: number) {
  let apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'form-response/' + formId + '/results', true);
  const url = new URL(apiUrl);
  if (skip !== undefined) {
    url.searchParams.append('skip', skip.toString())
  }
  if (take) {
    url.searchParams.append('take', take.toString())
  }
  apiUrl = url.href;
  let response = await axios.get(apiUrl);
  return response.data.data;
}

export async function getFormResponseByResponseId(formId: string, responseId: string, skip?: number, take?: number) {
  let apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'form-response/' + formId + '/responses/' + responseId, true);
  const url = new URL(apiUrl);
  if (skip !== undefined) {
    url.searchParams.append('skip', skip.toString())
  }
  if (take) {
    url.searchParams.append('take', take.toString())
  }
  apiUrl = url.href;
  let response = await axios.get(apiUrl);
  return response.data;
}

export async function fetchFormResponseWithQuestion(params: { formId: string, skip?: number, take?: number }): Promise<AxiosResponse<GetFormResponseWithQuestions>> {
  let apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form-response/${params.formId}/responses`, true);
  const url = new URL(apiUrl);
  if (params.skip !== undefined) {
    url.searchParams.append('skip', params.skip.toString())
  }
  if (params.take !== undefined) {
    url.searchParams.append('take', params.take.toString())
  }
  apiUrl = url.href;
  const response = await axios.get<GetFormResponseWithQuestions>(apiUrl);
  return response;
}

export async function fetchResponsesID(formId: string): Promise<GetAllResponseID[]> {
  let apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'form-response/responses-id?formId=' + formId, true);
  let response = await axios.get<{ responsesID: GetAllResponseID[] }>(apiUrl);
  return response.data.responsesID;
}

export async function getFileResponses(skip?: number, take?: number) {
  let apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'form-response/assets', true);
  const url = new URL(apiUrl);
  if (skip) {
    url.searchParams.append('skip', skip.toString())
  }
  if (take) {
    url.searchParams.append('take', take.toString())
  }
  apiUrl = url.href;
  let response = await axios.get<GetFilesDto, any>(apiUrl);
  return response.data;
}

export async function deleteAttachment(mediaId: string,attachmentId : string) {
  let apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form-response/${attachmentId}/remove-attachment`, true);
  const url = new URL(apiUrl);
  if (mediaId) {
    url.searchParams.append('mediaId', mediaId.toString())
  }
  apiUrl = url.href;
  let response = await axios.delete(apiUrl);
  return response;
}