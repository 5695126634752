export interface CustomLinkInteractionResponse {
  status: number;
  message: number;
  interactions: GetCustomLinkInteractionsResDto[];
}

export interface GetCustomLinkInteractionsResDto {
  isRevoked: boolean | undefined;
  id: string;
  email: string;
  expiryTime: Date | null;
  formId: string
  createdAt: Date;
  updatedAt: Date;
  interactions: InteractionActivity[];
}

export interface InteractionActivity {
  activity: InteractionStatus;
  id: string;
  createdAt: Date;
}

export enum InteractionStatus {
  NOT_SENT = "NOT_SENT",
  SENT = "SENT",
  OPENED = "OPENED",
  CLICKED = "CLICKED",
  SUBMITTED = "SUBMITTED"
}

export interface GetInteractionsParams {
  email?: string;
}
