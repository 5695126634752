import axios, { AxiosError, AxiosResponse } from "axios";
import { CreateRoleReqDto, RolesResponseDtoItem, UpdateRoleReqDto } from "../store/models/roles.interface";
import { GenerateApiUrl } from "./generate-api-url";
import toast from "react-hot-toast";

export async function getRoles() {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `roles`, true);
  const response = await axios.get<{ roles: RolesResponseDtoItem[], status: number, message: string }>(apiUrl);
  return response.data;
}

export async function updateRole(roleName: string, data: UpdateRoleReqDto) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `roles/${roleName}`, true);
  const response = await axios.patch<void>(apiUrl, data);
  return response;
}

export async function createRole(data: CreateRoleReqDto) {
  try {
    const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `roles`, true);
    const response = await axios.post<RolesResponseDtoItem & { status: number, message: string }>(apiUrl, data);
    return response;
  } catch (error) {
    if ((error as AxiosError).message == 'QUOTA_LIMIT_EXCEEDED') {
      toast.error(`Quota set for the your current subscription has exceeded. Please upgrade your plan to create more roles`);
    } else if (((error as AxiosError).response as AxiosResponse).data?.message == 'SUBSCRIPTION_INACTIVE') {
      toast.error(`Please renew your plan to create more roles`);
    } else {
      toast.error(`An error occurred while creating the form. Please try again later`);
    }
    throw error;
  }
}

export async function deleteRole(roleName: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `roles/${roleName}`, true);
  const response = await axios.delete<void>(apiUrl);
  return response;
}

