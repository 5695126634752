import axios from "axios";
import { CreateAccountRespDto, PasswordResetReqDto, ResendVerificationReqDto } from "../store/models/sign-up.interface";
import { CheckIfUserExistsRespDto, CreateUserReqDto, UserActivationReqDto, UserActivationRespDto } from "../store/models/user.interface";
import { GenerateApiUrl } from "./generate-api-url";

const uaxios = axios.create();  // Create new instance to prevent interception and token override.

export async function createAccount(data: CreateUserReqDto): Promise<CreateAccountRespDto> {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `public/create-account`, false);
  return new Promise((res, reject) => {
    grecaptcha.ready(async function () {
      try {
        const val = await grecaptcha.execute(process.env.REACT_APP_CAPTCHA_SITE_KEY as string, { action: 'submit' });
        const response = await uaxios.post<CreateAccountRespDto>(apiUrl, data, {
          headers: { authorization: val },
        });
        res(response.data);
      } catch (error) {
        reject(error);
      }
    })
  })
}

export async function resendAccountVerification(data: ResendVerificationReqDto): Promise<{ status: string, message: string }> {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `public/resend-account-verification`, false);
  return new Promise((res, reject) => {
    grecaptcha.ready(async function () {
      try {
        const val = await grecaptcha.execute(process.env.REACT_APP_CAPTCHA_SITE_KEY as string, { action: 'submit' });
        const response = await uaxios.post<{ status: string, message: string }>(apiUrl, data, {
          headers: { authorization: val },
        });
        res(response.data);
      } catch (error) {
        reject(error);
      }
    })
  })
}

export async function activateUser(data: UserActivationReqDto): Promise<UserActivationRespDto> {
  const apiUrl: string = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `public/activate`, false);
  return new Promise((res, reject) => {
    grecaptcha.ready(async function () {
      try {
        const val = await grecaptcha.execute(process.env.REACT_APP_CAPTCHA_SITE_KEY as string, { action: 'submit' });
        const response = await uaxios.post<UserActivationRespDto>(apiUrl, data, {
          headers: { authorization: val },
        });
        res(response.data);
      } catch (error) {
        reject(error);
      }
    })
  })
}

export async function forgotAndResetPassword(data: PasswordResetReqDto): Promise<{ status: string, message: string }> {
  const apiUrl: string = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `public/forgot-password`, false);
  return new Promise((res, reject) => {
    grecaptcha.ready(async function () {
      try {
        const val = await grecaptcha.execute(process.env.REACT_APP_CAPTCHA_SITE_KEY as string, { action: 'submit' });
        const response = await uaxios.post(apiUrl, data, {
          headers: { authorization: val },
        });
        res(response.data);
      } catch (error) {
        reject(error);
      }
    })
  })
}

export async function checkIfUserExists(qParams?: { email?: string }): Promise<CheckIfUserExistsRespDto> {
  const apiUrl: string = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `public/exists`, false);
  let params = {};
  if (qParams?.email) {
    Object.assign(params, { email: qParams.email });
  }
  return new Promise((res, reject) => {
    grecaptcha.ready(async function () {
      try {
        const val = await grecaptcha.execute(process.env.REACT_APP_CAPTCHA_SITE_KEY as string, { action: 'submit' });
        const response = await uaxios.get<CheckIfUserExistsRespDto>(apiUrl, {
          params: params,
          headers: { authorization: val },
        });
        res(response.data);
      } catch (error) {
        reject(error);
      }
    });
  });
}
