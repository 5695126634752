import styles from '../settings-panel.module.scss';
import { useEffect, useState } from "react";
import { Accordion } from "../../../common/accordion/accordion";
import CustomSelect from '../../../common/custom-select/custom-select';
import { HeaderSettingsProps } from './header-settings.props';
import { DropdownDataItem } from '../../../common/custom-select/custom-select.props';
import { SingleValue } from 'react-select';
import svgCloseBtn from "../../../../../assets/v2/icons/close-btn.svg";
import { useTranslation } from 'react-i18next';
import TextComponent from '../../../common/text-component/text-component';

export function HeaderSettings(props: HeaderSettingsProps) {
  const { t } = useTranslation();
  const [isHeaderSettingsOpen, setIsHeaderSettingsOpen] = useState(false);

  useEffect(() => {
    if (props.isActionPanelOpen === false) setIsHeaderSettingsOpen(false);
  }, [props.isActionPanelOpen]);

  return (
    <Accordion
      onAccordionClick={() => setIsHeaderSettingsOpen((prev) => !prev)}
      btn_content={t("header")}
      openState={isHeaderSettingsOpen}
    >
      <div className={styles.accordionContent}>
        <div className={styles.fontContainer}>
          <div className={styles.fontLayout}>
            <TextComponent textType='Header'>
              <div>{t("form_styles_header_font")}</div>
            </TextComponent>
            {
              props.updatePayload.config?.font?.fontSize &&
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img className={styles.fontClearBtn} src={svgCloseBtn} alt="close-btn" onClick={() => {
                  props.setUploadPayload((prev) => { return { ...prev, config: { ...prev.config, font: { ...prev.config?.font, fontSize: null } } } })
                  if (props.errors.size === 0) {
                    props.setIsValuesDirty(true);
                  }
                }} />
              </div>
            }
            <CustomSelect
              selectStyleType='fade'
              value={props.updatePayload.config?.font?.fontSize ? {
                label: props.updatePayload.config?.font?.fontSize,
                value: props.updatePayload.config?.font?.fontSize,
              } : undefined}
              data={props.fontSize.map(item => {
                const obj: DropdownDataItem = {
                  label: `${item[0].toLocaleUpperCase()}${item.slice(1)}`,
                  value: item
                }
                return obj;
              })}
              selectedData={(item) => {
                const value = item as SingleValue<DropdownDataItem>;
                props.setUploadPayload((prev) => { return { ...prev, config: { ...prev.config, font: { ...prev.config?.font, fontSize: value?.value } } } })
                if (props.errors.size === 0) {
                  props.setIsValuesDirty(true);
                }
              }}
              placeholder={`${t("font_size")}`}
              isClearable={true}
            />
          </div>
          <div className={styles.fontLayout}>
            <div>
              <TextComponent textType='Header'>
                <div>{t("form_styles_header_weight")}</div>
              </TextComponent>
              {
                props.updatePayload.config?.font?.fontWeight &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img className={styles.fontClearBtn} src={svgCloseBtn} alt="close-btn" onClick={() => {
                    props.setUploadPayload((prev) => { return { ...prev, config: { ...prev.config, font: { ...prev.config?.font, fontWeight: null } } } })
                    if (props.errors.size === 0) {
                      props.setIsValuesDirty(true);
                    }
                  }} />
                </div>
              }
            </div>
            <CustomSelect
              selectStyleType='fade'
              value={props.updatePayload.config?.font?.fontWeight ? {
                label: props.updatePayload.config?.font?.fontWeight,
                value: props.updatePayload.config?.font?.fontWeight,
              } : undefined}
              data={props.fontWeight.map(item => {
                const obj: DropdownDataItem = {
                  label: `${item[0].toLocaleUpperCase()}${item.slice(1)}`,
                  value: item
                }
                return obj;
              })}
              selectedData={(item) => {
                const value = item as SingleValue<DropdownDataItem>;
                props.setUploadPayload((prev) => { return { ...prev, config: { ...prev.config, font: { ...prev.config?.font, fontWeight: value?.value } } } })
                props.setIsValuesDirty(true);
              }}
              placeholder={`${t("font_weight")}`}
              isClearable={true}
            />
          </div>
        </div>
      </div>
    </Accordion>
  )
}
