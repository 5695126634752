import CustomText from '../custom-bodyText/custom-text';
import CustomButton from '../custom-button/custom-button';
import styles from './custom-mobile-pagination.module.scss';
import { CustomMobilePaginationProps } from './custom-mobile-pagination.props';
import svgRightArrowBlack from "../../../../assets/v2/icons/right-arrow-black.svg";

export default function CustomMobilePagination(props: CustomMobilePaginationProps) {
  return (
    <div className={styles.container}>
      <CustomButton
        style={{
          backgroundColor: "var(--highlight-color)",
        }}
        buttonType='reset'
        classType='primary'
        onClick={() => {
          if (props.responseId)
            props.getPreviousResponse(props.responseId)
        }}
      >
        <div className={styles.backImg}>
          <img style={{ display: 'flex' }} src={svgRightArrowBlack} alt="no" />
        </div>
      </CustomButton>
      <CustomText textType='t3' style={{ fontWeight: "500" }}>
        <div>{props.index + 1}</div>
      </CustomText>
      <CustomText textType='t3' style={{ fontWeight: "500" }}>
        <div>/</div>
      </CustomText>
      <CustomText textType='t3' style={{ fontWeight: "500" }}>
        <div>{props.count}</div>
      </CustomText>
      <CustomButton
        style={{
          backgroundColor: "#D3CCFF",
        }}
        buttonType='reset'
        classType='primary'
        onClick={() => {
          if (props.responseId)
            props.getNextResponse(props.responseId)
        }}
      >
        <div>
          <img style={{ display: 'flex' }} src={svgRightArrowBlack} alt="no" />
        </div>
      </CustomButton>

    </div>
  )
}
