import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../models/load-state.enum";
import { RolesResponseDtoItem } from "../models/roles.interface";
import { createRoleThunk, deleteRoleThunk, getRolesThunk, updateRoleThunk } from "../thunks/roles.thunk";

const rolesInitialState: {
  data: RolesResponseDtoItem[];
  loadingState: LoadState,
} = { data: [], loadingState: LoadState.IDLE };

export const rolesSlice = createSlice({
  name: "roles",
  initialState: rolesInitialState,
  reducers: {}, extraReducers(builder) {
    builder
      .addCase(getRolesThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(getRolesThunk.fulfilled, (state, action) => {
        state.data = action.payload.roles;
        state.loadingState = LoadState.IDLE;
      })
      .addCase(getRolesThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
      .addCase(createRoleThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(createRoleThunk.fulfilled, (state, action) => {
        const data = [...state.data];
        data.push(action.payload);
        state.data = data;
        state.loadingState = LoadState.IDLE;
      })
      .addCase(createRoleThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
      .addCase(updateRoleThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(updateRoleThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        for (let role of state.data) {
          if (role.name === payload.roleName) {
            role.description = payload.data.description as string;
            role.permissions = payload.data.permissions;
          }
        }
        state.loadingState = LoadState.IDLE;
      })
      .addCase(updateRoleThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
      .addCase(deleteRoleThunk.pending, (state, action) => {
        state.loadingState = LoadState.LOADING;
      })
      .addCase(deleteRoleThunk.fulfilled, (state, action) => {
        state.data = state.data.filter((value) => value.name !== action.payload);
        state.loadingState = LoadState.IDLE;
      })
      .addCase(deleteRoleThunk.rejected, (state, action) => {
        state.loadingState = LoadState.ERROR;
      })
  },
});

export default rolesSlice;
