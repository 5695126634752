import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import CustomInput from '../../../../components/v2/common/custom-input/custom-input';
import CustomSelect from '../../../../components/v2/common/custom-select/custom-select';
import { DropdownDataItem } from '../../../../components/v2/common/custom-select/custom-select.props';
import { CountryList } from '../../../../constants/mobile-country-code';
import { CompanyPopulation, IndustryTypes } from '../../user-onboarding/user-type/constants';
import styles from './add-organization-modal.module.scss'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CreateOrganizationReqDto } from '../../../../store/models/sign-up.interface';
import { createOrganizationThunk } from '../../../../store/thunks/organization.thunk';
import { RootDispatch } from '../../../../store/store';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import Modal from '../../../../components/v2/common/modal/modal';
import { useNavigate } from 'react-router-dom';
import svgChecklist from "../../../../assets/REPLACE-THESE/checklist.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgEmployee from "../../../../assets/REPLACE-THESE/employee.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgLocationMarker from "../../../../assets/REPLACE-THESE/location-marker.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgOfficeBuilding from "../../../../assets/REPLACE-THESE/office-building.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgPaneDelete from "../../../../assets/v2/close.svg";
import CharacterValidator from '../../../../util/character-validator';

export default function AddOrganizationModal(props: AddOrganizationModal) {
  const dispatch: RootDispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [companyData, setCompanyData] = useState<{
    displayName: string,
    orgName: string,
    country: string,
    industry: string,
    size: string
  }>({
    displayName: "",
    orgName: "",
    country: "",
    industry: "",
    size: ""
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orgWarningText, setOrgWarningText] = useState<string | undefined>(undefined);
  const [countryWarningText, setCountryWarningText] = useState<string | undefined>(undefined);
  const [industryWarningText, setIndustryWarningText] = useState<string | undefined>(undefined);
  const [sizeWarningText, setSizeWarningText] = useState<string | undefined>(undefined);
  const [addOrgError, setAddOrgError] = useState<string | undefined>(undefined);
  const reg = new RegExp(/^[A-Za-z0-9 ]+$/);

  useEffect(() => {
    if (!props.toggleAddOrg) {
      setCompanyData({
        displayName: "",
        orgName: "",
        country: "",
        industry: "",
        size: ""
      });
      setOrgWarningText('');
      setCountryWarningText('');
      setIndustryWarningText('')
      setSizeWarningText('');
      setAddOrgError('');
    }
  }, [props.toggleAddOrg]);

  async function addOrganization() {
    setIsLoading(true);
    setAddOrgError('');
    const orgData: CreateOrganizationReqDto = {
      orgDisplayName: companyData.displayName,
      industry: companyData.industry,
      country: companyData.country,
      size: companyData.size
    }
    try {
      const res = await dispatch(createOrganizationThunk(orgData)).unwrap();
      if (res) {
        setIsLoading(false);
        toast.success(`${t("org_created")}`, {
          className: styles.successToast
        });
        navigate(`/o/${res.name}/dashboard`);
        window.location.reload();
      }
    } catch (err) {
      // TODO: Capture error from response and show here.
      toast.error(t("org_create_error"));
      setAddOrgError(t("org_create_error"))
      setIsLoading(false);
    }
  }

  return (
    <>
      <Modal showModal={props.toggleAddOrg} setShowModal={props.setToggleAddOrg}
        header={
          <div className={styles.header}>
            <div className={styles.headerContainer}>
              <div style={{ fontWeight: '600' }}>
                {t("add_org")}
              </div>
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
              <CustomButton
                  style={{
                    background: "var(--accent-color)",
                    padding: "0.2rem",
                    borderRadius: '50%',
                    border: 'none',
                    boxSizing: "border-box",
                    display:'flex'
                  }}
                  buttonType='button'
                  classType='tertiary'
                  onClick={() => { props.setToggleAddOrg(false); }}>
                  <img style={{width:'1.2rem'}} src={svgPaneDelete} alt="" />
                </CustomButton>
              </div>
            </div>
          </div>
        }
        content={
          <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem', marginBottom: '2rem' }}>
              <div>{t("org_reload")}</div>
              <div className={styles.optionsContainer}>
                <img src={svgOfficeBuilding} alt="professional user" />
                <div style={{ width: "100%" }}>
                  <CustomInput
                    inputType='text'
                    inputStyleType='fade'
                    placeHolder={`${t('login_company_setup_company_name')}`}
                    style={{
                      width: '100%'
                    }}
                    value={companyData.displayName}
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      if (value.length < 1) {
                        setOrgWarningText(`${t('login_company_setup_company_name_error')}`);
                      } else {
                        const validation = CharacterValidator(value);
                        if (validation.length > 0) {
                          setOrgWarningText(validation);
                        } else if (reg.test(value)) {
                          setOrgWarningText('');
                          setAddOrgError('');
                        } else {
                          setOrgWarningText(`${t('company_setup_special_character_err')}`);
                        }
                      }
                      setCompanyData((prevState) => ({
                        ...prevState,
                        displayName: e.target.value,
                        orgName: value
                      }));
                    }}
                  />
                  {
                    orgWarningText && orgWarningText !== '' &&
                    <p className={styles.errorText}>{orgWarningText}</p>
                  }
                </div>
              </div>
              <div className={styles.optionsContainer}>
                <img src={svgLocationMarker} alt="location marker" />
                <CustomSelect
                  placeholder={`${t('login_company_setup_country_name')}`}
                  data={CountryList.map(item => {
                    const data: DropdownDataItem = {
                      label: item.name,
                      value: item.name
                    }
                    return data;
                  })}
                  selectStyleType={"colored"}
                  selectedData={(item) => {
                    if (item !== null) {
                      const res = (item as DropdownDataItem).value;
                      if (res.length < 1) {
                        setCountryWarningText(`${t('login_company_setup_country_name_error')}`);
                      } else {
                        setCountryWarningText('');
                        setAddOrgError('');
                      }
                      setCompanyData((prevState) => ({
                        ...prevState,
                        country: res
                      }));
                    }
                  }}
                />
                {
                  countryWarningText && countryWarningText !== '' &&
                  <p className={styles.errorText}>{countryWarningText}</p>
                }
              </div>
              <div className={styles.optionsContainer}>
                <img src={svgChecklist} alt="checklist" />
                <CustomSelect
                  placeholder={`${t("login_company_setup_industry")}`}
                  data={IndustryTypes.map(item => {
                    const data: DropdownDataItem = {
                      label: item,
                      value: item
                    }
                    return data;
                  })}
                  selectStyleType={"colored"}
                  selectedData={(item) => {
                    if (item !== null) {
                      const res = (item as DropdownDataItem).value;
                      if (res.length < 1) {
                        setIndustryWarningText(`${t("login_company_setup_industry_error")}`);
                      } else {
                        setIndustryWarningText('');
                        setAddOrgError('');
                      }
                      setCompanyData((prevState) => ({
                        ...prevState,
                        industry: res
                      }));
                    }
                  }}
                />
                {
                  industryWarningText && industryWarningText !== '' &&
                  <p className={styles.errorText}>{industryWarningText}</p>
                }
              </div>
              <div className={styles.optionsContainer} >
                <img src={svgEmployee} alt="employees" />
                <CustomSelect
                  placeholder={`${t("login_company_setup_company_size")}`}
                  selectStyleType={"colored"}
                  data={CompanyPopulation.map(item => {
                    const data: DropdownDataItem = {
                      label: item,
                      value: item
                    }
                    return data;
                  })}
                  selectedData={(item) => {
                    if (item !== null) {
                      const res = (item as DropdownDataItem).value;
                      if (res && res.length < 1) {
                        setSizeWarningText(`${t("login_company_setup_company_size_error")}`);
                      } else {
                        setSizeWarningText('');
                        setAddOrgError('');
                      }
                      setCompanyData((prevState) => ({
                        ...prevState,
                        size: res
                      }));
                    }
                  }}
                />
                {
                  sizeWarningText && sizeWarningText !== '' &&
                  <p className={styles.errorText}>{sizeWarningText}</p>
                }
              </div>
            </div>
            <div style={{ marginLeft: 'auto', marginBottom: "0.5rem", width: "10rem" }}>
              <CustomButton buttonType={'button'}
                classType={'quaternary'}
                disabled={(orgWarningText !== '' || countryWarningText !== '' || industryWarningText !== '' || sizeWarningText !== '') ? true : false}
                onClick={() => { addOrganization() }}
                isLoading={isLoading}>
                <span>{isLoading ? t("loading") : `${t("add_organization")}`}</span>
              </CustomButton>
            </div>
            {
              addOrgError && addOrgError !== '' &&
              <p style={{ textAlign: "end" }} className={styles.errorText}>{addOrgError}</p>
            }
          </>

        }
      />
    </>
  );
}
interface AddOrganizationModal {
  toggleAddOrg: boolean;
  setToggleAddOrg: Dispatch<SetStateAction<boolean>>;
}

