export enum SubscriptionPlanCode {
  TRANSLATION = 'TRANSLATION',
  RAFFLE = 'RAFFLE',
  BASIC = 'BASIC',
  PRO = 'PRO',
  USER = 'USER',
  RESPONSE = 'RESPONSE',
  STORAGE = 'STORAGE',
  SMS = 'SMS'
}
