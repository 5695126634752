import ToolTip from '../../../components/v2/common/tool-tip/tool-tip';
import CustomButton from '../../../components/v2/common/custom-button/custom-button';
import { useTranslation } from 'react-i18next';
import svgLoading from "../../../assets/v2/icons/loading.svg";
import svgRefresh from "../../../assets/v2/refresh.svg";

export default function SettingRefreshComponent(props: SettingRefreshComponentProps) {
  const { t } = useTranslation();
  return (
    <div>
      <ToolTip position='bottom' content={`${t("refresh")}`}>
        <CustomButton
          buttonType='button'
          classType='tertiary'
          style={{
            background: "var(--accent-color)",
            padding: " 8px",
            borderRadius: '50%',
            border: 'none',
            boxShadow: 'none'
          }}
          disabled={props.refreshPage}
          onClick={() => props.getLatestData()}
        >
          <div style={{ width: '1.2rem', height: '1.2rem' }}>
            {props.refreshPage ? <img src={svgLoading} alt='loading' /> : <img src={svgRefresh} alt='refresh' />}
          </div>
        </CustomButton>
      </ToolTip>
    </div>
  )
}

interface SettingRefreshComponentProps {
  getLatestData: () => Promise<void>;
  refreshPage: boolean;
}
