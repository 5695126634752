import styles from '../settings-panel.module.scss';
import { useEffect, useState } from "react";
import { Accordion } from '../../../common/accordion/accordion';
import { useTranslation } from 'react-i18next';
import { AuthenticatedFormProps } from './authenticated-form.props';

export function AuthenticationSettings(props: AuthenticatedFormProps) {
  const { t } = useTranslation();
  const [authenticationSettingsOpen, setIsAuthenticationSettingsOpen] = useState(false);

  useEffect(() => {
    if (props.isActionPanelOpen === false) setIsAuthenticationSettingsOpen(false);
  }, [props.isActionPanelOpen]);

  return (
    <Accordion
      onAccordionClick={() => setIsAuthenticationSettingsOpen((prev) => !prev)}
      btn_content={t("authentication")}
      openState={authenticationSettingsOpen}
    >
      <div className={styles.accordionContent}>
        <div className={styles.logoContainer}>
          <div>{t("enable_authentication")}</div>
          <div>
            <label className={styles.switch} id={'isAuthenticated'}>
              <input
                type={"checkbox"}
                checked={props.updatePayload.config?.isAuthenticated}
                onChange={(e) => {
                  props.setUploadPayload((prev) => {
                    return { ...prev, config: { ...prev.config, isAuthenticated: e.target.checked } }
                  });
                  props.setIsValuesDirty(true);
                }}
                id="isAuthenticated"
              />
              <span className={styles.slider}></span>
            </label>
          </div>
        </div>
      </div>
    </Accordion >
  )
}
