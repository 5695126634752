import React, { useState } from 'react';
import styles from './edit-user.module.scss';
import CustomText from '../../../../../components/v2/common/custom-bodyText/custom-text';
import CustomHeading from '../../../../../components/v2/common/custom-heading/custom-heading';
import CustomButton from '../../../../../components/v2/common/custom-button/custom-button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../../store/store';
import CustomSelect from '../../../../../components/v2/common/custom-select/custom-select';
import { DropdownDataItem } from '../../../../../components/v2/common/custom-select/custom-select.props';
import toast from 'react-hot-toast';
import { attachUserWithOrgRolesThunk } from '../../../../../store/thunks/organization.thunk';
import { MultiValue } from 'react-select';
import { GetOrgUserRespDto } from '../../../../../store/models/organization.interface';
import svgPaneDelete from "../../../../../assets/v2/close.svg";

export default function EditUser(props: EditUserDto) {
  const dispatch: RootDispatch = useDispatch();
  const roles = useSelector((state: RootState) => state.roles.data);
  const defaultSelectedRoles = props.userDetails.organizations[0].roleNames.map((r: string) => { return { label: r, value: r } });
  const [privateEmails, setPrivateEmails] = useState<string[]>([props.userDetails.email]);
  const [selectedRoles, setSelectedRoles] = useState<MultiValue<DropdownDataItem>>(defaultSelectedRoles);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [isDirtyFlag, setIsDirtyFlag] = useState(true)

  const validateRoles = (data: string[]) => {
    if (data.length === 0) {
      setError(t("role_error"));
      return false;
    }
    else setError("");
    return true;
  }

  const editUser = async () => {
    const selRoles = selectedRoles.map(r => r.value);
    const rolesValidation = validateRoles(selRoles);
    if (rolesValidation === false) {
      return;
    }
    setIsLoading(true);
    try {
      await dispatch(attachUserWithOrgRolesThunk({
        userId: props.userDetails.id, data: {
          roleNames: selRoles
        }
      })).unwrap();
      toast.success(t("user_edited"))
    } catch (err) {
      toast.error(t("user_edit_error"));
    }
    setIsLoading(false);
    props.setIsSidePaneOpen(false);
  }

  return (
    <div className={styles.sidepaneMain}>
      <div className={styles.topContainer}>
        <div className={styles.closeBtnContainer}>
          <div>
            <CustomHeading headerType='h3'>
              <div className={styles.textColor}>{t("edit_user_heading")}</div>
            </CustomHeading>
            <CustomText textType='t5'>
              <div className={styles.textColor}>{t("edit_user_subheading")}</div>
            </CustomText>
          </div>

          <div className={styles.mobileCloseBtn}>
            <button className={styles.closebtn} onClick={() => { props.setIsSidePaneOpen(false); }}>
              <img style={{width:'1rem'}} src={svgPaneDelete} alt="" />
            </button>
          </div>
        </div>
        <div className={styles.line}></div>
      </div>
      <div className={styles.inpuContainer}>
        <div>
          <CustomHeading headerType='h5'>
            <div className={styles.textColor}>{t("mail_ids")}</div>
          </CustomHeading>
          <div>            {
            privateEmails.length > 0 &&
            <div id={'private-emails-container'} className={styles.privateEmailsContainer}>
              {
                privateEmails.map((value, id) => {
                  return (
                    <div key={id} className={styles.emailsBubble}>
                      {value}
                    </div>
                  )
                })
              }
            </div>
          }
          </div>
        </div>
        <div>
          <CustomHeading headerType='h5'>
            <div className={styles.textColor}>{t("select_role")}</div>
          </CustomHeading>

          <CustomSelect
            data={roles.map((elem) => {
              let data: DropdownDataItem = {
                label: elem.name,
                value: elem.name
              }
              return data;
            })}
            isMultiSelect={true}
            selectStyleType={"colored"}
            defaultvalue={selectedRoles}
            selectedData={(value) => {
              if (value != null && Array.isArray(value)) {
                setSelectedRoles(value);
                setIsDirtyFlag(false);
                if (value.length) setError("");
              }
            }}
            placeholder={`${t("select_role")}`}
          />
        </div>
        <div className={styles.errorEmail}>{error}</div>
        <div className={styles.saveBtn}>
          <CustomButton
            buttonType='button'
            classType='quaternary'
            isLoading={isLoading}
            disabled={isDirtyFlag}
            onClick={editUser}>
            {
              isLoading ?
                <>{t("loading")}</>
                :
                <CustomHeading headerType='h4' style={{ color: "#ffff" }}>
                  <div className={styles.textColor}>{`${t("edit_user")}`}</div>
                </CustomHeading>
            }

          </CustomButton>
        </div>
        <div className={styles.mobileCloseBtn}>
          <CustomButton
            buttonType='button'
            classType='secondary'
            isLoading={isLoading}
            disabled={isDirtyFlag}
            onClick={editUser}>
            {
              isLoading ?
                <>{t("loading")}</>
                :
                <CustomHeading headerType='h4' style={{ color: "#000" }}>
                  <div>{`${t("edit_user")}`}</div>
                </CustomHeading>
            }

          </CustomButton>
        </div>
      </div>
    </div>
  )
}

interface EditUserDto {
  setIsSidePaneOpen: React.Dispatch<React.SetStateAction<boolean>>,
  userDetails: GetOrgUserRespDto
}

