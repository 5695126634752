import { useEffect, useRef, useState } from "react";
import { SingleValue } from "react-select";
import { blackListAccounts } from "../../../../../api/forms";
import CustomText from "../../../common/custom-bodyText/custom-text";
import CustomCheckbox from "../../../common/custom-checkbox/custom-checkbox";
import CustomHeading from "../../../common/custom-heading/custom-heading";
import CustomSelect from "../../../common/custom-select/custom-select";
import { DropdownDataItem } from "../../../common/custom-select/custom-select.props";
import { RafflePropertiesProps } from "./raffle-properties.props";
import styles from './raffle-properties.module.scss';
import { useTranslation } from "react-i18next";
import UseValidation from "../../../../../util/validator/use-validation";
import { toast } from "react-hot-toast";
import { AxiosError } from "axios";
import CustomTextArea from "../../../common/custom-text-area/custom-text-area";

export function RaffleProperties(props: RafflePropertiesProps) {
  const { t } = useTranslation();
  const isTouched = useRef(false);
  const { raffleModeBlackListValidation } = UseValidation();
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (props.value && props.value.config && props.value.config.customMessage && props.value.config.customMessage.message && props.value.config.customMessage.message.trim().length !== 0) {
      setIsDisabled(true);
    }
    else {
      props.setValue({ ...props.value, config: { ...props.value.config, customMessage: { ...props.value.config.customMessage, includeRaffleId: false } } });
      setIsDisabled(false);
    }
  }, [props.value && props.value.config && props.value.config.customMessage && props.value.config.customMessage.message])

  useEffect(() => {
    if (isTouched.current) {
      raffleModeBlackListValidation(props.value, props.blackListInput);
    }
  }, [props.blackListInput])

  const uploadBlackLists = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        setIsLoaderActive(true);
        const file = e.target.files[0];
        if (file.size > 1024 * 568) {
          toast.error(t("file_size_limit", { limit: "500Kb" }));
          return;
        }
        const res = await blackListAccounts(props.control.formId, file);
        const updatedContent = [...props.blackListInput.split(',').filter(val => val.length > 0), ...res.data.validEmails];
        props.setBlackListInput(updatedContent.join(','));
        setIsLoaderActive(false);
      }
    } catch (err) {
      if (err instanceof AxiosError && err.response?.data.message) {
        toast.error(err.response?.data.message);
      } else {
        console.log('error here', err);
      }
      setIsLoaderActive(false);
    }
  }

  const removeEmailByIndex = (index: number) => {
    const value = props.value;
    let emails: string[] = [...props.value.config.blackLists.email];
    emails.splice(index, 1);
    props.setIsDirty(true);
    props.setValue({ ...value, config: { ...value.config, blackLists: { ...value.config.blackLists, email: [...emails] } } });
  }

  const removeAllEmails = () => {
    const value = { ...props.value };
    props.setIsDirty(true);
    props.setValue({ ...value, config: { ...value.config, blackLists: { email: [], phone: [] } } });
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <CustomHeading headerType='h4'>
          <div className={styles.labelContainer}>
            <div>{t("collect_participant_info")}</div>
          </div>
        </CustomHeading>
        <CustomCheckbox
          data={`${props.control.id}-email`}
          labelText={`${t("email_id")}`}
          isInitialChecked={props.value.config.userInfo?.includes('email')}
          isLabelNormal
          onCheckChange={(data) => {
            if (!props.value.config.userInfo) return;
            let userInfo: string[] = [...props.value.config.userInfo];
            userInfo = userInfo.filter(val => val !== 'email');
            if (data.isChecked) {
              if (!props.value.config.userInfo.includes('email')) {
                props.setIsDirty(true);
              }
              props.setValue({ ...props.value, config: { ...props.value.config, userInfo: [...userInfo, 'email'] } });
            } else {
              if (props.value.config.userInfo.includes('email')) {
                props.setIsDirty(true);
              }
              props.setValue({ ...props.value, config: { ...props.value.config, userInfo: [...userInfo], workEmail: false } });
            }
          }}
        />
        <CustomCheckbox
          labelText={`${t("phone_num")}`}
          data={`${props.control.id}-phone`}
          isInitialChecked={props.value.config.userInfo?.includes('phone')}
          isLabelNormal
          onCheckChange={(data) => {
            if (!props.value.config.userInfo) return;
            let userInfo: string[] = [...props.value.config.userInfo];
            userInfo = userInfo.filter(val => val !== 'phone');
            if (data.isChecked) {
              if (!props.value.config.userInfo.includes('phone')) {
                props.setIsDirty(true);
              }
              props.setValue({ ...props.value, config: { ...props.value.config, userInfo: [...userInfo, 'phone'] } });
            } else {
              if (props.value.config.userInfo.includes('phone')) {
                props.setIsDirty(true);
              }
              props.setValue({ ...props.value, config: { ...props.value.config, userInfo: [...userInfo] } });
            }
          }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        {
          props.value.config.userInfo?.includes('email') &&
          <>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}><div>{t("email_id_setting")}</div></div>
            </CustomHeading>
            <CustomCheckbox
              data={`${props.control.id}-is-official-email`}
              labelText={`${t("allow_work_email")}`}
              isInitialChecked={props.value.config?.workEmail}
              disabled={!props.value.config.userInfo?.includes('email')}
              isLabelNormal
              onCheckChange={(data) => {
                props.setValue({ ...props.value, config: { ...props.value.config, workEmail: data.isChecked } });
                props.setIsDirty(true);
              }}
            />
          </>
        }
      </div>
      {
        props.control !== null && props.controlErrorMessage != null && props.controlErrorMessage[props.control.formId] != null && props.controlErrorMessage[props.control.formId][props.control.id] != null && props.controlErrorMessage[props.control.formId][props.control.id]["raffleParticipants"] != null
        &&
        <CustomText textType='t8' isErrorText={true}>{t((props.controlErrorMessage[props.control.formId][props.control.id]["raffleParticipants"]))} </CustomText>
      }
      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <CustomHeading headerType='h4'>
          <div className={styles.labelContainer}><div>{t("raffle_id_size")}</div></div>
        </CustomHeading>
        <CustomSelect
          data={[{ label: '4', value: '4' }, { label: '6', value: '6' }, { label: '8', value: '8' }, { label: '10', value: '10' }]}
          selectStyleType={'colored'}
          selectedData={(item) => {
            const val = item as SingleValue<DropdownDataItem>;
            props.setValue({ ...props.value, config: { ...props.value.config, raffleIdLength: val?.value } });
            props.setIsDirty(true);
          }}
          placeholder={`${t("select_raffle_id_size")}`}
          value={{ label: props.value.config.raffleIdLength, value: props.value.config.raffleIdLength }}
          isClearable
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <CustomHeading headerType='h4'>
          <div className={styles.labelContainer}><div>{t("notif_msg")}</div></div>
        </CustomHeading>
        <CustomText textType='t6' isErrorText={false} style={{ color: '#4a4a4a' }}>{t("raffle_notif_msg_heading")}</CustomText>
        <CustomText textType='t6' isErrorText={false} style={{ color: '#4a4a4a' }}>{t("raffle_notif_msg_ex")}</CustomText>
        <CustomTextArea value={props.value.config.customMessage?.message} getText={(e) => { props.setValue({ ...props.value, config: { ...props.value.config, customMessage: { ...props.value.config.customMessage, message: e } } }); props.setIsDirty(true); }} placeHolder={'Enter the message to be dispatched to the winners'} />
        <CustomText textType='t6' isErrorText={false} style={{ color: '#4a4a4a' }}>{t("raffle_custom_msg")}:</CustomText>
        <CustomCheckbox
          data={`${props.control.id}-include-raffle`}
          labelText={`${t("include_raffle_id")}`}
          isInitialChecked={props.value.config.customMessage?.includeRaffleId}
          disabled={!isDisabled}
          isLabelNormal
          onCheckChange={(data) => {
            props.setValue({ ...props.value, config: { ...props.value.config, customMessage: { ...props.value.config.customMessage, includeRaffleId: data.isChecked } } });
            props.setIsDirty(true);
          }}
        />
      </div>

      {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <CustomHeading headerType='h4'>
          <div className={styles.labelContainer}>
            <div>Blacklist emails</div>
          </div>
        </CustomHeading>
        <div>
          <div style={{ display: 'flex', fontSize: '12px' }}>
            <div className={styles.blacklistedEmailsTitle}>Blacklisted emails</div>
            {
              props.value.config.blackLists?.email.length > 0 &&
              <div className={styles.removeAllBlackLists} onClick={removeAllEmails}>Remove all</div>
            }
          </div>
          <div style={{ width: 'calc(100% - 5px)' }} className={`${styles.rows}`}>
            <div className={styles.primaryCol}>Email</div>
            <div className={styles.secondaryCol}>Action</div>
          </div>
          <div className={styles.table}>
            {
              props.value.config.blackLists?.email.length <= 0 &&
              <div className={`${styles.rows}`}>
                <div className={styles.emptyBlackListsPlaceholer}>You haven't blacklisted any email IDs.</div>
              </div>
            }
            {
              props.value.config.blackLists?.email.length > 0 &&
              props.value.config.blackLists.email.map((email: string, index: number) => {
                return (
                  <div key={index} className={`${styles.rows}`}>
                    <div className={styles.primaryCol}>{email}</div>
                    <div className={styles.secondaryCol}><div className={styles.removeBtn} onClick={() => removeEmailByIndex(index)} >Remove</div></div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div>
          <div style={{ fontSize: '12px', marginBottom: '0.5rem' }}>Enter email IDs seperated by comma (,) and click the save button, or you can upload a file&nbsp;
            <span>
              <label style={{ color: 'var(--primary-color)', textDecoration: 'underline' }} htmlFor={'blackListFile'}>here</label>
              <input onChange={uploadBlackLists} style={{ display: 'none' }} name={'blackListFile'} id={'blackListFile'} type={'file'} />
            </span>
            &nbsp;to prefill email IDs.
          </div>
          <div style={{ fontSize: '12px' }}><span style={{ fontWeight: '600' }}>NOTE:&nbsp;</span>Only .txt and .csv files are allowed. Files should only contain email IDs, each in a new line.</div>
        </div>
        <div style={{ display: 'flex', gap: '0.5rem', position: 'relative' }}>
          {isLoaderActive && <div className={styles.blacklistLoader}>Loading...</div>}
          <CustomTextArea
            getText={(value) => {
              props.setBlackListInput(value);
              props.setIsDirty(true);
              if (isTouched.current) raffleModeBlackListValidation(props.value, props.blackListInput);
            }}
            onBlur={(value) => { isTouched.current = true; raffleModeBlackListValidation(props.value, props.blackListInput); }}
            value={props.blackListInput} placeHolder={'Enter email IDs to blacklist seperated by a comma'}></CustomTextArea>
        </div>
        {
          props.control !== null && props.controlErrorMessage != null && props.controlErrorMessage[props.control.formId] != null && props.controlErrorMessage[props.control.formId][props.control.id] != null && props.controlErrorMessage[props.control.formId][props.control.id]["raffleBlackListEmails"] != null
          &&
          <CustomText textType='t8' isErrorText={true}>One or more email(s) entered is invalid. Please correct them</CustomText>
        }
      </div> */}
    </>
  );
}
