import { createSlice } from "@reduxjs/toolkit";
import { DeleteFormControlReq } from "../models/form-controls/delete-form-control";
import { StoreFormControls, } from "../models/form-controls/form-control.interface";
import { UpdateFormControlDto } from "../models/form-controls/update-form-control";
import { LoadState } from "../models/load-state.enum";
import { TextStyle } from "../models/text-style.enum";
import { RootState } from "../store";
import { controlReorderThunk, createControlThunk, deleteControlThunk, getControlByFormThunk, updateControlThunk, updateQuizControlThunk, } from "../thunks/controls.thunk";

const controlInitialState: {
  data: StoreFormControls;
  fetchLoadingState: LoadState;
  createLoadingState: LoadState;
  updateLoadingState: LoadState;
  style?: TextStyle;
} = {
  data: {},
  fetchLoadingState: LoadState.IDLE,
  createLoadingState: LoadState.IDLE,
  updateLoadingState: LoadState.IDLE,
};

export const controlSlice = createSlice({
  name: "control",
  initialState: controlInitialState,
  reducers: {
    setOpenEditorControl(state, action) {
      if (state.data[action.payload.formId][action.payload.id])
        state.data[action.payload.formId][action.payload.id] = { ...state.data[action.payload.formId][action.payload.id], openEditor: true };
    },
    setEmpty(state,action) {
      state.data[action.payload.formId] = [];
    },
    resetCtrl(state, action) {
      state.data[action.payload.formId] = [...action.payload.ctrl];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createControlThunk.pending, (state, action) => {
        state.createLoadingState = LoadState.IDLE;
      })
      .addCase(createControlThunk.fulfilled, (state, action) => {
        let res = action.payload;
        let data = state.data[res.data.formId] ? state.data[res.data.formId] : [];
        if (res.parentType === null) {
          data.splice(res.controlIndex, 0, res.data);
          data = data.map((val, index) => { return { ...val, order: index } });
        }
        else if (res.parentType === "page" && res.parentIndex !== undefined) {
          data[res.parentIndex].children.splice(res.controlIndex, 0, res.data);
          data[res.parentIndex].children = data[res.parentIndex].children.map((val, index) => { return { ...val, order: index } });
        } else if (res.parentType === "section" && res.grandParentIndex !== undefined && res.parentIndex !== undefined) {
          data[res.grandParentIndex].children[res.parentIndex].children.splice(res.controlIndex, 0, res.data);
          data[res.grandParentIndex].children[res.parentIndex].children = data[res.grandParentIndex].children[res.parentIndex].children.map((val, index) => { return { ...val, order: index } });
        }
        state.data[res.data.formId] = [...data];
        state.createLoadingState = LoadState.IDLE;
      })
      .addCase(createControlThunk.rejected, (state) => {
        state.createLoadingState = LoadState.ERROR;
      })
      .addCase(updateControlThunk.pending, (state) => {
        state.updateLoadingState = LoadState.LOADING;
      })
      .addCase(updateControlThunk.fulfilled, (state, action) => {
        const payload: UpdateFormControlDto = action.payload;
        const data = state.data[payload.formId];

        if (payload.parentType === null) {
          const control = data[payload.controlIndex];
          if (payload.config) control.config = payload.config;
          if (payload.controlType) control.controlType = payload.controlType;
          if (payload.isReadOnly) control.isReadOnly = payload.isReadOnly;
          if (payload.label) control.label = payload.label;
          if (payload.labelHTML) control.labelHTML = payload.labelHTML;
          if (payload.options) control.options = payload.options;
          if (payload.order) control.order = payload.order;
          if (payload.parentId) control.parentId = payload.parentId;
          data[payload.controlIndex] = { ...control };
        }
        else if (payload.parentType === "page" && payload.parentIndex !== undefined) {
          const control = data[payload.parentIndex].children[payload.controlIndex];
          const res = { ...control }
          if (payload.config) res.config = payload.config;
          if (payload.controlType) res.controlType = payload.controlType;
          if (payload.isReadOnly) res.isReadOnly = payload.isReadOnly;
          if (payload.label) res.label = payload.label;
          if (payload.labelHTML) res.labelHTML = payload.labelHTML;
          if (payload.options) res.options = payload.options;
          if (payload.order) res.order = payload.order;
          if (payload.parentId) res.parentId = payload.parentId;
          data[payload.parentIndex].children[payload.controlIndex] = { ...res };
          state.data[payload.formId] = [...data];
        } else if (payload.parentType === "section" && payload.grandParentIndex !== undefined && payload.parentIndex !== undefined) {
          const control = data[payload.grandParentIndex].children[payload.parentIndex].children[payload.controlIndex];
          if (payload.config) control.config = payload.config;
          if (payload.controlType) control.controlType = payload.controlType;
          if (payload.isReadOnly) control.isReadOnly = payload.isReadOnly;
          if (payload.label) control.label = payload.label;
          if (payload.labelHTML) control.labelHTML = payload.labelHTML;
          if (payload.options) control.options = payload.options;
          if (payload.order) control.order = payload.order;
          if (payload.parentId) control.parentId = payload.parentId;
          data[payload.grandParentIndex].children[payload.parentIndex].children[payload.controlIndex] = { ...control };
        }
        state.data[payload.formId] = [...data];
      })
      .addCase(updateControlThunk.rejected, (state) => {
        state.updateLoadingState = LoadState.ERROR;
      })
      .addCase(deleteControlThunk.fulfilled, (state, action) => {
        const payload: DeleteFormControlReq = action.payload;
        const data = state.data[payload.formId] ? state.data[payload.formId] : [];
        if (payload.parentType === null) {
          data.splice(payload.controlIndex, 1);
        }
        else if (payload.parentType === "page" && payload.parentIndex !== undefined) {
          data[payload.parentIndex].children.splice(payload.controlIndex, 1);
          data[payload.parentIndex].children = data[payload.parentIndex].children.map((value, index) => { return { ...value, order: index } });
        } else if (payload.parentType === "section" && payload.grandParentIndex !== undefined && payload.parentIndex !== undefined) {
          data[payload.grandParentIndex].children[payload.parentIndex].children.splice(payload.controlIndex, 1);
          data[payload.grandParentIndex].children[payload.parentIndex].children = data[payload.grandParentIndex].children[payload.parentIndex].children.map((value, index) => { return { ...value, order: index } });
        }
        state.data[payload.formId] = [...data];
      })
      .addCase(getControlByFormThunk.fulfilled, (state, action) => {
        const formId = action.meta.arg;
        if (action.payload && action.payload.length > 0) {
          state.data[formId] = [...action.payload];
        }
        state.createLoadingState = LoadState.IDLE;
      })
      .addCase(controlReorderThunk.fulfilled, (state, action) => {
        const formId = action.meta.arg.payload.formId;
        if (action.payload && action.payload.length > 0) {
          state.data[formId] = [...action.payload];
        }
        state.createLoadingState = LoadState.IDLE;
      })
      .addCase(updateQuizControlThunk.fulfilled, (state, action) => {
        let payload = action.payload;
        for (let element = 0; element < state.data[payload.formId].length; element++) {
          if (state.data[payload.formId][element].controlType === 'quiz') {
            state.data[payload.formId][element].config = {
              ...action.payload.config
            }
          }
          else if (state.data[payload.formId][element].children.length > 0) {
            for (let elem = 0; elem < state.data[payload.formId][element].children.length; elem++) {
              if (state.data[payload.formId][element].children[elem].controlType === 'quiz') {
                state.data[payload.formId][element].children[elem].config = {
                  ...action.payload.config
                }
              }
              else if (state.data[payload.formId][element].children[elem].children.length > 0) {
                for (let e = 0; e < state.data[payload.formId][element].children[elem].children.length; e++) {
                  if (state.data[payload.formId][element].children[elem].children[e].controlType === 'quiz') {
                    state.data[payload.formId][element].children[elem].children[e].config = {
                      ...action.payload.config
                    }
                  }
                }
              }
            }
          }
        }
      })
      .addCase(updateQuizControlThunk.rejected, (state, action) => {
        state.updateLoadingState = LoadState.ERROR;
      });
  },
});

export const getFormControls = (state: RootState, formId: string) => {
  return state.formControls.data[formId];
};

export const { setOpenEditorControl, resetCtrl, setEmpty } = controlSlice.actions;
