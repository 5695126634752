import { ConditionalEdge } from '../../../../../store/models/form-builder/conditional-logic';
import { LogicControl } from '../logic-builder/models/logic-control.props';
import { VisualizerPanel } from '../react-flow/react-flow';
import styles from './logic-visualizer.module.scss';

export interface LogicVisualizerProps {
  controls: LogicControl[];
  conditionalLogic: ConditionalEdge[];
}

export function LogicVisualizer(props: LogicVisualizerProps) {
  return (
    <div className={styles.container}>
      <VisualizerPanel control={props.controls} conditionalLogic={props.conditionalLogic}></VisualizerPanel>
    </div>
  )
}
