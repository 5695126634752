import { createSlice } from '@reduxjs/toolkit';
import { ValidationDto } from '../models/form-controls/form-control.interface';
import { RootState } from '../store';

const initialState: {
  controlValidation: ValidationDto;
} = { controlValidation: {} };

const validationSlice = createSlice({
  name: 'validation',
  initialState: initialState,
  reducers: {
    addMessage(state, action) {
      const data = action.payload;
      const formId = data.formId as keyof object;
      const controlId = data.controlId as keyof object;
      if (state.controlValidation[formId] != null && state.controlValidation[formId][controlId] != null) {
        state.controlValidation[formId] = {
          ...state.controlValidation[formId],
          [controlId]: {
            ...state.controlValidation[formId][controlId],
            [data.property]: data.message,
          },
        };
      } else {
        state.controlValidation[formId] = {
          ...state.controlValidation[formId],
          [controlId]: {
            [data.property]: data.message,
          },
        };
      }
    },
    removeControl(state, action) {
      const data = action.payload;
      const formId = data.formId as keyof object;
      const controlId = data.controlId as keyof object;
      const key = data.key as keyof object;
      if (state.controlValidation[formId] != null && state.controlValidation[formId][controlId] != null && state.controlValidation[formId][controlId][key] != null) {
        delete state.controlValidation[formId][controlId][key];
        if (Object.keys(state.controlValidation[formId][controlId]).length === 0) {
          delete state.controlValidation[formId][controlId];
        }
      }
    },
    deleteValidatedControl(state, action) {
      const data = action.payload;
      const formId = data.formId as keyof object;
      const controlId = data.controlId as keyof object;
      if (state.controlValidation[formId] != null && state.controlValidation[formId][controlId] != null) {
        delete state.controlValidation[formId][controlId];
      }
    }
  },
});

export const getControlValidation = ((state: RootState, formId: string, controlId: string) => {
  if (state.validation.controlValidation[formId] != null && state.validation.controlValidation[formId][controlId] != null) { return state.validation.controlValidation[formId][controlId]; }

  return {}
})

export const { addMessage, removeControl, deleteValidatedControl } = validationSlice.actions;
export default validationSlice;
