import React from 'react';
import styles from './secondary-top-bar.module.scss';

export default function SecondaryTopBar(props:SecondaryTopBarProps) {
  return (
    <div className={styles.main}>
      {props.children}
    </div>
  )
}


interface SecondaryTopBarProps{
  children: React.ReactNode;
}
