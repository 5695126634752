import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './response-details.module.scss';
import CustomText from '../../../../components/v2/common/custom-bodyText/custom-text';
import { GetFormResponseItemDto } from '../../../../store/models/form-response.interface';
import { CreateFormControlResDto, FormControl } from '../../../../store/models/form-controls/form-control.interface';
import FileControl, { FormFileControlConfig } from '../../../../components/form-response/controls/file-control/file-control';
import { GetTimeTrackResDto } from '../../../../api/time-track';
import { useTranslation } from 'react-i18next';
import { blackListRaffleIds } from '../../../../api/form-controls';
import { BlackListAction } from '../../../../store/models/form-controls/blacklist-raffle';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import svgCorrect from "../../../../assets/v2/icons/correct.svg";
import svgWrong from "../../../../assets/v2/icons/wrong.svg";
import TextComponent from '../../../../components/v2/common/text-component/text-component';

export default function ResponseView(props: ResponseViewProps) {
  const { t } = useTranslation();
  const { formId } = useParams();
  const [raffleCtrl, setRaffleCtrl] = useState<FormControl>();

  useEffect(() => {
    setRaffleCtrl(searchRaffleCtrl());
  }, [props.formControls]);

  const blackListRaffleId = async (responseId: string, raffleId?: string, action?: BlackListAction) => {
    if (raffleId && action && formId) {
      const raffleCtrl = searchRaffleCtrl();
      if (!raffleCtrl) {
        toast.error(t("raffled_disabled"));
        return;
      }
      try {
        await blackListRaffleIds(raffleCtrl.id, { action: action, raffleId: raffleId, formId: formId, responseId: responseId });
        const updatedRes = {
          ...props.activeResponse,
          metadata: {
            ...props.activeResponse.metadata,
            raffle: {
              ...props.activeResponse.metadata?.raffle,
              isEligible: action === BlackListAction.ADD ? false : true,
            }
          }
        }
        props.setActiveResponse(updatedRes);
      } catch (err) {
        toast.error(t("blacklist_fail"));
      }
    }
  }

  const searchRaffleCtrl = () => {
    let isFound = false;
    let raffleCtrl: FormControl | undefined;
    for (const page of props.formControls) {
      for (const ctrl of page.children) {
        if (ctrl.controlType === 'raffle') {
          raffleCtrl = ctrl;
          isFound = true;
          break;
        }
      }
      if (isFound === true) {
        break;
      }
    }
    return raffleCtrl;
  }

  return (
    <div className={styles.formSection}>
      {
        <div className={styles.quizRaffleContainer}>
          {/* quiz control */}
          {
            props.activeResponse && props.activeResponse.score !== undefined && props.quizControl &&
            <div className={styles.scoreSection}>
              <TextComponent textType='Content'>
                <div>
                  Score:  <span style={{ fontWeight: "500", color: "#642DFB" }}>{props.activeResponse.score}/{props.quizControl.config.totalScore}</span>
                </div>
              </TextComponent>
            </div>
          }
          {
            props.activeResponse && props.activeResponse.raffleId &&
            <>
              <div className={styles.quizSection}>
                <div>
                </div>
                <div style={{ display: 'flex', gap: '0.5rem', alignItems: "end" }}>
                  <TextComponent textType='Content'>
                    <div>
                      {t("raffle_id")}:  <span style={{ fontWeight: "500", color: "var(--secondary-color)" }}>{props.activeResponse.raffleId}</span>
                    </div>
                  </TextComponent>
                  {
                    raffleCtrl && raffleCtrl.config?.winners?.find((val: Record<string, any>) => val.metadata.raffleId === props.activeResponse.raffleId)
                      ? <div className={styles.raffleWinnerBtn}>{t("winner")}</div>
                      :
                      props.activeResponse.metadata && props.activeResponse.metadata?.raffle?.isEligible === true
                        ? <div className={styles.markEligibleLink} onClick={() => { blackListRaffleId(props.activeResponse.id, props.activeResponse.raffleId, BlackListAction.ADD) }}>{t("eligible")}</div>
                        : <div className={styles.markInEligibleLink} onClick={() => { blackListRaffleId(props.activeResponse.id, props.activeResponse.raffleId, BlackListAction.REMOVE) }}>{t("ineligible")}</div>
                  }
                </div>
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                </div>
              </div>
            </>
          }
        </div>
      }
      {
        props.activeResponse && props.formControls.map((elem) => {
          return (
            elem.children.length > 0 && elem.children.map((a) => {
              return (
                a.children.length > 0 ?
                  (
                    a.children.map((i) => {
                      return (
                        props.activeResponse?.responseData.map((e) => {
                          let raffleIdT = '';
                          let quizIdT = '';
                          if (i.controlType === 'raffle') {
                            if (e.control_id.includes('-raffle-email')) {
                              const res = e.control_id.match(/.+?(?=-raffle-email)/);
                              raffleIdT = res[0];
                            }
                            if (e.control_id.includes('-raffle-phone') && !e.control_id.includes('-raffle-phone-code')) {
                              const res = e.control_id.match(/.+?(?=-raffle-phone)/);
                              raffleIdT = res[0];
                            }
                          }
                          if (i.controlType === 'quiz') {
                            if (e.control_id.includes('email')) {
                              const res = e.control_id.match(/.+?(?=-email)/);
                              quizIdT = res[0];
                            }
                          }
                          if (i.id === e.control_id || i.id === raffleIdT || i.id === quizIdT) {
                            const controlId = e.control_id;
                            if (i.controlType !== "file" && i.controlType !== "checkbox") {
                              return (
                                <div className={styles.formQuestionAnswerRow} key={'form-question-answer-row'}>
                                  <div className={styles.question}>
                                    <TextComponent textType='Content'>
                                      <div style={{ fontWeight: "500", color: "#828282" }}>
                                        {
                                          i.controlType !== 'email' && i.controlType !== 'mobile number' &&
                                          i.label
                                        }
                                        {
                                          i.controlType === 'email' &&
                                          <>{t("raffle_email")}</>
                                        }
                                        {
                                          i.controlType === 'quiz' && e.control_id.includes('-email') &&
                                          <>{t("quiz_email")}</>
                                        }
                                        {
                                          i.controlType === 'mobile number' &&
                                          <>{t("raffle_phone")}</>
                                        }
                                      </div>
                                    </TextComponent>
                                  </div>
                                  <div>
                                    {props.timeTrackInfo?.controls[controlId] &&
                                      <div className={styles.clockCont}>
                                        <div className={styles.clock}>{t("time_take")}: </div>
                                        <div>
                                          {`${Math.floor(props.timeTrackInfo.controls[controlId].duration / 1000) < 0 ? 0 : Math.floor(props.timeTrackInfo.controls[controlId].duration / 1000)}s`}
                                        </div>
                                      </div>
                                    }
                                  </div>
                                  <div className={styles.answer}>
                                    <TextComponent textType='Content'>
                                    <div style={e.response ? { fontWeight: "600", color: "#555" } : { fontWeight: "500", color: "#828282" }}>
                                      {
                                        e.response
                                          ?
                                          <>
                                            {
                                              i.controlType === 'mobile number'
                                                ? <>{e.response.countryCode}-{e.response.mobileNumber}</>
                                                : e.response
                                            }
                                          </>
                                          : `${t("no_resp")}`
                                      }
                                    </div>
                                    </TextComponent>
                                    {
                                      i?.config?.quiz?.correctAnswer && i.config.quiz.correctAnswer.length > 0 && e.response && i.config.quiz.correctAnswer.includes(e.response) && <span className={styles.answerImage}><img src={svgCorrect} alt="" /></span>
                                    }
                                    {
                                      i?.config?.quiz?.correctAnswer && i.config.quiz.correctAnswer.length > 0 && e.response && !i.config.quiz.correctAnswer.includes(e.response) && <span className={styles.answerImage}><img src={svgWrong} alt="" /></span>
                                    }
                                  </div>
                                </div>
                              )
                            }
                            if (i.controlType === "checkbox") {
                              return (
                                <div className={styles.formQuestionAnswerRow} key={'form-question-answer-row-checkbox'}>
                                  <div className={styles.question}>
                                    <TextComponent textType='Content'>
                                      <div style={{ fontWeight: "500", color: "#828282" }}>
                                        {i.label}
                                      </div>
                                    </TextComponent>
                                  </div>
                                  <div>
                                    {props.timeTrackInfo && props.timeTrackInfo.controls[controlId] &&
                                      <div className={styles.clockCont}>
                                        <div className={styles.clock}> {t("time_take")}: </div>
                                        <div>
                                          {`${Math.floor(props.timeTrackInfo.controls[controlId].duration / 1000) < 0 ? 0 : Math.floor(props.timeTrackInfo.controls[controlId].duration / 1000)}s`}
                                        </div>
                                      </div>
                                    }
                                  </div>
                                  <div className={styles.answer}>
                                    <TextComponent textType='Content'>
                                      <div style={e.response ? { fontWeight: "600" } : { fontWeight: "500", color: "#828282" }}>
                                        {e.response ? e.response.join(",") : `${t("no_resp")}`}
                                      </div>
                                    </TextComponent>
                                    {
                                      i?.config?.quiz?.correctAnswer && i.config.quiz.correctAnswer.length > 0 && e.response && props.areEqual(i.config.quiz.correctAnswer, e.response) && <span className={styles.answerImage}><img src={svgCorrect} alt="" /></span>
                                    }
                                    {
                                      i?.config?.quiz?.correctAnswer && i.config.quiz.correctAnswer.length > 0 && e.response && !props.areEqual(i.config.quiz.correctAnswer, e.response) && <span className={styles.answerImage}><img src={svgWrong} alt="" /></span>
                                    }
                                  </div>
                                </div>
                              )
                            }
                            if (i.controlType === "file") {
                              if (e.response) props.setHasFile(true);
                              else props.setHasFile(false);
                              return (
                                <div className={styles.formQuestionAnswerRow} key={'form-question-answer-row-file'}>
                                  <div className={styles.question}>
                                    <TextComponent textType='Content'>
                                      <div style={{ fontWeight: "500", color: "#828282" }}>
                                        {i.label}
                                      </div>
                                    </TextComponent>
                                  </div>
                                  <div>
                                    {props.timeTrackInfo && props.timeTrackInfo.controls[controlId] &&
                                      <div className={styles.clockCont}>
                                        <div className={styles.clock}>{t("time_take")}: </div>
                                        <div>
                                          {`${Math.floor(props.timeTrackInfo.controls[controlId].duration / 1000) < 0 ? 0 : Math.floor(props.timeTrackInfo.controls[controlId].duration / 1000)}s`}
                                        </div>
                                      </div>
                                    }
                                  </div>
                                  <div className={styles.answer}>
                                    <TextComponent textType='Content'>
                                      <div>
                                        {e.response ? <FileControl value={e.response}/> : t('form_responses_details_no_files')}
                                      </div>
                                    </TextComponent>
                                  </div>
                                </div>
                              )
                            }
                          }
                        })
                      )
                    })
                  ) :
                  props.activeResponse?.responseData.map((e, index) => {
                    let raffleId = '';
                    let quizId = '';
                    if (a.controlType === 'raffle') {
                      if (e.control_id.includes('-raffle-email')) {
                        const res = e.control_id.match(/.+?(?=-raffle-email)/);
                        raffleId = res[0];
                      }
                      if (e.control_id.includes('-raffle-phone') && !e.control_id.includes('-raffle-phone-code')) {
                        const res = e.control_id.match(/.+?(?=-raffle-phone)/);
                        raffleId = res[0];
                      }
                    }
                    if (a.controlType === 'quiz') {
                      if (e.control_id.includes('email')) {
                        const res = e.control_id.match(/.+?(?=-email)/);
                        quizId = res[0];
                      }
                    }
                    if (a.id === e.control_id || a.id === raffleId || a.id === quizId) {
                      const controlId = e.control_id;
                      if (a.controlType !== "file" && a.controlType !== "checkbox") {
                        return (
                          <div className={styles.formQuestionAnswerRow} key={'form-question-answer-row-2'}>
                            <div className={styles.question}>
                              <TextComponent textType='Content'>
                                <div style={{ fontWeight: "500", color: "#828282" }}>
                                  {/* <span>{index + 1}. </span> */}
                                  {a.label}
                                  {
                                    a.controlType === 'raffle' && e.control_id.includes('-raffle-email') &&
                                    <>{t("raffle_email")}</>
                                  }
                                  {
                                    a.controlType === 'quiz' && e.control_id.includes('email') &&
                                    <>{t("quiz_email")}</>
                                  }
                                  {
                                    a.controlType === 'raffle' && e.control_id.includes('-raffle-phone') && !e.control_id.includes('-raffle-phone-code') &&
                                    <>{t("raffle_phone")}</>
                                  }
                                </div>
                              </TextComponent>
                            </div>
                            <div>
                              {props.timeTrackInfo && props.timeTrackInfo.controls[controlId] &&
                                <div className={styles.clockCont}>
                                  <div className={styles.clock}>{t("time_take")}: </div>
                                  <div>
                                    {`${Math.floor(props.timeTrackInfo.controls[controlId].duration / 1000) < 0 ? 0 : Math.floor(props.timeTrackInfo.controls[controlId].duration / 1000)}s`}
                                  </div>
                                </div>
                              }
                            </div>
                            <div className={styles.answer}>
                              <TextComponent textType='Content'>
                                <div style={e.response ? { fontWeight: "600", color: "#555" } : { fontWeight: "500", color: "#828282" }}>
                                  {e.response ? e.response : `${t("no_resp")}`}
                                </div>
                              </TextComponent>
                              {
                                a?.config?.quiz?.correctAnswer && a.config.quiz.correctAnswer.length > 0 && e.response && a.config.quiz.correctAnswer.includes(e.response) && <span className={styles.answerImage}><img src={svgCorrect} alt="" /></span>}
                              {
                                a?.config?.quiz?.correctAnswer && a.config.quiz.correctAnswer.length > 0 && e.response && !a.config.quiz.correctAnswer.includes(e.response) && <span className={styles.answerImage}><img src={svgWrong} alt="" /></span>
                              }

                            </div>

                          </div>
                        )
                      }
                      if (a.controlType === "checkbox") {
                        return (
                          <div className={styles.formQuestionAnswerRow} key={'form-question-answer-row-checkbox-2'}>
                            <div className={styles.question}>
                              <TextComponent textType='Content'>
                                <div style={{ fontWeight: "500", color: "#828282" }}>
                                  {a.label}
                                </div>
                              </TextComponent>
                            </div>
                            <div>
                              {props.timeTrackInfo && props.timeTrackInfo.controls[controlId] &&
                                <div className={styles.clockCont}>
                                  <div className={styles.clock}>{t("time_take")} : </div>
                                  <div>
                                    {`${Math.floor(props.timeTrackInfo.controls[controlId].duration / 1000) < 0 ? 0 : Math.floor(props.timeTrackInfo.controls[controlId].duration / 1000)}s`}
                                  </div>
                                </div>
                              }
                            </div>
                            <div className={styles.answer}>
                              <TextComponent textType='Content'>
                                <div style={e.response ? { fontWeight: "600" } : { fontWeight: "500", color: "#828282" }}>
                                  {e.response ? e.response.join(",") : `${t("no_resp")}`}
                                </div>
                              </TextComponent>
                              {
                                a?.config?.quiz?.correctAnswer && a.config.quiz.correctAnswer.length > 0 && e.response && props.areEqual(a.config.quiz.correctAnswer, e.response) && <span className={styles.answerImage}><img src={svgCorrect} alt="" /></span>}
                              {
                                a?.config?.quiz?.correctAnswer && a.config.quiz.correctAnswer.length > 0 && e.response && !props.areEqual(a.config.quiz.correctAnswer, e.response) && <span className={styles.answerImage}><img src={svgWrong} alt="" /></span>
                              }
                            </div>
                          </div>
                        )
                      }
                      if (a.controlType === "file") {
                        if (e.response) props.setHasFile(true);
                        else props.setHasFile(false);
                        return (
                          <div className={styles.formQuestionAnswerRow} key={'form-question-answer-row-file'}>
                            <div className={styles.question}>
                              <TextComponent textType='Content'>
                                <div style={{ fontWeight: "500", color: "#828282" }}>
                                  {a.label}
                                </div>
                              </TextComponent>
                            </div>
                            <div>
                              {props.timeTrackInfo && props.timeTrackInfo.controls[controlId] &&
                                <div className={styles.clockCont}>
                                  <div className={styles.clock}>{t("time_take")}: </div>
                                  <div>
                                    {`${Math.floor(props.timeTrackInfo.controls[controlId].duration / 1000) < 0 ? 0 : Math.floor(props.timeTrackInfo.controls[controlId].duration / 1000)}s`}
                                  </div>
                                </div>
                              }
                            </div>
                            <div className={styles.answer}>
                              <TextComponent textType='Content'>
                                <div style={{color: "#a5a5a5" }}>
                                  {e.response ? <FileControl value={e.response} /> : t('form_responses_details_no_files')}
                                </div>
                              </TextComponent>
                            </div>
                          </div>
                        )
                      }

                    }
                  })
              )
            })
          )
        })}
    </div>
  )
}


interface ResponseViewProps {
  activeResponse: GetFormResponseItemDto;
  setActiveResponse: Dispatch<SetStateAction<GetFormResponseItemDto | undefined>>;
  quizControl: FormControl | undefined;
  formControls: CreateFormControlResDto[];
  timeTrackInfo: GetTimeTrackResDto | undefined;
  areEqual: (arr1: Array<string>, arr2: Array<string>) => boolean;
  setHasFile: Dispatch<SetStateAction<boolean>>
}
