import { useState } from "react";
import styles from "./star-rating.module.scss";
import svgStarCheck from "../../assets/star-check.svg";
import svgStarHover from "../../assets/star-hover.svg";
import svgStartUncheck from "../../assets/star-uncheck.svg";

export interface StarRatingProps {
  rating: number,
  setRating: (rating: number) => void
}

export function StarRating(props: StarRatingProps) {
  const [hover, setHover] = useState(0);

  return (
    <div>
      {[...Array(5)].map((value, index) => {
        return (
          <span
            key={index}
            className={styles.starSpan}
            onMouseEnter={() => {
              setHover(index + 1);
            }}
            onMouseLeave={() => {
              setHover(0);
            }}
            onClick={() => {
              if (props.rating - 1 === index) props.setRating(0);
              else props.setRating(index + 1);
            }}
          >
            {
              index >= hover
                ?
                <>
                  {
                    (index >= props.rating)
                      ? <img className={styles.star} src={svgStartUncheck} alt="" />
                      : <img className={styles.star} src={svgStarCheck} alt="" />
                  }
                </>
                : <img className={styles.star} src={svgStarHover} alt="" />
            }
          </span>
        );
      })}
    </div>
  )
}
