import { UserMetadataKey } from "../../store/models/user-metadata-key.enum";
import store from "../../store/store";

export default function hasSufficientScopes(requiredRoles: string[], userId?: string, includeSubscriptionCheck?: boolean): boolean {
  const state = store.getState();
  const activeOrg = state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION];
  const loggedInUserId = state.user.profileInfo.userId;
  let permissions: string[] = [];

  //check for relevant permissions
  for (const o of state.user.profileInfo.organizations) {
    if (o.name === activeOrg) {
      permissions = permissions.concat(...o.permissions);
    }
  }
  const res = requiredRoles.some(irp => {
    if (irp.includes('.all')) {
      return permissions.includes(irp);
    } else {
      if (permissions.includes(irp)) {
        if (userId) {
          if (userId === loggedInUserId) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    }
  });
  return res;
}

export function hasActiveSubscription() {
  const state = store.getState();
  const isActiveSubscription = state.subscription.data.isActive;
  return isActiveSubscription;
}

export function hasActiveOrg(): boolean {
  const state = store.getState();
  return state.user.profileInfo.organizations.length > 0
}
