import { AuthClientTokens } from "@react-keycloak/core";
import { AuthClientEvent, AuthClientError } from '@react-keycloak/core/lib';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import axios from "axios";
import Keycloak from "keycloak-js";
import { useRef, useState } from "react";
import { getAllProfileInfoForAppInit } from "../../../api/profile";
import { addToken } from "../../../store/slices/token.slice";
import { addUser, setProfileInfo } from "../../../store/slices/user.slice";
import store from "../../../store/store";
import CustomLoader from "../../v2/common/custom-loader/custom-loader";

// React-Keycloak seems to have been deprecated and has been archived by the original author: https://github.com/react-keycloak/react-keycloak#readme
// TODO: Switch to https://github.com/authts/react-oidc-context instead.
// TODO: Implement cleaner patterns to fix the unnecessary init call before login.

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT as string
});

export default function KeyCloak({ children }: any) {
  const isFirstTimeDone = useRef(false);
  const [isUserPreferenceDone, setUserPreferenceDone] = useState(false);
  const keycloakEventHandler = async (event: AuthClientEvent, error?: AuthClientError) => {
    switch (event) {
      case 'onAuthSuccess':
        console.debug('😊 successfully authenticated');
        break;
      case 'onAuthRefreshSuccess':
        await fetchUserPreference();
        console.debug('✔️ refreshed auth token');
        break;
      case 'onReady':
        await fetchUserPreference();
        setUserPreferenceDone(true);
        console.debug('😀 app is ready for use');
        break;
      case 'onTokenExpired':
        console.debug('😑 auth token expired');
        break;
      case 'onAuthError':
        console.debug('☹️ auth error', error);
        break;
      case 'onAuthRefreshError':
        console.debug('☹️ auth refresh error', error);
        break;
      case 'onAuthLogout':
        console.debug('👋🏾 logging out');
        break;
      case 'onInitError':
        console.debug('☹️ init error', error);
        break;
      default:
        console.debug('😕 we are confused as to why this happened as well');
    }
  };

  const tokenReceivedHandler = async (tokens: AuthClientTokens) => {
    const token = tokens.token as string;
    store.dispatch(addToken(token));
    if (token) {
      const parsedToken = JSON.parse(window.atob(token.split('.')[1]));
      store.dispatch(addUser(parsedToken));
      setupAxiosInterceptor();
    }
  }

  async function fetchUserPreference() {
    try {
      const res = await getAllProfileInfoForAppInit();
      if (!isFirstTimeDone.current) {
        keycloak.updateToken(600).then((updateRes) => {
          if (updateRes) {
            console.debug('🔃 token updated');
            isFirstTimeDone.current = true;
          }
        }).catch(() => {
          console.debug('⚠️ failed to refresh token');
        });
      }
      setUserPreferenceDone(true);
      if (res && JSON.parse(JSON.stringify(res))) {
        store.dispatch(setProfileInfo(res));
      }
    } catch (err) {
      console.error("User preference couldn't be fetched");
    }
  }

  const setupAxiosInterceptor = () => {
    console.debug('📐 axios interceptor updated');
    axios.interceptors.request.use(
      function (config) {
        if (store.getState().token.data) {
          config.headers.setAuthorization(`Bearer ${store.getState().token.data}`);
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onTokens={tokenReceivedHandler}
      onEvent={keycloakEventHandler}
      autoRefreshToken
    >
      {isUserPreferenceDone ? children : <CustomLoader />}

      {/* {children} */}
    </ReactKeycloakProvider>
  )
}

export interface IUserProfile {
  sub: string;
  sf: {
    user_id: string;
  };
  email_verified: boolean;
  name: string;
  organizations: Record<string, { roles: string[]; name: string; }>;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
}
