import { createAsyncThunk } from "@reduxjs/toolkit";
import { getControlsByFormId } from "../../api/form-controls";
import { CreateFormControlDto, CreateFormControlRes } from "../models/form-controls/create-form-control";
import { DeleteFormControlReq } from "../models/form-controls/delete-form-control";
import { FormControl } from "../models/form-controls/form-control.interface";
import { ReorderFormControlsDto, UpdateFormControlDto, UpdateQuizControlDto } from "../models/form-controls/update-form-control";
import { WsError } from "../models/ws/ws-error";
import { WsResponse } from "../models/ws/ws-response";

export const createControlThunk = createAsyncThunk("forms/createControl",
  async (param: { socket: any, payload: CreateFormControlDto }) => {
    return new Promise<{ data: FormControl, controlIndex: number, parentIndex?: number, grandParentIndex?: number, parentType: "page" | "section" | null }>((resolve, reject) => {
      const action = 'autosave:create-control';
      param.socket.timeout(5000).emit(action, { action, payload: param.payload.data }, (err: WsError, res: WsResponse<CreateFormControlRes>) => {
        if (err || (res && res.status === "error")) {
          reject(err);
          return;
        }
        let formControl: FormControl = {
          formId: res.formId,
          controlId: res.controlId,
          label: res.label,
          options: res.options,
          config: res.config,
          order: res.order,
          controlType: res.controlType,
          parentId: res.parentId,
          isReadOnly: res.isReadOnly,
          id: res.id,
          children: res.children,
          labelHTML: res.labelHTML,
          openEditor: false
        };
        resolve({ data: formControl, controlIndex: param.payload.controlIndex, parentIndex: param.payload.parentIndex, grandParentIndex: param.payload.grandParentIndex, parentType: param.payload.parentType });
      });
    });
  }
);

export const updateControlThunk = createAsyncThunk("forms/updateControl",
  async (param: { socket: any, payload: UpdateFormControlDto }) => {
    const action = 'autosave:update-control';
    return new Promise<UpdateFormControlDto>((resolve, reject) => {
      param.socket.timeout(5000).emit(action, { action, payload: param.payload }, (err: WsError, res: WsResponse<{}>) => {
        if (err || (res && res.status === "error")) {
          reject(err);
        } else {
          resolve(param.payload);
        }
      });
    });
  }
);

export const deleteControlThunk = createAsyncThunk("forms/deleteControl",
  async (param: { socket: any, payload: DeleteFormControlReq }) => {
    const action = 'autosave:delete-control';
    return new Promise<DeleteFormControlReq>((resolve, reject) => {
      param.socket.timeout(5000).emit(action, { action, payload: param.payload }, (err: WsError, res: WsResponse<{}>) => {
        if (err || (res && res.status === "error")) {
          reject(err);
        } else {
          resolve(param.payload);
        }
      });
    })
  }
)

export const getControlByFormThunk = createAsyncThunk("forms/form-controls",
  async (formId: string) => {
    return await getControlsByFormId(formId);
  }
);

export const controlReorderThunk = createAsyncThunk(
  "forms/reorderControls",
  async (param: { socket: any, payload: ReorderFormControlsDto }) => {
    const action = 'autosave:reorder-control';
    return new Promise<FormControl[]>((resolve, reject) => {
      param.socket.timeout(5000).emit(action, { action, payload: param.payload }, (err: WsError, res: WsResponse<{ formControls: FormControl[] }>) => {
        if (err || (res && res.status === "error")) {
          reject(err);
        } else {
          resolve(res.formControls);
        }
      });
    })
  }
);

export const updateQuizControlThunk = createAsyncThunk(
  "forms/updateQuizControl",
  async (param: { socket: any, payload: UpdateQuizControlDto }) => {
    const action = 'autosave:update-control';
    return new Promise<UpdateQuizControlDto>((resolve, reject) => {
      param.socket.timeout(5000).emit(action, { action, payload: param.payload }, (err: WsError, res: WsResponse<{}>) => {
        if (err || (res && res.status === "error")) {
          reject(err);
        } else {
          resolve(param.payload);
        }
      });
    });
  }
);
