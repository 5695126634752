import React, { useEffect, useRef } from 'react'
import styles from './tool-tip.module.scss'


export default function ToolTip(prop: ToolTipProps) {

    const toolTipRef = useRef<HTMLDivElement>(null);

    return (
        <div className={styles.wrapper} style={{ position: 'relative' }}
            data-tooltip={prop.content}
            data-tooltip-location={prop.position}
            ref={toolTipRef}
            >
            {prop.children}
        </div>
    )
}


export interface ToolTipProps {
    position: 'right' | 'left' | 'bottom' | 'top';
    content: string;
    children?: React.ReactNode;
}
