import styles from './builder-canvas.module.scss';
import CustomHeading from '../../common/custom-heading/custom-heading';
import { BuilderCanvasProps } from './builder-canvas.props';
import { PageControl } from '../page-control/page-control';
import { toast } from 'react-hot-toast';
import svgPlusCircle from "../../../../assets/v2/icons/plus-circle.svg";
import { useTranslation } from 'react-i18next';

export function BuilderCanvas(props: BuilderCanvasProps) {
  const { t } = useTranslation();

  const addNewPage = () => {
    if (props.formControls && props.formControls.length > 0) {
      if (props.formControls[props.formControls.length - 1].children.length > 0) {
        props.createControl({ controlName: 'page', controlIndex: props.formControls ? props.formControls.length : 0, parentId: null, parentType: null })
      } else {
        toast.error(t("builder_prev_page_cntrl_error"));
      }
    }
  }

  return (
    <>
      <div id={'builder-canvas'} className={`${styles.builderPane}`} onDragOver={(e) => { e.preventDefault() }}>
        <div className={`${styles.titleContainer}`}
          onClick={() => {
            props.setIsActionPanelOpen(!props.isActionPanelOpen);
            props.setSidePaneTabvalue("settings");
            props.setActionBarTitle(t("settings"));
          }}
          style={{
            background: props.form?.config?.theme?.main ? props.form?.config?.theme?.main : 'var(--secondary-top-bar-color)'
          }}
        >
          <div className={styles.formName}>
            <CustomHeading headerType='h1' style={{
              padding: "1rem 1rem",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              background: props.form?.config?.theme?.main ? props.form?.config?.theme?.main : 'var(--secondary-top-bar-color)',
              color: props.form?.config?.theme?.main ? '#ffffff' : '#000000',
              maxWidth: "100%",
              boxSizing: "border-box"

            }}
            >
              <div
                style={{
                  fontSize: props.form?.config?.font?.fontSize ? props.form?.config?.font?.fontSize === 'larger' ? 'large' : props.form?.config?.font?.fontSize : '14px',
                  fontWeight: props.form?.config?.font?.fontWeight ? props.form?.config?.font?.fontWeight : '600',
                  maxWidth: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >{props.form?.name}</div>
            </CustomHeading>

            {props.isQuizEnabled && <CustomHeading headerType='h1' style={{
              padding: "2rem 1rem",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              background: props.form?.config?.theme?.main ? props.form?.config?.theme?.main : 'var(--secondary-top-bar-color)',
              color: props.form?.config?.theme?.main ? '#ffffff' : '#000000',
            }}
            >
              <div
                style={{
                  fontSize: props.form?.config?.font?.fontSize ? props.form?.config?.font?.fontSize === 'larger' ? 'large' : props.form?.config?.font?.fontSize : '14px',
                  fontWeight: props.form?.config?.font?.fontWeight ? props.form?.config?.font?.fontWeight : '600',
                }}
              >Total Score: {props.score !== undefined && props.score.toString().includes('.') ? props.score.toFixed(1) : props.score !== undefined && props.score >= 0 && props.score}
              </div>
            </CustomHeading>}
          </div>
          {
            !(props.form?.config?.theme?.main) &&
            <div className={styles.break}></div>
          }
        </div>
        {
          props.formControls && props.formControls.length > 0 && props.formControls.map((value, index) => {
            return (
              <PageControl
                key={value.id}
                formControls={props.formControls}
                controlIndex={index}
                parent={undefined}
                parentIndex={undefined}
                formControl={value}
                isDraggable={props.isDraggable}
                mouseHeight={props.mouseHeight}
                setIsDraggable={props.setIsDraggable}
                reorderControls={props.reorderControls}
                pickedCtrlRef={props.pickedCtrlRef}
                pickedCtrlIndexRef={props.pickedCtrlIndexRef}
                pickedCtrlParentRef={props.pickedCtrlParentRef}
                pickedCtrlParentIndexRef={props.pickedCtrlParentIndexRef}
                pickedCtrlGrandParentRef={props.pickedCtrlGrandParentRef}
                pickedCtrlGrandParentIndexRef={props.pickedCtrlGrandParentIndexRef}
                hoverCtrlRef={props.hoverCtrlRef}
                hoverCtrlIndexRef={props.hoverCtrlIndexRef}
                hoverCtrlParentRef={props.hoverCtrlParentRef}
                hoverCtrlParentIndexRef={props.hoverCtrlParentIndexRef}
                hoverCtrlGrandParentRef={props.hoverCtrlGrandParentRef}
                hoverCtrlGrandParentIndexRef={props.hoverCtrlGrandParentIndexRef}
                dragPlaceHolder={props.dragPlaceHolder}
                deleteControl={props.deleteControl}
                createControl={props.createControl}
                setActiveFormControl={props.setActiveFormControl}
                setSidePaneTabvalue={props.setSidePaneTabvalue}
                setIsActionPanelOpen={props.setIsActionPanelOpen}
                isActionPanelOpen={props.isActionPanelOpen}
                hoverCtrlId={props.hoverCtrlId}
                pickedCtrlId={props.pickedCtrlId}
                pickedCtrlParentDomRef={props.pickedCtrlParentDomRef}
                setActionBarTitle={props.setActionBarTitle}
                form={props.form}
                isQuizEnabled={props.isQuizEnabled}
                isLoading={props.isLoading}
              />
            )
          })
        }
        <div className={styles.addPageContainer}>
          <div className={styles.addPageMargin} />
          <div
            className={styles.addPageBtn}
            onClick={(e) => { addNewPage() }}
          > <div className={styles.plusBtnImg}><img src={svgPlusCircle} alt='Add' /></div> {t("builder_add_new_page")}</div>
        </div>
      </div>
    </>
  )
}
