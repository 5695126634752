import { useTranslation } from 'react-i18next';
import CustomText from '../../common/custom-bodyText/custom-text';
import CustomHeading from '../../common/custom-heading/custom-heading';
import styles from './total-selected-plans.module.scss';
import { BaseSubscriptionPlan, AddOnSubscriptionPlan } from '../../../../store/models/subscription/plans';
import trashIcon from '../../../../assets/v2/delete.svg';
import { Dispatch, SetStateAction } from 'react';
import { SubscriptionPlanType } from '../../../../store/models/subscription/plan-type.enum';
import CustomButton from '../../common/custom-button/custom-button';

export interface TotalSelectedPlansProps {
  plans: (BaseSubscriptionPlan | (AddOnSubscriptionPlan & {
    isSelected: boolean;
  }))[];
  setSelectedBasePlan: Dispatch<SetStateAction<BaseSubscriptionPlan | undefined>>;
  setAddOnPlans: Dispatch<SetStateAction<(AddOnSubscriptionPlan & {
    isSelected: boolean;
  })[]>>;
  isFreeze: boolean;
}

export function TotalSelectedPlans(props: TotalSelectedPlansProps) {
  const { t } = useTranslation();
  const totalAmount = props.plans.reduce((acc, curr) => acc + curr.planMetaData.baseAmount, 0);

  return (
    <div className={`${styles.container} ${props.isFreeze ? styles.containerDisabled : ''}`}>
      <div className={styles.titleContainer}>
        <CustomHeading headerType='h3'>{t("total_plan_main")}</CustomHeading>
        <CustomText textType='t5'>{t("total_plan_sub")}</CustomText>
      </div>
      <div>
        {
          props.plans?.length > 0
            ?
            <div className={`tableWrapper tableNoShadow ${styles.showTable}`}>
              <>
                <div className={`tableHeaderRow ${props.isFreeze ? styles.containerDisabled : ''}`}>
                  <div className={'tableData'}>{t("feature_heading")}</div>
                  <div className={'tableData'}>{t("price")}</div>
                  <div className={'tableData'}>{t("action")}</div>
                </div>
                {
                  props.plans.map((plan, index) => {
                    return (
                      <div className={'tableRow'} key={index}>
                        <div className={'tableData'}>{plan.displayName}</div>
                        <div className={'tableData'}>${plan.planMetaData.baseAmount / 100}</div>
                        <div className={'tableData'}>
                          <div>
                            <CustomButton
                              style={{
                                background: "var(--accent-color)",
                                padding: "0.5rem",
                                width: 'fit-content',
                                borderRadius: '50%',
                                border: 'none',
                                boxSizing: "border-box",
                                display: 'flex'
                              }}
                              buttonType='button'
                              classType='tertiary'
                              onClick={() => {
                                if (plan.planType === SubscriptionPlanType.BASE) props.setSelectedBasePlan(undefined);
                                else if (plan.planType === SubscriptionPlanType.ADD_ON) props.setAddOnPlans((prev) => prev.map(val => {
                                  if (val.id === plan.id) return { ...val, isSelected: false };
                                  return val;
                                }));
                              }}>
                              <img style={{ width: '1.2rem' }} src={trashIcon} alt="" />
                            </CustomButton>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                <div className={'tableRow'}>
                  <div className={'tableData'}><CustomHeading headerType='h5'>{t("total_plan_main")}</CustomHeading></div>
                  <div className={'tableData'}><CustomHeading headerType='h5'>${totalAmount / 100}</CustomHeading></div>
                  <div className={'tableData'}>&nbsp;</div>
                </div>
              </>
            </div>
            :
            <CustomText textType='t5' style={{ color: "#4a4a4a" }}>{t("view_total_amount")}</CustomText>
        }
      </div>
    </div>
  )
}
