import { createSlice } from "@reduxjs/toolkit";

export const socketSlice = createSlice({
    name: "token",
    initialState: {
        Ins: null
    },
    reducers: {
        setStatus(state, action) {
            state.Ins = action.payload;
        }
    }
});

export const { setStatus } = socketSlice.actions;

export default socketSlice;