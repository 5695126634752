import React, { useState } from 'react'
import styles from "./custom-tabs.module.scss";
import { CustomTabDto } from './custom-tabs.props';
import TextComponent from '../text-component/text-component';

export default function CustomTabs(props: CustomTabDto) {

  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={styles.tabHeadContainer}>
      <div className={styles.tabHeading}>
        {
          props.tabHeadings.map((heading, index) => (
            <button key={index} style={props.btnStyles} onClick={() => {
              setActiveTab(index);
              props.setActiveIndex(index);
            }} className={` ${styles.tabBtn} ${activeTab === index && styles.activeTab}`}>
              <TextComponent textType='Content'>
              <div>{heading}</div>
              </TextComponent>
            </button>
          ))
        }
      </div>
    </div>
  )
}


