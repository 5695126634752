import axios from "axios";
import { GenerateApiUrl } from "./generate-api-url";
import { ConditionalEdge, CreateConditionalEdge, GetConditionalEdges } from "../store/models/form-builder/conditional-logic";

export async function createConditionalLogic(formId: string, payload: CreateConditionalEdge): Promise<ConditionalEdge> {
  let url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form/${formId}/conditional-logic`, true);
  const response = await axios.post<ConditionalEdge>(url, payload);
  return response.data;
}

export async function getAllConditionalLogic(formId: string): Promise<GetConditionalEdges> {
  let url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form/${formId}/conditional-logic`, true);
  const response = await axios.get<GetConditionalEdges>(url);
  return response.data;
}

export async function updateConditionalLogic(formId: string, conditionalId: string, payload: Partial<CreateConditionalEdge>): Promise<void> {
  let url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form/${formId}/conditional-logic/${conditionalId}`, true);
  await axios.patch(url, payload);
}

export async function deleteConditionalLogic(formId: string, conditionalId: string): Promise<void> {
  let url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form/${formId}/conditional-logic/${conditionalId}`, true);
  await axios.delete(url);
}

export async function cloneConditionalLogic(formId: string, refFormId: string) {
  let url = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form/${formId}/conditional-logic/clone`, true);
  await axios.post<ConditionalEdge>(url, { refFormId });
}
