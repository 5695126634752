import { Fragment, useEffect, useState } from 'react';
import styles from './responses.overview.module.scss';
import { GetFormResponseWithQuestions } from '../../../../store/models/form-response.interface';
import { timeSince } from '../../../../util/time-since';
import { useNavigate } from 'react-router-dom';
import { FormControl } from '../../../../store/models/form-controls/form-control.interface';
import svgRightArrowBlack from "../../../../assets/v2/icons/right-arrow-black.svg";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { getForm } from '../../../../store/slices/forms.slice';
import { ResponseUserName } from '../response-user-name/response-user-name';

export default function ResponsesTable(props: ResponseTableProps) {
  const { t } = useTranslation();
  const [isMail, setIsMail] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const form = useSelector((state: RootState) => getForm(state, props.formId ? props.formId : ''));

  useEffect(() => {
    if (props.responseData) {
      props.responseData.formControls.forEach(item => {
        const control = item.children.find(value => { return value.controlType === "raffle" });
        if (control && control.controlType === "raffle") {
          setIsMail(control.config.userInfo.includes("email"));
          setIsPhone(control.config.userInfo.includes("phone"));
        }
      })
    }
  }, [props.responseData]);

  const navigate = useNavigate();

  return (
    <div className={styles.userTableMainDiv}>
      <div className={styles.gridTable}>
        <div className={styles.headingColumn}>
          <div className={styles.headingValue}>{t("submitted_at")}</div>
          {
            form?.config?.isAuthenticated &&
            <div className={styles.headingValue}>{t("submitted_by")}</div>
          }
          {
            props.responseData?.formControls && props.responseData.formControls.map((parent, index) => {
              const renderHeading = (value: FormControl[]): JSX.Element => {
                return (
                  <Fragment key={parent.id}>
                    {
                      value.map((child, childIndex) => {
                        return (
                          <Fragment key={child.id}>
                            {
                              child.children.length > 0 ? (<>{renderHeading(child.children)}</>) :
                                (<>
                                  {
                                    child.controlType !== "label" &&
                                    <div className={styles.headingValue} key={child.id}>
                                      {
                                        child.controlType === "quiz" &&
                                        <>
                                          {t("quiz_email")}
                                        </>
                                      }
                                      {
                                        child.label != null && child.controlType === "email" && isMail &&
                                        <>
                                          {t("raffle_email")}
                                        </>
                                      }
                                      {
                                        child.label != null && child.controlType === "mobile number" && isPhone &&
                                        <>
                                          {child.label}
                                        </>
                                      }

                                      {
                                        child.label != null && child.controlType !== "mobile number" && child.controlType !== "email" &&
                                        <>
                                          {child.label}
                                        </>
                                      }
                                    </div>
                                  }
                                </>)
                            }
                          </Fragment>
                        )
                      })
                    }
                  </Fragment>
                )
              }
              return (<Fragment key={parent.id}>{renderHeading(parent.children)}</Fragment>)
            })
          }
        </div>
        <>
          {
            props.responseData?.formResponse && props.responseData.formResponse.map((response, index) => (
              <div className={styles.tableColumn} key={response.id}
                onClick={() => {
                  navigate(`${response.id}`);
                }}
              >
                <div className={styles.tableValue}>{response.submittedAt ? timeSince(new Date(response.submittedAt)) : "-"}</div>
                {
                  form?.config?.isAuthenticated && response.userId &&
                  <ResponseUserName userId={response.userId} includeStyle />
                }
                {
                  props.responseData?.formControls && props.responseData.formControls.map((parent, index) => {

                    const renderResponse = (value: FormControl[]): JSX.Element[] => {
                      return (
                        value.map((child, index) => {
                          return (
                            <Fragment key={child.id}>
                              {
                                child.children.length > 0 ? (
                                  renderResponse(child.children)
                                ) : (
                                  child.controlType !== "raffle" && child.controlType !== "section" && child.controlType !== "label" &&
                                  <div className={styles.tableValue} key={child.id}>
                                    {
                                      child.controlType === "file" ? (
                                        <div>
                                          {
                                            response.responseData[child.id as keyof object] && response.responseData[child.id as keyof object].answer ?
                                              <div>

                                                {response.responseData[child.id as keyof object].answer.length} {`${t('response_overview_files')}`}{response.responseData[child.id as keyof object].answer.length > 1 ? "s" : ''} {`${t("uploaded")}`}
                                              </div> :
                                              <div>
                                                -
                                              </div>
                                          }
                                        </div>
                                      )
                                        : child.controlType === "quiz" ? (
                                          <>
                                            {response.responseData[child.id + "-email" as keyof object] && response.responseData[child.id + "-email" as keyof object].answer &&
                                              <>
                                                {response.responseData[child.id + "-email" as keyof object].answer}
                                              </>
                                            }
                                          </>
                                        )
                                          : (
                                            <>
                                              {
                                                child.controlType === "mobile number" ? (
                                                  <>
                                                    {response.responseData[child.id as keyof object] && response.responseData[child.id as keyof object].answer &&
                                                      <>
                                                        {response.responseData[child.id as keyof object].answer.countryCode}-{response.responseData[child.id as keyof object].answer.mobileNumber}
                                                      </>
                                                    }
                                                  </>
                                                ) : (
                                                  <>
                                                    {response.responseData[child.id as keyof object] && response.responseData[child.id as keyof object].answer ?
                                                      <>
                                                        {
                                                          <>
                                                            {
                                                              Array.isArray(response.responseData[child.id as keyof object].answer) ?
                                                                <>
                                                                  {
                                                                    response.responseData[child.id as keyof object].answer.length > 0 &&
                                                                    response.responseData[child.id as keyof object].answer.join(", ")
                                                                  }
                                                                </> :
                                                                <>
                                                                  {
                                                                    response.responseData[child.id as keyof object].answer.length > 100 ?
                                                                      <>
                                                                        {response.responseData[child.id as keyof object].answer.slice(0, 70)}...
                                                                      </> :
                                                                      <>
                                                                        {
                                                                          response.responseData[child.id as keyof object].answer
                                                                        }
                                                                      </>
                                                                  }
                                                                </>
                                                            }
                                                          </>
                                                        }
                                                      </> :
                                                      <div> - </div>
                                                    }
                                                  </>
                                                )
                                              }
                                            </>
                                          )
                                    }
                                  </div>
                                )
                              }
                            </Fragment>
                          )
                        })
                      )
                    }

                    return (<Fragment key={index}>{renderResponse(parent.children)}</Fragment>);
                  })
                }
              </div>
            ))
          }
        </>
      </div>
      <div className={styles.cardHolder}>
        {
          props.responseData?.formResponse && props.responseData.formResponse.map((item, index) => (
            <div className={styles.mobileCard} key={item.id} >
              <div className={styles.rowOne}>
                <div>{t("resp_id")}</div>
                {item.id && <div className={styles.dataContainer}>{item.id}</div>}
              </div>
              <div className={styles.rowOne}>
                <div>{t("submitted_at")}</div>
                {item.submittedAt && <div className={styles.dataContainer}>{timeSince(new Date(item.submittedAt))}</div>}
              </div>
              <div className={styles.rowFour}>
                <div className={styles.diamond}></div>
                <div className={styles.line}></div>
                <div className={styles.diamond}></div>
              </div>
              <div className={styles.mobileViewAllContainer} onClick={() => navigate(`${item.id}`)}>
                <div className={styles.viewAllContainer}>{t("view_resp")}</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img style={{ maxWidth: "80%" }} src={svgRightArrowBlack} alt="no" />
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div >
  )
}

interface ResponseTableProps {
  responseData: GetFormResponseWithQuestions | undefined;
  formId?: string;
}
