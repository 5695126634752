import { createSlice } from "@reduxjs/toolkit";

const captchaInitialState: {
    isCaptchaLoaded: boolean;
} = {
    isCaptchaLoaded: false
};

const captchaSlice = createSlice({
    name: 'captcha',
    initialState: captchaInitialState,
    reducers: {
        setCaptchaState(state, action: {payload: boolean, type: string}) {
            state.isCaptchaLoaded = action.payload;
        }
    }
});

export const {
    setCaptchaState
} = captchaSlice.actions;

export default captchaSlice;