import styles from './page-layout.module.scss';
import Carousal from '../../../../components/v2/common/carousal/carousal';

export default function PageLayout() {

  return (
    <>
      <div className={styles.leftContainer}>
        <span className={styles.mainTitle}>Why you should <span className={styles.subTitle}>sign up </span>
          for our product!</span>
        <Carousal />
      </div>
    </>
  )
}
