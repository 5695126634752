import { useEffect, useId } from 'react';
import bulmaCalendar from 'bulma-calendar';
import styles from './custom-time.module.scss'
import { CustomTimeProps } from './custom-time.props';

export default function CustomTime(props: CustomTimeProps) {
  const id = useId();
  const containerId = useId();

  const closeButton = () => {
    const comp = document.getElementById(id);
    const bul: bulmaCalendar = (comp as any).bulmaCalendar;
    if (!bul) return;
    bul.hide();
  }

  useEffect(() => {
    const comp = document.getElementById(id);
    const bul: bulmaCalendar = (comp as any).bulmaCalendar;
    if (!bul) return;
    if (props.value) {
      bul.startTime = new Date(props.value);
    } else {
      bul.clear();
    }
    bul.save();
    bul.hide();
  }, [props.value]);

  useEffect(() => {
    bulmaCalendar.attach(`[id="${id}"]`, {
      type: 'time', showHeader: false, validateLabel: 'Submit', startTime: props.value ? new Date(props.value) : undefined
    });

    const comp = document.getElementById(id);
    const bul: bulmaCalendar = (comp as any).bulmaCalendar;

    // bul.on('hide', (value) => {
    //   props.getTime(value.data.startTime);
    // });

    bul.on('show', () => {
      if (props.onShow) props.onShow();
    })

    // custom implementation to hide element during clear
    const container = document.getElementById(containerId);
    const onClickClear = () => {
      bul.clear();
      bul.hide();
      bul.save();
      props.getTime(undefined);
    }
    const onClickSubmit = () => {
      props.getTime(bul.startTime);
      bul.hide();
    }
    const onClickCancel = () => {
      bul.hide();
    }
    if (container) {
      const clearBtnFtr = container.querySelector('.datetimepicker-footer-clear');
      if (clearBtnFtr) clearBtnFtr.addEventListener('click', onClickClear);
      const clearBtn = container.querySelector('.datetimepicker-clear-button');
      if (clearBtn) clearBtn.classList.add(styles.displayNone);
      const submitBtnFtr = container.querySelector('.datetimepicker-footer-validate');
      if (submitBtnFtr) submitBtnFtr.addEventListener('click', onClickSubmit);
      const cancelBtnFtr = container.querySelector('.datetimepicker-footer-cancel');
      if (cancelBtnFtr) cancelBtnFtr.addEventListener('click', onClickCancel);
    }

    if (props.hideTimeDialog) props.hideTimeDialog.current = closeButton;

    return () => {
      const container = document.getElementById(containerId);
      if (container) {
        const clearBtnFtr = container.querySelector('.datetimepicker-footer-clear');
        if (clearBtnFtr) clearBtnFtr.removeEventListener('click', onClickClear);
        const submitBtnFtr = container.querySelector('.datetimepicker-footer-validate');
        if (submitBtnFtr) submitBtnFtr.removeEventListener('click', onClickSubmit);
        const cancelBtnFtr = container.querySelector('.datetimepicker-footer-cancel');
      if (cancelBtnFtr) cancelBtnFtr.removeEventListener('click', onClickCancel);
      }
    }
  }, []);

  useEffect(() => {
    const comp = document.getElementById(id);
    const bul: bulmaCalendar = (comp as any).bulmaCalendar;
    if (!bul) return;
    if (!props.timeFocus && bul) bul.hide();
  }, [props.timeFocus])

  return (
    <div id={containerId}>
      <input
        type={'time'}
        id={id}
        // data-start-time={props.value}
        disabled={props.disabled} />
    </div>
  )
}
