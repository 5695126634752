import styles from './org-settings.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CustomHeading from '../../../../components/v2/common/custom-heading/custom-heading';
import { RootDispatch, RootState } from '../../../../store/store';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import { useNavigate } from 'react-router-dom';
import { SubscriptionPlanType } from '../../../../store/models/subscription/plan-type.enum';
import hasSufficientScopes from '../../../../util/access-validator/access-validator';
import { activateCancelledSubscription } from '../../../../api/subscription';
import { fetchSubscriptionThunk } from '../../../../store/thunks/subscription.thunk';
import toast from 'react-hot-toast';
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import NoContent from '../../../../components/v2/common/no-content/no-content';
import { Fragment } from 'react';

export default function SubscriptionDetails() {
  const { t } = useTranslation();
  const latestSubscription = useSelector((state: RootState) => state.subscription.data);
  const navigate = useNavigate();
  const dispatch: RootDispatch = useDispatch();

  const onClickActivate = async () => {
    try {
      await activateCancelledSubscription();
      toast.success(t("subscription_activated_again"));
      dispatch(fetchSubscriptionThunk());
    } catch (err) {
      toast.error(t("subscription_activation_failure"));
    }
  }

  return (
    <div className={styles.subMainContainer}>
      {
        latestSubscription.isActive
          ?
          hasSufficientScopes(["subscription:read"]) ?
            <>
              <div className={styles.headingContainer}>
                <TextComponent textType='Header'>{t("sub_details")}</TextComponent>
                <div style={{ "fontSize": "14px" }}>{t("table_form_status")}: {latestSubscription.isCanceledAtPeriodEnd ? `${t("cancelled")}` : `${t("active")}`}</div>
                <div style={{ "fontSize": "14px" }}> {latestSubscription.isCanceledAtPeriodEnd ? `${t("valid_till")} : ` : `${t("next_billing_cycle")} : `}{new Date(latestSubscription.nextPaymentDate).toLocaleDateString()}</div>
              </div>
              <div className={styles.subsDetailsContainer}>
                <div className={styles.detailsPanel}>
                  <TextComponent textType='Header'>{t("plans")}</TextComponent>
                  <div className={'tableWrapper tableNoShadow'}>
                    <div className={'tableHeaderRow'} style={{ backgroundColor: 'transparent' }}>
                      <div className={'tableData'}>{t("name")}</div>
                      <div className={'tableData'}>{t("type")}</div>
                      <div className={'tableData'}>{t("price")}</div>
                    </div>
                    {
                      latestSubscription.plans.map((plan, index) => {
                        if (plan.planType === SubscriptionPlanType.USAGE_BASED) return <Fragment key={index}></Fragment>;
                        return (
                          <div className={'tableRow'} key={plan.id}>
                            <div className={'tableData'}>{plan.displayName}</div>
                            <div className={'tableData'}>{plan.planType.toLocaleUpperCase().split('_').join(' ')}</div>
                            <div className={'tableData'}>${(plan.planMetaData as Record<string, any>).baseAmount / 100}</div>
                          </div>
                        );
                      })
                    }
                  </div>
                  <div className='mobileContainer'>
                    {
                      latestSubscription && latestSubscription.plans.length > 0 &&
                      latestSubscription.plans.map((plan, index) => {
                        return (<Fragment key={index}>
                          <div key={index} className='mobileCard'>
                            <div className={styles.alignCentre}>{t("name")}: {plan.displayName}</div>
                            <div className={styles.alignCentre}>{t("type")}: {plan.planType.toLocaleUpperCase().split('_').join(' ')}</div>
                            <div className={styles.alignCentre}>{t("price")}: ${(plan.planMetaData as Record<string, any>).baseAmount / 100}</div>
                          </div>
                        </Fragment>)
                      })
                    }
                  </div>
                  {
                    hasSufficientScopes(["subscription:update"]) &&
                    <div style={{ marginTop: 'auto' }}>
                      <CustomButton
                        buttonType='button'
                        classType='quaternary'
                        disabled={latestSubscription?.stripeSubscriptionId ? false : true}
                        onClick={() => { latestSubscription.isCanceledAtPeriodEnd ? onClickActivate() : navigate('../subscription-plans') }}>
                        <CustomHeading headerType='h4'>
                          <div style={{ color: '#fff', marginTop: 'auto' }}>{latestSubscription.isCanceledAtPeriodEnd ? `${t("activate")}` : `${t("change_plan")}`}</div>
                        </CustomHeading>
                      </CustomButton>
                    </div>
                  }
                </div>
                <div className={styles.detailsPanel}>
                  <TextComponent textType='Header'>{t("plan_benefits")}</TextComponent>
                  <div className={'tableWrapper tableNoShadow'}>
                    <div className={'tableHeaderRow'} style={{ backgroundColor: 'transparent' }}>
                      <div className={'tableData'}>{t("feature")}</div>
                      <div className={'tableData'}>{t("base_count")}</div>
                    </div>
                    <div className={'tableRow'}>
                      <div className={'tableData'}>{t("nav_bar_responses")}</div>
                      <div className={'tableData'}>{(latestSubscription.plans.find(val => val.planType === SubscriptionPlanType.BASE)?.planMetaData as Record<string, any>)?.responseCount}</div>
                    </div>
                    <div className={'tableRow'}>
                      <div className={'tableData'}>{t("nav_bar_users")}</div>
                      <div className={'tableData'}>{(latestSubscription.plans.find(val => val.planType === SubscriptionPlanType.BASE)?.planMetaData as Record<string, any>)?.userCount}</div>
                    </div>
                    <div className={'tableRow'}>
                      <div className={'tableData'}>{t("sms")}</div>
                      <div className={'tableData'}>{(latestSubscription.plans.find(val => val.planType === SubscriptionPlanType.BASE)?.planMetaData as Record<string, any>)?.smsCount}</div>
                    </div>
                    <div className={'tableRow'}>
                      <div className={'tableData'}>{t("storage")}</div>
                      <div className={'tableData'}>{(latestSubscription.plans.find(val => val.planType === SubscriptionPlanType.BASE)?.planMetaData as Record<string, any>)?.storageMB}MB</div>
                    </div>
                  </div>
                </div>
                <div className='mobileContainer'>
                  <div className='mobileCard'>
                    <div className={styles.alignCentre}>{t("feature")}{t("nav_bar_responses")}</div>
                    <div className={styles.alignCentre}>{t("base_count")} {(latestSubscription.plans.find(val => val.planType === SubscriptionPlanType.BASE)?.planMetaData as Record<string, any>)?.responseCount}</div>
                  </div>
                  <div className='mobileCard'>
                    <div className={styles.alignCentre}>{t("feature")} {t("active_users")}</div>
                    <div className={styles.alignCentre}>{t("base_count")} {(latestSubscription.plans.find(val => val.planType === SubscriptionPlanType.BASE)?.planMetaData as Record<string, any>)?.userCount}</div>
                  </div>
                  <div className='mobileCard'>
                    <div className={styles.alignCentre}>{t("feature")} {t("sms")}</div>
                    <div className={styles.alignCentre}>{t("base_count")} {(latestSubscription.plans.find(val => val.planType === SubscriptionPlanType.BASE)?.planMetaData as Record<string, any>)?.smsCount}</div>
                  </div>
                  <div className='mobileCard'>
                    <div className={styles.alignCentre}>{t("feature")} {t("storage")}</div>
                    <div className={styles.alignCentre}>{t("base_count")} {(latestSubscription.plans.find(val => val.planType === SubscriptionPlanType.BASE)?.planMetaData as Record<string, any>)?.storageMB}MB</div>
                  </div>
                </div>
              </div>
            </>
            :
            <NoContent
              content={`${t("no_subscription_to_show")}`}
            />
          :
          hasSufficientScopes(["subscription:create"]) ?
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', justifyContent: 'center', alignItems: 'center' }}>
              <div>
                {t("start_subscription_text")}
              </div>
              <div>
                <CustomButton
                  buttonType='button'
                  classType='quaternary'
                  onClick={() => navigate('../subscription-plans')}>
                  <CustomHeading headerType='h4'>
                    <div style={{ color: '#fff', marginTop: 'auto' }}>{t("subscribe")}</div>
                  </CustomHeading>
                </CustomButton>
              </div>
            </div>
            :
            <NoContent
              content={`${t("no_subscription_to_show")}`}
            />
      }
    </div>
  )
}
