import { useEffect, useRef, useState } from 'react';
import style from './custom-pagination.module.scss';
import { CustomPaginationProps } from './custom-pagination.props';
import CustomButton from '../custom-button/custom-button';
import CustomTooltip from '../../custom-tooltip/custom-tooltip';
import { Direction } from '../../custom-tooltip/custom-tooltip.props';
import svgDoubleArrowLeft from "../../../../assets/double-arrow-left.svg";
import svgDoubleArrowRight from "../../../../assets/double-arrow-right.svg";
import svgRightArrowBlack from "../../../../assets/v2/icons/right-arrow-black.svg";
import ToolTip from '../tool-tip/tool-tip';

export function CustomPagination(props: CustomPaginationProps) {
  const [currentPageNumbers, setCurrentPageNumbers] = useState<number[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [pageArr, setPageArr] = useState<number[]>([]);
  const [isDoubleLeftArrowDisabled, setDoubleLeftArrowDisabled] = useState(false);
  const [isDoubleRightArrowDisabled, setDoubleRightArrowDisabled] = useState(false);

  useEffect(() => {
    let i;
    let j = props.totalCount;
    let pageArr = [];
    for (i = 1; j > props.countPerPage; i++) {
      pageArr.push(i);
      j -= props.countPerPage;
    }
    if (j > 0) {
      pageArr.push(i);
    }
    setPageArr(pageArr);
  }, [props.totalCount]);

  useEffect(() => {
    if (currentPageNumbers[0] === 1) setDoubleLeftArrowDisabled(true);
    else setDoubleLeftArrowDisabled(false);
    if (currentPageNumbers[2] === pageArr.length) setDoubleRightArrowDisabled(true);
    else setDoubleRightArrowDisabled(false);
    if (currentPageNumbers.length < 3) {
      setDoubleLeftArrowDisabled(true);
      setDoubleRightArrowDisabled(true);
    }
  }, [currentPageNumbers]);

  useEffect(() => {
    if (activePage === 1) {
      let arr = [];
      for (let i = 1; i <= 3; i++) {
        if (i > pageArr.length) break;
        arr.push(i);
      }
      setCurrentPageNumbers([...arr]);
    } else if (activePage === pageArr.length) {
      let arr = [];
      for (let i = 2; i >= 0; i--) {
        if ((activePage - i) > 0) arr.push(activePage - i);
      }
      setCurrentPageNumbers([...arr]);
    } else {
      setCurrentPageNumbers([activePage - 1, activePage, activePage + 1]);
    }
  }, [pageArr, activePage]);

  const handleClick = (pageNumber: number) => {
    const skip = (pageNumber - 1) * props.countPerPage;
    props.handlePagination({ skip: skip, total: props.totalCount });
    setActivePage(pageNumber);
  }

  const handlePrev = () => {
    if (activePage === 1) {
      return;
    }
    handleClick(activePage - 1);


  }

  const handleNext = () => {
    if (activePage === pageArr.length) {
      return;
    }
    handleClick(activePage + 1);
  }

  const movePrevNumbers = () => {
    if (currentPageNumbers.length < 3) {
      return;
    }
    if (currentPageNumbers[0] <= 4) {
      setCurrentPageNumbers([1, 2, 3]);
      return;
    }
    setCurrentPageNumbers([currentPageNumbers[0] - 3, currentPageNumbers[0] - 2, currentPageNumbers[0] - 1]);
  }

  const moveNextNumbers = () => {
    if (currentPageNumbers.length < 3) {
      return;
    }
    if ((currentPageNumbers[currentPageNumbers.length - 1] + 3) >= pageArr.length) {
      setCurrentPageNumbers([pageArr.length - 2, pageArr.length - 1, pageArr.length]);
      return;
    }
    setCurrentPageNumbers([currentPageNumbers[2] + 1, currentPageNumbers[2] + 2, currentPageNumbers[2] + 3]);
  }

  return (
    <div className={style.container}>
      <div className={style.paginationContainer}>
        <ToolTip position='top' content='Previous'>
          <CustomButton
            buttonType='button'
            classType='secondary'
            onClick={() => handlePrev()}
            style={{
              background: "var(--accent-color)",
              padding: "0.5rem 0.5rem",
            }}
            disabled={(activePage === 1)}
          >
            <div className={style.backImg}>
              <img src={svgRightArrowBlack} alt="no" />
            </div>
          </CustomButton>
        </ToolTip>
        <div onClick={() => movePrevNumbers()} className={`${style.wrapDimension} ${isDoubleLeftArrowDisabled ? style.disableBtn : style.doubleLeftArrow}`}>
          <img className={style.doubleArrow} src={svgDoubleArrowLeft} alt='left'></img>
        </div>
        {
          currentPageNumbers.map((page) => {
            return (
              <div key={page} className={`${style.wrapDimension} ${page === activePage ? style.activePage : style.page}`} onClick={() => handleClick(page)}>{page}</div>
            );
          })
        }
        <div onClick={() => moveNextNumbers()} className={`${style.wrapDimension} ${isDoubleRightArrowDisabled ? style.disableBtn : style.doubleLeftArrow}`}>
          <img className={style.doubleArrow} src={svgDoubleArrowRight} alt='left'></img>
        </div>
        <ToolTip content='Next' position='top'>
          <CustomButton
            buttonType='button'
            classType='secondary'
            onClick={() => handleNext()}
            style={{
              background: "var(--accent-color)",
              padding: "0.5rem 0.5rem",
            }}
            disabled={(activePage === pageArr.length)}
          >
            <div className={style.nextImg}>
              <img src={svgRightArrowBlack} alt="no" />
            </div>
          </CustomButton>
        </ToolTip>
      </div>
    </div>
  )
}
