import styles from './custom-button.module.scss';
import { CustomButtonProps } from './custom-button.props';
import spinner from '../../../../assets/btn-spinner-v2.svg';

export default function CustomButton(props: CustomButtonProps) {
  function classSelector(classType: string) {
    switch (classType) {
      case 'primary':
        return styles.btn2;
      case 'secondary':
        return styles.btn;
      case 'tertiary':
        return styles.btn3;
      case 'quaternary':
        return styles.btn4;
      default:
        break;
    }
  }

  return (
    <>
      <button className={`${classSelector(props.classType)} ${props.disabled ? styles.btnDisabled : ""} ${props.isLoading ? styles.isLoading : ""}`}
        onClick={props.onClick}
        style={props.style ? props.style : undefined}
        disabled={props.disabled}
        type={props.buttonType}
      >
        {
          props.isLoading === true
            ?
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img style={{display: 'flex'}} className={styles.spinner} src={spinner} alt="spinner" />
            </div>
            :
            props.children
        }
      </button>
    </>
  )
}

