import { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import Loader from '../loader/loader';
import { RootDispatch, RootState } from '../../../store/store';
import Forbidden from '../../../pages/forbidden-pages/forbidden';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSubscriptionThunk } from '../../../store/thunks/subscription.thunk';
import { UserMetadataKey } from '../../../store/models/user-metadata-key.enum';
import { SetUserMetadataDto } from '../../../store/models/profile.interface';
import { setUserMetadataByKey } from '../../../api/profile';
import CustomLoader from '../../v2/common/custom-loader/custom-loader';

export default function WorkspaceValidator() {
  const { workspaceId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isWorkSpaceVerified, setIsWorkSpaceVerified] = useState(false);
  const profile = useSelector((state: RootState) => state.user.profileInfo);
  const dispatch: RootDispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('popstate', () => {
      const workspaceUrl = window.location.pathname.split('/')[2];
      if (profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION] !== workspaceUrl || profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION] !== workspaceId) {
        window.location.reload();
      }
    })
    return () => {
      window.removeEventListener('popstate', () => { })
    }
  }, []);

  useEffect(() => {
    if (!isWorkSpaceVerified) {
      checkUserWorkSpace();
    }
  }, [window.location.pathname])


  useEffect(() => {
    checkUserWorkSpace();
  }, [profile])


  const checkUserWorkSpace = async () => {
    if (profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION] === workspaceId) {
      setIsWorkSpaceVerified(true);
      dispatch(fetchSubscriptionThunk());
      setIsLoading(false);
      return;
    }
    const orgDetails = profile.organizations.find(item => item.name === workspaceId);
    if (orgDetails && workspaceId) {
      const reqData: SetUserMetadataDto = {
        value: orgDetails.name
      };
      await setUserMetadataByKey(UserMetadataKey.ACTIVE_ORGANIZATION, reqData);
    } else {

      setIsWorkSpaceVerified(false);
      setIsLoading(false);
    }
  }

  return (
    <>
      {
        isLoading ? <CustomLoader /> : (isWorkSpaceVerified ? <Outlet /> : <Forbidden />)
      }
    </>
  )
}
