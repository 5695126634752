import toast from "react-hot-toast";
import { downloadCsvOrExcelZip, downloadResZip } from "../../api/form-response";
import { triggerDownload } from "../util";

// Download responses for a form in csv or excel format
export const downloadAsCsvOrExcel = async (formId: string, donwloadType: string, hasFile: boolean) => {
  let fileName = "";
  switch (donwloadType) {
    case "csv":
      if (hasFile) fileName = "form-responses-csv.zip";
      else fileName = "form-responses.csv";
      break;

    case "excel":
      if (hasFile) fileName = "form-responses-excel.zip"
      else fileName = "form-responses.xlsx";
      break;
  }
  try {
    let type: string = '';
    if (hasFile) type = 'application/zip';
    else if (!hasFile) {
      if (donwloadType === 'csv') type = 'text/csv';
      if (donwloadType === 'excel') type = 'application/octet-stream';
    }
    const res = await downloadCsvOrExcelZip(formId, donwloadType);
    const blob = new Blob([res.data], { type: type })
    triggerDownload(blob, fileName);
    toast.success(`Download as ${donwloadType} successful`);
  } catch (error) {
    toast.error(`Download as ${donwloadType} failed`);
  }
}

// Download response for single repsonse id
export const downloadSingleResponseCsv = async (formId: string, responseId: string, hasFile: boolean) => {
  if (responseId) {
    try {
      let type: string = '';
      if (hasFile) type = 'application/zip';
      else if (!hasFile) type = 'text/csv';
      let fileName = "";
      let res = await downloadResZip(formId, responseId, "csv");
      const blob = new Blob([res.data], { type: type });
      if (hasFile) fileName = `${responseId}-csv.zip`;
      else if (!hasFile) fileName = `${responseId}.csv`;
      triggerDownload(blob, fileName);
      toast.success(`Download file for response id successful`);
    } catch (error) {
      toast.error(`Download file for response id successful`);
    }
  }
}
