export enum FormState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  DELETED = 'DELETED',
  WAITING_FOR_PUBLISH = 'WAITING_FOR_PUBLISH'
}

export interface Form {
  id: string;
  name: string;
  description: string;
  state?: FormState;
  url: string;
  tags: string[];
  expiryTime?: string;
  expiryResponseCount?: number;
  isPublic: boolean;
  deletedAt?: Date;
  publishedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  config?: Record<string, any>;
  createdBy?: string;
  updatedBy?: string;
  timerConfig?: Record<string, any>;
  createdByUserName: string;
  activeUsers?: number;
  integrationIds?: string[];
}

export interface FormResponse {
  count: number;
  forms: Form[];
  current: number;
  skip: number;
}
export interface CreateFormReqDto {
  name: string;
  description?: string;
  tags: string[];
  expiryTime?: string;
  expiryResponseCount?: number;
  config?: Record<string, any>;
  timerConfig?: Record<string, any>;
}

export interface CreateFormResDto {
  name: string;
  description: string;
  isPublic: boolean;
  tags: string[];
  id: string;
  url: string;
  config?: object;
  state?: FormState;
  expiryTime?: string;
  timerConfig?: object;
  expiryResponseCount?: number;
  createdBy?: string;
  createdAt?: Date;
  createdByUserName: string;
}

export interface GetFormResDto {
  name: string;
  description: string;
  isPublic: boolean;
  tags: string[];
  id: string;
  url: string;
  config?: FormConfig;
  state?: FormState;
  expiryTime?: string;
  timerConfig?: FormTimerConfig;
  expiryResponseCount?: number;
  createdBy?: string;
  createdAt?: Date;
  createdByUserName: string;
}

export interface CustomLinksDto {
  emails: string[];
  expiryTime?: Date | null;
  formId: string;
}

// @TODO: Dto should contain only the fields that can be updated
export interface UpdateFormDto {
  name?: string;
  description?: string;
  isPublic?: boolean | null;
  // id: string;
  url?: string;
  config?: Record<string, any>;
  timerConfig?: Record<string, any>;
  state?: FormState;
  expiryTime?: string | null;
  expiryResponseCount?: number | null;
  createdBy?: string;
  createdAt?: Date;
  tags?: string[];
  integrationIds?: string[];
}


export interface FormSettingsDto {

  name: string;
  description: string;
  tags: string[];
  config: FormConfig;
  timerConfig: FormTimerConfig;
  state: FormState;
  expiryTime: string;
  expiryResponseCount?: number;
  createdBy: string;

}

export interface FormConfig {
  font: FontSettings;
  theme: ThemeConfig;
  logo?: LogoConfig;
  image?: string;
  snapShot?: string;
}

export interface LogoConfig {
  logoActiveStatus: boolean;
}

export interface ThemeConfig {
  id: Number;
  main: string;
  background: string;
}


export interface FormTimerConfig {
  timerActiveStatus: boolean;
  timer: number;
  timerInstruction: string;
}

export interface FontSettings {

  fontSize: string;
  fontWeight: string
}


export interface GetFileItemsDto{
  id:string;
  originalName : string;
  fileId : string;
  mimeType: string;
  formName:string;
  fileSize:number
}

export interface GetFilesDto {
  data: GetFileItemsDto[]
}