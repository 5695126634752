import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLatestSubscription } from "../../api/subscription";

export const fetchSubscriptionThunk = createAsyncThunk(
  "/fetchSubscription",
  async () => {
    let response = await getLatestSubscription();
    return response;
  }
)
