import React, { useState } from 'react';
import styles from './hamburger-menu.module.scss';
import { HamburgerMenuProps } from './hamburger-menu.props';

export default function HamburgerMenu(props : HamburgerMenuProps) {

    const [isMenuOpen, setisMenuOpen] = useState(false)

  return (
    <>
    {/* <div className={`${styles.main} ${props.isMenuOpen ? styles.mainActive : ""}`} onClick={()=>{ props.setIsMenuOpen(!props.isMenuOpen) }}> */}
    <div className={`${styles.menu} ${props.isMenuOpen ? styles.menuActive : ""}`}>
     <div style={{display:"flex",width:"100%"}}>
      <div style={{width:"80%",background: "var(--secondary-color)"}}>
      {props.children && props.children}
      </div>
      <div style={{width:"20%", background: "#00000096",visibility:`${props.isMenuOpen ? "visible" : "hidden"}`}} onClick={()=>{ props.setIsMenuOpen(!props.isMenuOpen) }}>
        {/* Message */}
      </div>
     </div>
    </div>
    {/* </div> */}
    <div className={styles.hamburgerBtn} onClick={()=>{ props.setIsMenuOpen(!props.isMenuOpen) }}>
            <div className={`${styles.hamBar} ${styles.one} ${props.isMenuOpen ?  styles.oneActive: ""}`} ></div>
            <div className={`${styles.hamBar} ${styles.two} ${props.isMenuOpen ?  styles.twoActive: ""}`} ></div>
            <div className={`${styles.hamBar} ${styles.three} ${props.isMenuOpen ?  styles.threeActive: ""}`} ></div>
    </div>
    </>
  )
}
