import { createSlice } from "@reduxjs/toolkit";

const stepIndexInitialState: {
  index: number;
} = { index: 1 };

export const stepIndexSlice = createSlice({
    name:"stepIndex",
    initialState: stepIndexInitialState,
    reducers:{
        updateIndex(state,action){
            state.index=action.payload;
        }
    }
});

export const { updateIndex } = stepIndexSlice.actions;

export default stepIndexSlice;
