import { configureStore } from "@reduxjs/toolkit";
import { controlSlice } from "./slices/form-control.slice";
import formresponsSlice from "./slices/form-respone.slice";
import formsSlice from "./slices/forms.slice";
import socketSlice from "./slices/socket.slice";
import stepIndexSlice from "./slices/step-index.slice";
import templatesSlice from "./slices/templates.slice";
import tokenSlice from "./slices/token.slice";
import userSlice from "./slices/user.slice";
import { activeDetailsSlice } from './slices/active-details.slice'
import validationSlice from "./slices/validation.slice";
import rolesSlice from "./slices/roles.slice";
import subscriptionSlice from "./slices/subscription.slice";
import orgSlice from "./slices/organization.slice";
import integrationsSlice from "./slices/integrations.slice";
import activePageSlice from "./slices/active-page.slice";
import conditionalLogicSlice from "./slices/conditional-logic.slice";
import apiKeysSlice from "./slices/api-keys.slice";

const store = configureStore({
  reducer: {
    forms: formsSlice.reducer,
    formControls: controlSlice.reducer,
    templates: templatesSlice.reducer,
    user: userSlice.reducer,
    token: tokenSlice.reducer,
    socket: socketSlice.reducer,
    formResponses: formresponsSlice.reducer,
    stepIndex: stepIndexSlice.reducer,
    activeDetails: activeDetailsSlice.reducer,
    validation: validationSlice.reducer,
    roles: rolesSlice.reducer,
    subscription: subscriptionSlice.reducer,
    organization: orgSlice.reducer,
    integrations: integrationsSlice.reducer,
    activePage: activePageSlice.reducer,
    conditionalLogic: conditionalLogicSlice.reducer,
    apiKeys: apiKeysSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type RootDispatch = typeof store.dispatch;
