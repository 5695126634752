import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CreateFeedbackDto, submitFeedback } from "../../api/feedback";
import { StarRating } from "../../components/feedback-page/star-rating";
import CustomButton from "../../components/v2/common/custom-button/custom-button";
import CustomInput from "../../components/v2/common/custom-input/custom-input";
import CustomTextArea from "../../components/v2/common/custom-text-area/custom-text-area";
import styles from "./feedback-page.module.scss";
import CustomText from "../../components/v2/common/custom-bodyText/custom-text";

export function FeedbackPage() {
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form, setForm] = useState<{
    comments: string,
    rating: number,
    name: string,
    email: string,
    isAnonymous: boolean
  }>({
    comments: '',
    rating: 0,
    name: '',
    email: '',
    isAnonymous: false
  });
  const [errors, setErrors] = useState(new Map<string, string>());
  const { t } = useTranslation();
  const setFormRating = (rating: number) => {
    const error = new Map(errors);
    if (rating === 0) {
      error.set('rating', `${t("feedback_rating_err")}`);
    } else {
      error.delete('rating');
    }
    setErrors(error);
    setForm({ ...form, rating: rating });
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      if (form.isAnonymous === true) {
        const data: CreateFeedbackDto = {
          rating: form.rating,
          comments: form.comments,
        }
        await submitFeedback(data);
      } else {
        const data = {
          rating: form.rating,
          comments: form.comments,
          name: form.name,
          email: form.email
        }
        await submitFeedback(data);
      }
      toast.success(t("feedback_toast_thanks"));
      navigate('/');
    } catch (err) {
      toast.error(t("feedback_toast_err"));
    }
    clearForm();
    setSubmitLoading(false);
  }

  const textValidation = (fieldName: string, value: string) => {
    const error = new Map(errors);
    if (value != null && value.trim().length === 0) {
      error.set(fieldName, `${t('feedback_field_name', { name: `${fieldName}` })}`);
    } else {
      error.delete(fieldName);
    }
    setErrors(error);
  }

  const emailValidation = (email: string) => {
    const error = new Map(errors);
    const re = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    if (email.length < 1) {
      error.set('email', `${t("enter_mail")}`);
    } else if (re.test(email)) {
      error.delete('email');
    } else {
      error.set('email', `${t("enter_valid_email")}`);
    }
    setErrors(error);
  }

  useEffect(() => {
    if (form.isAnonymous === true) {
      let error = new Map<string, string>(errors);
      error.delete('name');
      error.delete('email');
      setErrors(error);
      setForm({
        ...form,
        name: '',
        email: '',
      });
    }
  }, [form.isAnonymous])

  const disabledCheck = () => {
    if (errors.size > 0) return true;
    if (form.rating === 0 || form.comments === '') return true;
    if (form.isAnonymous === false) {
      if (form.name === '' || form.email === '') return true;
    }
    return false;
  }

  const clearForm = () => {
    setForm({
      comments: '',
      rating: 0,
      name: '',
      email: '',
      isAnonymous: false
    });
    setErrors(new Map<string, string>());
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <div className={styles.contentBox}>
          <div>{t("feedback_application_rate")}</div>
          <div className={styles.contentMargin}>
            <StarRating rating={form.rating} setRating={setFormRating} />
            {
              errors.get('rating') &&
              <div className={`${styles.topPadding} ${styles.errorText}`}>{errors.get('rating')}</div>
            }
          </div>
          <div className={`${styles.contentMargin} ${styles.inputFieldContainer}`}>
            {/* <label className={styles.inputFieldLabel} htmlFor="comments">{t("feedback_application_comments")}</label> */}
            {/* <textarea
              className={`${styles.textAreaInput}
              ${errors.get('comments') && styles.errorTextArea}`}
              name="comments" rows={3} placeholder={t("feedback_application_enter_comments")}
              value={form.comments}
              onChange={(e) => {
                setForm({ ...form, comments: e.target.value });
                textValidation('comments', e.target.value);
              }}
            >
            </textarea> */}
            <div style={{ marginBottom: '0.5rem' }}>{t("feedback_application_comments")}<span style={{color:"red"}}>*</span></div>
            <CustomTextArea value={form.comments} placeHolder={t("feedback_application_enter_comments")} getText={(value) => {
              setForm({ ...form, comments: value });
              textValidation('comments', value);
            }}></CustomTextArea>
            {
              errors.get('comments') &&
              <div className={styles.errorText}>{errors.get('comments')}</div>
            }
          </div>
          <div>
            <label htmlFor="anonymous-check" className={styles.checkBoxContainer}>
              <input className={styles.checkbox} type="checkbox" name="anonymous-check" checked={form.isAnonymous} onChange={() => { }} />
              <span className={styles.checkmark} onClick={() => { setForm({ ...form, isAnonymous: !form.isAnonymous }) }}></span>
              {t("feedback_application_anonymous")}
            </label>
          </div>
          {
            !form.isAnonymous &&
            <div>
              <div className={`${styles.contentMargin} ${styles.inputFieldContainer}`}>
                {/* <label className={styles.inputFieldLabel} htmlFor="name">{t('feedback_application_name')}</label>
                <input
                  className={`${styles.textInput}
                  ${errors.get('name') && styles.errorTextArea}`}
                  name="name" placeholder={t("feedback_application_enter_name")}
                  value={form.name}
                  onChange={(e) => {
                    setForm({ ...form, name: e.target.value });
                    textValidation('name', e.target.value);
                  }}
                /> */}
                <div style={{ marginBottom: '0.5rem' }}>{t('feedback_application_name')}</div>
                <CustomInput inputStyleType={"fade"} inputType={'text'} value={form.name} placeHolder={t("feedback_application_enter_name")} onChange={(e) => {
                  setForm({ ...form, name: e.target.value });
                  textValidation('name', e.target.value);
                }} ></CustomInput>
                {
                  errors.get('name') &&
                  <div className={styles.errorText}>{errors.get('name')}</div>
                }
              </div>
              <div className={`${styles.contentMargin} ${styles.inputFieldContainer}`}>
                {/* <label className={styles.inputFieldLabel} htmlFor="email">{t("feedback_application_email")}</label>
                <input
                  className={`${styles.textInput}
                  ${errors.get('email') && styles.errorTextArea}`}
                  name="email" placeholder={t("feedback_application_enter_email")}
                  value={form.email}
                  onChange={(e) => {
                    setForm({ ...form, email: e.target.value });
                    emailValidation(e.target.value);
                  }}
                /> */}
                <div style={{ marginBottom: '0.5rem' }}>{t("feedback_application_email")}</div>
                <CustomInput inputStyleType={"fade"} inputType={'text'} value={form.email} placeHolder={t("feedback_application_enter_email")} onChange={(e) => {
                  setForm({ ...form, email: e.target.value });
                  emailValidation(e.target.value);
                }} ></CustomInput>
                {
                  errors.get('email') &&
                  <div className={styles.errorText}>{errors.get('email')}</div>
                }
              </div>
            </div>
          }
          <div className={styles.buttonContainer}>
            <CustomButton
              style={{ width: 'fit-content', minWidth: '6rem',boxShadow:'none' }}
              buttonType={'button'}
              classType={'secondary'}
              // onclick={clearForm}
              onClick={clearForm}
            >
              <CustomText textType="t4">
                {t('clear')}
              </CustomText>
            </CustomButton>
            <CustomButton
              style={{ width: 'fit-content', minWidth: '6rem',boxShadow:'none' }}
              buttonType={'submit'}
              classType={'quaternary'}
              disabled={disabledCheck()}
              onClick={() => { }}
            >
              <CustomText style={{ color: "white" }} textType="t4">
                {t('feedback_application_submit')}
              </CustomText>
            </CustomButton>
          </div>
        </div>
      </form >
    </div >
  )
}
