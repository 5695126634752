import styles from './multiline-tags.module.scss';
import { KeyboardEvent, useState } from 'react';
import { MultilineTagsProps } from './multiline-tags.props';
import { useTranslation } from 'react-i18next';
import svgDeleteTag from "../../../../assets/v2/icons/delete-tag.svg";
import CustomButton from '../custom-button/custom-button';

export function MultilineTags(props: MultilineTagsProps) {
  const { t } = useTranslation();
  const [onKeyUpState, setOnKeyUpState] = useState<KeyboardEvent<HTMLInputElement>>();

  const removeTag = (indexToRemove: number) => {
    const newTags = [...props.value.filter((_, index) => index !== indexToRemove)];
    props.onChange(newTags);
  };

  const onKeyUpHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    setOnKeyUpState(event);
    if (event.key === 'Enter' || event.code === 'Space') {
      addTag(event)
    }
  };

  const onKeyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    if ((event.key === 'Backspace') && ((event.target as HTMLInputElement).value === '')) {
      const len = props.value.length;
      if (len > 0) {
        const newTags = props.value.slice(0, len - 1);
        props.onChange(newTags);
      }
    }
  };

  const addTag = (event: KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.value.trim() !== '') {
      const valuesToAppend = target.value.trim().split(/[ ,]+/);
      for (const v of valuesToAppend) {
        if (props.setErrors) {
          if ((props.type === 'email') && !isEmail(v)) {
            props.setErrors(t(`publish_panel_enter_valid_email`));
            return;
          }
          props.setErrors('');
        }
      }
      const newTags = [...props.value, ...valuesToAppend];
      props.onChange(newTags);
      target.value = '';
    }
  };

  const isEmail = (email: string) => {
    const re = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    if (re.test(email) && props.setErrors) {
      return true;
    }
    return false;
  }

  return (
    <div className={styles.tagContainer}>
      <div className={`${styles.tagsInput}`}>
        {
          props.value && (props.value.length > 0) && <ul className={`${styles.tags}`}>
            {
              props.value.map((tag, index) => {
                return (
                  <li key={index} className={`${styles.multiTag}`}>
                    <span style={{width:'90%'}}>{tag}</span>
                    <span className={`${styles.tagCloseIcon}`} onClick={() => removeTag(index)}>
                      <img src={svgDeleteTag} alt="delete-tag"></img>
                    </span>
                  </li>
                )
              })
            }
          </ul>
        }
        <input onKeyUp={(e) => { onKeyUpHandler(e) }} onKeyDown={onKeyDownHandler} placeholder='Press Enter or space to add tags' />
      </div>
      <CustomButton style={{width:'10%',display:'flex',justifyContent:'center',alignItems:'center',alignSelf:'end'}} buttonType='button' classType='quaternary' onClick={() => { if (onKeyUpState) addTag(onKeyUpState) }}> + </CustomButton>
    </div>
  );
}
