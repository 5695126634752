import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/v2/common/custom-button/custom-button";
import styles from "./forbidden.module.scss";
import CustomHeading from "../../components/v2/common/custom-heading/custom-heading";
import CustomText from "../../components/v2/common/custom-bodyText/custom-text";
import { useEffect } from "react";
import { hasActiveOrg } from "../../util/access-validator/access-validator";
import svgForbidden from "../../assets/v2/403.svg";

export default function Forbidden(props: ForbiddenProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!hasActiveOrg()) {
      navigate('/profile');
    }
  }, []);

  return (
    <>
      {
        hasActiveOrg() &&
        <div className={`${styles.parentContainer}`}>
          <div className={`${styles.section}`}>
            <div className={styles.content}>
              <img src={svgForbidden} alt="403"></img>
              <CustomHeading headerType="h3">
                <div className={`is-primary-color is-secondary-font is-highlighted`}>{t('permission_denied')}</div>
              </CustomHeading>
              <CustomText textType="t3">
                <div className={`is-primary-color is-secondary-font is-highlighted`}>
                  {t('page_access_denied_text')}
                </div>
              </CustomText>
              <div className={styles.btnContainer}>
                {!props.isInPage ? <CustomButton buttonType='button' classType='quaternary' onClick={() => navigate('/profile')}>{t('page_not_found_go_to_dashboard')} </CustomButton> : <></>}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export interface ForbiddenProps {
  isInPage?: boolean; // value should consider as label in chart
}
