import axios from 'axios';
import { Template } from '../store/models/template.interface';
import { GenerateApiUrl } from './generate-api-url';

export async function fetchAllTemplates(filters?: { tags?: string, name?: string }) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `templates`, false);
  let params = {};
  if (filters?.name !== '') {
    Object.assign(params, { name: filters?.name });
  }
  if (filters?.tags !== '') {
    Object.assign(params, { tags: filters?.tags });
  }

  const response = await axios.get<{ templates: Template[] }>(apiUrl, { params: params });
  return response;
}

export async function getTemplateTags() {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `templates/all-tags`, false);
  try {
    const response = await axios.get<{ tags: string[] }>(apiUrl);
    return response.data.tags;
  } catch (err) {
    return [];
  }
}
