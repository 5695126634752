import { useParams } from "react-router-dom";
import { LogicBuilderPane } from "../logic-builder-pane/logic-builder-pane";
import { LogicVisualizer } from "../logic-visualizer/logic-visualizer";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { useSetupLogicBuilder } from "./hooks/use-setup-logic-builder";
import { UseLogicBuilderRes } from "./models/use-logic-builder.interface";
import { FormControl } from "../../../../../store/models/form-controls/form-control.interface";

export function LogicBuilder(props: { logicBuilder: UseLogicBuilderRes, formControls: FormControl[] }) {
  const setupLogicBuilder = useSetupLogicBuilder(props.formControls);

  return (
    <>
      <LogicBuilderPane srcControls={setupLogicBuilder.srcLogicControls} targetControls={setupLogicBuilder.tgtLogicControls} logicBuilder={props.logicBuilder} controls={setupLogicBuilder.logicControl} conditionalLogic={props.logicBuilder.conditionalLogic} logicControlLookup={setupLogicBuilder.logicControlsLookup} />
      <LogicVisualizer controls={setupLogicBuilder.logicControl} conditionalLogic={props.logicBuilder.conditionalLogic} />
    </>
  )
}
