import { SingleValue, MultiValue } from "react-select";
import { CreateConditionalEdge, ConditionComparatorEnum } from "../../../../../store/models/form-builder/conditional-logic";
import CustomButton from "../../../common/custom-button/custom-button";
import CustomSelect from "../../../common/custom-select/custom-select";
import { DropdownDataItem } from "../../../common/custom-select/custom-select.props";
import { dropdownInputControls, logicActionData, comparatorData, numberComparatorData, tagInputControls } from "../logic-builder-pane/models/panel-data.constants";
import { LogicControl } from "../logic-builder/models/logic-control.props";
import { ConditionalLogicCreateProps } from "./conditional-logic-create.props";
import styles from './conditional-logic-create.module.scss';
import { useTranslation } from "react-i18next";
import { MultilineTags } from '../../../common/multiline-tags/multiline-tags';
import { useState } from "react";

export function ConditionalLogicCreate(props: ConditionalLogicCreateProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const onClickAdd = async () => {
    setIsLoading(prev => true);
    props.createLogic.requiredValidation();
    if (props.createLogic.srcControl && props.createLogic.tgtControl && props.createLogic.comparator) {
      for (const logic of props.logicBuilder.conditionalLogic) {
        if (logic.source === props.createLogic.srcControl.id && logic.target === props.createLogic.tgtControl.id) {
          props.createLogic.assignErrors('duplicate', t('cond_edge_dup_err'));
          return;
        }
      }
      const payload: CreateConditionalEdge = {
        sourceControlId: props.createLogic.srcControl.id,
        targetControlId: props.createLogic.tgtControl.id,
        value: {
          type: props.createLogic.comparator.value as ConditionComparatorEnum,
          value: dropdownInputControls.includes(props.createLogic.srcControl.controlType) ? props.createLogic.dropDownValues.map(val => val.value) : props.createLogic.tagValues
        }
      }
      await props.logicBuilder.createConditionalLogic(payload);
      props.createLogic.clearValues();
    }
    setIsLoading(prev => false);
  }

  return (
    <>
      <div className={styles.logicContainer}>
        {/* action */}
        <div className={styles.panel}>
          <CustomSelect data={logicActionData} selectStyleType={'fade'} selectedData={(data) => { }} value={logicActionData[0]} placeholder={'Select action'} />
        </div>

        {/* target control */}
        <div className={styles.panel}>
          <CustomSelect
            data={props.targetControls.map((ctrl: LogicControl) => {
              return {
                label: ctrl.label,
                value: ctrl.id
              }
            })}
            selectStyleType={'fade'} placeholder={'Select target control'}
            value={props.createLogic.tgtControl ? { label: props.createLogic.tgtControl.label, value: props.createLogic.tgtControl.id } : undefined}
            isClearable
            selectedData={(data) => {
              const dataValue = data as SingleValue<DropdownDataItem>;
              const control = props.targetControls.find(val => val.id === dataValue?.value);
              props.createLogic.setTgtControl(control);
              props.createLogic.targetControlValidation(control);
            }}
          />
        </div>
        {
          props.createLogic.errors.tgtControl &&
          <div className={styles.errorText}>{props.createLogic.errors.tgtControl}</div>
        }
        {
          props.createLogic.errors.controlsErr &&
          <div className={styles.errorText}>{props.createLogic.errors.controlsErr}</div>
        }

        {/* source control  */}
        <div className={styles.panel}>
          <div className={styles.conBlock}><div>if</div></div>
          <CustomSelect
            data={props.srcControls.map((ctrl: LogicControl) => {
              return {
                label: ctrl.label,
                value: ctrl.id
              }
            })} selectStyleType={'fade'} placeholder={'Select source control'}
            value={props.createLogic.srcControl ? { label: props.createLogic.srcControl.label, value: props.createLogic.srcControl.id } : undefined}
            isClearable
            selectedData={(data) => {
              const dataValue = data as SingleValue<DropdownDataItem>;
              const control = props.srcControls.find(val => val.id === dataValue?.value);
              props.createLogic.setSrcControl(control);
              props.createLogic.srcControlValidation(control);
            }}
          />
        </div>
        {
          props.createLogic.errors.srcControl &&
          <div className={styles.errorText}>{props.createLogic.errors.srcControl}</div>
        }

        {/* comparator  */}
        {
          props.createLogic.srcControl &&
          <div className={styles.panel}>
            <CustomSelect
              data={props.createLogic.srcControl.controlType === 'number' ? [...comparatorData, ...numberComparatorData] : comparatorData}
              selectStyleType={'fade'} isClearable value={props.createLogic.comparator}
              selectedData={(data) => {
                props.createLogic.setComparator(data as SingleValue<DropdownDataItem>);
                props.createLogic.comparatorValidation(data as SingleValue<DropdownDataItem>);
              }}
            />
          </div>
        }
        {
          props.createLogic.errors.comparator &&
          <div className={styles.errorText}>{props.createLogic.errors.comparator}</div>
        }

        {/* values */}
        {
          props.createLogic.srcControl && tagInputControls.includes(props.createLogic.srcControl.controlType) &&
          <div className={styles.panel}>
            <MultilineTags type='text' value={props.createLogic.tagValues} onChange={(tags: string[]) => { props.createLogic.setTagValues(tags); props.createLogic.tagsValidation(tags) }} />
          </div>
        }
        {
          props.createLogic.errors.tags &&
          <div className={styles.errorText}>{props.createLogic.errors.tags}</div>
        }
        {
          props.createLogic.srcControl && dropdownInputControls.includes(props.createLogic.srcControl.controlType) &&
          <div className={styles.panel}>
            <CustomSelect
              data={props.createLogic.srcControl.options?.map(val => {
                return { label: val, value: val }
              })}
              selectStyleType={'fade'} isMultiSelect={true} value={props.createLogic.dropDownValues} isClearable
              selectedData={(data) => {
                props.createLogic.setDropDownValues(data as MultiValue<DropdownDataItem>);
                props.createLogic.dropdownValidation((data as MultiValue<DropdownDataItem>).map(val => val.value));
              }}
            />
          </div>
        }
        {
          props.createLogic.errors.dropdown &&
          <div className={styles.errorText}>{props.createLogic.errors.dropdown}</div>
        }
      </div>
      {
        props.createLogic.errors.duplicate &&
        <div className={styles.errorText}>{props.createLogic.errors.duplicate}</div>
      }
      <div className={styles.panel}>
        <CustomButton buttonType='button' style={{ height: '100%' }} classType='tertiary' onClick={() => { props.createLogic.clearValues() }} >Clear</CustomButton>
        <CustomButton buttonType='button' classType='quaternary' onClick={() => { onClickAdd() }} disabled={props.createLogic.isValuesDefined.has(false) || Object.keys(props.createLogic.errors).length > 0 || isLoading} isLoading={isLoading} >Add condition</CustomButton>
      </div>
    </>
  )
}
