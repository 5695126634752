import styles from './custom-node.module.scss';
import { Handle, Position } from "reactflow";

interface CustomNodeData {
  label: string;
  type: string;
}

interface NodeProps {
  data: CustomNodeData;
}

export function CustomNode(props: NodeProps) {

  return (
    <>
      <Handle type="target" position={Position.Top} />
      <div className={`${styles.customNodeContainer} ${props.data.type === 'page' ? styles.pageCtrl : ''} ${props.data.type === 'section' ? styles.secControl : ''}`}>
        <div className={styles.customNode}>
          {props.data.label}
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} />
    </>
  )
}
