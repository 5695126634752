import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../models/load-state.enum";
import { Template } from "../models/template.interface";
import { fetchAllTemplatesThunk } from "../thunks/templates.thunk";

const templatesInitialState: {
  data: Template[];
  fetchLoadingState: LoadState;
} = { data: [], fetchLoadingState: LoadState.IDLE };

export const templatesSlice = createSlice({
  name: "templates",
  initialState: templatesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTemplatesThunk.pending, (state) => {
        state.fetchLoadingState = LoadState.LOADING;
      })
      .addCase(fetchAllTemplatesThunk.fulfilled, (state, action) => {
        let data: Template[] = action.payload;
        state.data = data;
        state.fetchLoadingState = LoadState.IDLE;
      })
      .addCase(fetchAllTemplatesThunk.rejected, (state) => {
        state.fetchLoadingState = LoadState.ERROR;
      })
  }
});

export default templatesSlice;
