import { useContext, useEffect, useRef, useState } from 'react'
import styles from './control-properties-panel.module.scss';
import CustomHeading from '../../common/custom-heading/custom-heading';
import CustomInput from '../../common/custom-input/custom-input';
import CustomButton from '../../common/custom-button/custom-button';
import CustomSelect from '../../common/custom-select/custom-select';
import CustomText from '../../common/custom-bodyText/custom-text';
import CustomTime from '../../common/custom-time/custom-time';
import { SingleValue } from 'react-select';
import { ControlPropertiesPanelProp } from './controls-properties-panel.props';
import { useTranslation } from 'react-i18next';
import UseValidation from '../../../../util/validator/use-validation';
import { FormControl } from '../../../../store/models/form-controls/form-builder-control';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../store/store';
import RichTextEditorModal from '../../../common/rich-text-editor-modal/rich-text-editor-modal';
import { updateControlThunk } from '../../../../store/thunks/controls.thunk';
import { SocketContext } from '../../../../context/socket';
import toast from 'react-hot-toast';
import CustomCalender from '../../common/custom-calendar/custom-calendar';
import { UpdateFormControlDto } from '../../../../store/models/form-controls/update-form-control';
import { DropdownDataItem } from '../../common/custom-select/custom-select.props';
import { RaffleProperties } from './raffle-properties/raffle-properties';
import svgDelete from "../../../../assets/v2/icons/delete.svg";
import svgInfo from "../../../../assets/v2/icons/info.svg";
import CustomCheckbox from '../../common/custom-checkbox/custom-checkbox';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { MultilineTags } from '../../common/multiline-tags/multiline-tags';

export default function ControlPropertiesPanel(props: ControlPropertiesPanelProp) {
  const { t } = useTranslation();
  const dispatch: RootDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [formControl, setFormControl] = useState<FormControl>(props.control);
  const { numberValidation, textValidation, textAreaValidation, timeValidation, dateValidation, optionsValidation, fileValidation, labelValidation, raffleModeValidation, raffleModeBlackListValidation, quizModeValidation } = UseValidation();
  const controlErrorMessage = useSelector((state: RootState) => { return state.validation.controlValidation });
  const [optionsArr, setOptionsArr] = useState<string[]>([]);
  const [isMandatory, setIsMandatory] = useState(false);
  const prevControlId = useRef('');
  const [isDirty, setIsDirty] = useState(false);
  const [acceptType, setAcceptType] = useState<DropdownDataItem>({ value: '', label: '' });
  const [activeTimeSelector, setActiveTimeSelector] = useState<'start-time' | 'end-time'>();
  const toggleStartTime = useRef<() => void>();
  const toggleEndTime = useRef<() => void>();
  const [blackListInput, setBlackListInput] = useState('');
  const [notifyScore, setNotifyScore] = useState(props.control.config?.notifyScore);
  const [controlScore, setControlScore] = useState(0);
  const toggleStartDate = useRef<() => void>();
  const toggleEndDate = useRef<() => void>();
  const [activeDateSelector, setActiveDateSelector] = useState<'start-date' | 'end-date'>();

  useEffect(() => {
    if (props.control?.config?.quiz?.score) {
      setControlScore(Number(props.control.config.quiz.score));
    }
    setFormControl(props.control);
  }, [props.control]);

  useEffect(() => {
    if (toggleStartTime.current && toggleEndTime.current && activeTimeSelector) {
      if (activeTimeSelector === 'start-time') toggleEndTime.current();
      else if (activeTimeSelector === 'end-time') toggleStartTime.current();
    }
  }, [activeTimeSelector])

  useEffect(() => {
    if (toggleStartDate.current && toggleEndDate.current && activeDateSelector) {
      if (activeDateSelector === 'start-date') toggleEndDate.current();
      else if (activeDateSelector === 'end-date') toggleStartDate.current();
    }
  }, [activeDateSelector])

  useEffect(() => {
    if (formControl.config?.acceptType) {
      const acceptType = formControl.config["acceptType"] as string;
      if (acceptType) setAcceptType({ value: acceptType, label: acceptType });
    }
    if (prevControlId.current === formControl.id) {
      validator(formControl);
    } else {
      setInitialValues();
      prevControlId.current = formControl.id;
    }
    setIsMandatory(formControl.config.isMandatory && (formControl.config.isMandatory === true));
    setNotifyScore(formControl.config.notifyScore && (formControl.config.notifyScore === true));
  }, [formControl]);

  const setInitialValues = () => {
    setIsDirty(false);
    setOptionsArr(formControl.options.length === 0 ? [''] : [...formControl.options, '']);
  }

  const validator = (data: FormControl) => {
    if (props.control.controlType !== 'raffle' && props.control.controlType !== 'quiz') labelValidation(formControl);
    switch (data.controlType) {
      case "number":
        return numberValidation(data, props.isQuizEnabled);
      case "text":
        return textValidation(data, props.isQuizEnabled);
      case "textarea":
        return textAreaValidation(data);
      case "time":
        return timeValidation(data);
      case "date":
        return dateValidation(data, props.isQuizEnabled);
      case "checkbox":
      case "radio":
      case "dropdown":
        let tempArr: string[] = data.options;
        tempArr.forEach((item, index) => {
          if (((tempArr.length - 1) !== index) && (item.length === 0)) {
            tempArr.splice(index, 1);
          }
        });
        return optionsValidation(data, props.isQuizEnabled);
      case "file":
        return fileValidation(data);
      case "raffle":
        return raffleModeValidation(data);
      case "quiz":
        return quizModeValidation(data);
    }
    return { isError: false, errors: {} }
  };

  const currentControlErr = (props.control !== null) && controlErrorMessage && controlErrorMessage[props.control.formId] && controlErrorMessage[props.control.formId][props.control.id] ? controlErrorMessage[props.control.formId][props.control.id] : null;

  const onClick = async () => {
    try {
      let options: string[] = [];
      if (formControl.options.length) {
        options = formControl.options.map(o => o.trimEnd());
      }
      if (formControl.config?.quiz?.correctAnswer?.length) {
        let answers: string[] = [];
        answers = formControl.config.quiz.correctAnswer.map((a: any) => a.trimEnd());
        formControl.config = { ...formControl.config, quiz: { ...formControl.config.quiz, correctAnswer: answers } };
      }
      const payload: UpdateFormControlDto = {
        id: formControl.id,
        formId: formControl.formId,
        config: formControl.config,
        label: formControl.label,
        labelHTML: formControl.labelHTML,
        controlIndex: props.controlIndex,
        parentType: props.parentType,
        options: options,
        parentIndex: props.parentIndex,
        grandParentIndex: props.grandParentIndex
      };
      try {
        if (props.control.controlType === 'raffle') {
          if (props.control.config.userInfo.includes('phone') && !formControl.config.userInfo.includes('phone')) {
            const mobileCtrl = props.control.children.find(val => val.controlType === 'mobile number');
            if (mobileCtrl) {
              props.deleteControl({ controlIndex: mobileCtrl.order, formControl: mobileCtrl, formId: props.control.formId, order: mobileCtrl.order, parentId: props.control.id, parentType: 'section', grandParentIndex: props.parentIndex, parentIndex: props.controlIndex });
            }
          } else if (!props.control.config.userInfo.includes('phone') && formControl.config.userInfo.includes('phone')) {
            props.createControl({ controlIndex: 1, controlName: 'mobile number', parentId: props.control.id, parentType: 'section', grandParentIndex: props.parentIndex, parentIndex: props.controlIndex });
          }
          if (props.control.config.userInfo.includes('email') && !formControl.config.userInfo.includes('email')) {
            const emailCtrl = props.control.children.find(val => val.controlType === 'email');
            if (emailCtrl) {
              props.deleteControl({ controlIndex: emailCtrl.order, formControl: emailCtrl, formId: props.control.formId, order: emailCtrl.order, parentId: props.control.id, parentType: 'section', grandParentIndex: props.parentIndex, parentIndex: props.controlIndex });
            }
          } else if (!props.control.config.userInfo.includes('email') && formControl.config.userInfo.includes('email')) {
            props.createControl({ controlIndex: 0, controlName: 'email', parentId: props.control.id, parentType: 'section', grandParentIndex: props.parentIndex, parentIndex: props.controlIndex });
          }
        }
      } catch (e) {
        console.log(e);
      }
      if (formControl.config?.quiz?.score && (props.score !== undefined) && props.setScore) {
        props.setScore(Number((props.score + Number(formControl.config.quiz.score) - controlScore).toFixed(1)));
      }
      if (payload) {
        await dispatch(updateControlThunk({ socket: socket, payload: payload })).unwrap();
        setIsDirty(false);
        setBlackListInput('');
        toast.success(t("builder_form_cntrl_setting_updated"));
        if (props.setIsActionsPanelOpen) {
          props.setIsActionsPanelOpen(false);
        }
      }
    } catch (err) {
      toast.error(t("builder_form_cntrl_setting_update_fail"));
    }
  }

  const clearValues = () => {
    const control = props.lookupCtrls.find((ctrl) => ctrl.name === formControl.controlType);
    if (formControl.controlType === 'raffle' && JSON.stringify(control?.config) === JSON.stringify(formControl.config)) {
      raffleModeBlackListValidation(formControl, '');
      return;
    }
    setIsDirty(true);
    if (control) {
      if (formControl.controlType === 'raffle') {
        raffleModeBlackListValidation(formControl, '');
        setBlackListInput('');
        setFormControl({
          ...formControl,
          config: { ...control.config, blackLists: { ...props.control.config.blackLists } },
          label: '',
          labelHTML: undefined,
          options: []
        })
      }
      else {
        setFormControl({
          ...formControl,
          config: control.config,
          label: '',
          labelHTML: undefined,
          options: []
        });
      }
    }
    setOptionsArr(['']);
  };

  function removeLastWord(str: string, removeStr: string) {
    const lastIndexOfSpace = str.lastIndexOf(removeStr);
    return str.substring(0, lastIndexOfSpace);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {/* --common--- */}
      {
        props.control.controlType !== '' &&
        props.control.controlType !== 'raffle' &&
        props.control.controlType !== 'quiz' &&
        <div>
          <CustomHeading headerType='h4' style={{ marginBottom: '0.2rem' }}>
            <div className={styles.labelContainer}>
              <div>{t("builder_label")}</div>
              <div className={styles.bottomCheckToolTip} tool-tip={`${t("builder_label_tooltip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                <img className={styles.toolTipImg} src={svgInfo} alt="" />
              </div>
            </div>
          </CustomHeading>
          <RichTextEditorModal
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            control={formControl}
            onChange={(e) => {
              const content = e.getCurrentContent();
              const rawContentState = convertToRaw(content);
              const blocks = rawContentState.blocks;
              let markup = draftToHtml(rawContentState);
              let value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
              if (blocks.length && (blocks.length === 1) && (blocks[0].text === '')) {
                value = '';
                markup = '';
              }
              setIsDirty(true);
              setFormControl({
                ...formControl,
                label: value.trim(),
                labelHTML: removeLastWord(markup, '\n'),
              });
            }}
            placeHolder={t("builder_placeholder_label")}
            showTextStyle={true}
            errorText={currentControlErr?.["label"] ? controlErrorMessage[props.control.formId][props.control.id]["label"] : undefined}
          />
        </div>
      }
      {/* Required switch */}
      {
        (props.control.controlType !== '') &&
        (props.control.controlType !== 'label') &&
        (props.control.controlType !== 'raffle') &&
        (props.control.controlType !== 'quiz') &&
        <div className={styles.requiredContainer}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <CustomHeading headerType='h4'>
              <div>{t("properties_constants_required")}</div>
            </CustomHeading>
            <div className={styles.topCheckToolTip} tool-tip={`Enable the toggle to make this ${props.control.controlType} field mandatory to answer.`} style={{ width: '25%', position: 'relative', cursor: 'pointer' }} >
              <img className={styles.toolTipImg} src={svgInfo} alt="" />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label className={styles.switch}>
              <input
                type={"checkbox"}
                onChange={(e) => {
                  if (formControl) {
                    setIsDirty(true);
                    setFormControl({
                      ...formControl,
                      config: {
                        ...formControl.config,
                        "isMandatory": !isMandatory
                      }
                    })
                  }
                }}
                value={''}
                checked={isMandatory}
                id="logo"
              />
              <span className={styles.slider}></span>
            </label>
          </div>
        </div>
      }
      {/* Placeholder */}
      {
        (props.control.controlType !== '') &&
        (props.control.controlType !== 'label') &&
        (props.control.controlType !== 'radio') &&
        (props.control.controlType !== 'checkbox') &&
        (props.control.controlType !== 'date') &&
        (props.control.controlType !== 'time') &&
        (props.control.controlType !== 'raffle') &&
        (props.control.controlType !== 'quiz') &&
        <div className={styles.propertyContainer}>
          <CustomHeading headerType='h4'>
            <div className={styles.labelContainer}>
              <div>{t("properties_constants_placeholder")}</div>
              <div className={styles.topCheckToolTip} tool-tip={`${t("builder_placeholder_tooltip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                <img className={styles.toolTipImg} src={svgInfo} alt="" />
              </div>
            </div>
          </CustomHeading>
          <CustomInput inputStyleType='fade' inputType='text' value={formControl?.config?.placeHolder} placeHolder='' onChange={(e) => {
            if (formControl) {
              setIsDirty(true);
              setFormControl({
                ...formControl,
                config: {
                  ...formControl.config,
                  "placeHolder": e.target.value
                }
              })
            }
          }} />
        </div>
      }

      {/* --text--  --text area--
            maxCharacter */}

      {
        (props.control.controlType === "text") &&
        <div className={styles.propertyContainer}>
          <CustomHeading headerType='h4'>
            <div className={styles.labelContainer}>
              <div>{t("properties_constants_maximum_character")}</div>
              <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_maximum_character_tip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                <img className={styles.toolTipImg} src={svgInfo} alt="" />
              </div>
            </div>
          </CustomHeading>
          <CustomInput inputStyleType='fade' inputType='number' value={formControl?.config?.maxCharacter} placeHolder='' onChange={(e) => {
            if (formControl) {
              setIsDirty(true);
              setFormControl({
                ...formControl,
                config: {
                  ...formControl.config,
                  "maxCharacter": e.target.value !== "" ? e.target.value : null
                }
              })
            }
          }} />
          {
            currentControlErr?.["maxCharacter"]
              ? <CustomText textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["maxCharacter"]))}</CustomText>
              : <CustomText textType='t8'><div>{`${t('properties_constants_maximum_character_helper')}`}</div></CustomText>
          }
          {props.isQuizEnabled &&
            <div>
              <CustomHeading headerType='h4' style={{ paddingTop: "1rem" }}>
                <div className={styles.labelContainer}>
                  <div>{`${t("correct_answer_text")}`}</div>
                  <div className={styles.topCheckToolTip} tool-tip={`${t("builder_correct_answer_tooltip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                    <img className={styles.toolTipImg} src={svgInfo} alt="" />
                  </div>
                </div>
              </CustomHeading>
              <MultilineTags type='text' value={props.control?.config?.quiz?.correctAnswer} onChange={(tags) => {
                setIsDirty(true);
                setFormControl({
                  ...formControl,
                  config: {
                    ...formControl.config,
                    "quiz": {
                      ...formControl.config.quiz,
                      correctAnswer: tags.length > 0 ? tags : []

                    }
                  }
                })
              }} />
              {
                currentControlErr?.["correctAnswer"]
                && <CustomText style={{ marginTop: "0.25rem" }} textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["correctAnswer"]))}</CustomText>
              }
              <div className={styles.infoText}>{`${t("enter_add_value")}`}</div>

              <CustomHeading headerType='h4' style={{ paddingTop: "1rem" }}>
                <div className={styles.labelContainer}>
                  <div>{`${t("total_score_text")}`}</div>
                  <div className={styles.topCheckToolTip} tool-tip={`${t("builder_set_score_tooltip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                    <img className={styles.toolTipImg} src={svgInfo} alt="" />
                  </div>
                </div>
              </CustomHeading>
              <CustomInput value={formControl?.config?.quiz?.score} inputStyleType='fade' inputType='number' onChange={(e) => {
                if (formControl) {
                  setIsDirty(true);
                  setFormControl({
                    ...formControl,
                    config: {
                      ...formControl.config,
                      "quiz": {
                        ...formControl.config.quiz,
                        score: e.target.value
                      }
                    }
                  })
                }
              }} />
              {
                currentControlErr?.["score"]
                  ? <CustomText style={{ marginTop: "0.25rem" }} textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["score"]))}</CustomText>
                  : <CustomText style={{ paddingTop: "0.25rem" }} textType='t8'><div>{`${t('form_property_quiz_score_helper')}`}</div></CustomText>
              }
            </div>
          }
        </div>
      }

      {
        (props.control.controlType === "textarea") &&
        <>
          <div className={styles.propertyContainer}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>{t("properties_constants_maximum_character")}</div>
                <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_maximum_character_tip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <CustomInput inputStyleType='fade' value={formControl?.config?.maxCharacter} inputType='number' placeHolder='' onChange={(e) => {
              if (formControl) {
                setIsDirty(true);
                setFormControl({
                  ...formControl,
                  config: {
                    ...formControl.config,
                    "maxCharacter": e.target.value !== "" ? e.target.value : null
                  }
                })
              }
            }} />
            {
              currentControlErr?.["maxCharacter"]
                ? <CustomText textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["maxCharacter"]))}</CustomText>
                : <CustomText textType='t8'><div>{`${t('properties_constants_maximum_character_helper')}`}</div></CustomText>
            }
          </div>
          <div className={styles.propertyContainer}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>{t("properties_constants_maximum_rows")}</div>
                <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_maximum_rows_tip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <CustomInput inputStyleType='fade' inputType='number' value={formControl?.config?.maxRows} placeHolder='' onChange={(e) => {
              if (formControl) {
                setIsDirty(true);
                setFormControl({
                  ...formControl,
                  config: {
                    ...formControl.config,
                    "maxRows": e.target.value !== "" ? e.target.value : null
                  }
                })
              }
            }} />
            {
              currentControlErr?.["maxRows"]
                ? <CustomText textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["maxRows"]))}</CustomText>
                : <CustomText textType='t8'><div>{`${t('properties_constants_maximum_rows_helper')}`}</div></CustomText>
            }
          </div>
        </>
      }
      {
        (props.control.controlType === "checkbox" || props.control.controlType === "radio") &&
        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
          <CustomHeading headerType='h4'>
            <div className={styles.labelContainer}>
              <div>{t("properties_constants_layout")}</div>
              <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_layout_tip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                <img className={styles.toolTipImg} src={svgInfo} alt="" />
              </div>
            </div>
          </CustomHeading>
          <CustomSelect
            data={[
              { value: 'column', label: `${t("properties_constants_column")}` },
              { value: 'row', label: `${t("properties_constants_row")}` },
            ]}
            selectStyleType={'fade'}
            selectedData={(item) => {
              setIsDirty(true);
              const layout = (item as SingleValue<DropdownDataItem>)?.value;
              setFormControl({ ...formControl, config: { ...formControl.config, layout: layout } })
            }}
            value={formControl.config.layout === 'row' ? { value: 'row', label: `${t("properties_constants_row")}` } : { value: 'column', label: `${t("properties_constants_column")}` }} isClearable={true}
          ></CustomSelect>
        </div>
      }
      {
        ((props.control.controlType === "checkbox") || (props.control.controlType === "radio") || (props.control.controlType === 'dropdown')) &&
        <>
          <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>{t("properties_constants_options")}</div>
                <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_add_options", { controlType: props.control.controlType })}`} style={{ position: 'relative', cursor: 'pointer' }} >
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <div className={styles.options}>
              {optionsArr.map((item: string, index: number) => (
                <div className={styles.optionContainer} key={index}>
                  <CustomInput
                    inputStyleType='fade'
                    inputType='text'
                    key={index}
                    value={item}
                    onChange={(e) => {
                      setIsDirty(true);
                      const arr = [...optionsArr];
                      if (e.target.value.trim().length !== 0) {
                        arr[index] = e.target.value;
                        const res = arr.filter(item => item !== '');
                        setFormControl({ ...formControl, options: res });
                        setOptionsArr([...arr]);
                        if (optionsArr.length - 1 === index) {
                          setOptionsArr([...arr, '']);
                        }
                      }
                      else {
                        e.target.value = '';
                        arr[index] = e.target.value;
                        const res = arr.filter(item => item !== '');
                        setFormControl({ ...formControl, options: arr });
                        setFormControl({ ...formControl, options: res });
                        setOptionsArr([...arr]);
                      }
                    }}
                    placeHolder={t('properties_constants_options')}
                    style={{ paddingRight: "1.75rem" }}
                  />
                  <button
                    className={styles.removeButton}
                    onClick={() => {
                      setIsDirty(true);
                      const arr: string[] = [...optionsArr];
                      arr.splice(index, 1);
                      const res = arr.filter(item => item !== '');
                      setOptionsArr(arr);
                      setFormControl({ ...formControl, options: res });
                    }}
                    disabled={index === optionsArr.length - 1}
                    tabIndex={-1}
                  >
                    <img src={svgDelete} alt="delete" />
                  </button>
                </div>
              ))}
            </div>
            {
              currentControlErr?.["options"]
                ? <CustomText textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["options"]))}</CustomText>
                : <CustomText textType='t8'><div>{`${t("properties_constants_options_helper")}`}</div></CustomText>
            }
          </div>
          {props.control.controlType === 'checkbox' && props.isQuizEnabled &&
            <div>
              <CustomHeading headerType='h4'>
                <div className={styles.labelContainer}>
                  <div>{`${t("correct_answer_text")}`}</div>
                  <div className={styles.topCheckToolTip} tool-tip={`${t("builder_correct_answer_tooltip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                    <img className={styles.toolTipImg} src={svgInfo} alt="" />
                  </div>
                </div>
              </CustomHeading>
              <MultilineTags type='text' value={props.control?.config?.quiz?.correctAnswer} onChange={(tags) => {
                setIsDirty(true);
                setFormControl({
                  ...formControl,
                  config: {
                    ...formControl.config,
                    quiz: {
                      ...formControl.config.quiz,
                      correctAnswer: tags.length > 0 ? tags : []
                    }
                  }
                })
              }} />
              {
                currentControlErr?.["correctAnswer"]
                  ? <CustomText style={{ marginTop: "0.25rem" }} textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["correctAnswer"]))}</CustomText>
                  : <div className={styles.infoText}>{`${t("enter_add_value")}`}</div>
              }
              <CustomHeading headerType='h4' style={{ paddingTop: "1rem" }}>
                <div className={styles.labelContainer}>
                  <div>{`${t("total_score_text")}`}</div>
                  <div className={styles.topCheckToolTip} tool-tip={`${t("builder_set_score_tooltip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                    <img className={styles.toolTipImg} src={svgInfo} alt="" />
                  </div>
                </div>
              </CustomHeading>
              <CustomInput inputStyleType='fade' inputType='number' value={formControl?.config?.quiz?.score} onChange={(e) => {
                if (formControl) {
                  setIsDirty(true);
                  setFormControl({
                    ...formControl,
                    config: {
                      ...formControl.config,
                      "quiz": {
                        ...formControl.config.quiz,
                        score: e.target.value
                      }
                    }
                  })
                }
              }} />
              {
                currentControlErr?.["score"]
                  ? <CustomText style={{ marginTop: "0.25rem" }} textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["score"]))}</CustomText>
                  : <CustomText style={{ paddingTop: "0.25rem" }} textType='t8'><div>{`${t('form_property_quiz_score_helper')}`}</div></CustomText>
              }
            </div>
          }
          {props.control.controlType === 'radio' && props.isQuizEnabled &&
            <div>
              <CustomHeading headerType='h4'>
                <div className={styles.labelContainer}>
                  <div>{`${t("correct_answer_text")}`}</div>
                  <div className={styles.topCheckToolTip} tool-tip={`${t("builder_correct_answer_tooltip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                    <img className={styles.toolTipImg} src={svgInfo} alt="" />
                  </div>
                </div>
              </CustomHeading>
              <CustomInput style={{ padding: "8px 15px !important" }} inputStyleType='fade' inputType='text' value={formControl?.config?.quiz?.correctAnswer} onChange={(e) => {
                if (formControl) {
                  setIsDirty(true);
                  setFormControl({
                    ...formControl,
                    config: {
                      ...formControl.config,
                      "quiz": {
                        ...formControl.config.quiz,
                        correctAnswer: e.target.value === '' ? [] : [e.target.value]
                      }
                    }
                  })
                }
              }} />
              {
                currentControlErr?.["correctAnswer"]
                && <CustomText textType='t8' style={{ marginTop: "0.25rem" }} isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["correctAnswer"]))}</CustomText>
              }
              <CustomHeading headerType='h4' style={{ paddingTop: "1rem" }}>
                <div className={styles.labelContainer}>
                  <div>{`${t("total_score_text")}`}</div>
                  <div className={styles.topCheckToolTip} tool-tip={`${t("builder_set_score_tooltip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                    <img className={styles.toolTipImg} src={svgInfo} alt="" />
                  </div>
                </div>
              </CustomHeading>
              <CustomInput inputStyleType='fade' inputType='number' value={formControl?.config?.quiz?.score} onChange={(e) => {
                if (formControl) {
                  setIsDirty(true);
                  setFormControl({
                    ...formControl,
                    config: {
                      ...formControl.config,
                      "quiz": {
                        ...formControl.config.quiz,
                        score: e.target.value
                      }
                    }
                  })
                }
              }} />
              {
                currentControlErr?.["score"]
                  ? <CustomText style={{ marginTop: "0.25rem" }} textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["score"]))}</CustomText>
                  : <CustomText style={{ paddingTop: "0.25rem" }} textType='t8'><div>{`${t('form_property_quiz_score_helper')}`}</div></CustomText>
              }
            </div>
          }
          {props.control.controlType === 'dropdown' && props.isQuizEnabled &&
            <div>
              <CustomHeading headerType='h4'>
                <div className={styles.labelContainer}>
                  <div>{`${t("correct_answer_text")}`}</div>
                  <div className={styles.topCheckToolTip} tool-tip={`${t("builder_correct_answer_tooltip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                    <img className={styles.toolTipImg} src={svgInfo} alt="" />
                  </div>
                </div>
              </CustomHeading>
              <CustomInput inputStyleType='fade' inputType='text' value={formControl?.config?.quiz?.correctAnswer} onChange={(e) => {
                if (formControl) {
                  setIsDirty(true);
                  setFormControl({
                    ...formControl,
                    config: {
                      ...formControl.config,
                      "quiz": {
                        ...formControl.config.quiz,
                        correctAnswer: e.target.value === '' ? [] : [e.target.value]
                      }
                    }
                  })
                }
              }} />
              {
                currentControlErr?.["correctAnswer"]
                && <CustomText style={{ marginTop: "0.25rem" }} textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["correctAnswer"]))}</CustomText>
              }
              <CustomHeading headerType='h4' style={{ paddingTop: "1rem" }}>
                <div className={styles.labelContainer}>
                  <div>{`${t("total_score_text")}`}</div>
                  <div className={styles.topCheckToolTip} tool-tip={`${t("builder_set_score_tooltip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                    <img className={styles.toolTipImg} src={svgInfo} alt="" />
                  </div>
                </div>
              </CustomHeading>
              <CustomInput inputStyleType='fade' inputType='number' value={formControl?.config?.quiz?.score} onChange={(e) => {
                if (formControl) {
                  setIsDirty(true);
                  setFormControl({
                    ...formControl,
                    config: {
                      ...formControl.config,
                      "quiz": {
                        ...formControl.config.quiz,
                        score: e.target.value
                      }
                    }
                  })
                }
              }} />
              {
                currentControlErr?.["score"]
                  ? <CustomText style={{ marginTop: "0.25rem" }} textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["score"]))}</CustomText>
                  : <CustomText style={{ paddingTop: "0.25rem" }} textType='t8'><div>{`${t('form_property_quiz_score_helper')}`}</div></CustomText>
              }
            </div>
          }
        </>
      }
      {
        props.control.controlType === "date" &&
        <>
          <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>{t("properties_constants_start_date_limit")}</div>
                <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_start_date_limit_tip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <CustomCalender
              value={(formControl.config?.minDate && (formControl.config.minDate !== '')) ? formControl.config.minDate : undefined}
              selectedDate={(date) => {
                setIsDirty(true);
                setFormControl((prev) => {
                  return {
                    ...prev,
                    config: {
                      ...prev.config,
                      minDate: date ? date.toString() : ''
                    }
                  }
                });
              }}
              hideDateDialog={toggleStartDate}
              onShow={() => {
                setActiveDateSelector('start-date');
              }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>{t("properties_constants_end_date_limit")}</div>
                <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_end_date_limit_tip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <CustomCalender
              value={(formControl.config?.maxDate && (formControl.config.maxDate !== '')) ? formControl.config.maxDate : undefined}
              selectedDate={(date) => {
                setIsDirty(true);
                setFormControl((prev) => {
                  return {
                    ...prev,
                    config: {
                      ...prev.config,
                      maxDate: date ? date.toString() : ''
                    }
                  }
                });
              }}
              hideDateDialog={toggleEndDate}
              onShow={() => {
                setActiveDateSelector('end-date');
              }}
            />
            {
              currentControlErr?.["maxDate"]
                ? <CustomText textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["maxDate"]))}</CustomText>
                : <CustomText textType='t8'><div>{`${t('properties_constants_end_date_limit_helper')}`}</div></CustomText>
            }
          </div>
          {props.isQuizEnabled &&
            <div>
              <CustomHeading headerType='h4'>
                <div className={styles.labelContainer}>
                  <div>{`${t("correct_answer_text")}`}</div>
                  <div className={styles.topCheckToolTip} tool-tip={`${t("builder_correct_answer_tooltip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                    <img className={styles.toolTipImg} src={svgInfo} alt="" />
                  </div>
                </div>
              </CustomHeading>
              <CustomCalender value={formControl?.config?.quiz?.correctAnswer}
                selectedDate={(date) => {
                  if (formControl) {
                    setIsDirty(true);
                    setFormControl((prev) => {
                      return {
                        ...prev,
                        config: {
                          ...prev.config,
                          "quiz": {
                            ...prev.config.quiz,
                            correctAnswer: date ? [date.toString()] : []
                          }
                        }
                      }
                    });
                  }
                }
                } />
              {
                currentControlErr?.["correctAnswer"]
                && <CustomText style={{ marginTop: "0.25rem" }} textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["correctAnswer"]))}</CustomText>
              }
              <CustomHeading headerType='h4' style={{ paddingTop: "1rem" }}>
                <div className={styles.labelContainer}>
                  <div>{`${t("total_score_text")}`}</div>
                  <div className={styles.topCheckToolTip} tool-tip={`${t("builder_set_score_tooltip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                    <img className={styles.toolTipImg} src={svgInfo} alt="" />
                  </div>
                </div>
              </CustomHeading>
              <CustomInput inputStyleType='fade' inputType='number' value={formControl?.config?.quiz?.score} onChange={(e) => {
                if (formControl) {
                  setIsDirty(true);
                  setFormControl((prev) => {
                    return {
                      ...prev,
                      config: {
                        ...prev.config,
                        "quiz": {
                          ...prev.config.quiz,
                          score: e.target.value

                        }
                      }
                    }
                  });
                }
              }} />
              {
                currentControlErr?.["score"]
                  ? <CustomText style={{ marginTop: "0.25rem" }} textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["score"]))}</CustomText>
                  : <CustomText style={{ paddingTop: "0.25rem" }} textType='t8'><div>{`${t('form_property_quiz_score_helper')}`}</div></CustomText>
              }
            </div>
          }
        </>
      }
      {
        props.control.controlType === "time" &&
        <>
          <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>{t("properties_constants_start_time_limit")}</div>
                <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_start_time_limit_tip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <CustomTime
              value={(formControl.config?.minTime && (formControl.config.minTime !== '')) ? formControl.config.minTime : undefined}
              getTime={(time) => {
                setIsDirty(true);
                setFormControl((prev) => {
                  return {
                    ...prev,
                    config: {
                      ...prev.config,
                      minTime: time ? time.toString() : ''
                    }
                  }
                });
              }}
              onShow={() => {
                setActiveTimeSelector('start-time');
              }}
              hideTimeDialog={toggleStartTime}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>{t("properties_constants_end_time_limit")}</div>
                <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_end_time_limit_tip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <CustomTime
              value={(formControl.config?.maxTime && (formControl.config.maxTime !== '')) ? formControl.config.maxTime : undefined}
              getTime={(time) => {
                setIsDirty(true);
                setFormControl((prev) => {
                  return {
                    ...prev,
                    config: {
                      ...prev.config,
                      maxTime: time ? time.toString() : ''
                    }
                  }
                });
              }}
              onShow={() => {
                setActiveTimeSelector('end-time');
              }}
              hideTimeDialog={toggleEndTime}
            />

            {
              currentControlErr?.["maxTime"]
                ? <CustomText textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["maxTime"]))}</CustomText>
                : <CustomText textType='t8'><div>{`${t('properties_constants_end_time_limit_helper')}`}</div></CustomText>
            }
          </div>
        </>
      }
      {
        props.control.controlType === "file" &&
        <>
          <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>{t("properties_constants_accept_type")}</div>
                <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_accept_type_tip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <CustomSelect
              data={[
                { value: 'all', label: `${t("properties_constants_all")}` },
                { value: 'audio', label: `${t("properties_constants_audio")}` },
                { value: 'documents', label: `${t("properties_constants_documents")}` },
                { value: 'image', label: `${t("properties_constants_image")}` },
                { value: 'video', label: `${t("properties_constants_video")}` },
              ]}
              selectStyleType={'fade'}
              selectedData={(data) => {
                const res = (data as SingleValue<DropdownDataItem>)?.value;
                if (res) {
                  setIsDirty(true);
                  setFormControl({
                    ...formControl,
                    config: {
                      ...formControl.config,
                      acceptType: res,
                    }
                  })
                }
              }}
              value={{
                value: acceptType.value.charAt(0).toUpperCase() + acceptType.value.slice(1),
                label: acceptType.label.charAt(0).toUpperCase() + acceptType.label.slice(1),
              }}
              isClearable={true}
            ></CustomSelect>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>{t("properties_constants_maximum_file_count")}</div>
                <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_maximum_file_count_tip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <CustomInput inputStyleType='fade' inputType='number' placeHolder=''
              onChange={(e) => {
                if (formControl) {
                  setIsDirty(true);
                  setFormControl({
                    ...formControl,
                    config: {
                      ...formControl.config,
                      "maxFileCount": e.target.value
                    }
                  })
                }
              }}
              value={formControl.config?.maxFileCount ? formControl.config.maxFileCount : undefined}
            />
            {
              currentControlErr?.["maxFileCount"]
                ? <CustomText textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["maxFileCount"]))}</CustomText>
                : <CustomText textType='t8'><div>{`${t('properties_constants_maximum_file_count_helper')}`}</div></CustomText>
            }
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>{t("properties_constants_maximum_file_size")}</div>
                <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_maximum_file_size_tip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <CustomInput inputStyleType='fade' inputType='number' placeHolder=''
              onChange={(e) => {
                if (formControl) {
                  setIsDirty(true);
                  setFormControl({
                    ...formControl,
                    config: {
                      ...formControl.config,
                      "maxFileSize": e.target.value
                    }
                  })
                }
              }}
              value={formControl.config?.maxFileSize ? formControl.config.maxFileSize : undefined}
            />
            {
              currentControlErr?.["maxFileSize"]
                ? <CustomText textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["maxFileSize"]))}</CustomText>
                : <CustomText textType='t8'><div>{`${t('properties_constants_maximum_file_size_helper')}`}</div></CustomText>
            }
          </div>
        </>
      }
      {
        props.control.controlType === "number" &&
        <>
          <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>{t("properties_constants_minimum_number")}</div>
                <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_minimum_number_tip")}`} style={{ position: 'relative', cursor: 'pointer' }}>
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <CustomInput inputStyleType='fade' inputType='number' placeHolder='' onKeyDown={(e) => ["e", "E", "+"].includes(e.key) && e.preventDefault()} onChange={(e) => {
              if (formControl) {
                setIsDirty(true);
                setFormControl({
                  ...formControl,
                  config: {
                    ...formControl.config,
                    minValue: e.target.value !== "" ? e.target.value : null
                  }
                })
              }
            }}
              value={(formControl?.config && (formControl?.config.minValue !== null)) ? formControl.config.minValue : ""}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>{t("properties_constants_maximum_number")}</div>
                <div className={styles.topCheckToolTip} tool-tip={`${t("properties_constants_maximum_number_tip")}`} style={{ position: 'relative', cursor: 'pointer' }}>
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <CustomInput inputStyleType='fade' inputType='number' placeHolder='' onChange={(e) => {
              if (formControl) {
                setIsDirty(true);
                setFormControl({
                  ...formControl,
                  config: {
                    ...formControl.config,
                    maxValue: e.target.value !== "" ? e.target.value : null
                  }
                })
              }
            }}
              value={(formControl?.config && (formControl?.config.maxValue !== null)) ? formControl.config.maxValue : ""}
              onKeyDown={(e) => ["e", "E", "+"].includes(e.key) && e.preventDefault()}
            />
            {
              currentControlErr?.["maxValue"]
                ? <CustomText textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["maxValue"]))}</CustomText>
                : <CustomText textType='t8'><div>{`${t('properties_constants_maximum_number_helper')}`}</div></CustomText>
            }
            {props.isQuizEnabled &&
              <div>
                <CustomHeading headerType='h4' style={{ paddingTop: "1rem" }}>
                  <div className={styles.labelContainer}>
                    <div>{`${t("correct_answer_text")}`}</div>
                    <div className={styles.topCheckToolTip} tool-tip={`${t("builder_correct_answer_tooltip")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                      <img className={styles.toolTipImg} src={svgInfo} alt="" />
                    </div>
                  </div>
                </CustomHeading>
                <CustomInput inputStyleType='fade' inputType='number' value={formControl?.config?.quiz?.correctAnswer} onChange={(e) => {
                  if (formControl) {
                    setIsDirty(true);
                    setFormControl({
                      ...formControl,
                      config: {
                        ...formControl.config,
                        "quiz": {
                          ...formControl.config.quiz,
                          correctAnswer: e.target.value === '' ? [] : [e.target.value]
                        }
                      }
                    })
                  }
                }} />
                {
                  currentControlErr?.["correctAnswer"]
                  && <CustomText style={{ marginTop: "0.25rem" }} textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["correctAnswer"]))}</CustomText>
                }
                <CustomHeading headerType='h4' style={{ paddingTop: "1rem" }}>
                  <div className={styles.labelContainer}>
                    <div>{`${t("total_score_text")}`}</div>
                    <div className={styles.topCheckToolTip} tool-tip={`Set a score for the question.`} style={{ position: 'relative', cursor: 'pointer' }} >
                      <img className={styles.toolTipImg} src={svgInfo} alt="" />
                    </div>
                  </div>
                </CustomHeading>
                <CustomInput inputStyleType='fade' inputType='number' value={formControl?.config?.quiz?.score} onChange={(e) => {
                  if (formControl) {
                    setIsDirty(true);
                    setFormControl({
                      ...formControl,
                      config: {
                        ...formControl.config,
                        "quiz": {
                          ...formControl.config.quiz,
                          score: e.target.value
                        }
                      }
                    })
                  }
                }} />
                {
                  currentControlErr?.["score"]
                    ? <CustomText style={{ marginTop: "0.25rem" }} textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["score"]))}</CustomText>
                    : <CustomText style={{ paddingTop: "0.25rem" }} textType='t8'><div>{`${t('form_property_quiz_score_helper')}`}</div></CustomText>
                }
              </div>
            }
          </div>
        </>
      }
      {
        props.control.controlType === 'raffle' &&
        <RaffleProperties blackListInput={blackListInput} control={props.control} controlErrorMessage={controlErrorMessage} setBlackListInput={setBlackListInput} setIsDirty={setIsDirty} setValue={setFormControl} value={formControl} />
      }
      {
        props.control.controlType === 'quiz' &&
        <>
          {
            currentControlErr?.["userInfo"]
            && <CustomText textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["userInfo"]))} </CustomText>
          }
          <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", }}>
            <CustomHeading headerType='h4'>
              <div className={styles.labelContainer}>
                <div>{t("passing_percentage")}</div>
                <div className={styles.bottomCheckToolTip} tool-tip={`${t("set_passing_percentage")}`} style={{ position: 'relative', cursor: 'pointer' }}>
                  <img className={styles.toolTipImg} src={svgInfo} alt="" />
                </div>
              </div>
            </CustomHeading>
            <CustomInput inputStyleType='fade' inputType='number' value={formControl?.config?.passingPercent} onChange={(e) => {
              setIsDirty(true);
              setFormControl({ ...formControl, config: { ...formControl.config, passingPercent: e.target.value } });
            }} />
            {
              currentControlErr?.["passingPercent"]
              && <CustomText textType='t8' isErrorText={true}>{t((controlErrorMessage[props.control.formId][props.control.id]["passingPercent"]))}</CustomText>
            }
          </div>
          < div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "0.5rem" }} >
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <CustomHeading headerType='h4'>
                <div>{t("notify_score")}</div>
              </CustomHeading>
              <div className={styles.topCheckToolTip} tool-tip={`${t("toggle_notify_score")}`} style={{ position: 'relative', cursor: 'pointer' }} >
                <img className={styles.toolTipImg} src={svgInfo} alt="" />
              </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label className={styles.switch}>
                <input
                  type={"checkbox"}
                  onChange={(e) => {
                    if (formControl) {
                      setIsDirty(true);
                      setFormControl({
                        ...formControl,
                        config: {
                          ...formControl.config,
                          notifyScore: !notifyScore
                        }
                      })
                    }
                  }}
                  checked={notifyScore}
                  id="logo"
                />
                <span className={styles.slider}></span>
              </label>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            {
              formControl.config.userInfo?.includes('email') &&
              <>
                <CustomHeading headerType='h4'>
                  <div className={styles.labelContainer}><div>{t("email_id_setting")}</div></div>
                </CustomHeading>
                <CustomCheckbox
                  data={`${props.control.id}-email`}
                  labelText={`${t("allow_work_email")}`}
                  isInitialChecked={formControl.config.workEmail}
                  disabled={!formControl?.config.userInfo?.includes('email')}
                  isLabelNormal
                  onCheckChange={(data) => {
                    setFormControl({ ...formControl, config: { ...formControl.config, workEmail: data.isChecked } });
                    setIsDirty(true);
                  }}
                />
              </>
            }
          </div>
        </>
      }

      <div className={styles.saveBtnContainer}>
        <CustomButton buttonType='reset' classType='secondary' onClick={() => { clearValues(); setIsMandatory(props.control.config.isMandatory) }} style={{ width: "25%" }}>
          <div>{t("clear")}</div>
        </CustomButton>
        {
          currentControlErr
            ? <div className={styles.checkTooltip} tool-tip={`${t("clear_error_to_save_settings")}`} style={{ width: '25%', position: 'relative' }}><CustomButton buttonType='submit' classType='primary' onClick={() => { }} disabled={true}><div>{t("save")}</div></CustomButton></div>
            : <CustomButton buttonType='submit' classType='quaternary' onClick={() => { onClick() }} style={{ width: "25%" }} disabled={!isDirty}><div style={{ color: '#fff' }}>{t("save")}</div></CustomButton>
        }
      </div>
    </div >
  )
}
