import styles from "./file-control-styles.module.scss";
import FileCard from '../file-card/file-card';
import { Fragment, useEffect, useState } from "react";
import FilePreviewModal from "../../file-preview/file-preview-modal";
import { useTranslation } from "react-i18next";
import { downloadAttachment } from "../../../../api/form-response";

export default function FileControl(props: FormControlProps) {
  const [previewObj, setPreviewObj] = useState({});
  const [showFilePreview, setShowFilePreview] = useState(false);
  const { t } = useTranslation();
  const [isFileExist, setIsFileExist] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  async function handlePreview(params: { fileId: string, originalName: string, mimeType: string }, id: string) {
    setPreviewObj(params);
    setShowFilePreview(true);
  }

  useEffect(() => {
    checkFileExist();
  }, [])

  function checkFileExist() {
    if (Array.isArray(props?.value) && props?.value?.length > 0) {
      props?.value?.map(async (obj: any, index: number) => {
        try {
          await downloadAttachment(obj.fileId);
          setIsLoading(false);
          setIsFileExist(true)
        } catch (error: any) {
          if (error.response.status === 404) {
            setIsFileExist(false)
            setIsLoading(false);
          }
        }
      })
    }
  }


  return (
    <>
      <div className={styles.container}>
        {
          isLoading ?
            <div style={{ fontSize: '16px' }}>Loading...</div> :
            <>
              {isFileExist && (Array.isArray(props?.value) && props?.value?.length > 0) ? props?.value?.map((obj: any, index: number) => {

                return (
                  <Fragment key={obj.fileId}>
                    <FileCard {...obj} handleClick={() => handlePreview(obj, index.toString())} />
                  </Fragment>
                )
              }) :
                <>
                  {
                    isFileExist ? <div>{t('form_responses_details_no_files')}</div> : <div>{t("file_not_available")}</div>
                  }
                </>
              }
            </>
        }

      </div>
      {showFilePreview
        ? <FilePreviewModal {...previewObj} onModalClose={() => setShowFilePreview(false)} />
        : ''
      }
    </>
  )
}

export interface FormControlProps {
  controlType?: string;
  value: any;
}

export interface FormFileControlConfig {
  isMandatory: boolean;
  maxFileSize: number;
  maxFileCount: number;
  acceptType: string;
}
