import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import styles from './org-invitations.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../store/store';
import NoContent from '../../../../components/v2/common/no-content/no-content';
import { getOrgInvitationsThunk, getOrgUserByIdThunk, revokeUserInviteToOrganizationThunk } from '../../../../store/thunks/organization.thunk';
import OrgInvitationsRow from './org-invitation-row';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import hasSufficientScopes from '../../../../util/access-validator/access-validator';
import { ACTIVE_PAGE_STATUS } from '../../../../store/models/active-page';
import { activePageThunk } from '../../../../store/thunks/active-page.thunk';
import { SocketContext } from '../../../../context/socket';
import { setNewData } from '../../../../store/slices/active-page.slice';
import svgLoading from "../../../../assets/v2/icons/loading.svg";
import svgRefresh from "../../../../assets/v2/icons/refresh.svg";
import { useTranslation } from 'react-i18next';
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import ToolTip from '../../../../components/v2/common/tool-tip/tool-tip';
import { GetOrgInvitationsRespItem } from '../../../../store/models/organization.interface';
import Modal from '../../../../components/v2/common/modal/modal';
import toast from 'react-hot-toast';
import OrgInvitationMobileRow from './org-invitation-mobile-row';
import SecondaryTopBar from '../../../../components/v2/common/secondary-top-bar/secondary-top-bar';
import SettingRefreshComponent from '../setting-refresh-component';
import { UserMetadataKey } from '../../../../store/models/user-metadata-key.enum';

export default function OrgInvitations() {
  const { t } = useTranslation();
  const dispatch: RootDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const orgInvitations = useSelector((state: RootState) => state.organization.orgInvitations);
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const socket = useContext(SocketContext);
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const activeWebPage = useSelector((state: RootState) => state.activePage.activePage);
  const [invitedByEmail, setInvitedByEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState("");
  const isLatestSubscriptionActive = useSelector((state: RootState) => state.subscription.data.isActive);
  const userWorkspace = useSelector((state: RootState) => state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);

  useEffect(() => {
    const checkScope = hasSufficientScopes(["user:read"]);
    if (checkScope) {
      getLatestData();
    }
  }, [userWorkspace]);

  const getInvitedEmail = async (item: GetOrgInvitationsRespItem) => {
    const res = await dispatch(getOrgUserByIdThunk(item.createdByUserId)).unwrap();
    return res.email;
  }

  async function resolveInviteItem(item: GetOrgInvitationsRespItem) {
    const isEmail = new RegExp(/^[a-z0-9+_.-]+@[a-z0-9.-]+\.[a-z]+$/);
    if (isEmail.test(item.userIdOrEmail)) {
      return item.userIdOrEmail;
    }
    const userData = await dispatch(getOrgUserByIdThunk(item.userIdOrEmail)).unwrap();
    return userData.email;
  }

  useEffect(() => {
    if (newData) {
      getLatestData();
      dispatch((setNewData({ action: "" })));
    }
  }, [newData])

  const getLatestData = async () => {
    const checkScope = hasSufficientScopes(["user:read"]);
    if (checkScope) {
      setRefreshPage(true);
      await dispatch(getOrgInvitationsThunk());
      setRefreshPage(false);
    }
  }

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activeWebPage])

  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    dispatch(activePageThunk({ socket: socket, payload: { pageName: "invitations-sent", status } }));
  }

  const revokeInvite = async () => {
    if (isLatestSubscriptionActive) {
      try {
        await dispatch(revokeUserInviteToOrganizationThunk(userId)).unwrap();
        toast.success(t("invite_revoked"));
      } catch (err) {
        console.log("error", err);
        toast.error(t("revoke_failed"));
      }
      setShowModal(false);
    } else {
      toast.error(t('no_active_subscription_error'));
    }
  }

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal}
        header={<div>{t("sure_revoke_invite")}</div>}
        content={
          <div style={{ display: "flex", gap: "1rem" }}>
            <CustomButton
              buttonType='button'
              classType='secondary'
              onClick={() => revokeInvite()}>
              <div>{t("yes")}</div>
            </CustomButton>
            <CustomButton
              buttonType='button'
              classType='quaternary'
              onClick={() => { setShowModal(false) }}>
              <div>{t("no")}</div>
            </CustomButton>
          </div>
        }
      />
      <div className={styles.main}>
        <SecondaryTopBar>
          <div className={styles.subHeadingContainer}>
            <TextComponent textType='Content'>
              <div>{t("manage_team_mem")}</div>
            </TextComponent>
          </div>
          <div className={styles.topBtnContainer}>
            <SettingRefreshComponent
              getLatestData={getLatestData}
              refreshPage={refreshPage}
            />
          </div>
        </SecondaryTopBar>
        <div className={`${styles.contentPane} ${isLoading ? '' : styles.contentReady}`}>
          {
            orgInvitations && orgInvitations.length > 0 ? (
              <>
                <div className={'tableWrapper'}>
                  <>
                    <div className={'tableHeaderRow'}>
                      <div className={'tableData'}>{t("user_name")}</div>
                      <div className={'tableData'}>{t("invited_by")}</div>
                      <div className={'tableData'}>{t("invited_at")}</div>
                      <div className={'tableData'}>{t("status")}</div>
                      <div className={'tableData'}>{t("action")}</div>
                    </div>
                    {
                      orgInvitations.map((item, index) => {
                        return (
                          <Fragment key={item.id}>
                            <OrgInvitationsRow
                              key={item.id}
                              index={index}
                              inviteItem={item}
                              setShowModal={setShowModal}
                              setUserId={setUserId}
                              getInvitedEmail={getInvitedEmail}
                              resolveInviteItem={resolveInviteItem}
                            />
                          </Fragment>
                        )
                      })
                    }
                  </>
                </div>
                <div className={'mobileContainer'} >
                  {
                    orgInvitations.map((item, index) => {
                      return (
                        <Fragment key={item.id}>
                          <div className={'mobileCard'} key={index}>
                            <OrgInvitationMobileRow
                              invitedByEmail={invitedByEmail}
                              item={item}
                              resolveInviteItem={resolveInviteItem}
                              setShowModal={setShowModal}
                              setUserId={setUserId}
                              index={index}
                              getInvitedEmail={getInvitedEmail}
                            />
                          </div>
                        </Fragment>
                      )
                    })
                  }
                </div>
              </>
            ) : (
              <div style={{ padding: '1rem' }}>
                <NoContent content={t("no_invitations")} />
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

