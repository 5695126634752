import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAccount } from "../../api/sign-up";
import { acceptInviteToOrganization, declineInviteToOrganization, getInvitations, getUser } from "../../api/user";
import { CreateUserReqDto, OrgInviteReqDto } from "../models/user.interface";

export const createAccountThunk = createAsyncThunk(
  'public/create-account',
  async (data: CreateUserReqDto) => {
    let response = await createAccount(data);
    return response;
  }
)

export const getInvitationsThunk = createAsyncThunk(
  'users/invitations',
  async () => {
    const response = await getInvitations();
    let { status, message, ...data } = response;
    return data;
  }
)

export const acceptInviteToOrganizationThunk = createAsyncThunk(
  'users/acceptInvite',
  async (payload: { inviteId: string, data: OrgInviteReqDto }) => {
    await acceptInviteToOrganization(payload.inviteId, payload.data);
    return payload;
  }
)

export const declineInviteToOrganizationThunk = createAsyncThunk(
  'users/declineInvite',
  async (payload: { inviteId: string, data: OrgInviteReqDto }) => {
    await declineInviteToOrganization(payload.inviteId, payload.data);
    return payload;
  }
)

export const getUserThunk = createAsyncThunk(
  'users/getUser',
  async (payload: { userId: string, filters?: { email: string, orgName: string } }) => {
    const response = await getUser(payload.userId, payload.filters);
    let { status, message, ...data } = response;
    return data;
  }
)
