/**
 * @param {function} func This function will be executed after debounce
 * @param {number} timeout The default timeout is set to 100ms
 * @returns void
 */
export function debounce<T>(func: (args: T) => void, timeout: number = 100) {
    let timer: ReturnType<typeof setTimeout>;
    return (args: T) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(args);
        }, timeout);
    };
}