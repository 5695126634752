import axios, { AxiosError, AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { ActionType, FormResponseAction, RaffleWinner } from '../store/models/form-response.interface';
import { CreateFormReqDto, CreateFormResDto, CustomLinksDto, Form, FormResponse, FormState, UpdateFormDto } from '../store/models/form.interface';
import { GenerateApiUrl } from './generate-api-url';

export async function fetchAllForms(filters?: { category?: string, take?: number, skip?: number, state?: FormState, name?: string, isResponse?: boolean }) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'forms', true);
  let params = {};
  if (filters?.take) {
    Object.assign(params, { take: filters?.take });
  }
  if (filters?.skip) {
    Object.assign(params, { skip: filters?.skip });
  }
  if (filters?.category && filters?.category !== "") {
    Object.assign(params, { category: filters?.category });
  }
  if (filters?.state) {
    Object.assign(params, { state: filters?.state });
  }
  if (filters?.name && filters.name !== "") {
    Object.assign(params, { name: filters?.name });
  }
  if (filters?.isResponse) {
    Object.assign(params, { isResponse: filters.isResponse });
  }
  const response = await axios.get<FormResponse>(apiUrl, { params: params });
  return response;
}

export async function createForm(form: CreateFormReqDto): Promise<CreateFormResDto> {
  let apiUrl: string = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'forms', true);
  let response: AxiosResponse<CreateFormResDto, any>;
  try {
    response = await axios.post(apiUrl, form);
    return response.data;
  } catch (error) {
    if ((error as AxiosError).message == 'QUOTA_LIMIT_EXCEEDED') {
      toast.error(`Quota set for the your current subscription has exceeded. Please upgrade your plan to create more forms`);
    } else if (((error as AxiosError).response as AxiosResponse).data?.message == 'SUBSCRIPTION_INACTIVE') {
      toast.error(`Please renew your plan to create more forms`);
    } else {
      toast.error(`An error occurred while creating the form. Please try again later`);
    }
    throw error;
  }
}

export async function getFormCategories() {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'forms/categories', true);
  try {
    const response = await axios.get<{ categories: string[] }>(apiUrl);
    return response.data.categories;
  } catch (err) {
    return [];
  }
}

export async function getFormById(id: string): Promise<CreateFormResDto> {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${id}`, true);
  try {
    const response = await axios.get<CreateFormResDto>(apiUrl);
    return response.data;
  } catch (error) {
    toast.error(`An error occurred while fetching the form`);
    throw error;
  }
}

export async function publishForm(id: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${id}/publish`, true);
  await axios.patch<void>(apiUrl);
}

export async function deleteForm(id: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${id}`, true);
  await axios.delete<void>(apiUrl);
}

export async function updateForm(id: string, data: UpdateFormDto) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${id}`, true);
  try {
    const response = await axios.patch<UpdateFormDto>(apiUrl, data);
    return response.data;
  } catch (error) {
    toast.error(`An error occurred while saving the settings. Please try again later!`);
  }
}

export async function searchFormsByName(name: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/search?name=${name}`, true);
  const response = await axios.get<{ forms: Form[] }>(apiUrl);
  return response;
}

export async function base64Converter(id: string) {
  const split = id.split('media/');
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/base64/${split[1]}`, true);
  try {
    const response = await axios.get<{ image: string }>(apiUrl);
    return response;
  } catch (error) {
    toast.error(`${error}`);
  }
}

export async function dispatchCustomLinks(payload: CustomLinksDto) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `custom-links`, true);
  try {
    await axios.post(apiUrl, payload);
    return true;
  } catch (error) {
    return false;
  }
}

export async function revokeCustomLinkApi(id: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `custom-links/${id}`, true);
  try {
    await axios.patch(apiUrl, { isRevoked: true });
    return true;
  } catch (error) {
    return false;
  }
}

export async function updateFormPrivacyStatus(id: string, data: { isPublic: boolean, expiryTime: Date | null }) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${id}`, true);
  try {
    await axios.patch(apiUrl, data);
    return true;
  } catch (error) {
    return false;
  }
}

export async function addFormResponseAction(payload: { formId: string, config: object, order: number, actionType: string }) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form-response-action`, true);
  return await axios.post<FormResponseAction>(apiUrl, payload);
}

export async function getFormResponseAction(params: { formId?: string, actionType?: ActionType }) {
  let qParams = {};
  if (params.formId) {
    Object.assign(qParams, { formId: params.formId });
  }
  if (params.actionType) {
    Object.assign(qParams, { actionType: params.actionType });
  }
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form-response-action`, true);
  return await axios.get<{ formResponseAction?: FormResponseAction[] }>(apiUrl, { params: qParams });
}

export async function deleteFormResponseAction(id: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form-response-action/${id}`, true);
  return await axios.delete<FormResponseAction>(apiUrl);
}

export async function updateFormResponseAction(id: string, payload: { config?: object }) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form-response-action/${id}`, true);
  return await axios.patch(apiUrl, payload);
}

export async function cloneFormResponseAction(refFormId: string, clonedFormId: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form-response-action/clone`, true);
  return await axios.post(apiUrl, { refFormId, clonedFormId });
}

export async function getPvtFormsSharedCount(formId: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `custom-links/${formId}/unique-count`, true);
  return await axios.get(apiUrl);
}

export async function getDashboardAnalytics(qParams: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `dashboard/analytics?data=${qParams}`, true);
  return await axios.get(apiUrl);
}

export async function endRaffleMode(formId: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${formId}/end-raffle`, true);
  return await axios.patch(apiUrl);
}

export async function chooseWinners(formId: string, count: number) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${formId}/pick-winner?winnersCount=${count}`, true);
  const res = axios.get<{ winners: RaffleWinner[] }>(apiUrl);
  return res;
}

export async function blackListAccounts(formId: string, file: File) {
  const data = new FormData();
  data.append('file', file, `${formId}.${file.name.split('.')[file.name.split('.').length - 1]}`);
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/raffle/blacklists`, true);
  const res = axios.post<{ validEmails: string[], invalidEmails: string[] }>(apiUrl, data);
  return res;
}

export async function endQuizMode(formId: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${formId}/end-quiz`, true);
  return await axios.patch(apiUrl);
}

export async function sendUserScore(formId: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${formId}/send-score`, true);
  return await axios.patch(apiUrl);
}

export async function getEligibleRaffleCount(formId: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `form-response/raffle/count?formId=${formId}`, true);
  const res = await axios.get<{ eligibleRaffleCount: number }>(apiUrl);
  return res;
}

export async function submitFormForApproval(formId: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `forms/${formId}/submit-for-review`, true);
  const res = await axios.patch<{ eligibleRaffleCount: number }>(apiUrl);
  return res;
}
