import axios from "axios";
import { GenerateApiUrl } from "./generate-api-url";
import { AuditLogUsageCountDto } from "../store/models/audit-log.interface";

export async function getUsageFromAuditLogs(filters: { fromDate: Date, toDate: Date }) {
  const url = GenerateApiUrl(process.env.REACT_APP_AUDIT_LOG_SVC as string, `audit-log`, true);
  let params = {};
  if (filters.fromDate) {
    Object.assign(params, { fromDate: filters.fromDate });
  }
  if (filters.toDate) {
    Object.assign(params, { toDate: filters.toDate });
  }
  const response = await axios.get<AuditLogUsageCountDto>(url, { params: params });
  return response.data;
}
