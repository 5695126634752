import { useState, useEffect, useContext, useRef } from 'react';
import styles from './forms.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { SocketContext } from '../../../context/socket';
import { RootDispatch, RootState } from '../../../store/store';
import CustomHeading from '../../../components/v2/common/custom-heading/custom-heading';
import CustomButton from '../../../components/v2/common/custom-button/custom-button';
import { CustomSearch } from '../../../components/v2/common/custom-search/custom-search';
import CustomTabs from '../../../components/v2/common/custom-tabs/custom-tabs';
import { fetchAllFormsThunk } from '../../../store/thunks/forms.thunk';
import { Form, FormState } from '../../../store/models/form.interface';
import { getAllForms, getTotalFormsCount } from '../../../store/slices/forms.slice';
import { fetchAllTemplatesThunk } from '../../../store/thunks/templates.thunk';
import { CustomPagination } from '../../../components/v2/common/custom-pagination/custom-pagination';
import { useTranslation } from 'react-i18next';
import CustomLoader from '../../../components/v2/common/custom-loader/custom-loader';
import FormsTable from './forms-table';
import { ACTIVE_PAGE_STATUS } from '../../../store/models/active-page';
import { activePageThunk } from '../../../store/thunks/active-page.thunk';
import { setNewData } from '../../../store/slices/active-page.slice';
import svgNoForms from "../../../assets/v2/icons/no-forms.svg";
import TextComponent from '../../../components/v2/common/text-component/text-component';
import CreateForm from './create-form/create-form';
import { Template } from '../../../store/models/template.interface';
import toast from 'react-hot-toast';
import SecondaryTopBar from '../../../components/v2/common/secondary-top-bar/secondary-top-bar';
import { UserMetadataKey } from '../../../store/models/user-metadata-key.enum';
import { useNavigate } from 'react-router-dom';

export function FormsV2() {
  const user: any = useSelector((state: RootState) => state.user);
  const activePage = useSelector((state: RootState) => state.activePage.activePage);
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const [formsCount, setFormsCount] = useState(0);
  const forms: Form[] = useSelector((state: RootState) => getAllForms(state));
  const count: number = useSelector((state: RootState) => getTotalFormsCount(state));
  const [activeTab, setActiveTab] = useState<string>("");
  const [name, setName] = useState<string>("");
  const isLatestSubscriptionActive = useSelector((state: RootState) => state.subscription.data.isActive);
  const userWorkspace = useSelector((state: RootState) => state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);
  const dispatch: RootDispatch = useDispatch();
  const [hidePagination, setHidePagination] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const tabNames = ["", FormState.DRAFT, FormState.PUBLISHED, FormState.WAITING_FOR_PUBLISH];
  const [formsSkip, setFormSkip] = useState(0);
  const socket = useContext(SocketContext);
  const [showModal, setShowModal] = useState(false);
  const formsPerPage = 20;
  const navigate = useNavigate();
  let templates: Template[] = useSelector(
    (state: RootState) => state.templates.data
  );
  const { t } = useTranslation();
  const [isExpandSearch, setIsExpandSearch] = useState(false)
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getFormsCount();
  }, [userWorkspace]);

  const getFormsCount = async () => {
    let payload: any = {
      take: formsPerPage,
      skip: formsSkip
    };
    if (tabNames[tabIndex]) {
      payload.state = tabNames[tabIndex] as FormState;
    }
    const res = await dispatch(fetchAllFormsThunk(payload)).unwrap();
    setFormsCount(res.count);
  }

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activePage])

  useEffect(() => {
    if (newData) {
      getFormsCount();
      dispatch(setNewData({ action: "" }));
    }
  }, [newData]);


  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    dispatch(activePageThunk({ socket: socket, payload: { pageName: "forms", status } }));
  }

  useEffect(() => {
    const getForms = async () => {
      await dispatch(fetchAllFormsThunk({ state: tabNames[tabIndex] as FormState, name: name, take: formsPerPage }));
      setIsLoading(false);
    }
    getForms();
    setIsLoading(false);
  }, [tabIndex, name]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchAllFormsThunk({ skip: formsSkip, take: formsPerPage, state: tabNames[tabIndex] as FormState, name: name }));
    setIsLoading(false);
  }, [formsSkip])

  const goToFormDetails = (id: string, state: FormState | undefined) => {
    if (!isLatestSubscriptionActive) {
      toast.error(t('no_active_subscription_error'));
    }
    navigate(`${id}`);
  }

  return (
    <>
      <CreateForm
        setShowModal={setShowModal}
        showModal={showModal}
      />
      <SecondaryTopBar>
        <div className={styles.titleContainer}>
          <TextComponent textType='Content'>
            <div>{t("dashboard_subheading")}</div>
          </TextComponent>
        </div>
        <div className={styles.createForm}>
          <CustomButton
            style={{
              padding: "5px 9px",
              letterSpacing: '0.5px',
              pointerEvents: 'all',
              boxShadow: 'none',
              border: 'none'
            }}
            buttonType='button'
            classType='quaternary'
            onClick={() => setShowModal(true)}
          >
            <div>
              <TextComponent textType='Content'>
                <div style={{ color: '#fff' }}>{`${t("create_form")} `}</div>
              </TextComponent>
            </div>
          </CustomButton>
        </div>
      </SecondaryTopBar>
      <div className={styles.topContainer}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div className={`${styles.searchContainer} ${isExpandSearch ? styles.expandSearchContainer : ""}`} onClick={() => {
            setIsExpandSearch(true);
          }}>
            <CustomSearch
              searchWord={(item) => { setName(item) }}
              placeHolder={t("search_forms")}
            />
          </div>
          <div className={styles.tabContainer}>
            <CustomTabs
              tabHeadings={[t("all"), t("draft"), t("published"), t("waiting_for_approval")]}
              setActiveIndex={setTabIndex}
              btnStyles={{ padding: '0.25rem 0.75rem', whiteSpace: 'nowrap' }}
            />
          </div>
        </div>
        <div>
          {count > formsPerPage && !hidePagination &&
            <div style={{ width: '100%' }}>
              <div style={{ width: 'fit-content', margin: '0px 0px 0px auto', }}>
                <CustomPagination countPerPage={formsPerPage} totalCount={count} handlePagination={(res) => setFormSkip(res.skip)} />
              </div>
            </div>}
        </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.containerTwo}>
          <div className={styles.mobileTitleContainer}>
            <CustomHeading headerType='h3' style={{ pointerEvents: 'none', userSelect: 'none' }}>
              <div>{t("your_forms")}</div>
            </CustomHeading>
            <div className={`${styles.searchMobileContainer} ${isExpandSearch ? styles.expandSearchContainer : ""}`} onClick={() => {
              setIsExpandSearch(true);
            }}>
              <CustomSearch
                searchWord={(item) => { setName(item) }}
                placeHolder={t("search_forms")}
              />
            </div>
          </div>
          <>
            <div style={{ position: "relative", width: "100%", flexGrow: "1", display: "flex" }}>
              {
                <div className={`${styles.loaderCube} ${isLoading ? '' : styles.loaderClose}`}><CustomLoader /></div>
              }
              <div className={`${styles.contentPane} ${isLoading ? '' : styles.contentReady}`}>
                {formsCount > 0 ? (
                  <div className={styles.yourFormsContainer}>
                    <FormsTable
                      activeTab={activeTab}
                      forms={forms}
                      goToFormDetails={goToFormDetails}
                      t={t}
                    />
                    <div className={styles.paginationWrapper}>
                      {count > formsPerPage && !hidePagination &&
                        <div style={{ width: '100%', background: 'var(--secondary-top-bar-color)', padding: '1rem', boxSizing: 'border-box', boxShadow: 'var(--box-shadow)' }}>
                          <div style={{ width: 'fit-content', margin: '0px 0px 0px auto', }}>
                            <CustomPagination countPerPage={formsPerPage} totalCount={count} handlePagination={(res) => setFormSkip(res.skip)} />
                          </div>
                        </div>}
                    </div>
                  </div>
                ) : (
                  <div className={styles.noFormsContainer} style={{ pointerEvents: 'none', userSelect: 'none' }}>
                    <div>
                      <img src={svgNoForms} alt="no-forms" />
                    </div>
                    <TextComponent textType='Content'>
                      <div>{t("dashboard_create_forms", { name: user.data.name })}</div>
                    </TextComponent>
                  </div>
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  )
}
