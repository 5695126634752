import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../models/load-state.enum";
import { GetSubscriptionItemRespDto } from "../models/subscription.interface";
import { fetchSubscriptionThunk } from "../thunks/subscription.thunk";


const subscriptionInitialState: {
  data: GetSubscriptionItemRespDto;
  fetchLoadingState: LoadState;
} = {
  data: {
    id: '',
    isActive: false,
    orgId: '',
    plans: [],
    stripeSubscriptionId: '',
    lastPaymentDate: new Date(),
    nextPaymentDate: new Date(),
    deletedAt: new Date(),
    createdBy:'',
    isCanceledAtPeriodEnd : false,
  }, fetchLoadingState: LoadState.IDLE
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: subscriptionInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionThunk.pending, (state) => {
        state.fetchLoadingState = LoadState.LOADING;
      })
      .addCase(fetchSubscriptionThunk.fulfilled, (state, action) => {
        let data: GetSubscriptionItemRespDto = action.payload;
        state.data = { ...data };
        state.fetchLoadingState = LoadState.IDLE;
      })
      .addCase(fetchSubscriptionThunk.rejected, (state) => {
        state.fetchLoadingState = LoadState.ERROR;
        state.data = {
          id: '',
          isActive: false,
          orgId: '',
          plans: [],
          stripeSubscriptionId: '',
          lastPaymentDate: new Date(),
          nextPaymentDate: new Date(),
          deletedAt: new Date(),
          isCanceledAtPeriodEnd:false,
          createdBy : ''
        };
      })
  }
});

export default subscriptionSlice;
