import { RootDispatch, RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import styles from './integrations.module.scss';
import React, { useContext, useEffect, useRef, useState } from 'react';
import CustomHeading from '../../../../components/v2/common/custom-heading/custom-heading';
import NoContent from '../../../../components/v2/common/no-content/no-content';
import IntegrationsRow from './integrations-row';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import { useTranslation } from 'react-i18next';
import { ActionsPanel } from '../../../../components/v2/form-builder';
import { getAllIntegrationsSelector } from '../../../../store/slices/integrations.slice';
import { AddIntegration } from './add-integration/add-integration';
import { deleteIntegrationThunk, getAllIntegrationsThunk } from '../../../../store/thunks/integrations.thunk';
import { GetIntegrationsRespItem } from '../../../../store/models/settings/integrations/get-integrations.interface';
import { SocketContext } from '../../../../context/socket';
import { setNewData } from '../../../../store/slices/active-page.slice';
import { ACTIVE_PAGE_STATUS } from '../../../../store/models/active-page';
import { activePageThunk } from '../../../../store/thunks/active-page.thunk';
import toast from 'react-hot-toast';
import hasSufficientScopes from '../../../../util/access-validator/access-validator';
import Modal from '../../../../components/v2/common/modal/modal';
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import { fetchSubscriptionThunk } from '../../../../store/thunks/subscription.thunk';
import SecondaryTopBar from '../../../../components/v2/common/secondary-top-bar/secondary-top-bar';
import IntegrationActionContainer from './integration-action-container';
import { UserMetadataKey } from '../../../../store/models/user-metadata-key.enum';

export function Integrations() {
  const { t } = useTranslation();
  const dispatch: RootDispatch = useDispatch();
  const integrations = useSelector((state: RootState) => getAllIntegrationsSelector(state));
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [integrationsType, setIntegrationsType] = useState<'CREATE' | 'EDIT'>('CREATE');
  const [selectedIntegration, setSelectedIntegration] = useState<GetIntegrationsRespItem>();
  const socket = useContext(SocketContext);
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const activePage = useSelector((state: RootState) => state.activePage.activePage);
  const isLatestSubscriptionActive = useSelector((state: RootState) => state.subscription.data.isActive);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [itemId, setItemId] = useState("");
  const userWorkspace = useSelector((state: RootState) => state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);

  useEffect(() => {
    getLatestData();
    dispatch(fetchSubscriptionThunk());
  }, [userWorkspace]);

  const getLatestData = async () => {
    await dispatch(getAllIntegrationsThunk());
  }

  useEffect(() => {
    if (newData) {
      getLatestData();
      dispatch((setNewData({ action: "" })));
    }
  }, [newData])

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activePage])

  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    dispatch(activePageThunk({ socket: socket, payload: { pageName: "integrations", status } }));
  }

  const deleteIntegration = async (id: string) => {
    try {
      await dispatch(deleteIntegrationThunk(id)).unwrap();
      toast.success(t("integration_deleted"));
      await dispatch(getAllIntegrationsThunk()).unwrap();
    } catch (err) {
      toast.error(t("integration_delete_fail"));
    }
    setShowModal(false);
  }


  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal}
        header={<div>{t("sure_delete_integration")}</div>}
        content={
          <div style={{ display: "flex", gap: "1rem" }}>
            <CustomButton
              buttonType='button'
              classType='secondary'
              onClick={() => deleteIntegration(itemId)}>
              <div style={{ color: 'var(--font-color)' }}>{t("yes")}</div>
            </CustomButton>
            <CustomButton
              buttonType='button'
              classType='quaternary'
              onClick={() => { setShowModal(false); }}>
              <div>{t("no")}</div>
            </CustomButton>
          </div>
        }
      />
      <div className={styles.actionPaneBlock}>
        <ActionsPanel
          title={`${integrationsType === 'CREATE' ? `${t("add_integration")}` : ''}${integrationsType === 'EDIT' ? `${t("edit_integration")}` : ''}`}
          style={{ top: "5rem", minHeight: "calc(100vh - 6rem)", minWidth: "40rem", maxWidth: "40rem", transform: `${isPanelOpen ? "translate3d(0, 0, 0)" : "translate3d(40rem, 0, 0)"}` }} isActionsPanelOpen={isPanelOpen}
          setIsActionsPanelOpen={setIsPanelOpen}
        >
          {
            isPanelOpen && integrationsType === 'CREATE'
              ?
              <AddIntegration setIsPanelOpen={setIsPanelOpen} integrationsType={integrationsType} selectedIntegration={selectedIntegration} setSelectedIntegration={setSelectedIntegration} />
              : null
          }
          {
            isPanelOpen && integrationsType === 'EDIT'
              ?
              <AddIntegration setIsPanelOpen={setIsPanelOpen} integrationsType={integrationsType} selectedIntegration={selectedIntegration} setSelectedIntegration={setSelectedIntegration} />
              : null
          }
        </ActionsPanel>
      </div>
      <div className={`${styles.actionMenuBg} ${isPanelOpen ? styles.actionMenuBgActive : ""}`}>
        <div className={`${styles.mobileActionPanel} ${isPanelOpen ? styles.mobileActionPanelActive : ""}`}>
          {
            isPanelOpen && integrationsType === 'CREATE'
              ?
              <AddIntegration setIsPanelOpen={setIsPanelOpen} integrationsType={integrationsType} selectedIntegration={selectedIntegration} setSelectedIntegration={setSelectedIntegration} />
              : null
          }
          {
            isPanelOpen && integrationsType === 'EDIT'
              ?
              <AddIntegration setIsPanelOpen={setIsPanelOpen} integrationsType={integrationsType} selectedIntegration={selectedIntegration} setSelectedIntegration={setSelectedIntegration} />
              : null
          }
        </div>
      </div>
        <SecondaryTopBar>
          <div className={styles.subHeadingContainer}>
            <TextComponent textType='Content'>
              <div>{t("manage_integrations")}</div>
            </TextComponent>
          </div>
          <div className={styles.addBtnContainer}>
            <CustomButton
              buttonType='button'
              classType='quaternary'
              style={{
                padding: "5px 1rem",
                letterSpacing: '0.5px',
                boxShadow: 'none',
                border: 'none'
              }}
              onClick={async () => {
                if (isLatestSubscriptionActive) {
                  const checkScope = hasSufficientScopes(['integrations:create']);
                  if (checkScope) {
                    setIsPanelOpen(true);
                    setIntegrationsType('CREATE');
                  } else {
                    toast.error(t('settings_integration_add_no_permission'));
                  }
                } else {
                  toast.error(t('no_active_subscription_error'));
                }
              }}>
              <TextComponent textType='Content'>
                <div style={{ color: '#fff' }}>{t("add_integration")}</div>
              </TextComponent>
            </CustomButton>
          </div>
        </SecondaryTopBar>
      <div className={styles.integrationMain}>
        <div className={`${styles.contentPane} ${styles.contentReady}`}>
          {
            integrations && integrations.length > 0 ? (
              <React.Fragment>
                <div className={"tableWrapper"}>
                  <>
                    <div className={"tableHeaderRow"}>
                      <div className={"tableData"}>{t("integration_name")}</div>
                      <div className={"tableData"}>{t("integration_type")}</div>
                      <div className={"tableData"}>{t("created_at")}</div>
                      <div className={"tableData"}>{t("actions")}</div>
                    </div>
                    {
                      integrations.map((item, index) => {
                        return (
                          <React.Fragment key={item.id}>
                            <IntegrationsRow key={item.id} index={index} inviteItem={item} setItemId={setItemId} setSelectedIntegration={setSelectedIntegration} setIntegrationsType={setIntegrationsType} setIsPanelOpen={setIsPanelOpen} setShowModal={setShowModal} />
                          </React.Fragment>
                        )
                      })
                    }
                  </>
                </div>
                <div className={'mobileContainer'}>
                  {
                    integrations.map((item, index) => {
                      return (
                        <>
                          <div className={'mobileCard'} key={item.id} >
                            <div className={'rowOne'}>
                              <CustomHeading headerType='h4'>
                                {item.displayName}
                              </CustomHeading>
                            </div>
                            <div className={'rowOne'}>
                              <div className={'rowOne'}>
                                {item.type}
                              </div>
                              <div className={'rowOne'}>
                                {new Date(item.createdAt).toLocaleString()}
                              </div>
                            </div>
                            <div className={'rowFour'}>
                              <div className={'diamond'}></div>
                              <div className={'line'}></div>
                              <div className={'diamond'}></div>
                            </div>
                            <div className={'rowOne'}>
                              <IntegrationActionContainer
                                item={item}
                                setIntegrationsType={setIntegrationsType}
                                setIsPanelOpen={setIsPanelOpen}
                                setItemId={setItemId}
                                setSelectedIntegration={setSelectedIntegration}
                                setShowModal={setShowModal}
                              />
                            </div>
                          </div>
                        </>
                      )
                    })
                  }
                </div>
              </React.Fragment>
            ) : (
              <div style={{ padding: '1rem 0' }}>
                <NoContent content={`${t("no_integrations")}`} />
              </div>
            )
          }
        </div >
      </div >
    </>
  )
}
