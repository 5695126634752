import { createAsyncThunk } from "@reduxjs/toolkit";
import { createIntegration, deleteIntegration, getAllIntegrations, updateIntegration } from "../../api/integrations";
import { CreateIntegrationReqDto } from "../models/settings/integrations/create-integrations.interface";
import { UpdateIntegrationReqDto } from "../models/settings/integrations/update-integrations.interface";

export const getAllIntegrationsThunk = createAsyncThunk(
  'integrations/getIntegrations',
  async () => {
    const response = await getAllIntegrations();
    return response;
  }
);

export const createIntegrationsThunk = createAsyncThunk(
  'integrations/createIntegrations',
  async (payload: CreateIntegrationReqDto) => {
    await createIntegration(payload);
    return payload;
  }
);

export const deleteIntegrationThunk = createAsyncThunk(
  'integrations/deleteIntegrations',
  async (payload: string) => {
    await deleteIntegration(payload);
    return payload;
  }
);

export const updateIntegrationThunk = createAsyncThunk(
  'integrations/updateIntegrations',
  async (params: {id: string, payload: UpdateIntegrationReqDto}) => {
    await updateIntegration(params.id, params.payload);
  }
);
