import styles from './builder-placeholder.module.scss';
import svgCanvasPlaceholder from "../../../../assets/canvas-placeholder.svg";
import { useTranslation } from 'react-i18next';

export function BuilderPlaceholder() {
  const { t } = useTranslation();
  return (
    <div className={styles.placeHolder}>
      <img src={svgCanvasPlaceholder} alt="canvas placeholder" />
      <p>
      {t("builder_create_first_form")}
      </p>
    </div>
  )
}
