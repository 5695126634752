import axios from 'axios';
import { CustomLinkInteractionResponse, GetInteractionsParams } from '../store/models/custom-links';
import { GenerateApiUrl } from './generate-api-url';

export async function getLinkInteractions(formId: string, qParams: GetInteractionsParams) {
  let apiUrl: string = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `custom-link-interactions/${formId}`, true);
  let params = {};
  if (qParams.email) {
    Object.assign(params, { email: qParams.email });
  }
  return await axios.get<CustomLinkInteractionResponse>(apiUrl, { params: params });
}
