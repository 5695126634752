import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { LayoutPanel } from './layout-builder';
import { LogicControl } from '../logic-builder/models/logic-control.props';
import { ConditionalEdge } from '../../../../../store/models/form-builder/conditional-logic';

export function VisualizerPanel(props: {control: LogicControl[], conditionalLogic: ConditionalEdge[]}) {

  return (
    <ReactFlowProvider>
      <LayoutPanel control={props.control} conditionalLogic={props.conditionalLogic} />
    </ReactFlowProvider>
  )
}
