import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCaptchaState } from "../../../../store/slices/captcha.slice";

export function Captcha() {
    const dispatch = useDispatch();

    const handleLoaded = () => {
        dispatch(setCaptchaState(true));
    }

    useEffect(() => {
        const scriptTag = document.getElementById('recaptcha');

        if (scriptTag === null) {
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_SITE_KEY}`;
            script.id = 'recaptcha';
            script.addEventListener("load", handleLoaded);
            document.body.appendChild(script);
        }
    }, []);

    return <></>;
}
