import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACTIVE_PAGE_STATUS } from "../models/active-page";

export const activePageThunk = createAsyncThunk(
  "page/status",
  async (param: { socket: any, payload: { pageName: string, status: ACTIVE_PAGE_STATUS } }) => {
    if (!param.payload.pageName) {
      return;
    }
    const action = 'activepage';
    return new Promise((resolve, reject) => {
      param.socket.emit(action, { action, payload: param.payload }, (res: any) => {
        if (res.status === 'error') {
          reject(res);
          return;
        } else {
          resolve(res);
        }
      });
    })
  }
);
