import { useEffect, useState } from 'react';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import styles from './response-details.module.scss'
import { getTimeTrackData, GetTimeTrackResDto } from '../../../../api/time-track';
import { GetAllResponseID, GetFormResponseItemDto } from '../../../../store/models/form-response.interface';
import { CreateFormControlResDto, FormControl } from '../../../../store/models/form-controls/form-control.interface';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CustomMobilePagination from '../../../../components/v2/common/custom-mobile-pagination/custom-mobile-pagination';
import { downloadSingleResponseCsv } from '../../../../util/download-responses/download-responses';
import { fetchResponseCount, getFormResponseByResponseId } from '../../../../api/form-response';
import { RootDispatch, RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getControlByFormThunk } from '../../../../store/thunks/controls.thunk';
import { getQuizFormControl } from '../../../../api/form-controls';
import toast from 'react-hot-toast';
import { fetchAllResponsesID } from '../../../../store/thunks/form-responses.thunk';
import { getAllResponsesID } from '../../../../store/slices/form-respone.slice';
import ResponseView from './response-view';
import svgClockCircle from "../../../../assets/v2/icons/clock-circle.svg";
import svgDownloadDark from "../../../../assets/v2/download.svg";
import svgResponseArrow from "../../../../assets/v2/left-arrow.svg"
import svgUser from "../../../../assets/v2/icons/user.svg";
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import ToolTip from '../../../../components/v2/common/tool-tip/tool-tip';
import { ResponseUserName } from '../response-user-name/response-user-name';

export default function ResponseDetails() {
  const [activeResponse, setActiveResponse] = useState<GetFormResponseItemDto>();
  const [timeTrackInfo, setTimeTrackInfo] = useState<GetTimeTrackResDto | undefined>(undefined);
  const [responseIndex, setResponseIndex] = useState(0);
  const responsesID: GetAllResponseID[] = useSelector((state: RootState) => getAllResponsesID(state));
  const { t } = useTranslation();
  const [formControls, setFormControls] = useState<CreateFormControlResDto[]>([]);
  let dispatch: RootDispatch = useDispatch();
  const navigate = useNavigate();
  const { formId, responseId } = useParams();
  const [quizControl, setQuizControl] = useState<FormControl>();
  const [responseCount, setResponseCount] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [hasFile, setHasFile] = useState(false);

  useEffect(() => {
    setResponseCount(responsesID.length);
    if (responseId)
      checkResponseIndex(responseId);
  }, [responsesID])

  useEffect(() => {
    if (responseId && formId) {
      onClickResponseTab(responseId);
      getFormControls(formId);
      getQuizControl(formId);
      fetchFormResponseCount(formId);
    }
  }, [responseId])

  const fetchFormResponseCount = async (formId: string) => {
    try {
      const response = await fetchResponseCount(formId);
      const allRes = await dispatch(fetchAllResponsesID({ formId: formId }));
      setResponseCount(response.data.count);
    } catch (err) {
      toast.error(t('form_details_toast_form_response_err'));
    }
  }

  const fetchTimeTrackData = async (responseId: string) => {
    const res = await getTimeTrackData(responseId);
    setTimeTrackInfo(res);
  }

  const getFormControls = async (id: string) => {
    if (formId) {
      const formControlResp: any = await dispatch(getControlByFormThunk(id)).unwrap();
      if (formControlResp) setFormControls(formControlResp);
    }
  }

  const onClickResponseTab = async (id: string) => {
    if (formId && responseId) {
      const data = await getFormResponseByResponseId(formId, responseId);
      setActiveResponse(data);
      fetchTimeTrackData(id);
    }
  }

  const getNextResponse = (responseId: string) => {
    let value = responsesID.findIndex((item) => responseId === item.responseId);
    if (value !== (responsesID.length - 1)) {
      const res = responsesID[value + 1];
      setIsEnd(false);
      setIsStart(false)
      navigate(`../responses/${res.responseId}`);
      setResponseIndex(value + 1)
    } else {
      setIsEnd(true);
    }
  }

  const getPreviousResponse = (responseId: string) => {
    let value = responsesID.findIndex((item) => responseId === item.responseId);
    if (value !== 0) {
      const res = responsesID[value - 1];
      navigate(`../responses/${res.responseId}`);
      setResponseIndex(value - 1)
      setIsStart(false)
      setIsEnd(false);
    } else {
      setIsStart(true)
    }
  }

  const areEqual = (arr1: Array<string>, arr2: Array<string>) => {
    if (arr1.length === arr2.length) {
      return arr1.every(element => {
        if (arr2.includes(element)) {
          return true;
        }

        return false;
      });
    }

    return false;
  }

  const getQuizControl = async (formId: string) => {
    try {
      const quizControl = await getQuizFormControl(formId);
      setQuizControl(quizControl);
    } catch (err) { }
  }

  const checkResponseIndex = (responseId: string) => {
    let value = responsesID.findIndex((item) => responseId === item.responseId);
    if (value >= 0 && value === (responsesID.length - 1)) {
      setIsEnd(true);
    }
    if (value === 0) {
      setIsStart(true);
    }
  }

  return (
    <>
      {activeResponse &&
        <div className={styles.main}>
          <div className={styles.backBtnContainer}>
            <TextComponent textType='Header'>
              Response Details
            </TextComponent>
            <div className={styles.actionContainer}>
              <div className={styles.actionBtn}>
                <div className={styles.downloadBtn}>
                  <ToolTip position={'bottom'} content={`${t("download_resp")}`}>
                    <CustomButton
                      style={{
                        backgroundColor: "var(--accent-color)",
                        borderRadius: '48px',
                        width: "2.4rem",
                        height: "2.4rem",
                        padding: "9px",
                      }}
                      buttonType='reset'
                      classType='primary'
                      onClick={() => {
                        if (formId && responseId)
                          downloadSingleResponseCsv(formId, responseId, hasFile);
                      }}
                    >
                      <img style={{ width: "1.1rem" }} src={svgDownloadDark} alt="download" />
                    </CustomButton>
                  </ToolTip>
                </div>
                <div className={styles.headBtn}>
                  <CustomButton
                    buttonType="button"
                    classType="quaternary"
                    style={{
                      backgroundColor: "var(--accent-color)",
                      borderRadius: '48px',
                      padding: '8px'
                    }}
                    onClick={() => {
                      if (responseId) {
                        getPreviousResponse(responseId);
                      }
                    }}
                    disabled={isStart}
                  >
                    <div className={styles.previous}>
                      <img src={svgResponseArrow} alt="no" />
                    </div>
                  </CustomButton>
                </div>
                <div className={styles.headBtn}>
                  <CustomButton
                    buttonType="button"
                    classType="quaternary"
                    style={{
                      backgroundColor: "var(--accent-color)",
                      borderRadius: '48px',
                      padding: '8px'
                    }}
                    onClick={() => {
                      if (responseId) {
                        getNextResponse(responseId);
                      }
                    }}
                    disabled={isEnd}
                  >
                    <div className={styles.next}>
                      <img src={svgResponseArrow} alt="no" />
                    </div>
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bottomContianer}>
            <div className={styles.responseContentContainer}>
              <div className={styles.responseFirstRow}>
                <div className={styles.gapIconResponseDetails}>
                  <TextComponent textType='Header'>
                    <div className={styles.cardText}>{t("resp_id")}</div>
                  </TextComponent>
                  <TextComponent textType='Content'>
                    <div className={styles.responseText}>{activeResponse?.id}</div>
                  </TextComponent>
                </div>
                {
                  activeResponse?.userId &&
                  <div className={styles.gapIconResponseDetails}>
                    <TextComponent textType='Header'>
                      <div className={styles.cardText}>{t("submitted_by")}</div>
                    </TextComponent>
                    <TextComponent textType='Content'>
                      <div className={styles.responseText}>
                        <ResponseUserName userId={activeResponse.userId} includeStyle={false} />
                      </div>
                    </TextComponent>
                  </div>
                }
                <div className={styles.gapIconResponseDetails}>
                  <TextComponent textType='Header'>
                    <div className={styles.cardText}>{t("submitted_at")}</div>
                  </TextComponent>
                  <TextComponent textType='Content'>
                    <div className={styles.responseText}>{`${activeResponse && activeResponse.submittedAt ? new Date(activeResponse.submittedAt).toLocaleString() : null}`}</div>
                  </TextComponent>
                </div>
                <div className={styles.gapIconResponseDetails}>
                  <TextComponent textType='Header'>
                    <div className={styles.cardText}>{t("user_dev")}</div>
                  </TextComponent>
                  <TextComponent textType='Content'>
                    <div className={styles.responseText}>{activeResponse?.deviceType ? activeResponse?.deviceType : ""}</div>
                  </TextComponent>
                </div>
                <div className={styles.gapIconResponseDetails}>
                  <TextComponent textType='Header'>
                    <div className={styles.cardText}>{t("time_taken")}</div>
                  </TextComponent>
                  <TextComponent textType='Content'>
                    <div className={styles.responseText}>{activeResponse?.duration ? `${Math.floor(activeResponse.duration / 60)}.${activeResponse.duration % 60}s` : 'N/A'}</div>
                  </TextComponent>
                </div>
              </div>
              <div className={styles.responseMobileFirstRow}>
                <div className={styles.gapIconResponseDetails}>
                  <div >{t("resp_recorded_at")}</div>
                  <TextComponent textType='Content'>
                    <div>{`${activeResponse && activeResponse.submittedAt ? new Date(activeResponse.submittedAt).toLocaleString() : null}`}</div>
                  </TextComponent>
                </div>
                <div className={styles.responseMobileSecondRow}>
                  <div className={styles.gapIconResponseDetails}>
                    <CustomButton
                      style={{
                        background: "var(--highlight-color)",
                        width: "1.5rem",
                        height: "1.5rem",
                        padding: "3px",
                        cursor: "auto"
                      }}
                      buttonType='reset'
                      classType='primary'
                      onClick={() => {
                      }}
                    >
                      <img style={{ width: "1.5rem" }} src={svgUser} alt="arrow" />
                    </CustomButton>
                    <TextComponent textType='Content'>
                      <div>{activeResponse?.deviceType ? activeResponse?.deviceType : ""}</div>
                    </TextComponent>
                  </div>
                  <div className={styles.gapIconResponseDetails}>
                    <ToolTip position={'right'} content={`${t("time_taken")}`}>
                      <CustomButton
                        style={{
                          background: "var(--highlight-color)",
                          width: "1.5rem",
                          height: "1.5rem",
                          padding: "3px",
                          cursor: "auto"
                        }}
                        buttonType='reset'
                        classType='primary'
                        onClick={() => {
                        }}
                      >
                        <img style={{ width: "1.5rem" }} src={svgClockCircle} alt="arrow" />
                      </CustomButton>
                    </ToolTip>
                    <TextComponent textType='Content'>
                      <div>{timeTrackInfo && timeTrackInfo.duration ? `${Math.floor((timeTrackInfo.duration / 1000 / 60) % 60).toString().padStart(2, "0")}:${Math.floor((timeTrackInfo.duration / 1000) % 60).toString().padStart(2, "0")}` : "00:00"}</div>
                    </TextComponent>
                  </div>
                </div>
              </div>
              <hr className={styles.lineStyle}></hr>
              <ResponseView
                activeResponse={activeResponse}
                setActiveResponse={setActiveResponse}
                areEqual={areEqual}
                formControls={formControls}
                quizControl={quizControl}
                timeTrackInfo={timeTrackInfo}
                setHasFile={setHasFile}
              />
              <div className={styles.singleDownloadResponseIcon}>
                <CustomButton
                  style={{
                    backgroundColor: "#D3CCFF",
                    width: "2.3rem",
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0.5rem'
                  }}
                  buttonType='button'
                  classType='primary'
                  onClick={() => {
                    if (formId && responseId)
                      downloadSingleResponseCsv(formId, responseId, hasFile);
                  }}
                >
                  <img src={svgDownloadDark} alt="download" />
                </CustomButton>
                {
                  responseCount > 1 &&
                  <CustomMobilePagination index={responseIndex} count={responseCount} responseId={responseId} getNextResponse={() => { if (responseId) getNextResponse(responseId) }} getPreviousResponse={() => { if (responseId) getPreviousResponse(responseId) }} />
                }
              </div>
            </div>
          </div>
        </div >
      }
    </>
  );
}

