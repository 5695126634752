import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomHeading from "../../../../components/v2/common/custom-heading/custom-heading";
import styles from './fail.module.scss';
import CustomButton from "../../../../components/v2/common/custom-button/custom-button";
import gifFailExclamation from "../../../../assets/fail-exclamation.gif";
import gifFail from "../../../../assets/fail.gif";
import svgRightArrow from "../../../../assets/v2/icons/right-arrow.svg";

export default function SubscriptionFail2() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.main}>
        <div className={styles.pageContainer}>
          <div className={styles.parent}>
            <img className={styles.failexclamatory1} src={gifFailExclamation}></img>
            <img className={styles.failcross1} src={gifFail}></img>
            <img className={styles.successbtn} src="/assets/red-sqaure.svg"></img>
            <img className={styles.failcross2} src={gifFail}></img>
            <img className={styles.failexclamatory2} src={gifFailExclamation}></img>
          </div>
          <div className={styles.alignCenter}>
            <span className={styles.successText}> {`${t("payment_fail_heading")}`}</span>
            <div className={styles.alignCenter}>
              <span className={styles.paymentText}>{`${t("payment_fail_text")}`}  </span>
              <div style={{ display: "flex", gap: "1rem", marginTop: "20px" }}>

                <CustomButton
                  buttonType='submit'
                  classType='tertiary'
                  onClick={() => { navigate('/v2/settings/organization') }}
                >
                  <div className={styles.signBtn}>
                    <CustomHeading
                      headerType='h4'
                    >
                      <div>{`${t("close")}`}</div>
                    </CustomHeading>
                  </div>
                </CustomButton>

                <CustomButton
                  buttonType='submit'
                  classType='primary'
                  onClick={() => { navigate('/v2/settings/organization') }}
                >
                  <div className={styles.signBtn}>
                    <CustomHeading
                      headerType='h4'
                      style={{ color: "#FFFFFF" }}
                    >
                      <div>{`${t("try_again")}`}</div>
                    </CustomHeading>
                    <img src={svgRightArrow} alt="" />
                  </div>
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
