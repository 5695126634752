import { useTranslation } from 'react-i18next';
import CustomText from '../../../components/v2/common/custom-bodyText/custom-text';
import styles from './plans.module.scss';
import { BasePlans } from '../../../components/v2/settings/subs-plans/subs-base-plans';
import { SubsAddOns } from '../../../components/v2/settings/subs-add-ons/subs-add-ons';
import CustomButton from '../../../components/v2/common/custom-button/custom-button';
import { useEffect, useState } from 'react';
import { activateCancelledSubscription, cancelSubscription, createSubscription, getPlans, modifySubscription } from '../../../api/subscription';
import { AddOnSubscriptionPlan, BaseSubscriptionPlan } from '../../../store/models/subscription/plans';
import { TotalSelectedPlans } from '../../../components/v2/settings/total-selected-plans/total-selected-plans';
import { toast } from 'react-hot-toast';
import { GetPaymentAccountsRespItem } from '../../../store/models/user.interface';
import { BillingAccounts } from '../../../components/v2/settings/billing-accounts/billing-accounts';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../store/store';
import { getPaymentAccounts } from '../../../api/billing-accounts';
import { SubscriptionPlanType } from '../../../store/models/subscription/plan-type.enum';
import { useDispatch } from 'react-redux';
import { fetchSubscriptionThunk } from '../../../store/thunks/subscription.thunk';
import hasSufficientScopes from '../../../util/access-validator/access-validator';
import Modal from '../../../components/v2/common/modal/modal';
import SecondaryTopBar from '../../../components/v2/common/secondary-top-bar/secondary-top-bar';
import TextComponent from '../../../components/v2/common/text-component/text-component';

export function Plans() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch: RootDispatch = useDispatch();
  const [basePlans, setBasePlans] = useState<BaseSubscriptionPlan[]>([]);
  const [addOnPlans, setAddOnPlans] = useState<(AddOnSubscriptionPlan & { isSelected: boolean })[]>([]);
  const [selectedBasePlan, setSelectedBasePlan] = useState<BaseSubscriptionPlan>();
  const [totalSelectedPlans, setTotalSelectedPlans] = useState<(BaseSubscriptionPlan | AddOnSubscriptionPlan & { isSelected: boolean })[]>([]);
  const [isError, setIsError] = useState(false);
  const [billingAcc, setBillingAcc] = useState<GetPaymentAccountsRespItem[]>([]);
  const [isBillingAccErr, setIsBillingAccErr] = useState(false);
  const [selectedBillingAcc, setSelectedBillingAcc] = useState<GetPaymentAccountsRespItem>();
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const latestSubscription = useSelector((state: RootState) => state.subscription.data);
  const [showModal, setShowModal] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isFreeze, setIsFreeze] = useState(false);

  useEffect(() => {
    setupPlans();
    getBillingAccounts();
    return () => {
      resetValues();
    }
  }, []);

  useEffect(() => {
    if (!hasSufficientScopes(["subscription:read"]) && latestSubscription?.isActive && latestSubscription.deletedAt != null) {
      setIsFreeze(true);
      navigate('../settings');
    }
    if (latestSubscription?.isActive) {
      const val = latestSubscription.plans.find((val => val.planType === SubscriptionPlanType.BASE));
      if (val) {
        const basePlan = basePlans.find(plan => plan.id === val.id);
        setSelectedBasePlan(basePlan);
      }
      const newAddOn = [...addOnPlans];
      for (const plan of newAddOn) {
        const addOnPlan = latestSubscription.plans.find(val => val.id === plan.id);
        if (addOnPlan) {
          plan.isSelected = true;
        }
      }
      setAddOnPlans(newAddOn);
    }
  }, [latestSubscription, basePlans]);

  const resetValues = () => {
    setBasePlans([]);
    setAddOnPlans([]);
    setSelectedBasePlan(undefined);
    setSelectedBillingAcc(undefined);
    setIsError(false);
    setIsBillingAccErr(false);
    setIsBtnLoading(false);
  }

  useEffect(() => {
    setTotalSelectedPlans((prevPlans) => {
      return prevPlans.filter(plan => plan.planType !== SubscriptionPlanType.ADD_ON);
    });
    if (addOnPlans.length > 0) {
      const selectedAddOns = addOnPlans.filter(plan => plan.isSelected === true);
      if (selectedAddOns) {
        setTotalSelectedPlans((prevPlans) => {
          return [...prevPlans, ...selectedAddOns];
        });
      }
    }
  }, [addOnPlans]);

  useEffect(() => {
    setTotalSelectedPlans((prevPlans) => {
      return prevPlans.filter(plan => plan.planType !== SubscriptionPlanType.BASE);
    });
    if (selectedBasePlan) {
      setIsError(false);
      setTotalSelectedPlans((prevPlans) => {
        return [...prevPlans, selectedBasePlan];
      });
    }
  }, [selectedBasePlan]);

  useEffect(() => {
    if (selectedBillingAcc) {
      setIsBillingAccErr(false);
    }
  }, [selectedBillingAcc]);

  const setupPlans = async () => {
    const plans = await getPlans();
    const basePlans: BaseSubscriptionPlan[] = [];
    const addOnPlans: (AddOnSubscriptionPlan & { isSelected: boolean })[] = [];
    for (const plan of plans) {
      switch (plan.planType) {
        case SubscriptionPlanType.BASE: {
          basePlans.push(plan);
          break;
        }
        case SubscriptionPlanType.ADD_ON: {
          addOnPlans.push({ ...plan, isSelected: false });
          break;
        }
      }
    }
    setBasePlans(basePlans);
    setAddOnPlans(addOnPlans);
  }

  const onClickActivate = async () => {
    try {
      const res = await activateCancelledSubscription();
      toast.success(t("subscription_activated_again"));
    } catch (err) {
      toast.error(t("subscription_activation_failure"));
    }
  }

  const onClickModify = async () => {
    if (selectedBasePlan) {
      try {
        await modifySubscription({
          selectedPlans: [
            ...totalSelectedPlans.map(plan => {
              return {
                id: plan.id,
                planType: plan.planType
              }
            }),
          ],
        });
        toast.success(t("subscription_update_success"));
      } catch (err) {
        toast.error(t("subscription_update_failure"));
        setIsBtnLoading(prev => false);
        return;
      }
    }
  }

  const onClickCreate = async () => {
    if (selectedBasePlan && selectedBillingAcc) {
      try {
        await createSubscription({
          selectedPlans: [
            ...totalSelectedPlans.map(plan => {
              return {
                id: plan.id,
                planType: plan.planType
              }
            }),
          ],
          paymentMethod: selectedBillingAcc.id
        });
        toast.success(t("subscription_success"));
      } catch (err: any) {
        toast.error(err.response.data.message);
        setIsBtnLoading(prev => false);
        return;
      }
    }
  }

  const onClickSubmit = async () => {
    setIsBtnLoading(prev => true);
    let isError = false;
    if (!selectedBasePlan) {
      setIsError(true);
      isError = true;
    }
    if (!latestSubscription.isActive && !selectedBillingAcc) {
      setIsBillingAccErr(true);
      isError = true;
    }
    if (isError) {
      toast.error(t("subscription_required_fields_warning"));
      setIsBtnLoading(prev => false);
      return;
    }

    if (latestSubscription.isActive === true) {
      latestSubscription.deletedAt == null ?
        await onClickModify() : await onClickActivate()
    } else {
      await onClickCreate();
    }

    setSelectedBasePlan(undefined);
    setSelectedBillingAcc(undefined);
    setIsError(false);
    setIsBillingAccErr(false);
    setIsBtnLoading(prev => false);
    setTotalSelectedPlans([]);
    navigate('../settings');
  }

  const getBillingAccounts = async () => {
    try {
      const res = await getPaymentAccounts();
      setBillingAcc(res.cards);
    } catch (err) {
      console.log("err", err);
    }
  }

  const onClickCancelSubscription = async () => {
    const checkScope = hasSufficientScopes(["subscription:cancel"]);
    if (checkScope) {
      try {
        setIsCancelLoading(prev => true);
        await cancelSubscription();
        dispatch(fetchSubscriptionThunk());
        setSelectedBasePlan(undefined);
        setSelectedBillingAcc(undefined);
        setIsError(false);
        setIsBillingAccErr(false);
        setIsBtnLoading(prev => false);
        setTotalSelectedPlans([]);
        setShowModal(false);
        toast.success(t("subscription_cancel_success"));
        navigate('../settings');
      } catch (err) {
        toast.error(t("subscription_cancel_failure"));
      }
      setIsCancelLoading(prev => false);
    } else {
      toast.error(t('settings_cancel_subscription_no_permission'));
    }
  }

  return (
    <>
      <SecondaryTopBar>
        <div style={{ padding: '0.75rem', paddingLeft: '0' }}>
          <TextComponent textType='Content'>{t("subscription_sub")}</TextComponent>
        </div>
        <div className={styles.headerBtn}>
          {
            latestSubscription.isActive ?
              latestSubscription.deletedAt == null ?
                hasSufficientScopes(["subscription:update"]) && <CustomButton buttonType={'button'} classType={'quaternary'} style={{ padding: '5px 12px', letterSpacing: '0.5px' }} onClick={onClickSubmit} isLoading={isBtnLoading}>
                  {t("change_subscription")}
                </CustomButton> :
                hasSufficientScopes(["subscription:update"]) && <CustomButton buttonType={'button'} classType={'quaternary'} style={{ padding: '5px 12px', letterSpacing: '0.5px' }} onClick={onClickActivate} isLoading={isBtnLoading}>
                  {t("active_subscription")}
                </CustomButton>
              :
              hasSufficientScopes(["subscription:create"]) && <CustomButton buttonType={'button'} classType={'quaternary'} style={{ padding: '5px 12px', letterSpacing: '0.5px' }} onClick={onClickSubmit} isLoading={isBtnLoading}>
                {t("subscribe")}
              </CustomButton>
          }
        </div>
      </SecondaryTopBar>
      <div className={styles.main}>
        <div className={styles.contentContainer}>
          <BasePlans plans={basePlans} selectedPlan={selectedBasePlan} setSelectedPlan={setSelectedBasePlan} isError={isError} isFreeze={isFreeze} />
          <SubsAddOns plans={addOnPlans} setAddOnPlans={setAddOnPlans} isFreeze={isFreeze} />
          <TotalSelectedPlans plans={totalSelectedPlans} setAddOnPlans={setAddOnPlans} setSelectedBasePlan={setSelectedBasePlan} isFreeze={isFreeze} />
          {
            !latestSubscription?.isActive &&
            <BillingAccounts accounts={billingAcc} isBillingAccErr={isBillingAccErr} setSelectedBillingAcc={setSelectedBillingAcc} selectedBillingAcc={selectedBillingAcc} />
          }
          {
            hasSufficientScopes(["subscription:cancel"]) && latestSubscription?.isActive && latestSubscription?.deletedAt == null &&
            <div style={{ marginRight: 'auto' }}>
              <CustomButton buttonType='button' style={{ textDecoration: 'underline', background: 'none', border: 'none', boxShadow: 'none' }} classType='secondary' onClick={() => { setShowModal(true) }}>{t("cancel_sub")}</CustomButton>
            </div>
          }
        </div>
        <div>
        </div>
        <Modal showModal={showModal} setShowModal={setShowModal}
          header={
            <div>{t("cancel_sub")}</div>
          }
          content={
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                <CustomText textType='t8'>{t("subscription_text_1")}</CustomText>
                <CustomText textType='t8'>{t("subscription_text_2")}</CustomText>
                <CustomText textType='t8'>{t("subscription_text_3")}</CustomText>
                <CustomText textType='t8'>{t("subscription_text_4")}</CustomText>
              </div>
              <CustomText textType='t5'>{t("cancel_sub_warning")}</CustomText>
              <div style={{ display: 'flex', gap: '1rem' }}>
                <CustomButton buttonType='button' classType='quaternary' onClick={() => { setShowModal(false) }}>{t("no")}</CustomButton>
                <CustomButton buttonType='button' classType='secondary' onClick={onClickCancelSubscription} isLoading={isCancelLoading}>{t("yes")}</CustomButton>
              </div>
            </div>
          }
        />
      </div>
    </>
  )
}
