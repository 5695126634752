import { useEffect, useState } from "react";
import { FormControl } from "../../../../../../store/models/form-controls/form-control.interface";
import { LogicControl } from "../models/logic-control.props";

export function useSetupLogicBuilder(formControls: FormControl[]) {
  const [logicControl, setLogicControl] = useState<LogicControl[]>([]);
  const [logicControlsLookup, setLogicControlsLookup] = useState<Record<string, LogicControl>>({});
  const [sourceControls, setSourceControls] = useState<LogicControl[]>([]);
  const [targetControls, setTargetControls] = useState<LogicControl[]>([]);

  const validSrcTypes = ['number', 'text', 'textarea', 'dropdown', 'radio', 'checkbox', 'email', 'mobile number'];
  const validTgtTypes = ['label', 'page', 'section', 'number', 'text', 'textarea', 'dropdown', 'radio', 'checkbox', 'date', 'time', 'file', 'raffle'];

  const setupControls = () => {
    let pageCount = 0;
    let sectionCount = 0;
    const logicCtrls: LogicControl[] = [];
    let logicControlsLookup: Record<string, LogicControl> = {};
    const srcLogicCtrls: LogicControl[] = [];
    const tgtLogicCtrls: LogicControl[] = [];

    if (formControls) {
      // iterate over page control
      for (const page of formControls) {
        if (page.controlType === 'page') pageCount++;
        // add page to logic controls
        logicCtrls.push({
          controlType: page.controlType,
          id: page.id,
          label: `Page ${pageCount}`,
          options: page.options
        });
        logicControlsLookup = {
          ...logicControlsLookup,
          [page.id]: {
            controlType: page.controlType,
            id: page.id,
            label: `Page ${pageCount}`,
            options: page.options
          }
        }
        // skip first page as a target control
        if (page.order !== 0) {
          tgtLogicCtrls.push({
            controlType: page.controlType,
            id: page.id,
            options: page.options,
            label: `Page ${pageCount}`
          });
        }

        // iterate over page's children
        for (const ctrl of page.children) {
          // increment counter to address section count
          if (ctrl.controlType === 'section') sectionCount++;

          // add control to logic controls
          if (ctrl.controlType !== 'quiz') {
            logicCtrls.push({
              controlType: ctrl.controlType,
              id: ctrl.id,
              options: ctrl.options,
              label: ctrl.controlType === 'section' ? `Section ${sectionCount}` : (ctrl.controlType === 'raffle' ? 'Raffle control' : ctrl.label)
            });
            logicControlsLookup = {
              ...logicControlsLookup,
              [ctrl.id]: {
                controlType: ctrl.controlType,
                id: ctrl.id,
                options: ctrl.options,
                label: ctrl.controlType === 'section' ? `Section ${sectionCount}` : (ctrl.controlType === 'raffle' ? 'Raffle control' : ctrl.label)
              }
            }
          }

          // check if the control is a valid target control and add it
          if (validTgtTypes.includes(ctrl.controlType) && !(page.order === 0 && ctrl.order === 0)) {
            tgtLogicCtrls.push({
              controlType: ctrl.controlType,
              id: ctrl.id,
              options: ctrl.options,
              label: ctrl.controlType === 'section' ? `Section ${sectionCount}` : (ctrl.controlType === 'raffle' ? 'Raffle control' : ctrl.label)
            });
          }

          // check if the control is a valid source control and add it
          if (validSrcTypes.includes(ctrl.controlType)) {
            srcLogicCtrls.push({
              controlType: ctrl.controlType,
              id: ctrl.id,
              options: ctrl.options,
              label: ctrl.label
            });
          }

          // iterate over control's children
          for (const childrenCtrl of ctrl.children) {

            // add control to logic controls
            logicCtrls.push({
              controlType: childrenCtrl.controlType,
              id: childrenCtrl.id,
              options: childrenCtrl.options,
              label: childrenCtrl.label
            });
            logicControlsLookup = {
              ...logicControlsLookup,
              [childrenCtrl.id]: {
                controlType: childrenCtrl.controlType,
                id: childrenCtrl.id,
                options: childrenCtrl.options,
                label: childrenCtrl.label
              }
            }

            // check if the control is a valid source control and add it
            if (validSrcTypes.includes(childrenCtrl.controlType)) {
              srcLogicCtrls.push({
                controlType: childrenCtrl.controlType,
                id: childrenCtrl.id,
                options: childrenCtrl.options,
                label: childrenCtrl.label
              });
            }

            // stop if the control is the first control
            if (page.order === 0 && ctrl.order === 0 && ctrl.controlType === 'raffle') break;

            // check if the control is a valid target control and add it
            if (validTgtTypes.includes(childrenCtrl.controlType) && !(page.order === 0 && ctrl.order === 0 && childrenCtrl.order === 0)) {
              tgtLogicCtrls.push({
                controlType: childrenCtrl.controlType,
                id: childrenCtrl.id,
                options: childrenCtrl.options,
                label: childrenCtrl.label
              });
            }
          }
        }
      }
    }

    // TODO: adding this for debugging purposes for the bug #13471.
    console.debug('[conditional logic] form controls: ', formControls);
    console.debug('[conditional logic] source controls: ', srcLogicCtrls);
    console.debug('[conditional logic] target controls: ', tgtLogicCtrls);
    console.debug('[conditional logic] controls lookup: ', logicControlsLookup);
    setLogicControl(logicCtrls);
    setSourceControls(srcLogicCtrls);
    setTargetControls(tgtLogicCtrls);
    setLogicControlsLookup(logicControlsLookup);
  }

  useEffect(() => {
    setupControls();
  }, [formControls]);

  return {
    logicControl,
    logicControlsLookup,
    srcLogicControls: sourceControls,
    tgtLogicControls: targetControls,
  }
}
