import { useNavigate, useParams } from "react-router-dom";
import styles from './analytics.module.scss';
import { useTranslation } from "react-i18next";
import CustomTabs from "../../../../components/v2/common/custom-tabs/custom-tabs";
import { useState } from "react";
import ResponseAnalytics from "./response-analytics/response-analytics";
import UserAnalytics from "./user-analytics/user-analytics";

export default function Analytics() {
  const navigate = useNavigate();
  const { formId } = useParams();
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className={styles.main}>
      <div className={styles.tabStyles}>
        {/*
        @TODO User analytics is not working so commented out for now
        <div className={styles.tabsLayout}>
          <CustomTabs tabHeadings={[`${t("resp_analytics")}`, `${t("user_analytics")}`]}
            setActiveIndex={setTabIndex}
            btnStyles={{ whiteSpace: 'nowrap' }}
          />
        </div> */}
      </div>
      <div>
        {tabIndex === 0 && <ResponseAnalytics />}
        {/* {tabIndex === 1 && <UserAnalytics />} */}
      </div>
    </div>
  )
}
