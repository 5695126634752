import styles from './responses.overview.module.scss'
import { CustomPagination } from "../../../../components/v2/common/custom-pagination/custom-pagination";
import { GetFormResponseWithQuestions } from "../../../../store/models/form-response.interface";
import CustomButton from "../../../../components/v2/common/custom-button/custom-button";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import React from "react";
import { fetchResponseCount } from "../../../../api/form-response";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { downloadOptions } from "../form-responses";
import { RootDispatch, RootState } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchResponseListWithQuestionThunk } from "../../../../store/thunks/form-responses.thunk";
import CustomSelect from "../../../../components/v2/common/custom-select/custom-select";
import { DropdownDataItem } from "../../../../components/v2/common/custom-select/custom-select.props";
import { downloadAsCsvOrExcel } from "../../../../util/download-responses/download-responses";
import ResponsesTable from "./responses-table";
import { ACTIVE_PAGE_STATUS } from "../../../../store/models/active-page";
import { activePageThunk } from "../../../../store/thunks/active-page.thunk";
import { setNewData } from "../../../../store/slices/active-page.slice";
import { SocketContext } from "../../../../context/socket";
import svgPaneDelete from "../../../../assets/v2/icons/pane-delete.svg";
import TextComponent from "../../../../components/v2/common/text-component/text-component";
import ToolTip from "../../../../components/v2/common/tool-tip/tool-tip";

export default function ResponseOverview() {
  const { formId } = useParams();
  let dispatch: RootDispatch = useDispatch();
  const [isFilterMenuActive, setIsFilterMenuActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [hasFile, setHasFile] = useState(false);
  const [responseData, setResponseData] = useState<GetFormResponseWithQuestions>();
  const [isQuiz, setIsQuiz] = useState(false);
  const [responseCount, setResponseCount] = useState(0);
  const [responsesSkip, setResponsesSkip] = useState<number>(0);
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const activePage = useSelector((state: RootState) => state.activePage.activePage);
  const socket = useContext(SocketContext);

  useEffect(() => {
    getResponses(responsesSkip);
    if (formId) {
      fetchFormResponseCount(formId);
    }
  }, []);

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activePage])

  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    if (formId) {
      dispatch(activePageThunk({ socket: socket, payload: { pageName: `form-responses-${formId}`, status } }));
    }
  }

  useEffect(() => {
    if (newData) {
      getResponses(responsesSkip);
      if (formId) {
        fetchFormResponseCount(formId);
      }
      dispatch((setNewData({ action: "" })));
    }
  }, [newData])


  const fetchFormResponseCount = async (formId: string) => {
    try {
      const response = await fetchResponseCount(formId);
      setResponseCount(response.data.count);
    } catch (err) {
      toast.error(t('form_details_toast_form_response_err'));
    }
  }

  const getResponses = async (num: number) => {
    try {
      if (formId) {
        const data = await dispatch(fetchResponseListWithQuestionThunk({ formId: formId, skip: num, take: 10 })).unwrap();
        data.formControls.forEach(control => {
          control.children.forEach(item => {
            if (item.controlType === "quiz") {
              setIsQuiz(true);
            }
            if (item.controlType === 'file') setHasFile(true);
          })
        })
        setResponseData(data);
      }
    } catch (error) { }
  }

  const filterDiv = () => (
    <>
      <div style={{ alignSelf: "baseline", width: "100%" }}>
        <div className={styles.tagTitleContainer}>
          <TextComponent textType="Content">
            <div className={styles.tagTitle}>{t('download_as_responses')}</div>
          </TextComponent>
          <div className={styles.mobileCloseBtn}>
            <button className={styles.closebtn} onClick={() => { setIsFilterMenuActive(false) }}>
              <img src={svgPaneDelete} alt="" />
            </button>
          </div>
        </div>
        <hr className={styles.line} />
      </div>
      <div className={styles.downloadContainer}>
        {downloadOptions.map((item, index) => (
          <React.Fragment key={index}>
            <div className={styles.filterHolder}>
              <input className={styles.tag} type='button' value={item.label} onClick={() => {
                if (formId) downloadAsCsvOrExcel(formId, item.value, hasFile)
              }} />
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  )

  return (
    <>
      <div className={styles.main}>
        <div className={styles.backBtnContainer}>
          <div className={styles.actionContainer}>
            <div className={styles.actionBtn}>
              <div className={styles.downloadResp}>
                <ToolTip position='bottom' content={`${t("download_all_resp_as")}`}>
                  <div className={styles.downloadAsContainer}>
                    <CustomSelect data={downloadOptions.map((elem) => {
                      let data: DropdownDataItem = {
                        label: elem.label,
                        value: elem.value
                      }
                      return data;
                    })}
                      selectStyleType={"fade"}
                      selectedData={(value: any) => {
                        setIsLoading(true);
                        if (formId) {
                          downloadAsCsvOrExcel(formId, value.value, hasFile);
                        }
                        setIsLoading(false);
                      }}
                      placeholder={`${t("download_as_responses")}`}
                    />
                  </div>
                </ToolTip>
              </div>
            </div>
          </div>
        </div>
        <ResponsesTable responseData={responseData} formId={formId} />
        <div style={{ margin: "0px 0px 0px auto", paddingBottom: "1rem" }}>
          {responseCount > 10 ? <CustomPagination countPerPage={10} totalCount={responseCount} handlePagination={(res) => getResponses(res.skip)} /> : ''}
        </div>
      </div>
      <div className={styles.downloadSlider}>
        <CustomButton
          style={{
            padding: "5px",
          }}
          buttonType='button'
          classType='quaternary'
          onClick={() => { setIsFilterMenuActive(true) }}
        >{t("download_all_resp")}</CustomButton>
      </div>
      <div className={isFilterMenuActive ? `${styles.actionMenuBg} ${styles.actionMenuBgActive}` : styles.actionMenuBgInactive} onClick={() => { setIsFilterMenuActive(false) }}>
        <div className={`${styles.mobileFilterContainer} ${isFilterMenuActive ? styles.mobileFilterContainerActive : ""}`}>
          {filterDiv()}
        </div>
      </div>
    </>
  );
}
