import styles from './custom-select.module.scss';
import { DropdownDataItem, SelectDataProps } from './custom-select.props';
import Select, { ActionMeta, CSSObjectWithLabel, ControlProps, DropdownIndicatorProps, GroupBase, IndicatorSeparatorProps, IndicatorsContainerProps, MenuListProps, MenuProps, MultiValue, MultiValueProps, OptionProps, SingleValue, SingleValueProps, StylesConfig } from 'react-select';

export default function CustomSelect(props: SelectDataProps) {

  const menuStyle = (base: CSSObjectWithLabel, rsProps: MenuProps<DropdownDataItem, boolean, GroupBase<DropdownDataItem>>) => ({
    ...base,
    marginTop: "0.5rem",
    maxHeight: "10rem",
    overflow: "hidden",
    border: 'var(--border)',
    boxShadow: "none",
    borderRadius: "4px",
    color: "hsl(0, 0%, 0%)"
  });

  const controlStyle = (base: CSSObjectWithLabel, rsProps: ControlProps<DropdownDataItem, boolean, GroupBase<DropdownDataItem>>) => ({
    ...base,
    borderRadius: "4px",
    border: "none",
    fontSize: "12px",
    color: "hsl(0, 0%, 0%)",
    opacity: props.disabled ? 0.5 : 1,
    boxShadow: rsProps.isFocused ? "0" : "0",
    '&:hover': {
      border: rsProps.isFocused ? "0" : "0"
    }
  });

  const optionStyle = (base: CSSObjectWithLabel, rsProps: OptionProps<DropdownDataItem, boolean, GroupBase<DropdownDataItem>>) => ({
    ...base,
    background: rsProps.isSelected ? "#D9D9D9" : "none",
    opacity: rsProps.isDisabled ? 0.5 : 1,
    color: "hsl(0, 0%, 0%)",
    '&:hover': {
      background: rsProps.isDisabled ? "" : "#D9D9D9",
      cursor: "pointer",
    },
  });

  const indicatorsContainerStyle = (base: CSSObjectWithLabel, rsProps: IndicatorsContainerProps<DropdownDataItem, boolean, GroupBase<DropdownDataItem>>) => ({
    ...base,
    background: props.selectStyleType === "colored" ? "var(--secondary-color)" : "",
    color: "#000000",
    borderRadius: '0px var(--border-radius) var(--border-radius) 0px'
  });

  const menuListStyle = (base: CSSObjectWithLabel, rsProps: MenuListProps<DropdownDataItem, boolean, GroupBase<DropdownDataItem>>) => ({
    ...base,
    overflow: "auto",
    maxHeight: "10rem",
    fontSize: "12px",
  });

  const singleValueStyle = (base: CSSObjectWithLabel, rsProps: SingleValueProps<DropdownDataItem, boolean, GroupBase<DropdownDataItem>>) => ({
    ...base,
    color: "#000000",
  });

  const multiValueStyle = (base: CSSObjectWithLabel, rsProps: MultiValueProps<DropdownDataItem, boolean, GroupBase<DropdownDataItem>>) => ({
    ...base,
    color: "#000000",
  });

  const indicatorSeparatorStyle = (base: CSSObjectWithLabel, rsProps: IndicatorSeparatorProps<DropdownDataItem, boolean, GroupBase<DropdownDataItem>>) => ({
    ...base,
    background: "#000000",
    height: props.selectStyleType === "colored" ? "100%" : "60%",
    marginTop: props.selectStyleType === "colored" ? "0" : "8px",
    marginBottom: props.selectStyleType === "colored" ? "0" : "8px",
  });

  const dropdownIndicatorStyle = (base: CSSObjectWithLabel, rsProps: DropdownIndicatorProps<DropdownDataItem, boolean, GroupBase<DropdownDataItem>>) => ({
    ...base,
    color: props.selectStyleType === "colored" ? "#ffff" : "#000000",
  });

  const rsStyles: StylesConfig<DropdownDataItem, boolean, GroupBase<DropdownDataItem>> = {
    menu: menuStyle,
    control: controlStyle,
    option: optionStyle,
    indicatorsContainer: indicatorsContainerStyle,
    menuList: menuListStyle,
    singleValue: singleValueStyle,
    multiValue: multiValueStyle,
    indicatorSeparator: indicatorSeparatorStyle,
    dropdownIndicator: dropdownIndicatorStyle,
  };

  const onChangeHandler = (newValue: MultiValue<DropdownDataItem> | SingleValue<DropdownDataItem>, actionMeta: ActionMeta<DropdownDataItem>) => {
    props.selectedData(newValue);
  };

  return (
    <div style={{
      width: "100%",
      boxSizing: "border-box"
    }}>
      <div>
        <Select
          // defaultMenuIsOpen={true}
          isDisabled={props.disabled}
          isOptionDisabled={(option) => option.disable as boolean}
          styles={rsStyles}
          placeholder={props.placeholder}
          className={styles.customSelect}
          options={props.data}
          onChange={onChangeHandler}
          isMulti={props.isMultiSelect}
          defaultValue={props.defaultvalue}
          value={props.isClearable ? (props.value != null ? props.value : null) : props.value}
        />
      </div>
    </div>
  )
}
