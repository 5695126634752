import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react'
import styles from '../form-details-by-id.module.scss';
import CustomText from '../../../../components/v2/common/custom-bodyText/custom-text';
import NoContent from '../../../../components/v2/common/no-content/no-content';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import { GetCustomLinkInteractionsResDto, InteractionStatus } from '../../../../store/models/custom-links';
import toast from 'react-hot-toast';
import CustomHeading from '../../../../components/v2/common/custom-heading/custom-heading';
import { TFunction } from 'i18next';
import Modal from '../../../../components/v2/common/modal/modal';
import svgOctIcon from "../../../../assets/v2/icons/octicon_copy-24.svg";
import svgPaneDelete from "../../../../assets/v2/close.svg";
import svgRevoke from "../../../../assets/v2/icons/revoke.svg";
import { useTranslation } from 'react-i18next';
import ToolTip from '../../../../components/v2/common/tool-tip/tool-tip';
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import { MultilineTags } from '../../../../components/v2/common/multiline-tags/multiline-tags';

export default function CustomLinkListing(props: CustomLinkListingProps) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [showInteractions, setShowInteractions] = useState(false);
  const [selectedCustomLink, setSelectedCustomLink] = useState(props.customLink[0]);

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal}
        header={
          <div>{t("sure_revoke_user")}</div>
        }
        content={
          <div style={{ display: "flex", gap: "1rem" }}>
            <CustomButton
              buttonType='button'
              classType='secondary'
              onClick={() => { props.revokeCustomLink(userId); setShowModal(false) }}>
              <div style={{ color: "#000" }}>{t("yes")}</div>
            </CustomButton>
            <CustomButton
              buttonType='button'
              classType='quaternary'
              onClick={() => { setShowModal(false) }}>
              <div>{t("no")}</div>
            </CustomButton>
          </div>
        }
      />
      <Modal showModal={showInteractions} setShowModal={setShowInteractions}
        header={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={styles.interactionsTitle}>{t("interactions")}</div>
              <div style={{ display: 'flex' }}>
                <CustomButton
                  style={{
                    background: "var(--accent-color)",
                    padding: "0.4rem",
                    borderRadius: '50%',
                    border: 'none',
                    boxSizing: "border-box",
                    display: 'flex'
                  }}
                  buttonType='button'
                  classType='tertiary'
                  onClick={() => { setShowInteractions(false) }}>
                  <img style={{ width: '1rem' }} src={svgPaneDelete} alt="" />
                </CustomButton>
              </div>
            </div>
            <div>{t("email")}: {selectedCustomLink.email}</div>
          </>
        }
        content={
          <div className={styles.interactionsContainer}>
            {
              props.customLink.length ?
                <>
                  {/* desktop view */}
                  <div className={'tableWrapper'}>
                    <>
                      <div className={'tableHeaderRow'}>
                        {/* <div className={`${'tableData'} ${styles.isoneThird}`}>Receiver(s)</div> */}
                        <div className={`${'tableData'} ${styles.isoneThird}`}>{props.t("custom_link_date_time")}</div>
                        <div className={`${'tableData'} ${styles.isoneThird}`}>{props.t("table_form_status")}</div>
                      </div>
                      {
                        selectedCustomLink.interactions.map((item, index) => (
                          <div key={item.id} className={'tableRow'} style={{ cursor: 'pointer' }}>
                            {/* <div className={`${'tableData'} ${styles.isoneThird}`}>{selectedCustomLink.email}</div> */}
                            <div className={`${'tableData'} ${styles.isoneThird}`}>{new Date(item.createdAt).toLocaleString()}</div>
                            <div className={`${'tableData'} ${styles.isoneThird}`}>
                              {
                                item.activity === InteractionStatus.SUBMITTED &&
                                <div className={styles.responded}> {props.t("custom_link_responded")}</div>
                              }
                              {
                                item.activity === InteractionStatus.CLICKED &&
                                <div className={styles.viewed}> {props.t("custom_link_viewed")}</div>
                              }
                              {
                                item.activity === InteractionStatus.SENT &&
                                <div className={styles.sent}> {props.t("custom_link_sent")}</div>
                              }
                            </div>
                          </div>
                        ))
                      }
                    </>
                  </div>
                  {/* mobile view */}
                  <div className={'mobileContainer'}>
                    {
                      selectedCustomLink.interactions.map((item, index) => (
                        <div className={'mobileCard'} key={item.id} >
                          <div className={'rowOne'}>
                            <TextComponent textType='Content'>{props.t("custom_link_date_time")}</TextComponent>
                            {
                              item.createdAt &&
                              <div style={{ color: "#999999" }}>{new Date(item.createdAt).toLocaleString()}</div>
                            }
                          </div>
                          <div className={'rowOne'}>
                            <TextComponent textType='Content'>{props.t("table_form_status")}</TextComponent>
                            <>
                              {
                                item.activity === InteractionStatus.SUBMITTED &&
                                <div className={styles.responded}> {props.t("custom_link_responded")}</div>
                              }
                              {
                                item.activity === InteractionStatus.CLICKED &&
                                <div className={styles.viewed}> {props.t("custom_link_viewed")}</div>
                              }
                              {
                                item.activity === InteractionStatus.SENT &&
                                <div className={styles.sent}> {props.t("custom_link_sent")}</div>
                              }
                            </>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </> :
                <NoContent content={`${t("nothing_to_show")}`} />
            }
          </div>
        }
      />
      <div className={styles.linkMainContainer}>
        <div className={styles.linkLeftContainer}>
          <div className={styles.shareEmailContainer}>
            <TextComponent textType='Header'>
              <div style={{ padding: '0.75rem' }}>{`${t("link_not_expired")}`}</div>
            </TextComponent>
            <div className={styles.mailContainer}>
              <MultilineTags type={'email'} value={props.emails} onChange={(tags) => props.setEmails(tags)} setErrors={props.setErrors} />
              {props.errors ? <CustomText textType='t7' style={{
                paddingTop: "10px",
                color: "red",
                fontWeight: "600",
              }}>
                <div>{`${t("enter_valid_email")}`}</div>
              </CustomText> : <></>}
              <CustomButton
                buttonType='button'
                classType='quaternary'
                onClick={props.dispatchMail}
                style={{ width: "max-content", alignSelf: "baseline " }}
                disabled={!(props.emails.length > 0)}
              >
                <span>{t("send")}</span>
              </CustomButton>
            </div>
            <TextComponent textType='Info'> <div style={{ color: 'var(--secondary-color)' }}>{t("enter_add_value")}</div></TextComponent>
          </div>
        </div>
        <div className={styles.customLinkContainer}>
          <TextComponent textType='Header'>
            <div style={{
              padding: '0.75rem'
            }}>{t("shared_with")}</div>
          </TextComponent>
          {props.customLink.length ?
            <>
              <div className={'tableWrapper tableNoShadow'}>
                <>
                  <div className={'tableHeaderRow'}>
                    <div className={'tableData'}>{props.t("receivers")}</div>
                    <div className={'tableData'}>{props.t("custom_link_date_time")}</div>
                    <div className={'tableData'}>{props.t("form_details_link")}</div>
                    <div className={'tableData'}>{props.t("custom_link_actions")}</div>
                  </div>
                  {
                    props.customLink.map((item, index) => (
                      <div key={index} className={'tableRow'} style={{ cursor: 'pointer' }}>
                        <div className={'tableData'}>{item.email}</div>
                        <div className={'tableData'}>{new Date(item.createdAt).toLocaleString()}</div>
                        <div className={'tableData'} style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}>
                          <a target={"_blank"} rel={"noreferrer"} className={`${styles.link}`} id={`${item.id}-link`} href={`${process.env.REACT_APP_RENDERER_WEBAPP}/${props.formId}/${item.id}`}>{`${process.env.REACT_APP_RENDERER_WEBAPP}/${props.formId}/${item.id}`}</a>

                        </div>
                        <div className={`${'tableData'} ${styles.actionContainerDesk}`}>
                          <ToolTip content={`${t("copy")}`} position='bottom'>
                            <CustomButton
                              style={{
                                background: "var(--accent-color)",
                                padding: " 12px",
                                borderRadius: '50%',
                                border: 'none',
                                boxShadow: 'none'
                              }}
                              buttonType='button'
                              classType='secondary'
                              disabled={((item.interactions[0] && item.interactions[0].activity === InteractionStatus.SUBMITTED) || props.isDisabledCustom || item.isRevoked)}
                              onClick={() => {
                                let ele = document.getElementById(`${item.id}-link`);
                                if (ele) {
                                  navigator.clipboard.writeText(ele.innerHTML);
                                  toast.success(props.t("form_details_toast_link_copy"));
                                }
                              }}
                            >
                              <div className={styles.isImageHolder}>
                                <img src={svgOctIcon} />
                              </div>
                            </CustomButton>
                          </ToolTip>
                          <ToolTip content={item.isRevoked ? `${t("link_revoked")}` : `${t("revoke")}`} position='bottom'>
                            <CustomButton
                              style={{
                                background: "var(--accent-color)",
                                padding: " 12px",
                                borderRadius: '50%',
                                border: 'none',
                                boxShadow: 'none'
                              }}
                              buttonType='button'
                              classType='secondary'
                              onClick={() => {
                                setUserId(item.id);
                                setShowModal(true);
                              }}
                              disabled={props.isDisabledCustom || item.isRevoked}
                            >
                              <div className={styles.isImageHolder}>
                                <img style={{ width: "1.2rem" }} src={svgRevoke} alt="delete" />
                              </div>
                            </CustomButton>
                          </ToolTip>
                          <CustomButton
                            buttonType={'button'}
                            classType={'quaternary'}
                            onClick={() => { setSelectedCustomLink(item); setShowInteractions(true); }}
                            style={{ width: 'fit-content' }}
                          >
                            {t("view_interactions")}
                          </CustomButton>
                        </div>
                      </div>
                    ))
                  }
                </>
              </div>
              <div className={styles.cardHolder}>
                {
                  props.customLink.map((item, index) => (
                    <div className={styles.mobileCard} key={index} >
                      <div className={styles.rowOne}>
                        <CustomHeading headerType='h4'>
                          {item.email}
                        </CustomHeading>
                      </div>
                      <div className={styles.rowOne}>
                        {
                          item.createdAt &&
                          <div style={{ color: "#999999" }}>{new Date(item.createdAt).toLocaleString()}</div>
                        }
                      </div>
                      <div className={styles.rowFour}>
                        <div className={styles.diamond}></div>
                        <div className={styles.line}></div>
                        <div className={styles.diamond}></div>
                      </div>
                      <div className={styles.rowOne}>
                        <ToolTip content={item.isRevoked ? `${t("link_revoked")}` : `${t("revoke")}`} position='bottom'>
                          <CustomButton
                            style={{
                              background: "#ffff",
                              padding: "9px 9px",
                              boxSizing: "border-box"
                            }}
                            buttonType='button'
                            classType='secondary'
                            onClick={() => {
                              // props.revokeCustomLink(item.id)
                              setUserId(item.id);
                              setShowModal(true);
                            }}
                            disabled={props.isDisabledCustom || item.isRevoked}
                          >
                            <div className={styles.isImageHolder}>
                              <img style={{ width: "1.2rem" }} src={svgRevoke} alt="delete" />
                            </div>
                          </CustomButton>
                        </ToolTip>
                        <ToolTip content={`${t("copy")}`} position='bottom'>
                          <CustomButton
                            style={{
                              background: "#ffff",
                              padding: "9px 9px",
                              boxSizing: "border-box"
                            }}
                            buttonType='button'
                            classType='secondary'
                            onClick={() => {
                              let ele = document.getElementById(`${item.id}-link`);
                              if (ele) {
                                navigator.clipboard.writeText(ele.innerHTML);
                                toast.success(props.t("form_details_toast_link_copy"));
                              }
                            }}
                            disabled={item.interactions[0] && item.interactions[0].activity === InteractionStatus.SUBMITTED || item.isRevoked || props.isDisabledCustom}
                          >
                            <div className={styles.isImageHolder}>
                              <img src={svgOctIcon} />
                            </div>
                          </CustomButton>
                        </ToolTip>
                        <CustomButton
                          buttonType={'button'}
                          classType={'quaternary'}
                          onClick={() => { setSelectedCustomLink(item); setShowInteractions(true); }}
                          style={{ width: 'fit-content' }}
                        >
                          {t("view_interactions")}
                        </CustomButton>
                      </div>
                    </div>
                  ))
                }
              </div>
            </> :
            <NoContent content={`${t("nothing_to_show")}`} />
          }
        </div>
      </div>
    </>
  )
}

interface CustomLinkListingProps {
  setEmails: (value: SetStateAction<string[]>) => void;
  setErrors: Dispatch<SetStateAction<string>>;
  resetTags: MutableRefObject<(() => {}) | undefined>;
  errors: string;
  dispatchMail: () => Promise<void>;
  emails: string[];
  customLink: GetCustomLinkInteractionsResDto[];
  t: TFunction<"translation", undefined>;
  formId: string | undefined;
  revokeCustomLink: (id: string) => Promise<void>
  isDisabledCustom: boolean;
}
