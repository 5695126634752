import React, { Dispatch, SetStateAction, useState } from 'react'
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import CustomInput from '../../../../components/v2/common/custom-input/custom-input';
import CustomSelect from '../../../../components/v2/common/custom-select/custom-select';
import PageLayout from '../page-layout/page-layout';
import { IndustryTypes, CompanyPopulation } from '../user-type/constants';
import styles from './company-setup.module.scss';
import { DropdownDataItem } from '../../../../components/v2/common/custom-select/custom-select.props';
import CustomHeading from '../../../../components/v2/common/custom-heading/custom-heading';
import { CountryList } from '../../../../constants/mobile-country-code';
import { CreateUserReqDto } from '../../../../store/models/user.interface';
import { createAccountThunk } from '../../../../store/thunks/user.thunk';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '../../../../store/store';
import { CreateOrganizationReqDto } from '../../../../store/models/sign-up.interface';
import svgChecklist from "../../../../assets/REPLACE-THESE/checklist.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgEmployee from "../../../../assets/REPLACE-THESE/employee.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgLocationMarker from "../../../../assets/REPLACE-THESE/location-marker.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgOfficeBuilding from "../../../../assets/REPLACE-THESE/office-building.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import CharacterValidator from '../../../../util/character-validator';

export default function CompanySetup(props: {
  setIsSignUp: Dispatch<SetStateAction<boolean>>,
  setUserData: React.Dispatch<React.SetStateAction<{
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    mobileNumber: string;
    newPassword: string;
    confirmPassword: string;
  }>>,
  userData: {
    firstName: string,
    lastName: string,
    email: string,
    countryCode: string;
    mobileNumber: string,
    newPassword: string,
    confirmPassword: string,
  }
}) {
  const navigate = useNavigate();
  const dispatch: RootDispatch = useDispatch();
  const reg = new RegExp(/^[A-Za-z0-9 ]+$/);
  const { t } = useTranslation();
  const [orgWarningText, setOrgWarningText] = useState<string | undefined>(undefined);
  const [countryWarningText, setCountryWarningText] = useState<string | undefined>(undefined);
  const [industryWarningText, setIndustryWarningText] = useState<string | undefined>(undefined);
  const [sizeWarningText, setSizeWarningText] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companyData, setCompanyData] = useState<{
    displayName: string,
    orgName: string,
    country: string,
    industry: string,
    size: string
  }>({
    displayName: "",
    orgName: "",
    country: "",
    industry: "",
    size: ""
  });

  async function submitForm() {
    setIsLoading(true);
    const orgData: CreateOrganizationReqDto = {
      orgDisplayName: companyData.displayName,
      industry: companyData.industry,
      country: companyData.country,
      size: companyData.size
    }
    const accountData: CreateUserReqDto = {
      firstName: props.userData.firstName,
      lastName: props.userData.lastName,
      email: props.userData.email,
      mobileNumber: props.userData.countryCode + props.userData.mobileNumber,
      password: props.userData.confirmPassword,
      orgInfo: orgData
    }
    try {
      const res = await dispatch(createAccountThunk(accountData)).unwrap();
      setIsLoading(false);

      if (res) {
        toast.success(t("account_setup_success"));
        navigate('/validate-otp');
      }
    } catch (err) {
      toast.error(`${t('page_not_found_something_wrong')}`);
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.pageLayout}>
          <PageLayout />
        </div>
        <div className={styles.login}>
          <CustomButton
            buttonType='button'
            classType='tertiary'
            onClick={() => {
              navigate('/')
            }}
          ><CustomHeading
            headerType='h4'
          >
              <div>{t("login")}</div>
            </CustomHeading>
          </CustomButton>
        </div>
        <div className={styles.rightContainer}>
          <span className={styles.setupTextOne}>{t("login_company_setup_company_info")}</span>
          <span>{t("login_company_setup_company_info_sub")}</span>
          <div className={styles.optionsContainer}>
            <img src={svgOfficeBuilding} alt="professional user" />
            <div style={{ width: "100%" }}>
              <CustomInput
                inputType='text'
                inputStyleType='fade'
                placeHolder={`${t('login_company_setup_company_name')}`}
                style={{
                  width: '100%'
                }}
                value={companyData.displayName}
                onChange={(e) => {
                  const value = e.target.value.trim();

                  if (value.length < 1) {
                    setOrgWarningText(`${t('login_company_setup_company_name_error')}`);
                  } else {
                    if (reg.test(value)) {
                      const validation = CharacterValidator(value);
                      if (validation.length > 0) {
                        setOrgWarningText(validation);
                      } else {
                        setOrgWarningText('')
                      }
                      setOrgWarningText('');
                    }
                    else
                      setOrgWarningText(`${t('company_setup_special_character_err')}`)
                  }
                  setCompanyData((prevState) => ({
                    ...prevState,
                    displayName: e.target.value,
                    orgName: value
                  }));
                }}
              />
              {
                orgWarningText && orgWarningText !== '' &&
                <p className={styles.errorText}>{orgWarningText}</p>
              }
            </div>
          </div>
          <div className={styles.optionsContainer}>
            <img src={svgLocationMarker} alt="location marker" />
            <CustomSelect
              placeholder={`${t('login_company_setup_country_name')}`}
              data={CountryList.map(item => {
                const data: DropdownDataItem = {
                  label: item.name,
                  value: item.name
                }
                return data;
              })}
              selectStyleType={"colored"}
              selectedData={(item) => {
                if (item !== null) {
                  const res = (item as DropdownDataItem).value;
                  if (res.length < 1) {
                    setCountryWarningText(`${t('login_company_setup_country_name_error')}`);
                  } else {
                    setCountryWarningText('');
                  }
                  setCompanyData((prevState) => ({
                    ...prevState,
                    country: res
                  }));
                }
              }}
            />
            {
              countryWarningText && countryWarningText !== '' &&
              <p className={styles.errorText}>{countryWarningText}</p>
            }
          </div>
          <div className={styles.optionsContainer}>
            <img src={svgChecklist} alt="checklist" />
            <CustomSelect
              placeholder={`${t("login_company_setup_industry")}`}
              data={IndustryTypes.map(item => {
                const data: DropdownDataItem = {
                  label: item,
                  value: item
                }
                return data;
              })}
              selectStyleType={"colored"}
              selectedData={(item) => {
                if (item !== null) {
                  const res = (item as DropdownDataItem).value;
                  if (res.length < 1) {
                    setIndustryWarningText(`${t("login_company_setup_industry_error")}`);
                  } else {
                    setIndustryWarningText('');
                  }
                  setCompanyData((prevState) => ({
                    ...prevState,
                    industry: res
                  }));
                }
              }}
            />
            {
              industryWarningText && industryWarningText !== '' &&
              <p className={styles.errorText}>{industryWarningText}</p>
            }
          </div>
          <div className={styles.optionsContainer} >
            <img src={svgEmployee} alt="employees" />
            <CustomSelect
              placeholder={`${t("login_company_setup_company_size")}`}
              selectStyleType={"colored"}
              data={CompanyPopulation.map(item => {
                const data: DropdownDataItem = {
                  label: item,
                  value: item
                }
                return data;
              })}
              selectedData={(item) => {
                if (item !== null) {
                  const res = (item as DropdownDataItem).value;
                  if (res && res.length < 1) {
                    setSizeWarningText(`${t("login_company_setup_company_size_error")}`);
                  } else {
                    setSizeWarningText('');
                  }
                  setCompanyData((prevState) => ({
                    ...prevState,
                    size: res
                  }));
                }
              }}
            />
            {
              sizeWarningText && sizeWarningText !== '' &&
              <p className={styles.errorText}>{sizeWarningText}</p>
            }
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <CustomButton
              buttonType='button'
              onClick={() => {
                props.setIsSignUp(true);
              }}
              style={{
                width: "40%"
              }}
              classType={'tertiary'}
            >
              <span>{`${t("go_back")}`}</span>
            </CustomButton>
            <CustomButton
              buttonType='button'
              disabled={(orgWarningText !== '' || countryWarningText !== '' || industryWarningText !== '' || sizeWarningText !== '') ? true : false}
              onClick={() => {
                submitForm();
              }}
              classType={'quaternary'}
              isLoading={isLoading}
            >
              <span>{isLoading ? t("loading") : `${t("continue")}`}</span>
            </CustomButton>
          </div>
        </div>
      </div>
    </>
  )
}
