import { useContext, useEffect, useState } from 'react';
import CustomText from '../../../../components/v2/common/custom-bodyText/custom-text'
import styles from './score-card.module.scss'
import { CustomPagination } from '../../../../components/v2/common/custom-pagination/custom-pagination';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { downloadOptions } from '../form-responses';
import { FormControl } from '../../../../store/models/form-controls/form-control.interface';
import { QuizFormResponses } from '../../../../store/models/form-response.interface';
import { useNavigate, useParams } from 'react-router-dom';
import { getQuizFormControl } from '../../../../api/form-controls';
import { fetchResponseCount, getQuizModeScores } from '../../../../api/form-response';
import toast from 'react-hot-toast';
import { ACTIVE_PAGE_STATUS } from '../../../../store/models/active-page';
import { activePageThunk } from '../../../../store/thunks/active-page.thunk';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../store/store';
import { SocketContext } from '../../../../context/socket';
import { setNewData } from '../../../../store/slices/active-page.slice';
import svgAward from "../../../../assets/REPLACE-THESE/award.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgTrophy from "../../../../assets/REPLACE-THESE/trophy.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgPaneDelete from "../../../../assets/v2/icons/pane-delete.svg";

export default function ScoreCard() {
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterMenuActive, setIsFilterMenuActive] = useState(false);
  const [quizControl, setQuizControl] = useState<FormControl>();
  const [quizFormResponses, setQuizFormResponses] = useState<Array<QuizFormResponses>>([]);
  const { formId, responseId } = useParams();
  const [firstScorer, setFirstScorer] = useState<QuizFormResponses[]>();
  const [responseCount, setResponseCount] = useState(0);
  const [quizSkip, setQuizSkip] = useState<number>(0);
  const { t } = useTranslation();
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const activePage = useSelector((state: RootState) => state.activePage.activePage);
  const socket = useContext(SocketContext);
  let dispatch: RootDispatch = useDispatch();

  useEffect(() => {
    if (formId) {
      getQuizControl(formId);
      fetchFormResponseCount(formId)
    }
  }, [])

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activePage])

  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    if (formId) {
      dispatch(activePageThunk({ socket: socket, payload: { pageName: `score-card-${formId}`, status } }));
    }
  }

  useEffect(() => {
    if (newData && formId) {
      getQuizControl(formId);
      fetchFormResponseCount(formId);
      dispatch((setNewData({ action: "" })));
    }
  }, [newData])

  const getQuizControl = async (formId: string) => {
    try {
      const quizControl = await getQuizFormControl(formId);
      if (quizControl) {
        const quizScores = await getQuizModeScores(formId, quizSkip, 10);
        if (quizScores) setQuizFormResponses(quizScores);
        if (quizScores.length > 0) setFirstScorer([quizScores[0], quizScores[1], quizScores[2]]);
        setQuizControl(quizControl);
      }
    } catch (err) { }
  }

  const fetchFormResponseCount = async (formId: string) => {
    try {
      const response = await fetchResponseCount(formId);
      setResponseCount(response.data.count);
    } catch (err) {
      toast.error(t('form_details_toast_form_response_err'));
    }
  }

  async function handleQuizPagination(num: number) {
    if (formId) {
      setQuizSkip(num);
      const quizScores = await getQuizModeScores(formId, num, 10);
      setQuizFormResponses(quizScores);
    }
  }

  const filterDiv = () => (
    <>
      <div style={{ width: "100%" }}>
        <div className={styles.tagTitleContainer}>
          <CustomText textType='t4'>
            <div className={styles.tagTitle}>{t('download_as_responses')}</div>
          </CustomText>
          <div className={styles.mobileCloseBtn}>
            <button className={styles.closebtn} onClick={() => { setIsFilterMenuActive(false) }}>
              <img src={svgPaneDelete} alt="" />
            </button>
          </div>
        </div>
        <hr className={styles.line} />
      </div>
      <div className={styles.downloadContainer}>
        {downloadOptions.map((item, index) => (
          <React.Fragment key={index}>
            <div className={styles.filterHolder}>
              <input className={styles.tag} type='button' value={item.label} onClick={async () => {
                setIsLoading(true);
                // downloadAsCsvOrExcel(formId, item.value, hasFile);
                setIsLoading(false);
                setIsFilterMenuActive(false);
              }} />
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  )

  return (
    <>
      <div className={styles.container}>
        {
          quizFormResponses.length > 0 && firstScorer && quizControl &&
          <div className={styles.main}>
            <div className={styles.bottomContainer}>
              <div className={styles.userTableComplete}>
                <div className={styles.leftFirstContainer}>
                  <div className={styles.leftPaneFirstColumn}>
                    <div className={styles.participantsRow}>
                      <CustomText textType='t3' style={{ cursor: "default", fontWeight: "500", color: "#828282" }} >
                        <div>{t("no_of_participants")}</div>
                      </CustomText>
                      <CustomText textType='t3' style={{ cursor: "default", fontWeight: "500", }} >
                        <div>{responseCount}</div>
                      </CustomText>
                    </div>
                  </div>
                  <div className={styles.leftPaneFirstColumn}>
                    <div className={styles.participantsRow}>
                      <CustomText textType='t3' style={{ cursor: "default", fontWeight: "500", color: "#828282" }} >
                        <div>{t("avg_time_taken")}</div>
                      </CustomText>
                      <CustomText textType='t3' style={{ cursor: "default", fontWeight: "500" }} >
                        <div>{quizControl.averageTime ? quizControl.averageTime : '00:00'}</div>
                      </CustomText>
                    </div>
                  </div>
                </div>
                <div className={styles.userTableMainDiv}>
                  <div className={'tableWrapper tableNoShadow'}>
                    <div className={'tableHeaderRow'}>
                      <div className={'tableData'}>{t("participant")}</div>
                      <div className={'tableData'}>{t("total_score_text")}</div>
                      <div className={'tableData'}>{t("time_take")}</div>
                    </div>
                    {
                      quizFormResponses.map((item, index) => (
                        firstScorer && <div
                          key={index}
                          className={'tableRow'}
                          onClick={() => { }}>
                          <img style={{ width: "3rem" }} src={firstScorer.length > 1 && (firstScorer[0] && item.responseId === firstScorer[0].responseId) || (firstScorer[1] && item.responseId === firstScorer[1].responseId) || (firstScorer[2] && item.responseId === firstScorer[2].responseId) ? svgAward : svgTrophy} alt="arrow" />
                          <div className={'tableData'}><div className={styles.tableData}>{item.position}{item.position === 1 && "st"}{item.position === 2 && "nd"}{item.position === 3 && "rd"}{item.position > 3 && 'th'}</div>{item.email}</div>
                          <div className={'tableData'}>{`${item.score}/${quizControl?.config.totalScore}`}</div>
                          <div className={'tableData'}>{item.duration > 0 ? `${Math.floor((item.duration / 1000 / 60) % 60).toString().padStart(2, "0")}:${Math.floor((item.duration / 1000) % 60).toString().padStart(2, "0")}` : "00:00"}</div>

                        </div>
                      ))
                    }
                  </div>
                </div>
                <div style={{ margin: "1rem 1rem 1rem auto", }}>
                  {responseCount > 10 ? <CustomPagination countPerPage={10} totalCount={responseCount} handlePagination={(res) => handleQuizPagination(res.skip)} /> : ''}
                </div>
              </div>

            </div>
          </div>
        }
        {quizFormResponses.length > 0 && firstScorer && quizControl &&
          < div className={styles.mobileMain}>
            <div className={styles.leftPane}>
              <div className={styles.leftFirstContainer}>
                <div className={styles.leftPaneFirstColumn}>
                  <div className={styles.participantsRow}>
                    <CustomText textType='t3' style={{ cursor: "default", fontWeight: "500", color: "#828282" }} >
                      <div>{t("no_of_participants")}</div>
                    </CustomText>
                    <CustomText textType='t3' style={{ cursor: "default", fontWeight: "500", }} >
                      <div>{responseCount}</div>
                    </CustomText>
                  </div>
                </div>
                <div className={styles.leftPaneFirstColumn}>
                  <div className={styles.participantsRow}>
                    <CustomText textType='t3' style={{ cursor: "default", fontWeight: "500", color: "#828282" }} >
                      <div>{t("avg_time_taken")}</div>
                    </CustomText>
                    <CustomText textType='t3' style={{ cursor: "default", fontWeight: "500" }} >
                      <div>{quizControl.averageTime ? quizControl.averageTime : '00:00'}</div>
                    </CustomText>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.userTableComplete}>
              <div>
                <div className={styles.mobileCardCont}>
                  {
                    quizFormResponses.map((item, index) => (
                      firstScorer &&
                      <div
                        key={index}
                        className={styles.cardCont}
                        onClick={() => { }}>
                        <div>
                          <img style={(firstScorer[1] && item.responseId === firstScorer[1].responseId) || (firstScorer[2] && item.responseId === firstScorer[2].responseId) ? { width: "2rem" } : { width: "1.8rem" }} src={firstScorer.length > 1 && (firstScorer[0] && item.responseId === firstScorer[0].responseId) || (firstScorer[1] && item.responseId === firstScorer[1].responseId) || (firstScorer[2] && item.responseId === firstScorer[2].responseId) ? svgAward : svgTrophy} alt="arrow" />
                        </div>
                        <div className={styles.cardContentCont}>
                          <div className={styles.cardContent}><div>{item.position}{item.position === 1 && "st"}{item.position === 2 && "nd"}{item.position !== 1 && item.position !== 2 && 'rd'}</div>{item.email.length < 25 ? item.email : item.email.substring(0, 15) + "..."}</div>
                          <div className={styles.cardContentCont}>
                            <div className={styles.cardContent}>
                              <div>{t("total_score_text")} : </div>
                              <div>{`${item.score}/${quizControl?.config.totalScore}`}</div>
                            </div>
                            <div className={styles.cardContent}>
                              <div>{t("time_take")} : </div>
                              <div>{item.duration > 0 ? `${Math.floor((item.duration / 1000 / 60) % 60).toString().padStart(2, "0")}:${Math.floor((item.duration / 1000) % 60).toString().padStart(2, "0")}` : "00:00"}</div>
                            </div>
                          </div>
                        </div>

                      </div>
                    ))
                  }
                </div>
              </div>
              <div className={`${styles.mobileFilterContainer} ${isFilterMenuActive ? styles.mobileFilterContainerActive : ""}`}>
                {filterDiv()}
              </div>
            </div>
            <div style={{ margin: "1rem 1rem 1rem auto", }}>
              {responseCount > 10 ? <CustomPagination countPerPage={10} totalCount={responseCount} handlePagination={(res) => handleQuizPagination(res.skip)} /> : ''}
            </div>
          </div >
        }
      </div>
    </>
  );
}

