import TextComponent from '../../../../components/v2/common/text-component/text-component';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import hasSufficientScopes from '../../../../util/access-validator/access-validator';
import svgPaneDelete from "../../../../assets/v2/close.svg";
import svgNewForm from '../../../../assets/v2/icons/newform.svg';
import svgTemplates from '../../../../assets/v2/icons/report.svg';
import Cards from '../../../../components/v2/common/cards/cards';
import { CreateFormProps } from './create-form.props';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../store/store';
import Modal from '../../../../components/v2/common/modal/modal';
import { useEffect } from 'react';
import { fetchSubscriptionThunk } from '../../../../store/thunks/subscription.thunk';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';

export default function CreateForm(props: CreateFormProps) {
  const dispatch: RootDispatch = useDispatch();
  const { t } = useTranslation();
  const isLatestSubscriptionActive = useSelector((state: RootState) => state.subscription.data.isActive);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchSubscriptionThunk());
  }, []);

  const goToNewForm = async () => {
    if (isLatestSubscriptionActive) {
      const checkScope = hasSufficientScopes(["forms:create"]);
      if (!checkScope) {
        toast.error(t('form_creation_no_permission'));
      }
      navigate('../forms/create')
    } else {
      toast.error(t('no_active_subscription_error'));
    }
  }
  return (
    <>
      <Modal
        setShowModal={props.setShowModal}
        showModal={props.showModal}
        header={
          <div style={{
            display: 'flex'
          }}>
            <TextComponent textType='Header'>
              <div> {`${t("create_form_modal")}`}</div>
            </TextComponent>
            <div style={{ display: 'flex', marginLeft: 'auto' }}>
              <CustomButton
                style={{
                  background: "var(--accent-color)",
                  padding: "0.2rem",
                  borderRadius: '50%',
                  border: 'none',
                  boxSizing: "border-box",
                  display: 'flex'
                }}
                buttonType='button'
                classType='tertiary'
                onClick={() => { props.setShowModal(false); }}>
                <img style={{ width: '1.2rem' }} src={svgPaneDelete} alt="" />
              </CustomButton>
            </div>
          </div>
        }
        content={
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            background: 'var(--background-color)'
          }}>
            <Cards title={`${t("create_from_scratch")}`} onClick={() => { goToNewForm(); }} isClickabel={true}>
              <div>
                <img style={{ width: '7rem' }} src={svgNewForm} alt='' />
              </div>
            </Cards>
            <Cards title={`${t("create_from_template")}`} onClick={() => { navigate('../templates') }} isClickabel={true}>
              <div>
                <img style={{ width: '7rem' }} src={svgTemplates} alt='' />
              </div>
            </Cards>
          </div>
        }
      />
    </>
  )
}
