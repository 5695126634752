import { createSlice } from '@reduxjs/toolkit';
import { TextStyle } from '../models/text-style.enum';
import { RootState } from '../store';

const initialDetailsState: {
  formId: string;
  controlId: string;
  parentId: string;
  style?: TextStyle;    // button name (B,I,U) clicked
  selectedTextStyleBtn: any;  // selected buttons (B, U, I) depending on active editor state and focus
  refresh: boolean;
} = { formId: '', controlId: '', parentId: '', selectedTextStyleBtn: { BOLD: '', ITALIC: '', UNDERLINE: '' }, refresh: false };

export const activeDetailsSlice = createSlice({
  name: 'activeFormAndControlDetails',
  initialState: initialDetailsState,
  reducers: {
    setActiveFormId(state, action) {
      state.formId = action.payload.formId;
    },
    setActiveControlId(state, action) {
      state.controlId = action.payload.controlId;
    },
    setActiveParentId(state, action) {
      state.parentId = action.payload.parentId;
    },
    setStyle(state, action) {
      state.refresh = !state.refresh; // to reload the page even if same style clicked twice
      state.style = action.payload;
    },
    setSelectedTextStyleBtn(state, action) {
      state.selectedTextStyleBtn = { ...action.payload };
    }
  },
});

export const getControlDetails = (state: RootState) => {
  if (state.activeDetails.formId.length > 0 && state.activeDetails.controlId.length > 0 && state.forms.data.length > 0 && state.formControls.data[state.activeDetails.formId] != null && Object.keys(state.formControls.data[state.activeDetails.formId]).length > 0 && state.formControls.data[state.activeDetails.formId] != null) {
    const obj = state.formControls.data[state.activeDetails.formId];
    return Object.keys(obj).length === 0 ? null : state.formControls.data[state.activeDetails.formId];
  }
}

export const getFormDetails = (state: RootState) => {
  return state.forms.data.find(item => item.id === state.activeDetails.formId)
}

export const getActiveControl = (state: RootState) => {
  return state.activeDetails.controlId;
}

export const { setActiveFormId, setActiveControlId, setActiveParentId, setSelectedTextStyleBtn, setStyle } = activeDetailsSlice.actions;
