import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/globals.scss";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import KeyCloak from "./components/common/keycloak/keycloak.component";
import { initI18N } from './util/i18n';

// import reportWebVitals from './reportWebVitals';

// Refer 1: https://create-react-app.dev/docs/measuring-performance/
// Refer 2: https://github.com/GoogleChrome/web-vitals
// reportWebVitals(console.log);

initI18N();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <KeyCloak>
    <Provider store={store}>
      {/* <React.StrictMode> */}
      <React.Suspense fallback="loading">
        <App />
      </React.Suspense>
      {/* </React.StrictMode> */}
    </Provider>
  </KeyCloak>
);
