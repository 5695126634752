import React, { SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import styles from './custom-otp-input.module.scss';

export default function CustomOtpInput(props: OtpInputProps) {
    const [otp, setOtp] = useState('');
    const onChange = (value: string) => {
        props.setOtp(value);
        setOtp(value)
    }
    const RE_DIGIT = new RegExp(/^\d+$/);


    const valueItems = useMemo(() => {
        const valueArray = otp.split('');
        const items: Array<string> = [];
        for (let i = 0; i < 6; i++) {
            const char = valueArray[i];
            if (RE_DIGIT.test(char)) {
                items.push(char);
            } else {
                items.push('');
            }
        }
        return items;
    }, [otp]);

    const focusToNextInput = (target: HTMLElement) => {
        const nextElementSibling =
            target.nextElementSibling as HTMLInputElement | null;
        if (nextElementSibling) {
            nextElementSibling.focus();
        }
    };
    const focusToPrevInput = (target: HTMLElement) => {
        const previousElementSibling =
            target.previousElementSibling as HTMLInputElement | null;
        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };

    const inputOnChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        idx: number
    ) => {

        const target = e.target;
        let targetValue = target.value.trim();
        const isTargetValueDigit = RE_DIGIT.test(targetValue);

        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }
        targetValue = isTargetValueDigit ? targetValue : ' ';
        const nextInputEl = target.nextElementSibling as HTMLInputElement | null;
        if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
            return;
        }
        const targetValueLength = targetValue.length;
        if (targetValueLength === 1) {
            const newValue =
                otp.substring(0, idx) + targetValue + otp.substring(idx + 1);
            onChange(newValue);
            if (!isTargetValueDigit) {
                return;
            }
            focusToNextInput(target)
        } else if (targetValueLength === 6) {
            onChange(targetValue);
            target.blur();
        }
    };

    const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        const target = e.target as HTMLInputElement;
        const targetValue = target.value;

        if (key === 'ArrowRight' || key === 'ArrowDown') {
            e.preventDefault();
            return focusToNextInput(target);
        }

        if (key === 'ArrowLeft' || key === 'ArrowUp') {
            e.preventDefault();
            return focusToPrevInput(target);
        }

        if (e.key !== 'Backspace' || targetValue !== '') {
            return;
        }

        focusToPrevInput(target);
    };

    const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const { target } = e;
        const prevInputEl =
            target.previousElementSibling as HTMLInputElement | null;

        if (prevInputEl && prevInputEl.value === '') {
            return prevInputEl.focus();
        }
        target.setSelectionRange(0, target.value.length);
    };

    return (
        <div className={styles.otpGroup}>
            {valueItems.map((digit, idx) => (
                <input
                    key={idx}
                    type="text"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    pattern="\d{1}"
                    maxLength={6}
                    className={styles.otpInput}
                    value={digit}
                    onChange={(e) => inputOnChange(e, idx)}
                    onKeyDown={inputOnKeyDown}
                    onFocus={inputOnFocus}
                />
            ))}
        </div>
    )
}

interface OtpInputProps {
    setOtp: (value: SetStateAction<string>) => void
}