import { createAsyncThunk } from "@reduxjs/toolkit";
import { createRole, deleteRole, getRoles, updateRole } from "../../api/roles";
import { CreateRoleReqDto, UpdateRoleReqDto } from "../models/roles.interface";

export const getRolesThunk = createAsyncThunk(
  'roles/getRoles',
  async () => {
    const response = await getRoles();
    let { status, message, ...data } = response;
    return data;
  }
);

export const createRoleThunk = createAsyncThunk(
  'roles/createRole',
  async (payload: CreateRoleReqDto) => {
    const response = await createRole(payload);
    let { status, message, ...data } = response.data;
    return data;
  }
);

export const updateRoleThunk = createAsyncThunk(
  'roles/updateRole',
  async (payload: { roleName: string, data: UpdateRoleReqDto }) => {
    await updateRole(payload.roleName, payload.data);
    return payload;
  }
);

export const deleteRoleThunk = createAsyncThunk(
  'roles/deleteRole',
  async (roleName: string) => {
    await deleteRole(roleName);
    return roleName;
  }
);
