import { useState } from 'react';
import SideBar from '../../../components/v2/common/side-bar/side-bar';
import styles from './layout.module.scss';
import { Outlet } from 'react-router-dom';
import TopMenu from '../../../components/v2/common/top-menu/top-menu';

export default function LayoutV2() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={styles.container}>
      <SideBar setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
      <div className={styles.childContainer}>
        <TopMenu />
        <div className={`${styles.parentContainer}`}><Outlet /></div>
      </div>
    </div>
  )
}
