import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomHeading from "../../../../components/v2/common/custom-heading/custom-heading";
import styles from './success.module.scss';
import CustomButton from "../../../../components/v2/common/custom-button/custom-button";
import { RootDispatch, RootState } from '../../../../store/store';
import { useEffect } from 'react';
import { fetchSubscriptionThunk } from '../../../../store/thunks/subscription.thunk';
import { useDispatch, useSelector } from 'react-redux';
import svgSquare from "../../../../assets/square.svg";
import gifSuccess from "../../../../assets/success.gif";
import svgTick from "../../../../assets/tick.svg";
import svgRightArrow from "../../../../assets/v2/icons/right-arrow.svg";
import { UserMetadataKey } from '../../../../store/models/user-metadata-key.enum';

export default function SubscriptionSuccessPage2() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let dispatch: RootDispatch = useDispatch();
  const activeOrg = useSelector((state : RootState) => state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);

  useEffect(() => {
    dispatch(fetchSubscriptionThunk());
  }, []);

  return (
    <>
      <div className={styles.main}>
        <div className={styles.pageContainer}>
          <div className={styles.parent}>
            <img className={styles.successgif} src={gifSuccess}></img>
            <img className={styles.successbtn} src={svgSquare}></img>
            <img className={styles.successtick} src={svgTick}></img>
            <span className={styles.successText}> {`${t("payment_success")}`} </span>
          </div>
          <div className={styles.alignCenter}>
            <span className={styles.paymentText}>{`${t("payment_success_subheading")}`} </span>
            <div style={{ display: "flex", gap: "1rem", marginTop: "20px" }}>

              <CustomButton
                buttonType='submit'
                classType='tertiary'
                onClick={() => { navigate(`/${activeOrg}/settings`) }}
              >
                <div className={styles.signBtn}>
                  <CustomHeading
                    headerType='h4'
                  >
                    <div>{`${t("close")}`}</div>
                  </CustomHeading>
                </div>
              </CustomButton>

              <CustomButton
                buttonType='submit'
                classType='primary'
                onClick={() => { navigate(`/${activeOrg}/forms/build`) }}
              >
                <div className={styles.signBtn}>
                  <CustomHeading
                    headerType='h4'
                    style={{ color: "#FFFFFF" }}
                  >
                    <div>{`${t("create_form")}`}</div>
                  </CustomHeading>
                  <img src={svgRightArrow} alt="" />
                </div>
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
