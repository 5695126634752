import { useTranslation } from 'react-i18next';
import CustomText from '../../../../../components/v2/common/custom-bodyText/custom-text';
import CustomHeading from '../../../../../components/v2/common/custom-heading/custom-heading';
import styles from './add-integration.module.scss';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CustomButton from '../../../../../components/v2/common/custom-button/custom-button';
import CustomInput from '../../../../../components/v2/common/custom-input/custom-input';
import CustomSelect from '../../../../../components/v2/common/custom-select/custom-select';
import { GetIntegrationsRespItem, IntegrationType, MongodbConnectionProperties, GoogleSheetConnectionProperties } from '../../../../../store/models/settings/integrations/get-integrations.interface';
import { SingleValue } from 'react-select';
import { DropdownDataItem } from '../../../../../components/v2/common/custom-select/custom-select.props';
import { CreateIntegrationReqDto } from '../../../../../store/models/settings/integrations/create-integrations.interface';
import { RootDispatch, RootState } from '../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { createIntegrationsThunk, getAllIntegrationsThunk, updateIntegrationThunk } from '../../../../../store/thunks/integrations.thunk';
import { toast } from 'react-hot-toast';
import { UpdateIntegrationReqDto } from '../../../../../store/models/settings/integrations/update-integrations.interface';
import svgPaneDelete from "../../../../../assets/v2/close.svg";
import MongoDbIntegration from './mongo-db-integration/mongo-db-integration';
import GoogleSheetsIntegrations from './google-sheets-integrations/google-sheets-integrations';
import CharacterValidator from '../../../../../util/character-validator';
import TextComponent from '../../../../../components/v2/common/text-component/text-component';
import { getAllIntegrationsSelector } from '../../../../../store/slices/integrations.slice';

export interface AddIntegrationProps {
  setSelectedIntegration: Dispatch<SetStateAction<GetIntegrationsRespItem | undefined>>;
  setIsPanelOpen: Dispatch<SetStateAction<boolean>>;
  integrationsType: "CREATE" | "EDIT";
  selectedIntegration: GetIntegrationsRespItem | undefined;
}

export function AddIntegration(props: AddIntegrationProps) {
  const { t } = useTranslation();
  const integrationTypes = [
    { label: `${t("mongodb")}`, value: IntegrationType.MONGODB },
    { label: `${t("mongodb_atlas")}`, value: IntegrationType.MONGODB_ATLAS },
    // { label: 'Webhook', value: IntegrationType.WEBHOOK },
    { label: `${t("google_sheets")}`, value: IntegrationType.GOOGLE_SHEETS ,disable:true},
  ];
  const integrations = useSelector((state: RootState) => getAllIntegrationsSelector(state));
  const dispatch: RootDispatch = useDispatch();
  const [displayName, setDisplayName] = useState('');
  const [selectedIntegrationType, setSelectedIntegrationType] = useState<{ label: string, value: IntegrationType }>({ label: 'MongoDB', value: IntegrationType.MONGODB });
  const [connectionProps, setConnectionProps] = useState<MongoDBProperties | GoogleSheetProperties>(
    {
      host: '',
      dbName: '',
      collectionName: '',
      port: '',
      userName: '',
      password: '',
      authDb: '',
      sheetName: '',
      sheetUrl: '',
      token: ''
    }
  );
  const [errors, setErrors] = useState<{ host?: string, dbName?: string, collectionName?: string, userName?: string, integrationType?: string, integrationName?: string; sheetName?: string; token?: string }>({});
  const [isDirtyFlags, setIsDirtyFlags] = useState({ host: false, dbName: false, collectionName: false, userName: false, integrationType: false, integrationName: false, sheetName: false, token: false });
  const [isError, setIsError] = useState(true)

  useEffect(() => {
    if (props.integrationsType === 'EDIT' && props.selectedIntegration) {
      if (props.selectedIntegration.type === 'GOOGLE_SHEETS') {
        const res = props.selectedIntegration.config as GoogleSheetConnectionProperties;
        setConnectionProps({
          sheetName: res.sheetName,
          sheetUrl: '',
          token: ''
        })
      } else {
        const res = props.selectedIntegration.config as MongodbConnectionProperties;
        setConnectionProps({
          collectionName: res.collectionName,
          dbName: res.databaseName,
          host: res.host,
          userName: res.username,
          authDb: res.authDatabase ? res.authDatabase : '',
          password: res.credentials && res.credentials.password ? res.credentials.password : '',
          port: res.port ? res.port : ''
        });
      }
      setDisplayName(props.selectedIntegration.displayName);
      const val = integrationTypes.find(val => val.value === props.selectedIntegration?.type);
      if (val) setSelectedIntegrationType(val);
    }
  }, [props.integrationsType]);

  useEffect(() => {
    validateFieldsOnChange();
  }, [connectionProps, displayName])



  useEffect(() => {
    setErrors({});
    setIsDirtyFlags({ host: false, dbName: false, collectionName: false, userName: false, integrationType: false, integrationName: false, sheetName: false, token: false });
  }, [selectedIntegrationType]);

  const createIntegration = async () => {
    if (validateFields() === true) {
      return;
    } else {
      let googleConfig: GoogleSheetConnectionProperties = {
        sheetName: (connectionProps as GoogleSheetProperties).sheetName,
        credentials: {
          authCode: (connectionProps as GoogleSheetProperties).token,
        },
      }
      if (props.integrationsType === 'CREATE') {
        try {
          let payload: CreateIntegrationReqDto = {
            displayName: displayName,
            type: selectedIntegrationType.value,
            config: {},
          };

          if (selectedIntegrationType.value === 'GOOGLE_SHEETS') {
            payload = {
              displayName: displayName,
              type: selectedIntegrationType.value,
              config: googleConfig
            }
          } else {
            payload = {
              displayName: displayName,
              type: selectedIntegrationType.value,
              config: {
                host: (connectionProps as MongoDBProperties).host,
                databaseName: (connectionProps as MongoDBProperties).dbName,
                collectionName: (connectionProps as MongoDBProperties).collectionName,
                port: (connectionProps as MongoDBProperties).port,
                username: (connectionProps as MongoDBProperties).userName,
                authDatabase: (connectionProps as MongoDBProperties).authDb,
                credentials: {
                  password: (connectionProps as MongoDBProperties).password
                }
              }
            }
          }
          await dispatch(createIntegrationsThunk(payload)).unwrap();
          await dispatch(getAllIntegrationsThunk()).unwrap();
          toast.success(t("integration_added"));
          props.setIsPanelOpen(false);
          props.setSelectedIntegration(undefined);
        } catch (err) {
          toast.error(t("integration_fail"));
        }
      } else if (props.integrationsType === 'EDIT') {
        try {
          let payload: UpdateIntegrationReqDto;
          if (selectedIntegrationType.value === 'GOOGLE_SHEETS') {
            payload = {
              type: selectedIntegrationType.value,
              config: {
                sheetName: (connectionProps as GoogleSheetProperties).sheetName,
              }
            }
          } else {
            payload = {
              type: selectedIntegrationType.value,
              config: {
                host: (connectionProps as MongoDBProperties).host,
                databaseName: (connectionProps as MongoDBProperties).dbName,
                collectionName: (connectionProps as MongoDBProperties).collectionName,
                port: (connectionProps as MongoDBProperties).port,
                username: (connectionProps as MongoDBProperties).userName,
                authDatabase: (connectionProps as MongoDBProperties).authDb,
                credentials: {
                  password: (connectionProps as MongoDBProperties).password
                }
              }
            }
          }
          if (props.selectedIntegration) {
            await dispatch(updateIntegrationThunk({ id: props.selectedIntegration?.id, payload: payload })).unwrap();
            await dispatch(getAllIntegrationsThunk()).unwrap();
            toast.success(t("integration_added"));
            props.setIsPanelOpen(false);
            props.setSelectedIntegration(undefined);
          }
        } catch (err) {
          toast.error(t("integration_fail"));
        }
      }
    }
  }

  const validateFields = () => {
    const requiredConnectionProps = selectedIntegrationType.value === 'GOOGLE_SHEETS' ? ['sheetName', 'token'] : ['host', 'dbName', 'collectionName', 'userName'];
    let isErrorsPresent = false;
    if (displayName.trim().length === 0) {
      setErrors((prev) => { return { ...prev, integrationName: `${t("this_required")}` } });
      setIsDirtyFlags((prev) => { return { ...prev, integrationName: true } });
      isErrorsPresent = true;
    }

    const validName = integrations.filter((item)=> item.displayName === displayName);
    if(props.integrationsType === 'CREATE' && validName.length > 0){
      setErrors((prev) => { return { ...prev, integrationName: `Display name already exist` } });
      setIsDirtyFlags((prev) => { return { ...prev, integrationName: true } });
      isErrorsPresent = true;
    }

    for (const vals in connectionProps) {
      if (requiredConnectionProps.includes(vals)) {
        const val = connectionProps[vals as keyof object] as unknown as string;
        if (!val || (val && val.trim().length === 0)) {
          setErrors((prev) => { return { ...prev, [vals]: `${t("this_required")}` } });
          setIsDirtyFlags((prev) => { return { ...prev, [vals]: true } });
          isErrorsPresent = true;
        }
      }
    }
    return isErrorsPresent;
  }

  const validateFieldsOnChange = () => {
    const requiredConnectionProps = selectedIntegrationType.value === 'GOOGLE_SHEETS' ? ['sheetName', 'token'] : ['host', 'dbName', 'collectionName', 'userName'];
    if (displayName.trim().length === 0) {
      setIsError(true);
      return;
    }
    for (const vals in connectionProps) {
      if (requiredConnectionProps.includes(vals)) {
        const val = connectionProps[vals as keyof object] as unknown as string;
        if (!val || (val && val.trim().length === 0)) {
          setIsError(true);
          return;
        }
      }
    }
    setIsError(false);
  }

  const isErrorsPresent = () => {
    if (errors && Object.keys.length === 0) {
      setIsError(false);
      return false;
    } else {
      for (const vals in errors) {
        if (errors[vals as keyof object]) {
          setIsError(true);
          return true;
        }
      }
      setIsError(false)
      return false;
    }
  }

  return (
    <div className={styles.sidepaneMain}>
      <div className={styles.topContainer}>
        <div className={styles.closeBtnContainer}>
          <div style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}>
            <CustomHeading headerType='h5'>
              <div className={styles.textColor}>{t("add_integration")}</div>
            </CustomHeading>
            <CustomText textType='t5'>
              <div className={styles.textColor}>{t("add_integration_subheading")}</div>
            </CustomText>
          </div>
          <div className={styles.mobileCloseBtn}>
            <button className={styles.mobilePaneclosebtn} onClick={() => { props.setIsPanelOpen(false) }}>
              <img style={{width:'1.2rem'}} src={svgPaneDelete} alt="" />
            </button>
          </div>
        </div>
        <div className={styles.line}></div>
      </div>
      <div className={styles.inputContainer}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <CustomHeading headerType='h5'>
            <div className={styles.textColor}>{t("integration_name")}&nbsp;<span style={{ color: 'red' }}>*</span></div>
          </CustomHeading>
          <CustomInput
            inputStyleType={'primary'}
            inputType={'text'}
            placeHolder={t("name_integration")}
            value={displayName}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/\s/g, '');
              setDisplayName(e.target.value);
              const regx = new RegExp(/^[a-z0-9]+$/i);
              if (!regx.test(e.target.value)) {
                setIsError(true)
                setErrors((prev) => { return { ...prev, integrationName: `Input should be Alphanumeric` } });
              } else {
                const validation = CharacterValidator(e.target.value);
                if (validation.length > 0) {
                  setIsError(true)
                  setErrors((prev) => { return { ...prev, integrationName: validation } });
                } else {
                  setIsError(false)
                  setErrors((prev) => { return { ...prev, integrationName: undefined } });
                }
              }
              if (isDirtyFlags.integrationName === true) {
                if (e.target.value.trim().length < 1) {
                  setErrors((prev) => { return { ...prev, integrationName: `${t("this_required")}` } });
                } else {
                  setErrors((prev) => { return { ...prev, integrationName: undefined } });
                }
              }
            }}
            onBlur={(e) => {
              setIsDirtyFlags((prev) => { return { ...prev, integrationName: true } });
              if (e.target.value.trim().length < 1) {
                setErrors((prev) => { return { ...prev, integrationName: `${t("this_required")}` } });
              } else {
                const regx = new RegExp(/^[a-z0-9]+$/i);
                if (!regx.test(e.target.value)) {
                  setErrors((prev) => { return { ...prev, integrationName: "Input should be Alphanumeric" } });
                } else {
                  setErrors((prev) => { return { ...prev, integrationName: undefined } });
                }
              }
            }}
            disabled={props.integrationsType === 'CREATE' ? false : true}
          />
          {
            errors && errors.integrationName &&
            <div className={styles.errorText}>{errors.integrationName}</div>
          }
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <CustomHeading headerType='h5'>
            <div className={styles.textColor}>{t("integration_type")}&nbsp;<span style={{ color: 'red' }}>*</span></div>
          </CustomHeading>
          <CustomSelect
            data={integrationTypes}
            selectStyleType={'colored'}
            selectedData={(item) => {
              const singleVal = item as SingleValue<DropdownDataItem>;
              if (singleVal) {
                setSelectedIntegrationType({
                  value: singleVal.value as IntegrationType,
                  label: singleVal.label
                });
              }
            }}
            placeholder={t("select_integration_type")}
            value={selectedIntegrationType}
            disabled={props.integrationsType === 'EDIT'}
          />
        </div>
        <>
          {
            (() => {
              switch (selectedIntegrationType.value) {
                case IntegrationType.MONGODB:
                case IntegrationType.MONGODB_ATLAS:
                  return (
                    <MongoDbIntegration
                      connectionProps={connectionProps}
                      errors={errors}
                      isDirtyFlags={isDirtyFlags}
                      setConnectionProps={setConnectionProps}
                      setErrors={setErrors}
                      setIsDirtyFlags={setIsDirtyFlags}
                      setIsError={setIsError}
                    />
                  )
                case IntegrationType.GOOGLE_SHEETS:
                  return (
                    <GoogleSheetsIntegrations
                      connectionProps={connectionProps}
                      setConnectionProps={setConnectionProps}
                      errors={errors}
                      isDirtyFlags={isDirtyFlags}
                      setErrors={setErrors}
                      setIsDirtyFlags={setIsDirtyFlags}
                      integrationsType={props.integrationsType}
                      setIsError={setIsError}
                    />
                  )
              }
            })()
          }
        </>
        <div className={styles.saveBtn}>
          <CustomButton
            buttonType='button'
            classType='quaternary'
            onClick={() => { createIntegration(); isErrorsPresent(); }}
            disabled={isError}
          >
             <TextComponent textType='Content'>
             <div style={{color:'#fff'}}>
                {
                  props.integrationsType === 'CREATE'
                    ? `${t("add_integration")}`
                    : `${t("edit_integration")}`
                }
              </div>
              </TextComponent>
          </CustomButton>
        </div>
        <div className={styles.mobileCloseBtn}>
          <CustomButton
            buttonType='button'
            classType='secondary'
            onClick={() => { createIntegration(); isErrorsPresent() }}
            disabled={isError}
          >
            <CustomHeading headerType='h4' style={{ color: "#000" }}>
              <div>
                {
                  props.integrationsType === 'CREATE'
                    ? `${t("add_integration")}`
                    : `${t("edit_integration")}`
                }
              </div>
            </CustomHeading>
          </CustomButton>
        </div>
      </div>
    </div>
  )
}


export interface MongoDBProperties {
  host: string;
  dbName: string;
  collectionName: string;
  port?: string;
  userName: string;
  password?: string;
  authDb?: string;
}

export interface GoogleSheetProperties {
  sheetName: string;
  sheetUrl: string;
  token: string;
}
