export interface CreateConditionalEdge {
  sourceControlId: string;
  targetControlId: string;
  value: ConditionalEdgeResponse;
}

export interface ConditionalEdgeResponse {
  value: string[];
  type: ConditionComparatorEnum;
}

export enum ConditionComparatorEnum {
  EQUAL_TO = "EQUAL_TO",
  GREATER_THAN = "GREATER_THAN",
  LESSER_THAN = "LESSER_THAN",
  GREATER_THAN_OR_EQUAL_TO = "GREATER_THAN_OR_EQUAL_TO",
  LESSER_THAN_OR_EQUAL_TO = "LESSER_THAN_OR_EQUAL_TO",
}

export class ConditionalEdgeResponse {
  value: string[];
  type: ConditionComparatorEnum;

  constructor(
    value: string[],
    type: ConditionComparatorEnum
  ) {
    this.value = value;
    this.type = type;
  }
}

export class ConditionalEdge {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  source: string;
  target: string;
  value: ConditionalEdgeResponse;
  createdBy: string;

  constructor(
    id: string,
    createdAt: Date,
    updatedAt: Date,
    deletedAt: Date,
    source: string,
    target: string,
    value: ConditionalEdgeResponse,
    createdBy: string,
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deletedAt = deletedAt;
    this.source = source;
    this.target = target;
    this.createdBy = createdBy;
    this.value = new ConditionalEdgeResponse(value.value, value.type);
  }
}

export interface ConditionalEdge {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  source: string;
  target: string;
  value: ConditionalEdgeResponse;
  createdBy: string;
}

export interface GetConditionalEdges {
  conditionalEdges: ConditionalEdge[];
}

export interface StoreConditionalEdge {
  [formId: string]: ConditionalEdge[];
}
