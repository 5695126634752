import axios from "axios";
import { GetUserMetadataDto, ProfileInfoRespDto, SetUserMetadataDto } from "../store/models/profile.interface";
import { GenerateApiUrl } from "./generate-api-url";

export async function getUserPreference(): Promise<GetUserMetadataDto> {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `users/profile/preference`, false);
  const response = await axios.get<GetUserMetadataDto>(apiUrl);
  return response.data;
}

export async function setUserMetadataByKey(key: string, data: SetUserMetadataDto): Promise<{ status: number, message: string }> {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `users/profile/preference/${key}`, false);
  const response = await axios.post(apiUrl, data);
  return response.data;
}

export async function getAllProfileInfoForAppInit(): Promise<ProfileInfoRespDto> {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `users/profile/init`, false);
  const response = await axios.get<ProfileInfoRespDto>(apiUrl);
  return response.data;
}

export async function deleteUserProfile(){
  try {
    const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `users/profile`, false);
    await axios.delete(apiUrl);
  } catch (error){
    throw error;
  }
}
