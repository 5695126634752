import { Dispatch, SetStateAction, useEffect, useId } from 'react'
import bulmaCalendar from 'bulma-calendar';
import styles from './custom-date-time.module.scss';

export interface CustomDateTimeProps {
  onDateTimeSelect: (date?: Date) => void;
  value?: string;
  isAccordianOpen?: boolean;
  setUpdateDate?: Dispatch<SetStateAction<boolean>>;
}

export default function CustomDateTime(props: CustomDateTimeProps) {
  const dateTimeId = useId();
  const containerId = useId();

  useEffect(() => {
    const element = document.getElementById(dateTimeId);
    const bul: bulmaCalendar = (element as any).bulmaCalendar;
    if (!bul) return;
    if (props.value) {
      bul.startDate = new Date(props.value);
      bul.startTime = new Date(props.value);
      bul.save();
    } else {
      bul.clear(); bul.save();
    }
  }, [props.value]);


  useEffect(() => {
    const element = document.getElementById(dateTimeId);
    const bul: bulmaCalendar = (element as any).bulmaCalendar;
    if (bul && !props.isAccordianOpen) bul.hide();
  }, [props.isAccordianOpen])

  useEffect(() => {
    // attach bulma element
    bulmaCalendar.attach(`[id="${dateTimeId}"]`, {
      type: "datetime", showHeader: false, validateLabel: 'Submit',
      startDate: props.value ? new Date(props.value) : undefined,
      startTime: props.value ? new Date(props.value) : undefined,
    });

    // get element instance and emit values when selected
    const element = document.getElementById(dateTimeId);
    const bul: bulmaCalendar = (element as any).bulmaCalendar;
    bul.on('hide', (value) => {
      props.setUpdateDate && value.data.date.start && props.setUpdateDate(true);
      props.onDateTimeSelect(value.data.date.start);
    });

    // custom implementation to hide element during clear
    const container = document.getElementById(containerId);
    const onClickClear = () => { bul.clear(); bul.hide(); bul.save(); props.setUpdateDate && props.setUpdateDate(true); }
    if (container) {
      const clearBtnFtr = container.querySelector('.datetimepicker-footer-clear');
      if (clearBtnFtr) clearBtnFtr.addEventListener('click', onClickClear);
      const clearBtn = container.querySelector('.datetimepicker-clear-button');
      if (clearBtn) clearBtn.classList.add(styles.displayNone);
    }

    return () => {
      const container = document.getElementById(containerId);
      if (container) {
        const clearBtnFtr = container.querySelector('.datetimepicker-footer-clear');
        if (clearBtnFtr) clearBtnFtr.removeEventListener('click', onClickClear);
      }
    }
  }, []);

  return (
    <div id={containerId}>
      <input type={"datetime-local"} id={dateTimeId}
      // data-start-date={props.value}
      />
    </div>
  )
}
