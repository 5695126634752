import styles from './raffle-card.module.scss'
import CustomText from '../../../../components/v2/common/custom-bodyText/custom-text';
import CustomHeading from '../../../../components/v2/common/custom-heading/custom-heading';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomPagination } from '../../../../components/v2/common/custom-pagination/custom-pagination';
import { blackListRaffleIds, getRaffleFormControl } from '../../../../api/form-controls';
import { BlackListAction } from '../../../../store/models/form-controls/blacklist-raffle';
import { Form, FormState } from '../../../../store/models/form.interface';
import toast from 'react-hot-toast';
import { useContext, useEffect, useState } from 'react';
import svgTrophyAward from "../../../../assets/v2/icons/trophy-award.svg";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../store/store';
import { fetchResponseCount, fetchResponseList } from '../../../../api/form-response';
import { GetFormResponseItemDto } from '../../../../store/models/form-response.interface';
import { FormControl } from '../../../../store/models/form-controls/form-control.interface';
import { getEligibleRaffleCount } from '../../../../api/forms';
import { getForm } from '../../../../store/slices/forms.slice';
import hasSufficientScopes from '../../../../util/access-validator/access-validator';
import RaffleMode from '../raffle-mode/raffle-mode';
import { ACTIVE_PAGE_STATUS } from '../../../../store/models/active-page';
import { SocketContext } from '../../../../context/socket';
import { setNewData } from '../../../../store/slices/active-page.slice';
import { activePageThunk } from '../../../../store/thunks/active-page.thunk';
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import svgNoForms from "../../../../assets/v2/icons/no-forms.svg";

export default function RafflePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [winnerRaffleIds, setWinnerRaffleIds] = useState<Set<string>>();
  const { formId } = useParams();
  const [raffleSkip, setRaffleSkip] = useState<number>(0);
  const [formResponses, setFormResponses] = useState<GetFormResponseItemDto[]>([]);
  const [raffleControl, setRaffleControl] = useState<FormControl>();
  const [eligibleRaffleCount, setEligibleRaffleCount] = useState<number>(0);
  const [isDisabledCustom, setDisabledCustom] = useState<boolean>(false);
  const [responseCount, setResponseCount] = useState(0);
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const form: Form | undefined = useSelector((state: RootState) => getForm(state, formId as string));
  const socket = useContext(SocketContext);
  const activeWebPage = useSelector((state: RootState) => state.activePage.activePage);
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const refreshWebPage = useSelector((state: RootState) => state.activePage.refreshPage);
  const dispatch: RootDispatch = useDispatch();
  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activeWebPage])

  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    if (formId) {
      dispatch(activePageThunk({ socket: socket, payload: { pageName: `form-details-${formId}`, status } }));
    }
  }

  useEffect(() => {
    if (newData) {
      getLatestData(newData);
      dispatch((setNewData({ action: "" })));
    }
  }, [newData])

  useEffect(() => {
    if (raffleControl?.config.winners && raffleControl?.config.winners.length > 0) {
      const raffleIds: string[] = raffleControl?.config.winners.map((item: { id: string, metadata: Record<string, any>, position: number }, index: number) => {
        return item.metadata.raffleId;
      });
      setWinnerRaffleIds(new Set(raffleIds));
    }
  }, [raffleControl?.config.winners]);

  useEffect(() => {
    getLatestData();
    getRaffleControls();
  }, [form, refreshWebPage])

  const blackListRaffleId = async (responseId: string, raffleId?: string, action?: BlackListAction) => {
    if (raffleId && raffleControl?.id && action && form) {
      try {
        await blackListRaffleIds(raffleControl?.id, { action: action, raffleId: raffleId, formId: form.id, responseId: responseId });
        setFormResponses(formResponses.map(item => {
          if (item.id === responseId) {
            item.isEligibleForRaffle = action === BlackListAction.REMOVE ? true : false;
          }
          return item;
        }));
        setEligibleRaffleCount((prev) => {
          if (action === BlackListAction.REMOVE) {
            return prev + 1;
          } else return prev - 1;
        });
      } catch (err) {
        toast.error(t('blacklist_fail'));
      }
    }
  }

  const fetchFormResponseCount = async (formId: string) => {
    let count: number = 0;
    try {
      const response = await fetchResponseCount(formId);
      count = response.data.count;
    } catch (err) {
      toast.error(t('form_details_toast_form_response_err'));
    }
    setResponseCount(count);
  }

  const getLatestData = async (newData?: string) => {
    try {
      if (formId) {
        fetchFormResponseCount(formId);
        const checkScope = hasSufficientScopes(["response:read", "response:read.all"], form?.createdBy);
        setRefreshPage(true);
        const raffleControls = await getRaffleFormControl(formId);
        if (raffleControls) {
          if (form && form.state === FormState.PUBLISHED && checkScope) {
            await getFormResponses();
          }
          if (raffleControls.config.isActive === false) {
            const res = await getEligibleRaffleCount(formId);
            setEligibleRaffleCount(res.data.eligibleRaffleCount);
          }
        }
      }
      setRefreshPage(false);
    } catch (err) { }
  }

  const getRaffleControls = async () => {
    if (formId) {
      try {
        const raffleControl = await getRaffleFormControl(formId);
        if (raffleControl.config.isActive === false) {
          const res = await getEligibleRaffleCount(formId);
          setEligibleRaffleCount(res.data.eligibleRaffleCount);
          setDisabledCustom(true);
        }
        setRaffleControl(raffleControl);
      } catch (err) { }
    }
  }

  async function setRafflePagination(num: number) {
    if (formId) {
      setRaffleSkip(num);
      const responses = await fetchResponseList({ formId: formId, take: 10, skip: num });
      setFormResponses(responses.data.data.FormResponses);
    }
  }

  const getFormResponses = async () => {
    try {
      if (formId) {
        const responses = await fetchResponseList({ formId: formId, take: 10, skip: raffleSkip || 0 });
        setFormResponses(responses.data.data.FormResponses);
      }
    } catch (err) { }
  }

  const renderRaffleId = (item: GetFormResponseItemDto) => {
    return (<div className={`${'tableData'}`}>
      {
        item.raffleId && winnerRaffleIds?.has(item.raffleId)
          ? <div className={styles.winner}>{t('winner')}</div>
          : <div className={`${item.isEligibleForRaffle ? styles.raffleEligibile : styles.raffleInEligible}`}>
            {item.isEligibleForRaffle ? `${t('eligible')}` : `${t('ineligible')}`}
          </div>
      }
    </div>);
  };

  const renderEligibility = (item: GetFormResponseItemDto) => {
    return (<div className={styles.eligibilityBtn} onClick={(e) => {
      e.stopPropagation();
      blackListRaffleId(item.id, item.raffleId, item.isEligibleForRaffle ? BlackListAction.ADD : BlackListAction.REMOVE);
    }}>
      {item.isEligibleForRaffle ? `${t('ineligible')}` : `${t('eligible')}`}
    </div>);
  };

  return (
    <div className={styles.raffleMain}>
      {
        form && form.state === FormState.PUBLISHED && responseCount > 0 &&
        <div className={styles.raffleContainers}>
          <TextComponent textType='Header'>
            <div style={{ padding: '0.75rem 0' }}>{t('raffle_resps')}</div>
          </TextComponent>
          {
            responseCount > 0 &&
            <div className={styles.main}>
              <div className={styles.userTableMainDiv}>
                <div className={'tableWrapper tableNoShadow'}>
                  <div className={'tableHeaderRow'}>
                    <div className={'tableData'}>{t('raffle_id')}</div>
                    {raffleControl?.config.userInfo.includes('email') && <div className={'tableData'}>{t('email')}</div>}
                    {raffleControl?.config.userInfo.includes('phone') && <div className={'tableData'}>{t('phone_num')}</div>}
                    <div className={'tableData'}>{t('status')}</div>
                    <div className={'tableData'}>{t('action')}</div>
                  </div>
                  {
                    formResponses.map((item, index) => {
                      const mobileCtrl = raffleControl?.children.find(val => val.controlType === 'mobile number');
                      const emailCtrl = raffleControl?.children.find(val => val.controlType === 'email');
                      const emailRes = item.responseData.find(item => item.control_id === emailCtrl?.id)?.response;
                      const mobileRes: { countryCode: string, mobileNumber: string } = item.responseData.find(item => item.control_id === mobileCtrl?.id)?.response;

                      return (
                        <div key={index} className={'tableRow'} onClick={() => { if (formId) navigate(`../responses/${item.id}`); }}>
                          <div className={'tableData'}>{item.raffleId ? item.raffleId : '-'}</div>
                          {emailRes && raffleControl?.config.userInfo.includes('email') && <div className={'tableData'}>{emailRes}</div>}
                          {mobileRes && raffleControl?.config.userInfo.includes('phone') && <div className={'tableData'}>{mobileRes.countryCode}-{mobileRes.mobileNumber}</div>}
                          {item.raffleId && renderRaffleId(item)}
                          <div className={'tableData'}>{item.raffleId && winnerRaffleIds?.has(item.raffleId) ? '-' : renderEligibility(item)}</div>
                        </div>
                      )
                    })
                  }
                </div>
                <div className={styles.cardHolder}>
                  {
                    formResponses.map((item, index) => {
                      const mobileCtrl = raffleControl?.children.find(val => val.controlType === 'mobile number');
                      const emailCtrl = raffleControl?.children.find(val => val.controlType === 'email');
                      const emailRes = item.responseData.find(item => item.control_id === emailCtrl?.id)?.response;
                      const mobileRes: { countryCode: string, mobileNumber: string } = item.responseData.find(item => item.control_id === mobileCtrl?.id)?.response;
                      return (
                        <div className={styles.mobileCard} key={index} >
                          <div className={styles.rowOne}>
                            <CustomHeading headerType='h4'>
                              {t('raffle_id')}: {item.raffleId ? item.raffleId : '-'}
                            </CustomHeading>
                          </div>
                          <div className={styles.rowFour}>
                            <div className={styles.diamond}></div>
                            <div className={styles.line}></div>
                            <div className={styles.diamond}></div>
                          </div>
                          {
                            emailRes && raffleControl?.config.userInfo.includes('email') &&
                            <div className={styles.rowOne}>
                              <div className={styles.raffleCardData}>
                                <div>{t('account_details_email')}:</div>
                                <div style={{ fontWeight: '500' }}>{emailRes}</div>
                              </div>
                            </div>
                          }
                          {
                            mobileRes && raffleControl?.config.userInfo.includes('phone') &&
                            <div className={styles.rowOne}>
                              <div className={styles.raffleCardData}>
                                <div>{t('phone')}:</div>
                                <div style={{ fontWeight: '500' }}>{mobileRes.countryCode}-{mobileRes.mobileNumber}</div>
                              </div>
                            </div>
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div style={{ margin: "0px 0px 0px auto" }}>
                {responseCount > 10 ? <CustomPagination countPerPage={10} totalCount={responseCount} handlePagination={(res) => setRafflePagination(res.skip)} /> : ''}
              </div>
            </div>
          }
        </div>
      }
      <div className={styles.raffleContainers}>
        <div className={styles.subHeaderContainer}>
          <TextComponent textType='Header'>
            <div>{t('raffle_winner')}</div>
          </TextComponent>
          <div className={styles.raffleEndContainer}>
            <RaffleMode
              eligibleRaffleCount={eligibleRaffleCount}
              responseCount={responseCount}
              setEligibleRaffleCount={setEligibleRaffleCount}
              setRaffle={setRaffleControl}
              form={form}
              raffle={raffleControl}
            />
          </div>
        </div>
        {
          raffleControl && raffleControl.config.winners != null && raffleControl.config.winners.length > 0 ?
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", justifyContent: 'center' }}>
              {
                raffleControl.config.winners.map((item: { id: string, metadata: Record<string, any>, position: number }, index: number) => {
                  return (
                    <div className={styles.raffleCard} key={index} >
                      <div className={styles.titleRow}>
                        <div>
                          <img style={{ pointerEvents: 'none' }} src={svgTrophyAward} alt="" />
                        </div>
                        <CustomHeading headerType='h4'>
                          {t('winner_no')} {index + 1}
                        </CustomHeading>
                      </div>
                      <div className={styles.rowTwo}>
                        <div style={{ fontSize: "14px", fontWeight: "500" }}>{item.metadata.raffleId}</div>
                      </div>
                      <div className={styles.rowTwo}>
                        <div style={{ color: "#15D503", fontSize: "12px", fontWeight: "500" }}>{t('notified')}</div>
                      </div>
                      <div className={styles.rowFour}>
                        <div className={styles.diamond}></div>
                        <div className={styles.line}></div>
                        <div className={styles.diamond}></div>
                      </div>
                      {
                        item.metadata.email &&
                        <div className={styles.rowOne}>
                          {t('account_details_email')}:&nbsp;
                          {
                            <CustomText textType='t5' style={{ fontWeight: "500", textOverflow: 'ellipsis', overflow: 'hidden' }}>
                              {item.metadata.email}
                            </CustomText>
                          }
                        </div>
                      }
                      {
                        item.metadata.phone &&
                        <div className={styles.rowOne}>
                          {t('phone')}:&nbsp;
                          {
                            <CustomText textType='t5' style={{ fontWeight: "500", textOverflow: 'ellipsis', overflow: 'hidden' }}>
                              {item.metadata.phone.countryCode}-{item.metadata.phone.mobileNumber}
                            </CustomText>
                          }
                        </div>
                      }
                    </div>
                  )
                })
              }
            </div> : <div className={styles.noFormsContainer} style={{ pointerEvents: 'none', userSelect: 'none', flexGrow: '1' }}>
              <div>
                <img src={svgNoForms} alt="no-forms" />
              </div>
              <TextComponent textType='Content'>
                <div>{t("raffle_no_winners")}</div>
              </TextComponent>
            </div>
        }
      </div>
    </div>
  )
}
