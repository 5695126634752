import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/common/loader/loader';
import CustomButton from '../../../components/v2/common/custom-button/custom-button';
import styles from './login-new.module.scss';
import PageLayout from './page-layout/page-layout';
import { Captcha } from '../../../components/v2/common/captcha/captcha';
import { useEffect } from 'react';
import svgRightArrow from "../../../assets/v2/icons/right-arrow.svg";
import TextComponent from '../../../components/v2/common/text-component/text-component';
import { getAllProfileInfoForAppInit } from '../../../api/profile';
import CustomLoader from '../../../components/v2/common/custom-loader/custom-loader';

export default function LoginNew() {
  const navigate = useNavigate();
  const { keycloak, initialized } = useKeycloak();
  const { t } = useTranslation();

  async function onClicked() {
    if (!initialized) {
      return <CustomLoader/>
    }

    keycloak.login().then(async () => {
      const res = await getAllProfileInfoForAppInit();
      if (res && res.metadata && res.metadata.active_org) {
        navigate(`o/${res.metadata.active_org}/dashboard`);
      } else {
        navigate(`/profile`);
      }
    })

    return <></>
    //  return <>{keycloak.login({ redirectUri: window.location.origin + '/dashboard' })}</>;
  }

  useEffect(() => {
    navigate('/')
  }, [])

  return (
    <>
      <Captcha />
      <div className={styles.mainContainer}>
        <PageLayout />
        <div className={styles.rightContainer}>
          <TextComponent textType='Header'>
            <div>{t("login_welcome")}<span className={styles.formsTitle}> {t("smart_forms")}</span></div>
          </TextComponent>
          <TextComponent textType='Content'>
            <div>{t("sign_in_main")}</div>
          </TextComponent>
          <CustomButton
            buttonType='submit'
            classType='quaternary'
            onClick={onClicked}>
            <TextComponent textType='Content'>
              <div className={styles.signBtn}>
                <div style={{
                  color: '#fff'
                }}>{`${t("sign_in_smart")}`}</div>
                <img src={svgRightArrow} alt="" />
              </div>
            </TextComponent>
          </CustomButton>
          <div>
            <TextComponent textType='Content'>
              <div>{t("sign_new_user")}</div>
            </TextComponent>
          </div>
          <div className={styles.directLoginContainer}>
            <CustomButton
              buttonType='button'
              classType='secondary'
              onClick={() => {
                navigate('/user-type')
              }}>
              <div className={styles.signBtn}>
                <TextComponent textType='Content'>
                  <div>{`${t("sign_up")}`}</div>
                </TextComponent>
              </div>
            </CustomButton>
          </div>
          <div className={styles.loginTextContainer}>
            <span className={styles.signInTextFour}>
              <button className={styles.signInTextFive} onClick={() => {
                navigate("/forgot-password")
              }}>
                <TextComponent textType='Content'>
                  <u style={{ color: 'var(--secondary-color)' }}>{t("sign_in_forgot_password")}</u>
                </TextComponent>
              </button>
            </span>
            <span className={styles.signInTextFour}>
              <button className={styles.signInTextFive} onClick={() => {
                navigate("/validate-otp")
              }}>
                <TextComponent textType='Content'>
                  <u style={{ color: 'var(--secondary-color)' }}>{t("validate_otp")}</u>
                </TextComponent>
              </button>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
