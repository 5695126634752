import { Dispatch, SetStateAction } from 'react'
import styles from './user-invitations.module.scss';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import { GetInvitationsRespItem } from '../../../../store/models/user.interface';
import svgCross from "../../../../assets/v2/close.svg";
import svgTick from "../../../../assets/v2/icons/tick.svg";
import ToolTip from '../../../../components/v2/common/tool-tip/tool-tip';

export default function UserInvitationRow(props: UserInvitationRowProps) {
  return (
    <>
      <div key={props.index} className={'tableRow'}>
        <div className={'tableData'}>{props.inviteItem.orgName}</div>
        <div className={'tableData'}>{props.inviteItem.createdByUserEmail || props.email || props.inviteItem.createdByUserId}</div>
        <div className={'tableData'}>{new Date(props.inviteItem.createdAt).toLocaleString()}</div>
        <div className={'tableData'}>{props.inviteItem.status}</div>
        <div className={`${'tableData'} ${styles.actionContainer}`}>
            <ToolTip content='Accept' position='bottom'>
            <CustomButton
              disabled={props.inviteItem.status === 'PENDING' ? false : true}
              style={{
                background: "var(--accent-color)",
                padding: " 12px",
                borderRadius: '50%',
                border: 'none',
                boxSizing: "border-box"
              }}
              buttonType='button'
              classType='tertiary'
              onClick={() => {
                props.acceptInvite(props.inviteItem);
              }}
            >
              <div className={styles.isImageHolder}>
                <img style={{ width: "1.2rem" }} src={svgTick} alt="filter" />
              </div>
            </CustomButton>
            </ToolTip>
          <ToolTip content='Decline' position='bottom'>
            <CustomButton
              disabled={props.inviteItem.status === 'PENDING' ? false : true}
              style={{
                background: "var(--accent-color)",
                padding: " 12px",
                borderRadius: '50%',
                border: 'none',
                boxSizing: "border-box"
              }}
              buttonType='button'
              classType='tertiary'
              onClick={() => {
                props.setInvitationDetails(props.inviteItem)
                props.setShowDeleteModal(true);
              }}
            >
              <div className={styles.isImageHolder}>
                <img style={{ width: "1.2rem" }} src={svgCross} alt="filter" />
              </div>
            </CustomButton>
          </ToolTip>
        </div>
      </div>
    </>
  )
}


interface UserInvitationRowProps{
  inviteItem: GetInvitationsRespItem;
  index: number;
  email: string;
  setShowDeleteModal: (value: SetStateAction<boolean>) => void;
  acceptInvite: (item: GetInvitationsRespItem) => Promise<void>;
  setInvitationDetails: Dispatch<SetStateAction<GetInvitationsRespItem | undefined>>
}
