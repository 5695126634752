import styles from './section-control.module.scss';
import { DragEvent, useRef, useState } from 'react';
import { ControlItem } from '../control-item/control-item';
import { SectionControlProps } from './section-control.props';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { toast } from 'react-hot-toast';
import svgDrag from "../../../../assets/drag.svg";
import svgDeleteBuilder from "../../../../assets/v2/delete.svg";
import { useTranslation } from 'react-i18next';
import TextComponent from '../../common/text-component/text-component';
import ToolTip from '../../common/tool-tip/tool-tip';

export function SectionControl(props: SectionControlProps) {
  const { t } = useTranslation();
  const referenceDiv = useRef<HTMLDivElement>();
  const [blurPlaceholder, setBlurPlaceholder] = useState(false);
  const nextSibling = useRef<ChildNode>();

  const enableDrag = (e: React.MouseEvent<Element, MouseEvent>) => props.setIsDraggable(true);
  const disableDrag = (e: React.MouseEvent<Element, MouseEvent>) => props.setIsDraggable(false);

  const controlErrorMessage = useSelector((state: RootState) => { return state.validation.controlValidation });

  const onSectionDragStart = (e: DragEvent<HTMLDivElement>) => {
    // set mouse tracker
    props.mouseHeight.current = e.clientY;

    // set all required elements
    const pickedElement = e.target as HTMLDivElement;
    const controlOrder = pickedElement.getAttribute('ctrl-order');
    const cloneElement = pickedElement.cloneNode(true) as HTMLDivElement;
    pickedElement.classList.add(styles.draggedCtrl);
    nextSibling.current = pickedElement.nextSibling ? pickedElement.nextSibling : undefined;
    // pickedElement.classList.add(styles.draggedSection);
    props.pickedCtrlRef.current = pickedElement;
    props.pickedCtrlIndexRef.current = controlOrder ? +controlOrder : undefined;
    props.pickedCtrlParentRef.current = props.parent;
    props.pickedCtrlParentIndexRef.current = props.parentIndex;
    props.pickedCtrlParentDomRef.current = pickedElement.parentElement as HTMLDivElement;
    props.pickedCtrlGrandParentRef.current = props.grandParent;
    props.pickedCtrlGrandParentIndexRef.current = props.grandParentIndex;
    props.hoverCtrlRef.current = pickedElement;
    props.hoverCtrlIndexRef.current = controlOrder ? +controlOrder : undefined;
    props.pickedCtrlId.current = props.formControl.id;
    props.hoverCtrlId.current = props.formControl.id;

    // set dragged control styling
    cloneElement.classList.add(styles.draggedSection);
    const parent = document.createElement('div');
    props.dragPlaceHolder.current = parent;
    parent.appendChild(cloneElement);
    parent.style.width = `${pickedElement.getBoundingClientRect().width}px`;
    parent.style.height = `${pickedElement.getBoundingClientRect().height}px`;
    parent.classList.add(styles.draggedSectionParent);
    document.body.appendChild(parent);

    // set dragged control styling
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.dropEffect = "move";
    e.dataTransfer.setDragImage(parent, 30, 60);
    e.dataTransfer.clearData();
    e.dataTransfer.setData('re-order', 're-order');
  }

  const onSectionDragEnd = async (e: DragEvent<HTMLDivElement>) => {
    if (props.pickedCtrlRef.current !== props.hoverCtrlRef.current) {
      if (props.pickedCtrlRef.current) {
        const val = Array.from(props.pickedCtrlRef.current.parentNode?.children as HTMLCollection).indexOf(props.pickedCtrlRef.current);
        props.hoverCtrlIndexRef.current = val;
      }

      if (props.pickedCtrlParentRef.current?.id !== props.hoverCtrlParentRef.current?.id) {
        const element = props.pickedCtrlRef.current;
        const parent = props.pickedCtrlParentDomRef.current;
        if (element && parent) {
          parent.appendChild(element);
        }
      }

      if (props.formControl.id === props.pickedCtrlParentRef.current?.id || props.formControl.id === props.hoverCtrlParentRef.current?.id) {
        props.dragPlaceHolder.current?.remove();
        referenceDiv.current?.remove();
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (props.formControl.controlType === props.hoverCtrlParentRef.current?.controlType) {
        toast.error('You cannot place a section into another section');
        props.dragPlaceHolder.current?.remove();
        props.pickedCtrlRef.current?.classList.remove(styles.draggedCtrl);
        referenceDiv.current?.remove();
        e.preventDefault();
        e.stopPropagation();
        const parent = props.pickedCtrlRef.current?.parentElement;
        if (parent && props.pickedCtrlRef.current) parent.insertBefore(props.pickedCtrlRef.current, nextSibling.current ? nextSibling.current : null);
        return;
      }
      await props.reorderControls({ controlId: props.formControl.id, formId: props.formControl.formId, newParentId: props.hoverCtrlParentRef.current?.id ? props.hoverCtrlParentRef.current?.id : '', newPosition: props.hoverCtrlIndexRef.current ? props.hoverCtrlIndexRef.current : 0, oldParentId: props.pickedCtrlParentRef.current?.id ? props.pickedCtrlParentRef.current.id : '', oldPosition: props.pickedCtrlIndexRef.current ? props.pickedCtrlIndexRef.current : 0 });
    }

    if (props.pickedCtrlRef.current && props.hoverCtrlRef.current) {
      props.pickedCtrlRef.current.classList.remove(styles.draggedCtrl);
      props.pickedCtrlRef.current = undefined;
      props.pickedCtrlIndexRef.current = undefined;
      props.pickedCtrlParentRef.current = undefined;
      props.pickedCtrlParentIndexRef.current = undefined;
      props.pickedCtrlParentDomRef.current = undefined;
      props.pickedCtrlGrandParentRef.current = undefined;
      props.pickedCtrlGrandParentIndexRef.current = undefined;
      props.hoverCtrlRef.current.classList.remove(styles.dragOverCtrl);
      props.hoverCtrlRef.current = undefined;
      props.hoverCtrlIndexRef.current = undefined;
      props.hoverCtrlParentRef.current = undefined;
      props.hoverCtrlParentIndexRef.current = undefined;
      props.hoverCtrlGrandParentRef.current = undefined;
      props.hoverCtrlGrandParentIndexRef.current = undefined;
      props.pickedCtrlId.current = '';
      props.hoverCtrlId.current = '';
      if (props.dragPlaceHolder.current) {
        props.dragPlaceHolder.current.remove();
        props.dragPlaceHolder.current = undefined;
      }
    }
    props.setIsDraggable(false);
    e.stopPropagation();
    e.preventDefault();
  }

  const onSectionDragLeave = (e: DragEvent<HTMLDivElement>) => {
    setBlurPlaceholder(false);
    props.hoverCtrlRef.current?.classList.remove(styles.dragOverCtrl);
    props.mouseHeight.current = e.clientY;
    e.stopPropagation();
    e.preventDefault();
  }

  const deleteControl = () => {
    props.deleteControl({ formControl: props.formControl, controlIndex: props.controlIndex, formId: props.formControl.formId, order: props.controlIndex, parentId: null, parentType: "page", parentIndex: props.parentIndex, grandParentIndex: props.grandParentIndex });
  }

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    setBlurPlaceholder(false);
    const res = e.dataTransfer.types;
    let controlName: string | null = null;
    if (res && res.length > 0 && res[0] === 'create' && props.dragPlaceHolder.current) {
      const htmlCollection = props.dragPlaceHolder.current.parentNode?.children as HTMLCollection;
      if (htmlCollection) {
        const val = Array.from(htmlCollection).indexOf(props.dragPlaceHolder.current);
        props.hoverCtrlIndexRef.current = val;
      } else {
        props.hoverCtrlIndexRef.current = 0;
      }
      let pickedElement = props.pickedCtrlRef.current;
      if (pickedElement) controlName = pickedElement.getAttribute('ctrl-type');
      if (controlName == null) {
        e.stopPropagation();
        e.preventDefault();
        return;
      }
      if (props.formControl.controlType === controlName || controlName === 'raffle') {
        if (controlName === 'raffle') {
          toast.error(t("builder_raffle_in_sec_not_allowed"));
        } else {
          toast.error('You cannot place a section inside another section');
        }
        props.dragPlaceHolder.current?.remove();
        props.pickedCtrlRef.current?.classList.remove(styles.draggedCtrl);
        referenceDiv.current?.remove();
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      props.createControl({ controlName: controlName, controlIndex: props.hoverCtrlIndexRef.current, parentIndex: props.controlIndex, grandParentIndex: props.parentIndex, parentId: props.formControl.id, parentType: "section" });
      props.hoverCtrlIndexRef.current = undefined;
    } else if (res && res.length > 0 && res[0] === 're-order' && props.dragPlaceHolder.current) {
      if (props.formControl.controlType === controlName || controlName === 'raffle') {
        if (controlName === 'raffle') {
          toast.error('You cannot place a raffle control inside a section');
        } else {
          toast.error('You cannot place a section inside another section');
        }
        props.dragPlaceHolder.current?.remove();
        props.pickedCtrlRef.current?.classList.remove(styles.draggedCtrl);
        referenceDiv.current?.remove();
        e.preventDefault();
        e.stopPropagation();
        return;
      }
    }
    e.stopPropagation();
    e.preventDefault();
  }

  const ondropReorder = (e: DragEvent<HTMLDivElement>) => {
    setBlurPlaceholder(false);
    const res = e.dataTransfer.types;
    if (res && res.length > 0 && res[0] === 're-order') {
      props.hoverCtrlIndexRef.current = 0;
      props.hoverCtrlParentRef.current = props.formControl;
      props.hoverCtrlParentIndexRef.current = props.controlIndex;
      props.hoverCtrlId.current = props.formControl.id;
      if (props.formControl.controlType === props.hoverCtrlParentRef.current.controlType) {
        toast.error(t("sec_in_sec_not_allowed"));
        props.dragPlaceHolder.current?.remove();
        props.pickedCtrlRef.current?.classList.remove(styles.draggedCtrl);
        referenceDiv.current?.remove();
        e.preventDefault();
        e.stopPropagation();
        const parent = props.pickedCtrlRef.current?.parentElement;
        if (parent && props.pickedCtrlRef.current) parent.insertBefore(props.pickedCtrlRef.current, nextSibling.current ? nextSibling.current : null);
        return;
      }
      props.reorderControls({ controlId: props.formControl.id, formId: props.formControl.formId, newParentId: props.hoverCtrlParentRef.current?.id ? props.hoverCtrlParentRef.current?.id : '', newPosition: props.hoverCtrlIndexRef.current ? props.hoverCtrlIndexRef.current : 0, oldParentId: props.pickedCtrlParentRef.current?.id ? props.pickedCtrlParentRef.current.id : '', oldPosition: props.pickedCtrlIndexRef.current ? props.pickedCtrlIndexRef.current : 0 });
      props.hoverCtrlIndexRef.current = undefined;
      props.hoverCtrlParentRef.current = undefined;
      props.hoverCtrlParentIndexRef.current = undefined;
      props.hoverCtrlId.current = '';
      e.preventDefault();
      e.stopPropagation();
    }
  }

  const onDragEnter = (e: DragEvent<HTMLDivElement>) => {
    setBlurPlaceholder(true);
    if (props.formControl.children.length === 0) {
      const res = e.dataTransfer.types;
      const element = e.target as HTMLDivElement;
      if (res && res.length > 0 && res[0] === 're-order' && props.pickedCtrlRef.current) {
        props.hoverCtrlRef.current = e.target as HTMLDivElement;
        props.hoverCtrlIndexRef.current = 0;
        props.hoverCtrlParentRef.current = props.formControl;
        props.hoverCtrlParentIndexRef.current = props.controlIndex;
        props.hoverCtrlId.current = props.formControl.id;
        element.appendChild(props.pickedCtrlRef.current);
      } if (res && res.length > 0 && res[0] === 'create' && props.dragPlaceHolder.current) {
        props.dragPlaceHolder.current.remove();
        const div = document.createElement('div');
        referenceDiv.current = div;
        div.classList.add(styles.addPlaceholderDiv);
        element.appendChild(div);
      }
    }
    e.preventDefault();
    e.stopPropagation();
  }

  const dragPushTop = (e: DragEvent<HTMLDivElement>) => {
    setBlurPlaceholder(false);
    const vals = e.dataTransfer.types;
    const hoveredElement = e.target as HTMLDivElement;
    if (hoveredElement === props.pickedCtrlRef.current) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    const controlOrder = hoveredElement.getAttribute('ctrl-order') as string;

    props.hoverCtrlRef.current = hoveredElement;
    props.hoverCtrlIndexRef.current = +controlOrder;
    props.hoverCtrlParentRef.current = props.parent;
    props.hoverCtrlParentIndexRef.current = props.parentIndex;
    props.hoverCtrlGrandParentIndexRef.current = props.grandParentIndex;
    props.hoverCtrlId.current = props.formControl.id;

    const parent = hoveredElement.parentElement?.parentElement;
    if (vals && vals[0] === 'create' && parent && props.dragPlaceHolder.current) {
      try { parent.insertBefore(props.dragPlaceHolder.current, hoveredElement.parentElement); } catch (err) { }
    }
    else if (parent && props.pickedCtrlRef.current) {
      try { parent.insertBefore(props.pickedCtrlRef.current, hoveredElement.parentElement); } catch (err) { }
    }

    props.mouseHeight.current = e.clientY;
    e.stopPropagation();
    e.preventDefault();
  }

  const dragPushButtom = (e: DragEvent<HTMLDivElement>) => {
    setBlurPlaceholder(false);
    const vals = e.dataTransfer.types;
    const hoveredElement = e.target as HTMLDivElement;
    if (hoveredElement === props.pickedCtrlRef.current) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    const controlOrder = hoveredElement.getAttribute('ctrl-order') as string;

    props.hoverCtrlRef.current = hoveredElement;
    props.hoverCtrlIndexRef.current = +controlOrder;
    props.hoverCtrlParentRef.current = props.parent;
    props.hoverCtrlParentIndexRef.current = props.parentIndex;
    props.hoverCtrlGrandParentIndexRef.current = props.grandParentIndex;
    props.hoverCtrlId.current = props.formControl.id;

    const parent = hoveredElement.parentElement?.parentElement;
    if (vals && vals[0] === 'create' && parent && props.dragPlaceHolder.current) {
      try { parent.insertBefore(props.dragPlaceHolder.current, hoveredElement.parentElement.nextSibling); } catch (err) { }
    }
    else if (parent && props.pickedCtrlRef.current) {
      try { parent.insertBefore(props.pickedCtrlRef.current, hoveredElement.parentElement.nextSibling); } catch (err) { }
    }

    props.mouseHeight.current = e.clientY;
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <div section-value={props.formControl.id} ctrl-order={props.controlIndex} className={`${styles.draggableItem} ${controlErrorMessage != null && controlErrorMessage[props.formControl.formId] != null && controlErrorMessage[props.formControl.formId][props.formControl.id] != null ? styles.sectionValidationErr : ''}`} draggable={props.isDraggable} onDragStart={e => onSectionDragStart(e)} onDragEnd={e => onSectionDragEnd(e)}
      onDragEnter={e => {
        // onSectionDragEnter(e);
        props.mouseHeight.current = e.clientY;
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragLeave={e => onSectionDragLeave(e)} onDrop={(e) => onDrop(e)}
    >
      <div className={styles.dragInitiator} onDragEnter={(e) => dragPushTop(e)} ></div>
      <div style={{ display: 'flex', gap: '1rem', padding: '0 2.5rem' }}>
        <div className={styles.dragImgContainer}
          onDragEnter={(e) => { e.preventDefault(); e.stopPropagation(); }}
          onDragLeave={(e) => { e.preventDefault(); e.stopPropagation(); }}
          onDragOver={(e) => { e.stopPropagation(); e.preventDefault(); }}
        >
          <div className={styles.dragBtn} onMouseDown={e => enableDrag(e)} onMouseUp={e => disableDrag(e)}>
            <img className={styles.dragImg} src={svgDrag} alt="drag" />
          </div>
        </div>
        <div
          className={styles.controlContainer}
          onDragEnter={(e) => { e.preventDefault(); e.stopPropagation(); }}
          onDragLeave={(e) => { e.preventDefault(); e.stopPropagation(); }}
          onDragOver={(e) => { e.stopPropagation(); e.preventDefault(); }}
          draggable={false}
        >
          {
            props.formControl.children.length > 0 && props.formControl.children.map((value, index) => {
              if (value.children.length === 0) {
                return (
                  <ControlItem
                    key={value.id}
                    formControl={value}
                    controlIndex={index}
                    parent={props.formControl}
                    parentIndex={props.controlIndex}
                    grandParent={props.parent}
                    grandParentIndex={props.parentIndex}
                    isDraggable={props.isDraggable}
                    mouseHeight={props.mouseHeight}
                    dragPlaceHolder={props.dragPlaceHolder}
                    setIsDraggable={props.setIsDraggable}
                    reorderControls={props.reorderControls}
                    pickedCtrlRef={props.pickedCtrlRef}
                    pickedCtrlIndexRef={props.pickedCtrlIndexRef}
                    pickedCtrlParentRef={props.pickedCtrlParentRef}
                    hoverCtrlParentIndexRef={props.hoverCtrlParentIndexRef}
                    pickedCtrlGrandParentRef={props.pickedCtrlGrandParentRef}
                    pickedCtrlGrandParentIndexRef={props.pickedCtrlGrandParentIndexRef}
                    hoverCtrlRef={props.hoverCtrlRef}
                    hoverCtrlIndexRef={props.hoverCtrlIndexRef}
                    hoverCtrlParentRef={props.hoverCtrlParentRef}
                    pickedCtrlParentIndexRef={props.pickedCtrlParentIndexRef}
                    hoverCtrlGrandParentIndexRef={props.hoverCtrlGrandParentIndexRef}
                    deleteControl={props.deleteControl}
                    setSidePaneTabvalue={props.setSidePaneTabvalue}
                    setActiveFormControl={props.setActiveFormControl}
                    setIsActionPanelOpen={props.setIsActionPanelOpen}
                    isActionPanelOpen={props.isActionPanelOpen}
                    hoverCtrlId={props.hoverCtrlId}
                    pickedCtrlId={props.pickedCtrlId}
                    hoverCtrlGrandParentRef={props.hoverCtrlGrandParentRef}
                    pickedCtrlParentDomRef={props.pickedCtrlParentDomRef}
                    setActionBarTitle={props.setActionBarTitle}
                    createControl={props.createControl}
                    isQuizEnabled={props.isQuizEnabled}
                    isLoading={props.isLoading}
                  />
                )
              }
            })
          }
          {
            props.formControl.children.length === 0 &&
            <div
              className={`${styles.emptySection} ${blurPlaceholder ? styles.halfOpacity : ''}`}
              style={{ position: 'relative' }}
              onDragEnter={(e) => { onDragEnter(e) }}
              onDragLeave={(e) => {
                const res = e.dataTransfer.types;
                if (res && res.length > 0 && res[0] === 'create' && props.dragPlaceHolder.current) {
                  referenceDiv.current?.remove();
                }
              }}
              onDrop={(e) => { ondropReorder(e); }}
            >
              {
                controlErrorMessage && controlErrorMessage[props.formControl.formId] && controlErrorMessage[props.formControl.formId][props.formControl.id] &&
                <TextComponent textType='Error'>
                  <div style={{ pointerEvents: 'none', position: 'absolute' }}>{t("validation_control_minimum_count_err")}</div>
                </TextComponent>
              }
            </div>
          }
        </div>
        <div className={styles.contextMenuContainer}>
          <ToolTip content='Delete Section' position='top'>
            <button className={styles.hoverBtn} onClick={deleteControl}>
              <div className={styles.contronContextBtnConatiner}>
                <img className={styles.contronContextImg} src={svgDeleteBuilder} alt="delete" draggable={false} />
              </div>
            </button>
          </ToolTip>
        </div>
      </div>
      <div className={styles.dragInitiator} onDragEnter={(e) => dragPushButtom(e)} ></div>
    </div>
  )
}
