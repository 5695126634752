import styles from './controls-panel.module.scss';
import React, { DragEvent, useEffect, useRef, useState } from 'react';
import { ControlsPanelProps } from './controls-panel.props';
import { debounce } from '../../../../util/debounce';
import { CustomSearch } from '../../common/custom-search/custom-search';
import { LookupControl } from '../../../../store/models/lookup-control/lookup-controls.interface';

import svgCheckbox from "../../../../assets/v2/controls/checkbox.svg";
import svgDate from "../../../../assets/v2/controls/date.svg";
import svgDropdown from "../../../../assets/v2/controls/dropdown.svg";
import svgFile from "../../../../assets/v2/controls/file.svg";
import svgLabel from "../../../../assets/v2/controls/label.svg";
import svgNumber from "../../../../assets/v2/controls/number.svg";
import svgQuiz from "../../../../assets/v2/controls/quiz.svg";
import svgRadio from "../../../../assets/v2/controls/radio.svg";
import svgRaffle from "../../../../assets/v2/controls/raffle.svg";
import svgSection from "../../../../assets/v2/controls/section.svg";
import svgText from "../../../../assets/v2/controls/text.svg";
import svgTextArea from "../../../../assets/v2/controls/textarea.svg";
import svgTime from "../../../../assets/v2/controls/time.svg";
import { useTranslation } from 'react-i18next';
import TextComponent from '../../common/text-component/text-component';
import ToolTip from '../../common/tool-tip/tool-tip';

export function ControlsPanel(props: ControlsPanelProps) {
  const { t } = useTranslation();
  const isSearchStale = useRef(false);
  const [groupedCtrls, setGroupedCtrls] = useState<Map<string, LookupControl[]>>(new Map());
  const controlsOrder = ['SPECIAL', 'LAYOUT', 'HELPER', 'INPUT'];
  const controlIconMap: Record<string, string> = {
    checkbox: svgCheckbox,
    date: svgDate,
    dropdown: svgDropdown,
    file: svgFile,
    label: svgLabel,
    number: svgNumber,
    quiz: svgQuiz,
    radio: svgRadio,
    raffle: svgRaffle,
    section: svgSection,
    text: svgText,
    textarea: svgTextArea,
    time: svgTime,
  };
  const disableQuiz = true;

  useEffect(() => {
    prepareControlData();
  }, [props.lookupControls]);

  const prepareControlData = () => {
    const val = new Map<string, LookupControl[]>();
    props.lookupControls.forEach((ctrl) => {
      const arr = val.get(ctrl.type);
      if (ctrl.name !== 'email' && ctrl.name !== 'mobile number') {
        if (arr) {
          arr.push(ctrl);
        } else {
          val.set(ctrl.type, [{ ...ctrl }]);
        }
      }
    });
    setGroupedCtrls(val);
  }

  const onSearch = debounce((text: string) => {
    text = text.toLocaleLowerCase();
    if (text.trim().length === 0 && isSearchStale.current === true) {
      isSearchStale.current = false;
      const val = new Map<string, LookupControl[]>();
      props.lookupControls.forEach((ctrl) => {
        const arr = val.get(ctrl.type);
        if (ctrl.name !== 'email' && ctrl.name !== 'mobile number') {
          if (arr) {
            arr.push(ctrl);
          } else {
            val.set(ctrl.type, [{ ...ctrl }]);
          }
        }
      });
      setGroupedCtrls(val);
    } else {
      isSearchStale.current = true;
      const val = new Map<string, LookupControl[]>();
      props.lookupControls.forEach((ctrl) => {
        const name = ctrl.name.toLocaleLowerCase();
        if (name.startsWith(text)) {
          const arr = val.get(ctrl.type);
          if (ctrl.name !== 'email' && ctrl.name !== 'mobile number') {
            if (arr) {
              arr.push(ctrl);
            } else {
              val.set(ctrl.type, [{ ...ctrl }]);
            }
          }
        } else if (name.split(' ').length > 1) {
          name.split(' ').forEach((names: string) => {
            if (names.toLocaleLowerCase().startsWith(text)) {
              const arr = val.get(ctrl.type);
              if (ctrl.name !== 'email' && ctrl.name !== 'mobile number') {
                if (arr) {
                  arr.push(ctrl);
                } else {
                  val.set(ctrl.type, [{ ...ctrl }]);
                }
              }
            }
          });
        };
      });
      setGroupedCtrls(val);
    }
  });

  const startDrag = (e: DragEvent<HTMLDivElement>): void => {
    e.dataTransfer.clearData();
    e.dataTransfer.setData('create', 'create');
    const element = e.target as HTMLDivElement;
    element.classList.remove(styles.checkTooltip);
    props.dragPlaceHolder.current = element.cloneNode(true) as HTMLDivElement;
    props.dragPlaceHolder.current.classList.add(styles.createNode);
    props.pickedCtrlRef.current = e.target as HTMLDivElement;
  }

  const endDrag = (e: DragEvent<HTMLDivElement>): void => {
    props.dragPlaceHolder.current?.remove();
    const element = e.target as HTMLDivElement;
    if (element.getAttribute('ctrl-type') === 'raffle') element.classList.add(styles.checkTooltip);
    if (element.getAttribute('ctrl-type') === 'quiz') element.classList.add(styles.checkTooltip);
    props.pickedCtrlRef.current = undefined;
    props.dragPlaceHolder.current = undefined;
  }

  const createControlOnClick = (control: LookupControl) => {
    if (control.name === 'quiz' && props.setIsQuizEnabled) {
      props.setIsQuizEnabled(true);
    }

    if (control.name === 'raffle') {
      props.setIsRaffleActive(false);
    }

    const parent = props.formControls[props.formControls.length - 1];
    const controlIndex = parent.children.length;
    props.createControl({
      controlIndex: controlIndex,
      controlName: control.name,
      parentId: parent.id,
      parentType: 'page',
      parentIndex: props.formControls.length - 1,
    });
  }

  return (
    <div className={styles.container}>
      <TextComponent textType='Header'>
        <p className={`${styles.mb2}`}>{t("form_fields")}</p>
      </TextComponent>
      <div className={`${styles.mb1}`}>
        <CustomSearch placeHolder={`${t("search_by_cntrl_name")}`} searchWord={(item) => onSearch(item)} />
      </div>
      <div className={styles.controlsContainer}>
        {
          controlsOrder.map((value, index) => {
            return (
              <div key={value}>
                {
                  groupedCtrls.has(value) &&
                  <>
                    <TextComponent textType='Header'>
                      <div className={styles.mb1}>{`${value} CONTROLS`}</div>
                    </TextComponent>
                    <div className={`${styles.mb1} ${styles.controlsBox}`}>
                      {
                        groupedCtrls.get(value)?.map((val, index) => {
                          if (val.name === 'page') return <React.Fragment key={val.name}></React.Fragment>;
                          if (val.name === 'raffle') {
                            if (props.isRaffleSubscribed === false || props.isRaffleActive === false) {
                              return (
                                <div key={val.name} ctrl-type={val.name} className={`${styles.controls}`} draggable={false} style={{ cursor: 'not-allowed', position: 'relative' }}>
                                  <ToolTip content={props.isRaffleSubscribed === false ? `${t("raffle_not_subscribed")}` : `${t("raffle_already_exists")}`} position='bottom'>
                                    <img style={{ opacity: '0.5' }} src={controlIconMap[val.name]} alt="" />
                                    <TextComponent textType='Content'>
                                      <p style={{ opacity: '0.5' }}>{val.name[0].toLocaleUpperCase() + val.name.slice(1, val.name.length)}</p>
                                    </TextComponent>
                                  </ToolTip>
                                </div>
                              )
                            }
                            return (
                              <div id='raffleBtnCtrl' key={index} ctrl-type={val.name} className={`${styles.controls}`} draggable onDragStart={(e) => startDrag(e)} onDragEnd={(e) => endDrag(e)} onClick={() => createControlOnClick(val)}>
                                <ToolTip content={`${t("quiz_tooltip")}`} position='bottom'>
                                  <img src={controlIconMap[val.name]} alt="" />
                                  <TextComponent textType='Content'>
                                    <p>{val.name[0].toLocaleUpperCase() + val.name.slice(1, val.name.length)}</p>
                                  </TextComponent>
                                </ToolTip>
                              </div>
                            )
                          }
                          if (val.name === 'quiz') {
                            if (props.isQuizSubscribed === false || props.isQuizEnabled === true || disableQuiz) {
                              return (
                                <div key={index} ctrl-type={val.name} className={`${styles.controls} ${styles.checkTooltipDisabled}`} draggable={false} style={{ cursor: 'not-allowed', position: 'relative' }}>
                                  <ToolTip content={disableQuiz ? `${t("coming_soon")}` : `${t("quiz_already_exists")}`} position='bottom'>
                                    <img style={{ opacity: '0.5' }} src={controlIconMap[val.name]} alt="" />
                                    <TextComponent textType='Content'>
                                      <p style={{ opacity: '0.5' }}>{val.name[0].toLocaleUpperCase() + val.name.slice(1, val.name.length)}</p>
                                    </TextComponent>
                                  </ToolTip>
                                </div>
                              )
                            }
                            return (
                              <div id='quizBtnCtrl' key={index} ctrl-type={val.name} className={`${styles.controls} ${styles.checkTooltip}`} draggable onDragStart={(e) => startDrag(e)} onDragEnd={(e) => endDrag(e)} onClick={() => createControlOnClick(val)}>
                                <ToolTip content={`${t("quiz_tip")}`} position='bottom'>
                                  <img src={controlIconMap[val.name]} alt="" />
                                  <TextComponent textType='Content'>
                                    <p>{val.name[0].toLocaleUpperCase() + val.name.slice(1, val.name.length)}</p>
                                  </TextComponent>
                                </ToolTip>
                              </div>
                            )
                          }
                          return (
                            <div key={index} ctrl-type={val.name} className={styles.controls} draggable onDragStart={(e) => startDrag(e)} onDragEnd={(e) => endDrag(e)} onClick={() => createControlOnClick(val)}>
                              <img src={controlIconMap[val.name]} alt="" />
                              <TextComponent textType='Content'>
                                <p>{val.name[0].toLocaleUpperCase() + val.name.slice(1, val.name.length)}</p>
                              </TextComponent>
                            </div>
                          )
                        })
                      }
                    </div>
                  </>
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
