import { useTranslation } from "react-i18next";
import CustomButton from "../../../../components/v2/common/custom-button/custom-button";
import toast from "react-hot-toast";
import CustomInput from "../../../../components/v2/common/custom-input/custom-input";
import styles from './link-container.module.scss';
import TextComponent from "../../../../components/v2/common/text-component/text-component";

export default function LinkContainer(props: LinkContainerProps) {
  const { t } = useTranslation()
  const copyLink = (link: string) => {
    return <CustomButton
      buttonType="button"
      classType="quaternary"
      style={{
        width: '20%',
        border: 'none',
        boxShadow: 'none',
        borderRadius: "0px 2px 2px 0px"
      }}
      onClick={() => {
        const inputEle = document.getElementById(`${link}`) as HTMLInputElement;
        if (inputEle) {
          navigator.clipboard.writeText(inputEle.value);
          toast.success(t('form_details_toast_link_copy'));
        }
      }}
    ><span>{t('copy')}</span></CustomButton>
  }

  return (
    <>
      <TextComponent textType="Header">
        <div className={styles.linkLabel}>{t("share_link")}</div>
      </TextComponent>
      <div className={styles.fieldContainer}>
        <div className={styles.copyInputContainer}>
          <CustomInput
            id={'form-link'}
            placeHolder={t("form_details_link")}
            value={`${process.env.REACT_APP_RENDERER_WEBAPP}/${props?.url}`}
            readonly={true}
            inputType={'text'}
            inputStyleType={'primary'}
            style={
              {
                border: 'none',
                boxShadow: 'none',
                height: "100%"
              }
            }
          />
        </div>
        {copyLink("form-link")}
      </div>
      <TextComponent textType="Header">
        <div className={styles.linkLabel}>{t("embed_link")}</div>
      </TextComponent>
      <div className={styles.fieldContainer}>
        <div className={styles.copyInputContainer}>
          <CustomInput
            id={'form-embed-link'}
            placeHolder={t("form_details_link")}
            value={`<iframe title="form" src="${process.env.REACT_APP_RENDERER_WEBAPP}/${props.url}?embed=iframe" allowtransparency="true" allowfullscreen="true" style="min-width:100%;height:80vh;border:none" frameborder="0"></iframe>`}
            readonly={true}
            inputType={'text'}
            inputStyleType={'primary'}
            style={
              {
                border: 'none',
                boxShadow: 'none',
                height: "100%",
              }
            }
          />
        </div>
        {copyLink("form-embed-link")}
      </div>
    </>
  )
}

interface LinkContainerProps {
  url: string | undefined;
}
