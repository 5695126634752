import axios from "axios";
import { GetAllLookupCtrlQueryParamsDto } from "../store/models/lookup-control/get-lookup-controls.interface";
import { LookupControl } from "../store/models/lookup-control/lookup-controls.interface";

export async function getAllLookupCtrls(filters?: GetAllLookupCtrlQueryParamsDto): Promise<LookupControl[]> {
    let url = `${process.env.REACT_APP_MANAGER_SVC}/controls-lookup`;
    let params = {};
    if (filters && filters.name) {
        Object.assign(params, { name: filters.name });
    }
    const response = await axios.get<{ controlsLookup: LookupControl[] }>(url);
    return response.data.controlsLookup;
}
