import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styles from './custom-checkbox.module.scss'
import { ChecboxDataProps } from './custom-checkbox.props'

export default function CustomCheckbox(props: ChecboxDataProps) {
  const labelElement = props.labelText ? <label className={`${styles.labelText} ${props.isLabelNormal ? styles.isNormalLabel : ''}`} htmlFor={props.data}>{props.labelText}</label> : <></>;
  const [isChecked, setIsChecked] = useState(false);
  const triggerState = useRef(false);

  useEffect(() => {
    if (props.isInitialChecked !== undefined) {
      triggerState.current = false;
      setIsChecked(props.isInitialChecked);
    }
  }, []);

  useEffect(() => {
    if (props.isInitialChecked !== undefined) {
      triggerState.current = false;
      setIsChecked(props.isInitialChecked);
    }
  }, [props.isInitialChecked]);

  useEffect(() => {
    if (props.onCheckChange && triggerState.current === true) {
      props.onCheckChange({
        data: props.data,
        isChecked,
      });
      triggerState.current = false;
    }
  }, [isChecked]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    triggerState.current = true;
    setIsChecked(!isChecked);
  };

  return (
    <>
      <label className={`${styles.container} ${props.disabled ? styles.isDisabled : ""}`}>
        <input id={props.data} value={props.data} type="checkbox"
          checked={isChecked}
          onChange={(e) => handleChange(e)} disabled={props.disabled} />
        <span className={`${styles.checkmark} ${props.disabled ? styles.isDisabled : ""}`}></span>
        {labelElement}
      </label>
    </>
  )
}

