import styles from '../settings-panel.module.scss';
import CustomInput from "../../../common/custom-input/custom-input";
import CustomTextArea from "../../../common/custom-text-area/custom-text-area";
import { useEffect, useRef, useState } from "react";
import { Accordion } from "../../../common/accordion/accordion";
import { FormPropertiesProps } from './form-properties.props';
import { useTranslation } from 'react-i18next';
import TextComponent from '../../../common/text-component/text-component';

export function FormProperties(props: FormPropertiesProps) {
  const [isFormPropOpen, setIsFormPropOpen] = useState(false);
  const resetTags = useRef<() => {}>();
  const { t } = useTranslation();

  useEffect(() => {

    if (resetTags.current) resetTags.current();
    if (props.isActionPanelOpen === false) {
      setIsFormPropOpen(false);
    }
  }, [props.isActionPanelOpen]);

  return (
    <Accordion
      onAccordionClick={() => setIsFormPropOpen((prevState) => !prevState)}
      btn_content={t("form_properties")}
      openState={isFormPropOpen}
    >
      <div className={styles.sectionContainer}>
        <TextComponent textType='Header'>
          <div>{t("form_details_name")} <span className={styles.fontIsRed}>*</span></div>
        </TextComponent>
        <CustomInput value={props.updatePayload.name} key={'name'} onChange={(e) => {
          props.setUploadPayload((prev) => { return { ...prev, name: e.target.value } });
          props.setIsValuesDirty(true);
          const errors = new Map(props.errors);
          if (e.target.value.trim().length === 0) {
            errors.set('name', `${t("form_name_missing")}`);
            props.setErrors(errors);
          } else {
            errors.delete('name');
            props.setErrors(errors);
          }
        }} placeHolder={`${t("form_details_name")}`} inputStyleType={'primary'} inputType={'text'} />
        {
          props.errors.get('name') &&
          <p className={styles.errorText}>{props.errors.get('name')}</p>
        }
        <TextComponent textType='Header'>
          <div>{t("form_details_description")}<span className={styles.optionalStyle}>{t('optional')}</span></div>
        </TextComponent>
        <CustomTextArea value={props.updatePayload.description} getText={(value) => {
          props.setUploadPayload((prev) => { return { ...prev, description: value } });
          props.setIsValuesDirty(true);
        }} />
      </div>
    </Accordion>
  )
}
