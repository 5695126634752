import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from './generate-api-key.module.scss';
import CustomHeading from "../../../../../components/v2/common/custom-heading/custom-heading";
import CustomInput from "../../../../../components/v2/common/custom-input/custom-input";
import TextComponent from "../../../../../components/v2/common/text-component/text-component";
import CharacterValidator from "../../../../../util/character-validator";
import CustomButton from "../../../../../components/v2/common/custom-button/custom-button";
import { CreateAPIKeyRequestDto, CreateAPIKeyResponseDto } from "../../../../../store/models/api-keys.interface";
import toast from "react-hot-toast";
import CustomText from "../../../../../components/v2/common/custom-bodyText/custom-text";
import { RootDispatch } from "../../../../../store/store";
import { useDispatch } from "react-redux";
import { createAPIKeyThunk } from "../../../../../store/thunks/api-keys.thunk";

export default function GenerateAPIKey(props: AddAPIKeyDto) {
    const dispatch: RootDispatch = useDispatch();
    const { t } = useTranslation();
    const [apiKeyName, setAPIKeyName ] = useState<string>('');
    const [errors, setErrors] = useState({
        apiKeyName: "",
    });
    const [isError, setIsError] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [generatedAPIKey, setGeneratedAPIKey] = useState<CreateAPIKeyResponseDto>();

    const resetValues = () => {
        setIsGenerating(false);
        setAPIKeyName("");
        setIsError(false);
        setErrors({
            apiKeyName: ""
        });
    }

    const generateAPIKey = async (name: string) => {
        setIsGenerating(true);
        try {
            const payload: CreateAPIKeyRequestDto = {
                name: name
            };
            const res = await dispatch(createAPIKeyThunk(payload)).unwrap();
            setGeneratedAPIKey(res);
            toast.success(t("api_key_generated"));
        } catch(err) {
            toast.error(t("api_key_generation_error"));
        } finally {
            resetValues();
        }
    };

    const handleAPIKeyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAPIKeyName(e.target.value);
        if(e.target.value.length <= 0) {
        setErrors({
            ...errors,
            apiKeyName: "API Key Name should be filled."
        });
        setIsError(true);
        return;
        }
        const validate = CharacterValidator(e.target.value);
        if (validate.length > 0) {
            setErrors({
            ...errors,
            apiKeyName: "API Key name should be less than 40."
            });
            setIsError(true);
            return;
        }
        setIsError(false);
        setErrors({
            ...errors,
            apiKeyName: ""
        });
    }

    return(
        <div className={styles.sidepaneMain}>
            <div className={styles.topContainer}>
                <div className={styles.line}></div>
            </div>
            <div>
                {
                    generatedAPIKey 
                    ? <div className={styles.apiKeyCopyContainer}>
                        <TextComponent textType="Header">
                            <div className={styles.linkLabel}>{t("API Key")}</div>
                        </TextComponent>
                        <div className={styles.fieldContainer}>
                            <div className={styles.copyInputContainer}>
                                <CustomInput
                                id={'api-key'}
                                placeHolder={t("api_key")}
                                value={generatedAPIKey.prefixWithAPIKey}
                                readonly={true}
                                inputType={'text'}
                                inputStyleType={'primary'}
                                style={
                                    {
                                    border: 'none',
                                    boxShadow: 'none',
                                    height: "100%"
                                    }
                                }
                                />
                            </div>
                            <CustomButton
                                buttonType="button"
                                classType="quaternary"
                                style={{
                                    width: '20%',
                                    border: 'none',
                                    boxShadow: 'none',
                                    borderRadius: "0px 2px 2px 0px"
                                }}
                                onClick={() => {
                                    navigator.clipboard.writeText(generatedAPIKey?.prefixWithAPIKey);
                                    toast.success(t("api_key_copied_to_clipboard"))
                                }}
                                ><span>{t('copy')}</span>
                            </CustomButton>
                        </div>
                        <CustomText textType="t4" isErrorText={true}>
                            Please note that the API Key can be viewed only once. Store it to secure place.
                        </CustomText>
                    </div>
                    :
                    <div className={styles.inputContainer}>
                        <div>
                            <CustomHeading headerType='h5'>
                                <div className={styles.textColor}>{t("generate_api_key_name")}</div>
                            </CustomHeading>
                            <CustomInput
                                inputStyleType='fade'
                                inputType='text'
                                placeHolder={t("provide_meaningful_name_for_your_api_key")}
                                onChange={handleAPIKeyNameChange}
                            value={apiKeyName}
                            disabled={isGenerating}
                            />
                            <div>
                                {
                                errors?.apiKeyName &&
                                <TextComponent textType='Error'>
                                    <span>{errors.apiKeyName}</span>
                                </TextComponent>
                                }
                            </div>
                        </div>
                
                        <div className={styles.generateBtn}>
                            <CustomButton
                                buttonType='button'
                                classType='primary'
                                onClick={() => { generateAPIKey(apiKeyName) }}
                                disabled={isError || isGenerating || apiKeyName.length <= 0} >
                                <CustomHeading headerType='h4' style={{ color: "#fffff" }}>
                                <div>
                                    {
                                        isGenerating ? t("loading") : t("generate_api_key")
                                    }
                                </div>
                                </CustomHeading>
                            </CustomButton>
                        </div>
                    </div>
                } 
            </div>
        </div>
    );
}

interface AddAPIKeyDto {
  setIsSidePaneOpen: React.Dispatch<React.SetStateAction<boolean>>;
}