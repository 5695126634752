import styles from './forms-setup-v2.module.scss';
import toast from "react-hot-toast";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cloneFormResponseAction } from "../../../api/forms";
import { getAllLookupCtrls } from "../../../api/lookup-controls";
import CustomButton from "../../../components/v2/common/custom-button/custom-button";
import CustomInput from "../../../components/v2/common/custom-input/custom-input";
import CustomTextArea from "../../../components/v2/common/custom-text-area/custom-text-area";
import { BuilderPlaceholder } from "../../../components/v2/form-builder";
import { ActionsPanel } from "../../../components/v2/form-builder/actions-panel/actions-panel";
import { ControlsPanel } from "../../../components/v2/form-builder/controls-panel/controls-panel";
import { TopBar } from "../../../components/v2/form-builder/top-bar/top-bar";
import { SocketContext } from "../../../context/socket";
import { CreateFormControlDto, CreateFormControlsReq } from "../../../store/models/form-controls/create-form-control";
import { RootDispatch, RootState } from "../../../store/store";
import { createControlThunk } from "../../../store/thunks/controls.thunk";
import { createFormThunk, fetchFormByIdThunk } from '../../../store/thunks/forms.thunk';
import { useTranslation } from 'react-i18next';
import { getForm } from '../../../store/slices/forms.slice';
import { fetchAllTemplatesThunk } from '../../../store/thunks/templates.thunk';
import { cloneFormControls } from '../../../api/form-controls';
import { LookupControl } from '../../../store/models/lookup-control/lookup-controls.interface';
import TextComponent from '../../../components/v2/common/text-component/text-component';
import { ActiveBuilderPane } from '../../../store/models/form-builder/active-builder-pane.enum';

export default function FormsSetupV2() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [val, setVal] = useState('');
  const [isActionPanelOpen, setIsActionPanelOpen] = useState(false);
  const [lookupCtrls, setLookupCtrls] = useState<LookupControl[]>([]);
  const dragPlaceHolder = useRef<HTMLDivElement>();
  const pickedCtrlRef = useRef<HTMLDivElement>();
  const [isLoading, setIsLoading] = useState(false);
  const submitRef = useRef<HTMLButtonElement>(null);
  const [name, setName] = useState<string | undefined>('');
  const [desc, setDesc] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [error, setError] = useState('');
  const dispatch: RootDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [isDirty, setIsDirty] = useState(false);

  const form = useSelector((state: RootState) => getForm(state, searchParams.get('id') != null ? searchParams.get('id') as string : ''));
  const template = useSelector((state: RootState) => state.templates.data.find((value) => value.id === searchParams.get('id')));
  const [activeToggle, setActiveToggle] = useState<ActiveBuilderPane>(ActiveBuilderPane.BUILD_LOGIC);

  function handleWindowSizeChange() {
    if (window.matchMedia("(pointer:coarse)").matches && window.matchMedia("(any-pointer:coarse)").matches) {
      toast.error(t("mob_not_supported"));
      navigate(`../dashboard`);
    }
  }

  useEffect(() => {
    handleWindowSizeChange();
    fetchLookupCtrls();
    init();
    setIsActionPanelOpen(true);
  }, []);

  const init = async () => {
    const type = searchParams.get('type');
    let formData = form;
    let templateData = template;
    if (type != null && type === 'template' && !templateData) {
      try {
        const templates = await dispatch(fetchAllTemplatesThunk({})).unwrap();
        templateData = templates.find((value) => value.id === searchParams.get('id'))
      } catch (err) {
        toast.error(t("temp_fetch_fail"));
      }
    }

    if (type != null && type === 'form' && !formData) {
      const id = searchParams.get('id');
      try {
        formData = await dispatch(fetchFormByIdThunk(id !== null ? id : '')).unwrap();
      } catch (err) {
        toast.error(t("form_fetch_fail"));
      }
    }
    if (type != null && type === 'form' && formData) {
      setName(formData.name);
      setDesc(formData.description);
      setTags(formData.tags);
      setIsDirty(true);
    } else if (type != null && type === 'template' && templateData) {
      setName(templateData.name);
      setDesc('');
      setTags(templateData.tags);
      setIsDirty(true);
    }
  }

  const fetchLookupCtrls = async () => {
    try {
      const controls = await getAllLookupCtrls();
      setLookupCtrls(controls);
    } catch (err) {
      toast.error(t("loading_error"));
    }
  }

  const onSave = async () => {
    if (submitRef.current)
      submitRef.current.disabled = true;
    try {
      const type = searchParams.get('type');

      if (type != null && type === 'form' && form) {
        try {
          const formPayload = await dispatch(createFormThunk({ name: name ?? '', tags: tags, description: desc, config: form.config ?? undefined, expiryResponseCount: form.expiryResponseCount, timerConfig: form.timerConfig ?? undefined })).unwrap();
          await cloneFormControls({ formId: formPayload.id, sourceFormId: form.id, type: 'form' });
          const refFormId = searchParams.get('id');
          if (refFormId) await cloneFormResponseAction(refFormId, formPayload.id);
          toast.success(t("form_created"));
          setIsActionPanelOpen(false);
          setTimeout(() => navigate(`../forms/${formPayload.id}/edit`), 250);
          return;
        } catch (err) {
          if (submitRef.current) {
            submitRef.current.disabled = false;
          }
          toast.error(t("clone_fail"));
          return;
        }
      }
      const formPayload = await dispatch(createFormThunk({ name: name ?? '', tags: tags, description: desc })).unwrap();
      const ctrl = lookupCtrls.find(val => val.name === 'page');
      if (ctrl) {
        const payload: CreateFormControlDto = {
          data: {
            children: [],
            controlId: ctrl.id,
            formId: formPayload.id,
            isReadOnly: false,
            openEditor: false,
            options: [],
            parentId: null,
            config: ctrl.config,
            controlType: ctrl.name,
            order: 0,
          },
          controlIndex: 0,
          parentType: null
        };
        const page = await dispatch(createControlThunk({ socket: socket, payload: payload })).unwrap();
        if (type != null && type === 'template' && template) {
          const payload: CreateFormControlsReq[] = template.config.map((value) => {
            return {
              children: [],
              controlId: value.controlId,
              formId: formPayload.id,
              isReadOnly: value.isReadOnly,
              openEditor: false,
              options: value.options,
              order: value.order,
              parentId: page.data.id,
              config: value.config,
              controlType: value.controlType,
              label: value.label,
              labelHTML: value.labelHTML
            }
          });
          try {
            await cloneFormControls({ formId: formPayload.id, sourceFormId: template.id, type: 'template', config: payload });
          } catch (err) {
            toast.error(t("clone_fail"));
            return;
          }
        }
        toast.success(t("form_created"));
        setIsActionPanelOpen(false);
        setTimeout(() => navigate(`../forms/${formPayload.id}/edit`), 250);
      }
    } catch (err) {
      toast.error(t("form_save_fail"));
    }
    if (submitRef.current)
      submitRef.current.disabled = false;
  }

  const clearTags = useRef(null);

  const clearForm = () => {
    setName('');
    setError(t("form_name_mandatory"));
    setDesc('');
    setIsDirty(false);
    const func: any = clearTags?.current;
    if (func) {
      func();
    }
  }

  return (
    <>
      <div className={`${styles.container} ${isActionPanelOpen ? styles.blurContainer : ''}`}>
        <TopBar formControls={[]} setActionBarTitle={setVal} formName={name} isActionsPanelOpen={isActionPanelOpen} setIsActionsPanelOpen={setIsActionPanelOpen} setSidePaneTabvalue={setVal} isLoading={isLoading} setIsLoading={setIsLoading} activeToggle={ActiveBuilderPane.BUILD_LOGIC} setActiveToggle={setActiveToggle} plans={[]} />
        <div className={styles.builderContainer}>
          <ControlsPanel lookupControls={lookupCtrls} dragPlaceHolder={dragPlaceHolder} pickedCtrlRef={pickedCtrlRef} isRaffleActive={isLoading} setIsRaffleActive={setIsLoading} isRaffleSubscribed={isLoading} isQuizSubscribed={isLoading} createControl={{} as any} formControls={[]} />
          <div className={styles.builderCanvas} style={{ flexGrow: '1' }}>
            <div className={styles.formCanvasTitle}>
              <TextComponent textType='Content'>
                {name}
              </TextComponent>
            </div>
            <div className={styles.linebreak}></div>
            <BuilderPlaceholder />
          </div>
        </div>
      </div>
      <ActionsPanel title={
        searchParams.get('type') != null
          ? `${t("clone_form")}`
          : `${t("create_form_button")}`
      } isActionsPanelOpen={isActionPanelOpen} hideCloseButton={false} setIsActionsPanelOpen={setIsActionPanelOpen} navigateDashboardOnClose={true}>
        <div className={`${styles.mb1} ${styles.boldFont}`}>
          <TextComponent textType='Header'>
            {t('form_name')}<span className={styles.fontIsRed}>*</span>
          </TextComponent>
        </div>
        <div className={styles.mb1}>
          <CustomInput value={name} key={'name'} onChange={(e) => { setIsDirty(true); setName(e.target.value); if (e.target.value.trim().length === 0) setError(t("form_name_mandatory")); else setError('') }} placeHolder={t("setup_form_form_name_placeholder")} inputStyleType={'primary'} inputType={'text'} />
          {error !== '' && <div className={styles.errorText}>{error}</div>}
        </div>
        <div className={`${styles.mb1} ${styles.boldFont}`}>
          <TextComponent textType='Header'>
            {t('form_description')} <span className={styles.optionalStyle}>{t('optional')}</span>
          </TextComponent>
        </div>
        <div className={styles.mb1}>
          <CustomTextArea value={desc} getText={(e) => { setDesc(e); setIsDirty(true); }} placeHolder={t("talk_abt_form")} />
        </div>
        {/* commenting out the tags */}
        {/* <div className={`${styles.mb1} ${styles.boldFont}`}>Tags <span className={styles.optionalStyle}>{t('optional')}</span></div>
        <div className={styles.mb1}>
          {
            isContentLoaded &&
            <div>
              <MultilineTags defaultValue={tags} onInput={(tags) => { setTags([...tags]) }} clearTags={clearTags} />
              <div className={styles.infoText}>{`Click Enter to add value`}</div>
            </div>
          }
        </div> */}

        <div className={styles.saveBtnContainer}>
          <CustomButton buttonType='reset' classType='secondary' onClick={() => { clearForm(); }} style={{ width: "25%" }}>
            <div style={{ color: "#000" }}>{t("clear")}</div>
          </CustomButton>
          {/* <CustomButton disabled={error !== '' || !isDirty} buttonType='submit' classType='primary' onClick={() => onSave()} style={{ width: "25%" }}>
            {
              searchParams.get('type') != null
                ? <div>{t("clone")}</div>
                : <div>{t("create")}</div>
            }
          </CustomButton> */}
          <button ref={submitRef} disabled={error !== '' || !isDirty} className={styles.btn} onClick={() => { onSave() }}>
            {
              searchParams.get('type') != null
                ? <div>{t("clone")}</div>
                : <div>{t("create")}</div>
            }
          </button>
        </div>
      </ActionsPanel>
    </>
  )
}
