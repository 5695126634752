import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import { UserLoginType } from '../../../../store/models/user-type.enum';
import PageLayout from '../page-layout/page-layout';
import styles from './user-type.module.scss';
import CustomHeading from '../../../../components/v2/common/custom-heading/custom-heading';
import svgOrganizationUser from "../../../../assets/REPLACE-THESE/organization-user.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgPersonalUser from "../../../../assets/REPLACE-THESE/personal-user.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import TextComponent from '../../../../components/v2/common/text-component/text-component';

export default function UserType() {
  const navigate = useNavigate()
  const { t } = useTranslation();
  function changeUserType(userLoginType: string) {
    const userType = window.sessionStorage.getItem("userLoginType");

    if (userType) {
      sessionStorage.removeItem("userLoginType");
    }

    sessionStorage.setItem("userLoginType", userLoginType);
    navigate('/sign-up');
  }

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.pageLayout}>
          <PageLayout />
        </div>
        <div className={styles.rightContainer}>
          <TextComponent textType='Header'>
            <div>{t("sign_up_main")}</div>
          </TextComponent>
          <CustomButton
            buttonType='button'
            classType='tertiary'
            onClick={() => changeUserType(UserLoginType.PERSONAL)}
          >
            <div className={styles.signupBtn}>
              <img src={svgPersonalUser} alt="personal-user" loading='lazy' />
              <TextComponent textType='Header'>
                <div>{`${t("user_type_personal")}`}</div>
              </TextComponent>
            </div>
          </CustomButton>
          <CustomButton
            buttonType='button'
            classType='tertiary'
            onClick={() => changeUserType(UserLoginType.PROFESSIONAL)}>
            <div className={styles.signupBtn}>
              <img src={svgOrganizationUser} alt="personal-user" loading='lazy' />
              <TextComponent textType='Header'>
                <div>{`${t("user_type_professional")}`}</div>
              </TextComponent>
            </div>
          </CustomButton>
          <TextComponent textType='Content'>
            <div style={{ textAlign: "center" }}>
              {t("user_type_already")}  <Link to={'/'} className={styles.linkColor}>{t("sign_in")}</Link>  {t("here")}
            </div>
          </TextComponent>
        </div>
      </div>
    </>
  )
}
