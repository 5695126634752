import axios from "axios";
import { CreateAPIKeyRequestDto, CreateAPIKeyResponseDto, GetAPIKeysResDto } from "../store/models/api-keys.interface";
import { GenerateApiUrl } from "./generate-api-url";

export async function getAPIKeys(): Promise<GetAPIKeysResDto[]> {
  let apiUrl = GenerateApiUrl(`${process.env.REACT_APP_DATAEXPORT_RESTAPI_SVC as string}`, `api-keys`, true);
  const response = await axios.get<GetAPIKeysResDto[]>(apiUrl);
  return response.data;
}

export async function createAPIKey(apiKey: CreateAPIKeyRequestDto) {
  let apiUrl = GenerateApiUrl(`${process.env.REACT_APP_DATAEXPORT_RESTAPI_SVC as string}`, `api-keys`, true);
  const response = await axios.post<CreateAPIKeyResponseDto>(apiUrl, apiKey);
  return response.data;
}

export async function deleteAPIKey(id: string) {
  let apiUrl = GenerateApiUrl(`${process.env.REACT_APP_DATAEXPORT_RESTAPI_SVC as string}`, `api-keys/${id}`, true);
  const response = await axios.delete(apiUrl);
  return response;
}
