import axios from "axios";
import { InviteUserReqDto, InviteUserRespDto, GetOrgUsersRespDto, GetOrgUserRespDto, GetOrgInvitationsRespDto, SetOrgUserRolesDto } from "../store/models/organization.interface";
import { GenerateApiUrl } from "./generate-api-url";
import { CreateOrganizationReqDto, CreateOrganizationResponseDto } from "../store/models/sign-up.interface";

export async function inviteUserToOrg(data: InviteUserReqDto): Promise<InviteUserRespDto> {
  let apiUrl = GenerateApiUrl(`${process.env.REACT_APP_USER_MANAGER_SVC as string}/organization`, `users/invite`, true);
  const response = await axios.post<InviteUserRespDto>(apiUrl, data);
  return response.data;
}

export async function getOrgUsers(): Promise<GetOrgUsersRespDto> {
  let apiUrl = GenerateApiUrl(`${process.env.REACT_APP_USER_MANAGER_SVC as string}/organization`, `users`, true);
  const response = await axios.get<GetOrgUsersRespDto>(apiUrl);
  return response.data;
}

export async function getOrgUserById(userId: string): Promise<GetOrgUserRespDto> {
  let apiUrl = GenerateApiUrl(`${process.env.REACT_APP_USER_MANAGER_SVC as string}/organization`, `users/${userId}`, true);
  const response = await axios.get<GetOrgUserRespDto>(apiUrl);
  return response.data;
}

export async function removeUserFromOrganization(userId: string): Promise<void> {
  let apiUrl = GenerateApiUrl(`${process.env.REACT_APP_USER_MANAGER_SVC as string}/organization`, `users/${userId}`, true);
  await axios.delete(apiUrl);
}

export async function getOrgInvitations(): Promise<GetOrgInvitationsRespDto & { status: number, message: string }> {
  let apiUrl = GenerateApiUrl(`${process.env.REACT_APP_USER_MANAGER_SVC as string}/organization`, `invitations`, true);
  const response = await axios.get<GetOrgInvitationsRespDto & { status: number, message: string }>(apiUrl);
  return response.data;
}

export async function revokeUserInviteToOrganization(inviteId: string): Promise<void> {
  let apiUrl = GenerateApiUrl(`${process.env.REACT_APP_USER_MANAGER_SVC as string}/organization`, `users/invite/${inviteId}`, true);
  await axios.delete(apiUrl);
}

export async function attachUserWithOrgRoles(userId: string, data: SetOrgUserRolesDto): Promise<void> {
  let apiUrl = GenerateApiUrl(`${process.env.REACT_APP_USER_MANAGER_SVC as string}/organization`, `users/${userId}/roles`, true);
  await axios.put(apiUrl, data);
}

export async function createOrganization(data: CreateOrganizationReqDto): Promise<CreateOrganizationResponseDto> {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `organization`, false);
  const response = await axios.post<CreateOrganizationResponseDto>(apiUrl, data);
  return response.data;
}

export async function verifyWorkspace(orgName : string):Promise<{isVerified : boolean}>{
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `organization/${orgName}/verify`, false);
  const response = await axios.get(apiUrl);
  return response.data;
}

export async function deleteOrganization() {
  const apiUrl = GenerateApiUrl(`${process.env.REACT_APP_USER_MANAGER_SVC as string}/organization`, ``, true);
  const response = await axios.delete(apiUrl);
  return response.data;
}

