import { useKeycloak } from "@react-keycloak/web";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LoginNew from "../../../pages/v2/user-onboarding/login-new";
import Loader from "../loader/loader";
import { useEffect, useState } from "react";
import { getAllProfileInfoForAppInit } from "../../../api/profile";
import CustomLoader from "../../v2/common/custom-loader/custom-loader";

export default function Login() {
  const { keycloak, initialized } = useKeycloak();
  const [isUserInitialized, setIsUserInitialized] = useState(false);
  let location = useLocation();
  const navigate = useNavigate();
  // TODO: remove touch-event-poc from routes after POC is complete.
  const urls = ['sign-up', 'forgot-password', 'user-type', 'validate-otp', 'reset-password', 'touch-event-poc'];
  let path = location.pathname.split('/')[1];

  useEffect(() => {
    //to reset a page position on every render
    window.scrollTo(0, 0);
  }, [location]);

  if (!initialized) {
    return <CustomLoader/>;
  }

  const getUserOrg = async () => {
    const res = await getAllProfileInfoForAppInit();
    if (res && res.metadata && res.metadata.active_org) {
      setIsUserInitialized(true);
      navigate(`o/${res.metadata.active_org}/dashboard`);
    } else {
      setIsUserInitialized(true);
      navigate(`/profile`);
    }
  }

  if (keycloak.authenticated) {
    if (urls.includes(path) || (path === '') || (path === '/')) {
      getUserOrg()
      return isUserInitialized ? <></> : <CustomLoader/>;
    }
    return <Outlet />;
  }else{
    if(urls.includes(path) || path === '' || path === '/'){
      return <Outlet/>
    }
  }

  if (location.pathname === '/') {
    return <LoginNew />;
  }

  return <>{keycloak.login({
    redirectUri: location.pathname !== '/' ? window.origin + location.pathname : undefined,
  })}</>;
}
