import { useEffect, useState } from 'react'
import styles from './custom-file-input.module.scss';
import { CustomFileInputProps } from './custom-file-input.props';
import svgCloseBtnEmail from "../../../../assets/v2/close-btn-email.svg";
import svgDelete from "../../../../assets/v2/icons/delete.svg";
import svgUpload from "../../../../assets/v2/icons/upload.svg";

export default function CustomFileInput(props: CustomFileInputProps) {

  const [files, setFiles] = useState<File[]>();

  useEffect(() => {
    if (props.getFiles)
      setFiles(props.getFiles);
  }, [])


  useEffect(() => {
    if (props.setFiles && files) {
      props.setFiles(files);
    }
  }, [files])


  const removeFile = (index: number) => {
    if (files) {
      let tempFiles = [...files];
      tempFiles.splice(index, 1);
      setFiles(tempFiles);
    }
  }

  return (
    <div className={styles.main}>
      {files && files.length > 0 &&
        <div style={{ display: "flex" }}>
          <div className={styles.filesConatiner} style={{ flexGrow: "1" }}>
            {
              files.map((item, index) => (
                <div className={styles.files}>
                  <div>{item.name}</div>
                  <div onClick={() => removeFile(index)} className={styles.deleteFileBubble}><img src={svgCloseBtnEmail} alt="delete" /></div>
                </div>
              ))
            }
          </div>
          <div>
            <button onClick={() => { setFiles([]) }} className={styles.resetBtn}><img src={svgDelete} alt="delete" /></button>
          </div>
        </div>
      }
      <div className={`${props.disabled && styles.disabled}  ${styles.fileContainer} ${props.isReadOnly === true ? styles.readonly : ''}`} >
        <label htmlFor="inputTag" className={`${styles.customFileInput} ${props.disabled && styles.disabled}`}>
          <span className={styles.placeholderColor}>{props.placeHolder ? props.placeHolder : "choose file"}</span>
          <img src={svgUpload} alt="" />
          <input id="inputTag" type="file" onChange={(e) => {
            if (e.target.files && files) {
              let tempFile = [...files];
              Object.keys(e.target.files).forEach(file => {
                if (e.target.files)
                  tempFile.push(e.target.files[file as keyof object]);
              });
              setFiles(tempFile);
            }
          }} multiple={props.multiple} disabled={props.disabled} />
        </label>
      </div>
    </div>
  )
}
