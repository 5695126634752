import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"
import CustomButton from "../../components/v2/common/custom-button/custom-button";
import styles from "./page-not-found.module.scss";
import svgNotFound from "../../assets/v2/404.svg";

export default function PageNotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={`${styles.parentContainer}`}>
      <div className={`${styles.section}`}>
        <div className={styles.content}>
          <img src={svgNotFound} alt="404"></img>
          <div className={`is-primary-color is-secondary-font is-highlighted`} style={{ "fontSize": "2rem" }}>{t('page_not_found_oops')}</div>
          <div className={`is-primary-color is-secondary-font is-highlighted`} style={{ "fontSize": "1.5rem" }}>{t('page_not_found_text')}</div>
          <div className={styles.btnContainer}>
            <CustomButton buttonType='button' classType='quaternary' onClick={() => navigate('/profile')}>{t('page_not_found_go_to_dashboard')} </CustomButton>
          </div>
        </div>
      </div>
    </div>
  )
}
