import { UserMetadataKey } from '../store/models/user-metadata-key.enum';
import store from '../store/store';

export function GenerateApiUrl(svcPath: string, actionPath: string, isOrg: boolean): string {

  let apiUrl = '';
  if (isOrg) {
    const state = store.getState();
    let activeOrg: string = state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION];
    if (!activeOrg) {
        const user: any = state.user.data;
        const organizations = user.organizations;
        const activeOrgData = (organizations[Object.keys(organizations)[0]]).name;
        activeOrg = activeOrgData;
      }
      apiUrl = `${svcPath}/${activeOrg}/${actionPath}`;
  } else {
    apiUrl = `${svcPath}/${actionPath}`;
  }
  return apiUrl;
}
