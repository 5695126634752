import axios from "axios";
import { GenerateApiUrl } from "./generate-api-url";
import { GetPaymentAccountsResp, GetPaymentAccountsRespItem } from "../store/models/user.interface";

export async function detachPaymentMethod(id: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `billing-account?stripePaymentMethodId=${id}`, false);
  const response = await axios.delete(apiUrl);
  return response;
}

export async function addCard() {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `billing-account`, false);
  const res = await axios.post(apiUrl);
  return res.data.redirectUrl;
}

export async function getPaymentAccounts(): Promise<GetPaymentAccountsResp> {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `billing-account`, false);
  const res = await axios.get<GetPaymentAccountsResp & { status: number, message: string }>(apiUrl);
  return res.data;
}

export async function getPaymentAccountById(id: string): Promise<GetPaymentAccountsRespItem> {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_USER_MANAGER_SVC as string, `billing-account/${id}`, false);
  const res = await axios.get<GetPaymentAccountsRespItem & { status: number, message: string }>(apiUrl);
  return res.data;
}
