import styles from '../settings-panel.module.scss';
import CustomInput from '../../../common/custom-input/custom-input';
import CustomDateTime from '../../../common/custom-date-time/custom-date-time';
import { useEffect, useState } from "react";
import { Accordion } from "../../../common/accordion/accordion";
import { GlobalSettingsProps } from './global-settings.props';
import CustomText from '../../../common/custom-bodyText/custom-text';
import { useTranslation } from 'react-i18next';
import TextComponent from '../../../common/text-component/text-component';
import { MultilineTags } from '../../../common/multiline-tags/multiline-tags';

export function GlobalSettings(props: GlobalSettingsProps) {
  const { t } = useTranslation();
  const [isGlobalSettingsOpen, setIsGlobalSettingsOpen] = useState(false);
  const [emailErrors, setEmailErrors] = useState('');
  const [updateDate, setUpdateDate] = useState(false);

  useEffect(() => {
    const map = props.errors;
    if (emailErrors.length > 0) {
      map.set('emailErrors', emailErrors);
      props.setErrors(new Map(map));
    } else {
      map.delete('emailErrors');
      props.setErrors(new Map(map));
    }
  }, [emailErrors]);

  useEffect(() => {
    if (props.isActionPanelOpen === false) setIsGlobalSettingsOpen(false);
  }, [props.isActionPanelOpen]);

  useEffect(() => {
    if (props.isEmailNotificationOpted === true && props.emails && props.emails.length === 0) {
      setEmailErrors(t("enter_atleast_one_email"));
    } else if (props.isEmailNotificationOpted === false) {
      setEmailErrors('');
    }
  }, [props.isEmailNotificationOpted, props.emails]);

  useEffect(() => {
    if (updateDate) {
      if (props.errors.size === 0) {
        props.setIsValuesDirty(true);
      }
    }
    else props.setIsValuesDirty(false);
  }, [updateDate])

  return (
    <Accordion
      onAccordionClick={() => setIsGlobalSettingsOpen((prev) => !prev)}
      btn_content={t("global")}
      openState={isGlobalSettingsOpen}
    >
      <div className={styles.sectionContainer}>
        <TextComponent textType='Header'>
          <div>{t("form_expiry_time")}</div>
        </TextComponent>
        <CustomDateTime value={props.updatePayload.expiryTime != null ? props.updatePayload.expiryTime : undefined}
          onDateTimeSelect={(date) => {
            props.setUploadPayload((prev) => { return { ...prev, expiryTime: date ? date.toISOString() : null } });
            const currDate = new Date();
            const errors = new Map(props.errors);
            if (date != null && currDate > date) {
              errors.set('expiryTime', `${t("expiry_time_past")}`);
            } else errors.delete('expiryTime');
            props.setErrors(errors);
          }}
          isAccordianOpen={isGlobalSettingsOpen}
          setUpdateDate={setUpdateDate}
        />
        {
          props.errors.get('expiryTime') &&
          <p className={styles.errorText}>{props.errors.get('expiryTime')}</p>
        }
        <TextComponent textType='Header'>
          <div>{t("allowed_resp_cnt")}</div>
        </TextComponent>
        <CustomInput
          value={props.updatePayload.expiryResponseCount != null ? props.updatePayload.expiryResponseCount : undefined}
          placeHolder={`${t("maz_allowed_resp")}`} inputStyleType={'primary'} inputType={'number'}
          onChange={(e) => {
            const value = e.target.value.trim();
            props.setUploadPayload((prev) => { return { ...prev, expiryResponseCount: value.length > 0 && Number(value) > 0 ? Number(value) : null } });
            if (props.errors.size === 0) {
              props.setIsValuesDirty(true);
            }
            const errors = new Map(props.errors);
            if (value.length > 0 && Number(value) < 1) {
              errors.set('maximumResponse', `${t("max_allowed_resp_less_than_one")}`);
            } else errors.delete('maximumResponse');
            props.setErrors(errors);
          }}
        />
        {
          props.errors.get('maximumResponse') &&
          <p className={styles.errorText}>{props.errors.get('maximumResponse')}</p>
        }
        <div style={{ display: 'flex' }}>
          <TextComponent textType='Header'>
            <div>{t("form_styles_global_themes")}</div>
          </TextComponent>
          {
            props.updatePayload.config?.theme?.id &&
            <div className={styles.themesClear} onClick={() => {
              props.setUploadPayload((prev) => { return { ...prev, config: { ...prev.config, theme: null } } });
              if (props.errors.size === 0) {
                props.setIsValuesDirty(true);
              }
            }}>{t("clear")}</div>
          }
        </div>
        <div className={styles.themeContainer}>
          {
            props.themes.map(item => {
              return (
                <div className={`${styles.themeBar} ${item.id == props.updatePayload.config?.theme?.id ? styles.selectedTheme : ''}`} key={item.id}
                  onClick={() => {
                    props.setUploadPayload((prev) => { return { ...prev, config: { ...prev.config, theme: item } } });
                    if (props.errors.size === 0) {
                      props.setIsValuesDirty(true);
                    }
                  }}
                >
                  <div style={{ background: item.main }} className={styles.colorBox}></div>
                  <div style={{ background: item.background }} className={styles.colorBox2}></div>
                </div>
              )
            })
          }
        </div>
        <div className={styles.emailNotification}>
          <TextComponent textType='Header'>
            <div>{t("email_notif")}</div>
          </TextComponent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label className={styles.switch} id={'timerActiveStatus'}>
              <input
                type={"checkbox"}
                checked={props.isEmailNotificationOpted}
                onChange={(e) => {
                  props.setIsEmailNotificationOpted(e.target.checked);
                  if (props.errors.size === 0) {
                    props.setIsValuesDirty(true);
                  }
                }}
                id="timerActiveStatus"
              />
              <span className={styles.slider}></span>
            </label>
          </div>
        </div>
        {
          props.isEmailNotificationOpted &&
          <>
            <TextComponent textType='Header'>
              <div>{t("notif_on_emls")}</div>
            </TextComponent>
            <MultilineTags
              value={props.emails}
              type='email'
              onChange={(tags) => {
                props.setEmails(tags);
                if (props.errors.size === 0) {
                  props.setIsValuesDirty(true);
                }
              }}
              setErrors={setEmailErrors}
            />
            {
              emailErrors &&
              <CustomText textType='t7' style={{
                color: "red",
                fontWeight: "600",
              }}>
                <div>{emailErrors}</div>
              </CustomText>
            }
          </>
        }
      </div>
    </Accordion>
  )
}
