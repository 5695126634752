import { useContext, useEffect, useState } from 'react';
import BarChart from '../../../../../components/common/charts/bar';
import CircleChart from '../../../../../components/common/charts/circle';
import PieChart from '../../../../../components/common/charts/pie';
import CustomText from '../../../../../components/v2/common/custom-bodyText/custom-text';
import CustomHeading from '../../../../../components/v2/common/custom-heading/custom-heading';
import styles from './response-analytics.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../../store/store';
import { fetchResponseAnalytics } from '../../../../../store/thunks/form-responses.thunk';
import { useTranslation } from 'react-i18next';
import svgNoForms from "../../../../../assets/v2/icons/no-forms.svg";
import { SocketContext } from '../../../../../context/socket';
import { ACTIVE_PAGE_STATUS } from '../../../../../store/models/active-page';
import { activePageThunk } from '../../../../../store/thunks/active-page.thunk';
import TextComponent from '../../../../../components/v2/common/text-component/text-component';


export default function ResponseAnalytics() {
  const { formId } = useParams();
  let dispatch: RootDispatch = useDispatch();
  const [chartData, setChartData] = useState<any>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const activePage = useSelector((state: RootState) => state.activePage.activePage);
  const socket = useContext(SocketContext);

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activePage])

  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    if (formId) {
      dispatch(activePageThunk({ socket: socket, payload: { pageName: `response-analytics-${formId}`, status } }));
    }
  }

  useEffect(() => {
    if (newData && formId) {
      getChartData(formId);
    }
  }, [newData])

  useEffect(() => {
    if (formId) {
      getChartData(formId)
    }
  }, []);

  const getChartData = async (id: string) => {
    try {
      const response = await dispatch(fetchResponseAnalytics({ formId: id })).unwrap();
      setChartData(response.data[0]);
    } catch (error) { }
  }

  return (
    <div className={styles.analyticsMain}>
      {
        chartData && ((chartData.hasOwnProperty("deviceType") && chartData.deviceType.length > 0) || chartData.hasOwnProperty("dropdown") || chartData.hasOwnProperty("radio") || chartData.hasOwnProperty("checkbox")) ?
          <div className={styles.container}>
            {
              chartData && chartData.hasOwnProperty("deviceType") && chartData.deviceType.length > 0 ?
                <div className={`${styles.charts}`}>
                  <div className={styles.chartHeader}>
                    <TextComponent textType='Header'>
                      {t("dev_type")}
                    </TextComponent>
                  </div>
                  <div className={styles.chartHeight}>
                    {
                      < BarChart
                        data={chartData.deviceType}
                        keys={["value"]}
                        groupMode="stacked"
                        layout="horizontal"
                        indexBy="id"
                      />
                    }
                  </div>
                </div> : <></>
            }
            {
              chartData && chartData.hasOwnProperty("dropdown") && chartData.dropdown.map((e: any) => {
                return (<div className={`${styles.charts}`}>
                  <div className={styles.chartHeader}>
                    <TextComponent textType='Header'>
                      <div>{e.controlLabel}</div>
                    </TextComponent>
                  </div>
                  <div className={styles.chartHeight}>
                    {<PieChart
                      analytics={false}
                      data={e.data}
                    />}
                  </div>
                </div>
                );
              })
            }
            {
              chartData && chartData.hasOwnProperty("radio") && chartData.radio.map((e: any) => {
                return (<div className={`${styles.charts}`}>
                  <div className={styles.chartHeader}>
                    <TextComponent textType='Header'>
                      <div>{e.controlLabel}</div>
                    </TextComponent>
                  </div>
                  <div className={styles.chartHeight}>
                    {<PieChart
                      analytics={false}
                      data={e.data}
                    />}
                  </div>
                </div>
                );

              })
            }
            {
              chartData && chartData.hasOwnProperty("checkbox") && chartData.checkbox.map((e: any, index: any) => {

                return (<div className={`${styles.charts}`}>
                  <div className={styles.chartHeader}>
                    <TextComponent textType='Header'>
                      <div>{e.name}</div>
                    </TextComponent>
                  </div>
                  <div className={styles.chartHeight}>
                    {
                      <CircleChart
                        id={"name"}
                        value={"value"}
                        data={e}
                      />
                    }
                  </div>
                </div>
                );

              })
            }
          </div> :
          <div className={styles.noFormsMainContainer}>
            <div className={styles.noFormsContainer}>
              <div>
                <img src={svgNoForms} alt="no-forms" />
              </div>
              <TextComponent textType='Content'>
                <div>{t("no_analytics")}</div>
              </TextComponent>
            </div>
          </div>
      }
    </div>
  );
}
