import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Captcha } from '../../../../components/v2/common/captcha/captcha';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import CustomInput from '../../../../components/v2/common/custom-input/custom-input';
import CustomSelect from '../../../../components/v2/common/custom-select/custom-select';
import { CountryList } from '../../../../constants/mobile-country-code';
import { UserLoginType } from '../../../../store/models/user-type.enum';
import CompanySetup from '../company-setup/company-setup';
import PageLayout from '../page-layout/page-layout';
import styles from './sign-up.module.scss';
import { DropdownDataItem } from '../../../../components/v2/common/custom-select/custom-select.props';
import { toast } from 'react-hot-toast';
import { CreateUserReqDto } from '../../../../store/models/user.interface';
import { createAccountThunk } from '../../../../store/thunks/user.thunk';
import { RootDispatch } from '../../../../store/store';
import { useDispatch } from 'react-redux';
import { checkIfUserExists } from '../../../../api/sign-up';
import svgRightArrow from "../../../../assets/v2/icons/right-arrow.svg";
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import CharacterValidator from '../../../../util/character-validator';

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch: RootDispatch = useDispatch();
  const [isSignUp, setIsSignUp] = useState<boolean>(true);
  const [countryCodeWarningText, setCountryCodeWarningText] = useState<string | undefined>(undefined);
  const [firstNameWarningText, setFirstNameWarningText] = useState<string | undefined>(undefined);
  const [lastNameWarningText, setLastNameWarningText] = useState<string | undefined>(undefined);
  const [emailWarningText, setEmailWarningText] = useState<string | undefined>(undefined);
  const [mobileWarningText, setMobileWarningText] = useState<string | undefined>(undefined);
  const [passwordWarningText, setPasswordWarningText] = useState<string | undefined>(undefined);
  const [confirmWarningText, setConfirmWarningText] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validation, setValidation] = useState<boolean>(false);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [userData, setUserData] = useState<{
    firstName: string,
    lastName: string,
    email: string,
    countryCode: string,
    mobileNumber: string,
    newPassword: string,
    confirmPassword: string,
  }>({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    mobileNumber: "",
    newPassword: "",
    confirmPassword: "",
  });
  const { t } = useTranslation();
  const [userType, setUserType] = useState<string>("");

  useEffect(() => {
    const userType = sessionStorage.getItem("userLoginType");
    if (userType && userType != null) {
      setUserType(userType);
    } else {
      navigate('/user-type');
    }
  }, []);

  useEffect(() => {
    if (userData.mobileNumber) {
      if (userData.mobileNumber.length < 1) {
        setMobileWarningText(t("sign_up_mobile_required"));
      } else if ((userData.countryCode.length + userData.mobileNumber.length) > 15 || (userData.countryCode.length + userData.mobileNumber.length) < 7) {
        setMobileWarningText(t("invalid_mob"));
      } else {
        setMobileWarningText('');
      }
    }
  }, [userData.countryCode, userData.mobileNumber]);

  useEffect(() => {
    if (userData.firstName && userData.lastName && userData.email && userData.countryCode && userData.mobileNumber && ((userData.mobileNumber.length + userData.countryCode.length) < 15 || (userData.mobileNumber.length + userData.countryCode.length) > 7) && ((userData.newPassword && userData.confirmPassword && (userData.newPassword === userData.confirmPassword)))) {
      setValidation(true);
    }
    else {
      setValidation(false);
    }
    if (isTouched) {
      if (userData.newPassword !== userData.confirmPassword) {
        setConfirmWarningText(`${t("reset_password_confirm_warning")}`);
      }
    }
  }, [userData]);


  async function checkUserEmail(email?: string): Promise<boolean> {
    const res = await checkIfUserExists({ email: email ?? userData.email });
    if (res && res.isExists) {
      setEmailWarningText(`${t("sign_up_email_warning")}`);
      return false;
    }
    return true;
  }

  function changeUserTypeSignUp(userLoginType: string) {

    const userType = window.sessionStorage.getItem("userLoginType");
    if (userType) {
      sessionStorage.removeItem("userLoginType");
    }
    sessionStorage.setItem("userLoginType", userLoginType);
    setUserType(userLoginType);
  }

  function validateEmail(value: string) {
    const re = new RegExp(/^[a-z0-9+_.-]+@[a-z0-9.-]+\.[a-z]+$/);
    if (value.length < 1) {
      setEmailWarningText(`${t("sign_up_email_required")}`);
    } else if (!re.test(value)) {
      setEmailWarningText(`${t("sign_up_email_invalid")}`);
    } else {
      setEmailWarningText('');
    }
  }

  function validatePassword(value: string) {
    const re = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,50}$/);
    if (value.length < 1) {
      setPasswordWarningText(`${t("sign_up_new_password_required")}`);
    } else if (!re.test(value)) {
      setPasswordWarningText(`${t("reset_password_type_warning")}`);
    } else {
      setPasswordWarningText('');
    }
  }

  async function submitForm() {
    if (await checkUserEmail(userData.email)) {
      if (userType === UserLoginType.PROFESSIONAL) {
        setIsSignUp(false);
      } else {
        setIsLoading(true);
        const accountData: CreateUserReqDto = {
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          mobileNumber: userData.countryCode + userData.mobileNumber,
          password: userData.confirmPassword
        }
        sessionStorage.setItem("userName", accountData.email);
        try {
          const res = await dispatch(createAccountThunk(accountData)).unwrap();
          setIsLoading(false);
          if (res && res.userId) {
            toast.success(`${t("account_setup_success_validate_mob_eml")}`);
            navigate('/validate-otp');
          }
        } catch (err) {
          toast.error(`${err}`);
          setIsLoading(false);
        }
      }
    } else {
      setEmailWarningText(`${t("sign_up_email_warning")}`);
    }
  }

  return (
    <>
      <Captcha />
      {
        isSignUp && <div className={styles.mainContainer}>
          <div className={styles.pageLayout}>
            <PageLayout />
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.login}>
              <CustomButton
                buttonType='button'
                classType='tertiary'
                onClick={() => {
                  navigate('/')
                }}
              >
                <TextComponent textType='Header'>
                  <div>{t("login")}</div>
                </TextComponent>
              </CustomButton>
            </div>
            <TextComponent textType='Header'>
              <div>{t("sign_up_main")}</div>
            </TextComponent>
            <TextComponent textType='Content'>
              <span className={styles.signUpTextTwo}>{window.sessionStorage.getItem("userLoginType") === UserLoginType.PROFESSIONAL ? t("sign_up_sub") : t("sign_up_personal_sub")}</span>
            </TextComponent>
            <div style={{
              display: 'flex',
              width: '100%',
              gap: '1rem'
            }}>
              <CustomInput
                inputType='text'
                inputStyleType='fade'
                placeHolder={t("sign_up_first_name")}
                style={{
                  width: '50%'
                }}
                value={userData.firstName}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  const re = new RegExp(/^[A-Za-z ]+$/);
                  if (value.length < 1) {
                    setFirstNameWarningText(t("first_name_warning_text"));
                  } else if (!re.test(value)) {
                    setFirstNameWarningText(t("signup_first_nm_error"));
                  } else {
                    const validation = CharacterValidator(value);
                    if (validation.length > 0) {
                      setFirstNameWarningText(validation);
                    } else {
                      setFirstNameWarningText('');
                    }
                  }
                  setUserData((prevState) => ({
                    ...prevState,
                    firstName: e.target.value
                  }))
                }}
              />
              <CustomInput
                inputType='text'
                inputStyleType='fade'
                placeHolder={t("sign_up_last_name")}
                style={{
                  width: '50%'
                }}
                value={userData.lastName}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  const re = new RegExp(/^[A-Za-z ]+$/);
                  if (value.length < 1) {
                    setLastNameWarningText(t("last_name_warning_text"));
                  } else if (!re.test(value)) {
                    setLastNameWarningText(t("signup_last_nm_error"));
                  } else {
                    const validation = CharacterValidator(value);
                    if (validation.length > 0) {
                      setLastNameWarningText(validation);
                    } else {
                      setLastNameWarningText('');
                    }
                  }
                  setUserData((prevState) => ({
                    ...prevState,
                    lastName: e.target.value
                  }))
                }}
              />
            </div>
            {
              firstNameWarningText && firstNameWarningText !== '' &&
              <TextComponent textType='Error'>
                <p >{firstNameWarningText}</p>
              </TextComponent>
            }
            {
              lastNameWarningText && lastNameWarningText !== '' &&
              <TextComponent textType='Error'>
                <p >{lastNameWarningText}</p>
              </TextComponent>
            }
            <CustomInput
              inputType='email'
              inputStyleType='fade'
              placeHolder={userType === UserLoginType.INVITED || userType === UserLoginType.PROFESSIONAL ? t("work_email") : t("invite_user_enter_mail")}
              value={userData.email}
              onBlur={() => {
                if (userData.email !== "" && userData.email != null) {
                  checkUserEmail();
                }
              }}
              onChange={(e) => {
                const value = e.target.value.trim();
                validateEmail(value);
                setUserData((prevState) => ({
                  ...prevState,
                  email: e.target.value
                }))

              }}
            />
            {
              emailWarningText && emailWarningText !== '' &&
              <TextComponent textType='Error'>
                <p >{emailWarningText}</p>
              </TextComponent>
            }
            <div className={styles.mobileContainer}>
              <div className={styles.mobileCountryCodeContainer}>
                <CustomSelect data={CountryList.map((elem) => {
                  let data: DropdownDataItem = {
                    label: `${elem.dial_code} ${elem.name}`,
                    value: elem.dial_code
                  }
                  return data;
                })}
                  selectStyleType={'colored'}
                  value={
                    userData.countryCode !== "" ? {
                      label: userData.countryCode,
                      value: userData.countryCode
                    } : undefined
                  }
                  selectedData={(value) => {
                    if (value !== null) {
                      const res = (value as DropdownDataItem).value;
                      if (res.length < 1) {
                        setCountryCodeWarningText(t("country_code_required"));
                      } else {
                        setCountryCodeWarningText('');
                      }
                      setUserData((prevState) => ({
                        ...prevState,
                        countryCode: res
                      }))
                    }
                  }
                  }
                  placeholder={`${t("country_code")}`}
                />
              </div>
              <div className={styles.mobileNumberContainer}>
                <CustomInput
                  inputType='number'
                  inputStyleType='fade'
                  placeHolder={t("sign_up_mobile")}
                  value={userData.mobileNumber}
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    if (value.length < 1) {
                      setMobileWarningText(t("sign_up_mobile_required"));
                    } else if ((userData.countryCode.length + value.length) > 15 || (userData.countryCode.length + value.length) < 7) {
                      setMobileWarningText(t("invalid_mob"));
                    } else {
                      setMobileWarningText('');
                    }
                    setUserData((prevState) => ({
                      ...prevState,
                      mobileNumber: e.target.value
                    }))
                  }}
                /></div>
            </div>
            {
              countryCodeWarningText && countryCodeWarningText !== '' &&
              <TextComponent textType='Error'>
                <p >{countryCodeWarningText}</p>
              </TextComponent>
            }
            {
              mobileWarningText && mobileWarningText !== '' &&
              <TextComponent textType='Error'>
                <p>{mobileWarningText}</p>
              </TextComponent>
            }
            <CustomInput
              inputStyleType='fade'
              inputType='password'
              placeHolder={t("sign_up_new_password")}
              value={userData.newPassword}
              onChange={(e) => {
                const value = e.target.value.trim();
                validatePassword(value);
                setUserData((prevState) => ({
                  ...prevState,
                  newPassword: e.target.value
                }))
              }}
            />
            {
              passwordWarningText && passwordWarningText !== '' &&
              <TextComponent textType='Error'>
                <p>{passwordWarningText}</p>
              </TextComponent>
            }
            <CustomInput
              inputStyleType='fade'
              inputType='password'
              placeHolder={t("sign_up_repeat_password")}
              value={userData.confirmPassword}
              onChange={(e) => {
                const value = e.target.value.trim();
                if (value.length < 1) {
                  setConfirmWarningText(`${t("sign_up_new_password_required")}`);
                } else if (userData.newPassword !== value) {
                  setConfirmWarningText(`${t("reset_password_confirm_warning")}`);
                } else {
                  setConfirmWarningText('');
                }
                setIsTouched(true);
                setUserData((prevState) => ({
                  ...prevState,
                  confirmPassword: e.target.value
                }))
              }}
            />
            {
              confirmWarningText && confirmWarningText !== '' &&
              <TextComponent textType='Error'>
                <p >{confirmWarningText}</p>
              </TextComponent>
            }
            <CustomButton
              buttonType='submit'
              classType='quaternary'
              disabled={(firstNameWarningText || lastNameWarningText || countryCodeWarningText || emailWarningText || mobileWarningText || passwordWarningText || confirmWarningText || !validation) ? true : false}
              onClick={() => {
                submitForm();
              }}
              isLoading={isLoading}
            >
              <div className={styles.signupBtn}>
                <TextComponent textType='Header'>
                  <div style={{ color: '#fff' }} >{isLoading ? t("loading") : t("sign_up_start")}</div>
                </TextComponent>
                <img src={svgRightArrow} alt="" />
              </div>
            </CustomButton>
            {userType === UserLoginType.PERSONAL || userType === UserLoginType.PROFESSIONAL ? <div className={styles.loginTextContainer}>
              <TextComponent textType='Content'>
                <div > {t("switch_to")}
                  <button className={styles.signUpTextFive} onClick={() => {
                    const tempUserType = window.sessionStorage.getItem("userLoginType");
                    if (tempUserType === UserLoginType.PERSONAL) {
                      changeUserTypeSignUp(UserLoginType.PROFESSIONAL);
                    }
                    else if (tempUserType === UserLoginType.PROFESSIONAL) {
                      changeUserTypeSignUp(UserLoginType.PERSONAL);
                    }
                  }}>
                    {t("sign_up")}
                  </button>
                  {window.sessionStorage.getItem("userLoginType") === UserLoginType.PROFESSIONAL ? t("as_individual") : t("as_professional")}
                </div>
              </TextComponent>
            </div> : <></>}
          </div>
        </div>
      }
      {
        !isSignUp && <CompanySetup setIsSignUp={setIsSignUp} setUserData={setUserData} userData={userData} />
      }
    </>
  )
}
